import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import proj4 from 'proj4';

/**
 * `wms-layers`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class WMSLayers extends PolymerElement {
  static get template() {
    return html`
      <style include="">
        :host {
        }
      </style>
      <katapult-firebase-worker path="photoheight/company_space/[[userGroup]]/wms_layers" data="{{layers}}"></katapult-firebase-worker>
    `;
  }

  static get is() {
    return 'wms-layers';
  }
  static get properties() {
    return {
      map: {
        type: Object
      },
      mapTypeIds: {
        type: Object
      },
      mapBase: {
        type: String
      },
      userGroup: {
        type: String
      },
      allowMapClick: {
        type: Boolean
      },
      mapInitialized: {
        type: Boolean
      }
    };
  }
  static get observers() {
    return ['updateWmsLayers(mapInitialized, layers.*)'];
  }
  ready() {
    super.ready();
  }

  wmsNameToKey(name) {
    return 'wms:' + name.toLowerCase().replace(' ', '_');
  }

  updateWmsLayers(mapInitialized) {
    if (this.layers && mapInitialized) {
      if (!this.clickListener) {
        this.clickListener = google.maps.event.addListener(this.map, 'click', this.clickMap.bind(this));
      }
      for (let id in this.layers) {
        let wmsLayer = this.layers[id];
        let key = this.wmsNameToKey(wmsLayer.name);

        // Reassign mapTypeIds object for the legend to pickup the change.
        this.set('mapTypeIds.' + key, wmsLayer.name);

        if (wmsLayer.url.indexOf('token=') != -1 && this.get(`map.mapTypes.${key}`) == null) {
          this.map.mapTypes.set(key, {
            name: wmsLayer.name,
            alt: wmsLayer.name,
            minZoom: 0,
            maxZoom: 30,
            tileSize: { width: 256, height: 256 },
            getTile: this.getTileDivs.bind(this, wmsLayer)
          });
        } else {
          this.map.mapTypes.set(
            key,
            new google.maps.ImageMapType({
              name: wmsLayer.name,
              alt: wmsLayer.name,
              minZoom: 0,
              maxZoom: 30,
              tileSize: { width: 256, height: 256 },
              getTileUrl: this.getTileUrls.bind(this, wmsLayer)
            })
          );
        }
      }
    }
  }

  getTileDivs(layer, coord, zoom, doc) {
    var img = doc.createElement('img');
    img.style.width = '256px';
    img.style.height = '256px';
    // Get Image File with correct auth
    var req = new XMLHttpRequest();
    if (layer.wmsUser == null) {
      layer.wmsUser = prompt('Please enter your Username for this WMS Layer');
      layer.wmsPassword = prompt('Please enter your Password for this WMS Layer');
    }
    req.open('GET', this.getTileUrls(layer, coord, zoom), true, layer.wmsUser, layer.wmsPassword);
    req.responseType = 'blob';
    req.onload = function () {
      if (req.status == 200) {
        img.src = URL.createObjectURL(req.response);
      } else {
        console.warn('Error loading image map tile');
      }
    };
    req.send();
    return img;
  }

  getTileUrls(layer, coord, zoom) {
    var url =
      layer.url +
      '&service=wms&request=getmap&version=1.1.1&height=256&width=256&styles=&maxnativezoom=30&reusetiles=true&detectretina=true&srs=EPSG:3857&tms=false&continuousworld=false&nowrap=false&zoomreverse=false&zindex=null&bbox=';
    let proj = this.map.mapTypes.hybrid.projection,
      z = 1 << zoom,
      worldNW = { x: (256 * coord.x) / z, y: (256 * coord.y) / z },
      worldSE = { x: (256 * (1 + coord.x)) / z, y: (256 * (1 + coord.y)) / z },
      nw = proj.fromPointToLatLng(worldNW),
      se = proj.fromPointToLatLng(worldSE);
    nw = proj4('EPSG:3857', { x: nw.lng(), y: nw.lat() });
    se = proj4('EPSG:3857', { x: se.lng(), y: se.lat() });
    url += [nw.x, se.y, se.x, nw.y].join(',');
    url += '&format=' + (layer.image_format || 'image/png32');
    return encodeURI(url);
  }

  clickMap(e) {
    if (this.map.getMapTypeId().indexOf('wms:') == 0 && this.allowMapClick) {
      var url = null;
      for (let id in this.layers) {
        if (this.wmsNameToKey(this.layers[id].name) == this.mapBase) {
          url = this.layers[id].url;
          break;
        }
      }
      if (url != null) {
        // url = url.replace('http://', 'https://');
        var lat = e.latLng.lat();
        var lng = e.latLng.lng();
        var urlSplit = url.split('?');
        if (urlSplit.length == 2) {
          var queryParams = urlSplit[1].split('&');
          for (var i = 0; i < queryParams.length; i++) {
            if (queryParams[i].indexOf('layers=') == 0) {
              url += '&' + queryParams[i].replace('layers=', 'query_layers=');
              break;
            }
          }
        }
        url +=
          '&styles=&format=jpeg&request=GetFeatureInfo&width=2&height=2&x=1&y=1&srs=EPSG:4326&version=1.1.1&info_format=application/vnd.ogc.gml/3.1.1&bbox=';
        url += lng + ',' + lat + ',' + (lng + 0.0000001) + ',' + (lat + 0.0000001);
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onload = function (xhr) {
          if (xhr.status === 200) {
            if (this.wmsInfopopup) {
              this.wmsInfopopup.close();
              this.wmsInfopopup = null;
            }
            var gotContent = false;
            var content = '<table>';
            var parser = new DOMParser();
            var xml = parser.parseFromString(xhr.responseText, 'text/xml');
            var fields = xml.getElementsByTagName('FIELDS');
            for (var i = 0; i < fields.length; i++) {
              for (var j = 0; j < fields[i].attributes.length; j++) {
                content += '<tr><td>' + fields[i].attributes[j].name + '</td><td>' + fields[i].attributes[j].value + '</td>';
                gotContent = true;
              }
            }
            content += '</table>';
            if (gotContent) {
              this.wmsInfopopup = new google.maps.InfoWindow({
                content: content,
                disableAutoPan: true,
                position: d.latLng
              });
              this.wmsInfopopup.open(this.map);
            }
          }
        }.bind(this, xhr);
        xhr.send();
      }
    }
  }
}
window.customElements.define(WMSLayers.is, WMSLayers);
