/*
Copyright 2013 The Polymer Authors. All rights reserved.
Use of this source code is governed by a BSD-style
license that can be found in the LICENSE file.
*/
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';

import '../canvas-clock/canvas-clock.js';
import { Polymer } from '@polymer/polymer/lib/legacy/polymer-fn.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
Polymer({
  _template: html`
    <style>
      :host {
        display: block;
        margin-top: 24px;
      }
      #clock {
        font-size: 40pt;
        line-height: 75px;
        text-align: center;
        height: 75px;
      }
      #time {
        text-align: center;
        font-size: 16pt;
      }
    </style>
    <!--<div id="clock"><span>{{hours}}</span>:<span>{{minutes}}</span>:<span>{{seconds}}</span></div>-->
    <!--<div id="clock"><span>{{hours}}</span>:<span>{{minutes}}</span>:<span>{{seconds}}</span>:<span>{{millis}}</span></div>-->
    <canvas-clock active="[[active]]"></canvas-clock>
    <div id="time">{{ dateStr }}</div>
  `,

  is: 'display-clock',

  // note: properties only used in MDV bindings need not be declared (xSecond, ySecond, rotateSecond, etc.)
  // properties that need observation (e.g. we observe 'time' via 'timeChanged') must be declared
  time: null,

  dateStr: '',
  seconds: -1,
  minutes: -1,
  hours: -1,

  properties: {
    active: {
      type: Boolean,
      value: false
    },
    time: {
      type: Object
    },
    hours: String,
    minutes: String,
    seconds: String
  },

  observers: ['nextUpdateTimeLoop(active)'],

  ready: function () {},

  detached: function () {
    // when we are removed from DOM, stop watching the time
    clearInterval(this.interval);
  },

  nextUpdateTimeLoop: function () {
    if (this.active) this.rafRef = requestAnimationFrame(this.updateTimeLoop.bind(this));
  },

  updateTime: function () {
    // Get current dateTime and update date string, hours, minutes, and seconds.
    this.time = new Date();
    this.dateStr = [
      days[this.time.getDay()],
      months[this.time.getMonth()],
      this.time.getDate() + ',',
      this.time.getFullYear(),
      '(GMT ' + this.time.getTimezoneOffset() / -60 + ')'
    ].join(' ');
    this.hours = ('0' + this.time.getHours()).slice(-2);
    this.minutes = ('0' + this.time.getMinutes()).slice(-2);
    this.seconds = ('0' + this.time.getSeconds()).slice(-2);
    this.millis = ('00' + this.time.getMilliseconds()).slice(-3);
  },

  updateTimeLoop: function () {
    this.updateTime();
    this.nextUpdateTimeLoop();
  }
});
// statics
var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
