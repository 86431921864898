import { PickAnAttribute } from '../../modules/PickAnAttribute.js';
import { TraverseMarkers } from '../../modules/TraverseMarkers.js';

/*global k, firebase*/
import { SquashNulls } from '../../modules/SquashNulls.js';
import { GetMainPhoto } from '../../modules/GetMainPhoto.js';
import { AlternateDesigns } from '../../modules/AlternateDesigns.js';
import { GetConnectionLookup } from '../../modules/GetConnectionLookup.js';
import { DesignComparison } from '../../modules/DesignComparison.js';
import { isFlagEnabled } from '../../modules/FeatureFlags.js';

export default class WorkLocations {
  static async insertAndReturnListForCUEntry(
    jobId,
    nodes,
    connections,
    photos,
    traces,
    otherAttributes,
    company,
    modelDefaults,
    alternateDesignsConfig
  ) {
    const utilityCompany = await FirebaseWorker.ref(`photoheight/white_label/utilityCompany`)
      .once('value')
      .then((s) => s.val());
    const filterForeignPoleReplacements = await isFlagEnabled(
      'work_locations_separate_foreign_replacements',
      globalThis.FirebaseWorker.database(),
      {
        companyId: globalThis.katapultAuth?.userGroup,
        userId: globalThis.katapultAuth?.user?.uid
      }
    );
    let workLocations = await WorkLocations.insertByCompany(
      jobId,
      nodes,
      connections,
      photos,
      traces,
      company,
      utilityCompany,
      otherAttributes,
      {
        modelDefaults,
        alternateDesignsConfig,
        filterForeignPoleReplacements
      }
    );
    let update = {};
    for (var nodeId in nodes) {
      let power_mr_annotation = SquashNulls(nodes[nodeId], 'attributes', 'power_mr_annotation');
      let notes = '';
      for (let key in power_mr_annotation) {
        if (notes != '') notes += ' ';
        notes += power_mr_annotation[key];
      }
      if (notes != '') {
        update[nodeId + '/attributes/power_mr_annotation'] = { auto_added: notes };
      } else {
        update[nodeId + '/attributes/power_mr_annotation'] = null;
      }
    }
    await FirebaseWorker.ref('photoheight/jobs/' + jobId + '/nodes').update(update);
    if (workLocations.length > 0) {
      let picklists = SquashNulls(otherAttributes, 'company', 'picklists');
      let powerCompanies = {};
      for (let key in picklists) {
        if (key.toLowerCase().includes('power') || key.toLowerCase().includes('utility')) {
          picklists[key].forEach((item) => (powerCompanies[item.value] = true));
        }
      }
      workLocations.forEach((wl) => {
        let tags = SquashNulls(nodes, wl.nodeId, 'attributes', 'pole_tag');
        let tag = '';
        for (let key in tags) {
          if (powerCompanies[tags[key].company]) {
            if (tag != '') tag += ', ';
            tag += tags[key].company.replace('Company', '').trim() + ' ' + tags[key].tagtext;
          }
        }
        wl.tag = tag;
        wl.label = `${modelDefaults.ordering_attribute_label} ${wl[modelDefaults.ordering_attribute]}, ${wl.tag}, WL ${wl.work_location}`;
      });
    }
    return workLocations;
  }

  static insertByCompany(jobId, nodes, connections, photos, traces, company, utilityCompany, otherAttributes, options) {
    options = options || {};
    const modelDefaults = options.modelDefaults || {};
    let nodesWithMR = {};
    let update = {};
    const connLookup = GetConnectionLookup(nodes, connections);
    for (let nodeId in nodes) {
      if (
        WorkLocations.needsWorkLocation(
          nodeId,
          nodes,
          connLookup[nodeId],
          photos,
          traces,
          company,
          utilityCompany,
          otherAttributes,
          modelDefaults,
          options.alternateDesignsConfig
        )
      ) {
        nodesWithMR[nodeId] = {
          [modelDefaults.ordering_attribute]: PickAnAttribute(nodes[nodeId].attributes, modelDefaults.ordering_attribute),
          nodeId,
          photoId: GetMainPhoto(nodes[nodeId].photos)
        };
      } else {
        update[nodeId + '/attributes/work_location'] = null;
      }
    }
    for (let connId in connections) {
      for (let sectionId in connections[connId].sections) {
        let design = { nodes, photos, traces, errors: [] };
        const photoId = GetMainPhoto(connections[connId].sections[sectionId].photos);
        const alternateDesigns = AlternateDesigns.getAppliedAlternateDesigns(
          photoId,
          '',
          design.photos,
          design.nodes,
          [],
          options.alternateDesignsConfig,
          modelDefaults,
          ([key, val]) => val.metadata?.overlapping_design || val.metadata?.overlapping_job_id
        );

        //todo this could be made smaller so we are not copying all of nodes and photos for every alternate design
        if (alternateDesigns.length) {
          design = structuredClone(design);
          // Subtract the latest alternate design
          DesignComparison.subtractDesign(
            design,
            alternateDesigns.at(-1),
            photoId,
            '',
            [],
            options.alternateDesignsConfig,
            modelDefaults,
            otherAttributes,
            design.traces,
            { removePreviouslyProposed: true }
          );
        }
        if (this.findMR(connections[connId].sections[sectionId], design.photos, design.traces, company)) {
          let connNodes = [];
          let n1 = SquashNulls(nodes, connections[connId].node_id_1);
          let n2 = SquashNulls(nodes, connections[connId].node_id_2);
          const nodeType1 = PickAnAttribute(n1.attributes, modelDefaults.node_type_attribute);
          const nodeType2 = PickAnAttribute(n2.attributes, modelDefaults.node_type_attribute);
          if (modelDefaults.pole_node_types.includes(nodeType1) || nodeType1 == 'pad transformer') {
            connNodes.push({
              nodeId: connections[connId].node_id_1,
              [modelDefaults.ordering_attribute]: PickAnAttribute(n1.attributes, modelDefaults.ordering_attribute),
              photoId: GetMainPhoto(nodes[connections[connId].node_id_1]?.photos)
            });
          }
          if (modelDefaults.pole_node_types.includes(nodeType2) || nodeType2 == 'pad transformer') {
            connNodes.push({
              nodeId: connections[connId].node_id_2,
              [modelDefaults.ordering_attribute]: PickAnAttribute(n2.attributes, modelDefaults.ordering_attribute),
              photoId: GetMainPhoto(nodes[connections[connId].node_id_2]?.photos)
            });
          }
          connNodes.sort(this.orderingAttributeSort(modelDefaults));
          let foundNodeWithMr = connNodes.some((item) => {
            if (nodesWithMR[item.nodeId]) {
              return true;
            }
          });
          if (!foundNodeWithMr && connNodes.length > 0 && nodes[connNodes[0].nodeId]) {
            nodesWithMR[connNodes[0].nodeId] = connNodes[0];
          }
        }
      }
    }
    let mrNodes = Object.values(nodesWithMR);
    mrNodes.sort(this.orderingAttributeSort(modelDefaults));

    // Increment work locations for foreign poles separately from other poles because they go in a different work order
    const foreignPoleReplacements = options.filterForeignPoleReplacements
      ? mrNodes.filter((item) => {
          const proposedPoleSpec = PickAnAttribute(nodes[item.nodeId]?.attributes, 'proposed_pole_spec');
          if (!proposedPoleSpec) return false;
          const tags = Object.values(nodes?.[item.nodeId]?.attributes?.pole_tag ?? {});
          return tags.some((tag) => tag.owner && tag.company !== company);
        })
      : [];

    // Start at 10 and increase by 1
    let foreignCounter = 10;
    foreignPoleReplacements.forEach((node) => {
      node.work_location = (foreignCounter + '').padStart(3, '0');
      update[`${node.nodeId}/attributes/work_location`] = { auto_added: node.work_location };
      foreignCounter += 1;
    });

    // Start at 0100 and increase by 10s
    let counter = 100;
    mrNodes.forEach((node) => {
      if (foreignPoleReplacements.includes(node)) return;
      node.work_location = (counter + '').padStart(4, '0');
      update[`${node.nodeId}/attributes/work_location`] = { auto_added: node.work_location };
      counter += 10;
    });
    return FirebaseWorker.ref(`/photoheight/jobs/${jobId}/nodes`)
      .update(update)
      .then(() => mrNodes);
  }

  // returns a list of cu entry info for poles with existing work locations, without inserting anything new
  static returnListForCUEntry(nodes, otherAttributes, modelDefaults) {
    let workLocations = [];
    let picklists = SquashNulls(otherAttributes, 'company', 'picklists');
    let powerCompanies = {};
    for (let key in picklists) {
      if (key.toLowerCase().includes('power') || key.toLowerCase().includes('utility')) {
        picklists[key].forEach((item) => (powerCompanies[item.value] = true));
      }
    }
    for (let nodeId in nodes) {
      let node = nodes[nodeId];
      let workLocationNumber = PickAnAttribute(node.attributes, 'work_location');
      if (workLocationNumber) {
        let workLocation = {
          nodeId,
          work_location: workLocationNumber,
          [modelDefaults.ordering_attribute]: PickAnAttribute(node.attributes, modelDefaults.ordering_attribute),
          photoId: GetMainPhoto(node?.photos)
        };
        let tags = SquashNulls(node, 'attributes', 'pole_tag');
        let tag = '';
        for (let key in tags) {
          if (powerCompanies[tags[key].company]) {
            if (tag != '') tag += ', ';
            tag += tags[key].company.replace('Company', '').trim() + ' ' + tags[key].tagtext;
          }
        }
        workLocation.tag = tag;
        workLocation.label = `${modelDefaults.ordering_attribute_label} ${workLocation[modelDefaults.ordering_attribute]}, ${
          workLocation.tag
        }, WL ${workLocation.work_location}`;
        workLocations.push(workLocation);
      }
    }
    return workLocations.sort(this.orderingAttributeSort(modelDefaults));
  }

  // returns a list of poles that do not have a work location that should
  // and a list of nodes that have work locations that shouldn't
  // and an overall list of the nodes that do have work locations
  // and whether or not work locations exist on any make ready poles
  static checkForWorkLocations(
    nodes,
    connections,
    photos,
    traces,
    company,
    utilityCompany,
    otherAttributes,
    modelDefaults,
    alternateDesignsConfig
  ) {
    let workLocationsExist = false;
    const polesWithoutWorkLocations = [];
    const badPolesWithWorkLocations = [];
    const nodesWithWorkLocations = [];
    const connLookup = GetConnectionLookup(nodes, connections);
    for (const nodeId in nodes) {
      const needsWorkLocation = WorkLocations.needsWorkLocation(
        nodeId,
        nodes,
        connLookup[nodeId],
        photos,
        traces,
        company,
        utilityCompany,
        otherAttributes,
        modelDefaults,
        alternateDesignsConfig
      );
      const workLocation = PickAnAttribute(nodes[nodeId].attributes, 'work_location');
      if (needsWorkLocation) {
        if (!workLocation) {
          polesWithoutWorkLocations.push(nodeId);
        } else {
          workLocationsExist = true;
          nodesWithWorkLocations.push(nodeId);
        }
      } else {
        if (workLocation) {
          badPolesWithWorkLocations.push(nodeId);
          nodesWithWorkLocations.push(nodeId);
        }
      }
    }
    return { workLocationsExist, polesWithoutWorkLocations, badPolesWithWorkLocations, nodesWithWorkLocations };
  }

  static needsWorkLocation(
    nodeId,
    nodes,
    nodeConnections,
    photos,
    traces,
    company,
    utilityCompany,
    otherAttributes,
    modelDefaults,
    alternateDesignsConfig
  ) {
    const photoId = GetMainPhoto(nodes[nodeId].photos);
    const mrCategory = PickAnAttribute(nodes[nodeId].attributes, 'mr_category');
    const nodeType = PickAnAttribute(nodes[nodeId].attributes, modelDefaults.node_type_attribute);

    // We need one if we have a pole or pad transformer
    if ([...modelDefaults.pole_node_types, 'pad transformer'].includes(nodeType)) {
      const workLocationCategories = ['Complex Make Ready'];
      if (utilityCompany == 'ppl_attachments' || utilityCompany == 'lge' || utilityCompany == 'rie' || utilityCompany == 'UGI_utilities')
        workLocationCategories.push('Medium Make Ready');
      if (utilityCompany == 'lge') workLocationCategories.push('Pole Replacement', 'Pole Transfer');
      // And it's medium or complex mr category
      if (workLocationCategories.includes(mrCategory)) {
        return true;
      }
      // or there is mr
      else {
        // Handle alternate designs
        let design = AlternateDesigns.getCurrentJobDesign(
          photoId,
          nodeId,
          nodes,
          photos,
          traces,
          nodeConnections,
          alternateDesignsConfig,
          modelDefaults,
          otherAttributes
        );
        if (this.findMR(design.nodes[nodeId], design.photos, design.traces, company)) {
          return true;
        }
      }
    }
  }

  static findMR(item, photos, traces, company) {
    let photoData = this.getPhotoData(item, photos);
    let result = TraverseMarkers(photoData, (child, path, childProperty, childItemKey) => {
      let pathItems = path.split('.');
      let topMarker = SquashNulls(photoData, pathItems[0], pathItems[1]);
      let co = SquashNulls(traces, child._trace, 'company');
      if (co == company && ((topMarker.mr_move && topMarker.mr_move != '0') || topMarker.mr_note || topMarker.mr_remove)) {
        return true;
      }
    });
    return result;
  }
  static getPhotoData(item, photos) {
    let photoId = GetMainPhoto(item?.photos);
    return SquashNulls(photos, photoId, 'photofirst_data');
  }
  static orderingAttributeSort(modelDefaults) {
    return (a, b) => {
      return (a[modelDefaults.ordering_attribute] ?? '100000000').localeCompare(b[modelDefaults.ordering_attribute] ?? '100000000');
    };
  }
}
