import '@polymer/polymer/polymer-legacy.js';
import '../style-modules/paper-dialog-style.js';
import '../job-uploader/job-uploader.js';
import '../katapult-elements/katapult-button.js';
import { Polymer } from '@polymer/polymer/lib/legacy/polymer-fn.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
// Tracks completion of events
var windowEventCounter = 0;
var dropZoneEventCounter = 0;
Polymer({
  _template: html`
     <style include="paper-dialog-style">
       .dropZoneContainer {
         display: flex;
       }
      .dropZone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 160px;
        margin: 8px;
        padding: 24px;
        box-sizing: border-box;
        border: 1px solid var(--paper-grey-300);
        border-radius: 24px;
        text-transform: uppercase;
        transition: box-shadow 0.1s;
        position: relative
      }

      .dropZone:hover {
        @apply --shadow-elevation-2dp;
      }

      .dropZone:hover {
         cursor: pointer;
      }

      .dropZone:hover iron-icon {
         color: var(--primary-text-color-faded);
         transition: color 0.1s;
      }

      .dropZone.disabled {
         opacity: 0.5;
         pointer-events:none;
      }

      .buttonContainer {
         display:flex;
         justify-content:flex-end;
      }

      iron-icon {
        flex-grow: 1;
        width: inherit;
        color: var(--paper-grey-500);
      }

      .title {
         text-align: center;
         color: gray;
         bottom: 20px;
         font-size:16px;
      }

      .hidden {
         display: none;
      }
      p {
        font-size: 18pt;
        text-transform: uppercase;
        color: var(--primary-text-color-faded);
      }
      .helpIcon {
          position: absolute;
          top: 0;
          right: 0
      }
      #helpDialog {
          max-width: 650px
      }
      .helpText {
          font-size: 16px;
          text-transform: none
      }
      #closeHelpDialogButton {
          position: absolute;
          top: 10px;
          right: 10px;
      }
     </style>
      <job-uploader id="jobUploader" create-job-form="[[createJobForm]]" user-group="{{userGroup}}" job-id="{{jobId}}" job-nodes="{{jobNodes}}" job-conns="{{jobConns}}" attributes-data="[[attributesData]]" disabled="[[or(disabled, disableJobUploader)]]" model-options="[[modelOptions]]"></job-uploader>

        <paper-dialog id="helpDialog" no-cancel-on-outside-click>
            <div id="helpDialogWrapper">
                <template is="dom-if" if="{{equals(selectedHelpOption, 'nodeData')}}">
                    <div style="margin-bottom: 24px">
                        <h4>Uploading Node Data</h4>
                        <div>
                            <p class="helpText">You can import a .CSV/.XLSX file into Katapult Pro to pre-design your jobs. The only two necessary columns in your sheet are latitude and longitude, but you can include other attribute information in the spreadsheet as well. 
                            You can add extra columns, each column for one attribute, and the software will match those column headers to an attribute that exists in your model. If a column header doesn't match, you can map those columns to an attribute of your choosing.</p>
                            <hr></hr>
                            <p class="helpText">You can also create sub-jobs using this importer. If you provide a job_name column in your spreadsheet, the software will create sub-jobs and place the correct nodes in each of those sub-jobs. If you want to place those sub jobs into specific folders,
                             you can provide a job_path column instead of the job_name column. The job_path column should contain the exact path you'd like the job to reside at.. <br>Ex: Folder 1/Sub-Folder 1/Job Name</p>
                        </div>
                    </div>
                </template>
                <template is="dom-if" if="{{equals(selectedHelpOption, 'connData')}}">
                    <div style="margin-bottom: 24px">
                        <h4>Uploading Connection Data</h4>
                        <div>
                            <p class="helpText">You can import a .CSV/.XLSX file into Katapult Pro to pre-design your jobs. The only 4 necessary columns in your sheet are latitude1, longitude1, latitude2, and longitude2, but you can include other attribute information in the spreadsheet as well. 
                            You can add extra columns, each column for one attribute, and the software will match those column headers to an attribute that exists in your model. If a column header doesn't match, you can map those columns to an attribute of your choosing.</p>
                            <hr></hr>
                            <p class="helpText">You can also add connections to sub-jobs using this importer. If you provide a job_name column in your spreadsheet, the software will find those sub-jobs and place the connections there.
                        </div>
                    </div>
                </template>
                <template is="dom-if" if="{{equals(selectedHelpOption, 'customImport')}}">
                    <div style="margin-bottom: 24px">
                        <h4>Upload Custom Files</h4>
                        <div>
                            <p class="helpText">Want to upload data from another file format? Contact us at support@katapultengineering.com to request an estimate.</p>
                        </div>
                    </div>
                </template>
                <template is="dom-if" if="{{equals(selectedHelpOption, 'attributeData')}}">
                    <span>attributeData</span>
                </template>
                <template is="dom-if" if="{{equals(selectedHelpOption, 'wireData')}}">
                    <span>wireData</span>
                </template>
                <katapult-button id="closeHelpDialogButton" icon="clear" iconOnly noBorder on-click="closeHelpDialog"></katapult-button>
            </div>
        </paper-dialog>
        
      <paper-dialog id="container">
         <div id="uploadDialog" class="dropZoneContainer">
            <!-- If there's no jobId, we should display the node drop zone without worrying about if we can write.  If there is a jobId, then we only want to display it if sharing is write -->
            <template is="dom-if" if="[[showNodeZone]]">
               <div id="nodeDropZone" on-click="clickHandler" class="dropZone">
                  <iron-icon icon="file-upload"></iron-icon>
                  <div id="nodeDropZoneTitle" class="title">Upload Node Data</div>
                  <katapult-button class="helpIcon" option="nodeData" icon="help" iconOnly noBorder noBackground></katapult-button>
               </div>
            </template>
            <template is="dom-if" if="[[canWrite]]">
               <div id="connDropZone" on-click="clickHandler" class="dropZone">
                  <iron-icon icon="social:share"></iron-icon>
                  <div id="connDropZoneTitle" class="title">Upload Connection Data</div>
                  <katapult-button class="helpIcon" option="connData" icon="help" iconOnly noBorder noBackground></katapult-button>
               </div>
               <div id="attrDropZone" on-click="clickHandler" class="dropZone">
                  <iron-icon icon="file-upload"></iron-icon>
                  <div id="attrDropZoneTitle" class="title">Upload Attribute Data</div>
               </div>
               <div id="wireDropZone" on-click="clickHandler" class="dropZone" hidden="[[hideWireData(tier)]]">
                  <iron-icon icon="communication:clear-all"></iron-icon>
                  <div id="wireDropZoneTitle" class="title">Upload Wire Data</div>
               </div>
               <div id="customDropZone" on-click="clickHandler" class="dropZone">
                  <iron-icon icon="editor:functions"></iron-icon>
                  <div id="customDropZoneTitle" class="title">Custom Import</div>
                  <katapult-button class="helpIcon" option="customImport" icon="help" iconOnly noBorder noBackground></katapult-button>
              </div>
            </template>
          <template is="dom-if" if="[[!canWrite]]">
            <template is="dom-if" if="[[jobId]]">
              <p>You do not have permission to modify this job</p>
            </template>
          </template>
         </div>
         <div class="buttons">
            <katapult-button color="var(--paper-red-500)" on-click="clearUI">Cancel</katapult-button>
         </div>
      </paper-dialog>
`,

  is: 'drop-detector',

  properties: {
    attributesData: Object,
    hasGeoFire: {
      notify: true
    },
    canWrite: {
      type: Boolean,
      computed: 'calcCanWrite(jobId, sharing)'
    },
    showNodeZone: {
      type: Boolean,
      computed: 'calcShowNodeZone(jobId, canWrite)'
    },
    disableJobUploader: {
      type: Boolean,
      value: true
    },
    ignoreDropEvents: {
      type: Boolean
    },
    jobId: {
      notify: true
    },
    jobNodes: {
      notify: true
    },
    jobConns: {
      notify: true
    },
    katapultMap: {
      type: Object
    },
    userGroup: {
      notify: true
    },
    photoUploaderElement: {
      type: Object
    },
    disabled: Boolean
  },

  ready: function () {
    this.katapultMap.addEventListener('drop', this.dropHandler.bind(this));
  },

  or(a, b) {
    return a || b;
  },

  attached: function () {
    this.clearUI();
  },
  equals(a, b) {
    return a == b;
  },

  calcCanWrite: function () {
    return this.jobId && this.sharing === 'write';
  },

  calcShowNodeZone: function () {
    return !this.jobId || this.canWrite;
  },

  clearUI: function () {
    windowEventCounter = 0;
    dropZoneEventCounter = 0;
    this.files = null;
    this.$.container.close();
    this.toggleClass('disabled', false, this.$.wireDropZone);
    this.toggleClass('disabled', false, this.$.nodeDropZone);
    if (this.$.nodeDropZoneTitle) this.$.nodeDropZoneTitle.innerHTML = 'Upload Node Data';
  },

  updateUI: function (sharing, dropType, showPhotoExtensionError) {
    let nodeDropZone = this.shadowRoot.querySelector('#nodeDropZone');
    let wireDropZone = this.shadowRoot.querySelector('#wireDropZone');

    if (dropType == 'photoData') this.toggleClass('disabled', true, nodeDropZone);
    else if (dropType == 'nodeData') {
      let nodeDropZoneTitle = this.shadowRoot.querySelector('#nodeDropZoneTitle');

      if (!this.jobId) {
        nodeDropZoneTitle.innerHTML = 'Create Sub Jobs From Node Data';
        this.toggleClass('disabled', true, wireDropZone);
      } else nodeDropZoneTitle.innerHTML = 'Upload Node Data';

      if (this.hasGeoFire && nodeDropZoneTitle) {
        nodeDropZoneTitle.innerHTML = 'No Upload for GeoFire Job';
      }
    } else {
      this.toggleClass('disabled', true, nodeDropZone);
      this.toggleClass('disabled', true, wireDropZone);
    }
    this.$.container.open();
  },

  parentHasId: function (element, id) {
    while (element.parentElement) {
      if (element.getAttribute('id') == id || element.parentElement.getAttribute('id') == id) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  },

  dropHandler: function (e) {
    this.disableJobUploader = false;
    if (this.ignoreDropEvents) return;
    e.preventDefault();
    if (e.dataTransfer) {
      try {
        var data = JSON.parse(e.dataTransfer.getData('application/json'));
        if (data.photos != null) return;
      } catch (e) {}
    }
    // Get the dropped file objects
    var files = [];
    if (e.dataTransfer == null) {
      files = e.target.files;
    } else {
      files = e.dataTransfer.files;
    }
    if (files != null) {
      var noValidFileFound = true;
      var jpegFileCount = 0;
      var hadImageFiles = false;
      for (var i = 0; i < files.length; i++) {
        var fileParts = files[i].name.split('.');
        var extension = fileParts[fileParts.length - 1];
        if (files[i].type.indexOf('image') != -1) hadImageFiles = true;
        // Check for xls and stop if found
        if (extension == 'xls' || extension == 'xlsx' || extension == 'csv' || extension == 'kmz' || extension == 'zip') {
          e.stopPropagation();
          noValidFileFound = false;
          this.files = files;
          this.updateUI(this.sharing, 'nodeData');
          break;
        }
        // Count the number of jpeg files
        if (extension == 'jpeg' || extension == 'jpg' || extension == 'JPEG' || extension == 'JPG') {
          jpegFileCount++;
        }
      }

      // Check if all files were jpegs
      if (jpegFileCount == files.length) {
        noValidFileFound = false;
        this.files = files;
        this.updateUI(this.sharing, 'photoData');
        // Otherwise, check if we had at least some jpegs, but not all jpegs
      } else if (jpegFileCount > 0) {
        noValidFileFound = false;
        this.updateUI(this.sharing, 'photoData', true);
        // Finally, check if we had no jpegs, but we had some other images
      } else if (jpegFileCount == 0 && hadImageFiles) {
        noValidFileFound = false;
        this.updateUI(this.sharing, 'photoData', true);
      }

      if (noValidFileFound) this.updateUI(this.sharing, '');
    }
  },

  uploadPhotos: function () {
    if (this.sharing == 'write') {
      if (this.photoUploaderElement) {
        this.photoUploaderElement._addFilesToList(this.files, false);
        this.photoUploaderElement.open();
      }
    }
  },

  hideWireData: function (tier) {
    if (tier && tier.includes('enterprise')) return false;
    return true;
  },

  clickHandler: function (e) {
    let target = e.target;
    if (target.classList.contains('helpIcon')) {
      this.selectedHelpOption = target.getAttribute('option');
      this.$.helpDialog.open();
    } else {
      if (
        this.parentHasId(e.target, 'nodeDropZone') ||
        this.parentHasId(e.target, 'connDropZone') ||
        this.parentHasId(e.target, 'attrDropZone')
      ) {
        if (Array.from(this.files).every((file) => file.name.toLowerCase().endsWith('.kmz') || file.name.toLowerCase().endsWith('.zip'))) {
          this.domHost.importKMZToNodesAndConnections(this.files);
        } else {
          if (this.parentHasId(e.target, 'nodeDropZone')) {
            this.$.jobUploader.readFiles(this.files, 'nodeDropZone');
          } else if (this.parentHasId(e.target, 'connDropZone')) {
            this.$.jobUploader.readFiles(this.files, 'connDropZone');
          } else if (this.parentHasId(e.target, 'attrDropZone')) {
            this.$.jobUploader.readFiles(this.files, 'attrDropZone');
          }
        }
      } else if (this.parentHasId(e.target, 'wireDropZone')) {
        this.domHost.uploadWireData(this.files);
      } else if (this.parentHasId(e.target, 'customDropZone')) {
        this.domHost.openCustomFileImporter(this.files);
      }

      this.clearUI();
    }
  },

  closeHelpDialog: function () {
    this.$.helpDialog.close();
  }
});
