import '../../app-configuration/app-configuration.js';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { KatapultPageElement, html } from '../../mixins/katapult-page-element.js';
import { html as litHtml, render } from 'lit';
import { map } from 'lit/directives/map.js';
import { join } from 'lit/directives/join.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';
import { timeOut } from '@polymer/polymer/lib/utils/async.js';
import { KLogic } from '../../modules/KLogic.js';
import '../material-icon/material-icon';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/maps-icons.js';
import '@polymer/iron-icons/av-icons.js';
import '@polymer/iron-icons/social-icons.js';
import '@polymer/iron-icons/device-icons.js';
import '@polymer/iron-icons/editor-icons.js';
import '@polymer/iron-icons/communication-icons.js';
import '@polymer/iron-icons/hardware-icons.js';
import '@polymer/iron-icons/image-icons.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-collapse/iron-collapse.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import 'iron-a11y-keys/iron-a11y-keys.js';
import '@polymer/iron-flex-layout/iron-flex-layout.js';
import '@polymer/iron-overlay-behavior/iron-overlay-backdrop.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-tabs/paper-tab.js';
import '@polymer/paper-ripple/paper-ripple.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-radio-button/paper-radio-button.js';
import '@polymer/paper-radio-group/paper-radio-group.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-toggle-button/paper-toggle-button.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js';
import '@polymer/paper-tooltip/paper-tooltip.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@polymer/neon-animation/neon-animation.js';
import '../icon-picker-dialog/icon-picker-dialog.js';
import '../google-map/google-map-elements.js';
import '../google-map/google-map-svg-text-marker.js';
import '../../js/converted-components/geo-location/geo-location.js';
import '../drop-detector/drop-detector.js';
import { KatapultGeometry } from 'katapult-toolbox';
import { SquashNulls } from '../../modules/SquashNulls.js';
import { CamelCase } from '../../modules/CamelCase.js';
import { GetJobData } from '../../modules/GetJobData.js';
import { LargeUpdate } from '../../modules/LargeUpdate.js';
import { DefaultComputedBindings } from '../../modules/DefaultComputedBindings.js';
import { ToArray } from '../../modules/ToArray.js';
import { FlattenGeoJSON } from '../../modules/FlattenGeoJSON.js';
import { ModelDefaults } from '../../modules/ModelDefaults.js';
import { GetNewAttributeValue } from '../../modules/GetNewAttributeValue.js';
import { GetMainPhoto } from '../../modules/GetMainPhoto.js';
import '../katapult-map/katapult-map.js';
import '../wms-layers/wms-layers.js';
import { GeoFire } from 'geofire';
import { GeofireTools } from '../../modules/GeofireTools.js';
import '../katapult-auth/katapult-auth.js';
import '../select-drop-down/select-drop-down.js';
import '../input-element/input-element.js';
import '../input-element/gui_elements/katapult-team-members-input.js';
import '../katapult-photo/katapult-photo.js';
import '../katapult-photo/katapult-photo-viewer.js';
import '../katapult-photo/katapult-photo-chooser.js';
import '../display-clock/display-clock.js';
import '../job-chooser/job-chooser.js';
import '../job-chooser/create-job-form.js';
import { MLD } from '../../modules/MasterLocationDirectory.js';
import '../photo-first/photo-controls.js';
import '../avatar-icon/avatar-icon.js';
import HardwareDetails from '../photo-first/hardware-details.js';
import '../katapult-animations/katapult-slide-up-left-animation.js';
import '../katapult-animations/katapult-slide-left-animation.js';
import '../katapult-toolbar/katapult-toolbar.js';
import '../katapult-search/katapult-search.js';
import '../resizeable-div/resizeable-div.js';
import '../katapult-drop-down/katapult-drop-down.js';
import '../katapult-photo-label/katapult-photo-label.js';
import '../model-loader/model-loader.js';
import '../master-location-directory-manager/master-location-directory-manager.js';
import '../katapult-chat/katapult-chat.js';
import './katapult-tool-panel.js';
import './print-tools/print-mode-toolbar.js';
import './print-tools/print-generator.js';
import { PPLBilling } from '../../modules/PPLBilling.js';
import '../progress-bar/progress-bar.js';
import { ToGeoJSON } from '../../modules/_deprecated/ToGeoJSON.js';
import { GeoJSONQuery } from '../../modules/GeoJSONQuery.js';
import { FirebaseEncode } from '../../modules/FirebaseEncode.js';
import { GetPhotoSummary } from '../../modules/GetPhotoSummary.js';
import { GetConnectionLookup, GetExtendedConnectionLookup } from '../../modules/GetConnectionLookup.js';
import { JobDataByFeature } from '../../modules/JobDataByFeature.js';
import FixMapErrors from './FixMapErrors.js';
import PrepForPostConstruction from './PrepForPostConstruction.js';
import { IncrementFolderCounter } from '../../modules/IncrementFolderCounter.js';
import WorkLocations from './WorkLocations.js';
import { Uploader } from '../katapult-upload-page/Uploader.js';
import CollectionSetTools from '../photo-management/collectionSetTools.js';
import '../style-modules/paper-dialog-style.js';
import '../style-modules/paper-table-style.js';
import '../style-modules/paper-menu-button-style.js';
import './seed-job.js';
import './map-overlay.js';
import 'shpjs/dist/shp.js';
import { uuidv4 } from '../../modules/browserUUIDv4.js';
import proj4 from 'proj4';
import { setJobMetadata } from './button_functions/set_job_metadata.js';
import { getAddressData } from './button_functions/get_address_data.js';
import '../katapult-model-editor/katapult-model-editor-maps-getting-started-wizard';
import calibratePhoto from '../calibrate-photo/calibrate-photo.js';
import '../katapult-dialog-legacy/katapult-dialog-legacy.js';
import '../attribute-selector/attribute-selector.js';
import '../katapult-query-builder/katapult-query-builder';
import { EmailTemplate } from '../../modules/EmailTemplate';
import { OpenPage } from '../../modules/OpenPage.js';
import { GetAttributeLookup } from '../../modules/GetItemName.js';
import { Path } from '../../modules/Path.js';
import { PickAnAttribute } from '../../modules/PickAnAttribute.js';
import { NotifyResizable } from '../../modules/NotifyResizable.js';
import { ToTitleCase } from '../../modules/ToTitleCase.js';
import { TraverseMarkers } from '../../modules/TraverseMarkers.js';
import { PplTagLocation } from '../../modules/PplTagLocation.js';
import { WebpToJpg } from '../../modules/WebpToJpg.js';
import { SortedKeys } from '../../modules/SortedKeys.js';
import { Round } from '../../modules/Round.js';
import { RunOnChildren } from '../../modules/RunOnChildren.js';
import { CompareScids } from '../../modules/CompareScids.js';
import { HowLong, Pad } from '../../modules/Date.js';
import { Throttle } from '../../modules/Throttle.js';
import { GetMidpointLatLng } from '../../modules/GetMidpointLatLng.js';
import { StyleRuleToIcon } from '../../modules/StyleRuleToIcon.js';
import { AddNodeCalls } from '../../modules/AddNodeCalls.js';
import { ApplyEffectiveMoves, CalcPhotoDistanceRatios } from '../../modules/ApplyEffectiveMoves.js';
import { CalcLeftRight } from '../../modules/CalcLeftRight.js';
import { FormatHeight } from '../../modules/FormatHeight.js';
import { CalcStatement } from '../../modules/CalcStatement.js';
import { DataLayer } from '../../modules/DataLayer/DataLayer.js';
import { UpdateJobPermissions } from '../../modules/UpdateJobPermissions.js';
import { GetCardinalDirection } from '../../modules/GetCardinalDirection.js';
import { JoinSentences } from '../../modules/JoinSentences.js';
import { Convert } from '../../modules/Convert.js';
import { AssociatePhotos } from '../../modules/AssociatePhotos.js';
import { DataViews } from '../../modules/DataViews.js';
import { DialogService } from '../../modules/DialogService.js';
import { SetJobArchiveStatus } from '../../modules/SetJobArchiveStatus.js';
import { CalcOneTouchSummary } from '../../modules/CalcOneTouchSummary.js';
import { GetCompanyNames } from '../../modules/GetCompanyNames.js';
import { DeepEqual } from '../../modules/DeepEqual.js';
import { KatapultDialog } from '../katapult-elements/katapult-dialog.js';
import { DeleteJob } from '../../modules/DeleteJob.js';
import '../katapult-elements/katapult-button.js';
import '../katapult-color-picker/katapult-color-picker-dialog.js';
import '../katapult-color-picker/katapult-color-picker-button.js';
import { addFlagListener, removeFlagListener } from '../../modules/FeatureFlags.js';
import { CalcRulingSpan } from '../../modules/CalcRulingSpan.js';
import { getKPLAEngine } from '../../modules/IPLVersionManager.js';
import './exports/photo-export.js';
import { LineAngleCalculations } from '../../modules/LineAngleCalculations.js';
import { GeoStyleToIcon } from '../../modules/GeoStyleToIcon.js';
import { getLayer } from '../../modules/JobLayers.js';
import { stripIndent } from 'common-tags';
import { JobDashboard } from '../../modules/Dashboards/JobDashboard.js';
import { KatapultJob } from '../../modules/katapult-job/KatapultJob.js';
import { composeItemAttributesUpdate, composeItemGeoStyleUpdate } from '../../modules/katapult-job/KatapultJobUpdateComposers.js';
import { createReplacingItemAttributesMutation } from '../../modules/katapult-job/KatapultJobUtilities.js';
import { CallCrossServerIdentityAction } from '../../modules/CallCrossServerIdentityAction.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/tree/tree.js';
import './map-layers-menu-view.js';
import { ShowReadOnlyWelcomeDialog, ShowReadOnlyWelcomeDialogIfNotShownBefore } from './ReadyOnlyWelcomeDialog.js';
import { DateTime } from 'luxon';
import { getTokensFromUrl, runWebTool } from '../../modules/WebTools.js';
import { findPoleTagMappingInfo, openAddPoleToAppDialog } from './button_functions/add_pole_to_app.js';
import { listenToTransferLocking } from '../../modules/TransferLocking.js';
import { isJobLocked, showLockedJobDialog } from './locked-job-dialog.js';
import { AddPoleToApplication } from '../../modules/PoleApplication.js';
import { capitalCase } from 'change-case';
import pluralize from 'pluralize-esm';
import { ShowLargeUpdateBlockedDialog } from '../../modules/BlockLargeUpdates.js';
import { ITEM_UPDATE_LIMIT } from './button_functions/ItemUpdateLimit.js';
import { Chunk } from '../../modules/Chunk.js';

/* global Polymer, Firebase, google, navigator, L, k, OfflineJobPackage */
class KatapultMapsDesktop extends mixinBehaviors([DefaultComputedBindings], KatapultPageElement) {
  static get template() {
    return html` <style include="paper-dialog-style paper-tooltip-style paper-menu-button-style paper-table-style">
         img[src*="#gravitarMapIcon"] {
          border-radius:100%;
        }
         [fit] {
           position: absolute;
           top: 0;
           right: 0;
           bottom: 0;
           left: 0;
         }
         [hidden] {
           display: none !important;
         }
         :host {
           display:block;
           position:absolute;
           top: 0;
           left: 0;
           bottom:0;
           right:0;
           width: 100%;
           height: 100%;
           overflow:hidden;
           font-family: 'Source Sans Pro', sans-serif;
         }

         /*scrollbar styles:*/
         ::-webkit-scrollbar {
             width: 6px;
         }

         ::-webkit-scrollbar-track {
             -webkit-border-radius: 3px;
             border-radius: 3px;
             -webkit-box-shadow: inset 0 0 3px #717271;
         }

         ::-webkit-scrollbar-thumb {
             -webkit-border-radius: 3px;
             border-radius: 3px;
             background: rgba(0,114,153,0.8);
         }
         ::-webkit-scrollbar-thumb:window-inactive {
         	background: rgba(0,62,81,0.4);
         }
         /*end scrollbar styles*/
         #googlemap {
           --google-map-style :{
             font:300 13px Roboto, Arial, sans-serif;
           }
         }
         .deleteButton {
           background-color:#d23f31;
           color:white;
         }
         paper-spinner {
           --paper-spinner-layer-1-color:var(--primary-color);
           --paper-spinner-layer-2-color:var(--primary-color);
           --paper-spinner-layer-3-color:var(--primary-color);
           --paper-spinner-layer-4-color:var(--primary-color);
         }
         #mainChooser {
           position: absolute;
           z-index: 97;
           top:12px;
           left:14px;
           transition: left ease-in-out 0.3s;
           display:flex;
           background-color: white;
           border-radius: 4px;
           @apply --shadow-elevation-2dp;
         }
         #jobChooser {
           display: block;
           width: 300px;
           height: 48px;
           --paper-input-container-underline-display: block;
         }
         #welcomeJobChooser {
           flex-grow:1;
           --drop-down:{
             --paper-input-container-underline-display:initial;
           }
           --drop-down-arrow: {
             border-top: 5px solid #007299;
           }
           --job-chooser-divider: {
             display:none;
           }
         }
         .warning {
           color: #D23F31;
         }
         #toolsetChooser {

           color: white;
           text-align: center;
           font-variant-caps: all-small-caps;
           background-color: var(--primary-color);
           padding: 5px 0px 2px 0px;
           overflow: hidden;
           flex-shrink: 0;
         }

         #toolsetToggle {
           margin: 0;
           font-size: 11px;
           height: 30px;
           background-color: var(--secondary-color);
           border-radius: 0;
           color: white;
           font-weight: normal;
         }
         #toolsetToggleIcon {
           width: 20px;
           margin: 0;
           padding: 0;
         }

         #toolsetChooserMenu {
           padding: 0;
         }
         #toolsetChooserMenu katapult-button {
           margin: 0;
           padding: 0;
         }
         #toolsetChooserMenu iron-icon {
           --iron-icon-height: 18px;
           --iron-icon-width: 20px;
           margin: 0;
           padding-left: 3px;
         }
         #toolsetMenu {
           color: black;
           text-align: left;
           font-variant: normal;
           box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.24);
           --paper-menu-selected-item: {
             background: green;
           }
         }
         #toolsetMenu paper-item {
           padding:0px 16px;
         }
         #toolsetMenu paper-item:hover {
           background-color: #efefef;
         }
         #myLocation {
           position:absolute;
           bottom: 24px;
           right: 1em;
           width: 28px;
           height: 28px;
           padding: 4px;
           color: #555;
           background: #fff;
           border-radius: 2px;
         }
         #myLocation.read {
           right: 1em;
         }
         #myLocation.write {
           right: calc(2em + 95px);
         }
         #myLocation.write.editorOpen {
           right: calc(2em + 550px);
         }
         #myLocation.read.editorOpen {
           right: calc(1em + 455px);
         }
         #myLocation #ripple {
           color: #444;
         }

         [drawer] {
           background-color: white;
           overflow: auto;
           height:100%;
         }

         #logo {
           width: 75%;
           opacity: 0;
           position: relative;
           margin: 0;
           margin-top: 0;
           transition: width 0.18s ease-in, margin-top 0.18s ease-in, opacity 0.18s ease-in;
         }

         .tall #logo {
           opacity: 1;
           width: 100%;
           margin-top: 7em;
         }

         #katapultLogo {
           width: 87%;
           margin-top: 30px;
           display: block;
         }

         #userEmail {
           font-size: 11pt;
           margin-top: 4em;
           width: 0;
           opacity: 0;
           overflow: hidden;
           transition: opacity 0.18s ease-in;
         }

         .tall #userEmail {
           width: 100%;
           opacity: 1;
           margin-top: 11em;
           transition: width 0.18s ease-in, opacity 0.18s ease-in;
         }

         paper-dialog #qcDialog  {
           position: absolute;
           left: 1em;
           bottom: 1em;
           max-height: 400px;
           min-height: 280px;
           max-width: 500px;
           min-width: 350px;
         }
         paper-tabs {
           --paper-tabs-selection-bar-color: #1b3c52;
         }
         paper-tab {
           --paper-tab-ink: #1b3c52;
         }

         #passwordLogoutButton {
           position: absolute;
           min-width: 0;
           margin-top: -37px;
           right: 8px;
         }
         #customAuthLogout {
           text-decoration:underline;
           cursor:pointer;
           font-size: 11pt;
           position: absolute;
           top: 4px;
           right: 10px;
           z-index:2;
         }
         #logoutText {
           width: 0;
           overflow: hidden;
           transition: width 0.18s ease-in;
         }

         .tall #logoutText {
           width: 64px;
         }

         .menuDrawerIcon {
           margin: var(--sl-spacing-x-small);
         }

         .menuDrawerIcon.faded {
           opacity: 0.6;
         }

         .menuDrawerIcon.hover:hover {
           cursor: pointer;
         }

         .menuDrawer {
           margin-left: 1.5em;
         }

         #searchIcon {
           margin: 4px;
         }

         .searchBox {
           width: 224px;
           margin: -10px auto 0 auto;
         }

         #main {
           outline: none;
           height:100%;
           position: relative;
         }
         #moreContextInfoDrawer {
           margin-left: 20px;
           margin-right: 5px;
           font-size: 11pt;
         }
         .contextInfoLink {
           margin: 3px;
         }
         .contextInfoItem {
           margin-top:2px;
         }
         .contextInfoTitle {
           font-style:italic;
         }
         .inputLine {
           display: flex;
           flex-direction: row;
         }

         #dragCount {
           z-index: -1;
           width: 15px;
           height: 15px;
           border-radius: 10px;
           color: white;
           background-color: red;
         }
         #photoStream {
           position: fixed;
           width: 325px;
           height: calc(100% - 40px - 2.25em);
           top: calc(40px + 1.5em);
           left: 3em;
           z-index: 2;
           display:none;
           background-color:white;
           border:none;
           resize:horizontal;
         }

         #photoPanel {
           background-color: white;
           position: absolute;
           width: 275px;
           height: calc(100% - 40px - 2.25em);
           top: calc(40px + 1.5em);
           left: 1em;
           z-index: 2;
           overflow-y: auto;
           overflow-x: hidden;
         }

         #poleCount {
           display: flex;
           white-space: nowrap;
           align-items: center;
           border-radius: 80px;
           background-color: var(--paper-red-500);
           color: white;
           padding: 4px 35px 4px 35px;
           justify-self: flex-end;
           font-size: 11pt;
           margin: 4px;
         }
         #poleCount > span {
           text-align: center;
         }

         #poleCount.red {
           background-color: var(--paper-red-500);
           color: white;
         }

         #poleCount.green {
           background-color: var(--paper-green-500);
         }

         #poleCount.yellow {
           background-color: var(--paper-yellow-500);;
         }

         #showNodeBreakdown {
           cursor: pointer;
           /* padding: .5em 0 0 .5em; */
           margin-left: 0.5em;
         }

         .photoGroupLabel {
           background-color: #00a2e5;
           margin-bottom: 5px;
         }

         .photoGroupLabel::shadow .button-content {
           padding: 0.5em 1em;
         }

         .unassociatedPhotos {
           background-color: #D23F31;
           color: white;
         }
         #deliverablePhoto {
           z-index: 2;
           @apply --shadow-elevation-2dp;
           width: 500px;
         }
         #deliverablePhoto[animate] {
           transition: margin 0.3s;
         }
         #deliverablePhoto[disabled] katapult-photo-viewer {
           overflow: hidden;
         }
         #deliverablePhoto .handle {
           position: absolute;
           top: calc(50% - 24px);
           right: -24px;
           height: 48px;
           width: 24px;
           border-radius: 0 24px 24px 0;
           background-color: white;
           transition: right 0.3s;
         }
         #deliverablePhoto .handle:hover {
           cursor: pointer;
         }
         #deliverablePhoto .handle iron-icon {
           height: inherit;
           margin-left: -4px;
           transition: all 0.3s;
         }
         #deliverablePhoto .handle iron-icon[rotate=""] {
           transform: rotate(180deg);
         }
         #deliverablePhoto .handle[disabled=""] {
           right: 0;
         }
         #deliverablePhoto katapult-photo-viewer {
           /* display: flex;
           justify-content: center; */
           height: 100%;
           width: 100%;
           /* --katapult-photo: {
             width: auto;
             height: auto;
           }; */
         }
         #deliverablePhoto .photoButtons {
           position: absolute;
           background: #00BCD4;
           padding: 4px;
           width: 32px;
           height: 32px;
           top: calc(50% - 20px);
         }
         #deliverablePhoto .photoButtons[disabled] {
           background: #e0e0e0;
           color: #9e9e9e;
         }
         katapult-photo-chooser {
           z-index: 100;
         }
         .fitParent {
           position: absolute;
           top: 0;
           left: 0;
           height: 100%;
           width: 100%;
         }
         .legendCheckbox {
           --paper-checkbox-size:14px;
         }
         .legendItem {
           display:flex;
           align-items:center;
           margin:5px 0;
         }
         .legendIconContainer {
           display:flex;
           margin-right:8px;
           justify-content:center;
           align-items:center;
           position:relative;
           text-align:center;
           width:24px;
           height:24px;
         }
         .legendIconContainer > .section {
           top:5px;
           left:5px;
         }
         .legendIconContainer > .referenceNode {
           margin-left:0;
         }
         #zoomToLocationDialogInput {
           width: 300px;
         }
         #jobFeedbackDialog {
           width: 500px;
         }
         #jobFeedbackDialog a {
           display: block;
         }
         katapult-button[dialog-confirm] {
           float: right;
           /*margin-right: 5px;*/
           background-color: var(--secondary-color);
           color: #fff;
         }
         katapult-button[dialog-dismiss] {
           float: left;
           /*margin-left: 5px;*/
         }
         katapult-button[disabled] {
           background: #eaeaea;
           color: #a8a8a8;
           cursor: auto;
           pointer-events: none;
         }
         katapult-button:not([iconOnly]) {
           min-width: 75px;
         }
         #warningToast {
           background: #DDD;
           color:black;
           border:2px solid #FF8300;
         }
         #linkMapPhotoData paper-input::shadow #input {
           text-align: center;
         }
         @media all and (min-width: 601px) {
           #confirmDialog, #mapLayersManagerDialog {
             width:600px;
           }
         }
         .poleListItem {
           padding:0;
         }
         .nodeHover {
           box-shadow:0 0 20px 20px #15C517;
         }
         paper-item:hover, paper-toggle-button:hover, paper-checkbox:hover {
           cursor: pointer;
         }
         .dialogTitle {
           display:flex;
           align-items:flex-start;
           justify-content:flex-start;
         }
         .subheader {
           margin: 0px;
         }
         #flexNameToggle {
           display:flex;
           justify-content:space-between;
           align-items:center;
         }
         #editJobName {
           width: calc(100% - 160px);
         }
         #editJobNameInputCheck {
           color: #E0E0E0;
           transition: transform 0.4s, color 0.1s;
           transform: scale(0);
         }
         #linkToFirebase {
           color: #666;
           font-weight: lighter;
           font-size: 12px;
           margin-left: auto;
         }
         .iconContainer {
           display: flex;
           justify-content: center;
           align-items: center;
           position: relative;
           margin-left: 25px;
           height: 24px;
           width: 24px;
           border-radius: 12px;
         }
         #breakdownDetails {
           flex-direction: column;
           display: flex;
           padding: 12px;
           transition: all 0.3s;
           border-top: 1px solid var(--paper-grey-200);
         }
         #nodeBreakdownContainer {
           background: white;
           align-self: flex-start;
           justify-content: center;
           align-items: center;
           width: inherit;
           box-sizing: border-box;
           transition: all 0.3s;
         }
         #nodeBreakdownContainer[opened] {
           box-shadow: var(--shadow-elevation-4dp_-_box-shadow);
           border-radius: 8px;
         }
         .titleIcon {
           color: #666;
           width: 20px;
           height: 20px;
           padding: 2px;
           cursor: pointer;
           transition: color 0.6s;
         }
         #deleteJobIcon:hover {
           color: #c62828;
         }
         #archiveJobIcon:hover {
           color: #f57c00;
         }
         #archiveJobIcon[icon="unarchive"]:hover {
           color: #8bc34a;
         }

         /* ------------ Warnings Dialog Styles ------------ */

         #warningsDialog {
           z-index: 3!important;
           height: 500px;
           width: 400px;
         }
         #warningsDialog katapult-button {
           color: var(--primary-text-color-faded);
         }
         #warningsDialog div.warningsDialogHeaderSection {
           display: flex;
           flex-direction: row;
         }
         #warningsDialog div.warningsDialogHeaderSection .warningsDialogHeader {
           flex-grow: 1;
           margin-top: 3px;
         }
         #warningsDialog div.warningsDialogHeaderSection .warningsDialogCloseButton {
           top: -6px;
         }
         #warningsDialog div.warningsDialogBodySection {
           max-height: 390px;
           overflow: auto;
         }
         #warningsDialog div.warningsDialogPreparingSection {
           text-align: center;
         }
         #warningsDialog div.warningsDialogPreparingSection paper-spinner {
           vertical-align: middle;
         }
         #warningsDialog div.warningsDialogErrorSection {
           color: var(--paper-red-500);
         }
         #warningsDialog div.warningsDialogItemSection {
           margin-top: 0px;
           margin-bottom: 0px;
         }
         #warningsDialog div.warningsDialogItemSection:nth-of-type(odd){
           margin-top: 0px;
           margin-bottom: 0px;
           background-color: #D3D3D3
         }
         #warningsDialog div.warningsDialogItemDescriptionSection {
           display: flex;
           align-items: center;
         }
         #warningsDialog div.warningsDialogItemDescriptionSection katapult-button {
           flex-shrink: 0;
         }
         #warningsDialog div.warningsDialogItemDescriptionSection span {
           white-space: pre-wrap;
         }
         #warningsDialog .warningsDialogWarningSection {
           display:flex;
           align-items: center;
           margin-bottom: 5px;
           margin-right: 3px;
         }
         #warningsDialog .warningsDialogHideWarningButtonContainer {
           width: auto;
           padding: 0px 10px
         }
         #warningsDialog .warningsDialogHideWarningButton {
           opacity: 0;
           transition: opacity 0.1s;
           flex-shrink:0;
           padding: 0;
           height: 24px;
         }
         #warningsDialog .warningsDialogWarningSection:hover .warningsDialogHideWarningButton {
           opacity: 1;
         }
         /* ------------ End QC Dialog Styles ------------ */

         #duplicateIcon:hover {
           color: #26c6da;
         }
         #formIcon:hover {
           color: #3F51B5;
         }
         #editMapStylesIcon {
           --katapult-button: {
             padding:0;
           }
         }
         #editMapStylesIcon:hover {
           color:#3f51b5;
         }
         #uploadPhotosIcon:hover {
           color: #4caf50;
         }
         #addFilesContainer {
           display:flex;
           justify-content:flex-start;
           align-items:center;
         }
         #addFilesButton {
           width: 16px;
           height: 16px;
           padding: 0;
           margin: 0 8px;
           color: #fff;
           background: #aaa;
           border-radius: 8px;
         }
         #clearFile {
           color: #666;
           border-radius: 20px;
           padding: 0;
           transition: color 0.6s;
         }
         #clearFile:hover {
           color: #c62828;
           outline: none;
         }
         .deleteFileIcon {
           color: #666;
           width: 20px;
           height: 20px;
           padding: 2px;
           cursor: pointer;
           transition: color 0.6s;
         }
         .deleteFileIcon:hover, .deleteFileIcon:focus {
           color: #c62828;
           outline: none;
         }
         .currentItems {
           display: flex;
           flex-direction: row;
           flex-wrap: wrap;
           justify-content: space-around;
         }
         #deleteConfirmCollapse {
           text-align: center;
         }
         #deleteConfirmCollapse paper-input {
           margin-left: calc(50% - 100px);
           margin-bottom: 10px;
           width: 200px;
         }
         #bugFeedbackDialog h2 {
           padding-bottom: 20px;
         }
         .photoLabel {
           position: absolute;
           width: 100%;
           left: 0px;
           bottom: 5px;
           text-align: center;
           color: white;
           font-size: 14pt;
           text-shadow:
            -1px -1px 0 black,
             1px -1px 0 black,
             -1px 1px 0 black,
              1px 1px 0 black;
         }
         .disclaimerSpacer {
           height:20px;
         }
         paper-radio-button.radioWhite {
           --paper-radio-button-checked-color: ##eeff41;
           --paper-radio-button-checked-ink-color: ##eeff41;
           --paper-radio-button-unchecked-color: #f1f1f1;
           --paper-radio-button-unchecked-ink-color: #f1f1f1;
           --paper-radio-button-label-color: #f1f1f1
         }
         #dragPhotoMenu {
           position:absolute;
           margin:0;
           display:none;
           z-index:10;
           background-color:white;
           overflow: hidden;
           border-radius: 8px;
           @apply --shadow-elevation-2dp;
         }
         #dragPhotoMenu paper-item iron-icon {
           margin-right: 8px;
           color: var(--primary-text-color-faded);
         }
         #dragPhotoMenu paper-item:hover {
           background-color: var(--paper-grey-200);
         }
         paper-dialog-scrollable#feedbackBody {
           --paper-dialog-scrollable: {
             max-height: 450px;
           }
         }
         .immediateDownload {
           width: 20px;
           height: 20px;
           padding: 2px;
           margin-left: 3em;
         }
         #photoToggle {
           width: 1em;
           height: 50px;
           position: absolute;
           background: #fff;
           z-index: 3;
           top: 4em;
           opacity:0.8;
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           transition: width 0.4s, opacity 0.4s;
           border-right: 1px solid #000;
           border-bottom: 1px solid #000;
           border-top: 1px solid #000;
           border-radius: 3px;
         }
         #photoToggle:hover {
           width: 2em;
           opacity: 1;
         }
         paper-dialog-scrollable#timeBucketBody {
           --paper-dialog-scrollable: {
             max-height: 350px;
           }
         }
         #pplQcDialog h4 {
           margin-bottom: 5px;
         }
         #jobFolder {
           cursor:pointer;
           display: flex;
           align-items:center;
           z-index:2;
         }
         #jobFolder iron-icon {
           color:#666;
           margin-right:10px;
         }
         #mapLayersManagerDialog h3 {
           font-size:12pt;
           font-weight:normal;
           padding-bottom:2px;
           margin-bottom:10px;
           border-bottom:1px solid #b1b1b1;
         }
         #mapLayersManagerDialog h3 iron-icon {
           margin-right:5px;
         }
         .mapLayersManagerGroup {
           margin:5px 25px 20px;
         }
         #openMapLayersFileInput {
           background-color:var(--primary-color);
           color:white;
           white-space:nowrap;
           flex-shrink:0;
         }
         .linkedJobName {
           display:inline-block;
           /*width:calc(100% - 175px);*/
           width:calc(100% - 160px);
         }
         .hiddenItem {
           visibility:hidden;
           pointer-events:none;
         }
         #multiJobSwitcher {
           width:100%;
           --paper-radio-group-item-padding:none;
         }
         paper-slider {
           --paper-slider-knob-color: var(--primary-color);
           --paper-slider-active-color: var(--primary-color);
           --paper-slider-pin-color: var(--primary-color);
         }
         #mainVertContainer {
           position: relative;
           display: flex;
           flex-direction: column;
           height: 100%;
           width: 100%;
         }
         #toolbar {
           z-index: 5;
         }
         #mainHorizContainer {
           position: relative;
           display: flex;
           flex-direction: row;
           flex-grow: 1;
           min-height: 0; /* Fixes a weird bug where this div overflows its parent due to deliverable photo */
         }
         #pinnedSearchArea {
           width:300px;
           height:100%;
         }
         #katapultMap {
           flex-grow: 1;
         }
         #stateButtons {
           white-space: nowrap;
           color: var(--primary-text-color-faded);
         }
         #stateButtons > * {
           border-radius: 24px;
           transition: all 0.1s;
           margin: 4px;
         }
         #stateButtons > *:hover {
           background-color: var(--paper-grey-100);
         }
         #stateButtons > [disabled] {
           color: var(--paper-grey-300);
         }
         #stateButtons > [name="lock"][isLocked] {
           color: var(--paper-red-500);
         }
         #stateButtons > [name="cableTrace"][active] {
           color: var(--paper-blue-500);
         }
         #stateButtons > [name="mrView"][active] {
           color: var(--paper-green-500);
         }
         #stateButtons > [name="kplaView"][active] {
           color: var(--paper-green-500);
         }
         #stateButtons > [name="printView"][active] {
           color: #a17eb4;
         }
         #poleList, #cuEntryNodeList {
           --slot-content: {
             flex-direction: column;
           };
           --list-item: {
             flex-direction: row-reverse;
           };
         }
         #layersDialogWrapper {
           position: absolute;
           pointer-events: none;
           z-index: 5;
           width: 100%;
           height: 100%;
         }
         #layersDialog {
           position: absolute;
           pointer-events: all;
           display:none;
           background-color:white;
           border-radius: 8px;
           width:275px;
           overflow-y:auto;
           overflow-x:hidden;
           box-shadow:var(--shadow-elevation-4dp_-_box-shadow);
         }
         #layersDialog > paper-item {
           display:flex;
         }
         #layersDialog iron-icon {
           color: var(--primary-text-color-faded);
         }
         .toggleIcon {
           transition:transform 0.3s;
         }
         #openJobDialogue, #downloadButton, #sendFeedbackButton {
           margin: 4px 0;
           color:var(--primary-text-color-faded);
         }
         .labelItem {
           padding: 10px 20px;
           display: flex;
           justify-content: space-between;
           align-items: center;
         }
         .labelItem > span {
           flex-grow: 1;
         }
         .buttons {
           display: flex;
           justify-content: flex-end;
           padding: 16px;
         }
         h2 {
           font-size: 14pt;
           color: var(--primary-text-color);
           font-weight: normal;
         }
         .dialogSubHeader {
           margin:15px 0 10px;
         }
         .dialogStatusText {
           color:gray;
           font-style:italic;
           position:absolute;
           left:24px;
         }
         .dialogErrorText {
           color: var(--paper-red-500);
           position:absolute;
           left:24px;
         }

         #fullscreenPhoto {
             z-index: 1001;
             overflow: hidden;
             --iron-overlay-backdrop-background-color: white;
             --iron-overlay-backdrop-opacity: 1;
         }
         #fullscreenPhoto katapult-photo-viewer {
             width: 100%;
             height: 100%;
         }
         #fullscreenPhoto:focus {
             outline:0;
         }
         #fullscreenPhotoButtons {
          top: 0;
          right: 0;
          padding: 1em;
          gap: 0.5em;
          display: flex;
          flex-direction: row-reverse;
         }
         #fullscreenPhotoButtons, #fullPhotoLeft, #fullPhotoRight {
          position: absolute;
          z-index: 2;
         }
         #fullPhotoLeft {
             left: 1em;
             top: 50%;
             margin-top: -20px;
         }
         #fullPhotoRight {
             right: 1em;
             top: 50%;
             margin-top: -20px;
         }
         .filterItem {
           position: relative;
           padding: 12px;
           border-radius: 4px;
           display: flex;
           justify-content: center;
           align-items: center;
         }
         .filterItem:hover {
           background-color: var(--paper-grey-100);
         }
         .filterItem > span {
           font-weight: bold;
           margin-right: 8px;
           color: var(--paper-grey-600);
         }
         .filterItem input-element {
           flex-grow : 1;
           --input-element-paper-checkbox-checkmark-color: white;
         }
         .filterItem > katapult-button[icon] {
           color: var(--primary-text-color-faded);
           flex-shrink: 0;
         }
         katapult-search {
           --katapult-search-filter-toggle: {
             transition: color 0.3s;
           };
         }
         katapult-search:not([filters-active="0"]) {
           --katapult-search-filter-toggle: {
             transition: color 0.3s;
             color: var(--paper-green-500);
           };
         }
         katapult-query-builder {
           --katapult-query-builder-paper-table: {
             border: none;
             border-radius: 0;
           };
         }
         #loadCrumbTrailsButton {
           color: var(--primary-text-color-faded);
           --katapult-button-paper-spinner-color: var(--primary-text-color-faded);
         }
         #notificationDialogContainer {
           /* background: red; */
           display: flex;
           justify-content: center;
           position: absolute;
           left: 0;
           top: 0;
           width: 100%;
           z-index: 4;
           pointer-events: none;
         }
         #notificationDialog {
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           padding: 12px;
           margin: 0 16px;
           background-color: white;
           border-radius: 0 0 8px 8px;
           min-width: 260px;
           pointer-events: all;
           transition: all 0.3s;
           transform: translateY(-120px);
           @apply --shadow-elevation-4dp;
         }
         #notificationDialog > div {
           display: flex;
           justify-content: center;
           align-items: center;
           width: 100%;
           box-sizing: border-box;
         }
         #notificationDialog[opened=""] {
           transform: translateY(0px) !important;
         }
         #notificationDialogTitle {
           position: relative;
           text-transform: uppercase;
           color: var(--primary-text-color-faded);
           padding: 0 48px;
         }
         #notificationProgressBar {
           border: 1px solid var(--paper-grey-400);
           box-shadow: none;
           border-radius: 20px;
           --background-color: var(--primary-color);
           --progress-background-color: var(--secondary-color);
           --progress-secondary-background-color: var(--primary-color);
           --progress-secondary-color: white;
           height: 20px;
           --primary-text-color: white;
           width: 175px;
           margin: 0 20px;
         }
         #uploadMessage {
           color: var(--primary-text-color-faded);
           margin: 20px 0 0;
           font-size: 14px;
         }
         .masterLocationDirectoryContainer {
           display: flex;
           margin-left:10px;
           margin-bottom:8px;
         }
         #projectFolderPanelLoading {
           display: flex;
           align-items: center;
           padding: 10px 0;
         }
         #projectFolderPanelLoading paper-spinner-lite {
           --paper-spinner-color: var(--secondary-color);
           height: 22px;
           width: 22px;
           margin: 0 8px 0 2px;
         }
         .snapshotInfo {
           padding: 10px;
           border-bottom-right-radius: 10px;
           background-color: white;
           position: absolute;
           top: 0;
           left: 0;
           z-index: 4;
           display: flex;
           align-items: center;
         }
         .companyNameFixRow {
           display: flex;
           align-items: center;
           justify-content: space-between;
           padding:5px;
         }
         .companyNameFixRow:first-of-type {
           margin-top:20px;
         }
         .companyNameFixRow[unchanged] {
           background-color:var(--paper-grey-200);
         }
         .companyNameFixRow > [name="save"][active] {
           color: var(--primary-color);
         }
         #toast {
           display: flex;
           align-items: center;
         }
         sl-tree-item::part(base), sl-tree-item::part(label) {
          width: -webkit-fill-available;
         }
      </style>
      <!-- TODO: this dialog needs to be converted to a katapult-dialog so that it can be a proper modal -->
      <paper-dialog id="noPermissionDialog" no-cancel-on-outside-click no-cancel-on-esc-key>
        <div title amber>Permission Required</div>
        <div body>You do not have permission to view survey yet</div>
      </paper-dialog>

      <!-- Color Picker -->
      <katapult-color-picker-dialog></katapult-color-picker-dialog>
      <!--Confirm Dialog-->
      <paper-dialog
        id="confirmDialog"
        layered="false"
        entry-animation="scale-up-animation"
        exit-animation="fade-out-animation"
        on-iron-overlay-canceled="cancelPromptAction"
        opened="{{confirmDialogOpened}}"
        style$="[[confirmDialogStyle]]"
      >
        <template is="dom-if" if="[[confirmDialogTitle]]">
          <div title secondary-color>[[confirmDialogTitle]]</div>
        </template>
        <paper-dialog-scrollable id="confirmDialogScrollable">
          <h2>{{confirmDialogHeading}}</h2>
          <div style="display:table; min-height:138px; width:100%;">
            <span id="confirmBody" style="display:table-cell; vertical-align:middle;">
              <span id="confirmDialogBody"></span>
              <!--Dialog Templates-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'setMakeReadyState')}}">
                <p>
                  This will <strong>overwrite</strong> any manual changes to <strong>mr_violation</strong> and
                  <strong>mr_state</strong> attributes on nodes and sections
                </p>
                <p>Do you wish to continue?</p>
                <paper-toggle-button checked="{{includeGO95}}">Include GO 95</paper-toggle-button>
              </template>
              <!--Multi Add Attribute-->
              <template is="dom-if" if="{{isMultiAddAttribute(confirmDialogBodyType)}}">
                <div style="display: flex; flex-direction: column;">
                  <katapult-drop-down
                    items="[[getAttributeKeysForTypes(otherAttributes, multiAddAttributeTypes)]]"
                    on-selected-changed="multiAddAttributesSelectedChanged"
                    label="Choose an Attribute"
                    other-attributes="[[otherAttributes]]"
                    label-function="[[getAttributeNameLabel]]"
                  >
                    <paper-item on-click="openModelEditor">Add or Manage Attributes</paper-item>
                  </katapult-drop-down>
                  <div class="dialogSubHeader">Action to take:</div>
                  <paper-radio-group selected="{{multiAddAttributeOverwrite}}">
                    <paper-radio-button name="add">Add</paper-radio-button>
                    <paper-radio-button name="overwrite">Overwrite</paper-radio-button>
                    <paper-radio-button name="delete">Remove</paper-radio-button>
                    <template is="dom-if" if="{{multiAddAttributeUpdate}}">
                      <paper-radio-button name="update">Update</paper-radio-button>
                    </template>
                  </paper-radio-group>
                  <template is="dom-if" if="{{!equal(multiAddAttributeOverwrite, 'delete')}}">
                    <iron-collapse opened="[[multiAddAttributes.length]]">
                      <div class="dialogSubHeader">Set the value:</div>
                      <div style="max-height: 300px; overflow-y: auto;">
                        <template is="dom-repeat" items="[[multiAddAttributes]]" on-dom-change="refitConfirmDialog">
                          <input-element
                            style="flex-grow:1; flex-shrink:2; display:block;"
                            attribute_name="{{item.name}}"
                            model="{{getItem(otherAttributes, item.name)}}"
                            disabled="[[!equal(_sharing, 'write')]]"
                            hide-on-disabled=""
                            firebase-disabled="[[!signedIn]]"
                            other-attributes="[[otherAttributes]]"
                            value="{{item.value}}"
                            no-label-float=""
                            job-creator="[[jobCreator]]"
                          ></input-element>
                          <template is="dom-if" if="[[showPoleTagWarning(item)]]">
                            <div style="font-style: italic; margin-top: 8px;">
                              * Warning: Application pole tags will not be modified by this tool. Please use the portal or Add Pole to
                              Application feature to edit the pole tag.
                            </div>
                          </template>
                        </template>
                      </div>
                    </iron-collapse>
                  </template>
                  <template is="dom-if" if="{{equal(multiAddAttributeOverwrite,'delete')}}">
                    <div class="dialogSubHeader">Delete action type:</div>
                    <paper-radio-group selected="{{deleteInstance}}">
                      <paper-radio-button name="instance">Instance of attribute</paper-radio-button>
                      <paper-radio-button name="all">All</paper-radio-button>
                    </paper-radio-group>
                    <template is="dom-if" if="{{equal(deleteInstance,'instance')}}">
                      <iron-collapse opened="[[multiAddAttributes.length]]">
                        <div class="dialogSubHeader">Instance to delete:</div>
                        <div style="max-height: 300px; overflow-y: auto;">
                          <template is="dom-repeat" items="[[multiAddAttributes]]" on-dom-change="refitConfirmDialog">
                            <input-element
                              style="flex-grow:1; flex-shrink:2; display:block;"
                              attribute_name="{{item.name}}"
                              model="{{getItem(otherAttributes, item.name)}}"
                              disabled="[[!equal(_sharing, 'write')]]"
                              hide-on-disabled=""
                              firebase-disabled="[[!signedIn]]"
                              other-attributes="[[otherAttributes]]"
                              value="{{item.value}}"
                              no-label-float=""
                              job-creator="[[jobCreator]]"
                            ></input-element>
                            <template is="dom-if" if="[[showPoleTagWarning(item)]]">
                              <div style="font-style: italic; margin-top: 8px;">
                                * Warning: Application pole tags will not be modified by this tool. Please use the portal or Add Pole to
                                Application feature to edit the pole tag.
                              </div>
                            </template>
                          </template>
                        </div>
                      </iron-collapse>
                    </template>
                    <template is="dom-if" if="[[equal(deleteInstance,'all')]]">
                      <template is="dom-if" if="[[showPoleTagWarning(multiAddAttributes)]]">
                        <div style="font-style: italic; margin-top: 8px;">
                          * Warning: Application pole tags will not be modified by this tool. Please use the portal or Add Pole to
                          Application feature to edit the pole tag.
                        </div>
                      </template>
                    </template>
                  </template>
                  <div class="dialogSubHeader">Items to edit:</div>
                  <div style="display:flex; justify-content:space-around; margin-bottom: 16px;">
                    <paper-checkbox checked="{{multiAddAttributeItemsToEdit.nodes}}">Nodes</paper-checkbox>
                    <paper-checkbox checked="{{multiAddAttributeItemsToEdit.connections}}">Connections</paper-checkbox>
                    <paper-checkbox checked="{{multiAddAttributeItemsToEdit.sections}}">Sections</paper-checkbox>
                  </div>
                </div>
              </template>
              <!--Master Location Directory Check In-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'checkIntoMasterLocationDirectories')}}">
                <br />
                <br />
                <template is="dom-repeat" items="[[masterLocationDirectories]]" as="locationDirectory">
                  <paper-checkbox class="includeMasterLocationDirectoryCheckbox" name="[[locationDirectory._id]]">
                    [[locationDirectory.name]]
                  </paper-checkbox>
                  <br /><br />
                </template>
                <paper-spinner style="margin-bottom:15px;" id="shareJobCheckInJobsToMasterLocationDirectoriesSpinner"></paper-spinner>
              </template>
              <!--Master Location Directory Chooser-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'masterLocationDirectoryChooser')}}">
                <katapult-drop-down
                  label="Select Master Location Directory"
                  items="[[mldList]]"
                  value="{{selectedMLD}}"
                  label-path="name"
                  value-path="_id"
                  on-value-changed="masterLocationDirectorySelected"
                ></katapult-drop-down>
                <p style="color:gray; font-style:italic; margin-bottom:5px; margin-top:2px;">
                  Master Location Directories can be managed from the Job Settings Gear
                </p>
                <template is="dom-if" if="{{inTransferByPolygonMode}}">
                  <br />
                  <span>Enter the name for the job to be created and transferred:</span>
                  <paper-input label="New Job Name" value="{{transferByPolygonJobName}}"></paper-input>
                </template>
                <template is="dom-if" if="{{multiSelectMLD}}">
                  <template is="dom-if" if="{{!equal(selectedMLDs.length, 0)}}">
                    <h2 style="margin-bottom: 0px">Selected Master Location Directories</h2>
                  </template>
                  <template is="dom-repeat" items="[[selectedMLDs]]" as="locationDirectory">
                    <div style="display: flex; flex-direction: row">
                      <katapult-drop-down
                        items="[[mldList]]"
                        value="{{locationDirectory}}"
                        label-path="name"
                        value-path="_id"
                        on-value-changed="masterLocationDirectoryChanged"
                        style="width:90%"
                        noLabelFloat
                      ></katapult-drop-down>
                      <katapult-button iconOnly noBorder icon="delete" on-click="removeMasterLocationDirectory"></katapult-button>
                    </div>
                  </template>
                </template>
                <template is="dom-if" if="[[confirmDialogPromptForUniqueIds]]">
                  <paper-checkbox checked="{{useUniqueIdsForMLD}}">Use unique ids when available</paper-checkbox>
                </template>
              </template>
              <!--Address Data-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'addAddressData')}}">
                <template is="dom-if" if="{{!hideAddressOptions}}">
                  <div class="dialogSubHeader" style="margin-top:30px;">Options:</div>
                  <div style="display:flex; justify-content:flex-start; flex-wrap:wrap;">
                    <paper-checkbox checked="{{addIndividualAddressAttr}}" style="margin: 7px"
                      >Add individual address attributes</paper-checkbox
                    >
                    <paper-checkbox checked="{{addFormattedAddressAttr}}" style="margin: 7px"
                      >Add 'Formatted Address' attribute</paper-checkbox
                    >
                    <paper-checkbox checked="{{prioritizeStreetNumber}}" style="margin: 7px">Prioritize Street Number</paper-checkbox>
                    <paper-checkbox checked="{{stateAbbreviation}}" style="margin: 7px">Abbreviate State Name</paper-checkbox>
                  </div>
                  <template is="dom-if" if="{{addIndividualAddressAttr}}">
                    <div class="dialogSubHeader" style="margin-top:30px;">Individual Address Attributes:</div>
                    <div style="display:flex; justify-content:flex-start; flex-wrap:wrap;">
                      <paper-checkbox checked="{{addressAttributes.street_number}}" style="margin: 7px">Street Number</paper-checkbox>
                      <paper-checkbox checked="{{addressAttributes.street_name}}" style="margin: 7px">Street Name</paper-checkbox>
                      <paper-checkbox checked="{{addressAttributes.township}}" style="margin: 7px">Township</paper-checkbox>
                      <paper-checkbox checked="{{addressAttributes.municipality}}" style="margin: 7px">Municipality</paper-checkbox>
                      <div style="width:100%"></div>
                      <paper-checkbox checked="{{addressAttributes.county}}" style="margin: 7px">County</paper-checkbox>
                      <paper-checkbox checked="{{addressAttributes.state}}" style="margin: 7px">State</paper-checkbox>
                      <paper-checkbox checked="{{addressAttributes.zip_code}}" style="margin: 7px">Zip Code</paper-checkbox>
                    </div>
                  </template>
                </template>
              </template>
              <!--Fix company names-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'fixCompanyNames')}}">
                <template is="dom-repeat" items="[[companyNameMatches]]">
                  <div class="companyNameFixRow" unchanged$="[[equal(item.company, item.toCompany)]]">
                    <div style="width:200px; margin-right:10px;">[[item.company]]</div>
                    <katapult-drop-down
                      style="width:300px"
                      label="Company"
                      items="[[fixCompanyList]]"
                      value-path="value"
                      label-path="value"
                      value="{{item.toCompany}}"
                      no-label-float
                    ></katapult-drop-down>
                  </div>
                </template>
              </template>
              <!--Copy Nodes-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'copyNodes')}}">
                <job-chooser
                  alphabetical="[[orderJobsAlphabetically]]"
                  style="--paper-input-container-underline-display: block;"
                  selected="{{copyNodesJobId}}"
                  model-options="[[modelOptions]]"
                  user-group="{{userGroup}}"
                  label="Select a Job to copy nodes to"
                  no-create-new-job="{{readOnlyUser}}"
                  firebase-disabled="[[!confirmDialogOpened]]"
                ></job-chooser>
                <div style="padding-top: 12px; display: flex;">
                  <div style="flex: 1;">
                    <div style="text-transform: uppercase; font-weight: bold;">Copy Type</div>
                    <paper-radio-group selected="{{copyMoveAction}}" style="display: block;">
                      <paper-radio-button name="Copy" style="padding: 8px 0;"
                        ><iron-icon icon="content-copy"></iron-icon> Copy &amp; Paste</paper-radio-button
                      >
                      <paper-radio-button name="Move" style="padding: 8px 0;"
                        ><iron-icon icon="content-cut"></iron-icon> Cut &amp; Paste</paper-radio-button
                      >
                    </paper-radio-group>
                  </div>
                  <div style="flex: 1;">
                    <div style="text-transform: uppercase; font-weight: bold;">Include</div>
                    <paper-checkbox style="display: block; padding: 8px 0;" checked="{{copyConnsToJob}}">Connections</paper-checkbox>
                    <paper-checkbox style="display: block; padding: 8px 0;" checked="{{copyPhotosToJob}}">Photos</paper-checkbox>
                    <template is="dom-if" if="[[modelConfig.cus]]">
                      <paper-checkbox style="display: block; padding: 8px 0;" checked="{{copyCUsToJob}}">CU's</paper-checkbox>
                    </template>
                  </div>
                  <div style="flex: 1;">
                    <div style="text-transform: uppercase; font-weight: bold;">Other Options</div>
                    <template is="dom-if" if="{{appHasPortal()}}">
                      <paper-checkbox style="display: block; padding: 8px 0;" checked="{{addPolesToApp}}">Add Poles to APP</paper-checkbox>
                    </template>
                    <template is="dom-if" if="[[equal(userGroup, 'katapult')]]">
                      <paper-checkbox
                        hidden$="[[equal(copyMoveAction, 'Move')]]"
                        style="display: block; padding: 8px 0;"
                        checked="{{moveActionsWithCopy}}"
                        >Move actions and feedback to target job</paper-checkbox
                      >
                      <paper-checkbox hidden$="[[equal(copyMoveAction, 'Copy')]]" style="display: block; padding: 8px 0;" checked disabled
                        >Move actions and feedback to target job</paper-checkbox
                      >
                    </template>
                    <paper-checkbox style="display: block; padding: 8px 0;" checked="{{onlyCopyNodesWithFeedback}}"
                      >Only select nodes with Feedback</paper-checkbox
                    >
                  </div>
                </div>
              </template>
              <!--Link Map Photo Data-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'createSnapshot')}}">
                <paper-input id="snapshotName" value="{{newSnapshotName}}" label="Snapshot Name" on-input="isSnapshotValid"></paper-input>
                <template is="dom-if" if="{{showMidspanOptionForPCI(preppingForPostConstruction, enabledFeatures.pci_midspans)}}">
                  <katapult-drop-down
                    label="Select map styles"
                    value="{{snapshotMapStylesKey}}"
                    value-path="key"
                    label-path="label"
                    items="[[mapStylesListPCI]]"
                  ></katapult-drop-down>
                  <p>Note: The current job will use these styles when the preparation is complete.</p>
                </template>
                <template is="dom-if" if="[[equal(userGroup, 'katapult')]]">
                  <div style="display:flex; align-items:center; justify-content:space-between; flex-wrap:wrap;">
                    <paper-checkbox style="width:200px; margin: 10px 0px" checked="{{newSnapshotNumbers}}"
                      >New WR/WO Numbers</paper-checkbox
                    >
                  </div>
                </template>
                <template is="dom-if" if="{{showMidspanOptionForPCI(preppingForPostConstruction, enabledFeatures.pci_midspans)}}">
                  <paper-checkbox style="width:200px; margin: 10px 0px" checked="{{includeMidspansForPCI}}"
                    >Include Midspans</paper-checkbox
                  >
                </template>
                <template is="dom-if" if="[[!trackingDisabled]]">
                  <paper-checkbox style="width:200px; margin: 10px 0px" checked="{{disableTrackingSnapshot}}"
                    >Disable Attribute Tracking in Snapshot</paper-checkbox
                  >
                </template>
              </template>

              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'copyDataFromLayer')}}">
                <paper-checkbox on-change="copyDataFromLayerMasterCheckboxChanged">Delete Job Data Before Copy</paper-checkbox>
                <br />
                <br />

                <template is="dom-repeat" items="[[copyDataFromLayerJobs]]">
                  <div style="display:flex; flex-direction: row; margin-bottom: 15px">
                    <paper-checkbox checked="{{item.wipeData}}"></paper-checkbox>
                    <div>
                      <template is="dom-if" if="{{!equal(userGroup, 'techserv')}}">
                        <span>Copied to {{item.record.name}}</span><br />
                      </template>
                      <template is="dom-if" if="{{equal(userGroup, 'techserv')}}">
                        <span>Target Grid: {{item.record.name}}</span><br />
                      </template>
                      <span style="margin-left: 15px;">Nodes: {{item.record.nodes}}</span><br />
                      <span style="margin-left: 15px;">Connections: {{item.record.connections}}</span><br />
                      <span style="margin-left: 15px;">References Created: {{item.record.referenceConnections}}</span><br />
                    </div>
                    <template is="dom-if" if="{{equal(userGroup, 'techserv')}}">
                      <job-chooser
                        alphabetical
                        filter-by-name="{{item.record.name}}"
                        style="margin: 0 0 0 80px; vertical-align: top;"
                        model-options="[[modelOptions]]"
                        selected="{{item.record.targetJobId}}"
                        user-group="{{userGroup}}"
                        default-text="Target Job"
                        no-create-new-job="{{readOnlyUser}}"
                        hide-folder-chooser
                        underline
                        label-float
                        firebase-disabled="[[!confirmDialogOpened]]"
                      ></job-chooser>
                    </template>
                  </div>
                </template>
              </template>

              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'linkMapPhotoData')}}">
                <div id="linkMapPhotoData">
                  <div style="display:flex; align-items:center; justify-content:space-between; flex-wrap:wrap">
                    <paper-checkbox style="width:200px;margin:10px" checked="{{linkDownguys}}">Link Downguys To Anchors</paper-checkbox>
                    <paper-checkbox style="width:200px;margin:10px" checked="{{enterHardwareAngles}}">Enter Hardware Angles</paper-checkbox>
                    <paper-checkbox style="width:200px;margin:10px" checked="{{enterPowerSpec}}">Enter Power Cable Spec</paper-checkbox>
                    <paper-checkbox style="width:200px;margin:10px" checked="{{enterCrossArmLengths}}"
                      >Enter Cross Arm Lengths</paper-checkbox
                    >
                    <paper-checkbox style="width:200px;margin:10px" checked="{{doStreetLightLengths}}"
                      >Enter Street Light Lengths</paper-checkbox
                    >
                    <paper-checkbox style="width:200px;margin:10px" checked="{{doEquipmentSizes}}">Enter Equipment Spec</paper-checkbox>
                  </div>
                  <div style="margin-top:10px;">
                    <span hidden$="{{HWDetailsSinglePole}}">
                      <paper-input
                        style="display:inline-block; width:66px; margin-right:10px; text-align:center;"
                        value="{{startingLinkingOrderingAttribute}}"
                        label$="Start at [[modelDefaults.ordering_attribute_label]]"
                      ></paper-input>
                      <paper-input
                        style="display:inline-block; width:66px; margin-right:10px; text-align:center;"
                        value="{{endLinkingOrderingAttribute}}"
                        label$="End at [[modelDefaults.ordering_attribute_label]]"
                      ></paper-input>
                    </span>
                    <paper-checkbox style="width:200px;margin-left:10px" checked="{{skipDoneMapPhotoLinks}}"
                      >Skip Completed Items</paper-checkbox
                    >
                  </div>
                </div>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'mrView')}}">
                <paper-checkbox checked="{{mrViewNewCable}}">Include space for new cable 12" above existing.</paper-checkbox>
                <div style="margin-top:10px;">
                  <span>Insert warnings into:</span>
                  <paper-radio-group selected="{{mrViewProperty}}">
                    <paper-radio-button name="warning">Warning</paper-radio-button>
                    <paper-radio-button name="note">Note</paper-radio-button>
                  </paper-radio-group>
                </div>
                <div style="display:flex; align-items:center;">
                  <paper-checkbox checked="{{mrViewOnlyOwner}}">Only flag companies owned by:</paper-checkbox>
                  <katapult-drop-down
                    style="display:block; margin:10px;"
                    value="{{mrViewPoleOwner}}"
                    value-path="value"
                    label-path="value"
                    items="[[getPicklist(otherAttributes.pole_tag)]]"
                    show-last-results=""
                  ></katapult-drop-down>
                </div>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'mrEstimateStatus')}}">
                <div style="margin:10px 0;">
                  <paper-radio-group selected="{{mrEstimateStatus}}">
                    <paper-radio-button name="before">Before MR Estimate</paper-radio-button>
                    <paper-radio-button name="after">After MR Estimate</paper-radio-button>
                  </paper-radio-group>
                </div>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'drawPolygon')}}">
                <template is="dom-repeat" items="[[drawPolygonAttributes]]">
                  <paper-input value="{{item.$val}}" label="[[camelCase(item.$key)]]"></paper-input>
                </template>
                <div style="margin-top: 15px;">
                  <div style="display:inline-block; font-size: 16px; color:rgb(115, 115, 115);">Color:</div>
                  <katapult-color-picker-button
                    id="colorPicker"
                    style="display:inline-block;margin:0 0 0 8px;"
                    color="{{drawPolygonColor}}"
                  ></katapult-color-picker-button>
                </div>
                <template is="dom-if" if="[[!newPolygonLayerInput]]">
                  <katapult-drop-down
                    label="Select Layer for Polygon"
                    style="display:block;"
                    value="{{drawPolygonLayer}}"
                    value-path="$key"
                    label-path="name"
                    items="[[mapLayers]]"
                    show-last-results=""
                  >
                    <paper-item on-click="createNewPolygonLayer">Create New Layer</paper-item>
                  </katapult-drop-down>
                </template>
                <template is="dom-if" if="[[newPolygonLayerInput]]">
                  <paper-input label="New Layer Name" value="{{drawPolygonNewLayer}}">
                    <katapult-button
                      iconOnly
                      noBorder
                      icon="backspace"
                      slot="suffix"
                      on-click="cancelCreateNewPolygonLayer"
                    ></katapult-button>
                  </paper-input>
                </template>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'cuEntry')}}">
                <katapult-drop-down
                  id="cuEntryNodeList"
                  hidden$="{{!workLocationsExist}}"
                  style="width: 100%;"
                  label="Open CU Entry to Pole"
                  value="{{cuEntryNodeId}}"
                  items="[[poleListItems]]"
                  filter="[[boundCuNodeFilter]]"
                  label-path="label"
                  value-path="key"
                ></katapult-drop-down>
                <p>{{workLocationInfoString}}</p>
                <paper-checkbox style="width:200px;margin:10px" hidden$="{{!workLocationsExist}}" checked="{{regenerateWorkLocations}}"
                  >Regenerate All WLs</paper-checkbox
                >
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'setPmrAnnotation')}}">
                <template is="dom-if" if="{{existingPowerMakeReadyAnnotation}}">
                  <paper-textarea label="Existing PMR Annotation" value="{{existingPowerMakeReadyAnnotation}}" readonly></paper-textarea>
                </template>
                <paper-textarea label="Proposed PMR Annotation" value="{{powerMakeReadyAnnotation}}"></paper-textarea>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'mapLayerSelectDialog')}}">
                <katapult-drop-down
                  style="display:block;"
                  items="[[mapLayers]]"
                  label-path="name"
                  selected-item="{{confirmDialogSelectedMapLayer}}"
                  label="Selected Map Layer"
                  show-last-results=""
                ></katapult-drop-down>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'copyPolygonNodesToJob')}}">
                <job-chooser
                  alphabetical="[[orderJobsAlphabetically]]"
                  style="border:1px solid #d9d9d9;"
                  model-options="[[modelOptions]]"
                  selected="{{copyPolygonNodesToJobId}}"
                  user-group="{{userGroup}}"
                  label="Select a Job to copy reference layers to"
                  no-create-new-job="{{readOnlyUser}}"
                  firebase-disabled="[[!confirmDialogOpened]]"
                ></job-chooser>
                <div style="width:150px; display:grid;">
                  <paper-checkbox style="margin-top:6px; margin-bottom:6px;" checked="{{copyConnsToJob}}"
                    >Include Connections</paper-checkbox
                  >
                  <paper-checkbox style="margin-top:6px; margin-bottom:6px;" checked="{{copyPhotosToJob}}">Include Photos</paper-checkbox>
                  <template is="dom-if" if="[[modelConfig.cus]]">
                    <paper-checkbox style="margin-top:6px; margin-bottom:6px;" checked="{{copyCUsToJob}}">Include CU's</paper-checkbox>
                  </template>
                </div>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'makeSurveyAvailable')}}">
                <paper-checkbox style="margin:20px 0;" checked="{{surveyAvailableSR}}">Service Request Job</paper-checkbox>
                <template is="dom-if" if="[[!surveyAvailableSR]]">
                  <p>Enter in Work Order and Work Request numbers for this job.</p>
                  <paper-input allowed-pattern="[0-9]" value="{{surveyAvailableWr}}" label="WR Number"></paper-input>
                  <paper-input allowed-pattern="[0-9]" value="{{surveyAvailableWo}}" label="WO Number"></paper-input>
                </template>
                <template is="dom-if" if="[[surveyAvailableSR]]">
                  <job-chooser
                    alphabetical="[[orderJobsAlphabetically]]"
                    style="display:flex;"
                    selected="{{surveyAvailableSRJob}}"
                    user-group="{{userGroup}}"
                    default-text="Main Application to have Link to this Survey"
                    label-float
                    hide-folder-chooser
                    no-create-new-job
                    firebase-disabled="[[!confirmDialogOpened]]"
                  ></job-chooser>
                  <template is="dom-if" if="[[surveyAvailableJobError]]">
                    <div style="color:var(--paper-red-500);">[[surveyAvailableJobError]]</div>
                  </template>
                </template>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'selectAssignedToValue')}}">
                <katapult-drop-down
                  items="[[users]]"
                  value-path="uid"
                  label-path="email"
                  value="{{alpineAssignedTo}}"
                  label="Assign Job To a User"
                  no-label-float=""
                ></katapult-drop-down>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'copyReferenceToJob')}}">
                <job-chooser
                  alphabetical="[[orderJobsAlphabetically]]"
                  style="border:1px solid #d9d9d9;"
                  model-options="[[modelOptions]]"
                  selected="{{copyReferenceToJobId}}"
                  user-group="{{userGroup}}"
                  label="Select a Job to copy reference layers to"
                  no-create-new-job="{{readOnlyUser}}"
                  firebase-disabled="[[!confirmDialogOpened]]"
                ></job-chooser>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'unassociatePhotos')}}">
                <paper-checkbox checked="{{keepManuallyAssociatedPhotos}}" style="margin-top:30px;"
                  >Keep Manually Associated Photos</paper-checkbox
                >
              </template>
              <!--Import KMZ as nodes and connections-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'importKMZ')}}">
                <div style="overflow:auto; max-height:500px;  max-width:500px; position:relative;" katapult-drop-down-scroll-target>
                  <template is="dom-repeat" items="[[kmzImportMappingAttributes]]">
                    <div style="margin-top:10px;">
                      <div style="display:inline-block; width:49%;">
                        <span style="font-weight:bold;">[[item.property]]</span>
                        <div style="font-size:10pt; font-style:italic; overflow:auto; max-height: 200px;">(Example: [[item.sample]])</div>
                      </div>
                      <katapult-drop-down
                        items="[[getAttributeKeysForTypes(otherAttributes, 'node')]]"
                        value="{{item.mapped_property}}"
                        label="Choose attribute to import as"
                        no-label-float=""
                        style="display:inline-block; width:49%;"
                      >
                        <paper-item on-click="openModelEditor">Add or Manage Attributes</paper-item>
                      </katapult-drop-down>
                    </div>
                  </template>
                  <template is="dom-repeat" items="[[kmzImportNodeAttributes]]">
                    <div style="display:flex; margin-top:10px;">
                      <katapult-button iconOnly noBorder icon="delete" on-click="kmzAttributeRemoved"></katapult-button>
                      <input-element
                        style="flex-grow:1; flex-shrink:2; display:block;"
                        attribute_name="{{item.property}}"
                        model="{{getItem(otherAttributes, item.property)}}"
                        disabled="[[!equal(_sharing, 'write')]]"
                        hide-on-disabled=""
                        firebase-disabled="[[!signedIn]]"
                        other-attributes="[[otherAttributes]]"
                        value="{{item.value}}"
                        no-label-float=""
                        job-creator="[[jobCreator]]"
                      ></input-element>
                    </div>
                  </template>
                  <katapult-drop-down
                    items="[[getAttributeKeysForTypes(otherAttributes, 'node')]]"
                    on-selected-changed="kmzAttributeAdded"
                    label="Choose an attribute to add"
                    no-label-float=""
                    style="margin-left:16px;"
                  >
                    <paper-item on-click="openModelEditor">Add or Manage Attributes</paper-item>
                  </katapult-drop-down>
                  <template is="dom-if" if="[[kmzImportScrapedAttributes.length]]">
                    <div>Attributes to be scraped from KMZ Description:</div>
                    <template is="dom-repeat" items="[[kmzImportScrapedAttributes]]">
                      <div>[[item.property]] - [[item.count]] found (Example: [[item.example]])</div>
                    </template>
                  </template>
                </div>
              </template>
              <!--Bulk Insert Proposed Height-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'bulkInsertProposed')}}">
                <div style="display: flex; align-items: center; justify-content: space-around; position: relative;">
                  <paper-input
                    id="proposedInchDistance"
                    style="display:inline-block; width: 170px;"
                    type="number"
                    min="0"
                    max="40"
                    label="Inches From Existing"
                    value="{{proposedInchDistance}}"
                  ></paper-input>
                  <paper-radio-group selected="{{proposedInsertLocation}}">
                    <paper-radio-button name="above" style="display: block; margin-top: 30px; padding-bottom: 0"
                      >Above Highest Comm</paper-radio-button
                    >
                    <paper-radio-button name="below" style="display: block; margin-bottom: 20px">Below Lowest Comm</paper-radio-button>
                  </paper-radio-group>
                </div>
                <div style="display: flex; align-items: center; justify-content: space-around; position: relative; margin-bottom: 30px">
                  <paper-checkbox checked="{{forceInsertCheck}}">Force Insert</paper-checkbox>
                </div>
                <span
                  >This tool will stop and list warnings if there are any poles or sections with an uncalibrated main photo or existing
                  proposed markers. If you choose to Force Insert, proposed markers will be inserted on all poles and sections including
                  ones with existing proposed attchments.</span
                >
              </template>
              <!--Seed Poles from NJUNS-->
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'njuns')}}">
                <paper-dialog-scrollable style="height:400px;">
                  <paper-input id="njunsUser" label="NJUNS User" value="{{njunsUser}}"></paper-input>
                  <paper-input id="njunsPassword" label="NJUNS Password" type="password" value="{{njunsPassword}}"></paper-input>
                  <template is="dom-if" if="[[equal(njunsActionType, 'createTickets')]]">
                    <paper-textarea
                      id="njunsPoleNumbers"
                      label="Pole Grid Numbers"
                      value="{{njunsCreateTicketPoleNumbers}}"
                      always-float-label
                      rows="7"
                      style="margin-top:15px;"
                    ></paper-textarea>
                    <paper-checkbox style="display:block; margin-bottom:10px;" checked="{{njunsCreateCompleteTicketsWithoutPrompt}}"
                      >Automatically Create Complete Tickets (does not query closest)</paper-checkbox
                    >
                  </template>
                  <template is="dom-if" if="[[equal(njunsActionType, 'closeTickets')]]">
                    <paper-textarea label="Closing Comment" value="{{njunsCloseTicketComment}}"></paper-textarea>
                  </template>
                  <template is="dom-if" if="[[njuns_test_database_option]]">
                    <paper-checkbox checked="{{njunsTestDatabase}}">Test Database</paper-checkbox>
                    <br />
                    <br />
                  </template>
                  <template is="dom-if" if="[[equal(njunsActionType, 'seedPoles')]]">
                    <paper-item on-click="toggleDrawer" name="njunsSettingsDrawer"
                      ><iron-icon class="menuDrawerIcon" icon="settings"></iron-icon>Settings</paper-item
                    >
                    <iron-collapse class="menuDrawer" id="njunsSettingsDrawer">
                      <paper-checkbox checked="{{njunsSplitTickets}}">Split Tickets into Separate Jobs</paper-checkbox>
                      <br />
                      <div>Ticket JSON to import</div>
                      <input id="njunsDataInput" type="file" accept="application/json" />
                      <paper-textarea label="Query Data" id="seedFromNjunsOptions" value="{{seedFromNjunsOptions}}"></paper-textarea>
                    </iron-collapse>
                  </template>
                </paper-dialog-scrollable>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'duplicateJob')}}">
                <paper-input label="Duplicate Job Name" value="{{duplicateJobName}}">
                  <iron-a11y-keys keys="enter" on-keys-pressed="confirmDialogCallback"></iron-a11y-keys>
                </paper-input>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'qcSteps')}}">
                <template is="dom-repeat" items="[[activeCommandModel.models.steps]]">
                  <div style="margin-top:5px; margin-left:25px;">
                    <paper-checkbox checked="{{item.checked}}" on-change="selectQCGroup">[[item.title]]</paper-checkbox>
                    <!--<iron-icon icon="check-circle" style="margin-left:5px; color:var(--paper-green-500);"></iron-icon>-->
                  </div>
                </template>

                <br />
                New QC Modules
                <template is="dom-repeat" items="[[newQCModules]]">
                  <div style="margin-top:5px; margin-left:25px;">
                    <paper-checkbox checked="{{item.checked}}">[[item.title]]</paper-checkbox>
                  </div>
                </template>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'fillInActions')}}">
                <div style="margin-top:15px;">
                  Feedback will be provided at <a color="var(--secondary-color);" href$="[[origin]]/home">[[origin]]/home</a> and we'll
                  shoot you an email with further information or questions.
                </div>
                <div style="margin-top:15px;">
                  To receive feedback, select which users completed the following steps (leaving blank any steps not completed):
                </div>
                <template is="dom-repeat" items="[[actionItems]]">
                  <paper-row>
                    <paper-cell>[[item.action_name]]</paper-cell>
                    <paper-cell
                      ><katapult-drop-down
                        items="[[users]]"
                        value="{{item.uid}}"
                        label-path="email"
                        value-path="uid"
                        label="User"
                        fit-into="[[$.mapContainer]]"
                      ></katapult-drop-down
                    ></paper-cell>
                  </paper-row>
                </template>
                <div style="margin-top:35px;">Please add any notes or describe areas of focus for the review:</div>
                <paper-textarea label="Additional Notes" no-label-float rows="4" value="{{feedbackTrainingNotes}}"></paper-textarea>
              </template>
              <template is="dom-if" if="{{equal(confirmDialogBodyType, 'email')}}">
                <paper-input label="To:" value="{{confirmEmail.to}}"></paper-input>
                <paper-input label="Cc:" value="{{confirmEmail.cc}}"></paper-input>
                <paper-input label="Subject:" value="{{confirmEmail.subject}}"></paper-input>
                <paper-textarea id="contactBody" label="Body:" value="{{confirmEmail.body}}" autofocus="" rows="7"></paper-textarea>
              </template>
            </span>
          </div>
        </paper-dialog-scrollable>
        <div class="buttons">
          <template is="dom-if" if="{{confirmDialogError}}">
            <p class="dialogErrorText">[[confirmDialogError]]</p>
          </template>
          <template is="dom-if" if="{{!confirmDialogError}}">
            <p class="dialogStatusText">[[confirmDialogStatus]]</p>
          </template>
          <!-- <template is="dom-if" if="{{confirmDialogDismissiveText}}">
          <katapult-button on-click="cancelPromptAction" style$="{{confirmDialogDismissiveStyle}}" dialog-dismiss>{{confirmDialogDismissiveText}}</katapult-button>
        </template> -->
          <template is="dom-if" if="{{confirmDialogDismissiveText}}">
            <katapult-button on-click="cancelPromptAction" dialog-dismiss>{{confirmDialogDismissiveText}}</katapult-button>
          </template>
          <template is="dom-if" if="{{equal(confirmDialogBodyType, 'multiAddAttributes')}}">
            <katapult-button on-click="multiEditAttributesAllItems" dialog-confirm disabled="{{isConfirmDisabled}}"
              >Select All</katapult-button
            >
            <katapult-button
              data-type="multiAddAttributes"
              on-click="openNodeTypeSelectDialog"
              dialog-confirm
              disabled="{{isConfirmDisabled}}"
              >Select By Type...</katapult-button
            >
          </template>
          <template is="dom-if" if="{{equal(confirmDialogBodyType, 'copyNodes')}}">
            <katapult-button data-type="copyNodes" on-click="openNodeTypeSelectDialog" dialog-confirm disabled="{{isConfirmDisabled}}"
              >Copy Type...</katapult-button
            >
            <katapult-button on-click="copyAllNodes" dialog-confirm disabled="{{isConfirmDisabled}}">Copy All</katapult-button>
          </template>
          <template is="dom-if" if="{{equal(confirmDialogBodyType, 'scrapePhotos')}}">
            <katapult-button on-click="scrapeAllNodes" dialog-confirm disabled="{{isConfirmDisabled}}">Select All</katapult-button>
          </template>
          <!--Add Address Data-->
          <template is="dom-if" if="{{equal(confirmDialogBodyType, 'addAddressData')}}">
            <katapult-button dialog-confirm disabled="{{isConfirmDisabled}}" on-click="addAddressDataToNodes">Add to All</katapult-button>
            <katapult-button data-type="addressData" dialog-confirm disabled="{{isConfirmDisabled}}" on-click="openNodeTypeSelectDialog"
              >Add By Node Type</katapult-button
            >
          </template>
          <!--Add PA State Road Data-->
          <template is="dom-if" if="{{equal(confirmDialogBodyType, 'paStateRoadData')}}">
            <katapult-button on-click="addPAStateRoadDataToNodes" dialog-confirm disabled="{{isConfirmDisabled}}"
              >Select All</katapult-button
            >
          </template>

          <template is="dom-repeat" items="[[confirmDialogOtherButtons]]">
            <katapult-button on-click="confirmDialogButtonCallback" dialog-confirm disabled$="[[item.disabled]]" style$="[[item.style]]"
              >[[item.text]]</katapult-button
            >
          </template>
          <!-- Confirm Button -->
          <katapult-button
            dialog-confirm$="[[confirmDialogCloseOnConfirm]]"
            disabled="{{isConfirmDisabled}}"
            on-click="confirmDialogCallback"
            style="min-width: 4em;"
            >{{confirmDialogAffirmativeText}}</katapult-button
          >
        </div>
      </paper-dialog>

      <!-- Confirm Input Dialog -->

      <katapult-dialog-legacy
        id="confirmInputDialog"
        style="width: 450px;"
        draggable
        icon="[[confirmInputIcon]]"
        no-cancel-on-outside-click
        no-cancel-on-esc-key
        persist-manual-position
      >
        <span slot="title">[[confirmInputTitle]]</span>
        <span slot="subtitle">[[confirmInputSubtitle]]</span>
        <paper-textarea slot="body" value="{{confirmInputValue}}" label="[[confirmInputLabel]]"></paper-textarea>
        <katapult-button slot="buttons" on-click="confirmInputCancel">Cancel</katapult-button>
        <katapult-button slot="buttons" color="var(--secondary-color)" on-click="confirmInputAccepted">Apply</katapult-button>
      </katapult-dialog-legacy>

      <!-- Master Location Directory Manager -->
      <master-location-directory-manager
        id="masterLocationDirectoryManager"
        job-id="[[job_id]]"
        selected-model="[[jobCreator]]"
        user-group="[[userGroup]]"
        signed-in="[[signedIn]]"
      ></master-location-directory-manager>

      <!-- Power Annotations Generator -->
      <power-annotation-generator-dialog id="powerAnnotationGeneratorDialog"></power-annotation-generator-dialog>

      <!-- OverlappingNodesImporter -->
      <overlapping-nodes-importer
        id="overlappingNodesImporter"
        alternate-designs-config="[[alternateDesignsConfig]]"
        attribute-groups-model="[[attributeGroupsModel]]"
        model-defaults="[[modelDefaults]]"
        other-attributes="[[otherAttributes]]"
      >
      </overlapping-nodes-importer>

      <!-- ProposedDownGuyLinkingDialog -->
      <paper-dialog id="ProposedDownGuyLinkingDialog" style="width:520px;" no-cancel-on-outside-click>
        <h2>[[downGuyLinkingData.marker_description]] Details</h2>

        <template is="dom-if" if="[[checkShouldShowWireSpecDropdown(downGuyLinkingData.action_type)]]">
          <p>Choose the down guy spec for the marker.</p>
          <katapult-drop-down
            items="[[getDownGuySpecPicklist(otherAttributes)]]"
            value="{{downGuyLinkingData.selected_spec}}"
            label="Enter Down Guy Spec"
            show-last-results
          ></katapult-drop-down>
        </template>

        <p>The MR note will be set on the marker</p>
        <paper-textarea label="MR Note" value="{{downGuyLinkingData.mr_note}}"></paper-textarea>
        <paper-checkbox checked="{{downGuyLinkingData.installAuxEye}}" on-change="addAuxEyeNoteToDownGuyNote"
          >Install Aux Eye</paper-checkbox
        >
        <div class="buttons">
          <katapult-button dialog-dismiss>Cancel</katapult-button>
          <katapult-button on-click="updateProposedDownGuy" dialog-confirm>Okay</katapult-button>
        </div>
      </paper-dialog>

      <!-- Icon Dialog -->
      <icon-picker-dialog
        id="iconDialog"
        model-key="[[jobCreator]]"
        on-icon-selected="mapLayerIconSelectionMade"
        user-group="[[userGroup]]"
      ></icon-picker-dialog>

      <!--Moved the chooser out here to put its dialog into the correct context-->
      <project-folder-chooser
        id="projectFolderChooser"
        user-group="[[userGroup]]"
        company-names="[[companyNames]]"
        no-calc-company-names
        job-id="{{job_id}}"
        disabled="[[!signedIn]]"
        link-to-ppl="{{status.link_to_ppl}}"
        project-folders="{{projectFolders}}"
        job-creator="{{jobCreator}}"
        on-share-job="promptToUpdateMasterLocationDirectory"
        on-set-job-id="setJobId"
        on-duplicate-job-data="duplicateJobData"
        on-delete-job="deleteJob"
        on-toast="displayToastMessage"
        opened="{{folderChooserOpened}}"
      ></project-folder-chooser>
      <iron-a11y-keys target="[[body]]" keys="esc" on-keys-pressed="cancelPromptAction"></iron-a11y-keys>
      <iron-a11y-keys target="[[body]]" keys="delete" on-keys-pressed="delKeyPressed"></iron-a11y-keys>
      <iron-a11y-keys
        target="[[body]]"
        keys="1 2 3 4 5 6 7 8 9 a b c d e f g h i j k l m n o p q r s t u v w x y z alt+a"
        on-keys-pressed="shortcutKeyPressed"
      ></iron-a11y-keys>
      <katapult-auth
        user="{{user}}"
        admin="{{isAdmin}}"
        user-group="{{userGroup}}"
        signed-in="{{signedIn}}"
        read-only="{{readOnlyUser}}"
        company="{{companyName}}"
        job-token="{{jobToken}}"
        title="[[config.firebaseData.name]] Maps"
        signup-link="https://katapultpro.com/signup"
        no-signup="[[!config.firebaseData.offerLicenses]]"
        on-sign-in="onSignIn"
        on-show-auth-content-changed="showAuthContentChanged"
      >
        <div slot="title-content">
          <p style="margin: 0;">[[config.firebaseData.name]] Maps</p>
        </div>
        <div id="loginDescription" slot="description-content">
          <p>[[config.firebaseData.name]] Maps allows you to design and share survey jobs with Maps and Photos from any computer.</p>
          <p>Gather data for a permanent record of real-world conditions in minutes!</p>
          <template is="dom-if" if="{{appNameContains(config.firebaseData.name, 'katapult')}}">
            <p>
              Need some help? View our
              <a href="https://katapultpro.com/help/" style="color: white; font-weight: normal; text-decoration: none;">Docs</a>
            </p>
          </template>
        </div>
        <div slot="auth-content">
          <!-- Firebase Data-->
          <model-loader
            company-id="[[jobCreator]]"
            items='["config/defaults", "attributes", "mapping_buttons", "button_groups", "input_models", "routines", "input_model_groups", "hw_details_options", "trace_models", "snapshot_models", "config/map", "config/photofirst", "alternate_designs"]'
            snapshot-models="{{snapshotModels}}"
            config-defaults="{{configDefaults}}"
            other-attributes="{{otherAttributes}}"
            mapping-buttons="{{mappingButtons}}"
            button-groups="{{buttonGroups}}"
            input-models="{{inputModels}}"
            routines="{{routines}}"
            input-model-groups="{{inputModelGroups}}"
            hw-details-options="{{hwDetailsOptions}}"
            trace-models="{{traceModels}}"
            config-map="{{modelConfig}}"
            config-photofirst="{{photoFirstConfig}}"
            alternate-designs="{{alternateDesignsConfig}}"
          >
          </model-loader>
          <firebase-document
            id="jobCreatorID"
            path="photoheight/jobs/[[job_id]]/job_creator"
            data="{{jobCreator}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="projectId"
            path="photoheight/jobs/[[job_id]]/project_id"
            data="{{projectId}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="jobOwner"
            path="photoheight/jobs/[[job_id]]/job_owner"
            data="{{jobOwner}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="orderJobsAlphabetically"
            path="photoheight/company_space/[[userGroup]]/order_jobs_alphabetically"
            data="{{orderJobsAlphabetically}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="mrClearances"
            path="photoheight/company_space/[[jobCreator]]/models/mr_clearances"
            data="{{mrClearances}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="proposedCableLogic"
            path="photoheight/company_space/[[jobCreator]]/models/make_ready/proposed_cable_logic"
            data="{{proposedCableLogic}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-query
            id="makeReadyClearances"
            path="photoheight/company_space/[[jobCreator]]/models/make_ready/make_ready_items"
            order-by-child="order"
            data="{{makeReadyClearances}}"
            disabled="[[!signedIn]]"
          ></firebase-query>
          <firebase-document
            id="mrPreferences"
            path="photoheight/company_space/[[jobCreator]]/models/mr_preferences"
            data="{{mrPreferences}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="nodeCounterConfig"
            path="photoheight/company_space/[[jobCreator]]/models/config/logic"
            data="{{nodeCounterConfig}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="powerSpecLookup"
            path="photoheight/company_space/[[jobCreator]]/models/export_models/poleforeman/power_spec_lookup"
            data="{{powerSpecLookup}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="attributeGroups"
            path="photoheight/company_space/[[jobCreator]]/models/attribute_groups"
            data="{{attributeGroupsModel}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="companyAdmins"
            path="photoheight/company_space/[[jobOwner]]/admins"
            data="{{companyAdmins}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="userData"
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]"
            data="{{userData}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="useMetricUnits"
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/use_metric_units"
            data="{{useMetricUnits}}"
            default-zero-value="{{nullZeroValue}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="useDecimalFeet"
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/use_decimal_feet"
            data="{{useDecimalFeet}}"
            default-zero-value="{{nullZeroValue}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="labelFontSize"
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/desktop_label_font_size"
            data="{{labelFontSize}}"
            default-zero-value="8"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="dontLinkSpawnedWindows"
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/dontLinkSpawedWindows"
            data="{{dontLinkSpawnedWindows}}"
            default-zero-value="{{nullZeroValue}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/show_one_click_menu"
            data="{{showOneClickMenu}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/right_click_one_click_menu"
            data="{{rightClickOneClickMenu}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="dockSearchBarStatus"
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/dock_search_bar"
            data="{{dockSearchBarStatus}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="photoLabels"
            path="photoheight/company_space/[[userGroup]]/user_data/[[user.uid]]/photo_labels"
            data="{{photoLabelsFromFirebase}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="timeOffset"
            path=".info/serverTimeOffset"
            data="{{timeOffset}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="nodes"
            path="photoheight/jobs/[[job_id]]/nodes"
            data="{{nodes}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="connections"
            path="photoheight/jobs/[[job_id]]/connections"
            data="{{connections}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="savedViews"
            path="photoheight/jobs/[[job_id]]/saved_views"
            data="{{savedViews}}"
            disabled="[[!signedIn]]"
            on-empty-result="savedViewsChanged"
          ></firebase-document>
          <firebase-document
            id="status"
            path="photoheight/jobs/[[job_id]]/status"
            data="{{status}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="snapshots"
            path="photoheight/jobs/[[job_id]]/snapshots"
            data="{{jobSnapshots}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="status"
            path="photoheight/jobs/[[job_id]]/warning_reports"
            data="{{jobWarningReportsData}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="jobStyles"
            path="photoheight/jobs/[[job_id]]/map_styles"
            data="{{jobStyles}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="jobPermissions"
            path="photoheight/job_permissions/[[userGroup]]/jobs/[[job_id]]"
            data="{{jobPermissions}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-query
            id="defaultLabels"
            path="photoheight/jobs/[[job_id]]/default_labels"
            data="{{defaultLabels}}"
            disabled="[[!signedIn]]"
          ></firebase-query>
          <firebase-document
            id="tier"
            path="photoheight/company_space/[[getTierCompany(jobCreator, userGroup)]]/tier"
            data="{{tier}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="userModules"
            path="photoheight/company_space/[[userGroup]]/subscription/modules"
            data="{{userModules}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="companyOptions"
            path="photoheight/company_space/[[userGroup]]/options"
            data="{{companyOptions}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="traces"
            path="photoheight/jobs/[[job_id]]/traces/trace_data"
            data="{{traces}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="traceItems"
            path="photoheight/jobs/[[job_id]]/traces/trace_items"
            data="{{traceItems}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="users"
            path="photoheight/company_space/[[userGroup]]/users"
            data="{{users}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="modelOptions"
            path="photoheight/company_space/[[userGroup]]/model_options"
            data="{{modelOptions}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-query
            id="mapLayers"
            path="photoheight/jobs/[[job_id]]/layers/list"
            data="{{mapLayers}}"
            disabled="[[!signedIn]]"
          ></firebase-query>
          <firebase-document
            id="projectFolder"
            path="photoheight/jobs/[[job_id]]/project_folder"
            data="{{projectFolder}}"
            default-zero-value="{{nullZeroValue}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-query
            id="jobFiles"
            path="photoheight/jobs/[[job_id]]/files"
            data="{{jobFiles}}"
            disabled="[[!signedIn]]"
          ></firebase-query>
          <firebase-document
            id="recordNodeMoveAttribute"
            path="photoheight/jobs/[[job_id]]/metadata/record_node_move_attribute"
            data="{{recordNodeMoveAttribute}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="masterLocationDirectoryLastUpdate"
            path="photoheight/jobs/[[job_id]]/metadata/master_location_directory_last_update"
            data="{{masterLocationDirectoryLastUpdate}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="masterUtilityInfoList"
            path="utility_info/_list"
            data="{{masterUtilityInfoList}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="companyUtilityInfoList"
            path="utility_info/[[userGroup]]/_list"
            data="{{companyUtilityInfoList}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="availableAPILayers"
            path="/photoheight/company_space/[[userGroup]]/map_api_layers"
            data="{{availableAPILayers}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <katapult-firebase-worker
            id="isUtilityReviewContractor"
            path="photoheight/company_space/[[config.firebaseData.utilityCompany]]/portal_config/user_options/review_contractors/[[userGroup]]"
            data="{{isUtilityReviewContractor}}"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            id="isReviewContractor"
            path="photoheight/jobs/[[job_id]]/review_contractors/[[userGroup]]"
            data="{{isReviewContractor}}"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            id="apiLayerGroups"
            path="photoheight/company_space/[[userGroup]]/map_api_layer_groups"
            data="{{apiLayerGroups}}"
          ></katapult-firebase-worker>
          <katapult-firebase-worker
            id="toolLoggingDangerLimit"
            path="internal_logging/configuration/tool_node_limit"
            data="{{toolLoggingDangerLimit}}"
          ></katapult-firebase-worker>
          <firebase-document
            id="rootCompany"
            path="user_groups/[[user.uid]]/root_company"
            data="{{rootCompany}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="metadata"
            path="photoheight/jobs/[[job_id]]/metadata"
            data="{{metadata}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="attacherName"
            path="utility_info/[[config.firebaseData.utilityCompany]]/attachers/[[metadata.attachment_owner]]/name"
            data="{{attacherName}}"
          ></firebase-document>
          <firebase-document
            id="promptForCatalogImport"
            path="/photoheight/company_space/[[userGroup]]/prompt_for_catalog_import"
            data="{{promptForCatalogImport}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="feedbackConfig"
            path="photoheight/feedback"
            data="{{feedbackConfig}}"
            disabled="[[!signedIn]]"
          ></firebase-document>
          <firebase-document
            id="sharedCompanies"
            path="photoheight/jobs/[[job_id]]/sharing"
            data="{{sharedCompanies}}"
            disabled="[[disabled]]"
          ></firebase-document>
          <firebase-document
            id="contacts"
            path="photoheight/company_space/[[userGroup]]/contacts"
            data="{{contacts}}"
            disabled="[[disabled]]"
          ></firebase-document>
          <firebase-document
            id="trackingDisabled"
            path="photoheight/jobs/[[job_id]]/tracking_disabled"
            data="{{trackingDisabled}}"
            disabled="[[disabled]]"
          ></firebase-document>

          <saved-view-manager
            id="savedViewManager"
            active-saved-view="{{activeSavedView}}"
            job-id="[[job_id]]"
            job-creator="[[jobCreator]]"
            saved-views="{{savedViews}}"
            saved-views-array="{{savedViewsArray}}"
          ></saved-view-manager>

          <quality-control
            id="qualityControl"
            firebase-job="{{job_id}}"
            fatal-error="{{qcFatalError}}"
            prevent-download="{{qcPreventDownload}}"
            final-object="{{qcObject}}"
            export-json="{{qcJson}}"
            power-company="[[qcPowerCompany]]"
            user-group="{{userGroup}}"
            disabled="[[!signedIn]]"
          ></quality-control>
          <drop-detector
            id="dropDetector"
            job-id="{{job_id}}"
            tier="{{tier}}"
            create-job-form="{{getItemById('createJobForm')}}"
            katapult-map="[[$.katapultMap]]"
            sharing="{{_sharing}}"
            user-group="{{userGroup}}"
            attributes-data="{{otherAttributes}}"
            job-nodes="{{nodes}}"
            job-conns="{{connections}}"
            disabled="[[!signedIn]]"
            on-toast="displayToastMessage"
            model-options="[[modelOptions]]"
            config="[[config]]"
          ></drop-detector>
          <make-ready-calculations
            id="makeReadyCalculations"
            nodes="{{nodes}}"
            connections="{{connections}}"
            traces="{{traces}}"
            mr-clearances="{{mrClearances}}"
            mr-preferences="{{mrPreferences}}"
            model-attributes="{{otherAttributes}}"
          ></make-ready-calculations>
          <multi-mr-calcs
            id="multiMrCalcs"
            traces="[[traces]]"
            job-id="[[job_id]]"
            model-config="[[modelConfig]]"
            job-styles="[[jobStyles]]"
            mr-clearances="[[getItemById('makeReadyClearances')]]"
          ></multi-mr-calcs>
          <make-ready-details
            id="makeReadyDetails"
            nodes="{{nodes}}"
            connections="{{connections}}"
            traces="{{traces}}"
            model-attributes="{{otherAttributes}}"
            on-item-changed="makeReadyDetailsItemChanged"
            job_id="[[job_id]]"
          ></make-ready-details>
          <seed-job
            job-id="{{job_id}}"
            user-group="{{userGroup}}"
            job-metadata="{{metadata}}"
            job-name="{{jobName}}"
            on-progress="showStatusToast"
            model-defaults="[[modelDefaults]]"
            utility-company="[[config.firebaseData.utilityCompany]]"
            on-toast="displayToastMessage"
          ></seed-job>
          <map-overlay map="[[map]]" id="mapOverlay" action-dialog-data="[[actionDialogData]]" job_id="[[job_id]]"></map-overlay>
          <qc-checks
            id="qcChecks"
            input-models="[[inputModels]]"
            config="[[config]]"
            model-config="[[modelConfig]]"
            model-defaults="[[modelDefaults]]"
            nodes="[[nodes]]"
            connections="[[connections]]"
            traces="[[traces]]"
            trace-items="[[traceItems]]"
            other-attributes="{{otherAttributes}}"
            user-group="[[userGroup]]"
            job-status="[[status]]"
            job_id="{{job_id}}"
            job-name="[[jobName]]"
            job-creator="[[jobCreator]]"
            shared-companies="[[sharedCompanies]]"
            use-metric-units="[[useMetricUnits]]"
            use-decimal-feet="[[useDecimalFeet]]"
            is-review-contractor="[[isUtilityReviewContractor]]"
            trace-models="[[traceModels]]"
            mr-clearances="[[getItemById('makeReadyClearances')]]"
            alternate-designs-config="[[alternateDesignsConfig]]"
          ></qc-checks>
          <qc-spimcg id="qcSPIMCG" model-defaults="[[modelDefaults]]"></qc-spimcg>
          <guying-check id="guyingCheck" model-defaults="[[modelDefaults]]"></guying-check>
          <calc-bellspecs id="calcBellSpecs" model-defaults="[[modelDefaults]]"></calc-bellspecs>
          <invoice-ppl id="invoicePPL" model-defaults="[[modelDefaults]]"></invoice-ppl>
          <job-form-editor
            id="jobFormEditor"
            other-attributes="{{getAttributeKeysForTypes(otherAttributes, 'node')}}"
            job_id="{{job_id}}"
          ></job-form-editor>
          <map-styles-editor
            id="mapStylesEditor"
            job-styles="{{jobStyles}}"
            company-styles="{{companyStyles}}"
            other-attributes="{{otherAttributes}}"
            job-creator="{{jobCreator}}"
            user-group="{{userGroup}}"
            on-save="saveJobStyles"
            on-toast="displayToastMessage"
          ></map-styles-editor>
          <export-manager
            id="exportManager"
            job-styles="[[jobStyles]]"
            metadata="[[metadata]]"
            job-creator="{{jobCreator}}"
            node-labels="[[nodeLabels]]"
            show-span-distances="[[showSpanDistances]]"
            input-models="[[inputModels]]"
            trace-listeners="{{traceListeners}}"
            mr-preferences="[[mrPreferences]]"
            mr-clearances="[[getItemById('makeReadyClearances')]]"
            model-defaults="[[modelDefaults]]"
            model-config="{{modelConfig}}"
            use-metric-units="[[useMetricUnits]]"
            use-decimal-feet="[[useDecimalFeet]]"
            on-display-warnings-dialog="displayWarningsDialog"
            on-toast="displayToastMessage"
            users="[[users]]"
            tier="[[tier]]"
            job-modules="[[jobModules]]"
            other-attributes="{{otherAttributes}}"
            user-email="{{userEmail}}"
            user-group="{{userGroup}}"
            root-company="[[rootCompany]]"
            company-names="{{companyNames}}"
            sharing="{{sharing}}"
            job-files="{{filteredJobFiles}}"
            make-ready="[[!equal(makeReadyClearances.length, 0)]]"
            reload-photo-function="{{reloadPhotoFunction}}"
            disabled="[[!signedIn]]"
            on-prompt-select-connection="promptToSelectConnection"
            utility-company="[[config.firebaseData.utilityCompany]]"
            model-options="[[modelOptions]]"
          ></export-manager>
          <csv-xlsx-parser id="csvXlsxParser"></csv-xlsx-parser>
          <custom-file-importer
            id="customFileImporter"
            nodes="[[nodes]]"
            connections="[[connections]]"
            metadata="[[metadata]]"
            traces="[[traces]]"
            trace_items="[[trace_items]]"
            job-id="[[job_id]]"
            job-creator="[[jobCreator]]"
            job-name="[[jobName]]"
            on-toast="displayToastMessage"
            user-group="[[userGroup]]"
            create-job-form="{{getItemById('createJobForm')}}"
            attributes-data="{{otherAttributes}}"
            model-options="[[modelOptions]]"
          ></custom-file-importer>
          <print-generator
            id="printGenerator"
            map="{{map}}"
            user-group="{{userGroup}}"
            job-name="{{jobName}}"
            job-creator="{{jobCreator}}"
            model-config="[[modelConfig]]"
            model-defaults="[[modelDefaults]]"
            use-metric-units="[[useMetricUnits]]"
            use-decimal-feet="[[useDecimalFeet]]"
          ></print-generator>

          <input
            id="fileInput"
            hidden=""
            type="file"
            accept$="{{acceptedFileInputTypes}}"
            multiple$="{{acceptMultipleFileInputs}}"
            on-change="filesChosen"
          />
          <paper-toast id="progressToast" style="min-width:315px;" visible="false" duration="0" no-cancel-on-esc-key="">
            <div id="progressText" style="margin-bottom:3px;">{{progressText}}</div>
            <paper-progress id="progressPercent" style="width:auto;" value="{{progressPercent}}"></paper-progress>
          </paper-toast>
          <paper-toast id="toast" text="[[toastText]]" visible="false" duration="6000">
            <span id="toastBody"></span>
            <template is="dom-if" if="[[showToastSpinner]]" restamp>
              <div style="margin-left: 25px;">
                <paper-spinner-lite style="--paper-spinner-color: white;" active></paper-spinner-lite>
              </div>
            </template>
            <template is="dom-if" if="[[showCloseToastButton]]">
              <katapult-button style="margin: 5px 5px 5px 25px;" color="rgba(255, 255, 255)" on-click="closeToast">Close</katapult-button>
            </template>
          </paper-toast>
          <paper-toast id="warningToast" text="{{warningToastText}}" visible="false" duration="6000">
            <span id="warningToastBody"></span>
          </paper-toast>
          <!--Set Wire Spec Dialog-->
          <paper-dialog
            id="setWireSpecDialog"
            style="width:600px;"
            no-cancel-on-esc-key=""
            no-cancel-on-outside-click=""
            entry-animation="scale-up-animation"
          >
            <h2>Enter Wire Spec</h2>
            <paper-dialog-scrollable>
              <p>
                The chosen wire spec values will only be set on wires with a blank or missing wire spec attribute, unless "Overwrite Values"
                is enabled. If you want to skip a wire type, then leave the dropdown blank.
              </p>
              <template is="dom-if" if="{{!setPowerSpecData}}">
                <div style="display:grid; justify-content:center; margin-top:40px; margin-bottom:60px;">
                  <p style="grid-column:1; margin-top:5px; margin-right:15px;">Finding midspan cables...</p>
                  <paper-spinner style="grid-column:2" active=""></paper-spinner>
                </div>
                <div class="buttons">
                  <katapult-button dialog-dismiss on-click="cancelPromptAction">Cancel</katapult-button>
                </div>
              </template>
              <template is="dom-if" if="{{setPowerSpecData.error}}">
                <div style="display:grid; justify-content:center; margin-top:40px; margin-bottom:60px;">
                  <p style="color:red; grid-column:1; margin-top:5px; margin-right:15px;">{{setPowerSpecData.error}}</p>
                </div>
                <div class="buttons">
                  <katapult-button dialog-dismiss on-click="cancelPromptAction">Cancel</katapult-button>
                </div>
              </template>
              <template is="dom-if" if="{{setPowerSpecData.cableData}}">
                <template is="dom-if" if="{{listCountMatches(setPowerSpecData.cableData, 0)}}">
                  <div style="display:grid; justify-content:center; margin-top:40px; margin-bottom:60px;">
                    <p style="grid-column:1; margin-top:5px; margin-right:15px;">No cables were found in the selected sections.</p>
                  </div>
                  <div class="buttons">
                    <katapult-button dialog-dismiss on-click="cancelPromptAction">Cancel</katapult-button>
                  </div>
                </template>
                <template is="dom-if" if="{{!listCountMatches(setPowerSpecData.cableData, 0)}}">
                  <div style="display: grid; justify-content:left;">
                    <template is="dom-repeat" items="{{setPowerSpecData.cableData}}">
                      <label style="margin:30px 12px 0px 7px; text-align:right; grid-column:1">{{item.type}}: </label>
                      <katapult-drop-down
                        style="display:block; min-width:250px; margin-bottom:1em; grid-column:2"
                        items="[[item.options]]"
                        value="{{item.selectedSpec}}"
                        label="Enter wire_spec"
                        show-last-results=""
                      ></katapult-drop-down>
                    </template>
                  </div>
                  <div class="buttons">
                    <paper-checkbox style="margin-top:21px;" checked="{{setPowerSpecShouldOverwrite}}">Overwrite Values</paper-checkbox>
                    <div style="flex-grow: 1;"></div>
                    <katapult-button dialog-dismiss on-click="cancelPromptAction">Cancel</katapult-button>
                    <katapult-button dialog-confirm on-click="confirmSetPowerSpec">Set Wire Spec</katapult-button>
                  </div>
                </template>
              </template>
            </paper-dialog-scrollable>
          </paper-dialog>
          <!--End Set Wire Spec Dialog-->

          <!--Map Layer Manager Dialog-->
          <paper-dialog
            id="mapLayersManagerDialog"
            layered="false"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            opened="{{mapLayersManagerOpen}}"
          >
            <h2>Manage Map Layers:</h2>
            <paper-dialog-scrollable>
              <h3 style="margin-top:0; align-items: center; display: flex;">
                <iron-icon icon="note-add"></iron-icon>File Imports
                <span style="width:40px;">
                  <katapult-button
                    iconOnly
                    noBorder
                    id="downloadAllLayersButton"
                    icon="file_download"
                    on-click="downloadAllLayers"
                  ></katapult-button>
                  <paper-tooltip for="downloadAllLayersButton" position="right" offset="0">Download all as Shapefile</paper-tooltip>
                </span>
              </h3>
              <div class="mapLayersManagerGroup">
                <div>
                  <div style="display:flex;">
                    <p style="margin-bottom:0px; top:8px;">
                      To add a new layer, upload a KMZ file or a zip containing Shapefile data (.shp, .shx, .dbf, .prj).
                    </p>
                    <paper-spinner id="mapLayersFileUploadSpinner" style="top:9px; flex-shrink:0;"></paper-spinner>
                    <katapult-button id="openMapLayersFileInput" name="file" on-click="openMapLayersFileInput"
                      >Choose Files</katapult-button
                    >
                  </div>
                  <p style="color:gray; font-style:italic; margin-bottom:5px; margin-top:2px;">
                    Note: If a KMZ file already has styling, it may override your custom layer styles.
                  </p>
                  <input id="mapLayersFileInput" hidden="" type="file" multiple on-change="addMapLayer" />
                </div>
                <div>
                  <paper-table>
                    <paper-table-scroll on-sort-changed="mapLayerSorted">
                      <template is="dom-repeat" items="{{mapLayers}}">
                        <template is="dom-if" if="[[!equal(item.type, 'Reference Layer')]]">
                          <template is="dom-if" if="[[!equal(item.type, 'API Layer')]]">
                            <paper-row slot="sortable" item="[[item]]">
                              <paper-cell>
                                <template is="dom-if" if="[[!equal(item.type, 'Overlay Layer')]]">
                                  <div style="display: flex; align-items: center;">
                                    <iron-icon icon="katapult-misc:drag-indicator"></iron-icon>
                                    <div style="width:40px;">
                                      <katapult-button
                                        iconOnly
                                        noBorder
                                        id="Button[[item.$key]]"
                                        icon="file_download"
                                        on-click="downloadLayer"
                                      ></katapult-button>
                                      <paper-tooltip for="Button[[item.$key]]" position="right" offset="0"
                                        >Download as Shapefile</paper-tooltip
                                      >
                                    </div>
                                    <div style="width:40px;">
                                      <katapult-button
                                        iconOnly
                                        noBorder
                                        icon="palette"
                                        on-click="editMapLayer"
                                        disabled="[[!canWrite]]"
                                      ></katapult-button>
                                    </div>
                                    <input-element
                                      style="flex-grow: 1;"
                                      placeholder="Layer name"
                                      value="{{item.name}}"
                                      on-input-element-change="changeMapLayerName"
                                      no-label-float
                                      model="[[changeMapLayerNameInputModel]]"
                                      hide-attribute-name
                                    ></input-element>
                                    <katapult-button
                                      iconOnly
                                      noBorder
                                      icon="delete"
                                      on-click="openDeleteMapLayerItemConfirmDialog"
                                    ></katapult-button>
                                  </div>
                                </template>
                              </paper-cell>
                            </paper-row>
                          </template>
                        </template>
                      </template>
                    </paper-table-scroll>
                  </paper-table>
                </div>
              </div>

              <h3 style="margin-top:0;"><iron-icon icon="image:filter"></iron-icon>Manage Overlays</h3>
              <div class="mapLayersManagerGroup">
                <div style="display:flex;">
                  <p style="margin-bottom:0px; top:8px;">To add a new overlay, upload an image (.png, .jpg, .webp, .gif, .bmp).</p>
                  <paper-spinner id="mapLayersOverlayUploadSpinner" style="top:9px; flex-shrink:0;"></paper-spinner>
                  <katapult-button id="openMapLayersFileInput" name="overlay" on-click="openMapLayersFileInput"
                    >Choose File</katapult-button
                  >
                </div>
                <template is="dom-repeat" items="{{mapLayers}}" on-dom-change="notifyResize">
                  <template is="dom-if" if="[[equal(item.type, 'Overlay Layer')]]">
                    <div style="display: flex; align-items: center;">
                      <paper-input style="flex-grow: 1;" placeholder="Layer name" value="{{item.name}}" no-label-float=""></paper-input>
                      <katapult-button iconOnly noBorder icon="delete" on-click="openDeleteMapLayerItemConfirmDialog"></katapult-button>
                    </div>
                  </template>
                </template>
                <!-- <katapult-drop-down items="[[overlayInfoList]]" label="Choose a layer to add" label-path="name" on-selected-changed="addLayer" id="Overlay Layer"></katapult-drop-down> -->
              </div>
              <template is="dom-if" if='[[contains(["katapult", "davey_resource_group_inc"], userGroup)]]'>
                <h3 style="margin-top:0;"><iron-icon icon="maps:layers"></iron-icon>Reference Layers</h3>
                <div class="mapLayersManagerGroup">
                  <p>These layers are stored in database and are loaded for view of the map.</p>
                  <template is="dom-repeat" items="{{mapLayers}}" on-dom-change="notifyResize">
                    <template is="dom-if" if="[[equal(item.type, 'Reference Layer')]]">
                      <div style="display: flex; align-items: center;">
                        <paper-input style="flex-grow: 1;" placeholder="Layer name" value="{{item.name}}" no-label-float=""></paper-input>
                        <katapult-button iconOnly noBorder icon="delete" on-click="openDeleteMapLayerItemConfirmDialog"></katapult-button>
                      </div>
                    </template>
                  </template>
                  <katapult-drop-down
                    items="[[utilityInfoList]]"
                    label="Choose a layer to add"
                    label-path="name"
                    on-selected-changed="addApiOrReferenceLayer"
                    id="Reference Layer"
                  ></katapult-drop-down>
                </div>
              </template>

              <template is="dom-if" if="[[apiLayers]]">
                <h3 style="margin-top:0;"><iron-icon icon="maps:layers"></iron-icon>API Layers</h3>
                <div class="mapLayersManagerGroup">
                  <p>These layers query an external API</p>
                  <template is="dom-if" if="[[apiLayerLoadingMessage]]">
                    <div style="display: flex; flex-direction: row; align-items: center">
                      <i>[[apiLayerLoadingMessage]]</i>
                      <sl-spinner style="margin-left: var(--sl-spacing-small)"></sl-spinner>
                    </div>
                  </template>
                  <template is="dom-repeat" items="[[groupedApiLayers]]" on-dom-change="notifyResize">
                    <template is="dom-if" if="[[equal(item.type, 'API Layer')]]">
                      <!-- Show Layer Group -->
                      <template is="dom-if" if="[[item.groupedLayers]]">
                        <sl-tree on-sl-selection-change="unselectAllTreeItems">
                          <sl-tree-item style="display: flex; flex-direction: row; align-items: center;">
                            <div style="flex-grow: 1; font-size: 16px;">
                              <iron-icon icon="maps:layers" style="margin-right: var(--sl-spacing-2x-small)"></iron-icon>
                              <span>[[item.name]] ([[item.groupedLayers.length]])</span>
                            </div>
                            <katapult-button
                              iconOnly
                              noBorder
                              icon="delete"
                              on-click="openDeleteMapLayerItemConfirmDialog"
                            ></katapult-button>
                            <template is="dom-repeat" items="[[item.groupedLayers]]" as="item">
                              <sl-tree-item>
                                <span style="flex-grow: 1; font-size: 16px;">[[item.name]]</span>
                                <katapult-button
                                  iconOnly
                                  noBorder
                                  icon="delete"
                                  on-click="openDeleteMapLayerItemConfirmDialog"
                                ></katapult-button>
                              </sl-tree-item>
                            </template>
                          </sl-tree-item>
                        </sl-tree>
                      </template>
                      <!-- Show Single Layer -->
                      <template is="dom-if" if="[[!item.groupedLayers]]">
                        <div style="display: flex; flex-direction: row; align-items: center;">
                          <div style="flex-grow: 1; display: flex; flex-direction: row; font-size: 16px;">
                            <span>[[item.name]]</span>
                          </div>
                          <katapult-button iconOnly noBorder icon="delete" on-click="openDeleteMapLayerItemConfirmDialog"></katapult-button>
                        </div>
                      </template>
                    </template>
                  </template>
                  <katapult-drop-down
                    items="[[apiLayers]]"
                    label="Choose a layer to add"
                    label-path="name"
                    on-selected-changed="addApiOrReferenceLayer"
                    id="API Layer"
                    no-label-float
                  ></katapult-drop-down>
                </div>
              </template>
            </paper-dialog-scrollable>
            <div class="buttons">
              <katapult-button dialog-dismiss>Done</katapult-button>
            </div>
          </paper-dialog>

          <!--Map Layer Feature Dialog-->
          <paper-dialog id="editFeatureDialog" layered="false" entry-animation="scale-up-animation" exit-animation="fade-out-animation">
            <div title secondary-color>
              <template is="dom-if" if="[[!editingFeatureRef]]">
                <span>Edit Map Layer</span>
              </template>
              <template is="dom-if" if="[[editingFeatureRef]]">
                <span>Edit Map Layer Feature</span>
              </template>
            </div>
            <div body style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; align-items: center;">
                <span style="margin-right: 12px;">Color: </span>
                <katapult-color-picker-button
                  style="display:inline-block;margin:0 0 0 8px;"
                  color="{{editingMapLayerColor}}"
                ></katapult-color-picker-button>
              </div>
              <template is="dom-if" if="[[editingMapLayerFillColor]]">
                <div style="display: flex; align-items: center;">
                  <span style="margin-right: 12px;">Fill Color: </span>
                  <katapult-color-picker-button
                    style="display:inline-block;margin:0 0 0 8px;"
                    color="{{editingMapLayerFillColor}}"
                  ></katapult-color-picker-button>
                </div>
              </template>
              <div style="display: flex; align-items: center;">
                <span style="margin-right: 12px;">Weight: </span>
                <paper-input
                  style="margin-left: 8px;"
                  type="number"
                  value="{{editingMapLayerWeight}}"
                  min="0"
                  max="20"
                  no-label-float
                ></paper-input>
              </div>
              <template
                is="dom-if"
                if="[[showConfigurableIconFallback(editingFeatureRef, enabledFeatures.configurable_kmz_icon_fallback)]]"
              >
                <div style="display:flex; flex-direction: row; width: 100%; align-item: center">
                  <span>Default Icon: </span>
                  <template is="dom-if" if="[[editingMapLayerIcon]]">
                    <iron-icon
                      name="{{editingMapLayerIcon}}"
                      icon="{{editingMapLayerIcon}}"
                      style$="color:{{editingMapLayerIconColor}}; cursor:pointer; padding: 5px;"
                      on-click="openIconDialog"
                    ></iron-icon>
                    <katapult-color-picker-button
                      style="display:inline-block;margin:0 0 0 8px;"
                      color="{{editingMapLayerIconColor}}"
                    ></katapult-color-picker-button>
                  </template>
                  <template is="dom-if" if="[[!editingMapLayerIcon]]">
                    <katapult-button on-click="openIconDialog" style="margin-left: 8px;" size="20" color="var(--secondary-color)">
                      Choose Icon
                    </katapult-button>
                  </template>
                </div>
              </template>
            </div>
            <div class="buttons">
              <katapult-button dialog-dismiss>Cancel</katapult-button>
              <katapult-button color="var(--secondary-color)" callback="updateMapLayer">Save</katapult-button>
            </div>
          </paper-dialog>
          <paper-dialog
            id="copyFeatureDialog"
            layered="false"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            dynamic-align=""
          >
            <div title="" secondary-color="">Copy Feature?</div>
            <div body="">
              <katapult-drop-down
                label="Select Layer for Feature"
                style="display:block;"
                value="{{copyFeatureLayer}}"
                value-path="$key"
                label-path="name"
                items="[[mapLayers]]"
                show-last-results=""
              ></katapult-drop-down>
            </div>
            <div class="buttons">
              <katapult-button dialog-dismiss>Cancel</katapult-button>
              <katapult-button dialog-dismiss on-click="copyFeature" color="var(--secondary-color)">Copy</katapult-button>
            </div>
          </paper-dialog>
          <paper-dialog
            id="deleteFeatureDialog"
            layered="false"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            dynamic-align=""
          >
            <div title="" red="">Delete Feature?</div>
            <div body="">
              <p>Are you sure you want to delete this feature? This action cannot be undone</p>
            </div>
            <div class="buttons">
              <katapult-button dialog-dismiss>Cancel</katapult-button>
              <katapult-button dialog-dismiss on-click="deleteFeature" color="var(--paper-red-500)">Delete</katapult-button>
            </div>
          </paper-dialog>
          <!-- Map Layer Feature Property Dialog -->
          <paper-dialog
            id="editFeaturePropertyDialog"
            layered="false"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            dynamic-align=""
          >
            <div title="" secondary-color="">Edit [[camelCase(editingFeatureProperty)]]</div>
            <div body="">
              <paper-textarea
                style="max-height: 600px; overflow: auto;"
                value="{{editingFeaturePropertyValue}}"
                on-value-changed="editingFeaturePropertyChanged"
              ></paper-textarea>
            </div>
            <div class="buttons">
              <katapult-button dialog-dismiss>Cancel</katapult-button>
              <katapult-button color="var(--secondary-color)" callback="changeFeatureProperty">Save</katapult-button>
            </div>
          </paper-dialog>
          <!--Map Layer Delete Confirm Dialog-->
          <paper-dialog
            id="deleteMapLayerItemConfirmDialog"
            layered="false"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
          >
            <h2>Delete "{{mapLayerItemToDelete.name}}"</h2>
            <template is="dom-if" if="[[mapLayerItemToDelete.groupedLayers]]">
              <p>
                Are you sure you want to delete this map layer group? [[mapLayerItemToDelete.groupedLayers.length]] layers will be deleted
              </p>
            </template>
            <template is="dom-if" if="[[!mapLayerItemToDelete.groupedLayers]]">
              <p>Are you sure you want to delete this map layer</p>
            </template>
            <div class="buttons">
              <katapult-button dialog-dismiss>Cancel</katapult-button>
              <katapult-button color="var(--paper-red-500)" on-click="confirmDeleteMapLayerItem" dialog-dismiss>Yes</katapult-button>
            </div>
          </paper-dialog>

          <!--Warning dialog for copying nodes-->
          <paper-dialog
            id="checkCopyWarningDialog"
            style="width:500px"
            layered="false"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            on-iron-overlay-canceled="cancelPromptAction"
          >
            <h2 style="color:#d62020">Warning</h2>
            <template is="dom-if" if="{{copyFoundMatchingNodes}}">
              <p>
                Some of the nodes you chose to copy exist in {{copyingToJobName}}. If you proceed, some node data in {{copyingToJobName}}
                might be overwritten.
              </p>
            </template>
            <template is="dom-if" if="{{copyFoundDifferentStyles}}">
              <p>Due to differing map styles, the style will change on {{copyingStyleError}}.</p>
            </template>
            <div class="buttons">
              <katapult-button on-click="cancelPromptAction" dialog-dismiss>Cancel</katapult-button>
              <katapult-button on-click="copyNodesToJob" dialog-confirm>Proceed</katapult-button>
            </div>
          </paper-dialog>

          <!--Add attributes to all nodes confirm dialog-->
          <paper-dialog
            id="nodeTypeSelectDialog"
            layered="false"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            on-iron-overlay-canceled="cancelPromptAction"
          >
            <h2>Select types to include:</h2>

            <div id="nodeTypesList" style="display:grid">
              <template is="dom-repeat" items="{{itemTypeSelectDialogList}}">
                <paper-checkbox data-type$="{{item}}" data-index$="{{index}}" style="margin:5px;">{{camelCase(item)}}</paper-checkbox>
              </template>
              <template is="dom-if" if="{{nodeTypeSelectDialogAddAttributes}}">
                <paper-checkbox data-type="items with photos" style="margin:5px;">Items with Photos</paper-checkbox>
              </template>
            </div>

            <div class="buttons">
              <template is="dom-if" if="{{nodeTypeSelectDialogAddAttributes}}">
                <katapult-button on-click="_button_multi_add_attribute" dialog-dismiss>Back</katapult-button>
              </template>
              <template is="dom-if" if="{{nodeTypeSelectDialogCopyNodes}}">
                <katapult-button on-click="_button_copy_nodes" dialog-dismiss>Back</katapult-button>
              </template>
              <template is="dom-if" if="{{nodeTypeSelectDialogAddressData}}">
                <katapult-button on-click="_button_address_data" dialog-dismiss>Back</katapult-button>
              </template>
              <template is="dom-if" if="[[!nodeTypeSelectDialogHideCancelButton]]">
                <katapult-button on-click="cancelPromptAction" dialog-dismiss>Cancel</katapult-button>
              </template>
              <template is="dom-if" if="{{nodeTypeSelectDialogAddAttributes}}">
                <template is="dom-if" if="{{equal(multiAddAttributeOverwrite, 'delete')}}">
                  <katapult-button color="var(--secondary-color)" on-click="multiEditAttributesOfType" dialog-confirm
                    >Delete Attribute</katapult-button
                  >
                </template>
                <template is="dom-if" if="{{!equal(multiAddAttributeOverwrite, 'delete')}}">
                  <katapult-button color="var(--secondary-color)" on-click="multiEditAttributesOfType" dialog-confirm
                    >Add Attribute</katapult-button
                  >
                </template>
              </template>
              <template is="dom-if" if="{{nodeTypeSelectDialogCopyNodes}}">
                <katapult-button on-click="copyNodesOfType" dialog-confirm>Copy Nodes</katapult-button>
              </template>
              <template is="dom-if" if="{{nodeTypeSelectDialogAddressData}}">
                <katapult-button on-click="addAddressDataToNodeTypes" dialog-confirm>Get Address Data</katapult-button>
              </template>
              <template is="dom-if" if="{{nodeTypeSelectDialogAddressDataFilter}}">
                <katapult-button dialog-dismiss>Done</katapult-button>
              </template>
            </div>
          </paper-dialog>

          <!--Dialog of options for the QC for slack spans-->
          <paper-dialog
            id="qcSlackSpansOptionsDialog"
            no-cancel-on-outside-click=""
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
          >
            <h2>QC Slack Spans Options</h2>
            <br />
            <paper-checkbox style="margin-top:6px; margin-bottom:6px;" checked="{{qcSlackSpansAssessPower}}">Assess Power</paper-checkbox>
            <br />
            <paper-checkbox style="margin-top:6px; margin-bottom:6px;" checked="{{qcSlackSpansAssessComs}}">Assess Coms</paper-checkbox>
            <div class="buttons">
              <katapult-button dialog-dismiss>Run QC Check</katapult-button>
            </div>
          </paper-dialog>
          <!--End QC slack spans options dialog-->

          <!-- QC Dialog -->
          <paper-dialog
            id="qcDialog"
            position-target="{{getItemById('katapultMap')}}"
            horizontal-align="left"
            vertical-align="bottom"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            no-cancel-on-esc-key=""
            no-cancel-on-outside-click=""
            style="min-width:400px;max-width:500px;"
          >
            <h2>Quality Control</h2>
            <iron-pages selected="{{qcSelection}}">
              <div id="optionPage">
                <label id="firstLabel"><h4>Power Company</h4></label>
                <paper-radio-group selected="{{qcPowerCompany}}">
                  <template is="dom-repeat" items="{{calcList(otherAttributes)}}">
                    <paper-radio-button name="{{item.value}}">{{item.value}}</paper-radio-button>
                  </template>
                </paper-radio-group>
              </div>
              <div id="resultPage">
                <label id="scrollLabel"><h4>QC Output</h4></label>
              </div>
            </iron-pages>
            <paper-tabs selected="{{qcSelection}}" style="background-color:#e0e0e0;color:#1b3c52;" align-bottom="">
              <paper-tab>Options</paper-tab>
              <paper-tab>Results</paper-tab>
            </paper-tabs>
            <div id="buttons" class="buttons">
              <katapult-button dialog-dismiss icon="close">Close</katapult-button>
              <katapult-button on-click="buttonQC" id="runButton" color="#1b3c52" icon="bug_report">Run QC Test</katapult-button>
            </div>
          </paper-dialog>
          <!-- PPL QC Dialog -->
          <paper-dialog
            id="pplQcDialog"
            position-target="{{getItemById('mainHorizContainer')}}"
            horizontal-align="left"
            vertical-align="bottom"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            no-cancel-on-esc-key=""
            no-cancel-on-outside-click=""
            style="min-width:400px;max-width:500px;max-height:500px;"
          >
            <h2>Quality Control Results</h2>
            <div style="height:352px; width:400px; overflow:auto;">
              <template is="dom-if" if="{{!qcPPLReport}}">
                <div style="text-align:center;">
                  <paper-spinner style="vertical-align:middle;" active=""></paper-spinner>&nbsp;&nbsp;Preparing report...
                  <br />
                  <br />
                </div>
              </template>
              <template is="dom-if" if="{{pplQCWarningsExist(qcPPLReport.general_warnings)}}">
                <h4>General Warnings</h4>
                <template is="dom-repeat" items="{{qcPPLReport.general_warnings}}">
                  <div style="margin:0px;">{{item}}</div>
                </template>
              </template>
              <template is="dom-if" if="{{pplQCMissingsPolesExist(qcPPLReport.missing_poles)}}">
                <h4>Missing Poles</h4>
                <template is="dom-repeat" items="{{qcPPLReport.missing_poles}}">
                  <div style="margin:0px;">PPL tag {{item}} is not found in the job.</div>
                </template>
              </template>
              <template is="dom-if" if="{{pplQCPoleWarningsExist(qcPPLReport.warning_report)}}">
                <h4>Pole Warnings</h4>
                <template is="dom-repeat" items="{{getPoleWarningNodes(qcPPLReport.warning_report)}}">
                  <div style="margin-top:0px;margin-bottom:5px;">
                    <template is="dom-if" if="{{item.key}}">
                      <katapult-button
                        iconOnly
                        noBorder
                        icon="search"
                        on-click="qcViewNodeClicked"
                        on-dblclick="qcViewNodeDoubleClicked"
                        data-node-key$="{{item.key}}"
                      ></katapult-button>
                    </template>
                    [[modelDefaults.ordering_attribute_label]]: {{item.ordering_attribute}} - ({{item.node_type}})
                    <template is="dom-repeat" items="{{item.warnings}}" as="warning">
                      <div style="margin:0px;margin-left:20px;">{{warning}}</div>
                    </template>
                  </div>
                </template>
              </template>
            </div>
            <div class="buttons">
              <katapult-button dialog-dismiss icon="close">Close</katapult-button>
            </div>
          </paper-dialog>

          <network-load-analysis
            id="networkLoadAnalysis"
            job-id="[[job_id]]"
            nodes="[[nodes]]"
            connections="[[connections]]"
            model-defaults="[[modelDefaults]]"
            on-cancel-prompt-action="cancelPromptAction"
            on-auto-run-analysis-changed="autoRunLoadAnalysisChanged"
            on-zoom-to-item-clicked="zoomToLoadAnalysisAlertItem"
          ></network-load-analysis>

          <!-- Generic Warnings Dialog -->
          <!--
        To use this dialog:
          Set warningsDialogData to an object with the following properties:
              title: (String) the title of the report to display in the dialog
              
              summaryText: (String) a string with any general information you want to display
              
              errors: (Array) strings for the errors

              generalWarnings: (Array) strings for the warnings
              
              There are three Arrays (keys are nodeWarnings, connectionWarnings, and 
              sectionWarnings) that are a list of objects with the same set of properties as follows:
                  key: (String) the key for the node
                  connId: The id for the connection related to this item (null if the item is a node)
                  generalType: The general type of item (node, connection, or section)
                  description: (String) a description of the item
                  styledDescription: (optional Object) an object containing the ordering attributes (or names) of the two nodes that the section is between and the style to use for those ordering attributes
                      nodeOne: (String) the ordering attribute (or some other description) for the node
                      nodeOneStyle: (String) the CSS style to use on the text of nodeOne
                      nodeTwo: (String) the ordering attribute (or some other description) for the node
                      nodeTwoStyle: (String) the CSS style to use on the text of nodeTwo
                  warnings: (Array) an array of objects containing the following properties:
                      text: The text to display for the warning
                      key: A key that will generated for this specific warning 
                           for this specific item each time the report runs this
                           is used to allow the user to ignore specific warnings
      -->
          <katapult-dialog id="warningsDialog" position-target="{{getItemById('katapultMap')}}" width="550" draggable closeButton>
            <!--Dialog header-->
            <div slot="title">{{warningsDialogData.title}}</div>
            <template is="dom-if" if="{{enabledFeatures.qc_warnings_dialog_download_manager}}">
              <katapult-button
                id="downloadWarningsButton"
                slot="header-left-end"
                icon="file_download"
                iconOnly
                textColor="white"
                noBorder
                on-click="downloadWarnings"
              ></katapult-button>
              <paper-tooltip for="downloadWarningsButton">Download Manager</paper-tooltip>
            </template>

            <!--Dialog body-->
            <div class="warningsDialogBodySection">
              <!--Spinner to display when the data loading-->
              <template is="dom-if" if="{{!warningsDialogData}}">
                <div class="warningsDialogPreparingSection">
                  <paper-spinner active=""></paper-spinner>&nbsp;&nbsp;Preparing report...
                  <br />
                  <br />
                </div>
              </template>
              <!--Option to show hidden warnings-->
              <template is="dom-if" if="{{warningsDialog_DisplayShowAllToggle(warningsDialogData, jobWarningReportsData)}}">
                <paper-toggle-button checked="{{showAllDialogWarnings}}">Show Hidden Warnings</paper-toggle-button>
              </template>
              <!--Any summary text-->
              <p>{{warningsDialogData.summaryText}}</p>
              <!--A way to show basic messages that are not related to any items-->
              <template is="dom-if" if="{{warningsDialogData.messagesList.length}}">
                <template is="dom-repeat" items="{{warningsDialogData.messagesList}}">
                  <div style="margin-bottom:6px;">{{item.text}}</div>
                </template>
              </template>
              <!--Errors-->
              <template is="dom-if" if="{{warningsDialogData.errors.length}}">
                <div class="warningsDialogErrorSection">
                  <h4>Errors</h4>
                  <template is="dom-repeat" items="{{warningsDialogData.errors}}" as="error">
                    <div>{{warningsDialog_GetWarningText(error)}}</div>
                  </template>
                </div>
              </template>
              <!--Loops through all of the warning lists in the QC results-->
              <template
                is="dom-repeat"
                items="{{warningsDialog_GetResultsSections(warningsDialogData, warningsDialogData.*)}}"
                as="warningsSection"
              >
                <!--Check if the main section has any visible warnings and should be displayed-->
                <template
                  is="dom-if"
                  if="{{warningsDialog_SectionHasVisibleWarnings(warningsSection, jobWarningReportsData, showAllDialogWarnings)}}"
                >
                  <h4>{{warningsSection.title}}</h4>
                  <!--Loop through the items in this section-->
                  <template is="dom-repeat" items="{{warningsSection.itemList}}">
                    <!--Check if the current item in the list has any visible warnings and should be displayed-->
                    <template
                      is="dom-if"
                      if="{{warningsDialog_SectionHasVisibleWarnings(item, jobWarningReportsData, showAllDialogWarnings)}}"
                    >
                      <div class="warningsDialogItemSection">
                        <div class="warningsDialogItemDescriptionSection">
                          <!--The search icon used to find an item-->
                          <template is="dom-if" if="{{item.key}}">
                            <katapult-button
                              iconOnly
                              noBorder
                              icon="search"
                              on-click="warningsDialog_FindItem"
                              data-node-key$="{{item.key}}"
                            ></katapult-button>
                          </template>
                          <!--Any styled description-->
                          <template is="dom-if" if="{{item.styledDescription}}">
                            <span>section between </span
                            ><span style$="{{item.styledDescription.nodeOneStyle}}">{{item.styledDescription.nodeOne}}</span
                            ><span> and </span
                            ><span style$="{{item.styledDescription.nodeTwoStyle}}">{{item.styledDescription.nodeTwo}}</span>
                          </template>
                          <!--The regular description-->
                          <template is="dom-if" if="{{item.description}}"> {{item.description}} </template>
                        </div>
                        <!--Loop through the warnings for the item-->
                        <template is="dom-repeat" items="{{item.warnings}}" as="warning">
                          <template
                            is="dom-if"
                            if="{{warningsDialog_ShouldShowWarning(warning, jobWarningReportsData, showAllDialogWarnings)}}"
                          >
                            <div class="warningsDialogWarningSection">
                              <!--Check if this warning can be hidden or not-->
                              <div class="warningsDialogHideWarningButtonContainer">
                                <template is="dom-if" if="{{warningsDialog_CanHideWarning(warning)}}">
                                  <katapult-button
                                    iconOnly
                                    noBorder
                                    class="warningsDialogHideWarningButton"
                                    icon="{{warningsDialog_GetHideIcon(warning, jobWarningReportsData)}}"
                                    on-click="warningsDialog_ToggleHideWarning"
                                  ></katapult-button>
                                </template>
                              </div>
                              <div
                                class="warningsDialogWarningSection"
                                style$="{{warningsDialog_GetWarningTextStyle(warning, jobWarningReportsData)}}"
                              >
                                {{warningsDialog_GetWarningText(warning)}}
                              </div>
                            </div>
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </div>
          </katapult-dialog>
          <!-- End Generic QC Results Dialog -->
          <!-- PPL Invoice Dialog -->
          <paper-dialog
            id="pplInvoiceDialog"
            position-target="{{getItemById('katapultMap')}}"
            horizontal-align="left"
            vertical-align="bottom"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            no-cancel-on-esc-key=""
            no-cancel-on-outside-click=""
          >
            <paper-dialog-scrollable>
              <template is="dom-if" if="[[equal(pplInvoice.model, 'pci')]]">
                <h2 style="margin-top: 24px;">PCI Invoice Report</h2>
              </template>
              <template is="dom-if" if="[[!equal(pplInvoice.model, 'pci')]]">
                <h2 style="margin-top: 24px;">Invoice Report</h2>
              </template>
              <template is="dom-if" if="{{!pplInvoice}}">
                <div style="text-align:center;">
                  <paper-spinner style="vertical-align:middle;" active=""></paper-spinner>&nbsp;&nbsp;Preparing report...
                  <br />
                  <br />
                </div>
              </template>
              <template is="dom-if" if="{{pplInvoice}}">
                <div>
                  PPL Poles: {{pplInvoice.pole_counts.ppl_poles}} <br />
                  Foreign Poles: {{pplInvoice.pole_counts.foreign_poles}} <br />
                  <template is="dom-if" if="[[!equal(pplInvoice.pole_counts.poles_added_for_loading, null)]]"
                    >Poles Added for Loading: {{pplInvoice.pole_counts.poles_added_for_loading}} <br
                  /></template>
                  <template is="dom-if" if="[[!equal(pplInvoice.pole_counts.poles_loaded, null)]]"
                    >Poles Loaded: {{pplInvoice.pole_counts.poles_loaded}} <br
                  /></template>
                  Unknown Ownership Poles: {{pplInvoice.pole_counts.malformed_poles}} <br />
                  <br />
                  <template is="dom-if" if="[[!equal(pplInvoice.pole_counts.total_poles, null)]]"
                    >Total Pole Count: {{pplInvoice.pole_counts.total_poles}}
                  </template>
                </div>

                <template is="dom-if" if="[[pplInvoice.billingTotals]]">
                  <table style="text-align:left;">
                    <tbody>
                      <tr>
                        <th>Task</th>
                        <th style="padding:0 5px;">Count</th>
                        <th style="padding:0 5px;">Amount</th>
                        <th style="padding:0 5px;">Sum</th>
                      </tr>
                      <template is="dom-repeat" items="[[pplInvoice.billingTotals]]">
                        <template is="dom-if" if="[[!equal(item.label, 'Amount Previously Invoiced')]]">
                          <tr>
                            <td>[[item.label]]</td>
                            <td style="text-align:center;">[[item.count]]</td>
                            <td style="text-align:center;">$[[item.price]]</td>
                            <td style="text-align:center;">$[[item.total]]</td>
                          </tr>
                        </template>
                        <template is="dom-if" if="[[equal(item.label, 'Amount Previously Invoiced')]]">
                          <tr>
                            <td>&nbsp</td>
                            <td>&nbsp</td>
                            <td>&nbsp</td>
                            <td>&nbsp</td>
                          </tr>
                          <tr>
                            <td>[[item.label]]</td>
                            <td></td>
                            <td></td>
                            <td style="text-align:center;">-$[[item.total]]</td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </template>
                <div style="font-weight:bold; display:flex; justify-content:space-between;">
                  <template is="dom-if" if="[[equal(pplInvoice.model, 'pci')]]">
                    <div>AMOUNT TO INVOICE:</div>
                    <div>\${{pplInvoice.app_cost}}</div>
                  </template>
                  <template is="dom-if" if="[[!equal(pplInvoice.model, 'pci')]]">
                    <div>TOTAL:</div>
                    <div>\${{pplInvoice.app_cost}}</div>
                  </template>
                </div>
                <div class="buttons">
                  <katapult-button dialog-dismiss icon="close">Close</katapult-button>
                  <template is="dom-if" if="[[equal(pplInvoice.model, 'pci')]]">
                    <katapult-button
                      dialog-confirm
                      on-click="updatePCIAmountInvoiced"
                      data-amount$="[[pplInvoice.app_cost]]"
                      icon="close"
                      textColor="white"
                      >Mark As Invoiced</katapult-button
                    >
                  </template>
                </div>
              </template>
            </paper-dialog-scrollable>
          </paper-dialog>
          <!-- Job Feedback Dialog -->
          <paper-dialog id="jobFeedbackDialog" entry-animation="scale-up-animation" exit-animation="fade-out-animation">
            <h2>Send Feedback to {{getCompanyName(jobOwner)}}</h2>
            <paper-dialog-scrollable id="feedbackBody">
              <template is="dom-if" if="{{companyAdmins}}">
                <select-drop-down
                  id="selectDropDown"
                  label="To:"
                  selected-keys="{{feedbackTo}}"
                  items="{{companyAdmins}}"
                  displayed-property="email"
                  sort-by-key="email"
                  multi=""
                ></select-drop-down>
              </template>
              <template is="dom-if" if="{{!companyAdmins}}">
                <paper-input label="To:" floatinglabel="" class="sendFeedbackInput" value="{{feedbackToText}}"></paper-input>
              </template>

              <paper-input label="Cc:" floatinglabel="" class="sendFeedbackInput" value="{{feedbackCC}}"></paper-input>
              <paper-input label="Subject:" floatinglabel="" class="sendFeedbackInput" value="{{feedbackSubject}}"></paper-input>
              <paper-textarea label="Body" floatinglabel="" id="sendFeedbackBody" value="{{feedbackBody}}"></paper-textarea>
            </paper-dialog-scrollable>
            <div class="buttons">
              <katapult-button dialog-dismiss on-click="clearJobFeedbackFields">Cancel</katapult-button>
              <katapult-button dialog-confirm on-click="sendJobFeedback">Send Feedback</katapult-button>
            </div>
          </paper-dialog>
          <!--Time Bucket Dialog-->
          <paper-dialog id="timeBucketDialog" style="width:450px;">
            <h2>Time Bucket Warnings</h2>
            <div>
              The time buckets listed below are unusually large. You can choose to delete them before running the photo association, or run
              the association normally, including the large time buckets.
            </div>
            <paper-dialog-scrollable id="timeBucketBody">
              <template is="dom-repeat" items="{{associationResults.largeTimeBuckets}}">
                <div style="margin-top:5px;margin-left:15px;">{{item.type}} ({{item.description}}): {{item.timeAmount}}</div>
              </template>
            </paper-dialog-scrollable>
            <div class="buttons">
              <katapult-button dialog-confirm on-click="deleteLargeTimeBucketsThenAssociate">Delete, Then Associate</katapult-button>
              <katapult-button dialog-confirm on-click="commitAssociation">Associate Normally</katapult-button>
            </div>
          </paper-dialog>

          <div id="layersDialogWrapper">
            <!--Map Layers Dialog-->
            <div id="layersDialog">
              <!--App Context Info-->
              <iron-collapse opened="{{_computeOpened3(contextInfo, contextLayers)}}">
                <paper-item on-click="toggleDrawer" name="moreContextInfoDrawer">
                  <iron-icon class="menuDrawerIcon" icon="list"></iron-icon> Application Info
                </paper-item>
                <iron-collapse class="menuDrawer" id="moreContextInfoDrawer">
                  <!--App Context Info-->
                  <template is="dom-if" if="{{_hasContextInfo(contextInfo)}}">
                    <div style="padding:0 16px; text-align:center;">
                      <div class="contextInfoItem">{{contextInfo.submitting_company}}</div>
                      <div class="contextInfoItem"><span>{{contextInfo.polecount}}</span> Poles; <span>{{contextInfo.app_type}}</span></div>
                      <div class="contextInfoItem">Submitted <span>{{formatDate(contextInfo.date_submitted)}}</span></div>
                      <div class="contextInfoItem">[[contextInfo.cable_data]]</div>
                      <div class="contextInfoItem"><span class="contextInfoTitle">WR: </span>{{_computeExpression15(contextInfo)}}</div>
                      <div class="contextInfoItem"><span class="contextInfoTitle">WO: </span>{{_computeExpression16(contextInfo)}}</div>
                      <div class="contextInfoItem"><span class="contextInfoTitle">Status: </span>{{contextInfo.app_status}}</div>
                      <div class="contextInfoItem">
                        <span class="contextInfoTitle">Link: </span>
                        <a
                          class="contextInfoLink"
                          target="_blank"
                          href$="https://katapultwebservices.com/ppl/poleattachmentservices/ExistingApplication/{{contextInfo.applicationid}}"
                          >{{contextInfo.ppl_app_no}}</a
                        >
                      </div>
                      <template is="dom-if" if="{{contextInfo.app_files}}">
                        <div class="contextInfoItem">
                          <span class="contextInfoTitle">Files: </span>
                          <template is="dom-repeat" items="{{contextInfo.app_files}}" as="file">
                            <a
                              class="contextInfoLink"
                              target="_blank"
                              href$="[[getContextInfoLink(file.filepath, contextInfo.applicationid)]]"
                              >{{file.filepath}}</a
                            >
                          </template>
                        </div>
                      </template>
                      <template is="dom-if" if="{{contextInfo.app_notes}}">
                        <div class="contextInfoItem">
                          <span class="contextInfoTitle">Notes: </span>
                          <template is="dom-repeat" items="{{contextInfo.app_notes}}" as="note">
                            <span>{{note.notetext}}</span>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                  <!--Context Data Layers-->
                  <template is="dom-if" if="{{_computeOpened4(contextLayers, userGroup)}}">
                    <div style="margin:10px 0 0 16px; border-bottom: 1px solid #b1b1b1; padding-bottom: 5px;">Map Layers:</div>
                    <iron-selector attr-for-selected="name" selected-values="{{selectedContextLayers}}" multi="">
                      <template is="dom-repeat" items="{{contextLayers}}" as="layer">
                        <paper-item style="margin-left: 16px;" name="{{layer.name}}">{{camelCase(layer.name)}}</paper-item>
                      </template>
                    </iron-selector>
                  </template>
                </iron-collapse>
              </iron-collapse>
              <!--End App Context Info-->

              <!--Team Avatars-->
              <paper-item name="mapLabelsDrawer">
                <iron-icon class="menuDrawerIcon" icon="account-circle"></iron-icon>
                <div style="flex-grow:1;">Team Avatars</div>
                <paper-checkbox checked="{{showAvatars}}"></paper-checkbox>
              </paper-item>

              <!--Map Labels-->
              <paper-item on-click="toggleDrawer" name="mapLabelsDrawer" hidden$="{{equal(job_id, '')}}">
                <iron-icon class="menuDrawerIcon" icon="label"></iron-icon>
                <div style="flex-grow:1;">Labels</div>
                <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
                <paper-checkbox
                  checked="[[haveLabels(showSpanDistances, nodeLabels.*)]]"
                  on-click="stopPropagation"
                  on-change="toggleLabels"
                ></paper-checkbox>
              </paper-item>
              <iron-collapse class="menuDrawer" id="mapLabelsDrawer" hidden$="{{equal(job_id, '')}}">
                <div class="labelItem">Span Distances<paper-checkbox checked="{{showSpanDistances}}"></paper-checkbox></div>
                <div class="labelItem">Lowest Cable<paper-checkbox checked="{{nodeLabels.lowCable}}"></paper-checkbox></div>
                <div class="labelItem">Photo Count<paper-checkbox checked="{{nodeLabels.photoCount}}"></paper-checkbox></div>
                <template is="dom-repeat" items="[[objectKeys(nodeLabels, null, nodeLabels.*)]]">
                  <template is="dom-if" if="[[!isStandardMapLabel(item)]]">
                    <dom-bind
                      node-labels="[[nodeLabels]]"
                      item="[[item]]"
                      camel-case="[[camelCase]]"
                      bound-toggle-node-label="[[boundToggleNodeLabel]]"
                      bound-clear-node-label="[[boundClearNodeLabels]]"
                    >
                      <template>
                        <bind-path base="[[nodeLabels]]" path="[[item]]" value="{{labelVisible}}"></bind-path>
                        <div class="labelItem">
                          <span>[[camelCase(item)]]</span>
                          <paper-checkbox
                            item="[[item]]"
                            style="margin-left: 12px;"
                            checked="[[labelVisible]]"
                            on-change="boundToggleNodeLabel"
                          ></paper-checkbox>
                        </div>
                      </template>
                    </dom-bind>
                  </template>
                </template>
                <div style="display: flex; align-items: center;">
                  <katapult-drop-down
                    items="[[getAttributeKeysForTypes(otherAttributes, allowedLabelAttributeTypes, 'true')]]"
                    label="Add a label"
                    on-selected-changed="addNodeLabel"
                    on-focus="focusLabelInput"
                    on-blur="blurLabelInput"
                    only-open-down=""
                    label-function="[[camelCase]]"
                  ></katapult-drop-down>
                  <katapult-button
                    iconOnly
                    noBorder
                    id="clearLabelsButton"
                    icon="clear_all"
                    on-click="boundClearNodeLabels"
                    color="var(--paper-grey-200)"
                    style="margin: 16px; flex-shrink: 0"
                  ></katapult-button>
                  <paper-tooltip for="clearLabelsButton">Clear Unchecked</paper-tooltip>
                </div>
                <div style="display: flex; align-items: center">
                  <span>Label Font Size:</span>
                  <paper-input
                    value="{{labelFontSize}}"
                    type="number"
                    no-label-float
                    style="width: 40px; text-align: center; margin-left: 30px"
                    placeholder="0"
                  ></paper-input>
                </div>
              </iron-collapse>
              <!--End Map Labels-->

              <!--Map Legend-->
              <paper-item on-click="toggleDrawer" name="legendDrawer" hidden$="{{equal(job_id, '')}}">
                <iron-icon class="menuDrawerIcon" icon="info"></iron-icon>
                <div style="flex-grow:1;">Legend</div>
                <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
                <paper-checkbox
                  id="allLegendCheckboxes"
                  checked="[[legendItemChecked('all', hiddenLegendItems)]]"
                  on-click="toggleAllLegendCheckboxes"
                ></paper-checkbox>
              </paper-item>
              <iron-collapse class="menuDrawer" id="legendDrawer" hidden$="{{equal(job_id, '')}}">
                <template is="dom-repeat" items="{{legendItems}}">
                  <div class="legendItem">
                    <paper-checkbox
                      checked="[[legendItemChecked(item.id, hiddenLegendItems.*)]]"
                      class="legendCheckbox"
                      name="{{item.id}}"
                      on-click="toggleLegendItemCheckbox"
                    ></paper-checkbox>
                    <div class="legendIconContainer" style="flex-shrink:0; {{item.parentStyle}}">
                      <iron-icon icon="{{item.icon}}" style="{{item.style}}"></iron-icon>
                    </div>
                    {{item.title}}
                  </div>
                </template>
                <template is="dom-if" if="{{!isLiteTier(tier)}}">
                  <div class="legendItem">
                    <paper-checkbox
                      checked="[[legendItemChecked('mms', hiddenLegendItems.*)]]"
                      class="legendCheckbox"
                      name="mms"
                      on-click="toggleLegendItemCheckbox"
                    ></paper-checkbox>
                    <div class="legendIconContainer">
                      <iron-icon icon="katapult-map:triangle-up" style="color:#00f; width:18px; height:18px;"></iron-icon>
                    </div>
                    Midpoint Section
                  </div>
                  <div class="legendItem">
                    <paper-checkbox
                      checked="[[legendItemChecked('ms', hiddenLegendItems.*)]]"
                      class="legendCheckbox"
                      name="ms"
                      on-click="toggleLegendItemCheckbox"
                    ></paper-checkbox>
                    <div class="legendIconContainer">
                      <iron-icon icon="katapult-map:triangle-up" style="color:#0ff; width:18px; height:18px;"></iron-icon>
                    </div>
                    Other Section
                  </div>
                </template>
              </iron-collapse>
              <!--End Map Legend-->

              <!--Master Location Directory-->
              <paper-item
                on-click="toggleDrawer"
                name="masterLocationDirectoryLayersDrawer"
                hidden$="[[hideMasterLocationDirectoryDrawer(masterLocationDirectories)]]"
              >
                <iron-icon class="menuDrawerIcon" icon="find-in-page"></iron-icon>
                <div style="flex-grow:1;">Location Directories</div>
                <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
                <paper-checkbox
                  checked="[[showAllMasterLocationDirectoriesChecked(masterLocationDirectories, multiJobIds.*)]]"
                  on-click="toggleAllMasterLocationDirectories"
                ></paper-checkbox>
              </paper-item>
              <iron-collapse
                class="menuDrawer"
                id="masterLocationDirectoryLayersDrawer"
                hidden$="[[hideMasterLocationDirectoryDrawer(masterLocationDirectories)]]"
              >
                <template is="dom-repeat" items="[[masterLocationDirectories]]" as="locationDirectory">
                  <div class="masterLocationDirectoryContainer">
                    <paper-checkbox
                      checked="[[masterLocationDirectoryLayerIsOn(locationDirectory._id, multiJobIds.*)]]"
                      on-change="handleToggleMLDChecked"
                    >
                      [[locationDirectory.name]]
                    </paper-checkbox>
                    <template is="dom-if" if="[[locationDirectory.shared]]">
                      <iron-icon style="margin-left: 0.5em" icon="social:public"></iron-icon>
                    </template>
                  </div>
                </template>
              </iron-collapse>
              <!--End Master Location Directory-->

              <!--Job Layers-->
              <paper-item
                on-click="toggleDrawer"
                name="jobLayersDrawer"
                hidden$="[[hideJobDrawer(job_id)]]"
                data-lazy-load="../job-chooser/project-folder-panel.js"
              >
                <iron-icon class="menuDrawerIcon" icon="maps:map"></iron-icon>
                <div style="flex-grow:1;">Jobs</div>
                <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
                <paper-checkbox on-click="stopPropagation" checked="" on-change="toggleJobLayers"></paper-checkbox>
              </paper-item>
              <iron-collapse class="menuDrawer" id="jobLayersDrawer" hidden$="[[hideJobDrawer(job_id)]]">
                <div id="projectFolderPanelLoading" class="lazyLoadWaiting">
                  <paper-spinner-lite active=""></paper-spinner-lite>
                  <span>Loading Jobs</span>
                </div>
                <project-folder-panel
                  id="projectFolderPanel"
                  project-folders="{{projectFolders}}"
                  checked-folders="{{checkedFolders}}"
                  checked-jobs="{{multiJobIds}}"
                  main-job-id="[[job_id]]"
                  main-job-path="[[projectFolder]]"
                  job-chooser="{{getItemById('jobChooser')}}"
                  company-models="{{companyModels}}"
                  company-names="{{companyNames}}"
                  job-creator="[[jobCreator]]"
                  on-zoom="zoomToJobBounds"
                  on-toast="displayToastMessage"
                  job-chooser-updated="{{jobChooserJobsUpdated}}"
                  user-group="[[userGroup]]"
                  disabled="{{isJobAuth}}"
                  firebase-disabled="[[!signedIn]]"
                  model-options="[[modelOptions]]"
                ></project-folder-panel>
              </iron-collapse>
              <!--End Job Layers-->

              <!--Crumb Trails-->
              <template is="dom-if" if="[[equal(userGroup, 'katapult')]]">
                <paper-item on-click="toggleDrawer" name="crumbTrailsDrawer" hidden$="{{equal(job_id, '')}}">
                  <iron-icon class="menuDrawerIcon" icon="maps:my-location"></iron-icon>
                  <div style="flex-grow:1;">Crumb Trails</div>
                  <katapult-button
                    iconOnly
                    noBorder
                    id="loadCrumbTrailsButton"
                    icon="refresh"
                    loading="[[loadingCrumbTrails]]"
                    on-click="loadCrumbTrails"
                  ></katapult-button>
                  <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
                  <paper-checkbox
                    checked="[[crumbTrailsOn(crumbTrails.*)]]"
                    on-change="toggleAllCrumbTrails"
                    on-click="stopPropagation"
                  ></paper-checkbox>
                </paper-item>
                <iron-collapse class="menuDrawer" id="crumbTrailsDrawer" hidden$="{{equal(job_id, '')}}">
                  <template is="dom-repeat" items="{{crumbTrails}}">
                    <div style="padding:10px; display:flex; align-items: center;">
                      <paper-checkbox id="Toggle[[item.$key]]" checked="{{item.active}}" on-change="renderCrumbTrail"></paper-checkbox>
                      <span style="margin:0 5px;">[[item.date]]</span><user-chip uid="[[item.uid]]"></user-chip>
                    </div>
                  </template>
                </iron-collapse>
              </template>
              <!--End Crumb Trails-->

              <!--Imported Layers-->
              <paper-item on-click="toggleDrawer" name="mapLayersDrawer" hidden$="{{equal(job_id, '')}}">
                <iron-icon class="menuDrawerIcon" icon="maps:place"></iron-icon>
                <div style="flex-grow:1;">Imported Layers</div>
                <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
                <!-- TODO: improve mapLayersOn to correctly set itself as map layers turn on or off -->
                <paper-checkbox
                  checked="[[mapLayersAreOn]]"
                  on-checked-changed="toggleAllMapLayers"
                  on-click="stopPropagation"
                ></paper-checkbox>
              </paper-item>
              <iron-collapse class="menuDrawer" id="mapLayersDrawer" style="margin-right: 1.5em" hidden$="{{equal(job_id, '')}}">
                <map-layers-menu-view
                  id="mapLayersMenuView"
                  starting-layers="[[mapLayers]]"
                  multi-job-ids="[[multiJobIds]]"
                  geo-json-layers="[[geoJsonLayers]]"
                  overlays="[[overlays]]"
                  api-layer-groups="[[apiLayerGroups]]"
                  on-toggle-map-layer-item="toggleMapLayerItem"
                  on-toggle-map-layer-selectable="toggleMapLayerSelectable"
                ></map-layers-menu-view>
                <template is="dom-if" if="[[equal(_sharing, 'write')]]">
                  <katapult-button noBorder on-click="openMapLayersManager" icon="settings">Manage Layers...</katapult-button>
                </template>
              </iron-collapse>
              <!--End Imported Layers-->

              <!--Map Bases-->
              <paper-item on-click="toggleDrawer" name="mapBasesDrawer">
                <iron-icon class="menuDrawerIcon" icon="maps:terrain"></iron-icon>
                <div style="flex-grow:1;">Map Base</div>
                <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
                <paper-checkbox checked="[[mapBaseChecked(mapBase)]]" on-click="stopPropagation" on-change="toggleMapBase"></paper-checkbox>
              </paper-item>
              <iron-collapse class="menuDrawer" id="mapBasesDrawer">
                <paper-radio-group style="display:grid;" selected="{{mapBase}}">
                  <template is="dom-repeat" items="{{mapTypeIdsArray}}">
                    <paper-radio-button name="{{item.$key}}">{{item.$val}}</paper-radio-button>
                  </template>
                </paper-radio-group>
              </iron-collapse>
              <!--End Map Bases-->

              <!--Saved Views-->
              <paper-item on-click="toggleDrawer" name="savedViewsDrawer" hidden$="{{equal(job_id, '')}}">
                <iron-icon class="menuDrawerIcon" icon="save"></iron-icon>
                <div style="flex-grow:1;">Saved Views</div>
                <katapult-button iconOnly noBorder class="toggleIcon" icon="keyboard_arrow_down"></katapult-button>
              </paper-item>
              <iron-collapse class="menuDrawer" id="savedViewsDrawer" hidden$="{{equal(job_id, '')}}">
                <paper-radio-group style="display:grid;" selected="{{activeSavedView}}">
                  <template is="dom-repeat" items="{{savedViewsArray}}">
                    <paper-radio-button name="[[item.$key]]">{{either(item.name, 'Untitled View')}}</paper-radio-button>
                  </template>
                  <template is="dom-if" if="[[equal(_sharing, 'write')]]">
                    <katapult-button noBorder on-click="openSavedViewManager" icon="settings">Manage Saved Views...</katapult-button>
                  </template>
                </paper-radio-group>
              </iron-collapse>
              <!--End Saved Views-->
            </div>
          </div>
          <!--End Map Layers Dialog-->
          <katapult-feedback-chat
            id="feedbackChat"
            user-group="[[userGroup]]"
            map-styles="[[jobStyles]]"
            nodes="[[nodes]]"
          ></katapult-feedback-chat>

          <div id="mainVertContainer">
            <!--<input id="pdfFile" type="file" on-change="createMapPDF"/>-->
            <katapult-toolbar
              id="toolbar"
              style="flex-shrink: 0;"
              user-group="[[userGroup]]"
              flexible-regions$="[[pinSearchBar]]"
              job-id="[[job_id]]"
              job-creator="[[jobCreator]]"
              selected-node="[[selectedNode]]"
              active-connection="[[activeConnection]]"
              hide-login-chip="[[shouldShowReadOnlyWelcomeDialog]]"
            >
              <div slot="disclaimerContent" style="padding-top: 8px; height: 16px;">[[googleCopyrightText]]</div>
              <template is="dom-if" if="[[showTrainingReview(feedbackConfig, sharing)]]">
                <div slot="helpContent">
                  <katapult-button
                    style="text-transform:none; margin: 0 0.29em; padding: 0.7em 0.57em;"
                    on-click="fillInActionsForFeedbackReview"
                    noBorder
                    icon="rate_review"
                    textColor="white"
                    >Training Review</katapult-button
                  >
                </div>
              </template>

              <!-- Left Slot Content -->
              <katapult-search
                style="align-self: flex-start; [[if(pinSearchBar, 'width:300px; margin-left:-16px;')]];"
                slot="[[if(pinSearchBar, 'leftOfLogo', 'center')]]"
                filters=""
                filters-active$="[[poleListFilters.length]]"
              >
                <katapult-drop-down
                  id="poleList"
                  slot="input"
                  show-all-items="[[showAllItems]]"
                  items="[[poleListItems]]"
                  keep-open="[[pinSearchBar]]"
                  fixed-list-position="[[getPinnedListSize(pinSearchBar)]]"
                  search-text="{{poleListSearchText}}"
                  filter="[[poleListFilter]]"
                  label="[[if(job_id, 'Search Poles/Location', 'Search for a Location')]]"
                  label-path="label"
                  value-path="key"
                  no-label-float=""
                  on-selected-will-change="poleListSelectedWillChange"
                  on-selected-changed="poleListSelectedChanged"
                >
                  <paper-item style="flex-direction: initial;" on-click="dockSearchBar"
                    >[[if(pinSearchBar, 'Un-Dock', 'Dock to left')]]<iron-icon icon="katapult-map:pin" style="margin-left:10px;"></iron-icon
                  ></paper-item>
                </katapult-drop-down>
                <template is="dom-if" if="[[poleListMapSync]]">
                  <paper-cell id="mapItemsHidden" slot="input" icon style="padding: 8px;">
                    <material-icon style="color: var(--paper-red-500);" icon="visibility_off"></material-icon>
                  </paper-cell>
                  <paper-tooltip for="mapItemsHidden" slot="input">Some map items may be hidden</paper-tooltip>
                </template>
                <katapult-query-builder
                  id="poleListQuery"
                  slot="filter-content"
                  company-id="[[jobCreator]]"
                  job-id="[[job_id]]"
                  traces="[[traces]]"
                  on-query-changed="updatePoleListItems"
                >
                  <paper-cell slot="header" shrink>
                    <paper-toggle-button style="--primary-color: var(--secondary-color);" checked="{{poleListMapSync}}"
                      >Filter Map</paper-toggle-button
                    >
                  </paper-cell>
                </katapult-query-builder>
              </katapult-search>
              <job-chooser
                id="jobChooser"
                slot="left"
                alphabetical="[[orderJobsAlphabetically]]"
                hidden$="{{welcomeDialogOpened}}"
                selected="{{job_id}}"
                user-group="{{userGroup}}"
                disabled="{{isJobAuth}}"
                firebase-disabled="[[!signedIn]]"
                job-name="{{jobName}}"
                job-edit-button="[[showJobEditButton(sharing, readOnlyUser)]]"
                select-on-focus=""
                model-options="[[modelOptions]]"
                show-job-creator="[[showEditCreator(modelOptions)]]"
                no-create-new-job="{{readOnlyUser}}"
                on-set-job-id="setJobId"
                on-duplicate-job-data="duplicateJobData"
                on-delete-job="deleteJob"
                on-toast="displayToastMessage"
                on-search-focused="scrollToCurrent"
                on-open-folder-chooser="openFolderChooser"
                on-jobs-loaded="jobChooserLoad"
                folder-chooser-opened="[[folderChooserOpened]]"
                other-attributes="[[otherAttributes]]"
              >
              </job-chooser>

              <!-- Feedback button(s) -->
              <template is="dom-if" if="[[showLegacyFeedback]]">
                <katapult-button
                  id="sendFeedbackButton"
                  iconOnly
                  noBorder
                  hidden$="[[hideSendFeedback(job_id, _sharing)]]"
                  slot="left"
                  icon="question_answer"
                  title="Send Job Feedback (Legacy)"
                  on-click="openJobFeedback"
                ></katapult-button>
              </template>
              <template is="dom-if" if="[[!showLegacyFeedback]]">
                <katapult-button
                  id="openJobDialogue"
                  iconOnly
                  noBorder
                  hidden$="[[!job_id]]"
                  slot="left"
                  icon="question_answer"
                  title="Job Dialogue"
                  on-click="openJobDialogue"
                ></katapult-button>
              </template>

              <katapult-button
                id="downloadButton"
                hidden$="[[!job_id]]"
                slot="left"
                icon="file_download"
                iconOnly
                noBorder
                on-click="downloadJob"
              ></katapult-button>
              <paper-tooltip for="downloadButton" slot="left">Download Manager</paper-tooltip>
              <template is="dom-if" if="[[or(poleCount, assignedPoleCount)]]">
                <div slot="left" style="flex-grow: 1;"></div>
                <div id="nodeBreakdownContainer" opened$="{{showNodeBreakdown}}" slot="left">
                  <div id="poleCount" class$="{{computePoleCountClass(job_id, poleCount, assignedPoleCount)}}">
                    <span>{{poleCount}}</span>
                    <span hidden$="{{hidePoleCountTotal(assignedPoleCount)}}">&nbsp;of {{assignedPoleCount}}</span>
                    <template is="dom-if" if="{{showPolesLabel}}"><span>&nbsp;Poles Completed</span></template>
                    <template is="dom-if" if="{{!showPolesLabel}}"><span>&nbsp;Nodes Completed</span></template>
                    <iron-icon id="showNodeBreakdown" icon="info" on-click="toggleNodeBreakdown"></iron-icon>
                    <paper-tooltip for="nodeBreakdown">Node Breakdown</paper-tooltip>
                  </div>
                  <iron-collapse opened="[[showNodeBreakdown]]">
                    <div id="breakdownDetails">
                      <template is="dom-repeat" items="{{toArray(nodeBreakdown)}}" as="category">
                        <h4 style="margin: 5px 0px">{{category.label}}</h4>
                        <template is="dom-if" if="{{category.empty}}">
                          <span>&nbsp;None</span>
                        </template>
                        <template is="dom-repeat" items="{{category.data}}">
                          <template is="dom-if" if="{{equal(category.label, 'Pole Ownership')}}">
                            <span style="font-size: 15px">&nbsp;{{item}} -&nbsp;{{getCount(poleOwners, item)}}</span>
                          </template>
                          <template is="dom-if" if="{{equal(category.label, 'Completed Node Types')}}">
                            <span style="font-size: 15px">&nbsp;{{item.$key}} -&nbsp;{{item.done}} of {{item.count}} completed</span>
                          </template>
                        </template>
                      </template>
                    </div>
                  </iron-collapse>
                </div>
              </template>

              <div slot="left" style="flex-grow: 1;"></div>

              <!-- Center Slot Content -->

              <!-- Right Slot Content -->
              <div slot="right" style="flex-grow: 1;"></div>
              <template is="dom-if" if="[[job_id]]">
                <div id="stateButtons" slot="right">
                  <katapult-button
                    id="sagViewButton"
                    name="sagView"
                    icon="call_missed_outgoing"
                    iconOnly
                    noBorder
                    noBackground
                    on-click="toggleShowSag"
                    text-color="[[if(showSag, 'var(--paper-green-400)', '')]]"
                    hidden$="[[!enabledFeatures.sag_calculation_view]]"
                  ></katapult-button>
                  <paper-tooltip for="sagViewButton">Sag Calculation View</paper-tooltip>
                  <katapult-button
                    id="mrViewButton"
                    name="mrView"
                    icon="format_line_spacing"
                    iconOnly
                    noBorder
                    noBackground
                    on-click="toggleShowClearances"
                    text-color="[[if(showClearances, 'var(--paper-green-400)', '')]]"
                    hidden$="[[equal(makeReadyClearances.length, 0)]]"
                  ></katapult-button>
                  <paper-tooltip for="mrViewButton">Make Ready View</paper-tooltip>
                  <katapult-button
                    id="kplaViewButton"
                    name="kplaView"
                    icon="compare_arrows"
                    iconOnly
                    noBorder
                    noBackground
                    on-click="toggleShowKpla"
                    text-color="[[if(showKpla, 'var(--paper-green-400)', '')]]"
                    hidden$="[[!jobCreatorHasPoleLoadingModels]]"
                  ></katapult-button>
                  <paper-tooltip for="kplaViewButton">Pole Loading View</paper-tooltip>
                  <katapult-button
                    id="editLockButton"
                    name="lock"
                    icon="[[if(viewPublishedChecked, 'lock', 'lock_open')]]"
                    iconOnly
                    noBorder
                    noBackground
                    on-click="toggleEditableView"
                    text-color="[[if(viewPublishedChecked, 'var(--paper-red-500)', '')]]"
                  ></katapult-button>
                  <paper-tooltip for="editLockButton">Read Only Mode</paper-tooltip>
                  <katapult-button
                    id="cableTraceButton"
                    name="cableTrace"
                    icon="view_week"
                    iconOnly
                    noBorder
                    on-click="activateCableTracing"
                    text-color="[[if(cableTracing, 'var(--paper-blue-500)', '')]]"
                    hidden$="[[isLiteTier(tier)]]"
                  ></katapult-button>
                  <paper-tooltip for="cableTraceButton">Cable Trace View</paper-tooltip>
                  <katapult-button
                    id="togglePrintMode"
                    name="printView"
                    icon="print"
                    iconOnly
                    noBorder
                    on-click="togglePrintView"
                    text-color="[[if(viewMode, 'var(--paper-purple-300)', '')]]"
                  ></katapult-button>
                  <paper-tooltip for="togglePrintMode">Toggle Print Mode</paper-tooltip>
                </div>
                <div slot="right" style="flex-grow: 1;"></div>
                <katapult-button slot="right" callback="shareJob" icon="people"><span>Share</span></katapult-button>
                <template is="dom-if" if="[[shouldShowReadOnlyWelcomeDialog]]">
                  <katapult-button slot="pages" icon="account_circle" color="var(--sl-color-primary)" on-click="ShowReadOnlyWelcomeDialog"
                    >Create a Free Account</katapult-button
                  >
                </template>
              </template>
            </katapult-toolbar>
            <div id="mainHorizContainer">
              <template is="dom-if" if="[[pinSearchBar]]">
                <div id="pinnedSearchArea"></div>
              </template>
              <div id="mapContainer" style="flex-grow: 1; display: flex; flex-direction: row; position: relative;">
                <!--Fullscreen Photo Dialog-->
                <iron-overlay-backdrop
                  style="position: absolute; z-index: 3;"
                  tabindex="-1"
                  id="fullscreenPhoto"
                  entry-animation="scale-up-animation"
                  layered="false"
                >
                  <iron-a11y-keys keys="esc" on-keys-pressed="closeFullscreenPhoto"></iron-a11y-keys>
                  <div id="fullscreenPhotoButtons">
                    <katapult-button
                      title="Exit Photo"
                      icon="close"
                      color="var(--primary-text-color-faded)"
                      iconOnly
                      on-click="closeFullscreenPhoto"
                    ></katapult-button>

                    <template is="dom-if" if="[[fullscreenPhoto]]">
                      <katapult-button
                        title="Download Photo"
                        icon="file_download"
                        color="var(--primary-text-color-faded)"
                        iconOnly
                        on-click="downloadSinglePhoto"
                      ></katapult-button>

                      <template is="dom-if" if="[[fullscreenPhotoIsPanorama]]">
                        <template is="dom-if" if="[[!fullscreenPhotoIsShowingPanorama]]">
                          <katapult-button
                            title="Panorama View"
                            icon="panorama_photosphere"
                            color="var(--primary-text-color-faded)"
                            iconOnly
                            on-click="showFullscreenPanorama"
                          ></katapult-button>
                        </template>
                        <template is="dom-if" if="[[fullscreenPhotoIsShowingPanorama]]">
                          <katapult-button
                            title="2D View"
                            icon="image"
                            color="var(--primary-text-color-faded)"
                            iconOnly
                            on-click="hideFullscreenPanorama"
                          ></katapult-button>
                        </template>
                      </template>
                    </template>
                  </div>

                  <katapult-photo-viewer
                    id="fullscreenPhotoViewer"
                    zoom=""
                    photo-id="{{fullscreenPhoto}}"
                    use-lazy-loading
                    src="{{fullscreenPhotoSrc}}"
                    auto-resize=""
                    use-metric-units="[[useMetricUnits]]"
                    use-decimal-feet="[[useDecimalFeet]]"
                    has-make-ready-rules="[[!equal(makeReadyClearances.length, 0)]]"
                    job-id="[[job_id]]"
                    load-data=""
                    disabled="[[!fullscreenPhotoOpened]]"
                    show-sag="[[showSag]]"
                    show-clearances="[[showClearances]]"
                    on-reload-urls="reloadJobUrls"
                    toolbar="[[fullscreenPhoto]]"
                    auto-fit=""
                    on-enable-make-ready="toggleShowClearances"
                    visible-buttons="[[fullscreenButtons]]"
                    on-open-chooser="openChooser"
                    is-panorama="{{fullscreenPhotoIsPanorama}}"
                    showing-panorama="{{fullscreenPhotoIsShowingPanorama}}"
                  ></katapult-photo-viewer>
                  <template is="dom-if" if="[[fullscreenPhoto]]">
                    <iron-a11y-keys keys="left" on-keys-pressed="switchFullPhotoLeft"></iron-a11y-keys>
                    <iron-a11y-keys keys="right" on-keys-pressed="switchFullPhotoRight"></iron-a11y-keys>
                    <katapult-button
                      iconOnly
                      noBorder
                      id="fullPhotoLeft"
                      icon="chevron_left"
                      on-click="switchFullPhotoLeft"
                      color="var(--primary-text-color-faded)"
                    ></katapult-button>
                    <katapult-button
                      iconOnly
                      noBorder
                      id="fullPhotoRight"
                      icon="chevron_right"
                      on-click="switchFullPhotoRight"
                      color="var(--primary-text-color-faded)"
                    ></katapult-button>
                  </template>
                </iron-overlay-backdrop>

                <resizeable-div id="deliverablePhoto" right disabled="[[deliverablePhotoIsHidden]]" on-resize="deliverablePhotoResized">
                  <div style="top: 0; left: 0; bottom: 0; right: 0; position: absolute;">
                    <photo-controls
                      id="photoControls"
                      view="trace"
                      job-id="[[editingItemJob]]"
                      job-styles="[[jobStyles]]"
                      show-clearances="{{showClearances}}"
                      mr-clearances="[[getItemById('makeReadyClearances')]]"
                      proposed-cable-logic="[[proposedCableLogic]]"
                      use-metric-units="[[useMetricUnits]]"
                      use-decimal-feet="[[useDecimalFeet]]"
                      locked="[[!hasWritePermission]]"
                      tier="[[tier]]"
                      disabled="[[!signedIn]]"
                      connections="{{connections}}"
                      metadata="[[metadata]]"
                      attacher-name="[[attacherName]]"
                      model-config="{{modelConfig}}"
                      photo-viewer-lookup="{{photoViewerLookup}}"
                      show-one-click-menu="{{showOneClickMenu}}"
                      right-click-one-click-menu="{{rightClickOneClickMenu}}"
                      on-toast="displayToastMessage"
                      photo-summary-fb-url="{{concat('/jobs/', editingItemJob, '/photo_summary/')}}"
                      user-group="{{userGroup}}"
                      uid="{{user.uid}}"
                      job-creator="[[jobCreator]]"
                      photo="[[selectedDeliverablePhoto]]"
                      input-model-group="{{selectedInputModelGroup}}"
                      input-model-groups="[[inputModelGroups]]"
                      input-models="[[inputModels]]"
                      trace-models="{{traceModels}}"
                      other-attributes="[[otherAttributes]]"
                      routines="[[routines]]"
                      trace-listeners="{{traceListeners}}"
                      trace-items="[[traceItems]]"
                      default-power-company="{{defaultPowerCompany}}"
                      default-company="{{defaultCompany}}"
                      user-modules="[[userModules]]"
                      map-container="[[$.mapContainer]]"
                      model-defaults="[[modelDefaults]]"
                      dragging="[[dragging]]"
                    >
                      <katapult-photo-chooser
                        job-id="[[editingItemJob]]"
                        pci="true"
                        name="node"
                        uid="{{user.uid}}"
                        user-group="{{userGroup}}"
                        tier="[[tier]]"
                        use-metric-units="[[useMetricUnits]]"
                        use-decimal-feet="[[useDecimalFeet]]"
                        class="fitParent"
                        item="{{selectedDeliverablePhotoItem}}"
                        on-chooser-photo-tap="chooserPhotoTap"
                        on-open-transfer-heights="openTransferHeights"
                        model-config="{{modelConfig}}"
                      ></katapult-photo-chooser>
                      <katapult-photo-viewer
                        id="deliverablePhotoViewer"
                        job-id="[[editingItemJob]]"
                        job-styles="[[jobStyles]]"
                        job-creator="[[jobCreator]]"
                        metadata="[[metadata]]"
                        enabled-features="[[enabledFeatures]]"
                        visible-buttons="{{getVisibleButtons(tier, showClearances, _sharing, selectedDeliverableNode, modelConfig, userGroup, alternateDesignsConfig)}}"
                        name$="{{selectedDeliverablePhoto}}"
                        item="{{selectedDeliverablePhotoItem}}"
                        left-node="{{leftNode}}"
                        right-node="{{rightNode}}"
                        use-metric-units="[[useMetricUnits]]"
                        use-decimal-feet="[[useDecimalFeet]]"
                        photo-id="{{selectedDeliverablePhoto}}"
                        load-data=""
                        photo-association="{{selectedDeliverablePhotoAssociation}}"
                        disabled="[[!signedIn]]"
                        zoom=""
                        allow-tracing=""
                        published="{{!equal(_sharing, 'write')}}"
                        single-click-list="{{getSingleClickList(inputModelGroups, selectedInputModelGroup, routines, inputModels)}}"
                        annotations="{{showLines}}"
                        show-sag="{{showSag}}"
                        show-clearances="{{showClearances}}"
                        has-make-ready-rules="[[!equal(makeReadyClearances.length, 0)]]"
                        trace-listeners="{{traceListeners}}"
                        input-models="[[inputModels]]"
                        input-model-group-key="{{selectedInputModelGroup}}"
                        input-model-group-keys="[[inputModelGroupKeys]]"
                        other-attributes="[[otherAttributes]]"
                        users="{{users}}"
                        user-group="[[userGroup]]"
                        tabindex="0"
                        auto-resize=""
                        disclaimer-exists="{{photoDisclaimer}}"
                        default-power-company="[[defaultPowerCompany]]"
                        default-company="[[defaultCompany]]"
                        make-ready-clearances="[[getItemById('makeReadyClearances')]]"
                        config="{{modelConfig}}"
                        photo-first-config="{{photoFirstConfig}}"
                        left-photo-id="{{photoIdLeft}}"
                        right-photo-id="{{photoIdRight}}"
                        distance-ratios="{{distanceRatios}}"
                        no-fit=""
                        toolbar=""
                        katapult-drop-down-fit-into=""
                        show-kpla="[[showKpla]]"
                        model-defaults="[[modelDefaults]]"
                        alternate-designs-config="[[alternateDesignsConfig]]"
                        dragging="{{dragging}}"
                        use-lazy-loading
                        on-enable-make-ready="toggleShowClearances"
                        on-marker-selected="deliverableMarkerSelected"
                        on-reload-urls="reloadJobUrls"
                        on-confirm="viewerConfirm"
                        on-open-chooser="openChooser"
                        on-viewer-photo-tap="deliverablePhotoTap"
                        on-open-photo="openPhoto"
                        user-modules="[[userModules]]"
                        show-warnings
                      >
                        <katapult-photo-label
                          user-group="[[userGroup]]"
                          uid="[[user.uid]]"
                          company="[[jobCreator]]"
                          nodes="[[selectedDeliverablePhotoItem]]"
                          show-clearances="{{showClearances}}"
                          slot="toolbar-label"
                          user-modules="[[userModules]]"
                        ></katapult-photo-label>
                      </katapult-photo-viewer>
                    </photo-controls>
                  </div>
                  <!-- <div style="position: absolute; top: 24px; left: 24px; background: white; padding: 8px; border-radius: 24px; z-index: 999;">
                <paper-toggle-button checked="{{threeDActive}}">
                  <iron-icon icon="3d-rotation"></iron-icon>
                </paper-toggle-button>
              </div> -->
                  <div class="handle" on-click="toggleHideDeliverablePhoto" disabled$="[[!selectedDeliverablePhoto]]">
                    <iron-icon icon="chevron-right" rotate$="[[!deliverablePhotoIsHidden]]"></iron-icon><paper-ripple noink></paper-ripple>
                  </div>
                </resizeable-div>

                <coordinate-pa-api
                  id="coordinatePAapi"
                  user-group="[[userGroup]]"
                  metadata="[[metadata]]"
                  nodes="[[nodes]]"
                  connections="[[connections]]"
                  trace-data="[[traces]]"
                  job-id="[[job_id]]"
                  job-name="[[jobName]]"
                  job-creator="[[jobCreator]]"
                  alternate-designs-config="[[alternateDesignsConfig]]"
                  model-defaults="[[modelDefaults]]"
                  other-attributes="[[otherAttributes]]"
                  on-toast="displayToastMessage"
                ></coordinate-pa-api>

                <njuns-ticket-creator
                  id="njunsTicketCreator"
                  job-creator="[[jobCreator]]"
                  njuns-api="[[boundNjunsApi]]"
                  geocode="[[boundGeocode]]"
                  map="[[map]]"
                  njuns-token="{{njunsToken}}"
                  on-toast="displayToastMessage"
                ></njuns-ticket-creator>
                <katapult-map
                  id="katapultMap"
                  action-dialog-data="{{actionDialogData}}"
                  map="{{map}}"
                  job-id="{{job_id}}"
                  job-creator="[[jobCreator]]"
                  job-ids="{{multiJobIds}}"
                  nodes="{{nodes}}"
                  mapping-buttons="[[mappingButtons]]"
                  label-font-size="{{labelFontSize}}"
                  latitude="{{latitude}}"
                  longitude="{{longitude}}"
                  zoom="{{zoom}}"
                  projection="{{projection}}"
                  other-attributes="[[otherAttributes]]"
                  editing-node-and-conn="{{editingNodeAndConn}}"
                  editing-item-job="{{editingItemJob}}"
                  custom-map-types="{{customMapTypes}}"
                  job-styles="[[jobStyles]]"
                  link-map-photo-actions="[[linkMapPhotoActions]]"
                  cluster-max-zoom="{{clusterMaxZoom}}"
                  company-name="[[companyName]]"
                  editing="{{editing}}"
                  undo-log="{{undoLog}}"
                  model-config="{{modelConfig}}"
                  config="{{config}}"
                  dont-link-spawned-windows="[[dontLinkSpawnedWindows]]"
                  selected-node="{{selectedNode}}"
                  editing-node="{{editingNode}}"
                  active-command="{{activeCommand}}"
                  active-command-data="[[activeCommandData]]"
                  active-command-model="[[activeCommandModel]]"
                  action-dialog-model="[[actionDialogModel]]"
                  active-connection="{{activeConnection}}"
                  active-section="{{activeSection}}"
                  can-drag-override="{{canDragOverride}}"
                  cancel-after-drag="{{cancelAfterDrag}}"
                  cable-tracing="{{cableTracing}}"
                  connection-tracing="{{connectionTracing}}"
                  hidden-markers="{{hiddenLegendItems}}"
                  hidden-nodes="[[hiddenNodes]]"
                  hidden-connections="[[hiddenConnections]]"
                  node-labels="{{nodeLabels}}"
                  multi-select-import-polygon="{{multiSelectImportPolygon}}"
                  multi-select-included-types="{{multiSelectIncludedTypes}}"
                  map-type-ids="{{mapTypeIds}}"
                  map-base="{{mapBase}}"
                  prev-map-base="{{prevMapBase}}"
                  use-metric-units="[[useMetricUnits]]"
                  use-decimal-feet="[[useDecimalFeet]]"
                  show-span-distances="[[showSpanDistances]]"
                  uid="[[user.uid]]"
                  user-group="[[userGroup]]"
                  tier="[[tier]]"
                  gps-lat="{{gpsLat}}"
                  gps-lng="{{gpsLng}}"
                  snapping-angle="{{snappingAngle}}"
                  record-node-move-attribute="[[recordNodeMoveAttribute]]"
                  on-google-map-ready="googleMapReady"
                  on-map-drop="handleMapDrop"
                  on-select-item="selectItem"
                  on-item-mouseover="itemMouseover"
                  on-item-mouseout="itemMouseout"
                  on-make-section-primary="makeSectionPrimary"
                  on-click-map="clickMap"
                  on-annotation-dbl-click="openAnnotationEditor"
                  on-annotation-drag="dragAnnotation"
                  on-annotation-mouseover="annotationMouseover"
                  on-annotation-mouseout="annotationMouseout"
                  on-cancel="cancelPromptAction"
                  on-toast="displayToastMessage"
                  on-loading-changed="loadRenderMapLoadingChanged"
                  measurable="[[!config.firebaseData.hideMapMeasureButton]]"
                  mouse-lat="{{mouseLat}}"
                  mouse-lng="{{mouseLng}}"
                  signed-in="[[signedIn]]"
                  read-only="[[!equal(_sharing, 'write')]]"
                  on-highlight-conn="highlightConn"
                  model-defaults="[[modelDefaults]]"
                  on-item-drawn="itemDrawn"
                  on-zoom-to-node="receiveZoomToNodeEvent"
                >
                  <map-annotation-editor
                    id="mapAnnotationEditor"
                    action-dialog-data="{{actionDialogData}}"
                    slot="content"
                  ></map-annotation-editor>
                  <template is="dom-if" if="[[metadata.snapshot_of_job]]">
                    <div class="snapshotInfo" slot="content">
                      <material-icon icon="wallpaper"></material-icon>
                      <template is="dom-if" if="[[metadata.parent_job]]">
                        <!-- Snapshot Parent Link -->
                        <template is="dom-if" if="[[parentJobLink]]">
                          <span style="margin-left: 8px;"
                            >Viewing Snapshot of
                            <a style="color: var(--secondary-color);" href$="[[parentJobLink]]" target="_blank"
                              >[[metadata.parent_job]]</a
                            ></span
                          >
                        </template>
                        <!-- Snapshot Parent Name -->
                        <template is="dom-if" if="[[!parentJobLink]]">
                          <span style="margin-left: 8px;">Viewing Snapshot of [[metadata.parent_job]]</span>
                        </template>
                      </template>
                      <!-- Snapshot Name -->
                      <template is="dom-if" if="[[!metadata.parent_job]]">
                        <span style="margin-left: 8px;">Viewing Snapshot: [[jobName]]</span>
                      </template>
                    </div>
                  </template>
                  <div id="notificationDialogContainer" slot="content">
                    <div id="notificationDialog" opened$="[[showUploadNotification]]">
                      <div id="notificationDialogTitle">
                        <span>Uploading</span>
                        <!--1/2 height of poles completed-->
                        <progress-bar
                          id="notificationProgressBar"
                          label="[[getProgressLabel(uploadsComplete, uploadsInProgress)]]"
                          min="0"
                          max="[[uploadsInProgress]]"
                          value="[[uploadsComplete]]"
                        ></progress-bar>
                        <span>[[progressPercentage]]%</span>
                      </div>
                      <template is="dom-if" if="[[uploadMessage]]">
                        <div id="uploadMessage">[[uploadMessage]]</div>
                      </template>
                    </div>
                  </div>
                  <!-- Avatar Markers -->
                  <template is="dom-repeat" items="[[avatars.list]]">
                    <google-map-rich-marker
                      id="avatar.[[item.key]]"
                      latitude="[[item.latitude]]"
                      longitude="[[item.longitude]]"
                      rich-content="[[item.iconContent]]"
                      name="[[item.dragged]]"
                      click-events="false"
                      clickable="false"
                      z-index="100"
                      cursor="grab"
                      draggable="true"
                      drag-events="true"
                      on-google-map-marker-dragend="dragAvatar"
                      slot="markers"
                    >
                    </google-map-rich-marker>
                  </template>
                </katapult-map>
                <wms-layers
                  map="[[map]]"
                  user-group="[[userGroup]]"
                  map-type-ids="{{mapTypeIds}}"
                  map-initialized="[[mapInitialized]]"
                  allow-map-click="[[!or(selectedNode, activeCommand)]]"
                  map-base="[[mapBase]]"
                ></wms-layers>
              </div>
              <print-mode-toolbar
                id="printModeToolbar"
                _sharing="{{_sharing}}"
                view-mode="{{viewMode}}"
                job-id="[[job_id]]"
                job-name="[[jobName]]"
                nodes="[[nodes]]"
                model-config="[[modelConfig]]"
                model-defaults="[[modelDefaults]]"
                connections="[[connections]]"
                traces="[[traces]]"
                other-attributes="[[otherAttributes]]"
                use-metric-units="[[useMetricUnits]]"
                use-decimal-feet="[[useDecimalFeet]]"
                job-creator="[[jobCreator]]"
                print-generator="{{getItemById('printGenerator')}}"
                user-group="[[userGroup]]"
                job-styles="[[jobStyles]]"
                disabled="[[!signedIn]]"
              >
              </print-mode-toolbar>
              <katapult-tool-panel
                id="infoPanel"
                user="{{user}}"
                admin="[[isAdmin]]"
                user-group="{{userGroup}}"
                root-company="[[rootCompany]]"
                opened="{{infoPanelOpen}}"
                metadata="{{metadata}}"
                connections="{{connections}}"
                attribute-groups-model="{{attributeGroupsModel}}"
                company-options="[[companyOptions]]"
                job-snapshots="{{jobSnapshots}}"
                _sharing="{{_sharing}}"
                sharing="{{sharing}}"
                shared-companies="[[sharedCompanies]]"
                published="{{status.published}}"
                tier="{{tier}}"
                config="{{config}}"
                editing="{{editing}}"
                editing-node-and-conn="{{editingNodeAndConn}}"
                main-job-id="{{job_id}}"
                main-job-name="{{jobName}}"
                job-id="{{editingItemJob}}"
                job-name="{{editingItemJobName}}"
                job-creator="[[jobCreator]]"
                job-owner="[[jobOwner]]"
                job-permissions="[[jobPermissions]]"
                project-id="[[projectId]]"
                node-id="{{editingNode}}"
                conn-id="{{activeConnection}}"
                section-id="{{activeSection}}"
                job-styles="[[jobStyles]]"
                show-filters-object="{{showFiltersObject}}"
                show-span-distances="{{showSpanDistances}}"
                cable-tracing="[[cableTracing]]"
                node-labels="{{nodeLabels}}"
                photo-labels="{{photoLabels}}"
                other-attributes="[[getJobModels('otherAttributes', editingItemJob, job_id, companyModels.*, otherAttributes.*)]]"
                mapping-buttons="[[mappingButtons]]"
                button-groups="[[buttonGroups]]"
                button-group="{{buttonGroup}}"
                use-metric-units="[[useMetricUnits]]"
                use-decimal-feet="[[useDecimalFeet]]"
                active-toolset="{{activeToolset}}"
                user-group="[[userGroup]]"
                model-defaults="[[modelDefaults]]"
                model-options="[[modelOptions]]"
                nodes="[[nodes]]"
                drawing-polygon="[[drawingPolygon]]"
                disabled="[[!signedIn]]"
                display-application="{{displayApplication}}"
                on-close="closeInfoPanel"
                on-open-photo-first="openPhotoFirst"
                on-open-photo="openPhoto"
                on-toggle-polyline-editing="togglePolylineEditing"
                on-attribute-button-click="attributeButtonClick"
                on-select-deliverable-photo="selectDeliverablePhoto"
                on-update-alternate-design-data="updateAlternateDesignData"
                on-download-pole-data="downloadJob"
                on-delete="deleteItem"
                on-reload-urls="reloadJobUrls"
                on-link-down-guys="doHwDetailForDnGuy"
                on-move-anchor="editAnchorLocation"
                on-mapping-button-pressed="mappingButtonPressed"
                on-trace-pole="tracePole"
                on-table-button-action="tableButtonAction"
                model-defaults="[[modelDefaults]]"
                on-toast="displayToastMessage"
                company-names="[[companyNames]]"
                show-legacy-feedback="[[showLegacyFeedback]]"
                is-review-contractor="[[isUtilityReviewContractor]]"
                on-open-3d-view="open3DView"
              ></katapult-tool-panel>
            </div>
          </div>

          <!-- Drag Photo Menu -->
          <div id="dragPhotoMenu">
            <paper-item style="margin:0;padding: 0 15px" on-click="dragMovePhoto"
              ><iron-icon icon="open-with"></iron-icon>Move Photo</paper-item
            >
            <!--<paper-item style="margin:0;padding: 0 15px" on-click="dragAddPhoto"><iron-icon icon="image:add-to-photos"></iron-icon>Add Photo</paper-item>-->
            <paper-item style="margin:0;padding: 0 15px" on-click="dragDuplicatePhoto"
              ><iron-icon icon="content-copy"></iron-icon>Duplicate Photo</paper-item
            >
          </div>

          <!-- Create Job Form -->
          <create-job-form
            id="createJobForm"
            user-group="[[userGroup]]"
            model-options="[[modelOptions]]"
            company-names="[[companyNames]]"
            on-choose-new-job-location="chooseNewJobLocation"
            is-review-contractor="[[isUtilityReviewContractor]]"
            other-attributes="[[otherAttributes]]"
          ></create-job-form>
          <!-- Getting Started Dialog -->
          <paper-dialog
            id="welcomeDialog"
            opened="{{welcomeDialogOpened}}"
            exit-animation="katapult-slide-up-left-animation"
            style="width: 400px;"
            no-cancel-on-outside-click
            no-cancel-on-esc
          >
            <h2 title secondary-color>Welcome to {{config.firebaseData.name}} Maps</h2>
            <div style="margin: 24px 0">
              <job-chooser
                alphabetical="[[orderJobsAlphabetically]]"
                id="welcomeJobChooser"
                underline
                selected="{{job_id}}"
                disabled="{{isJobAuth}}"
                firebase-url="{{config.firebaseUrl}}"
                user-group="{{userGroup}}"
                firebase-disabled="[[!signedIn]]"
                select-on-focus
                only-open-down
                model-options="[[modelOptions]]"
                show-job-creator="[[showEditCreator(modelOptions)]]"
                no-create-new-job
                on-toast="displayToastMessage"
                on-search-focused="scrollToCurrent"
                on-open-folder-chooser="openFolderChooser"
                katapult-drop-down-scroll-target
                other-attributes="[[otherAttributes]]"
              >
              </job-chooser>
            </div>
            <div class="buttons" style="justify-content: space-between;">
              <katapult-button on-click="dismissNewJob" style="min-width:75px;">Skip</katapult-button>
              <template is="dom-if" if="{{!equal(tier, 'read only')}}">
                <div style="display: flex; align-items: center;">
                  <paper-spinner style="display: block; margin-right: 5px;" active="[[createJobLoading]]"></paper-spinner>
                  <katapult-button id="createNewJobButton" on-click="createNewJob" color="var(--secondary-color)"
                    >Create New Job</katapult-button
                  >
                </div>
              </template>
            </div>
          </paper-dialog>
          <!-- Catalog Import Dialog -->
          <paper-dialog
            id="catalogImportDialog"
            entry-animation="scale-up-animation"
            exit-animation="fade-out-animation"
            no-cancel-on-esc-key
            no-cancel-on-outside-click
          >
            <div title secondary-color>
              <iron-icon icon="image:photo-filter"></iron-icon>
              <span>Getting Started</span>
            </div>
            <div body>
              <katapult-model-editor-maps-getting-started-wizard
                config="[[config]]"
                style="width: 800px; height: 400px; display: block;"
                user-group="[[userGroup]]"
                user-modules="[[userModules]]"
                on-finished="gettingStartedWizardFinished"
              >
              </katapult-model-editor-maps-getting-started-wizard>
            </div>
          </paper-dialog>
          <!--Zoom to Location Dialog-->
          <paper-dialog
            id="zoomToLocationDialog"
            exit-animation="katapult-slide-left-animation"
            no-cancel-on-outside-click=""
            no-cancel-on-esc=""
          >
            <div style="display:flex; margin-top:2px">
              <paper-input id="zoomToLocationDialogInput" autofocus="" label="Zoom to a location" on-keypress="searchForLocationKeypress">
                <katapult-button iconOnly noBorder icon="search" on-click="searchForLocation" slot="suffix"></katapult-button>
              </paper-input>
            </div>
            <div class="buttons">
              <katapult-button style="margin:-10px 0 0 0;" dialog-dismiss on-click="cancelZoomToLocation">Done</katapult-button>
            </div>
          </paper-dialog>
          <!-- Node Over Connection Toleratnce Dialog -->
          <katapult-dialog id="nodeOverConnectionToleranceDialog" title="Node Over Connection" width="400">
            <p>Enter a value(in feet) for checking if a node is over a connection</p>
            <paper-input label="Distance" autoValidate type="number" min="0" floatinglabel="" value="{{distanceValue}}"></paper-input>
            <katapult-button slot="buttons" data-run-tolerance="false" on-click="finishNodeOverConnection">Cancel</katapult-button>
            <katapult-button slot="buttons" color="var(--secondary-color)" data-run-tolerance="true" on-click="finishNodeOverConnection"
              >Continue</katapult-button
            >
          </katapult-dialog>
          <katapult-dialog id="lineAngleCalculatorDialog" title="Line Angle Calculations" width="400" closeButton draggable>
            <paper-table>
              <paper-row>
                <paper-cell style="align-items:flex-start; min-width:125px;"> Break Angle </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> - </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> [[lineAngleResults.breakAngle]]° </paper-cell>
                <paper-cell>
                  <katapult-button
                    id="breakAngle"
                    icon="content_copy"
                    size="25"
                    on-click="copyLineAngleCalc"
                    iconOnly
                    noBorder
                    noBackground
                  ></katapult-button>
                </paper-cell>
              </paper-row>
              <paper-row>
                <paper-cell style="align-items:flex-start; min-width:125px"> Outer Angle </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> - </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> [[lineAngleResults.outerAngle]]° </paper-cell>
                <paper-cell>
                  <katapult-button
                    id="outerAngle"
                    icon="content_copy"
                    size="25"
                    on-click="copyLineAngleCalc"
                    iconOnly
                    noBorder
                    noBackground
                  ></katapult-button>
                </paper-cell>
              </paper-row>
              <paper-row>
                <paper-cell style="align-items:flex-start; min-width:125px"> Inner Angle </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> - </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> [[lineAngleResults.innerAngle]]° </paper-cell>
                <paper-cell>
                  <katapult-button
                    id="innerAngle"
                    icon="content_copy"
                    size="25"
                    on-click="copyLineAngleCalc"
                    iconOnly
                    noBorder
                    noBackground
                  ></katapult-button>
                </paper-cell>
              </paper-row>
              <paper-row>
                <paper-cell style="align-items:flex-start; min-width:125px"> Feet Of Pull </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> - </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> [[lineAngleResults.feetOfPull]]' </paper-cell>
                <paper-cell>
                  <katapult-button
                    id="feetOfPull"
                    icon="content_copy"
                    size="25"
                    on-click="copyLineAngleCalc"
                    iconOnly
                    noBorder
                    noBackground
                  ></katapult-button>
                </paper-cell>
              </paper-row>
              <paper-row>
                <paper-cell style="align-items:flex-start; min-width:125px"> Feet Off Pole </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> - </paper-cell>
                <paper-cell style="align-items:flex-start; padding-left:2em;"> [[lineAngleResults.feetOffPole]]' </paper-cell>
                <paper-cell>
                  <katapult-button
                    id="feetOffPole"
                    icon="content_copy"
                    size="25"
                    on-click="copyLineAngleCalc"
                    iconOnly
                    noBorder
                    noBackground
                  ></katapult-button>
                </paper-cell>
              </paper-row>
            </paper-table>
          </katapult-dialog>
          <katapult-dialog-legacy id="test">
            <attribute-selector
              slot="body"
              all-attributes="[[otherAttributes]]"
              groups-model="[[attributeGroupsModel]]"
              dialog="true"
            ></attribute-selector>
          </katapult-dialog-legacy>

          <!-- Remove Annotation Data Dialog -->
          <katapult-dialog id="removeAnnotationDataDialog" title="Remove Annotation Data" width="800">
            <h3>Are you sure you want to remove all annotation data?</h3>
            <p>Photos will remain associated to their current locations. A Job Snapshot will be created automatically before deletion.</p>
            <paper-table>
              <paper-row>
                <paper-cell>
                  <paper-checkbox style="margin-top:6px; margin-bottom:6px; width: 50%" checked="{{deleteMarkerData}}"
                    >Delete Marker Data</paper-checkbox
                  >
                </paper-cell>
                <paper-cell>
                  <paper-checkbox
                    style="margin-top:6px; margin-bottom:6px; width: 50%"
                    checked="{{deleteTraceData}}"
                    disabled="{{deleteMarkerData}}"
                    >Delete Trace Data</paper-checkbox
                  >
                </paper-cell>
              </paper-row>
              <paper-row>
                <paper-cell>
                  <paper-checkbox style="margin-top:6px; margin-bottom:6px; width: 50%" checked="{{deleteCalibrationData}}"
                    >Delete Height Calibration Data</paper-checkbox
                  >
                </paper-cell>
                <paper-cell>
                  <paper-checkbox style="margin-top:6px; margin-bottom:6px; width: 50%" checked="{{deleteClassificationData}}"
                    >Delete Photo Classifications Data</paper-checkbox
                  >
                </paper-cell>
              </paper-row>
            </paper-table>
            <katapult-button slot="buttons" dialog-confirm color="var(--secondary-color)" on-click="removeAnnotationData"
              >Remove Annotation Data</katapult-button
            >
            <katapult-button slot="buttons" dialog-dismiss icon="close">Cancel</katapult-button>
          </katapult-dialog>

          <!-- Set CU Data Dialog -->
          <katapult-dialog
            id="setCUDataDialog"
            title="CU Data - [[setCUData.scid]] ([[setCUData.poleTag.tagtext]])"
            icon="local_shipping"
            closeOnEscape
            draggable
            maxWidth="600"
          >
            <template is="dom-if" if="[[!setCUData.accessible]]">
              <div style="text-align: center;">
                <p>Is this pole accessible by bucket truck?</p>
                <katapult-button
                  style="margin-right: 10px;"
                  class="button accessible"
                  attribute="bucket_truck_accessible"
                  data="yes"
                  slot="buttons"
                  on-click="checkAdvanceWorkLocation"
                  >Yes</katapult-button
                >
                <katapult-button
                  class="button accessible"
                  attribute="bucket_truck_accessible"
                  slot="buttons"
                  data="no"
                  on-click="checkAdvanceWorkLocation"
                  >No</katapult-button
                >
              </div>
            </template>
            <template is="dom-if" if="[[!setCUData.flagging]]">
              <div style="text-align: center;">
                <p>Does this pole require flagging?</p>
                <katapult-button
                  style="margin-right: 10px;"
                  class="button flagging"
                  attribute="traffic_control"
                  data="average"
                  slot="buttons"
                  on-click="checkAdvanceWorkLocation"
                  >Yes - Average</katapult-button
                >
                <katapult-button
                  style="margin-right: 10px;"
                  class="button flagging"
                  attribute="traffic_control"
                  data="double"
                  slot="buttons"
                  on-click="checkAdvanceWorkLocation"
                  >Yes - Double</katapult-button
                >
                <katapult-button
                  class="button flagging"
                  attribute="traffic_control"
                  data="none"
                  slot="buttons"
                  on-click="checkAdvanceWorkLocation"
                  >No</katapult-button
                >
              </div>
            </template>
          </katapult-dialog>

          <!-- Remove Photo Data Dialog (Minus Pole Height + Height Markers) -->
          <katapult-dialog id="removePhotoDataDialog" title="Remove Photo Data" width="600">
            <paper-row><h3>Are you sure you want to remove photo data?</h3></paper-row>
            <paper-row style="margin-top: 12px;">
              Photos will remain associated to their current locations. Everything but height annotations will be deleted. A Job Snapshot
              will be created automatically before deletion.
            </paper-row>
            <katapult-button slot="buttons" dialog-confirm color="var(--secondary-color)" on-click="deletePhotoData"
              >Remove Annotation Data</katapult-button
            >
            <katapult-button slot="buttons" on-click="cancelPromptAction" dialog-dismiss icon="close">Cancel</katapult-button>
          </katapult-dialog>

          <!--Temporary Outage Dialog-->
          <!--<paper-dialog opened style="width:400px;" always-on-top>-->
          <!--  <h2>Temporary Outage</h2>-->
          <!--  <paper-dialog-scrollable>-->
          <!--    Sorry, we are experiencing technical difficulties when loading photos due to an Amazon S3 outage. This will be resolved as soon possible. In the meantime, everything other than photos should work.-->
          <!--  </paper-dialog-scrollable>-->
          <!--  <div class="buttons">-->
          <!--    <katapult-button dialog-confirm autofocus>Okay</katapult-button>-->
          <!--  </div>-->
          <!--</paper-dialog>-->

          <!-- unassociated photo tray -->
          <iframe id="photoStream"></iframe>
        </div>
      </katapult-auth>`;
  }

  static get properties() {
    return {
      activeCommandModel: {
        type: Object,
        computed: 'calcActiveCommandModel(activeCommand, mappingButtons)'
      },
      companyAdmins: {
        type: Array,
        value: null
      },
      checkedFolders: {
        type: Object,
        value: () => ({})
      },
      lineAngleResults: {
        type: Object,
        value: {}
      },
      modelDefaults: {
        type: Object,
        computed: 'computeModelDefaults(configDefaults, otherAttributes)',
        value: () => ModelDefaults()
      },
      setCUData: {
        type: Object,
        value: () => ({})
      },
      showFiltersObject: {
        type: Object,
        value: () => ({ filters: true })
      },
      snappingAngle: {
        type: Number,
        value: 0,
        observer: 'rotateIcon'
      },
      activeCommand: {
        type: String,
        value: null,
        observer: 'activeCommandChanged'
      },
      activeCommandData: {
        type: Object,
        value: null
      },
      activeConnection: {
        type: String,
        value: null
      },
      fullscreenButtons: {
        type: Object,
        computed: 'getFullscreenButtons(fullscreenPhoto)'
      },
      deliverablePhotoIsHidden: {
        type: Boolean,
        computed: 'calcDeliverablePhotoIsHidden(selectedDeliverablePhoto, hideDeliverablePhoto)'
      },
      mapLayers: {
        type: Array,
        value: []
      },
      overlays: {
        type: Object,
        value: {}
      },
      undoLog: {
        type: Array,
        value: () => []
      },
      activeSection: {
        type: String,
        value: null
      },
      useMetricUnits: {
        type: Boolean,
        value: false,
        observer: 'useMetricUnitsChanged'
      },
      useMetricUnitsChecked: {
        type: Boolean,
        observer: 'useMetricUnitsCheckedChanged'
      },
      useDecimalFeet: {
        type: Boolean,
        value: false,
        observer: 'useDecimalFeetChanged'
      },
      useDecimalFeetChecked: {
        type: Boolean,
        observer: 'useDecimalFeetCheckedChanged'
      },
      canDragOverride: {
        type: Boolean,
        value: false
      },
      toolsetToggleText: {
        type: String,
        value: 'Collapse'
      },
      canWrite: {
        type: Boolean,
        computed: 'calcCanWrite(_sharing)'
      },
      selectedInputModelGroup: {
        type: String
      },
      inputModelGroups: {
        type: Object,
        observer: 'setSelectedInputModelGroup'
      },
      inputModelGroupKeys: {
        type: Array,
        computed: 'SortedKeys(inputModelGroups)'
      },
      connections: {
        type: Object,
        observer: '_connectionsObserver'
      },
      connectionKeys: {
        value: []
        // value is set from this.connection observer: '_connectionsObserver'
      },
      contextLayersSearch: String,
      origin: {
        type: String,
        value: () => window.location.origin
      },
      editing: {
        type: String,
        value: null
      },
      editingNode: {
        type: String,
        value: null
      },
      proposedInchDistance: {
        type: Number,
        value: 12
      },
      proposedInsertLocation: {
        type: String,
        value: 'above'
      },
      activeItem: {
        type: String,
        computed: 'getActiveItem(editing, editingNode, activeConnection, activeSection)'
      },
      geoJsonLayers: {
        type: Object,
        value: function () {
          return [];
        }
      },
      hashKeys: {
        type: Array,
        value: function () {
          return [
            'mapBase',
            'job_id',
            'editing',
            'activeConnection',
            'editingNode',
            'activeSection',
            'selectedNode',
            'pageLoadTag',
            '$.katapultMap.latitude',
            '$.katapultMap.longitude',
            '$.katapultMap.zoom',
            'buttonGroup',
            'contextLayersSearch',
            'selectedContextLayers',
            'nodeLabels',
            'showSpanDistances',
            'editingItemJob',
            'job_token'
          ];
        }
      },
      savedKeys: {
        type: Array,
        value: function () {
          return [
            { key: 'latitude' },
            { key: 'longitude' },
            { key: 'zoom' },
            { key: 'buttonGroup' },
            { key: 'mapBase' },
            { key: 'contextLayersSearch', default: null },
            { key: 'selectedContextLayers', default: [] },
            { key: 'nodeLabels', default: {} },
            { key: 'showSpanDistances', default: true },
            { key: 'editingItemJob' },
            { key: 'hiddenLegendItems', default: null },
            { key: 'multiJobIds' },
            { key: 'geoJsonLayers', default: [] },
            { key: 'crumbTrails' }
          ];
        }
      },
      savedViews: {
        type: Object,
        observer: 'savedViewsChanged'
      },
      savedViewsArray: {
        type: Array,
        computed: 'toArray(savedViews)'
      },
      activeSavedView: {
        type: String,
        observer: 'loadSavedView'
      },
      /*
        katapultpro.com/map/#$jobId/n$editingNodeId
          temporary url items - $savedViewId, auth, 
        ktplt.co/l/DLKGJK434
          jobId
          view
          auth
      */

      hideDeliverablePhoto: {
        type: Boolean,
        value: false
      },
      viewPublishedChecked: {
        type: Boolean,
        value: false,
        notify: true
      },
      job_id: {
        type: String,
        value: '',
        observer: 'jobIdChanged'
      },
      editingItemJob: {
        type: String,
        value: null
      },
      multiJobIds: {
        type: Object,
        value: function () {
          return {};
        }
      },
      newJob: {
        type: Boolean,
        value: false
      },
      newJobSetupMetadataGroups: {
        type: Array,
        value: []
      },
      jobUrlsBusyLoading: {
        type: Boolean,
        value: false
      },
      jobName: {
        type: String,
        observer: 'jobNameChanged'
      },
      jobCreator: {
        type: String,
        observer: 'jobCreatorChanged'
      },
      jobPackageCompany: {
        type: String,
        value: null
      },
      legendItems: {
        type: Array,
        value: function () {
          return [];
        },
        computed: 'computeLegendItems(jobStyles, tier, _sharing)'
      },
      loadingCrumbTrails: {
        type: Boolean,
        value: false
      },
      loadingMapItems: {
        type: Object,
        value: () => ({})
      },
      linkedWindow: {
        type: String,
        value: null
      },
      linkMapPhotoActions: {
        type: Array,
        value: null
      },
      mapTypeIdsArray: {
        type: Array,
        computed: 'computeMapTypeIdsArray(mapTypeIds.*)'
      },
      mapBase: {
        type: String,
        value: 'hybrid',
        observer: 'mapBaseChanged'
      },
      pinSearchBar: {
        type: Boolean,
        value: false
      },
      prevMapBase: {
        type: String,
        value: 'hybrid'
      },
      loadedFromHash: {
        // Tells the job_id observer whether job_id was set manually or from the hash on page load.
        type: Boolean,
        value: false
      },
      multiAddAttributes: {
        observer: 'multiAddAttributesChanged'
      },
      multiAddAttributeUpdate: {
        type: Boolean,
        value: false
      },
      filesToUpload: {
        type: Array,
        value: function () {
          return [];
        }
      },
      nodes: {
        type: Object,
        observer: 'nodesLoaded'
      },
      nodesChanged: {
        type: Boolean,
        value: true
      },
      nodeKeys: {
        value: []
        // value is set from this.nodes observer: 'nodesLoaded'
        // computed: "bigObjectKeys(nodes,'nodeKeys')",
      },
      nodeLabels: {
        type: Object,
        value: function () {
          return {};
        }
      },
      nodeCounterConfig: {
        type: Object,
        observer: 'nodesLoaded'
      },
      nullZeroValue: {
        type: String,
        value: () => {
          return null;
        }
      },
      pageLoadTag: {
        value: null
      },
      photoLabels: {
        type: Object,
        observer: 'photoLabelsChanged'
      },
      photoLabelsFromFirebase: {
        type: Object,
        observer: 'photoLabelsFromFirebaseChanged'
      },
      poleForemanJson: {
        type: Object,
        value: null
      },
      poleListItems: {
        type: Array,
        value: []
      },
      poleListClickTimer: {
        type: Number,
        value: null
      },
      poleOwners: {
        type: Object,
        value: function () {
          return {};
        }
      },
      removeAttributeOpen: {
        type: Boolean,
        value: false
      },
      selectedContextLayers: {
        type: Array,
        value: () => {
          return [];
        }
      },
      qcPPLReport: {
        value: null
      },
      qcPowerCompany: {
        type: String,
        value: 'NES'
      },
      qcSlackSpansAssessPower: {
        type: Boolean,
        value: true
      },
      qcSlackSpansAssessComs: {
        type: Boolean,
        value: false
      },
      selectedNode: {
        type: String,
        value: null,
        observer: 'selectedNodeChanged'
      },
      sharedCompanies: {
        type: Object
      },
      sharing: {
        value: '',
        observer: 'sharingChanged'
      },
      showNodeBreakdown: {
        type: Boolean,
        value: false
      },
      nodeBreakdown: {
        type: Object,
        value: {}
      },
      status: {
        type: Object,
        notify: true
      },
      avatars: {
        type: Object,
        value: () => ({ list: [], listLookup: {} })
      },
      showAvatars: {
        type: Boolean,
        value: true
      },
      showLines: {
        type: Boolean,
        value: true
      },
      showSag: {
        type: Boolean,
        value: false
      },
      showClearances: {
        type: Boolean,
        value: false
      },
      tier: {
        type: String,
        value: null
      },
      groupUserData: {
        type: Object
      },
      userGroup: {
        type: String,
        observer: 'userGroupChanged'
      },
      user: {
        type: Object,
        observer: 'userChanged'
      },
      _sharing: {
        type: String,
        value: 'read'
      },
      signInActionsQueue: {
        type: Array,
        value: () => {
          return [];
        }
      },
      activeCommand: {
        type: String,
        value: null
      },
      reloadPhotoFunction: {
        type: Function,
        value: function () {
          return this.reloadPhotos.bind(this);
        }
      },
      changeInStatus: {
        type: Boolean,
        value: false
      },
      cableTracing: {
        type: Boolean,
        value: false
      },
      connectionTracing: {
        type: Boolean,
        value: false
      },
      zoomNode: {
        type: String,
        observer: 'zoomToNode'
      },
      textboxModel: {
        type: Object,
        value: () => ({ gui_element: 'textbox' })
      },
      showSpanDistances: {
        type: Boolean,
        value: true
      },
      lazyLoadCalls: {
        type: Array,
        value: function () {
          return [];
        }
      },
      fullscreenPhotoOpened: {
        type: Boolean,
        value: false
      },
      stateRoadSyle: {
        type: Object,
        value: () => {
          return {
            1: {
              name: 'State Road',
              color: 'rgba(255, 206, 128, 0.7)'
            },
            2: {
              name: 'Turnpike',
              color: 'rgba(255, 0, 0, 0.7)'
            },
            4: {
              name: 'Non-State Federal Aid Road',
              color: 'rgba(100, 165, 165, 0.7)'
            },
            5: {
              name: 'Local Road',
              color: 'rgba(244, 221, 255, 0.7)'
            },
            6: {
              name: 'Toll Bridge',
              color: 'rgba(122, 245, 0, 0.7)'
            }
          };
        }
      },
      addIndividualAddressAttr: {
        type: Boolean,
        value: true
      },
      addressAttributes: {
        type: Object,
        computed: '_getAddressOptions(mappingButtons.address_data.models)'
      },
      addFormattedAddressAttr: {
        type: Boolean,
        value: false
      },
      prioritizeStreetNumber: {
        type: Boolean,
        value: false
      },
      stateAbbreviation: {
        type: Boolean,
        value: false
      },
      utilityInfoList: {
        type: Array,
        value: () => []
      },
      shortcuts: {
        type: Array,
        value: []
      },
      deleteInstance: {
        type: String,
        value: 'all'
      },
      companyNames: {
        type: Object,
        value: () => ({})
      },
      showLegacyFeedback: {
        type: Boolean,
        value: false
      },
      trackingDisabled: {
        type: Boolean,
        value: false
      },
      duplicateJobTrackingDisabled: {
        type: Boolean,
        value: false
      },
      progressPercentage: {
        type: Number,
        value: 0
      },
      enabledFeatures: {
        type: Object,
        value: () => ({})
      },
      signedIn: {
        type: Boolean,
        value: false
      },
      allowedLabelAttributeTypes: {
        type: Array,
        value: () => ['node', 'connection', 'section']
      },
      deleteMarkerData: {
        type: Boolean,
        value: false
      },
      rootCompany: {
        type: String,
        value: null
      },
      associatedLocations: {
        type: Object
      }
    };
  }

  static get observers() {
    return [
      'signedInChanged(signedIn)',
      'checkUserJobAccessStatus(job_id, signedIn)',
      'mapLayersChanged(mapLayers.*)',
      'getGoogleCopyrightText(zoom, latitude, longitude)',
      'updateHash(job_id, activeItem, activeSavedView, jobToken)',
      'setSharingPermissions(job_id, userGroup)',
      'chooseContextLayers(selectedContextLayers.*, contextLayers)',
      'parseUserData(userData.*)',
      'focusInputDialogEditing(editingNode, editing, _sharing)',
      'showDeliverablePhoto(status, showPhoto)',
      'statusChanged(status.*, enabledFeatures.job_locking)',
      'set_sharing(sharing, changeInStatus, readOnlyUser, jobName, enabledFeatures.job_locking)',
      'checkJobName(jobName, jobCreator)',
      'setPplPowerSpecLookup(powerSpecLookup.*)',
      'toggleAvatars(showAvatars, userGroup, mapInitialized, projection)',
      'updatePoleListItems(poleListSearchText, nodes, jobStyles.default.nodes.*, poleListMapSync)',
      'fullscreenPhotoChanged(fullscreenPhoto)',
      'multiAddAttributeItemsToEditChanged(multiAddAttributeItemsToEdit.*)',
      'getSelectedItemPhotos(selectedNode, userGroup)',
      'setDefaultLabels(jobChangedLoadDefaultLabels, modelConfig.default_labels.*)',
      'zoomToActiveJob(zoomToJob, nodesAreLoaded, mapInitialized)',
      'setDefaultPublishedLabels(noDefaultView, status, status.published, _sharing)',
      'getEditingItemJobName(editingItemJob, userGroup)',
      'deliverablePhotoIsHiddenChanged(deliverablePhotoIsHidden, showAuthContent)',
      'modelDefaultsChanged(modelDefaults)',
      'checkPromptForCatalogImport(promptForCatalogImport)',
      'debouncedRunLoadAnalysis(nodes.*, connections.*)',
      'getShortcuts(jobCreator)',
      'jobFilesUpdated(jobFiles, jobFiles.*)',
      'getUtilityInfoList(masterUtilityInfoList, companyUtilityInfoList, userGroup)',
      'generateDownGuyRoutineNote(downGuyLinkingData.selected_spec, downGuyLinkingData.installAuxEye)',
      'autoDockSearch(dockSearchBarStatus)',
      'updateParentJobLink(metadata.parent_job_id)',
      'getCompanyNames(contacts.*, sharedCompanies.*)',
      'updateExistingSectionInfoWindows(showSag, showClearances, showKpla)',
      'updateShowLegacyFeedback(enabledFeatures.legacy_job_feedback, companyOptions.map.old_feedback)',
      'calcPermissionFromAppStatus(metadata.app_status, metadata.engineering_contractor, metadata.data_collection_contractor, metadata.review_contractor, userGroup)',
      'updateJobLocking(metadata.job_locked)',
      'deleteMarkerDataChanged(deleteMarkerData)',
      'setupAPILayers(availableAPILayers, apiLayerGroups)',
      'groupLayersAndRefreshMapLayerList(apiLayerGroups.*, mapLayers.*)',
      'refreshAllLayerItemStates(multiJobIds.*, geoJsonLayersChange, mapOverlaysChanged)',
      'updateTransferLockingListener(jobOwner)'
    ];
  }

  static get importMeta() {
    return import.meta;
  }

  constructor() {
    super();
    this.config = config;
    this.SortedKeys = SortedKeys;
    this.isTruthy = (x) => !!x;
    this.changeMapLayerNameInputModel = {
      gui_element: 'textbox'
    };
    this.sortMapLayersOnChange = true;

    this.ShowReadOnlyWelcomeDialog = ShowReadOnlyWelcomeDialog;
    this.shouldShowReadOnlyWelcomeDialog = false;
    const shouldShowReadOnlyWelcomeDialogOnThisServer = this.config?.appName === 'katapult-production';
    if (shouldShowReadOnlyWelcomeDialogOnThisServer) {
      onAuthStateChanged(getAuth(), (user) => {
        if (user != null && user.email == null) {
          this.shouldShowReadOnlyWelcomeDialog = true;
          ShowReadOnlyWelcomeDialogIfNotShownBefore();
        }
      });
    }
  }

  connectedCallback() {
    super.connectedCallback();

    this.messageHandler = function (e) {
      if (e.origin == window.location.origin) {
        try {
          // Do nothing if data is empty
          if (!e.data) return;
          // Otherwise, try to parse the message
          var message = JSON.parse(e.data);
          if (message.type == 'closePhotoTray') {
            this.$.photoStream.style.display = 'none';
            this.$.photoStream.src = 'about:blank';
          } else if (message.type == 'resize') {
            this.$.photoStream.style.width = message.width;
          } else if (message.type == 'openFullscreenPhoto') {
            this.openPhoto({ detail: { key: message.key } });
          } else if (message.type == 'confirm') {
            var functionName = message.args.pop();
            var callerElementId = message.args.pop();
            var callerElement = e.source.document.querySelector('#' + callerElementId);
            if (callerElement != null) {
              message.args.push(callerElement[functionName].bind(callerElement));
            }
            this.confirm.apply(this, message.args);
          }
        } catch (e) {
          // This is to suppress errors that occur when a third-party library fires a 'message' event
          switch (e.message) {
            case `Unexpected token 's', "setImmedia"... is not valid JSON`:
              break;
            default:
              console.error(e);
          }
        }
      }
    }.bind(this);

    window.addEventListener('message', this.messageHandler);

    this.jobLockingFlagListener = addFlagListener('job_locking', (enabled) => {
      this.set('enabledFeatures.job_locking', enabled);
    });
    this.legacyJobFeedbackFlagListener = addFlagListener('legacy_job_feedback', (enabled) => {
      this.set('enabledFeatures.legacy_job_feedback', enabled);
    });
    this.pciMidspansFlagListener = addFlagListener('pci_midspans', (enabled) => {
      this.set('enabledFeatures.pci_midspans', enabled);
    });
    this.configurableKMZIconFallbackFlagListener = addFlagListener('configurable_kmz_icon_fallback', (enabled) => {
      this.set('enabledFeatures.configurable_kmz_icon_fallback', enabled);
    });
    this.njunsTestDatabaseOptionFlagListener = addFlagListener('njuns_test_database_option', (enabled) => {
      this.set('enabledFeatures.njuns_test_database_option', enabled);
    });
    this.prepForPCISubsequentRoundsFlagListener = addFlagListener('prep_for_PCI_subsequent_rounds', (enabled) => {
      this.set('enabledFeatures.prep_for_PCI_subsequent_rounds', enabled);
    });
    this.qcWarningsDialogDownloadManagerFlagListener = addFlagListener('qc_warnings_dialog_download_manager', (enabled) => {
      this.set('enabledFeatures.qc_warnings_dialog_download_manager', enabled);
    });
    this.sagCalculationViewFlagListener = addFlagListener('sag_calculation_view', (enabled) => {
      this.set('enabledFeatures.sag_calculation_view', enabled);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('message', this.messageHandler);
    window.removeEventListener('mousedown', this.layersButton.closeLayersDialog);

    document.removeEventListener('click', this._boundCloseDragPhotoMenu);

    removeFlagListener('job_locking', this.jobLockingFlagListener);
    removeFlagListener('legacy_job_feedback', this.legacyJobFeedbackFlagListener);
    removeFlagListener('pci_midspans', this.pciMidspansFlagListener);
    removeFlagListener('configurable_kmz_icon_fallback', this.configurableKMZIconFallbackFlagListener);
    removeFlagListener('njuns_test_database_option', this.njunsTestDatabaseOptionFlagListener);
    removeFlagListener('prep_for_PCI_subsequent_rounds', this.prepForPCISubsequentRoundsFlagListener);
    removeFlagListener('qc_warnings_dialog_download_manager', this.qcWarningsDialogDownloadManagerFlagListener);
    removeFlagListener('sag_calculation_view', this.sagCalculationViewFlagListener);
    this.disconnectTransferLockingListener?.();
  }

  ready() {
    super.ready();
    this.body = document.body;
    this.boundToggleNodeLabel = this.toggleNodeLabel.bind(this);
    this.boundClearNodeLabels = this.clearNodeLabels.bind(this);
    this.boundCuNodeFilter = this.cuNodeFilter.bind(this);
    this.boundGeocode = this.geocode.bind(this);
    this.boundNjunsApi = this.njunsApi.bind(this);

    let googleMapLoadedResolve;
    this.googleMapLoaded = new Promise((resolve) => (googleMapLoadedResolve = resolve));
    this.googleMapLoaded.resolve = googleMapLoadedResolve;

    window.mapsDesktop_linkCableSpec = this.linkCableSpec.bind(this);
    window.mapsDesktop_expandReferenceInfo = this.expandReferenceInfo.bind(this);
    window.mapsDesktop_masterLocationDirectoryToggleJob = this.masterLocationDirectoryToggleJob.bind(this);
    window.mapsDesktop_dragContextPhoto = this.dragContextPhoto.bind(this);

    // Set up the web worker for the element
    this.mapsWorker = new Worker('/source/_resources/workers/KatapultMapsWorker.js', { type: 'module' });
    this.mapsWorker.postMessage({
      call: 'init',
      args: [config.firebaseConfigs[config.appName]]
    });
    this.mapsWorker.addEventListener(
      'message',
      (e) => {
        // Check if the event data has a call attribute
        if (e.data.call) {
          // Check if the function to call is available
          if (this[e.data.call]) {
            // Call the function and apply the arguments passed in
            this[e.data.call].apply(this, e.data.args);
          }
        }
      },
      false
    );

    this.appConfiguration = new AppConfiguration(this, {
      dataLoaded: () => {
        this.$.katapultMap.updateMapStyle(this.config.firebaseData.palette.primaryColor, this.config.firebaseData.palette.secondaryColor);
      }
    });

    document.onmouseup = () => {
      this.mouseup = true;
      this.mousedown = false;
    };
    document.onmousedown = () => {
      this.mouseup = false;
      this.mousedown = true;
    };
    var jsonFromHash = true;
    try {
      var hash = decodeURI(window.location.hash.substring(1));
      if (hash.length > 0) hash = JSON.parse(hash);
      if (hash.auth != null && hash.job_id != null) {
        this.loadedFromHash = true;
        var customAuth = hash.auth;
        var ref = FirebaseWorker.ref('photoheight/jobs/' + hash.job_id + '/name');
        ref.once(
          'value',
          function (snapshot) {},
          function (error) {
            if (this.signedIn) {
              firebase.auth().signOut();
              this.signedIn = false;
              this.nodesAreLoaded = false;
              this.zoomToJob = true;
            }
            katapultAuth.authenticateAnonymousSession(customAuth, hash.job_id);
          }.bind(this)
        );
        delete hash.auth;
      }
      if (hash.job_id != null && hash.latitude == null && hash.longitude == null) {
        this.zoomToJob = true;
      }
      for (var i = 0; i < this.hashKeys.length; i++) {
        var element = this;
        var key = this.hashKeys[i];
        if (key.indexOf('$') == 0) {
          var keySplit = key.split('.');
          element = this.$[keySplit[1]];
          key = keySplit[2];
        }
        if (hash[key] != null) element.set(key, hash[key]);
      }
      if (hash.selectedNode != null && (hash.latitude == null || hash.longitude == null)) {
        loadRenderMap.zoomToItem = hash.selectedNode;
        this.editingItemJob = hash.job_id;
        this.editing = 'Node';
      } else if (hash.selectedNode && hash.selectedNode != '')
        setTimeout(() => {
          this.$.katapultMap.selectNode({ detail: { key: hash.selectedNode, jobId: this.job_id } });
        }, 3000);
      this.message = hash.message;
      this.mapBase = (hash.mapBase && hash.mapBase.toLowerCase()) || (hash.job_id == '' || hash.job_id == null ? 'roadmap' : 'hybrid');
      this.latitude = hash.latitude || 25.3241665257384;
      this.longitude = hash.longitude || 12.65625;
      this.zoom =
        parseInt(hash.zoom) || (hash.job_id == '' || hash.job_id == null || hash.latitude == null || hash.longitude == null ? 3 : 18);
      this.nodeLabels = hash.nodeLabels || {};

      if (hash.job_id == '' || hash.job_id == null) {
        this.set('additionalMapOptions.styles', this.katapultMapStyle);
        if (hash.createNewJob) {
          setTimeout(() => {
            this.$.jobChooser.createNewJob();
          }, 2000);
        } else {
          this.$.welcomeDialog.open();
          this.$.welcomeDialog.addEventListener('dom-change', () => {
            this.$.welcomeDialog.refit();
          });
        }
      }
    } catch (e) {
      jsonFromHash = false;
      let keys = window.location.hash.substring(1).split('/');
      if (keys[0].search(/^[\w\-]{20}$/) == 0 || keys[0] == 'covid-19') this.job_id = keys[0];
      if (keys.length > 1 && keys[1].search(/^[ncs]([\w\-]{20}|\d+)(:(midpoint_section|[\w\-]{20}))?$/) == 0)
        this.applySetting('activeItem', keys[1]);
      if (keys.length > 2 && keys[2].search(/^[\w\-]{20}$/) == 0) {
        this.activeViewFromUrl = true;
        this.activeSavedView = keys[2];
      }
      if (keys.length > 4 && this.job_id) {
        this.editing = 'Job';
        this.editingItemJob = this.job_id;
        this.displayApplication = keys[4];
      }
    }

    // if window.opener exists then clear the sessionStorage and set window.opener to null
    if (window.opener) {
      // Remove all saved data for katapultMaps from sessionStorage
      this.savedKeys.forEach((item) => sessionStorage.removeItem('katapultMaps:' + item.key));
      window.opener = null;
    } else {
      // check if the current job_id set from the hash is the same as the job_id in session storage in multiJobIds
      // if they are not the same then clear the sessionStorage
      let multiJobIds = JSON.parse(sessionStorage.getItem('katapultMaps:multiJobIds')) || null;
      if (multiJobIds == null || (multiJobIds && !multiJobIds.hasOwnProperty(this.job_id))) {
        // Remove all saved data for katapultMaps from sessionStorage
        this.savedKeys.forEach((item) => sessionStorage.removeItem('katapultMaps:' + item.key));
      }
    }

    this.savedKeys.forEach((item) => {
      // skip geoJsonLayers for now, we will handle them once the map is loaded
      if (item.key == 'geoJsonLayers') return;
      this._createMethodObserver(`saveSettings('${item.key}', ${item.key}, ${item.key}.*)`);

      if (!jsonFromHash) {
        try {
          let val = JSON.parse(sessionStorage.getItem('katapultMaps:' + item.key));
          if (val != null) {
            this.settingsLoadedFromSession = true;
            this.applySetting(item.key, val);
          } else if (item.key == 'multiJobIds') {
            this.loadJobMapLayer();
          } else if (item.key == 'latitude' && this.activeSavedView == null) {
            this.zoomToJob = true;
          }
        } catch (e) {}
      }
    });

    // Check if we should zoom in on any items (but don't zoom if we used settings from session)
    if (!this.settingsLoadedFromSession) {
      this.signInActionsQueue.push({
        id: 'selectMapItemFromURL',
        action: () => {
          if (this.selectedNode) {
            loadRenderMap.zoomToItem = this.selectedNode;
            this.$.katapultMap.selectNode({ detail: { key: this.selectedNode, jobId: this.job_id } });
          } else if (this.activeConnection) {
            // Check if there is also a section selected or not
            if (this.activeSection) {
              loadRenderMap.zoomToItem = `${this.activeConnection}:${this.activeSection}`;
              this.$.katapultMap.selectSection({
                detail: { sectionId: this.activeSection, connId: this.activeConnection, jobId: this.job_id }
              });
            } else {
              loadRenderMap.zoomToItem = `${this.activeConnection}`;
              this.$.katapultMap.selectConnection({ detail: { key: this.activeConnection, jobId: this.job_id } });
            }
          }
        }
      });
    }

    this.$.qualityControl.addEventListener(
      'qc-message-changed',
      function (e) {
        var result = this.$.resultPage;
        if (result.childElementCount > 1) {
          result.removeChild(result.children[1]);
        }
        e.detail.id = 'qcOutput';
        result.appendChild(e.detail);
        result.children[1].style.overflow = 'auto';
        result.children[1].style.maxHeight = '225px';
        this.$.qcDialog.notifyResize();
      }.bind(this)
    );
    document.body.addEventListener('dragover', function (e) {
      e.dataTransfer.dropEffect = 'move';
      e.preventDefault();
    });

    this.$.katapultMap.addEventListener(
      'jcop-transfer',
      function (e) {
        this.jcopTransfer(e);
      }.bind(this)
    );

    this.addEventListener(
      'universal-menu-opened',
      function (e) {
        if (this.$.qcDialog.opened) {
          this.$.qcDialog.style.transition = 'left 0.4s';
          if (e.detail.opened) {
            this.$.qcDialog.style.left = '275px';
          } else {
            this.$.qcDialog.style.left = '0px';
          }
        }
      }.bind(this)
    );
    // set hasWritePermission here using katapult auth.  It will update whenever permissions change
    this.hasWritePermission = katapultAuth?.canWrite ?? false;
    katapultAuth.addEventListener('user-permissions-set', () => (this.hasWritePermission = katapultAuth.canWrite || false));

    if (this.message != null) {
      this.toast(this.message);
      this.message = null;
    }
    this._boundCloseDragPhotoMenu = this.closeDragPhotoMenu.bind(this);
    // this.$.loginBox.addEventListener('user-group-changed',this.loadCustomExports.bind(this));
    setTimeout(() => {
      this.pageLoaded = true;
    });

    // Fix overflow of dropdowns in paper-dialog-scrollables
    this.shadowRoot.querySelectorAll('paper-dialog-scrollable').forEach((x) => {
      if (x.id != 'confirmDialogScrollable') {
        x.$.scrollable.style.position = 'relative';
        x.$.scrollable.setAttribute('katapult-drop-down-fit-into', '');
      }
    });

    this.addEventListener('set-loading-arrows', this.updateLoadingArrows);
    this.addEventListener('clear-loading-arrows', this.updateLoadingArrows);
    this.addEventListener('update-section-info-windows', this.updateExistingSectionInfoWindows.bind(this));
  }

  updateTransferLockingListener(jobOwner) {
    this.disconnectTransferLockingListener?.();
    if (!jobOwner) return;

    this.disconnectTransferLockingListener = listenToTransferLocking(jobOwner, (transferLocking) => {
      if (transferLocking?.lock_jobs === true && this.job_id) {
        showLockedJobDialog(this.job_id);
        this.job_id = '';
      }
    });
  }

  setupAPILayers() {
    if (!this.availableAPILayers) return;
    // get the individual api layers
    const apiLayers = [];
    for (const [layerKey, layer] of Object.entries(this.availableAPILayers)) {
      apiLayers.push({
        type: 'API Layer',
        // Layers are stored on the company's default model
        model_key: this.userGroup,
        model_layer_key: layerKey,
        ...layer
      });
    }

    // Group the api layers
    const apiLayersWithGrouping = this.$.mapLayersMenuView?.groupLayersByProperties(apiLayers, ['api_layer_group'], {
      alwaysIncludeLayerAsSingle: true
    });
    // set the api layers to the api layers and api layer groups
    this.set('apiLayers', apiLayersWithGrouping);
  }

  calcPermissionFromAppStatus() {
    const appStatus = this.metadata?.app_status;
    if (appStatus && ['Draft', 'Canceled', 'Incomplete'].includes(appStatus) && this.userGroup) {
      const contractorCompanies = [
        this.metadata.engineering_contractor,
        this.metadata.data_collection_contractor,
        this.metadata.review_contractor
      ].filter(Boolean);
      const userIsContractor = contractorCompanies.includes(this.userGroup);

      // If the user is not a contractor, the utility company, or viewing the job from an authenticated link, then show the don't have permission dialog
      if (!userIsContractor && this.userGroup != this.config.firebaseData.utilityCompany && this.userGroup != '_custom_auth') {
        this.permissionFromAppStatusDialog = KatapultDialog.open({
          dialog: { title: 'Permission Required', modal: true, maxWidth: 600 },
          template: () => litHtml`
              <div style="text-align: center;">
                <p>You do not have permission to view survey yet</p>
                <katapult-button id="goToAppView" color="var(--secondary-color)" textColor="white">Go to App View</katapult-button>
              </div>
            `
        });
        // Get the goToAppView button
        const goToAppViewButton = this.permissionFromAppStatusDialog.querySelector('#goToAppView');
        // When the button is clicked, take the user to the pole-application page for the current job
        goToAppViewButton.addEventListener('click', () => {
          OpenPage('pole-application', {
            hash: this.job_id,
            target: '_blank'
          });
        });
      } else {
        this.permissionFromAppStatusDialog?.close();
      }
    }
  }

  updateParentJobLink() {
    this.parentJobLink = this.metadata?.parent_job_id
      ? OpenPage('map', {
          hash: {
            job_id: this.metadata?.parent_job_id,
            latitude: this.latitude,
            longitude: this.longitude,
            zoom: this.zoom
          },
          returnLink: true
        })
      : null;
  }

  async getCompanyNames() {
    if (this.contacts != null && this.sharedCompanies != null) {
      this.set('companyNames', await GetCompanyNames(this.contacts, this.sharedCompanies, this.companyNames, FirebaseWorker));
    }
  }

  checkShouldShowWireSpecDropdown(actionType) {
    return actionType == 'proposed_new_anchor' || actionType == 'proposed';
  }

  jobFilesUpdated(jobFiles) {
    // do not include anything that was not an uploaded file
    if (jobFiles && jobFiles.length) {
      let filteredJobFiles = [];
      let index = 0;
      jobFiles.forEach((file) => {
        if (file.name != null) {
          filteredJobFiles.push(file);
          // have to add this to get the dom-repeat in the export-manager to display any name changes, because polymer is a poopy head 💩
          this.notifyPath(`filteredJobFiles.${index++}.name`);
        }
      });
      this.set('filteredJobFiles', filteredJobFiles);
    }
  }

  notifyResize(e) {
    let elem = e.currentTarget;
    while (elem != null) {
      if (typeof elem.notifyResize == 'function') {
        elem.notifyResize();
        break;
      } else {
        elem = elem.parentElement;
      }
    }
  }

  highlightConn(e, d) {
    if (this.highlightInlineConn != null) this.highlightInlineConn.setMap(null);
    if (this.highlightBisectConns != null) {
      let t;
      while ((t = this.highlightBisectConns.pop()) != null) t.setMap(null);
    }
    this.highlightInlineConn = null;
    this.highlightBisectConns = [];
    if (d != null && !Array.isArray(d) && d.p1 && d.p2) {
      this.highlightInlineConn = new google.maps.Polyline({
        clickable: false,
        map: this.map,
        path: [d.p1, d.p2],
        strokeColor: '#fff',
        strokeOpacity: 0.8,
        strokeWeight: 10,
        zIndex: -1
      });
    } else if (d != null && Array.isArray(d)) {
      for (let i = 0; i < d.length; i++) {
        let t = d[i];
        if (t && t.p1 && t.p2) {
          this.highlightBisectConns.push(
            new google.maps.Polyline({
              clickable: false,
              map: this.map,
              path: [t.p1, t.p2],
              strokeColor: '#fff',
              strokeOpacity: 0.8,
              strokeWeight: 10,
              zIndex: -1
            })
          );
        }
      }
    }
  }

  checkPromptForCatalogImport() {
    if (this.promptForCatalogImport) {
      if (this.$.welcomeDialog.opened) this.$.welcomeDialog.close();
      this.shadowRoot.querySelector('#catalogImportDialog').open();
    }
  }

  gettingStartedWizardFinished() {
    this.shadowRoot.querySelector('#catalogImportDialog').close();
    this.$.welcomeDialog.open();
  }

  isOldApp() {
    return window.config.appName != 'ppl-kws';
  }

  /* Locally update the selected node's icon's rotation */
  async rotateIcon(angle) {
    // Do nothing if we are not in the rotate icon command
    if (this.activeCommand != '_rotateIcon' || !this.selectedNode) return;

    // Get the load render map element from within katapult-map
    const _loadRenderMapElement = this.$.katapultMap?.$?.loadRenderMap;
    if (!_loadRenderMapElement) return;

    // Locally update the icon rotation, depending on the item type
    const selectedMapMarker = _loadRenderMapElement.pointLocations?.[this.selectedNode];
    if (selectedMapMarker) selectedMapMarker.icon = GeoStyleToIcon({ ...selectedMapMarker.data, r: angle });
  }

  /* Save the selected node's icon's rotation to the database */
  async saveIconRotation(angle) {
    // Do nothing if we don't have a selected node
    if (!this.selectedNode) return;

    const update = {
      [`geohash/${this.selectedNode}/r`]: angle,
      [`nodes/${this.selectedNode}/style_adjustments/rotation`]: angle
    };
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
  }

  contains(array, item) {
    if (array) return array.contains(item);
  }

  signedInChanged(signedIn) {
    // If we are now signed in, do each action from the signInActionsQueue
    if (signedIn) {
      if (this.signInActionsQueue) {
        while (this.signInActionsQueue.length) {
          let item = this.signInActionsQueue.pop();
          if (item.action && typeof item.action === 'function') item.action.call(this);
        }
      }
    }
  }

  saveSettings(key, value) {
    this['saveSettings' + key] = Debouncer.debounce(this['saveSettings' + key], timeOut.after(100), () => {
      // If we are trying to save geoJsonLayers, reduce to just keys and selectable status
      if (key == 'geoJsonLayers') {
        value = value.map((x) => {
          return { key: x.key, selectable: x.selectable, storage_file_name: x.storage_file_name };
        });
      } else if (key == 'crumbTrails') value = (value || []).filter((x) => x.active).map((x) => x.label);
      else if (key == 'multiJobIds') {
        value = JSON.parse(JSON.stringify(value));
        delete value.annotations;
        delete value.pages;
      }
      //Session Storage
      sessionStorage.setItem('katapultMaps:' + key, JSON.stringify(value));
      if (this.$.savedViewManager.opened) this.$.savedViewManager.settingChanged(key, value);
      // Clear the active saved view if we have changed a setting
      if (this.activeSavedView && !this.activeViewFromUrl) {
        let setting = SquashNulls(this.savedViews, this.activeSavedView, 'settings', key);
        if (setting === '') setting = null;
        if (['latitude', 'longitude', 'zoom', 'activeItem'].includes(key) && setting == null) return;
        if (setting && (key == 'latitude' || key == 'longitude')) {
          setting = Round(setting, 7);
          value = Round(value, 7);
        }

        if (!this.settingMatches(setting, value) && !this.ignoreSavedViewMatch) {
          this.activeSavedView = null;
        }
      }
    });
  }

  async sharingChanged() {
    if (this.sharing === 'applicant') {
      let surveyExists = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/metadata/survey_available`)
        .once('value')
        .then((s) => s.val());
      if (!surveyExists) {
        this.job_id = null;
        this.$.noPermissionDialog.open();
      }
    }
  }

  savedViewsChanged(value, oldValue) {
    if (this.jobChangedLoadDefaultView) {
      if (this.activeSavedView == null) {
        for (let key in this.savedViews) {
          if (this.savedViews[key].default) {
            this.activeSavedView = key;
            return;
          }
        }
        this.noDefaultView = true;
      }
      this.jobChangedLoadDefaultView = false;
    }
    if (value != null && oldValue == null) {
      this.loadSavedView(this.activeSavedView);
    }
  }

  setDefaultPublishedLabels() {
    // There is no default view. Check add a default view if it is published
    if (this.noDefaultView && this._sharing != 'write' && this.status != null && this.status.published) {
      this.noDefaultView = false;
      var gotNodeLabels = false;
      for (var key in this.nodeLabels) {
        gotNodeLabels = true;
        break;
      }
      // only load pole_tag labels if this is a new job being loaded, not if feedback is being committed
      if (!gotNodeLabels) {
        this.nodeLabels = { pole_tag: true };
      }
    }
  }

  async loadSavedView(view) {
    if (view) {
      if (this.settingsLoadedFromSession) {
        this.activeViewFromUrl = false;
        this.settingsLoadedFromSession = null;
        if (!this.matchesCurrentView(view)) this.activeSavedView = null;
        return;
      }
      await this.toggleAllMapLayers({ currentTarget: { checked: false } });
      this.toggleAllCrumbTrails(false);
      let settings = SquashNulls(this.savedViews, view, 'settings');
      if (settings) {
        for (let key in settings) {
          await this.applySetting(key, Path.copy(settings[key]));
        }
        for (const item of this.savedKeys) {
          if (settings[item.key] === undefined && item.default !== undefined) {
            await this.applySetting(item.key, item.default);
          }
        }
        if (this.activeViewFromUrl) {
          this.activeViewFromUrl = false;
          if (!settings.latitude) {
            this.zoomToJob = true;
          }
        }
      }
    }
  }

  matchesCurrentView(viewId) {
    let viewSettings = SquashNulls(this.savedViews, viewId, 'settings');
    let matchesKeys = this.savedKeys.every((item) => {
      if (['latitude', 'longitude', 'zoom'].includes(item.key) && viewSettings[item.key] == null) return true;
      let setting = viewSettings[item.key];
      let value = this[item.key];
      if (item.key == 'latitude' || item.key == 'longitude') {
        setting = Round(setting, 7);
        value = Round(value, 7);
      }
      return this.settingMatches(setting, value);
    });
    if (viewSettings.activeItem != null && viewSettings.activeItem != this.activeItem) {
      matchesKeys = false;
    }
    return matchesKeys;
  }

  settingMatches(setting1, setting2) {
    return (Path.valueIsEmpty(setting1) && Path.valueIsEmpty(setting2)) || JSON.stringify(setting1) == JSON.stringify(setting2);
  }

  async applySetting(key, value) {
    if (key == 'activeItem') {
      if (value[0] == 'n') {
        this.selectedNode = value.slice(1);
        this.editingNode = value.slice(1);
        this.editing = 'Node';
      } else if (value[0] == 'c') {
        this.activeConnection = value.slice(1);
        this.editing = 'Connection';
      } else if (value[0] == 's') {
        let ids = value.slice(1).split(':');
        this.activeConnection = ids[0];
        this.activeSection = ids[1];
        this.editing = 'Section';
      }
    } else if (key == 'turnOnAllLayers') {
      await this.toggleAllMapLayers({ currentTarget: { checked: true } });
      this.ignoreSavedViewMatch = true;
    } else if (key == 'geoJsonLayers') {
      // We originally stored the layer keys as strings in an array, but then
      // converted to an array of objects for more flexibility. So convert any
      // string values to objects here
      value = value.map((item) => {
        // If the item is a string, it's the layer key and we should convert
        // it to an object with the key and no other assumed settings
        if (typeof item == 'string') {
          return { key: item };
        }
        // Otherwise return as-is
        return item;
      });

      // Turn on each layer
      for (let layerSettings of value) {
        // Turn on the layer and set selectable based on the settings
        let layerIsSelectable = layerSettings.selectable ?? true;
        const layerToToggle = {
          $key: layerSettings.key,
          selectable: layerIsSelectable,
          storage_file_name: layerSettings.storage_file_name
        };
        await this.toggleMapLayer(layerToToggle, true);
      }
    } else if (key == 'crumbTrails') {
      if (value.length) {
        if (!this.crumbTrails) await this.loadCrumbTrails();
        value.forEach((label) => {
          this.renderCrumbTrail({ label, active: true }, true);
        });
      }
    } else if (key == 'multiJobIds') {
      if (!SquashNulls(value, 'pages') && this.currentMapPrintConfigsKey && this.viewMode == 'print') {
        value.pages = {
          url: `photoheight/jobs/${this.job_id}/map_print_config_layers/${this.currentMapPrintConfigsKey}/pages`,
          clickable: true,
          zIndex: 1
        };
      }
      this.set(key, value);
    } else {
      this.set(key, value);
    }
  }

  async loadCrumbTrails(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!this.loadingCrumbTrails) await (this.loadingCrumbTrails = this._loadCrumbTrails().finally(() => (this.loadingCrumbTrails = null)));
    else console.warn('Already loading crumb trails...');
  }

  async _loadCrumbTrails(e) {
    let gpsLogs = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/gps_logs`)
      .once('value')
      .then((s) => s.val());
    let emails = {};
    let groupedGpsLogs = {};
    for (let key in gpsLogs) {
      let log = gpsLogs[key];
      if (!emails[log.uid]) {
        emails[log.uid] =
          (await FirebaseWorker.ref(`user_info/${log.uid}/email`)
            .once('value')
            .then((s) => s.val())) || 'Unknown';
      }
      let date = new Date(log.time).toLocaleDateString();
      let email = emails[log.uid];
      let user = email.split('@')[0];
      let label = `${date} - ${user}`;
      Math.seedrandom(email);
      let colors = ['#F44336', '#E91E63', '#9C27B0', '#3F51B5', '#2196F3', '#00BCD4', '#4CAF50', '#FFC107', '#FF9800', '#FF9800'];
      let stroke = colors[Math.floor(Math.random() * colors.length)];
      if (!groupedGpsLogs[label]) groupedGpsLogs[label] = { date, uid: log.uid, user, stroke, logs: [] };
      groupedGpsLogs[label].logs.push(log);
    }
    for (let label in groupedGpsLogs) groupedGpsLogs[label].logs.sort((a, b) => parseInt(a.time) - parseInt(b.time));
    let featureCollections = {};
    let featureCollectionDetails = [];
    for (let label in groupedGpsLogs) {
      let group = groupedGpsLogs[label];
      featureCollectionDetails.push({ label, uid: group.uid, date: group.date });
      let featureCollection = {
        type: 'FeatureCollection',
        name: label,
        features: []
      };
      for (let i = 1; i < group.logs.length; i++) {
        let logs = group.logs.slice(i - 1, i + 1);
        let distance =
          google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(...logs[0].l),
            new google.maps.LatLng(...logs[1].l)
          ) / 0.3048;
        let durationMillis = parseInt(logs[1].time) - parseInt(logs[0].time);
        let speed = Math.round((distance / 5280 / durationMillis) * 3600 * 1000 * 10) / 10;
        featureCollection.features.push({
          geometry: {
            coordinates: [logs[0].l.slice(0).reverse(), logs[1].l.slice(0).reverse()],
            type: 'LineString'
          },
          properties: {
            Date: group.date,
            Start: new Date(logs[0].time).toLocaleTimeString(),
            End: new Date(logs[1].time).toLocaleTimeString(),
            Duration: HowLong(parseInt(logs[1].time) - parseInt(logs[0].time)).join(', '),
            Distance: `${Math.round(distance * 10) / 10} ft`,
            Speed: `${speed} MPH`,
            User: group.user,
            stroke: group.stroke,
            'stroke-weight': 7,
            'stroke-opacity': 0.7,
            _hideButtons: true,
            _crumbTrailSegment: true
          },
          type: 'Feature'
        });
      }
      featureCollections[label] = featureCollection;
    }
    featureCollectionDetails.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    // Remove all crumb trails from map.
    let currentActiveCrumbTrails = [];
    if (Array.isArray(this.crumbTrails)) {
      currentActiveCrumbTrails = this.crumbTrails.filter((x) => x.active).map((x) => x.label);
      this.crumbTrails.forEach((item) => this.renderCrumbTrail(item, false));
    }

    // Reassign new crumb trail data.
    this.crumbTrailData = featureCollections;
    this.crumbTrails = featureCollectionDetails;

    // Initialize this if not done already.
    if (!this.activeCrumbTrailFeatures) this.activeCrumbTrailFeatures = {};

    // Turn on all crumb trails that were previously on.
    this.crumbTrails.forEach((x) => {
      if (currentActiveCrumbTrails.includes(x.label)) this.renderCrumbTrail(x, true);
    });
  }

  dockSearchBar(auto) {
    if (auto == true) {
      this.pinSearchBar = true;
    } else {
      this.pinSearchBar = !this.pinSearchBar;
    }

    if (!this.pinSearchBar) {
      this.$.poleList.clear();
    } else {
      this.$.poleList.value = this.selectedNode;
    }
    // Update position of filter dropdowns
    setTimeout(() => {
      this.shadowRoot.querySelectorAll('.filterElements').forEach((item) => {
        if (item.tagName == 'KATAPULT-DROP-DOWN') {
          item.updatePosition();
        } else if (item.shadowRoot) {
          item.shadowRoot.querySelectorAll('katapult-drop-down').forEach((item) => {
            item.updatePosition();
          });
        }
      });
    }, 1000);
  }

  autoDockSearch() {
    /*uses the value of dockSearchBarStatus which is set by a checkbox in the user's settings to determine
    whether to dock the search bar or not.*/
    if (this.dockSearchBarStatus) {
      this.dockSearchBar(true);
      //open pole list (only when docked)
      this.$.poleList.opened = true;
    }
  }

  selectedNodeChanged() {
    if (this.pinSearchBar) {
      this.$.poleList.value = this.selectedNode;
    }
    if (this.activeCommand == 'measure_path_length') {
      if (this.selectedNode) {
        this.findPathLengthNodes.push(this.selectedNode);
        if (this.findPathLengthNodes.length > 1) {
          let connectionLookup = GetExtendedConnectionLookup({ nodes: this.nodes, connections: this.connections });

          let findPathDistanceBetween = (a, b, fromConnection) => {
            let connections = connectionLookup.lookupByNode[a];
            if (Array.isArray(connections)) {
              for (let connection of connections) {
                if (!fromConnection || fromConnection.connId != connection.connId) {
                  // If we have found the connection that leads to our endpoint, return it's length.
                  if (connection.toNodeId == b) {
                    return connection.length;
                  }
                  // Otherwise, calc the distance from this new node to our endpoint.
                  else {
                    let distance = findPathDistanceBetween(connection.toNodeId, b, connection);
                    // If we are returned a distance, return the sum of that distance and our connection length.
                    if (!isNaN(distance)) {
                      return distance + connection.length;
                    }
                  }
                }
              }
            }
          };

          let distance = findPathDistanceBetween(...this.findPathLengthNodes);
          if (!isNaN(distance)) {
            distance = Math.round(distance * 10) / 10;
            let el = document.createElement('textarea');
            el.value = distance;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.toast(`The distance ${distance}' has been copied to the clipboad.`, null, 4000);
          } else {
            this.toast('These two nodes do not appear to be connected.', null, 4000);
          }
          this.cancelPromptAction();
        } else {
          this.$.katapultMap.openActionDialog({ text: 'Please select a second node for measurement' });
        }
      }
    }
    //   //this.selectDeliverablePhoto('node'); //WARN: keep an eye on this line if code breaks
  }

  getPinnedListSize(pinSearchBar) {
    if (pinSearchBar) {
      return {
        width: '300px',
        height: 'calc(100% - 56px)',
        top: '56px',
        left: '0px',
        borderRadius: 0,
        zIndex: -1
      };
    } else {
      return {};
    }
  }

  async getSelectedItemPhotos() {
    if (!this.userGroup || !this.user) return;

    let photos = '';
    if (this.selectedNode) {
      let temp = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes/${this.selectedNode}/photos`)
        .once('value')
        .then((s) => s.val());
      if (temp) photos = Object.keys(temp);
    }
    FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/user_data/${this.user.uid}/page_linking/photosToHighlight`).set(photos);
  }

  setDefaultLabels() {
    if (this.jobChangedLoadDefaultLabels && this.modelConfig != null) {
      const noSavedView = !this.jobChangedLoadDefaultView && this.noDefaultView;
      if (!this.settingsLoadedFromSession && noSavedView) {
        // Get default labels.
        const labels = this.modelConfig?.default_labels;
        // Set labels if default labels exist
        if (labels != null) this.nodeLabels = labels;
      }
      // Make sure job change flag is false.
      this.jobChangedLoadDefaultLabels = false;
    }
  }

  getGoogleCopyrightText() {
    this.getGoogleCopyrightTextDebouncer = Debouncer.debounce(this.getGoogleCopyrightTextDebouncer, timeOut.after(1000), () => {
      let copyrightDiv = (this.googleCopyrightText = this.$.katapultMap.$.googlemap.$.map.querySelector('.gmnoscreen div'));
      if (copyrightDiv) this.googleCopyrightText = copyrightDiv.innerText;
    });
  }

  deliverablePhotoResized() {
    if (this.$.pplInvoiceDialog.opened) {
      this.$.pplInvoiceDialog.notifyResize();
    }
  }

  openChooser(e) {
    //let item = e.currentTarget.item;
    this.shadowRoot.querySelectorAll('katapult-photo-chooser').forEach((chooser) => {
      //TODO - make more robust
      //if ((item.nodeId && SquashNulls(chooser, 'item', 'nodeId') == item.nodeId) || (item.connId && SquashNulls(chooser, 'item', 'connId') == item.connId && SquashNulls(chooser, 'item', 'sectionId') == item.sectionId)) {
      chooser.toggle();
      //}
    });
  }
  loadJobMapLayer() {
    if (this.job_id != '' && this.job_id != null) {
      this.set('multiJobIds', { [this.job_id]: { color: null, url: 'photoheight/jobs/' + this.job_id + '/geohash' } });
    }
  }

  appNameContains(appName, searchString) {
    return (appName || '').toLowerCase().indexOf(searchString.toLowerCase()) != -1;
  }

  clickMap(e) {
    if (this.geoJsonInfo) this.geoJsonInfo.close();
    if (this.infoWindow) this.infoWindow.close();
    this.sectionInfoWindows?.forEach((infoWindow) => infoWindow.close());
    if (this.crumbTrailOverlay) this.crumbTrailOverlay.setMap(null);
    this.closeAnnotationEditor(e);
    if (!this.$.deliverablePhoto.getAttribute('hide')) {
      // Reset last clicked photo to hide visible markers
      var photoControls = this.shadowRoot.querySelector('#photoControls');
      if (photoControls) {
        photoControls.lastClickedPhoto = null;
      }
    }
    if (this.activeCommand == '_coorinateCapture') {
      let ll = {
        latitude: e.detail.latLng.lat(),
        longitude: e.detail.latLng.lng()
      };
      var path = this.coordinateCapture.path + '/' + this.coordinateCapture.property + '/' + this.coordinateCapture.itemKey;
      if (this.coordinateCapture.list != null) {
        path += '/' + this.coordinateCapture.list[this.coordinateCapture.listIndex];
        FirebaseWorker.ref(path).set(ll);
        this.coordinateCapture.listIndex++;
        if (this.coordinateCapture.listIndex < this.coordinateCapture.list.length) {
          this.$.katapultMap.openActionDialog({
            title:
              'Please select the location of the "' +
              this.coordinateCapture.property +
              ' ' +
              this.coordinateCapture.list[this.coordinateCapture.listIndex] +
              '"'
          });
        } else {
          var editing = this.coordinateCapture.editing;
          this.cancelPromptAction();
          this.editing = editing;
        }
      } else {
        FirebaseWorker.ref(path).set(ll);
        if (!this.doNextVantagePoint()) this.cancelPromptAction();
      }
    } else if (this.activeCommand == 'measure_bearing' && this.selectedNode) {
      let el = document.createElement('textarea');
      let bearing = this.$.katapultMap.rubberBandAngle || '';
      el.value = bearing;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.toast('Bearing: ' + bearing + '. The bearing has been copied to the clipboad.', null, 4000);
      this.cancelPromptAction();
    } else if (this.activeCommand == '_linkMapPhotoData') {
      var action = this.linkMapPhotoActions[0];
      if (action.action_type == 'hardwareAngle') {
        var bearing = Round(
          (google.maps.geometry.spherical.computeHeading(
            new google.maps.LatLng(this.nodes[action.nodeId].latitude, this.nodes[action.nodeId].longitude),
            new google.maps.LatLng(this.mouseLat, this.mouseLng)
          ) +
            360) %
            360,
          1
        );
        FirebaseWorker.ref(
          'photoheight/jobs/' +
            this.job_id +
            '/photos/' +
            action.photoId +
            '/photofirst_data/' +
            action.property +
            '/' +
            action.itemKey +
            '/bearing'
        ).set(bearing, (error) => {
          if (error) this.toast(error);
          else this.doNextMapPhotoAction();
        });
      }
    } else if (this.activeCommand == '_drawPolygon') {
      if (!this.drawingPolygon) {
        this.drawingPolygon = new google.maps.Polygon({
          fillColor: this.config.firebaseData.palette.secondaryColor,
          strokeColor: this.config.firebaseData.palette.secondaryColor,
          editable: true,
          clickable: false,
          paths: [e.detail.latLng],
          map: this.map
        });
      } else {
        this.drawingPolygon.getPath().push(e.detail.latLng);
      }
    } else if (this.activeCommand == '_addMapPrintItem') {
      this.$.printModeToolbar.addMapPrintItem(e);
    } else if (this.activeCommand == '_addMapLengthDimension') {
      this.$.printModeToolbar.insertDimensionClick(e);
    } else if (this.activeCommand == '_rotateIcon') {
      this.cancelPromptAction(new CustomEvent('rotate-icon-confirm'));
    }
  }

  computeMapTypeIdsArray() {
    if (this.mapTypeIds) {
      return ToArray(this.mapTypeIds);
    }
    return [];
  }

  computeModelDefaults(configDefaults, otherAttributes) {
    return ModelDefaults(configDefaults, otherAttributes);
  }

  itemDrawn(e) {
    if (e.detail.nodeId != null) {
      if (this.activeCommand == '$drawProposedAnchor') this.linkAnchorToDownGuy(e.detail.nodeId);
    }
  }

  modelDefaultsChanged() {
    // Only set it the first time based on the models.
    if (!this.poleListQueryInitialized) {
      // Only set a specific filter if the model defaults have only one main node type.
      if (this.modelDefaults.pole_node_types.length == 1) {
        let queryChildren = this.$.poleListQuery?.query?.children;
        if (Array.isArray(queryChildren)) {
          queryChildren.push({
            attribute: this.modelDefaults.node_type_attribute,
            operator: 'equals',
            value: this.modelDefaults.pole_node_types[0]
          });
          this.poleListQueryInitialized = true;
        }
      }
    }
  }

  toggleMapBase(e) {
    // If it's checked, then switch to the prevMapBase
    if (e.currentTarget.checked) {
      this.mapBase = this.prevMapBase;
    }
    // If it's unchecked, then switch to "blank"
    else {
      this.mapBase = 'blank';
    }
  }

  mapBaseChanged(newVal, oldVal) {
    // Set prevMapBase to the previous value of mapBase when it
    // changes if it's not changing to 'blank'
    if (newVal != 'blank') {
      this.prevMapBase = newVal;
    }
  }

  mapBaseChecked(mapBase) {
    // The checkbox should be checked if the mapbase is anything but 'blank'
    return mapBase != 'blank';
  }

  layerIdIsUtilityRefLayerType(layerId, layerType, utilityOverride) {
    const utilityCompany = utilityOverride || this.config.firebaseData.utilityCompany;
    switch (layerType) {
      case 'poles':
        return layerId?.includes(`${utilityCompany}--poles`) || layerId?.includes(`${utilityCompany}--pole_attributes_imports`);
      case 'primary':
        return layerId?.includes(`${utilityCompany}--primary_overhead`) || layerId?.includes(`${utilityCompany}--primary_overhead_imports`);
      case 'secondary':
        return (
          layerId?.includes(`${utilityCompany}--secondary_overhead`) || layerId?.includes(`${utilityCompany}--secondary_overhead_imports`)
        );
    }
  }

  async selectItem(e) {
    if (e.detail.jobId.indexOf('__ref') == 0) {
      if (this.infoWindow) this.infoWindow.close();
      this.infoWindow = null;
      if (
        SquashNulls(
          this.mapLayers.filter((x) => '__ref' + x.$key == e.detail.jobId),
          '0',
          'unclickable'
        )
      ) {
        setTimeout(() => {
          this.selectedNode = null;
        });
        return;
      }
      let info = e.detail.geoData.info;
      if (!info && typeof e.detail.geoData.d == 'object') {
        info = e.detail.geoData.d;
      }
      let content = '';
      if (
        this.activeCommand == '_linkMapPhotoData' &&
        (this.layerIdIsUtilityRefLayerType(e.detail.jobId, 'primary', 'ppl') ||
          this.layerIdIsUtilityRefLayerType(e.detail.jobId, 'secondary', 'ppl'))
      ) {
        var details = e.detail.geoData.info;
        let wire = this.layerIdIsUtilityRefLayerType(e.detail.jobId, 'primary', 'ppl') ? 'Pri:' : 'Sec:';
        let mainWireDetails = `${wire} (${details.conductor_qty}) ${details.conductor_size} ${details.conductor_type}`;
        let neutWireDetails = `Neut: ${details.neutral_size} ${details.neutral_type}`;
        let feederDetails = `(feeder: ${details.feeder_id}) ${details.operating_voltage}V`;

        content = `<span style="white-space:nowrap; font-size:14px;">${mainWireDetails} ${neutWireDetails} ${feederDetails} </span>`;
        content += `<katapult-button style="margin-left: 20px;" color="#74b" onclick="mapsDesktop_linkCableSpec()" raised>Use This Spec</katapult-button>`;

        let power_spec = info.conductor_size == '999' ? 'Unknown' : info.conductor_size;
        power_spec += ' ' + (info.conductor_type == '9999' ? 'Unknown' : info.conductor_type);
        if (power_spec == 'Unknown Unknown') power_spec = 'Unknown';
        this.selectedContextCable = { power_spec, type: 'secondary', feeder: details.feeder_id };
        if (this.layerIdIsUtilityRefLayerType(e.detail.jobId, 'primary', 'ppl')) {
          let neut =
            (details.neutral_size == '999' ? 'Unknown' : details.neutral_size) +
            ' ' +
            (details.neutral_type == '9' ? 'Unknown' : details.neutral_type);
          if (neut == 'Unknown Unknown') neut = 'Unknown';
          this.selectedContextCable.type = 'primary';
          this.selectedContextCable.primary_count = details.conductor_qty;
          this.selectedContextCable.neutral_spec = neut;
        }
      } else if (e.detail.jobId && e.detail.jobId.startsWith('__refnational-grid--poles_collected_n')) {
        content += '<div style="display:table; font-size:14px; margin-bottom:13px;">';
        let subProperties = [];
        for (let key in info) {
          if (key != 'jobs')
            if (typeof info[key] === 'object') {
              subProperties.push({ key, properties: info[key] });
            } else {
              content +=
                '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                CamelCase(key) +
                ':</b></div><div style="display:table-cell; padding:0 5px;">' +
                info[key] +
                '</div></div>';
            }
        }
        content += '</div>';
        subProperties.forEach((subInfo) => {
          content += '<div style="font-size:14px;"><b>' + CamelCase(subInfo.key) + ':</b></div>';
          content += '<div style="display:table; font-size:14px; margin-left:15px;">';
          for (let subKey in subInfo.properties) {
            if (typeof subInfo.properties[subKey] == 'object') this.parseObject(subInfo.properties[subKey], content);
            else
              content +=
                '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                CamelCase(subKey) +
                ':</b></div><div style="display:table-cell; padding:0 5px;">' +
                subInfo.properties[subKey] +
                '</div></div>';
          }
          content += '</div>';
        });
        if (info.jobs) {
          for (let id in info.jobs) {
            let job = info.jobs[id];
            let ids = id.split(':');
            content += `<div style="font-size:14px;"><b>${
              job.type || 'Photo Acquisition'
            } Job</b></div><div style="margin-left:15px; font-size:14px;">`;
            content += `<a target="_blank" href="${window.location.origin + window.location.pathname}#${ids[0]}/n${ids[1]}">${
              job.jobName
            }</a>`;
            for (let property in job) {
              if (!['jobName', 'photos', 'type'].includes(property)) {
                content +=
                  '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                  CamelCase(property) +
                  ':</b></div><div style="display:table-cell; padding:0 5px;">' +
                  job[property] +
                  '</div></div>';
              }
            }
            for (let photoId in job.photos) {
              let url = await firebase
                .storage()
                .ref('photos/' + photoId + '_small.webp')
                .getDownloadURL();
              content += `<a target="_blank" href="${window.location.origin + window.location.pathname.replace('/map', '/photos')}#${
                ids[0]
              }/${photoId}"><img src="${url}" jobid="${
                ids[0]
              }" cameraid="no_equipment_on_record" folderid="no_equipment_on_record" type="add-only" photos='["${photoId}"]' ondragstart="mapsDesktop_dragContextPhoto(event)"/></a>`;
            }
            content += '</div>';
          }
        }
      } else if (info) {
        content += '<div style="display:table; font-size:14px; margin-bottom:13px;">';
        let subProperties = [];
        for (let key in info) {
          if (info[key] instanceof firebase.firestore.Timestamp) {
            info[key] = info[key].toDate().toLocaleString();
          }
          if (info[key] !== null && typeof info[key] === 'object') {
            subProperties.push({ key, properties: info[key] });
          } else {
            content +=
              '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
              CamelCase(key) +
              ':</b></div><div style="display:table-cell;">' +
              info[key] +
              '</div></div>';
          }
        }
        // Add link to ppl pole lookup
        if (info.tag)
          content += `<div style="display:table-row;"><div style="display:table-cell;"><b>Link:</b></div><div style="display:table-cell;"><a target="_blank" href="${this.config.firebaseData.origin_url}/pole-lookup/#${info.tag}">${info.tag}</a></div></div>`;
        content += '</div>';
        subProperties.forEach((subInfo) => {
          content +=
            '<div style="display:table; font-size:14px;"><div style="display:table-row;"><div style="display:table-cell;"><b>' +
            subInfo.key +
            `:</b></div><div style="display:table-cell;"><katapult-button iconOnly noBorder onclick="mapsDesktop_expandReferenceInfo(this)" style="padding:0; width:20px; height:20px;" icon="expand_more"></katapult-button></div></div>`;
          content += '<iron-collapse style="margin-left:15px;">';
          for (let subKey in subInfo.properties) {
            if (subInfo.key == 'Transformer')
              content +=
                '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                subKey +
                ':</b></div><div style="display:table-cell;">' +
                (subInfo.properties[subKey].labeltext || 'No Transformer Size Available') +
                '</div></div>';
            else if (subInfo.key == 'Capacitor') {
              let sizeA = subInfo.properties[subKey].a_phase_si;
              let sizeB = subInfo.properties[subKey].b_phase_si;
              let sizeC = subInfo.properties[subKey].c_phase_si;
              let string = `a:${sizeA} b:${sizeB} c:${sizeC}`;
              content +=
                '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                subKey +
                ':</b></div><div style="display:table-cell;">' +
                (string || 'No Capacitor Size Available') +
                '</div></div>';
            } else if (subInfo.key == 'Recloser')
              content +=
                '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                subKey +
                ':</b></div><div style="display:table-cell;">' +
                (subInfo.properties[subKey].device_des || 'No Description Available') +
                '</div></div>';
            else if (subInfo.key == 'Switch')
              content +=
                '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                subKey +
                ':</b></div><div style="display:table-cell;">' +
                (subInfo.properties[subKey].device_des || 'No Description Available') +
                '</div></div>';
            else if (subInfo.key == 'Streetlight') {
              for (let prop in subInfo.properties[subKey]) {
                content +=
                  '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                  CamelCase(prop) +
                  ':</b></div><div style="display:table-cell;">' +
                  subInfo.properties[subKey][prop] +
                  '</div></div>';
              }
            } else if (typeof subInfo.properties[subKey] == 'object') this.parseObject(subInfo.properties[subKey], content);
            else
              content +=
                '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
                subKey +
                ':</b></div><div style="display:table-cell;">' +
                subInfo.properties[subKey] +
                '</div></div>';
          }
          content += '</iron-collapse></div>';
        });

        try {
          // Get the attachments for this pole
          let s = await firebase
            .app()
            .database(`https://${this.config.firebaseData.contextLayerDatabase}.firebaseio.com`)
            .ref(`${this.config.firebaseData.contextLayers.poles.url.replace('poles', 'attachments')}/${e.detail.key}`)
            .once('value')
            .then((s) => s);

          if (s.val()) {
            content += `<div style="display:table; font-size:14px;"><div style="display:table-row;"><div style="display:table-cell;"><b>Attachments:</b></div><div style="display:table-cell;"><katapult-button iconOnly noBorder onclick="mapsDesktop_expandReferenceInfo(this)" style="padding:0; width:20px; height:20px;" icon="expand_more"></katapult-button></div></div>`;
            content += '<iron-collapse style="margin-left:15px;">';
            // loop through each attachment company
            s.forEach((company) => {
              if (typeof company.val() == 'object') {
                content += `<div><b>${company.val()[0].name}</b></div>`;
                // loop through all attachments for each company
                company.val().forEach((att) => {
                  content += `<div style="margin-left:15px;">${att.order} - ${att.attach_type || 'Attachment'}</div>`;
                });
              }
            });
            content += '</iron-collapse></div>';
          }
        } catch (e) {
          // if this failed then the user does not have permission to view attachments
          // keep calm and carry on...
          console.debug(e);
        }
      }

      // Check for items from the Master Location Directory and add special buttons
      if (e.detail.jobId?.startsWith('__refMasterLocationDirectory') && e.detail.geoData.d?.j && Array.isArray(e.detail.geoData.d.j)) {
        // Add a header above the job listing
        content += `<div style="margin-bottom:13px; width:80%; text-align:center; margin-left:auto; margin-right:auto;">Turn on/off a job layer with the checkbox. Click the link to open that job in a new tab.</div>`;

        for (let i = 0; i < e.detail.geoData.d.j.length; i++) {
          let jobId = e.detail.geoData.d.j[i];
          let jobName;
          let jobPath;
          try {
            // If the job is deleted, skip it
            const isJobDeleted = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/deleted`)
              .once('value')
              .then((s) => s.val());
            if (isJobDeleted === true) continue;

            jobName = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/name`)
              .once('value')
              .then((s) => s.val());
            jobPath = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/project_folder`)
              .once('value')
              .then((s) => s.val());
          } catch (e) {
            this.toast(
              `You've selected a node that exists in a job that is not shared with you.  You will need to request access to this job in order to view information about this node.`
            );
            return;
          }

          let jobLink = OpenPage('map', {
            target: '_blank',
            hash: {
              job_id: jobId,
              latitude: this.latitude,
              longitude: this.longitude,
              zoom: this.zoom
            },
            returnLink: true
          });

          // Check if the job is already on in the job layers
          let jobIsOn = this.multiJobIds.hasOwnProperty(jobId) && this.multiJobIds[jobId] != null;
          let isCurrentJob = jobId == this.job_id;

          content += `
            <div style="display:table; font-size:14px; margin-bottom:7px;">
              <div style="display:table-row;">
                <div style="display:table-cell;">
                  <paper-checkbox ${isCurrentJob == true ? 'disabled' : ''} ${
                    jobIsOn == true ? 'checked' : ''
                  } onchange="mapsDesktop_masterLocationDirectoryToggleJob(this, '${jobId}', '${jobPath}')"></paper-checkbox>
                  <a href="${jobLink}" target="_blank">${jobName}</a>
                </div>
              </div>
            </div>
          `;
        }
      }

      if (content) {
        if (!this.infoWindow)
          this.infoWindow = new google.maps.InfoWindow({
            disableAutoPan: true,
            maxWidth: this.activeCommand == '_linkMapPhotoData' ? 'none' : 400
          });
        this.infoWindow.setContent(content);
        this.infoWindow.setPosition(e.detail.latLng);
        this.infoWindow.open(this.map);
      }
    } else {
      this.$.confirmDialog.close();
      if (
        this.activeCommand == '_addMapLengthDimension' ||
        (this.activeCommand == '_addMapAngleDimension' && e.detail.type == 'connection')
      ) {
        this.$.printModeToolbar.insertDimensionClick(e);
      }
      // Check if we are shift-clicking a section
      else if (e.detail?.shiftKey && e.detail.type == 'section') {
        this.minimizeNonSectionMarkers(e.detail.connId, e.detail.sectionId);
      } else {
        // Check if the selected item is a node or section and if so, update the deliverable photo
        if ((e.detail.type == 'node' || e.detail.type == 'section') && !e.detail.actionTaken) {
          if (this.infoWindow) this.infoWindow.close();
          // Key of clicked item.
          let itemKey = e.detail.sectionId || e.detail.key;
          // Key of item from which we should load the photo.
          let photoItemKey = itemKey;
          // Item could be an anchor in which case we want to select its parent node's photo.
          let nodeType = PickAnAttribute(this.nodes?.[e.detail.key]?.attributes, this.modelDefaults.node_type_attribute);
          let isAnchor = this.modelDefaults.anchor_node_types.includes(nodeType);
          let parentNodeId = null;
          if (isAnchor) {
            let nodeConnections = Path.get(loadRenderMap, `pointLocations.${itemKey}.data.n`) || {};
            let connId = Object.keys(nodeConnections)[0];
            let endpointNumber = nodeConnections[connId];
            let parentNodeId = Path.get(this.connections, `${connId}.node_id_${endpointNumber == 1 ? 2 : 1}`);
            if (parentNodeId) photoItemKey = parentNodeId;
          }
          this.selectDeliverablePhoto(e.detail.type, photoItemKey);
          this.shadowRoot.querySelector('#deliverablePhotoViewer').markers.forEach((marker, i) => {
            let highlightedColor = '';
            let minimized = false;
            if (isAnchor) {
              minimized = marker.marker.anchor_id != itemKey && marker.marker.proposed_anchor_id != itemKey;
              if (marker.marker.proposed_anchor_id == itemKey) highlightedColor = '#2196F3';
              if (marker.marker.proposed_anchor_id && marker.marker.anchor_id == itemKey) highlightedColor = '#F44336';
            }
            this.shadowRoot.querySelector('#deliverablePhotoViewer').set(`markers.${i}.minimized`, minimized);
            this.shadowRoot.querySelector('#deliverablePhotoViewer').set(`markers.${i}.highlightedColor`, highlightedColor);
          });
          this.shadowRoot.querySelector('#deliverablePhotoViewer').$.photo.orientateImage();
        }
        // Check if the item is a node
        if (e.detail.type == 'node') {
          if (e.detail.jobId != this.job_id && this.activeCommand != null) {
            this.toast('Please select a node on your active job, or switch the active job.');
          } else {
            if (this._promptToSelectCallback) {
              this._promptToSelectCallback(['node', e.detail.key]);
            } else if (this.activeCommand == 'set_order') {
              this.startingNodeIdForOrder = e.detail.key;
            } else if (this.activeCommand == '_linkMapPhotoData') {
              this.editingNode = e.detail.key;
              this.editing = 'Node';
            } else if (this.activeCommand == '_linkAnchorToDownGuy' && this.activeCommandData) {
              this.linkAnchorToDownGuy(e.detail.key);
            } else if (this.actionDialogModel && this.actionDialogModel.type == 'attribute' && e.detail.jobId == this.job_id) {
              // Could add support for other jobs, but it would require loading in the node attributes to toggle them
              await this.setAttributesFromButton([e.detail.key]);
              this.cancelPromptAction();
            } else if (!e.detail.actionTaken) {
              this.editingItemJob = e.detail.jobId;
              this.editingNode = e.detail.key;
              if (this.editing != null) {
                this.editing = 'Node';
              }
            }
          }
        }
        // Check if the item is a connection
        if (e.detail.type == 'connection') {
          // If we are in cable tracing mode, then select the cable trace of the connection
          if (this.activeCommand == '_offsetLines') {
            this.promptOffsetLine(e.detail.key);
          } else if (this._promptToSelectCallback) {
            this._promptToSelectCallback(['connection', e.detail.key]);
          } else if (this.cableTracing) {
            this.selectCableTrace(e.detail.key);
          } else if (this.connectionTracing) {
            this.selectConnectionTrace(e.detail.key);
          }
        }
        // Check if the item is a section
        if (e.detail.type == 'section') {
          if (this._promptToSelectCallback) {
            this._promptToSelectCallback(['section', `${e.detail.connId}:${e.detail.sectionId}`]);
          }
        }
      }

      if (this._sharing == 'write' && !this.showingRealPosition) {
        let otherData = {
          job_id: this.job_id,
          job_name: this.jobName,
          root_company: this.rootCompany,
          lastLog: { '.sv': 'timestamp' },
          email: this.userEmail,
          virtual: true
        };
        if (e.detail.type === 'node') otherData.node_id = e.detail.key;
        this.mapsWorker.postMessage({
          call: 'updateVirtualPosition',
          args: [e.detail.geoData, this.job_id, this.user.uid, this.userGroup, otherData, 10]
        });
      }
    }
  }

  async linkAnchorToDownGuy(anchorId) {
    if (anchorId == null || loadRenderMap.pointLocations[anchorId] == null) throw new Error('Could not find node with id:', anchorId);

    if (!['proposed', 'transfer', 'proposed_new_anchor', 'transfer_new_anchor'].includes(this.activeCommandData?.action_type))
      throw new Error('Invalid linking action type');

    let connId = Object.keys(loadRenderMap.pointLocations[anchorId].data.n)[0];
    let existingAnchorId = this.activeCommandData.source_marker?.anchor_id ?? this.activeCommandData.source_marker?.proposed_anchor_id;

    if (connId) {
      // Get the path to the clicked node's node_type
      let paths = [
        `nodes/${anchorId}/attributes/${this.modelDefaults.node_type_attribute}`,
        `nodes/${anchorId}/attributes/company`,
        `nodes/${anchorId}/attributes/proposed_rod_size`,
        `connections/${connId}`,
        `nodes/${existingAnchorId}/attributes/${this.modelDefaults.node_type_attribute}}`,
        `nodes/${existingAnchorId}/attributes/company`
      ];

      // Get data for this job.
      const data = await GetJobData(this.job_id, paths);

      // Check that the clicked node is an anchor.
      const nodeType = Object.values(data[`nodes/${anchorId}/attributes/${this.modelDefaults.node_type_attribute}`])[0];
      if (!this.modelDefaults.anchor_node_types.includes(nodeType)) {
        const err = 'You can only link anchor nodes.';
        this.toast(err);
        throw new Error(err);
      }

      // Get the id of the other node
      let mainNodeId =
        data[`connections/${connId}`].node_id_1 == anchorId
          ? data[`connections/${connId}`].node_id_2
          : data[`connections/${connId}`].node_id_1;
      let anchorTypeOfExisting = Object.values(
        SquashNulls(data, `nodes/${existingAnchorId}/attributes/${this.modelDefaults.node_type_attribute}`)
      )[0];

      // Get the company data for the anchors
      let companyOfClicked = Object.values(SquashNulls(data, `nodes/${anchorId}/attributes/company`))[0] || null;

      // Get the proposed rod size for the anchor
      let rodSizeOfClicked = Object.values(SquashNulls(data, `nodes/${anchorId}/attributes/proposed_rod_size`))[0] || null;

      // Get the lat/long of the other node
      const otherNodeData = await GetJobData(this.job_id, [`nodes/${mainNodeId}/latitude`, `nodes/${mainNodeId}/longitude`]);

      let clickedNodeLocation = new google.maps.LatLng(
        loadRenderMap.pointLocations[anchorId].data.l[0],
        loadRenderMap.pointLocations[anchorId].data.l[1]
      );
      let mainNodeLocation = new google.maps.LatLng(
        otherNodeData[`nodes/${mainNodeId}/latitude`],
        otherNodeData[`nodes/${mainNodeId}/longitude`]
      );

      // Get the distance and bearing from the main node to the clicked anchor node
      let distanceToClicked = google.maps.geometry.spherical.computeDistanceBetween(mainNodeLocation, clickedNodeLocation);
      let bearingToClicked = Round((google.maps.geometry.spherical.computeHeading(mainNodeLocation, clickedNodeLocation) + 360) % 360, 1);
      distanceToClicked = Round(distanceToClicked / (this.useMetricUnits ? 1 : 0.3048), 1);

      if (existingAnchorId) {
        var companyOfExisting = Object.values(SquashNulls(data, `nodes/${existingAnchorId}/attributes/company`))[0];
        let existingAnchorLocation = new google.maps.LatLng(
          loadRenderMap.pointLocations[existingAnchorId].data.l[0],
          loadRenderMap.pointLocations[existingAnchorId].data.l[1]
        );
        // Get the distance and bearing from the main node to the existing anchor node
        var distanceToExisting = google.maps.geometry.spherical.computeDistanceBetween(mainNodeLocation, existingAnchorLocation);
        var bearingToExisting = Round(
          (google.maps.geometry.spherical.computeHeading(mainNodeLocation, existingAnchorLocation) + 360) % 360,
          1
        );
        distanceToExisting = Round(distanceToExisting / (this.useMetricUnits ? 1 : 0.3048), 1);
      }

      if (distanceToExisting != 0) {
        distanceToExisting = distanceToExisting || null;
      }
      if (bearingToExisting != 0) {
        bearingToExisting = bearingToExisting || null;
      }
      anchorTypeOfExisting = anchorTypeOfExisting || null;
      companyOfExisting = companyOfExisting || null;

      // Determine the spec type that should be added to the down guy
      const spec_type = Object.values(this.otherAttributes?.down_guy_spec?.picklists ?? {}).length != 0 ? 'down_guy_spec' : 'wire_spec';

      // Set the anchor id for the marker
      FirebaseWorker.ref(`${this.activeCommandData.marker_firebase_path}/proposed_anchor_id`).set(anchorId, (err) => {
        if (err) {
          this.toast(err);
        } else {
          // Set the linking data for the dialog
          this.downGuyLinkingData = Object.assign({}, this.activeCommandData, {
            distanceToClicked,
            bearingToClicked,
            anchorTypeOfClicked: nodeType,
            companyOfClicked,
            rodSizeOfClicked,
            distanceToExisting,
            bearingToExisting,
            anchorTypeOfExisting,
            companyOfExisting,
            installAuxEye: false,
            spec_type
          });

          this.generateDownGuyRoutineNote();

          // Show the dialog
          this.$.ProposedDownGuyLinkingDialog.open();
        }
        this.cancelPromptAction();
      });
    }
  }

  getDownGuySpecPicklist(otherAttributes) {
    // The order we do the following in is important because PoleForeman pole to pole guys are stored in a picklist called `guy_specs` in the `wire_spec` attribute, and they are different from the picklist for `down_guy_specs`.

    const items = new Set();

    // This is how down guy specs are stored when imported O-Calc catalogs and PoleForeman MDB files.
    const downGuySpec = otherAttributes.down_guy_spec ?? {};

    // Add all the items from the picklists to the items array
    for (const picklist in downGuySpec.picklists) {
      const values = downGuySpec.picklists[picklist].map((x) => x.value);
      values.forEach((value) => items.add(value));
    }

    // This is how down guy specs are stored when imported from SPIDA client files and DDS Equipment JSON files.
    if (items.size == 0) {
      const wireSpecs = this.getAttributePicklists(otherAttributes, 'wire_spec', 'guy_specs');
      wireSpecs.forEach((spec) => items.add(spec));
    }

    return Array.from(items);
  }

  getAttributePicklists(otherAttributes, attributeName, picklist) {
    let items = [];
    for (let picklistKey in SquashNulls(otherAttributes, attributeName, 'picklists')) {
      // if the picklist is null or the picklist doesn't exist in the models, we want to give the user the option to choose any value in any wire_spec picklist.
      // if picklist not null and it is defined in their models, we only want to include those values in the list of items so skip the rest of the them.
      if ((picklist != null) & (otherAttributes?.[attributeName]?.picklists?.[picklist] !== undefined) && picklistKey != picklist) continue;
      items = items.concat(otherAttributes[attributeName].picklists[picklistKey].map((x) => x.value));
    }
    return items;
  }

  updateProposedDownGuy() {
    let update = {};
    if (this.downGuyLinkingData.selected_spec && this.downGuyLinkingData.spec_type) {
      update[`${this.downGuyLinkingData.marker_firebase_path}/${this.downGuyLinkingData.spec_type}`] =
        this.downGuyLinkingData.selected_spec;
    }
    update[`${this.downGuyLinkingData.marker_firebase_path}/mr_note`] = this.downGuyLinkingData.mr_note;
    FirebaseWorker.ref('/').update(update);
  }

  generateDownGuyRoutineNote() {
    // Create the description for the anchor
    let anchorDescription = 'existing anchor';
    if (this.downGuyLinkingData.anchorTypeOfClicked) {
      anchorDescription = this.downGuyLinkingData.anchorTypeOfClicked.trim();
    }
    anchorDescription = anchorDescription.split(' ');

    // if proposed rod size is valid, the anchor is being replaced, so change verbiage
    if (anchorDescription[0] == 'existing' && this.downGuyLinkingData.rodSizeOfClicked !== null) {
      anchorDescription[0] = 'replaced';
    }

    if (this.downGuyLinkingData.companyOfClicked) {
      anchorDescription.splice(1, 0, this.downGuyLinkingData.companyOfClicked);
    }
    anchorDescription = anchorDescription.join(' ');

    let specForDownGuy = ' ';
    if (this.downGuyLinkingData.selected_spec) {
      specForDownGuy = ` ${this.downGuyLinkingData.selected_spec} `;
    }

    let mrNote = '';
    switch (this.downGuyLinkingData.action_type) {
      case 'proposed':
      case 'proposed_new_anchor': {
        mrNote = `${this.downGuyLinkingData.installAuxEye ? 'Install an aux eye attachment' : 'Place'} on ${anchorDescription} rod with ${Math.round(this.downGuyLinkingData.distanceToClicked)}' lead at ${Math.round(
          this.downGuyLinkingData.bearingToClicked
        )}° and attach${specForDownGuy}guy.`;
        // Note override for AEG models
        if (this.jobCreator == 'aeg') {
          let cardinalDirection = GetCardinalDirection(this.downGuyLinkingData.bearingToClicked);
          let guySpec = this.downGuyLinkingData.distanceToClicked < 10 ? '10M' : '6M';
          mrNote = `${this.downGuyLinkingData.installAuxEye ? 'Install an aux eye attachment' : 'Place'} on ${anchorDescription} rod with ${Math.round(this.downGuyLinkingData.distanceToClicked)}' lead at ${Math.round(
            this.downGuyLinkingData.bearingToClicked
          )}° (${cardinalDirection}) and attach ${guySpec} guy.`;
        }
        break;
      }
      default: {
        mrNote = `Transfer ${this.downGuyLinkingData.marker_description} from anchor at ${Math.round(
          this.downGuyLinkingData.distanceToExisting
        )}' lead at ${Math.round(this.downGuyLinkingData.bearingToExisting)}° to the ${anchorDescription} at ${Math.round(
          this.downGuyLinkingData.distanceToClicked
        )}' lead.`;
        break;
      }
    }

    // Join the new mr note with the existing mr note.
    mrNote = JoinSentences([this.downGuyLinkingData?.existingMrNote, mrNote], { removeDuplicates: true, ignoreCase: true });

    // Set a generated note for the user to edit
    this.set('downGuyLinkingData.mr_note', mrNote);
  }

  async confirmInput(value, { title, subtitle, icon, label }) {
    return new Promise((resolve) => {
      this.confirmInputResolve = resolve;
      this.confirmInputValue = value;
      this.confirmInputTitle = title || 'Confirm Input';
      this.confirmInputSubtitle = subtitle;
      this.confirmInputLabel = label;
      this.confirmInputIcon = icon;
      this.$.confirmInputDialog.open();
    });
  }

  confirmInputAccepted(e) {
    this.$.confirmInputDialog.close();
    this.confirmInputResolve(this.confirmInputValue);
  }

  confirmInputCancel() {
    this.confirmInputResolve = null;
    this.confirmInputValue = null;
    this.$.confirmInputDialog.close();
  }

  async setAttributesFromButton(nodeIds) {
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    let connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);

    let update = {};
    for (let nodeId of nodeIds) {
      let node = this.nodes[nodeId];
      let photo = photos?.[this.getMainPhotoFromNodeId(nodeId)];

      // Get the Markers
      let markers = DataViews.help.getMarkers(photo?.photofirst_data, this.traces, { includeAllMarkerTypes: true, allowProposed: true });
      // And Midspan Markers
      markers.forEach((marker) => {
        marker.midspan_markers = DataViews.help.getMidspanMarkers(
          marker,
          'all',
          node,
          this.nodes,
          connectionLookup[nodeId],
          photos,
          this.traces,
          {}
        );
      });

      let data = { nodeId, node, metadata: this.metadata, jobName: this.jobName, photo, markers };

      for (let property in this.actionDialogModel.attributes) {
        let value = undefined;
        if (this.actionDialogModel.debug) {
          let debug = KLogic.debug(this.actionDialogModel.attributes[property], data);
          value = debug.results[0].res;
        } else {
          value = KLogic.compute(this.actionDialogModel.attributes[property], data);
        }
        if (this.actionDialogModel.confirm) {
          value = await this.confirmInput(value, {
            label: CamelCase(property),
            title: `Confirm ${CamelCase(property)}`,
            subtitle: `${this.modelDefaults.ordering_attribute_label} ${
              PickAnAttribute(node.attributes, this.modelDefaults.ordering_attribute) ||
              '(No ' + this.modelDefaults.ordering_attribute_label + ')'
            }`,
            icon: this.actionDialogModel.icon.replace(/-/g, '_')
          });
        }
        if (typeof value != 'undefined') {
          if (this.actionDialogModel.toggle) {
            let exists = PickAnAttribute(node.attributes, property);
            if (exists) {
              value = typeof value == 'boolean' ? false : '';
            }
          }
          update[`nodes/${nodeId}/attributes/${property}`] = { button_set: value };
          node.attributes[property] = update[`nodes/${nodeId}/attributes/${property}`];
          GeofireTools.setGeohash('nodes', node, nodeId, this.jobStyles, update);
        }
      }
    }
    if (this.job_id) {
      FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
    }
  }

  parseObject(obj, content) {
    for (let key in obj) {
      if (typeof obj[key] == 'object') {
        content +=
          '<div style="display:table; font-size:14px;"><div style="display:table-row;"><div style="display:table-cell;"><b>' +
          key +
          `:</b></div><div style="display:table-cell;"><katapult-button iconOnly noBorder onclick="mapsDesktop_expandReferenceInfo(this)" style="padding:0; width:20px; height:20px;" icon="expand_more"></katapult-button></div></div>`;
        content += '<iron-collapse style="margin-left:15px;">';
        content = this.parseObject(obj[key], content);
        content += '</iron-collapse>';
      } else
        content +=
          '<div style="display:table-row;"><div style="display:table-cell;"><b>' +
          key +
          ':</b></div><div style="display:table-cell;">' +
          obj[key] +
          '</div></div>';
    }
    return content;
  }

  closeInfoPanel() {}

  async downloadAllLayers(e) {
    await import('../../js/open-source/shpwrite.js');
    let geoJsonData = [];

    // get and combine all the feature data for each layer that can be downloaded
    for (const layer of this.mapLayers) {
      const layerData = await getLayer({ jobId: this.job_id, storageFileName: layer.storage_file_name, layerId: layer.$key });
      const features = layerData.features || [];

      // add the features to the geoJsonData array
      if (features?.length > 0) {
        features.forEach((feature) => {
          geoJsonData.push(feature);
        });
      }
    }

    if (geoJsonData) {
      // (optional) set names for feature types and zipped folder
      var options = {
        folder: 'Layers',
        types: {
          point: this.jobName + ' Points',
          polygon: this.jobName + ' Polygons',
          line: this.jobName + ' Lines',
          polyline: this.jobName + ' Polylines'
        }
      };

      let flattened = FlattenGeoJSON({ type: 'FeatureCollection', features: geoJsonData });
      let data = await shpwrite.zip(flattened, options);
      let link = document.createElement('a');
      link.href = 'data:application/zip;base64,' + data;
      // Remove illegal filename characters and set the filename
      link.download = this.jobName.replace(/[<>?|:"*$#[\]\\]/g, ' ').trim() + '.zip';
      link.click();
    } else {
      this.toast('There are no Shapefile files to download.');
    }
  }

  async downloadLayer(e) {
    await import('../../js/open-source/shpwrite.js');
    let layer = SquashNulls(e, 'model', 'item');
    const layerData = await getLayer({ jobId: this.job_id, storageFileName: layer.storage_file_name, layerId: layer.$key });

    // (optional) set names for feature types and zipped folder
    var options = {
      folder: layer.name,
      types: {
        point: layer.name + ' Points',
        polygon: layer.name + ' Polygons',
        line: layer.name + ' Lines',
        polyline: layer.name + ' Polylines'
      }
    };

    let flattened = FlattenGeoJSON({ type: 'FeatureCollection', features: layerData.features || [] });
    let data = await shpwrite.zip(flattened, options);
    let link = document.createElement('a');
    link.href = 'data:application/zip;base64,' + data;
    // Remove illegal filename characters and set the filename
    link.download = layer.name.replace(/[<>?|:"*$#[\]\\]/g, ' ').trim() + '.zip';
    link.click();
  }

  groupLayersAndRefreshMapLayerList() {
    if (!this.mapLayers) return;
    // group the map layers by api_layer_group if they exist
    if (this.apiLayerGroups && Object.values(this.apiLayerGroups).length != 0) {
      // create a map layers object that respects grouping
      const groupedApiLayers = this.$.mapLayersMenuView?.groupLayersByProperties(this.mapLayers, ['api_layer_group']);
      this.set('groupedApiLayers', groupedApiLayers);
    }
    // refresh the layer items in the map layers list
    this.$.mapLayersMenuView?.refreshLayerItems();
  }

  mapLayersChanged() {
    //Runs once on page load, runs on layer deletion, runs on layer add
    this.geoJsonLayers.forEach((layer, index) => {
      let key = layer.key;
      var foundKey = false;
      for (var i = 0; i < this.mapLayers.length; i++) {
        if (this.mapLayers[i].$key == key) {
          foundKey = true;
          break;
        }
      }
      // If we didn't find the key, them remove the geoJson
      if (foundKey == false) {
        for (var i = 0; i < this.geoJsonLayers[index].features.length; i++) {
          this.map.data.remove(this.geoJsonLayers[index].features[i]);
        }
        this.splice('geoJsonLayers', index, 1);
        this.geoJsonLayersChange = !this.geoJsonLayersChange;
      }
    });
    // only sort the changed layers if a flag is set
    if (this.sortMapLayersOnChange) this.mapLayers.sort((a, b) => a.order - b.order);
  }

  /**
   * Exists solely to ensure that a shoelace tree is not selectable.
   * It loops through all of the tree items and sets their selected state to "false"
   */
  unselectAllTreeItems(e) {
    const tree = e.currentTarget;
    const treeItems = tree.querySelectorAll('sl-tree-item');
    treeItems.forEach((item) => (item.selected = false));
  }

  openDeleteMapLayerItemConfirmDialog(e) {
    if (e && e.model && e.model.item) {
      this.mapLayerItemToDelete = e.model.item;
      this.$.deleteMapLayerItemConfirmDialog.open();
    }
  }

  async confirmDeleteMapLayerItem(e) {
    if (this.mapLayerItemToDelete && this.job_id) {
      // if the item to delete has a "groupedLayers" property, then all of those layers need to be deleted.
      const groupedLayers = this.mapLayerItemToDelete.groupedLayers;
      if (groupedLayers) {
        let layersDeleted = 0;
        for (const mapLayerToDelete of groupedLayers) {
          await this.deleteMapLayer(mapLayerToDelete);
          // set a loading message for the api layer section
          this.set('apiLayerLoadingMessage', `Deleting layers (${++layersDeleted}/${groupedLayers.length})...`);
        }
        // clear the loading message
        this.set('apiLayerLoadingMessage', null);
      }
      // if the item to delete does not have a "groupedLayers" property, then just delete the single layer.
      else this.deleteMapLayer(this.mapLayerItemToDelete);
    }
  }

  async deleteMapLayer(layer) {
    if (layer.type == 'Reference Layer' || layer.type == 'API Layer') {
      this.set('multiJobIds.__ref' + layer.$key, null);
    } else if (layer.type == 'Overlay Layer') {
      if (this.overlays[layer.$key]) {
        this.overlays[layer.$key].setMap(null);
        delete this.overlays[layer.$key];
      }
    }

    const { deleteLayer } = await import('../../modules/JobLayers.js');
    await deleteLayer({ jobId: this.job_id, layerId: layer.$key, name: layer.name });
  }

  openMapLayersManager() {
    this.$.mapLayersManagerDialog.open();
  }

  async openSavedViewManager() {
    await import('./saved-view-manager.js');
    this.$.savedViewManager.open();
  }

  crumbTrailsOn() {
    return Array.isArray(this.crumbTrails) && this.crumbTrails.every((x) => x.active);
  }

  toggleJobLayers(e) {
    if (e.currentTarget.checked) {
      this.multiJobIds = this.previousJobIds;
    } else {
      this.previousJobIds = this.multiJobIds;
      this.loadJobMapLayer();
    }
  }

  async toggleAllMapLayers(e) {
    const { checked } = e.currentTarget;
    for (const mapLayer of this.mapLayers ?? []) {
      await this.toggleMapLayer(mapLayer, checked);
    }
  }

  // Handle keys for master location directories a little differently
  masterLocationDirectoryLayerIsOn(directoryId) {
    const key = `MasterLocationDirectory:${directoryId}`;
    return !!(this.multiJobIds?.[`__ref${key}`] ?? this.geoJsonLayers.find((x) => x.key == key) ?? this.overlays?.[key]?.getMap());
  }

  legendItemChecked(key) {
    if (key == 'all') return this.hiddenLegendItems != 'all';
    return this.hiddenLegendItems != 'all' && !SquashNulls(this.hiddenLegendItems, key);
  }

  hideJobDrawer(job_id) {
    return job_id == '';
  }

  hideMasterLocationDirectoryDrawer(masterLocationDirectories) {
    return !masterLocationDirectories || masterLocationDirectories.length == 0;
  }

  showAllMasterLocationDirectoriesChecked() {
    if (this.multiJobIds) {
      for (let key in this.multiJobIds) {
        if (key.includes('MasterLocationDirectory') && this.multiJobIds[key]) {
          return true;
        }
      }
    }
    return false;
  }

  toggleAllMasterLocationDirectories(e) {
    e.stopPropagation();

    if (this.masterLocationDirectories) {
      const { checked } = e.currentTarget;
      this.masterLocationDirectories.forEach((directory) => {
        this.toggleMasterLocationDirectory(checked, directory._id);
      });
    }
  }

  handleToggleMLDChecked(e) {
    const { checked } = e.currentTarget;
    const directoryId = e?.model?.locationDirectory?._id;

    this.toggleMasterLocationDirectory(checked, directoryId);
  }

  /**
   * Toggles a master location directory layer on or off
   * @param {Boolean} checked - Whether the layer is being turned on or off
   * @param {String} directoryId - The ID of the master location directory
   */
  async toggleMasterLocationDirectory(checked, directoryId) {
    // Do nothing if the directory ID is missing
    if (!directoryId) return;

    // If the layer is being turned on
    if (checked) {
      // Get the min zoom and set the default
      const modelsConfigRef = globalThis.FirebaseWorker.database().ref(`photoheight/company_space/${this.userGroup}/models/config`);
      const minZoom = (await modelsConfigRef.child(`map/master_location_directory/master_geohash_min_zoom`).once('value')).val() || 14;

      // Get the directory owner
      const directoryData = this.masterLocationDirectories?.find((directory) => directory._id == directoryId);
      const directoryOwner = directoryData?._owner_company;
      if (!directoryOwner) throw Error(`Unable to determine directory owner for ${directoryId}`);

      // Set the reference to the master location directory layer
      this.set(`multiJobIds.__refMasterLocationDirectory:${directoryId}`, {
        url: `companies/${directoryOwner}/directories/${directoryId}/locations`,
        zIndex: 3,
        keepInCenterPastMinZoom: true,
        minZoom
      });
    }
    // The layer is being turned off
    else this.set(`multiJobIds.__refMasterLocationDirectory:${directoryId}`, null);
  }

  toggleAllCrumbTrails(e) {
    // Active can be passed in or be the value of the checked property of the event target.
    let active = Object(e) === e ? e.currentTarget.checked : e;
    if (this.crumbTrails) for (let i = 0; i < this.crumbTrails.length; i++) this.renderCrumbTrail(this.crumbTrails[i], active);
  }

  renderCrumbTrail(e, active) {
    let item = e.model ? e.model.item : e;
    // If a new state is specified, set it on the item.
    if (typeof active === 'boolean' && this.crumbTrails) {
      let i = this.crumbTrails.findIndex((x) => x.label == item.label);
      if (i != -1) this.set(`crumbTrails.${i}.active`, active);
    }
    let featureCollection = this.crumbTrailData[item.label];
    if (featureCollection) {
      // If features exist for this crumb trail, remove them from the map.
      if (Array.isArray(this.activeCrumbTrailFeatures[item.label])) {
        this.activeCrumbTrailFeatures[item.label].forEach((feature) => this.map.data.remove(feature));
        this.activeCrumbTrailFeatures[item.label] = null;
      }
      // If this crumb trail is active, add it to the map.
      if (item.active) {
        this.activeCrumbTrailFeatures[item.label] = this.map.data.addGeoJson(featureCollection);
      }
    }
  }

  addUtilityLayerToList(utilityLayers, basePath, list) {
    // Sort the layers by their sort order - layers used to be stored as an array, but now they are
    // stored as an object, so we added support for a sort_order to control the list order
    const utilityLayerKeys = Object.keys(utilityLayers || {}).sort((layerKeyA, layerKeyB) => {
      const layerA = utilityLayers[layerKeyA];
      const layerB = utilityLayers[layerKeyB];
      return (layerA.sort_order || 0) - (layerB.sort_order || 0);
    });
    // Add the layer to the list with a ref_layer_config_source path for fetching config data dynamically
    for (const layerKey of utilityLayerKeys) {
      const layer = utilityLayers[layerKey];
      list.push({
        ref_layer_config_source: `${basePath}/${layerKey}`,
        ...layer
      });
    }
  }

  async getUtilityInfoList(masterUtilityInfoList, companyUtilityInfoList) {
    let list = [];

    // Add layers from the utility and your company from utility_info to the list
    this.addUtilityLayerToList(masterUtilityInfoList, `utility_info/_list`, list);
    this.addUtilityLayerToList(companyUtilityInfoList, `utility_info/${this.userGroup}/_list`, list);

    if (this.signedIn && this.userGroup && this.userGroup != '_custom_auth') {
      this.referenceLayerUserGroup = this.userGroup;
      let refLayers = await firebase
        .firestore()
        .collection(`companies/${this.userGroup}/reference_layers`)
        .get()
        .then((s) => s.docs.map((doc) => ({ $key: doc.id, ...doc.data() })));
      refLayers.forEach((doc) => {
        list.push({
          options: {
            firestore: true,
            ...doc.options
          },
          url: `companies/${this.userGroup}/reference_layers/${doc.$key}/locations`,
          name: doc.name
        });
      });
    }
    this.utilityInfoList = list;
  }

  /**
   * Used to determine whether to toggle a layer group or single layer
   * @param {object} e - Event details for the triggering element
   */
  async toggleMapLayerItem(e) {
    const checked = e.detail?.checked;
    const toggledMapLayerItem = e.detail?.item;
    if (checked == null || toggledMapLayerItem == null) return;

    // if the layer item has grouped layer, it's a layer group, toggle all of the layers
    const groupedLayers = toggledMapLayerItem.groupedLayers;
    if (groupedLayers) {
      for (const layer of groupedLayers) {
        await this.toggleMapLayer(layer, checked);
      }
    } else await this.toggleMapLayer(toggledMapLayerItem, checked);
  }

  /**
   * Toggles a given map layer on or off.  It does all of the following:
   * 1. If the layer is a reference layer, it uses the current geoJsonQuery object to add or remove the layer from the map
   * 2. If the layer is an API Layer, it adds or removes the layer to the multiJobIds object, which is then used by the api-map-layer element
   * 3. If the layer is an overlay layer, it adds the layer to the list of overlays
   * 4. If the layer is  anything else, it will update the geo json layers
   * @param {object} mapLayerToToggle - The map layer to toggle
   * @param {boolean} checked - Whether the layer is being turned on or off
   */
  async toggleMapLayer(mapLayerToToggle, checked) {
    if (mapLayerToToggle == null || checked == null || !this.job_id) return;

    // Turn the map layer on or off depending on its type
    if (mapLayerToToggle.type == 'Reference Layer') {
      // If the layer has a ref_layer_config_source, then load the config from there instead of from the data on the job
      if (checked && mapLayerToToggle?.ref_layer_config_source) {
        // Try to load the remote config data and continue if a permission error happens so we fall back to local config
        try {
          const remoteConfigData = (await FirebaseWorker.ref(mapLayerToToggle?.ref_layer_config_source).once('value')).val();
          Object.assign(mapLayerToToggle, remoteConfigData);
        } catch (err) {
          console.log('Could not load reference layer source at', mapLayerToToggle?.ref_layer_config_source);
          console.error(err);
        }
      }

      if (mapLayerToToggle.loadType == 'geojson-query') {
        if (!this.geoJsonQuery)
          this.geoJsonQuery = new GeoJSONQuery(
            this.map,
            (loading) => {
              this.mapLoadingChanged(loading, 'geoJsonQuery');
            },
            google,
            config
          );
        if (checked) {
          this.geoJsonQuery.addLayer(mapLayerToToggle);
        } else {
          this.geoJsonQuery.removeLayer(mapLayerToToggle);
        }
      } else {
        if (checked) {
          // Get the url for the data in the layer. If the data is stored in Firestore, then
          // leave the url alone. If the url already starts with utility_info, then leave it alone.
          // Otherwise, prepend "utility_info" to the url. This is because we used to store the urls without
          // the utility_info prefix, but once we started importing utility data with timestamps, we update
          // the urls and include the utility_info prefix.
          const layerIsInFirestore = !!mapLayerToToggle.options?.firestore;
          const layerUrlStartsWithUtilityInfo =
            mapLayerToToggle.url.startsWith('utility_info') || mapLayerToToggle.url.startsWith('/utility_info');
          const urlPrefix = layerIsInFirestore || layerUrlStartsWithUtilityInfo ? '' : 'utility_info/';
          const layerDataUrl = urlPrefix + mapLayerToToggle.url;

          let options = { url: layerDataUrl };
          for (let key in mapLayerToToggle.options) {
            options[key] = mapLayerToToggle.options[key];
          }
          this.set('multiJobIds.__ref' + mapLayerToToggle.$key, options);
        } else {
          this.set('multiJobIds.__ref' + mapLayerToToggle.$key, null);
        }
      }
    } else if (mapLayerToToggle.type == 'API Layer') {
      if (checked) {
        this.set('multiJobIds.__ref' + mapLayerToToggle.$key, mapLayerToToggle);
      } else {
        this.set('multiJobIds.__ref' + mapLayerToToggle.$key, null);
      }
    } else if (mapLayerToToggle.type == 'Overlay Layer') {
      if (checked) {
        if (!this.overlays[mapLayerToToggle.$key]) {
          let bounds = mapLayerToToggle.options.bounds;
          firebase
            .storage()
            .ref(`job_files/${this.job_id}/${mapLayerToToggle.$key}`)
            .getDownloadURL()
            .then((url) => {
              this.overlays[mapLayerToToggle.$key] = new google.maps.GroundOverlay(url, bounds);
              this.overlays[mapLayerToToggle.$key].setMap(this.map);
              this.mapOverlaysChanged = !this.mapOverlaysChanged;
            });
        } else {
          this.overlays[mapLayerToToggle.$key].setMap(this.map);
          this.mapOverlaysChanged = !this.mapOverlaysChanged;
        }
      } else {
        if (this.overlays[mapLayerToToggle.$key]) {
          this.overlays[mapLayerToToggle.$key].setMap(null);
          this.mapOverlaysChanged = !this.mapOverlaysChanged;
        }
      }
    } else {
      let geoJsonLayer = this.geoJsonLayers.find((x) => x.key == mapLayerToToggle.$key);
      if ((checked && !geoJsonLayer) || (!checked && geoJsonLayer)) {
        if (checked) {
          const layerData = await getLayer({
            jobId: this.job_id,
            storageFileName: mapLayerToToggle.storage_file_name,
            layerId: mapLayerToToggle.$key
          });
          if (layerData) {
            layerData.features = layerData.features || [];
            const addedLayer = this.map.data.addGeoJson(layerData);
            this.push('geoJsonLayers', {
              key: mapLayerToToggle.$key,
              features: addedLayer,
              storage_file_name: mapLayerToToggle.storage_file_name
            });
            // Default selectable to true if it's not included
            let selectable = mapLayerToToggle?.selectable ?? true;
            this.setMapLayerSelectable(mapLayerToToggle.$key, selectable);
            this.geoJsonLayersChange = !this.geoJsonLayersChange;
          }
        } else {
          if (geoJsonLayer) {
            let geoJsonLayerIndex = this.geoJsonLayers.indexOf(geoJsonLayer);
            for (var i = 0; i < geoJsonLayer.features.length; i++) {
              this.map.data.remove(geoJsonLayer.features[i]);
            }
            this.splice('geoJsonLayers', geoJsonLayerIndex, 1);
            this.geoJsonLayersChange = !this.geoJsonLayersChange;
          }
        }
      }
    }
    // toggle the ignore of matches the current view with the saved view (need this to be ignore to turn on all layers)
    this.ignoreSavedViewMatch = false;
  }

  toggleMapLayerSelectable(e) {
    // Get the event data
    const layer = e.detail.layer;
    const isSelectable = e.detail.isSelectable;
    if (layer == null || isSelectable == null) return;
    // Get the id of the layer that was toggled
    const layerId = layer.$key;
    // Set unclickable for reference map layers
    const mapLayerInDesktop = this.mapLayers.find((x) => x.$key == layerId);
    mapLayerInDesktop.unclickable = isSelectable;
    // Set the layer to the opposite selectable state
    this.setMapLayerSelectable(layerId, !isSelectable);
  }

  setMapLayerSelectable(layerId, selectable) {
    //Runs once per layer on page load, runs whe layer toggled on, runs when layer interaction is toggled
    let geoJsonLayerIndex = this.geoJsonLayers.findIndex((x) => x.key == layerId);
    let geoJsonLayer = this.geoJsonLayers[geoJsonLayerIndex];
    if (geoJsonLayer) {
      // Update all layers in the feature to be unclickable
      for (let i = 0; i < geoJsonLayer.features.length; i++) {
        geoJsonLayer.features[i].setProperty('_unclickable', !selectable);
      }
      // Set the selectable flag on the layer
      this.set(`geoJsonLayers.${geoJsonLayerIndex}.selectable`, selectable);
    }

    // if the layer is stored in multiJobIds or overlays, update the selectable property so that we can store whether the layer is selectable in session storage
    if (this.overlays[layerId]) {
      this.overlays[layerId]?.setOptions({ clickable: selectable });
    }
    if (this.multiJobIds[`__ref${layerId}`]) {
      this.set(`multiJobIds.__ref${layerId}.selectable`, selectable);
    }
  }

  areAnyMapLayersOn() {
    const layerItems = this.$.mapLayersMenuView?.layerItems;
    return layerItems?.some((layerItem) => layerItem.state?.checked ?? false);
  }

  refreshAllLayerItemStates() {
    // Refresh the state for the layer items
    this.$.mapLayersMenuView?.refreshStateForAllLayerItems();
    // Check to see if any map layers are on
    this.mapLayersAreOn = this.areAnyMapLayersOn();
  }

  async updatePoleListItems() {
    let showAllItems = false;
    if (this.updatingPoleListItems) {
      this.missedPoleListUpdate = true;
      return;
    }
    this.updatingPoleListItems = true;
    this.missedPoleListUpdate = false;
    // TODO: this gets called everytime a node attribute is changed (I think because writing an attr updates geostyle and this watches
    // geostyle)?
    // Check if the search string looks like an app number (if Review Contractor).
    if (
      this.isUtilityReviewContractor &&
      (this.poleListSearchText.substring(0, 4) == 'APP_' || this.poleListSearchText.substring(0, 4) == 'REL_')
    ) {
      this.poleListItems = [{ icons: ['assignment'], type: 'external_job', label: this.poleListSearchText }];
    } else {
      // Build lookup of node labels.
      let nodeLabels = {};
      let foundOrderVal = false;
      let foundTags = false;
      let powerCompanies = this.get('otherAttributes.company.picklists.power_companies') || [];
      for (let nodeId in this.nodes) {
        let orderAttributeValue = PickAnAttribute(this.get(`nodes.${nodeId}.attributes`), this.modelDefaults.ordering_attribute);
        let orderValue = '(' + (orderAttributeValue || 'No Value') + ')';
        // Get pole tags and give priority to power tags (if no power tags, use the other tags).
        let poleTags = Object.values(this.get(`nodes.${nodeId}.attributes.pole_tag`) || {});
        let powerTags = poleTags.filter((tag) => powerCompanies.some((company) => company.value === tag.company));
        let tags = (powerTags.length ? powerTags : poleTags).map((tag) => tag.tagtext).join(', ');
        if (orderAttributeValue && !foundOrderVal) foundOrderVal = true;
        if (tags && !foundTags) foundTags = true;
        nodeLabels[nodeId] = { orderValue, tags };
      }

      for (let nodeId in nodeLabels) {
        let labelParts = [];
        if (foundOrderVal) labelParts.push(nodeLabels[nodeId].orderValue);
        if (nodeLabels[nodeId].tags) labelParts.push(nodeLabels[nodeId].tags);
        nodeLabels[nodeId].label = labelParts.join(' - ');
      }

      // Build a list of filtered nodes.
      let tempPoleListItems = [];
      // Also build a list of nodes to hide.
      let tempHiddenNodes = [];
      for (let nodeId in this.nodes) {
        let label = nodeLabels[nodeId].label;
        let attributes = this.get(`nodes.${nodeId}.attributes`) || {};
        let labelMatches = label.toLowerCase().includes(String(this.poleListSearchText).toLowerCase());

        let filtersMatch = this.$.poleListQuery.eval(
          (attr) => {
            if (attr == '$nodeId') return nodeId;
            // Return a list of all values for this attribute.
            return Object.values(attributes[attr] ?? {});
          },
          () => GetMainPhoto(this.nodes[nodeId].photos)
        );

        let style = GeofireTools._getItemGeoStyle('nodes', this.get(`nodes.${nodeId}`), this.jobStyles, nodeId);
        if (labelMatches && filtersMatch) {
          tempPoleListItems.push({ key: nodeId, label, type: 'pole', icons: [style] });
        } else {
          tempHiddenNodes.push(nodeId);
        }
      }

      // Update hidden nodes.
      this.hiddenNodes = this.poleListMapSync ? tempHiddenNodes : [];

      // Use hidden nodes to build a list of hidden connections.
      let tempHiddenConnections = [];
      for (let connId in this.connections) {
        let conn = this.connections[connId];
        if (this.hiddenNodes.includes(conn.node_id_1) || this.hiddenNodes.includes(conn.node_id_2)) tempHiddenConnections.push(connId);
      }

      this.hiddenConnections = tempHiddenConnections;

      // Sort list by labels.
      tempPoleListItems = tempPoleListItems.sort((a, b) => {
        if (a.label.trim() < b.label.trim()) return -1;
        if (a.label.trim() > b.label.trim()) return 1;
        return 0;
      });

      // Check if the item is a valid pole tag and if so, add it to the list.
      if (this.isUtilityReviewContractor && this.tag_cleaner(this.poleListSearchText).valid) {
        tempPoleListItems.push({ icons: ['image:blur-on'], type: 'external_pole', label: this.poleListSearchText.toUpperCase() });
      }

      // Check for National Grid Poles (National Grid Ids are 9 numbers).
      if (
        ['katapult', 'national_grid'].includes(this.userGroup) &&
        [8, 9, 10].includes(this.poleListSearchText.length) &&
        !isNaN(parseInt(this.poleListSearchText))
      ) {
        let lookup = await FirebaseWorker.ref(`photoheight/pole_lookup/National Grid/${FirebaseEncode.encode(this.poleListSearchText)}`)
          .once('value')
          .then((s) => s.val());
        if (lookup && lookup.jobs)
          for (let jobId in lookup.jobs) {
            let jobName = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/name`)
              .once('value')
              .then((s) => s.val());
            let mapStyles = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/map_styles`)
              .once('value')
              .then((s) => s.val());
            for (let nodeId in lookup.jobs[jobId].nodes) {
              let node = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/nodes/${nodeId}`)
                .once('value')
                .then((s) => s.val());
              let style = GeofireTools._getItemGeoStyle('nodes', node, mapStyles, nodeId);
              tempPoleListItems.push({
                key: nodeId,
                label: `${this.poleListSearchText} in ${jobName}`,
                type: 'pole',
                jobId,
                icons: [{ icon: style.i, color: style.c, size: parseInt(style.s) + 16, containerPadding: 0, containerMargin: '0 16px 0 0' }]
              });
            }
          }
      }

      // If there are no pole results yet, try to match the search text to a location.
      if (!tempPoleListItems.length && this.googleGeocoder && this.poleListSearchText) {
        showAllItems = true;
        let searchSplit = (this.poleListSearchText || '').replace(/[(|)|\[|\]|\{|\}]/g, '').split(',');
        // if splitting the string by comma doesn't work try splitting by a space
        if (searchSplit.length == 1) {
          searchSplit = searchSplit[0].split(' ');
        }
        if (searchSplit.length == 2 && !isNaN(Number(searchSplit[0])) && !isNaN(Number(searchSplit[1]))) {
          let latitude = Number(searchSplit[0]);
          let longitude = Number(searchSplit[1]);
          tempPoleListItems.push({
            icons: ['maps:my-location'],
            type: 'latlng',
            label: `(${Round(latitude, 10)}, ${Round(longitude, 10)})`,
            latitude,
            longitude
          });
        }
        await new Promise((resolve, reject) => {
          this.googleGeocoder.geocode({ address: this.poleListSearchText, bounds: this.map.getBounds() }, (res, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              res.forEach((x) =>
                tempPoleListItems.push(Object.assign(x, { icons: ['maps:place'], type: 'location', label: x.formatted_address }))
              );
            }
            resolve();
          });
        });
      }

      // If the company has master location directories with attributes
      // stored, then show an option to search the directories
      if (this.poleListSearchText && this.masterLocationDirectories && this.masterLocationDirectories.find((x) => x.location_attributes)) {
        tempPoleListItems.push({
          icons: ['find-in-page'],
          type: 'search_master_location_directories',
          label: 'Search Location Directories...',
          value: this.poleListSearchText
        });
      }

      if (!tempPoleListItems || !tempPoleListItems.length) tempPoleListItems = this.getNoResultsSearchItem();
      this.showAllItems = showAllItems;
      this.poleListItems = tempPoleListItems;
    }
    this.updatingPoleListItems = false;
    if (this.missedPoleListUpdate) this.updatePoleListItems();
  }

  async searchMasterLocationDirectoryAttributes(searchValue) {
    if (this.masterLocationDirectories && searchValue) {
      let results = await MLD.searchAttributeLocations(this.userGroup, searchValue).catch((err) => {
        if (err) {
          this.toast(err);
        }
      });
      if (results.length) {
        // Warn the user about the results limit
        if (results.length >= 30) {
          this.toast('Only showing the top 30 results from Location Directories');
        }
        this.poleListItems = results.map((x) => {
          let directory = this.masterLocationDirectories.find((directory) => directory._id == x.d);
          let attribute = ToTitleCase(x.a);
          return {
            icons: [
              {
                iconSize: 18,
                icon: 'katapult-map:circle',
                color: directory.default_location_color
              }
            ],
            type: 'search_master_location_directories_result',
            label: `Match for ${attribute}`,
            value: x
          };
        });
      } else {
        this.poleListItems = this.getNoResultsSearchItem();
      }
    }
  }

  getNoResultsSearchItem() {
    return [{ icons: ['social:mood-bad'], label: 'NO RESULTS' }];
  }

  async uploadMapLayerData(name, fileType, geoJSON) {
    const { createLayer } = await import('../../modules/JobLayers.js');

    if (geoJSON.features) {
      // Check for NaN in properties and coordinates
      geoJSON.features = geoJSON.features.filter((feature) => {
        for (let property in feature.properties) {
          if (feature.properties[property] !== feature.properties[property]) {
            feature.properties[property] = '';
          }
          // Firebase encode all properties so they can be valid Firebase keys
          let newProperty = FirebaseEncode.encode(property);
          // If the encoded key is different, add it and remove the old one
          if (newProperty != property) {
            feature.properties[newProperty] = JSON.parse(JSON.stringify(feature.properties[property]));
            delete feature.properties[property];
          }
        }
        let coords = feature?.geometry?.coordinates;
        if (Array.isArray(coords)) {
          coords = coords.flat(5);
          if (coords.some((coordinate) => coordinate !== coordinate)) {
            return false;
          }
        }
        return true;
      });
      geoJSON.features.forEach((feature) => {
        if (feature?.geometry?.type == 'Polygon') feature.properties['fill'] = '#fff';
        // if this layer contains some features that have no key property, create those key properties.
        feature.properties = feature.properties || {};
        feature.properties._key = feature.properties._key || FirebaseWorker.ref().push().key;
      });
    }

    const createLayerConfig = {
      jobId: this.job_id,
      name,
      features: geoJSON.features,
      type: fileType,
      order: geoJSON.order
    };

    await createLayer(createLayerConfig)
      .catch((error) => {
        const message = error.message || 'An error occurred while uploading the map layer data.';
        if (message.includes('SizeError')) {
          this.toast('Layer data is too large to upload. Please reduce the size of the file to below 64MB and try again.', null, 10000);
        } else {
          throw error;
        }
      })
      .finally(() => {
        this.$.mapLayersFileUploadSpinner.active = false;
        // Clear the file input
        this.shadowRoot.querySelector('#mapLayersFileInput').value = '';
      });
  }

  editFeature(featureRef) {
    if (this.canWrite) {
      this.editingMapLayer = null;
      this.editingFeatureRef = featureRef;
      this.editingFeature = this.getFeatureFromRef(featureRef);
      this.editingMapLayerColor = this.editingFeature.getProperty('stroke');
      this.editingMapLayerFillColor = this.editingFeature.getProperty('fill');
      this.editingMapLayerWeight = this.editingFeature.getProperty('stroke-width') || 3;
      this.$.editFeatureDialog.open();
      if (this.geoJsonInfo) this.geoJsonInfo.close();
    }
  }

  editFeaturePoints(featureRef) {
    this.editingFeatureRef = featureRef;
    this.editingFeature = this.getFeatureFromRef(featureRef);
    this.edtingFeatureBackupGeometry = this.editingFeature.getGeometry();
    this.editingFeature.setProperty('editable', true);
    this.$.katapultMap.openActionDialog({
      title: 'Edit Feature Geometry',
      text: 'Warning: editing a feature will remove all z coordinates from the layer.',
      cancel: () => {
        this.editingFeature.setGeometry(this.edtingFeatureBackupGeometry);
        this.editingFeature.setProperty('editable', false);
        this.cancelPromptAction();
      },
      buttons: [
        {
          title: 'Cancel',
          callback: () => {
            this.editingFeature.setGeometry(this.edtingFeatureBackupGeometry);
            this.editingFeature.setProperty('editable', false);
            this.cancelPromptAction();
          },
          attributes: { outline: '' }
        },
        {
          title: 'Save',
          callback: async () => {
            this.toast('Updating layer geometry...', null, 0);
            const { updateFeature } = await import('../../modules/JobLayers.js');
            // Check if the map layer has not been flattened yet. If not, we should
            // flatten every feature and set the flag. This is to avoid the issue
            // where features that are in a 3D format from the shapefile (includes
            // a z coordinate) won't be broken away from the edited feature. Also
            // don't try to flatten master reference layers
            let flattened = await FirebaseWorker.ref(
              `photoheight/jobs/${this.job_id}/layers/list/${this.editingFeatureRef.layerKey}/flattened`
            )
              .once('value')
              .then((s) => s.val());
            // Turn off editing for the feature
            this.editingFeature.setProperty('editable', false);
            this.cancelPromptAction();
            if (!flattened && !this.editingFeatureRef.masterRefLayer) {
              let features = this.geoJsonLayers.find((x) => x.key == this.editingFeatureRef.layerKey)?.features || [];
              try {
                // Loop through all of the features in the layer
                for (let i = 0; i < features.length; i++) {
                  // Get a reference to the feature
                  let tempRef = this.getFeatureRef(features[i]);
                  // Get the feature from the data layer
                  let dataLayerFeature = this.getFeatureFromRef(tempRef);
                  // Convert the feature to GeoJson and save to flatten
                  const data = await new Promise((resolve) => {
                    dataLayerFeature.toGeoJson((data) => {
                      resolve(data);
                    });
                  });
                  await updateFeature({
                    jobId: this.job_id,
                    layerId: tempRef.layerKey,
                    featureId: tempRef.featureKey,
                    storageFileName: tempRef.storageFileName,
                    geometry: data.geometry,
                    commit: true
                  });
                }
              } catch (error) {
                this.toast(error.message);
                this.editingFeature.setGeometry(this.edtingFeatureBackupGeometry);
              }

              // Set the flattened flag
              await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/layers/list/${this.editingFeatureRef.layerKey}/flattened`).set(
                true
              );

              this.toast('Layer update complete!');
            } else {
              this.editingFeature.toGeoJson(async (data) => {
                if (this.editingFeatureRef && !this.editingFeatureRef.masterRefLayer) {
                  try {
                    await updateFeature({
                      jobId: this.job_id,
                      layerId: this.editingFeatureRef.layerKey,
                      featureId: this.editingFeatureRef.featureKey,
                      storageFileName: this.editingFeatureRef.storageFileName,
                      geometry: data.geometry,
                      commit: true
                    });
                    this.toast('Layer update complete!');
                  } catch (error) {
                    this.toast(error.message);
                    this.editingFeature.setGeometry(this.edtingFeatureBackupGeometry);
                  }
                }
              });
            }
          },
          attributes: { 'secondary-color': '' }
        }
      ]
    });
    if (this.geoJsonInfo) this.geoJsonInfo.close();
  }

  async copyFeature(e) {
    let button = e.currentTarget;
    button.loading = true;
    await new Promise((resolve) => {
      this.editingFeature.toGeoJson(async (data) => {
        resolve(
          (async () => {
            if (this.copyFeatureLayer) {
              let layer = this.$.mapLayers.child(this.copyFeatureLayer);
              if (layer.type == 'Reference Layer' || layer.type == 'API Layer' || layer.type == 'Overlay Layer') {
                this.toast('Reference Layers cannot have polygons added to them.');
              } else {
                const { addFeatureToLayer } = await import('../../modules/JobLayers.js');
                try {
                  // Add the feature to the layer and store it in cloud storage (or RTDB)
                  await addFeatureToLayer({
                    jobId: this.job_id,
                    layerId: this.copyFeatureLayer,
                    feature: data,
                    storageFileName: layer.storage_file_name
                  });

                  // Add the feature to the map
                  let geoJsonLayer = this.geoJsonLayers.find((x) => x.key == this.copyFeatureLayer);
                  if (geoJsonLayer) geoJsonLayer.features.push(...this.map.data.addGeoJson(geoJson));
                  this.toast('Feature copied!');
                } catch (error) {
                  this.toast(error.message);
                }
              }
            } else {
              this.toast('No layer selected to copy to');
            }
          })()
        );
      });
    });
    button.loading = false;
  }

  async deleteFeature() {
    const { deleteFeatureFromLayer } = await import('../../modules/JobLayers.js');
    try {
      // Delete the feature from the layer and remove it from cloud storage (or RTDB)
      await deleteFeatureFromLayer({
        jobId: this.job_id,
        layerId: this.editingFeatureRef.layerKey,
        featureId: this.editingFeatureRef.featureKey,
        storageFileName: this.editingFeatureRef.storageFileName
      });

      // Remove the feature from the map
      if (this.editingFeature) this.map.data.remove(this.editingFeature);
      this.editingFeatureRef = this.editingFeature = null;
      this.toast('Feature deleted!');
    } catch (error) {
      this.toast(error.message);
    }
  }

  promptCopyFeature(featureRef) {
    this.editingFeatureRef = featureRef;
    this.editingFeature = this.getFeatureFromRef(featureRef);
    if (this.editingFeature.masterRefLayer) {
      this.editingFeatureRef = this.editingFeature = null;
    } else {
      this.$.copyFeatureDialog.open();
      if (this.geoJsonInfo) this.geoJsonInfo.close();
    }
  }

  promptDeleteFeature(featureRef) {
    this.editingFeatureRef = featureRef;
    this.editingFeature = this.getFeatureFromRef(featureRef);
    if (this.editingFeature.masterRefLayer) {
      this.editingFeatureRef = this.editingFeature = null;
    } else {
      this.$.deleteFeatureDialog.open();
      if (this.geoJsonInfo) this.geoJsonInfo.close();
    }
  }
  editFeatureProperty(featureRef, property) {
    this.editingFeatureRef = featureRef;
    this.editingFeatureProperty = property;
    this.editingFeature = this.getFeatureFromRef(featureRef);
    this.editingFeaturePropertyValue = this.editingFeature.getProperty(property);
    this.$.editFeaturePropertyDialog.open();
    if (this.geoJsonInfo) this.geoJsonInfo.close();
  }

  editMapLayer(e) {
    if (this.canWrite) {
      this.editingFeatureRef = this.editingFeature = null;
      this.editingMapLayer = e.model.item;
      this.editingMapLayerColor = e.model.item.color;
      this.editingMapLayerWeight = e.model.item.weight;
      this.editingMapLayerIcon = e.model.item.defaultIcon?.icon || null;
      this.editingMapLayerIconColor = e.model.item.defaultIcon?.color || null;
      this.$.editFeatureDialog.open();
    }
  }

  editingFeaturePropertyChanged() {
    this.$.editFeaturePropertyDialog.notifyResize();
  }

  getFeatureFromRef(featureRef) {
    let geoJsonLayerIndex = this.geoJsonLayers.findIndex((x) => x.key == featureRef.layerKey);
    return featureRef.masterRefLayer
      ? this.get('geoJsonQuery.features.' + featureRef.layerKey + '.' + featureRef.featureIndex)
      : this.get(`geoJsonLayers.${geoJsonLayerIndex}.features.${featureRef.featureIndex}`);
  }

  poleListFilter(e) {
    return true;
  }

  poleListSelectedChanged(e) {
    let selectedItem = e.detail.selectedItem;
    let currentTarget = e.currentTarget;
    if (selectedItem) {
      if (this.modelDefaults.pole_node_types.includes(selectedItem.type)) {
        // Switch to job of item.
        if (selectedItem.jobId && selectedItem.jobId != this.job_id) {
          this.job_id = selectedItem.jobId || this.job_id;
          setTimeout(() => {
            this.editingItemJob = this.job_id;
            this.editing = 'Node';
            this.selectedNode = this.editingNode = selectedItem.key;
            loadRenderMap.zoomToItem = selectedItem.key;
          });
        } else {
          this.$.katapultMap.selectNode({ detail: { key: selectedItem.key, jobId: this.job_id } });
          this.zoomToNode(selectedItem.key);
        }
      } else if (selectedItem.type === 'latlng') {
        this.latitude = selectedItem.latitude;
        this.longitude = selectedItem.longitude;
        this.zoomText = selectedItem.label;
        this.$.katapultMap.zoomToLocation({
          latitude: this.latitude,
          longitude: this.longitude,
          minZoom: 15,
          showMarker: true,
          markerContent: this.zoomText
        });
      } else if (selectedItem.type === 'location') {
        this.latitude = selectedItem.geometry.location.lat();
        this.longitude = selectedItem.geometry.location.lng();
        this.zoomText = selectedItem.formatted_address;
        this.$.katapultMap.zoomToLocation({
          latitude: this.latitude,
          longitude: this.longitude,
          minZoom: 15,
          showMarker: true,
          markerContent: this.zoomText
        });
      }
      // If the selected item is an external pole or job (an APP number or
      // pole tag not in the current job), then search the context data
      else if (selectedItem.type === 'external_pole' || selectedItem.type === 'external_job') {
        this.searchContextLayers(selectedItem.label);
        this.selectedContextLayers = ['poles'];
      }
      // Check if the item is a search result from searching the master location directories for an attribute
      else if (selectedItem.type == 'search_master_location_directories_result') {
        let location = SquashNulls(selectedItem, 'value', 'l');
        let directoryId = SquashNulls(selectedItem, 'value', 'd');
        if (location && directoryId) {
          // Turn on the directory layer
          this.toggleMasterLocationDirectory(true, directoryId);
          this.$.katapultMap.zoomToLocation({
            latitude: location[0],
            longitude: location[1],
            zoom: 20,
            showMarker: true,
            markerContent: 'Marker Context'
          });
        } else {
          this.toast('Error finding location directory item');
        }
      }
      if (!this.pinSearchBar) {
        currentTarget.clear();
        setTimeout(() => this.updatePoleListItems());
      }
    } else if (this.pinSearchBar) {
      setTimeout(() => (currentTarget.value = this.selectedNode));
    }
  }

  poleListSelectedWillChange(e) {
    let selectedItem = e.detail.selectedItem;
    let currentTarget = e.currentTarget;
    // If the selected item is to search the master location directories, then run that search
    if (selectedItem && selectedItem.type == 'search_master_location_directories') {
      // Prevent the selection in the drop down (this will automatically be set
      // back by the drop down code)
      currentTarget.preventSelection = true;
      // Prevent the drop down from closing on selection
      currentTarget.keepOpen = true;
      // Delay fot 50ms and then allow the dialog to close again (for future clicks)
      setTimeout(() => {
        currentTarget.keepOpen = false;
      }, 50);
      // Search the master location directories
      this.searchMasterLocationDirectoryAttributes(selectedItem.value);
    }
  }

  async changeFeatureProperty() {
    // Update the feature in cloud storage (or RTDB), if it isn't a master reference layer
    if (!this.editingFeatureRef.masterRefLayer) {
      const { updateFeature } = await import('../../modules/JobLayers.js');
      await updateFeature({
        jobId: this.job_id,
        layerId: this.editingFeatureRef.layerKey,
        featureId: this.editingFeatureRef.featureKey,
        storageFileName: this.editingFeatureRef.storage_file_name,
        properties: {
          [this.editingFeatureProperty]: this.editingFeaturePropertyValue
        },
        commit: true
      });

      // Update the feature on the map
      this.editingFeature.setProperty(this.editingFeatureProperty, this.editingFeaturePropertyValue);

      this.$.editFeaturePropertyDialog.close();
    }
  }

  async changeMapLayerName(e) {
    if (e.model && e.model.item && e.model.item.$key && this.job_id) {
      const newName = e.model.item.name;
      const storageFileName = e.model.item.storage_file_name;

      // Update the layer in cloud storage (or RTDB)
      const { updateLayer } = await import('../../modules/JobLayers.js');
      await updateLayer({
        jobId: this.job_id,
        layerId: e.model.item.$key,
        storageFileName,
        name: newName
      });
    }
  }

  showConfigurableIconFallback(editingFeatureRef, configurable_kmz_icon_fallback) {
    return !editingFeatureRef && configurable_kmz_icon_fallback === true;
  }

  openIconDialog() {
    // don't change the default icon if the user can't write, or there is no job id
    if (!this.canWrite || !this.job_id) return;
    this.$.iconDialog.open();
  }

  async mapLayerIconSelectionMade(e) {
    // set the editing icon to the one we just picked
    this.editingMapLayerIcon = e.detail.icon;
  }

  getFeatureRef(feature) {
    // Feature could be part of an imported layer(this.geoJsonLayers) or a master layer (this.geoJsonQuery).
    let featureRef = { layerKey: null, featureIndex: -1, featureKey: feature.getProperty('_key') };
    for (let geoJsonLayer of this.geoJsonLayers) {
      featureRef.layerKey = geoJsonLayer.key;
      featureRef.storageFileName = geoJsonLayer.storage_file_name;
      featureRef.featureIndex = geoJsonLayer.features.indexOf(feature);
      if (featureRef.featureIndex != -1) break;
    }
    // Check master layers.
    if (featureRef.featureIndex == -1 && this.geoJsonQuery) {
      for (let key in this.geoJsonQuery.features) {
        featureRef.layerKey = key;
        featureRef.featureIndex = this.geoJsonQuery.features[featureRef.layerKey].indexOf(feature);
        if (featureRef.featureIndex != -1) {
          featureRef.masterRefLayer = true;
          break;
        }
      }
    }
    return featureRef;
  }

  openMapLayersFileInput(e) {
    //Run when file selector opened
    this.importType = e.currentTarget.getAttribute('name');
    this.shadowRoot.querySelector('#mapLayersFileInput').click();
  }

  async mapLayerSorted(e) {
    // make sure the map layers are not sorted when they change
    this.sortMapLayersOnChange = false;
    // make a copy so we don't run the observer until after all of the changes are made
    let mapLayers = [...this.mapLayers];
    // switch the proper items arround
    let removedItem = mapLayers.splice(e.detail.prevIndex, 1)[0];
    mapLayers.splice(e.detail.index, 0, removedItem);
    // update the order attibute for on each layer
    let update = {};
    mapLayers.forEach((layer, index) => {
      update[`photoheight/jobs/${this.job_id}/layers/list/${layer.$key}/order`] = index + 1;
      layer.sorted = index + 1;
    });
    await FirebaseWorker.ref().update(update);
    // force the ui to use the updated map layers
    this.mapLayers = [...mapLayers];
  }

  async addMapLayer() {
    if (this.importType == 'overlay') {
      //Call function in map-overlay.js
      this.$.mapOverlay.newOverlay(URL.createObjectURL(this.shadowRoot.querySelector('#mapLayersFileInput').files[0]));
      //Clear the input
      this.shadowRoot.querySelector('#mapLayersFileInput').files = undefined;
      //close the dialog
      this.$.mapLayersManagerDialog.close();
    } else {
      // make sure the layers that get uploaded are sorted
      this.sortMapLayersOnChange = true;
      // skip the upload if there is no job id
      if (!this.job_id) return;
      // Get the files from the input
      let files = this.shadowRoot.querySelector('#mapLayersFileInput').files || [];
      // loop through all of the files
      let resultsArray = [];
      for (let file of files) {
        try {
          this.$.mapLayersFileUploadSpinner.active = true;
          let result = await this.kmzOrShapeToJson(file, true);
          // If the results geoJSON property is an array (multiple shape
          // files), then map the array to resultsArray so we import each layer
          if (result && Array.isArray(result.geoJSON)) {
            let results =
              result.geoJSON?.map((x) => {
                return {
                  fileName: x.fileName,
                  type: x.type,
                  geoJSON: x
                };
              }) || [];
            // push all of the results to the results array
            results.forEach((item) => resultsArray.push(item));
          }
          // if the result does not have a geoJSON array, default to just pushing the result to the array
          else resultsArray.push(result);
        } catch (e) {
          this.$.mapLayersFileUploadSpinner.active = false;
        }
      }
      // sort the results array
      resultsArray.sort((a, b) => {
        if (a.fileName > b.fileName) return 1;
        else if (a.fileName < b.fileName) return -1;
        else return 0;
      });
      // give an order to each item
      resultsArray.forEach((item, index) => (item.geoJSON.order = index + 1));

      // upload each layer item
      resultsArray.forEach((resultItem) => {
        this.uploadMapLayerData(resultItem.fileName, resultItem.type, resultItem.geoJSON);
      });
    }
  }

  kmzOrShapeToJson(inputFile, includeStyles) {
    return new Promise(async (resolve, reject) => {
      var foundValidFile = false;
      // Check if we have a file
      if (inputFile) {
        // Get the file name
        var fileName = inputFile.name;
        fileName = fileName.substring(0, fileName.lastIndexOf('.')).trim();

        if (inputFile.name.toLowerCase().endsWith('.zip') == true) {
          foundValidFile = true;
          var fileReader = new FileReader();
          fileReader.onload = function () {
            shp(fileReader.result)
              .then((geoJSON) => {
                resolve({ type: 'kmz', geoJSON, fileName });
              })
              .catch((error) => {
                reject(error);
              });
          }.bind(this);
          fileReader.readAsArrayBuffer(inputFile);
        }

        // Check if the file is a KMZ file
        if (inputFile.name.toLowerCase().endsWith('.kmz') == true) {
          await import('../../js/open-source/zipjs/zip.js');
          zip.workerScriptsPath = '../_resources/js/open-source/zipjs/';
          foundValidFile = true;
          // use a BlobReader to read the zip from a Blob object
          zip.createReader(
            new zip.BlobReader(inputFile),
            function (reader) {
              // get all entries from the zip
              reader.getEntries(
                function (entries) {
                  var kmlFile = null;
                  let iconUrls = {};
                  let promises = [];
                  if (entries.length) {
                    // Check for the KML file
                    for (var i = 0; i < entries.length; i++) {
                      promises.push(
                        new Promise((resolve) => {
                          if (entries[i].filename.toLowerCase().endsWith('.kml')) {
                            kmlFile = entries[i];
                            resolve();
                          } else if (entries[i].filename.endsWith('.png') && includeStyles) {
                            let name = entries[i].filename;
                            entries[i].getData(new zip.BlobWriter(), (file) => {
                              let imgRef = firebase.storage().ref(`kmz_imports/${this.job_id}/${fileName}/${name}`);
                              imgRef.put(file).then((snapshot) => {
                                imgRef.getDownloadURL().then((url) => {
                                  iconUrls[name] = url;
                                  resolve();
                                });
                              });
                            });
                          } else resolve();
                        })
                      );
                    }
                  }

                  Promise.all(promises).then(() => {
                    // Check if the KML file was not found
                    if (kmlFile) {
                      // Get the KML contents
                      kmlFile.getData(
                        new zip.TextWriter(),
                        function (kmlString) {
                          // For some reason, parsing the string as "text/xml" drops data, but parsing as "text/html"\
                          var htmlDoc = new DOMParser().parseFromString(kmlString, 'text/html');
                          // Make sure all the palcemark IDs are unique
                          var placemarks = htmlDoc.getElementsByTagName('Placemark');
                          for (var j = 0; j < placemarks.length; j++) {
                            placemarks[j].setAttribute('id', FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/layers').push().key);
                          }
                          var geoJSON = ToGeoJSON.kml(htmlDoc);

                          if (includeStyles) {
                            // Give injectKMLStyles the xml version of the document so it's easier to parse styles
                            geoJSON = this.injectKMLStyles(geoJSON, htmlDoc, iconUrls);
                          }

                          resolve({ type: 'shapefile', geoJSON, fileName });
                          // Close the zip reader
                          reader.close();
                        }.bind(this)
                      );
                    } else {
                      reject();
                    }
                  });
                }.bind(this)
              );
            }.bind(this),
            function (error) {
              console.log('There was an error reading the KMZ file:', error);
              reject(error);
            }.bind(this)
          );
        }
        if (!foundValidFile) {
          this.toast('You must upload a KMZ file or a zip containing Shapefiles.');
          reject();
        }
      }
    });
  }

  injectKMLStyles(geoJSON, htmlDoc, iconUrls) {
    var styles = htmlDoc.getElementsByTagName('style');
    var styleMaps = htmlDoc.getElementsByTagName('stylemap');
    var styleMapLookup = {};
    var styleLookup = {};
    var xmlParser = new DOMParser();

    for (var i = 0; i < styleMaps.length; i++) {
      if (styleMaps[i].id) {
        styleLookup[styleMaps[i].id] = styleMaps[i];
      }
    }

    if (geoJSON.features) {
      for (var i = 0; i < geoJSON.features.length; i++) {
        if (geoJSON.features[i].properties.styleUrl) {
          var selectedStyle = htmlDoc.getElementById(geoJSON.features[i].properties.styleUrl.substring(1));
          let localIcon = true;

          if (geoJSON.features[i].properties.styleMapHash && geoJSON.features[i].properties.styleMapHash.normal) {
            localIcon = false;
            selectedStyle = htmlDoc.getElementById(geoJSON.features[i].properties.styleMapHash.normal.substring(1));
          }

          if (selectedStyle) {
            selectedStyle = xmlParser.parseFromString('<style>' + selectedStyle.innerHTML + '</style>', 'text/xml');

            let icon = selectedStyle.querySelector('IconStyle > Icon > href') || null;
            let iconScale = selectedStyle.querySelector('IconStyle > scale') || null;
            let polyColor = selectedStyle.querySelector('PolyStyle > color') || null;
            let lineColor = selectedStyle.querySelector('LineStyle > color') || null;
            let lineWidth = selectedStyle.querySelector('LineStyle > width') || null;

            if (localIcon && icon && icon.innerHTML) {
              icon = iconUrls[icon.innerHTML];
            }

            geoJSON.features[i].properties['icon'] = !localIcon ? icon && icon.innerHTML : icon;
            geoJSON.features[i].properties['icon-scale'] = iconScale && iconScale.innerHTML;
            geoJSON.features[i].properties['fill'] = polyColor && this.convertKMLColor(polyColor.innerHTML, 'color');
            geoJSON.features[i].properties['fill-opacity'] = polyColor && this.convertKMLColor(polyColor.innerHTML, 'opacity');
            geoJSON.features[i].properties['stroke'] = lineColor && this.convertKMLColor(lineColor.innerHTML, 'color');
            geoJSON.features[i].properties['stroke-opacity'] = lineColor && this.convertKMLColor(lineColor.innerHTML, 'opacity');
            geoJSON.features[i].properties['stroke-width'] = lineWidth && lineWidth.innerHTML;
          } else if (this.enabledFeatures.configurable_kmz_icon_fallback === true) {
            // If we can't find a style for an icon, use the default one if we have any
            let defaultMapLayerIcon = this.modelConfig.map_layers_default_icon;
            if (defaultMapLayerIcon?.color && defaultMapLayerIcon?.icon) {
              // Get the proper geo style rule using the color and icon
              let iconStyle = {
                c: defaultMapLayerIcon.color,
                i: defaultMapLayerIcon?.icon
              };
              // Get the icon url from the style
              let iconData = GeoStyleToIcon(iconStyle);
              let iconURL = iconData.url;
              // Set the geoJson feature icon to be this url
              geoJSON.features[i].properties['icon'] = iconURL;
              // Set the other default icon properties
              geoJSON.features[i].properties['icon-scale'] = '0.625';
              geoJSON.features[i].properties['fill'] = null;
              geoJSON.features[i].properties['fill-opacity'] = null;
              geoJSON.features[i].properties['stroke'] = null;
              geoJSON.features[i].properties['stroke-opacity'] = null;
              geoJSON.features[i].properties['stroke-width'] = null;
            }
            geoJSON.features[i].properties['noIconDataFound'] = true;
          }
        }
      }
    }

    return geoJSON;
  }

  convertKMLColor(colorValue, type) {
    // KML color format: aabbggrr
    if (colorValue) {
      if (type == 'color') {
        // Remove first two characters (alpha value)
        var result = colorValue.substr(2);
        // Reverse the string to get the rgb value
        result = result.split('').reverse().join('');
        return '#' + result;
      } else if (type == 'opacity') {
        // Get the alpha component
        var alphaValue = colorValue.substring(0, 2);
        // Convert alpha hex value to decimal
        var decimalAlpha = parseInt(alphaValue, 16);
        // Convert decimal to percentage out of max value
        decimalAlpha = decimalAlpha / 255;
        return decimalAlpha.toFixed(2);
      }
    }
  }

  loadRenderMapLoadingChanged(e) {
    this.mapLoadingChanged(e.detail.loading, e.detail.loadingName || 'loadRenderMap');
  }

  mapLoadingChanged(loading, itemName) {
    if (itemName) {
      this.loadingMapItems[itemName] = loading;
    }
    var loading = false;
    for (var key in this.loadingMapItems) {
      if (this.loadingMapItems[key]) {
        loading = true;
        break;
      }
    }
    var button = this.$.katapultMap.$.googlemap.shadowRoot.querySelector('#layersButton');
    if (button) {
      button.loading = loading;
    }
  }

  async addApiOrReferenceLayer(e) {
    let selectedLayerItem = e.detail.selectedItem;
    let eventTarget = e.currentTarget;
    // ensure we have a job id and a selected item (layer or layer group)
    if (this.job_id && selectedLayerItem) {
      // get the layer type and grouped layer properties
      const layerType = eventTarget.id;
      const groupedLayers = selectedLayerItem.groupedLayers;
      // if the selected layer item has a layers property, its a layer group so all layers associated with the group should be added
      if (groupedLayers) {
        let layersAdded = 0;
        for (const layer of groupedLayers) {
          await this.executeAddApiOrReferenceLayer(layer, layerType);
          // increment a count and show a loading message
          this.set('apiLayerLoadingMessage', `Adding layers (${++layersAdded}/${groupedLayers.length})...`);
        }
        // set a loading message for the api layer section
        this.set('apiLayerLoadingMessage', null);
      }
      // if the selected layer item doesn't have a layers property, it's a single layer, so add it
      else this.executeAddApiOrReferenceLayer(selectedLayerItem, layerType);
      // clear the drop down menu selection
      eventTarget.value = null;
    }
  }

  async executeAddApiOrReferenceLayer(layer, layerType) {
    // create update payload for add a layer
    let update = {};
    let key = layer.name.replace(/\s/g, '_');
    if (layerType === 'Reference Layer') {
      key = (layer.url || layer.pointPath).replace(/\//g, '--');
    }
    layer.type = layerType;
    delete layer.$key;
    update['/list/' + key] = layer;
    // run the firebase update
    await FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/layers').update(update);
    // set the new key
    layer.$key = key;
  }

  getJobModels(type, editingItemJob, job_id) {
    var jobCreator = SquashNulls(this.multiJobIds, editingItemJob, 'data', 'jobCreator');
    if (editingItemJob == job_id || jobCreator == this.jobCreator) {
      return this[type];
    } else {
      return SquashNulls(this.companyModels, jobCreator, type, 'value') || null;
    }
  }

  zoomToLoadAnalysisAlertItem(e) {
    const alert = e.detail.alert;
    if (alert.node) {
      this.zoomToNode(alert.node.$key);
      this.$.katapultMap.selectNode({ detail: { key: alert.node.$key, jobId: this.job_id } });
    } else if (alert.connection) {
      this.zoomToConnection(alert.connection.connId);
      this.$.katapultMap.selectConnection({ detail: { key: alert.connection.connId, jobId: this.job_id } });
    }
  }

  zoomToJobBounds(e) {
    this.map.fitBounds(e.detail.bounds);
  }

  async getEditingItemJobName(jobId, userGroup) {
    if (jobId && userGroup && userGroup != '_custom_auth') {
      this.editingItemJobName =
        SquashNulls(this.$.jobChooser, 'jobs', jobId, 'name') ||
        (await FirebaseWorker.ref(`photoheight/job_permissions/${userGroup}/jobs/${jobId}/name`)
          .once('value')
          .then((s) => s.val()));
    } else {
      this.editingItemJobName = null;
    }
  }

  convertKMLColor(colorValue, type) {
    // KML color format: aabbggrr
    if (colorValue) {
      if (type == 'color') {
        // Remove first two characters (alpha value)
        var result = colorValue.substr(2);
        // Reverse the string to get the rgb value
        result = result.split('').reverse().join('');
        return '#' + result;
      } else if (type == 'opacity') {
        // Get the alpha component
        var alphaValue = colorValue.substring(0, 2);
        // Convert alpha hex value to decimal
        var decimalAlpha = parseInt(alphaValue, 16);
        // Convert decimal to percentage out of max value
        decimalAlpha = decimalAlpha / 255;
        return decimalAlpha.toFixed(2);
      }
    }
  }

  showAuthContentChanged() {
    // Wait for content to be stamped (by using setTimeout) and then resize the map and deliverable photo.
    setTimeout(() => {
      this.$.katapultMap.$.googlemap.resize();
      this.deliverablePhotoIsHiddenChanged();
    });
  }

  onSignIn() {
    // When we get a sign in notification from Firebase, check if
    // we have an anonymousAuthToken set and if so, tell the worker
    // to also authenticate since the authentication persistence will
    // be set to session and the worker will not have access to the
    // main thread's session
    if (katapultAuth && katapultAuth.anonymousAuthToken) {
      this.mapsWorker.postMessage({
        call: 'anonymousSignIn',
        args: [katapultAuth.anonymousAuthToken]
      });
    }
  }

  onSignOut() {
    this.job_id = null;
  }

  async googleMapReady() {
    if (!this.mapInitialized) {
      this.mapInitialized = true;
      this.googleMapLoaded.resolve();
      //Add GPS Button
      let gpsButton = document.createElement('katapult-button');
      gpsButton.icon = 'my_location';
      gpsButton.style.backgroundColor = 'white';
      gpsButton.style.width = '40px';
      gpsButton.style.height = '40px';
      gpsButton.style.borderRadius = '24px';
      gpsButton.style.padding = '8px';
      gpsButton.style.boxSizing = 'border-box';
      gpsButton.style.marginLeft = '10px';
      gpsButton.style.marginBottom = '10px';
      gpsButton.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
      gpsButton.style.color = '#333333';
      gpsButton.style.display = 'grid';
      gpsButton.addEventListener('click', (e) => {
        if (this.gpsLat && this.gpsLng) {
          this.map.panTo(new google.maps.LatLng(this.gpsLat, this.gpsLng));
        } else this.toast('No GPS location found');
      });
      this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(gpsButton);

      //Add Layers dialog
      let layersButton = document.createElement('katapult-button');
      layersButton.id = 'layersButton';
      layersButton.icon = 'layers';
      layersButton.style.backgroundColor = 'white';
      layersButton.style.width = '40px';
      layersButton.style.height = '40px';
      layersButton.style.borderRadius = '24px';
      layersButton.style.boxSizing = 'border-box';
      layersButton.style.marginLeft = '10px';
      layersButton.style.marginBottom = '10px';
      layersButton.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
      layersButton.style.color = '#333333';
      layersButton.style.display = 'grid';
      this.layersButton = layersButton;
      this.layersButton.closeLayersDialog = (e) => {
        let composedPath = e.composedPath();
        if (!composedPath.includes(this.$.layersDialog) && !composedPath.includes(this.$.savedViewManager)) {
          setTimeout(() => {
            this.$.layersDialog.open = false;
          }, 100);
          this.$.layersDialog.style.display = 'none';
          window.removeEventListener('mousedown', this.layersButton.closeLayersDialog);
        }
      };
      layersButton.addEventListener('click', this.openLayersDialog.bind(this));
      this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(layersButton);

      window.editFeature = this.editFeature.bind(this);
      window.editFeaturePoints = this.editFeaturePoints.bind(this);
      window.editFeatureProperty = this.editFeatureProperty.bind(this);
      window.promptDeleteFeature = this.promptDeleteFeature.bind(this);
      window.multiEditAttribute = this.multiEditAttribute.bind(this);
      window.multiDelete = this.multiDelete.bind(this);
      window.tallyByPolygon = this.tallyByPolygon.bind(this);
      window.copyPolygonNodesToJob = this.copyPolygonNodesToJob.bind(this);
      window.downloadByPolygon = this.downloadByPolygon.bind(this);
      window.shareByPolygon = this.shareByPolygon.bind(this);
      window.runMapErrorsReport = this.runMapErrorsReport.bind(this);
      window.transferByPolygon = this.transferByPolygon.bind(this);
      window.promptCopyFeature = this.promptCopyFeature.bind(this);
      // Shorthand property for geocoder
      this.googleGeocoder = new google.maps.Geocoder();
      // Init listeners for geoJson click events
      if (!this.geoJsonInfo) this.geoJsonInfo = new google.maps.InfoWindow({ disableAutoPan: true });
      this.map.data.addListener(
        'click',
        function (e) {
          this.geoJsonInfo.close();
          if (this.activeCommand == '_selectPolygon' && e.feature.getGeometry().getType() == 'Polygon') {
            this.polygonSelected(e.feature);
          } else if (e && e.feature && e.feature.getProperty('label')) {
            let labelSpan = '<span style="white-space:nowrap; font-size:14px;">' + e.feature.getProperty('label') + '</span>';
            if (
              this.activeCommand == '_linkMapPhotoData' &&
              (e.feature.getProperty('type') == 'primary_overhead' || e.feature.getProperty('type') == 'secondary_overhead')
            ) {
              let label =
                labelSpan +
                `<katapult-button style="margin-left: 20px;" color="#74b" onclick="mapsDesktop_linkCableSpec()">Use This Spec</katapult-button>`;
              let power_spec = e.feature.getProperty('cond_size') == '999' ? 'Unknown' : e.feature.getProperty('cond_size');
              power_spec += ' ' + (e.feature.getProperty('cond_type') == '9999' ? 'Unknown' : e.feature.getProperty('cond_type'));
              if (power_spec == 'Unknown Unknown') power_spec = 'Unknown';
              this.selectedContextCable = { power_spec, type: 'secondary', feeder: e.feature.getProperty('feederid') };
              if (e.feature.getProperty('type') == 'primary_overhead') {
                let neut =
                  (e.feature.getProperty('neut_size') == '999' ? 'Unknown' : e.feature.getProperty('neut_size')) +
                  ' ' +
                  (e.feature.getProperty('neut_type') == '9' ? 'Unknown' : e.feature.getProperty('neut_type'));
                if (neut == 'Unknown Unknown') neut = 'Unknown';
                this.selectedContextCable.type = 'primary';
                this.selectedContextCable.primary_count = e.feature.getProperty('cond_qty');
                this.selectedContextCable.neutral_spec = neut;
              }
              this.geoJsonInfo.setContent(label);
            } else this.geoJsonInfo.setContent(labelSpan);
            if (e.latLng) this.geoJsonInfo.setPosition(e.latLng);
            this.geoJsonInfo.open(this.map);
          } else if (e && e.feature) {
            this.selectedPolygonData = e.feature;
            // Get index reference to feature.
            let featureRef = this.getFeatureRef(e.feature);
            let featureRefString = JSON.stringify(featureRef).replace(/"/g, "'");
            var content = '';
            content += '<table>';
            var privateProperties = [
              'editable',
              'styleHash',
              'styleUrl',
              'stroke',
              'stroke-weight',
              'stroke-width',
              'stroke-opacity',
              'label',
              'fill',
              'fill-opacity'
            ];
            e.feature.forEachProperty((item, property) => {
              // Decode properties
              property = FirebaseEncode.decode(property);
              if (privateProperties.indexOf(property) == -1 && property[0] !== '_') {
                content += '<tr><td><b>' + CamelCase(property) + ':</b></td><td>' + item + '</td>';
                if (property == 'job_name' && this.canWrite)
                  content += `<td><katapult-button iconOnly noBorder icon="create" onclick="editFeatureProperty(${featureRefString}, 'job_name')"></katapult-button></td>`;
                content == '</tr>';
              }
            });
            content += '</table>';
            // Add buttons bar.
            if (this.canWrite && !e.feature.getProperty('_hideButtons')) {
              content += '<span style="font-weight: bold; margin-left: 3.5px">Feature Edit Tools:</span>';
              if (!featureRef.masterRefLayer) {
                const jobDataToolsLabelStyle = e.feature.getProperty('description') ? '100px' : '60px';
                content += `<span style="font-weight: bold; margin-left: ${jobDataToolsLabelStyle}">Job Data Tools:</span>`;
              }
              content += '<div style="position: relative; display: flex;" padding: 4px; justify-content: center; align-items: center;>';
              if (e.feature.getProperty('description'))
                content += `<katapult-button iconOnly noBorder icon="edit_square" onclick="editFeatureProperty(${featureRefString}, 'description')" title="Edit Feature Property"></katapult-button>`;
              content += `<katapult-button iconOnly noBorder icon="palette" onclick="editFeature(${featureRefString})" title="Edit Feature"></katapult-button>`;
              if (!featureRef.masterRefLayer) {
                content += `<katapult-button iconOnly noBorder icon="share" onclick="editFeaturePoints(${featureRefString})" title="Edit Feature Points"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="content_copy" onclick="promptCopyFeature(${featureRefString})" title="Copy Feature"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="delete" onclick="promptDeleteFeature(${featureRefString})" title="Delete Feature"></katapult-button>`;
                content += '<span style="font-size: 33px; color: black">|</span>';
                content += `<katapult-button iconOnly noBorder icon="create" onclick="multiEditAttribute(${featureRefString})" title="Multi Edit Attribute"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="delete_sweep" onclick="multiDelete(${featureRefString})" title="Multi Delete"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="center_focus_strong" onclick="tallyByPolygon(${featureRefString})" title="Tally by Polygon"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="forward" onclick="copyPolygonNodesToJob(${featureRefString})" title="Copy Polygon Nodes to Job"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="file_download" onclick="downloadByPolygon(${featureRefString})" title="Download by Polygon"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="map" onclick="shareByPolygon(${featureRefString})" title="Share by Polygon"></katapult-button>`;
                if (this.userGroup == 'charter' || this.userGroup == 'rainbow_design_services')
                  content += `<katapult-button iconOnly noBorder icon="healing" onclick="runMapErrorsReport(${featureRefString})" title="Run Map Errors Report"></katapult-button>`;
                content += `<katapult-button iconOnly noBorder icon="send" onclick="transferByPolygon(${featureRefString})" title="Transfer by Polygon"></katapult-button>`;
              }
              content += '</div>';
            }
            // Get index reference to feature.
            featureRef = { layerKey: null, featureIndex: -1 };
            for (let geoJsonLayer of this.geoJsonLayers) {
              featureRef.layerKey = geoJsonLayer.key;
              featureRef.featureIndex = geoJsonLayer.features.indexOf(e.feature);
              if (featureRef.featureIndex != -1) break;
            }
            if (content) {
              content = `<div style="color: rgba(33, 33, 33, 0.8);">${content}</div>`;
              // Add edit button to dialog.
              if (featureRef.featureIndex != -1 && this.canWrite) content += ``;
              this.geoJsonInfo.setContent(content);
              if (e.latLng) this.geoJsonInfo.setPosition(e.latLng);
              this.geoJsonInfo.open(this.map);
            } else {
              this.editFeature(featureRef);
            }
            if (e.feature.getProperty('_crumbTrailSegment')) {
              if (!this.crumbTrailOverlay) this.crumbTrailOverlay = new google.maps.Polyline();
              this.crumbTrailOverlay.setPath(e.feature.getGeometry().getArray());
              let stroke = e.feature.getProperty('stroke');
              this.crumbTrailOverlay.set('strokeColor', stroke);
              let icons = [
                { icon: { path: google.maps.SymbolPath.CIRCLE, scale: 4, strokeColor: stroke, fillColor: stroke }, offset: '0%' },
                { icon: { path: google.maps.SymbolPath.FORWARD_OPEN_ARROW, scale: 4, strokeColor: stroke }, offset: '50%' },
                { icon: { path: google.maps.SymbolPath.CIRCLE, scale: 4, strokeColor: stroke, fillColor: stroke }, offset: '100%' }
              ];
              this.crumbTrailOverlay.set('icons', icons);
              this.crumbTrailOverlay.setMap(this.map);
            }
          }
        }.bind(this)
      );
      let defaultKMZIconSize = 30;
      // setStyle function for geoJson layers
      this.map.data.setStyle(
        function (feature) {
          let clickable = !feature.getProperty('_unclickable');
          let prop = (key) => feature && feature.getProperty(key),
            label = '',
            t;
          if (prop('summary') && prop('summary').toLowerCase().indexOf('pri') == 0) {
            feature.setProperty('type', 'primary_overhead');
          } else if (prop('summary') && prop('summary').toLowerCase().indexOf('sec') == 0) {
            feature.setProperty('type', 'secondary_overhead');
          } else if (prop('id_no')) {
            feature.setProperty('type', 'poles');
          } else if (prop('juris')) {
            feature.setProperty('type', 'state_roads');
          }
          if ((t = prop('id_no'))) {
            let poleOwner = prop('pole_owner_name') || 'Unknown';
            if (prop('estimated_geometry')) poleOwner = '(Location not in PPL Database) Tagged:';
            label = '<a target="_blank" href="https://katapultwebservices.com/ppl/poles?' + t + '">' + poleOwner + ' ' + t + '</a>';
          }
          if ((t = prop('st_rt_no'))) label += 'State Route Number: ' + t;
          if ((t = prop('seg_no'))) label += '<br/>Segment Number: ' + t;
          if ((t = prop('street_nam'))) label += '<br/>Street Name: ' + t;
          if ((t = prop('summary'))) label += t;
          if ((t = prop('oper_volt'))) label += ' ' + t + (feature.getProperty('type') == 'primary_overhead' ? 'kV' : 'V');
          if ((t = prop('juris'))) {
            t = this.stateRoadSyle[t];
            label += '<br/>Jurisdiction: ' + t.name;
            feature.setProperty('label', label);
            feature.setProperty('type', 'state_road');
            return {
              clickable,
              strokeColor: t.color,
              strokeOpacity: 1,
              strokeWeight: 8
            };
          }
          feature.setProperty('label', label);
          if (prop('type') == 'primary_overhead') return { clickable, strokeColor: '#03f', strokeOpacity: 0.5, strokeWeight: 8 };
          else if (prop('type') == 'secondary_overhead') return { clickable, strokeColor: '#f00', strokeOpacity: 0.5, strokeWeight: 8 };
          else if (prop('type') == 'poles') {
            let activityType = prop('activity_types');
            let attachType = prop('attachment_types');
            var colors = {
              'Bolted Cable': {
                background: '#FBDA71',
                color: '#003768'
              },
              'Guy (In Com Space)': {
                background: '#B9982E',
                color: '#FFFFFF'
              },
              'Guy (Below Com Space)': {
                background: '#A17A00',
                color: '#FFFFFF'
              },
              'Guy (Stub Pole)': {
                background: '#A17A00',
                color: '#FFFFFF'
              },
              'Telephone Cable': {
                background: '#FBDA71',
                color: '#003768'
              },
              'Service Drop': {
                background: '#3486D0',
                color: '#FFFFFF'
              },
              fallback: {
                background: '#5D9AD0',
                color: '#FFFFFF'
              }
            };
            let color = {
              background: 'white',
              color: 'black'
            };
            if (activityType != 'buffer_pole') {
              color = colors[attachType] || colors.fallback;
            }
            let label = prop('app_pole_order') || '';
            if (activityType == 'takeoff_pole') {
              color = colors['Bolted Cable'];
              label = 'T';
            }
            let svgSize = 18;
            let svgContent =
              '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style="fill:' +
              color.background +
              ';"><circle r="7.5" cy="9" cx="9" stroke="#000" stroke-width="1"/>';
            let transmissionHalo = prop('ppl_type') == 'transmission';
            let existingAttach = prop('ex_by_applicant');
            if (prop('pole_owner') == 'Foreign') {
              let cutX = 12;
              let semiCircleColor = 'white';
              if (activityType == 'buffer_pole') {
                cutX = 0;
                semiCircleColor = 'black';
              }
              svgContent +=
                '<defs><clipPath id="cut-off-half"><rect x="' +
                cutX +
                '" y="0" width="9" height="18" /></clipPath></defs><circle cx="9" cy="9" r="7.5" fill="' +
                semiCircleColor +
                '" clip-path="url(#cut-off-half)" stroke="#000" stroke-width="1" />';
            }
            if (label) {
              svgContent +=
                '<text x="9" y="12" style="fill: ' +
                color.color +
                ';" font-family="Verdana" font-size="9" font-weight="bold" text-anchor="middle">' +
                label +
                '</text>';
            }
            if (activityType == 'Remove') {
              svgContent +=
                '<text x="18" y="18" style="fill: #8d2424;" font-family="Verdana" font-size="9" font-weight="bold" stroke-width="1" stroke="#8d2424" text-anchor="end">X</text>';
            }
            svgContent += '</svg>';
            let url = 'data:image/svg+xml,';
            if (existingAttach || transmissionHalo || activityType == 'Overlash') {
              svgSize = 54;
              url += '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54">';
              if (existingAttach) {
                let haloColor = '#1E4D78';
                for (let i = 0; i < existingAttach.length; i++) {
                  if (existingAttach[i].toLowerCase().indexOf('cable') != -1) {
                    haloColor = '#5D9AD0';
                    break;
                  }
                }
                url +=
                  '<defs><filter id="blur"><feGaussianBlur in="SourceGraphic" stdDeviation="3" /></filter></defs><circle r="13.5" cx="27" cy="27" fill="' +
                  haloColor +
                  '" filter="url(#blur)" />';
              }
              if (transmissionHalo) {
                url += '<circle r="18" cx="27" cy="27" fill="#003768" /><circle r="13.5" cx="27" cy="27" fill="white" />';
              }
              if (activityType == 'Overlash') {
                url +=
                  '<rect x="13.5" y="13.5" width="27" height="27" fill="#003768"/><rect x="15.75" y="15.75" width="22.5" height="22.5" fill="white"/>';
              }
              url += '<image x="18" y="18" height="18" width="18" href="data:image/svg+xml;base64,' + btoa(svgContent) + '" /></svg>';
            } else {
              url += svgContent;
            }
            url = url.replace(/\#/g, '%23');
            let anchor = { x: svgSize / 2, y: svgSize / 2 };
            return {
              clickable,
              icon: { url, anchor },
              title: prop('app_pole_order') || undefined
            };
          } else {
            // For all other types, set the default properties
            var featureObject = {
              clickable,
              strokeColor: prop('stroke'),
              strokeOpacity: prop('stroke-opacity'),
              strokeWeight: prop('stroke-width'),
              fillColor: prop('fill'),
              fillOpacity: prop('fill-opacity'),
              editable: prop('editable')
            };
            // Check if there is an icon property
            if (prop('icon')) {
              let iconScale = prop('icon-scale') || 1;
              featureObject['icon'] = {
                clickable,
                url: prop('icon'),
                scaledSize: { width: defaultKMZIconSize * iconScale, height: defaultKMZIconSize * iconScale },
                anchor: { x: (iconScale * defaultKMZIconSize) / 2, y: (iconScale * defaultKMZIconSize) / 2 }
              };
            }
            for (let key in featureObject) {
              if (key.toLowerCase().indexOf('color') != -1 && typeof featureObject[key] === 'object' && featureObject[key] != null) {
                featureObject[key] = `rgba(${featureObject[key].r || 0}, ${featureObject[key].g || 0}, ${featureObject[key].b || 0}, ${
                  featureObject[key].a || 1
                })`;
              }
            }
            return featureObject;
          }
        }.bind(this)
      );

      // setup observer, retrieve and apply geoJsonLayers from session storage
      this._createMethodObserver(`saveSettings('geoJsonLayers', geoJsonLayers, geoJsonLayers.*)`);
      let geoJsonLayers = JSON.parse(sessionStorage.getItem('katapultMaps:geoJsonLayers'));
      if (geoJsonLayers) await this.applySetting('geoJsonLayers', geoJsonLayers);

      setTimeout(() => {
        this.getGoogleCopyrightText();
      }, 4000);
      setTimeout(() => {
        this.mapLoadingChanged();
      });
    }
  }

  openLayersDialog() {
    if (!this.$.layersDialog.open) {
      this.$.layersDialog.open = true;
      let buttonPosition = this.layersButton.getBoundingClientRect();
      let bottom = window.innerHeight - buttonPosition.top + 5;
      this.$.layersDialog.style.maxHeight = window.innerHeight - bottom - 61 + 'px';
      this.$.layersDialog.style.bottom = bottom + 'px';
      this.$.layersDialog.style.left = buttonPosition.left + 'px';
      this.$.layersDialog.style.display = 'block';
      window.addEventListener('mousedown', this.layersButton.closeLayersDialog);
    }
  }

  useMetricUnitsChanged() {
    if (this.useMetricUnits != null) {
      this.useMetricUnitsChecked = this.useMetricUnits;
    }
  }

  useMetricUnitsCheckedChanged() {
    if (this.userGroup != null && this.user != null && this.user.uid != null && this.useMetricUnitsChecked != null) {
      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/use_metric_units').set(
        this.useMetricUnitsChecked
      );
    }
  }

  useDecimalFeetChanged() {
    if (this.useDecimalFeet != null) {
      this.useDecimalFeetChecked = this.useDecimalFeetChanged;
    }
  }

  useDecimalFeetChanged() {
    if (this.userGroup != null && this.user != null && this.user.uid != null && this.useDecimalFeetChecked != null) {
      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/use_decimal_feet').set(
        this.useDecimalFeetChecked
      );
    }
  }

  async openProjectFolderChooser() {
    await import('../job-chooser/project-folder-chooser.js');
    await this.$.projectFolderChooser.chooseJobLocation(this.job_id, this.projectFolder, this.$.jobChooser, true);
  }

  async openFolderChooser(e) {
    let target = e.target;
    if (!this.$.projectFolderChooser.handleOpenEvent) {
      await import('../job-chooser/project-folder-chooser.js');
      this.$.projectFolderChooser.handleOpenEvent({ target });
      e.stopPropagation();
    }
  }

  jobChooserLoad() {
    this.jobChooserJobsUpdated = !this.jobChooserJobsUpdated;
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  makeReadyDetailsItemChanged(e) {
    let indices = this.$.makeReadyDetails.indices;
    let editingType = this.$.makeReadyDetails.editingType;
    let nodeContext = this.$.makeReadyDetails.queue[indices.node];
    this.editingNode = this.zoomNode = nodeContext.nodeKey;
    this.editing = 'Node';

    // Show deliverable photo.
    this.selectDeliverablePhoto('node', this.editingNode);
    this.showPhoto = true;

    if (editingType == 'node') {
      this.selectedNode = nodeContext.nodeKey;
    } else if (editingType == 'anchor') {
      this.selectedNode = nodeContext.anchors[indices.anchor].anchorKey;
    } else if (editingType == 'downGuy') {
      this.selectedNode = nodeContext.nodeKey;
      let downGuyContext = SquashNulls(nodeContext, 'anchors', indices.anchor, 'downGuys', indices.downGuy, 'markerContext');
      let property = SquashNulls(downGuyContext, 'property');
      let itemKey = SquashNulls(downGuyContext, 'itemKey');
      setTimeout(() =>
        this.shadowRoot.querySelector('#deliverablePhotoViewer').selectAnnotation(property, itemKey, property, itemKey, null, true)
      );
    }
  }

  createNewJob() {
    this.$.createJobForm.open((success) => {
      this.$.welcomeJobChooser.selected = success.jobId;
      this.$.zoomToLocationDialog.open();
    });
  }

  dismissNewJob() {
    this.$.welcomeDialog.close();
  }

  chooseNewJobLocation(e) {
    this.$.createJobForm.$.projectFolderChooser.chooseLocation(e.detail.newJobPath, this.$.jobChooser, (path) => {
      this.$.createJobForm.newJobPath = path;
    });
  }

  cancelZoomToLocation() {
    this.mapBase = 'hybrid';
    this.set('additionalMapOptions.styles', null);
  }

  deleteItem(e) {
    this.$.katapultMap.deleteItem(e);
  }

  delKeyPressed(e) {
    let focusedItem = e.detail.keyboardEvent.composedPath()[0];
    if (
      !['INPUT', 'TEXTAREA', 'KATAPULT-PHOTO-VIEWER'].includes(focusedItem.tagName) &&
      (this.status == null || !this.status.published || this._sharing == 'write')
    )
      setTimeout(() => {
        this.$.katapultMap.deleteItem('delete node');
      });
  }

  searchForLocation() {
    var location = this.$.zoomToLocationDialogInput.value;
    this.googleGeocoder.geocode(
      { address: location },
      function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          this.$.zoomToLocationDialogInput.value = results[0].formatted_address;
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
          this.zoomText = results[0].formatted_address;
          this.$.katapultMap.zoomToLocation({
            latitude: this.latitude,
            longitude: this.longitude,
            zoom: 15,
            showMarker: true,
            markerContent: this.zoomText
          });
          this.$.katapultMap?.setMapBase?.('hybrid');
          setTimeout(() => this.$.zoomToLocationDialog.close(), 1000);
        } else {
          this.$.zoomToLocationDialogInput.errorMessage = status;
          this.$.zoomToLocationDialogInput.invalid = true;
        }
      }.bind(this)
    );
  }

  zoomToLocationSearch() {
    var location = this.zoomText;
    if (location.substring(0, 4) == 'APP_' || location.substring(0, 4) == 'REL_' || this.tag_cleaner(location).valid) {
      if (this.isUtilityReviewContractor && location != null) {
        this.searchContextLayers(location, { noZoom: true });
      }
    } else {
      this.googleGeocoder.geocode(
        { address: location },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            this.latitude = results[0].geometry.location.lat();
            this.longitude = results[0].geometry.location.lng();
            this.zoomText = results[0].formatted_address;
            this.$.katapultMap.zoomToLocation({
              latitude: this.latitude,
              longitude: this.longitude,
              zoom: 15,
              showMarker: true,
              markerContent: this.zoomText
            });
          }
        }.bind(this)
      );
    }
  }

  searchForLocationKeypress(e) {
    this.$.zoomToLocationDialogInput.invalid = false;
    if (e.keyCode == 13) this.searchForLocation();
  }

  userChanged() {
    // See if we have auth from the front end
    if (this.user) {
      this.userEmail = this.user.email;
      this.user.getIdTokenResult().then((result) => {
        const claims = result.claims;
        if (claims.jobid != null) {
          this.isJobAuth = true;
          this.userEmail = claims.email;
          this.tier = 'custom';

          if (!this.$.toolbar.$.loginChip) return;

          this.$.toolbar.$.loginChip.showMyAccount = false;
          this.$.toolbar.$.loginChip.$.chip.userEmail = claims.email;
          this.$.toolbar.$.loginChip.$.largeChip.userEmail = claims.email;
        } else {
          this.isJobAuth = false;

          if (!this.$.toolbar.$.loginChip) return;

          this.$.toolbar.$.loginChip.showMyAccount = true;
        }
      });
    } else {
      this.userEmail = null;
    }
  }

  userGroupChanged(value, oldValue) {
    if (this.contextLayersSearch != null) {
      this.zoomText = this.contextLayersSearch;
      this.searchContextLayers(this.contextLayersSearch, { noZoom: true });
    }
    // If the userGroup is set, then set up a listener for the master location directories
    this.updateDirectoryListListener();
  }

  updateDirectoryListListener() {
    // Always unsub the old listener before we create a new one
    if (typeof this.unsubscribeDirectoryListener == 'function') this.unsubscribeDirectoryListener();

    // Don't listen for directories without valid auth
    if (this.userGroup == null || this.userGroup == '_custom_auth') return;

    // Listen for changes to the master location directories list
    this.unsubscribeDirectoryListener = MLD.onDirectoryListSnapshot(
      this.userGroup,
      (directories) => {
        const directoriesList = Object.values(directories).sort(MLD.SORT_DATE_CREATED_ASC);

        // Remove directories that are no longer in the list
        const removedDirectories = this.masterLocationDirectories?.filter(
          (existingDirectory) => !directoriesList.find(({ _id }) => _id === existingDirectory._id)
        );
        removedDirectories?.forEach((directory) => this.toggleMasterLocationDirectory(false, directory._id));

        // Set the master location directories list
        this.masterLocationDirectories = directoriesList;
      },
      { includeSharedDirectories: true }
    );
  }

  showJobEditor() {
    this.editing = 'Job';
    this.editingItemJob = this.job_id;
  }

  calcDeliverablePhotoIsHidden() {
    let hidden = !this.selectedDeliverablePhoto || this.hideDeliverablePhoto;
    if (hidden) {
      let deliverablePhoto = this.shadowRoot?.querySelector('#deliverablePhotoViewer');
      if (deliverablePhoto) {
        deliverablePhoto.overflowAnnotations = false;
        deliverablePhoto.style.zIndex = 'unset';
      }
    }
    return hidden;
  }

  calcLinkHidden(comp, showDel) {
    return showDel ? true : comp != 'katapult';
  }

  camelCase(x) {
    return CamelCase(x);
  }

  showMasterLocationDirectoryManager() {
    if (this.job_id) {
      this.$.masterLocationDirectoryManager.open();
    }
  }

  async promptToUpdateMasterLocationDirectory() {
    // Do nothing if the job_id is not set or the MLD manager is not loaded
    if (!this.job_id || !this.$.masterLocationDirectoryManager || !this.masterLocationDirectories?.length) return;

    // Make sure the MLD manager has directories
    if (this.$.masterLocationDirectoryManager.directories?.length == 0) {
      this.$.masterLocationDirectoryManager.directories = this.masterLocationDirectories;
    }

    // Get the directories that the job is checked into
    const jobRef = globalThis.FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`);
    const allCheckedInDirectories = (await jobRef.child(`metadata/master_location_directory`).once('value')).val();
    const checkedInDirectories = Object.keys(allCheckedInDirectories ?? {}).filter((id) =>
      this.masterLocationDirectories?.find((d) => d._id === id)
    );
    // If the job has not been checked into any directories, then prompt to check it in
    if (!checkedInDirectories.length) {
      const { primaryColor, primaryColorLightTextColor } = this.config.firebaseData.palette;
      this.confirm(
        'Check this job into a Master Location Directory?',
        stripIndent`
          This will add all poles in this job to the selected Master Location Directories. You can remove this job from a Master Location
          Directory at any time from the job settings. To view poles checked into a Master Location Directory, turn the layer on from the
          Location Directories layers list.`,
        'Check In',
        'Skip',
        `background-color: ${primaryColor}; color: ${primaryColorLightTextColor};`,
        'checkIntoMasterLocationDirectories',
        async (e) => {
          // Prevent the dialog from closing
          e.stopPropagation();
          const spinner = this.shadowRoot.querySelector('#shareJobCheckInJobsToMasterLocationDirectoriesSpinner');
          spinner.active = true;
          // Get the directory checkboxes
          const checkboxes = this.shadowRoot.querySelectorAll('.includeMasterLocationDirectoryCheckbox');

          // Loop through the checkboxes and check job into each directory checked
          for (let i = 0; i < checkboxes.length; i++) {
            const { name: _id, checked } = checkboxes[i];
            if (!_id || !checked) continue;
            await this.$.masterLocationDirectoryManager.checkInJob({ detail: { jobId: this.job_id }, model: { directory: { _id } } });
          }

          // Stop the spinner
          spinner.active = false;
          this.$.confirmDialog.close();
          // Uncheck boxes
          checkboxes.forEach((checkbox) => (checkbox.checked = false));
        }
      );
    } else {
      // If the job has been checked into directories already, then check it into those directories again
      for (const directoryId of checkedInDirectories) {
        this.$.masterLocationDirectoryManager.checkInJob({
          detail: { jobId: this.job_id },
          model: { directory: { _id: directoryId } }
        });
      }
    }
  }

  deliverablePhotoIsHiddenChanged() {
    setTimeout(() => {
      this.$.deliverablePhoto.style.marginLeft = (this.deliverablePhotoIsHidden ? -this.$.deliverablePhoto.offsetWidth : 0) + 'px';
      // If we are showing auth content, go ahead and start animating the deliverable photo.
      if (katapultAuth.showAuthContent)
        setTimeout(() => {
          this.$.deliverablePhoto.setAttribute('animate', true);
        });
    });
    setTimeout(() => this.deliverablePhotoResized(), 350);
  }

  setSelectedInputModelGroup(inputModelGroups) {
    if (inputModelGroups) {
      // If there already is a selected input model group, then return that
      if (!this.selectedInputModelGroup || !inputModelGroups[this.selectedInputModelGroup]) {
        // Return "Measure" if it's available
        if (SquashNulls(inputModelGroups, 'Measure') != '') {
          this.selectedInputModelGroup = 'Measure';
          return;
        }
        // Otherwise, find the first one by priority
        var first;
        for (var groupName in inputModelGroups) {
          if (
            first == null ||
            (inputModelGroups[groupName]._priority != null &&
              (first._priority == null || inputModelGroups[groupName]._priority > first._priority))
          ) {
            first = {
              groupName,
              _priority: inputModelGroups[groupName]._priorty
            };
          }
        }
        if (first != null) {
          this.selectedInputModelGroup = first.groupName;
        }
      }
    }
  }

  // TODO (03-23-2023): We define this function in two places. We should consolidate it.
  getSingleClickList(inputModelGroups, inputGroup, routines, inputModels) {
    // Wait until all the data is loaded
    if (!inputModelGroups || !inputGroup || !routines || !inputModels) return [];

    const list = [];
    const inputModelGroup = inputModelGroups?.[inputGroup];
    for (const [key, inputOpts] of Object.entries(inputModelGroup ?? {})) {
      const itemModel = routines?.[key] ?? inputModels?.[key];
      // Skip if the key starts with an underscore or if there is no item model
      if (key[0] == '_' || !itemModel) continue;

      const label = itemModel.name ?? CamelCase(FirebaseEncode.decode(itemModel.label ?? key));
      const shortcut = inputOpts._shortcut != null ? ` (${inputOpts._shortcut})` : '';
      list.push({ value: key, label: `${label}${shortcut}`, description: itemModel.description, icons: itemModel.icons });
    }

    // Sort the list by label
    const sortedList = list.sort((a, b) => a.label.localeCompare(b.label));
    return sortedList;
  }

  openWireSpecDialog() {
    this.$.setWireSpecDialog.open();
  }

  resizeWireSpecDialog(data) {
    this.setPowerSpecData = data;
    this.$.setWireSpecDialog.notifyResize();
  }

  updateQcPPL(qcPPLReport) {
    if (this.$.pplQcDialog && qcPPLReport) {
      this.qcPPLReport = qcPPLReport;
      this.$.pplQcDialog.notifyResize();
    }
  }

  async setJobStatus(jobId, status) {
    if (!jobId) throw 'Missing job id';
    if (!['archived', 'active'].includes(status)) throw `Expected 'active' or 'archived' status`;
    await SetJobArchiveStatus(jobId, this.userGroup, status, {
      projectFolder: this.projectFolder,
      jobChooserOptions: this.get('companyOptions.job_chooser')
    });
  }

  async duplicateJob(jobId, newJobName) {
    // Duplicate the job.
    const { jobId: newJobId } = (await this.duplicateJobData(null, { fromJobId: jobId, duplicateJobName: newJobName })) ?? {};
    // Either open new job in this tab.
    if (this.modelConfig?.prevent_spawn_new_tab_duplicate_job) this.job_id = newJobId;
    // Or spawn a new tab.
    else
      OpenPage('map', {
        target: '_blank',
        hash: newJobId
      });
  }

  async duplicateJobData(e, d) {
    const fromJobId = d.fromJobId;
    if (!fromJobId) throw 'Expected a source job id';
    const callback = d.callback;
    const newName = d.duplicateJobName;
    if (!newName || newName == '') throw 'Expected new job name';
    this.toast('Duplicating job...', null, 8000);
    // Check if the new name matches any existing jobs for the company
    const alreadyExists = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/list`)
      .orderByChild('name')
      .equalTo(newName)
      .once('value')
      .then((s) => s.exists());
    if (alreadyExists) {
      this.toast(newName + ' already exists. Either rename the duplicate, or delete the duplicate to copy this job.', null, 8000);
      return;
    }
    const key = FirebaseWorker.ref('photoheight/jobs/').push().key;
    const job = await FirebaseWorker.ref('photoheight/jobs/' + fromJobId)
      .once('value')
      .then((s) => s.val());
    const update = {};
    const perm = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/jobs/${fromJobId}`)
      .once('value')
      .then((s) => s.val());
    const counters = await FirebaseWorker.ref(`photoheight/counters/${this.userGroup}/${this.job_id}`)
      .once('value')
      .then((s) => s.val());
    perm.name = job.name = newName;
    job.sharing = {};
    job.metadata = job.metadata || {};
    job.metadata['duplicated_job'] = true;
    job.metadata['tracking_model'] = null;
    perm.metadata = perm.metadata || {};
    perm.metadata['duplicated_job'] = true;
    perm.metadata['tracking_model'] = null;
    job.sharing[this.userGroup] = 'write';
    job.tracking_disabled = this.duplicateJobTrackingDisabled;
    for (const view in job.saved_views) {
      if (Path.isObject(job.saved_views[view]?.settings?.multiJobIds)) {
        job.saved_views[view].settings.multiJobIds = { [key]: { url: `photoheight/jobs/${key}/geohash` } };
      }
    }
    delete job.actions;
    if (job.metadata || perm.metadata) {
      const restrictedMetadata = await FirebaseWorker.ref(`photoheight/validation/job_metadata`)
        .once('value')
        .then((s) => s.val());
      for (let property in restrictedMetadata) {
        if (job.metadata) delete job.metadata[property];
        if (perm.metadata) delete perm.metadata[property];
      }
    }
    if (job.photo_folders) {
      for (const folder in job.photo_folders) {
        job.photo_folders[folder].job_id = key;
      }
    }
    update[`jobs/${key}`] = job;
    await UpdateJobPermissions(key, this.userGroup, perm, {
      jobChooserOptions: this.get('companyOptions.job_chooser'),
      updatePath: 'photoheight',
      update
    });
    update[`counters/${this.userGroup}/${key}`] = counters;
    if (job.project_folder) {
      let parts = job.project_folder.split('/');
      let projectFolderPath = 'company_space/' + this.userGroup + '/project_folders/';
      for (let i = 0; i < parts.length; i++) {
        // if there is another piece left, it means there is another folder.
        if (parts[i + 1]) projectFolderPath += FirebaseEncode.encode(parts[i]) + '/folders/';
        else projectFolderPath += FirebaseEncode.encode(parts[i]) + '/jobs/' + key;
      }
      update[projectFolderPath] = true;
    }
    if (key) {
      // determine what job files we need to copy to the new job
      let filesToCopy = [];
      const getFilesToCopy = async (storageRef) => {
        const files = await storageRef.listAll();
        // if there are any folders(prefixes), then get the files in the folder
        for (let i = 0; i < files.prefixes.length; i++) {
          await getFilesToCopy(files.prefixes[i]);
        }
        // if there are files(items), then add them to the list of files to copy
        for (let i = 0; i < files.items.length; i++) {
          filesToCopy.push([files.items[i].fullPath, files.items[i].fullPath.replace(fromJobId, key)]);
        }
      };
      const jobFilesRef = firebase.storage().ref(`job_files/${fromJobId}`);
      await getFilesToCopy(jobFilesRef);

      // copy all job files to the new job
      for (let i = 0; i < filesToCopy.length; i++) {
        const [fromPath, toPath] = filesToCopy[i];
        const fromRef = firebase.storage().ref(fromPath);
        const toRef = firebase.storage().ref(toPath);

        // get the file and put it in the new location
        await fromRef.getDownloadURL().then(async (url) => {
          await fetch(url).then(async (response) => {
            await response.blob().then(async (blob) => {
              await toRef.put(blob, { contentType: response.headers.get('content-type') });
            });
          });
        });
      }

      let e = await LargeUpdate(FirebaseWorker.ref('photoheight', { noTracking: true }), update);
      if (e) this.toast('Failed to duplicate job', null, 6000);
      else {
        callback?.(job, key);
        return { job, jobId: key };
      }
    } else this.toast('Failed to generate Firebase key.');
  }

  checkJobName(jobName, jobCreator) {
    if (jobName != null && jobName != '' && jobCreator && jobCreator.indexOf('katapult') != -1 && jobName.substring(0, 4) == 'APP_') {
      this.zoomText = jobName;
      this.zoomToLocationSearch();
    }
  }

  jobCreatorChanged(jobCreator) {
    window.loadRenderMap.jobCreator = jobCreator;
    if (jobCreator)
      FirebaseWorker.ref(`photoheight/company_space/${jobCreator}/models/pole_loading`)
        .once('value')
        .then((s) => (this.jobCreatorHasPoleLoadingModels = s.exists()));
  }

  async deleteJob(e, d) {
    let key = d.key;
    let callback = d.callback;
    await DeleteJob(key, FirebaseWorker, this.masterLocationDirectories).catch((err) => {
      this.toast(err);
      throw err;
    });
    if (this.job_id == key) this.job_id = null;
    if (callback) callback(key);
  }

  showEditCreator(options) {
    return options != null;
  }

  getAttributeKeysForTypes(attrs, types, skipNullAttributeTypes) {
    types = (types && types.base) || types || ['node'];
    if (!Array.isArray(types)) types = [types];
    let t = [];
    for (let a in attrs) {
      let allowedTypes = attrs[a].attribute_types;
      // if the allowed types is null and we are not skipping null attribute types
      // or if the allowed types is an array and it contains one of the given types
      // then add the attribute to the list
      if (
        (allowedTypes == null && !skipNullAttributeTypes) ||
        (Array.isArray(allowedTypes) && allowedTypes.some((x) => types.includes(x)) == true)
      )
        t.push(a);
    }
    return t;
  }

  getAttributeNameLabel(attr) {
    return CamelCase(this.otherAttributes?.[attr]?.label ?? attr);
  }

  isStandardMapLabel(item) {
    return item == 'lowCable' || item == 'photoCount';
  }

  isLiteTier(tier) {
    return tier == 'katapult pro lite';
  }

  focusInputDialogEditing() {
    if (this.editing != null && this.editing != '' && this._sharing == 'write') {
      this.$.infoPanel.nextInputElement();
    }
  }

  getNodeZIndex(nodes, id, selected) {
    if (id == selected) return 1000;
    else if (this.modelDefaults.pole_node_types.includes(PickAnAttribute(nodes?.[id].attributes, this.modelDefaults.node_type_attribute)))
      return 500;
    return 5;
  }

  removeAlpha(x) {
    return x.replace(/[^0-9.]/g, '');
  }

  formatTime(time) {
    if (time == null) return '';
    var date = new Date(time);
    var hours = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);
    var seconds = ('0' + date.getSeconds()).slice(-2);
    return hours + ':' + minutes + ':' + seconds;
  }

  getPhotoLabelItems(selectedDeliverableNode) {
    var labels = [];
    var attributes = SquashNulls(this.nodes, selectedDeliverableNode, 'attributes');
    if (attributes != '') {
      for (var property in this.photoLabels) {
        var label = CamelCase(property) + ': ';
        var valueLabel = '';
        var count = 0;
        for (var itemKey in attributes[property]) {
          if (count > 0) {
            valueLabel += ', ';
          }
          valueLabel += this.getAttributeLabel(property, attributes[property][itemKey], ', ');
          count++;
        }
        if (count > 0) {
          labels.push(label + (valueLabel == '' ? '(None)' : valueLabel));
        }
      }
    }
    return labels;
  }

  async setSharingPermissions(job_id, userGroup) {
    if (job_id && userGroup) {
      let sharing = await FirebaseWorker.ref(`photoheight/jobs/${job_id}/sharing/${userGroup}`)
        .once('value')
        .then((s) => s.val() || '');
      this.set('sharing', sharing);
    } else {
      this.set('sharing', '');
    }
  }

  checkCompany(userGroup) {
    if (this.userGroup == 'katapult') {
      return true;
    } else {
      return false;
    }
  }

  calcActiveCommandModel(activeCommand) {
    const models = {
      ...this.mappingButtons,
      $drawProposedAnchor: {
        label: 'Proposed Anchor',
        connection: {
          attributes: {
            connection_type: { value: this.modelDefaults.downguy_connection_types[0] }
          },
          location_1: 'active',
          location_1_allowed_types: [...this.modelDefaults.pole_node_types],
          location_2: 'new'
        },
        node: {
          attributes: {
            node_type: { value: this.modelDefaults.proposed_anchor_node_type },
            company: { value: this.activeCommandData?.marker_company },
            rod_size: { value: '3/4' },
            anchor_eyes: { value: '3' },
            sizes_of_attached_dn_guys: { value: '' },
            existing_aux_eye: { value: '0' },
            anc_elevation: { value: '0' }
          },
          location: 'new'
        }
      }
    };
    return models?.[activeCommand];
  }

  calcCanWrite(_sharing) {
    return _sharing == 'write';
  }

  getItem(object) {
    if (object == null || arguments.length <= 1) return null;
    var temp = object;
    for (var i = 1; i < arguments.length; i++) {
      var arg = arguments[i];
      if (temp == null || arg == null) return null;
      temp = temp[arg];
    }
    return temp;
  }

  isMultiAddAttribute(confirmDialogBodyType) {
    return confirmDialogBodyType == 'multiAddAttributes' || confirmDialogBodyType == 'multiAddAttributesImportPolygon';
  }

  showPoleTagWarning(item) {
    const includesPoleTagAttribute = item?.name == 'pole_tag' || item?.some?.((x) => x.name == 'pole_tag');
    return this.config.firebaseData.utilityCompany && includesPoleTagAttribute;
  }

  getConnSectionKeys(connKey) {
    if (this.connections == null) return null;
    var conn = this.connections[connKey];
    if (conn != null && conn.sections != null) {
      return Object.keys(conn.sections);
    }
    return null;
  }

  getCompanyName(companyId) {
    return SquashNulls(this.companyNames, companyId) || companyId;
  }

  qcViewNodeClicked(e) {
    var nodeKey = (e && e.model && e.model.item && e.model.item.key) || null;
    if (nodeKey) {
      this.$.katapultMap.selectNode({ detail: { key: nodeKey, jobId: this.job_id } });
      this.zoomToNode(nodeKey);
    }
  }

  qcViewNodeDoubleClicked(e) {
    var nodeKey = (e && e.model && e.model.item && e.model.item.key) || null;
    if (nodeKey) this.$.katapultMap.doubleClickNode({ detail: { key: nodeKey, jobId: this.job_id } });
  }

  nodeListClick(e) {
    var nodeKey = (e && e.model && e.model.node && e.model.node.key) || null;
    if (nodeKey) this.$.katapultMap.selectNode({ detail: { key: nodeKey, jobId: this.job_id } });
  }

  nodeListDblClick(e) {
    var nodeKey = (e && e.model && e.model.node && e.model.node.key) || null;
    if (nodeKey) this.$.katapultMap.doubleClickNode({ detail: { key: nodeKey, jobId: this.job_id } });
  }

  nodeListIconClick(e) {
    if (this.poleListClickTimer) return;
    else {
      clearTimeout(this.poleListClickTimer);
      this.poleListClickTimer = setTimeout(
        function () {
          this.poleListClickTimer = null;
        }.bind(this),
        250
      );
    }
    var nodeKey = (e && e.model && e.model.node && e.model.node.key) || null;
    this.selectedNode = nodeKey;
    if (nodeKey) this.zoomToNode(nodeKey);
  }

  /**
   * Handles the zoom to node event.
   * This function listens for an event that contains a nodeId and triggers the zoomToNode function
   * if the nodeId is present in the event details.
   * @param {Event} event - The event object containing the nodeId in its details.
   */
  receiveZoomToNodeEvent(event) {
    const nodeId = event?.detail?.nodeId;
    if (!nodeId) return;

    this.zoomToNode(nodeId);
  }

  zoomToNode(nodeKey) {
    this.zoomNode = typeof nodeKey == 'undefined' ? this.zoomNode : nodeKey;
    if (this.zoomNode && this.nodes && this.nodes[this.zoomNode]) {
      var latLng = new google.maps.LatLng(this.nodes[this.zoomNode].latitude, this.nodes[this.zoomNode].longitude);
      this.editing = 'Node';
      if (this.map.getCenter() != latLng) this.map.panTo(latLng);
      if (this.zoom < 20) this.map.setZoom(20);
    }
    this.zoomNode = null;
  }

  zoomToConnection(connectionKey) {
    if (connectionKey && this.connections && this.connections[connectionKey]) {
      var node1Lat = this.getNodeLatitude(this.connections[connectionKey].node_id_1);
      var node1Long = this.getNodeLongitude(this.connections[connectionKey].node_id_1);
      var node2Lat = this.getNodeLatitude(this.connections[connectionKey].node_id_2);
      var node2Long = this.getNodeLongitude(this.connections[connectionKey].node_id_2);
      var mid = GetMidpointLatLng(
        new google.maps.LatLng(node1Lat, node1Long),
        new google.maps.LatLng(node2Lat, node2Long),
        this.map.getProjection()
      );
      if (this.map.getCenter() != mid) this.map.panTo(mid);
      if (this.zoom < 18) this.map.setZoom(18);
    }
  }

  getNodeLongitude(nodeKey) {
    if (nodeKey && this.nodes && this.nodes[nodeKey]) return this.nodes[nodeKey].longitude;
    return '';
  }

  getNodeLatitude(nodeKey) {
    if (nodeKey && this.nodes && this.nodes[nodeKey]) return this.nodes[nodeKey].latitude;
    return '';
  }

  getSectionAttr(connKey, sectionKey) {
    return SquashNulls(this.connections, connKey, 'sections', sectionKey, 'multi_attributes');
  }

  getNodeAttr(nodeKey) {
    if (nodeKey && this.nodes && this.nodes[nodeKey]) return this.nodes[nodeKey].attributes;
    return '';
  }

  showJobEditButton(sharing, readOnlyUser) {
    return sharing == 'write' && !readOnlyUser;
  }

  getActiveItem(editing, editingNode, activeConnection, activeSection) {
    if ((editing == 'Node' || editing == 'Node & Connection') && editingNode) return 'n' + editingNode;
    if (editing == 'Connection' && activeConnection) return 'c' + activeConnection;
    if (editing == 'Section' && activeSection) return 's' + activeConnection + ':' + activeSection;
    return '';
  }

  updateHash(job_id, activeItem, activeSavedView) {
    this.updateHashDebouncer = Debouncer.debounce(this.updateHashDebouncer, timeOut.after(400), () => {
      let hash = [];
      hash.push(this.job_id || '');
      hash.push(this.activeItem || '');
      hash.push(this.activeSavedView || '');
      hash.push(this.jobToken || '');
      hash = hash.join('/').replace(/\/+$/, ''); // remove trailing slashes
      history.replaceState(undefined, undefined, '#' + hash);
    });
  }

  addToHash(key, hash) {
    var element = this;
    if (key.indexOf('$') == 0) {
      var keySplit = key.split('.');
      element = this.$[keySplit[1]];
      key = keySplit[2];
    }
    if (element[key] != null && (!Array.isArray(element[key]) || element[key].length > 0)) {
      hash[key] = element[key];
    }
  }

  getNodeURL(selectedNode, type, activeConn) {
    // for feedback email
    if (selectedNode != '' || selectedNode != undefined) {
      var url = window.location.href;
      var fullURL = url.split('#');
      var hash = {};
      for (var i = 0; i < this.hashKeys.length; i++) {
        this.addToHash(this.hashKeys[i], hash);
      }
      hash.selectedNode = selectedNode;
      hash.editingNode = selectedNode;
      hash.editing = type;
      if (type == 'Section') {
        hash.activeConnection = activeConn;
      }
      hash = JSON.stringify(hash);
      url = fullURL[0] + '#' + hash;
      url = encodeURI(url);
      return url;
    } else {
      return false;
    }
  }

  highlightTraceOnMap(traceKey, shouldPromptForSplitting) {
    if (this.traces && this.traceItems[traceKey] != null) {
      // Cancel the current action and show toast
      this.cancelPromptAction();
      this.toast('Mapping out trace...');
      // Get the keys of photos that the trace is in and add "photos/" before each key
      let photoPaths = Object.keys(this.traceItems[traceKey]).map((key) => 'photos/' + key);
      // Get the photo data
      GetJobData(this.job_id, photoPaths).then((data) => {
        // Stores the locations for the polygon's path
        let pathLocations = [];
        // Loop through every photo in the set
        for (let key in data) {
          // Get the photo object
          let photoData = data[key];
          // Check if the photo has associated locations
          if (photoData && photoData.associated_locations) {
            // Loop through each location, and make a point for that location
            for (let locationKey in photoData.associated_locations) {
              let locationItem = {};
              // Check if the location is a node
              if (photoData.associated_locations[locationKey] == 'node') {
                if (this.nodes[locationKey]) {
                  // Set the locationItem's lat/long and key
                  locationItem.latitude = this.nodes[locationKey].latitude;
                  locationItem.longitude = this.nodes[locationKey].longitude;
                  locationItem.key = locationKey;
                }
              }
              // Otherwise, check if the location is a section
              else if (photoData.associated_locations[locationKey] == 'section') {
                // Create an array of the path to the section
                let locationParts = locationKey.split(':');
                let path = [this.connections, locationParts[0], 'sections', locationParts[1]];
                let section = SquashNulls.apply(this, path);
                // Set the locationItem's lat/long and keys
                locationItem.latitude = section.latitude;
                locationItem.longitude = section.longitude;
                locationItem.key = locationParts[1];
                locationItem.connectionKey = locationParts[0];
              }
              // If a locationItem has coordinates, add the type and add to the array
              if (locationItem.latitude && locationItem.longitude) {
                locationItem.type = photoData.associated_locations[locationKey];
                pathLocations.push(locationItem);
              }
            }
          }
        }

        let highlights = [];

        do {
          let foundBranches = [];
          this.findBranchesForConnectedPoints(pathLocations, foundBranches);

          for (let i = 0; i < foundBranches.length; i++) {
            let segmentPoints = foundBranches[i];

            // If there is only 1 segment point, create a fake second point that is
            // just barely different in position so that the polygon point will show up
            if (segmentPoints.length == 1) {
              let dummyCopy = JSON.parse(JSON.stringify(segmentPoints[0]));
              dummyCopy.latitude += 0.0000001;
              segmentPoints.push(dummyCopy);
            }

            highlights.push({
              strokeColor: '#00f6ff',
              strokeWeight: 22,
              strokeOpacity: 0.75,
              points: segmentPoints
            });
          }
        } while (pathLocations.length > 0);

        // Set the polygon highlight data
        this.$.katapultMap.polygonMapHighlights = highlights;

        // Check if we should prompt the user to split the trace
        if (shouldPromptForSplitting == true) {
          this.splittingTraceKey = traceKey;
          this.selectedNode = null;
          this.activeCommand = '_multiSelectItems';
          // Tell the multi select counter which types to show
          this.multiSelectIncludedTypes = {
            nodes: true,
            sections: false,
            connections: true
          };
          this.$.katapultMap.openActionDialog({
            title: 'Click nodes or draw a polygon around them to include them in the trace to split. Right click to delete polygon points.',
            buttons: [
              { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
              { title: 'Finish', callback: this.confirmSplitTrace.bind(this), attributes: { 'secondary-color': '' } }
            ]
          });
        }
      });
    } else this.toast('Could not highlight trace.');
  }

  findBranchesForConnectedPoints(checkableEndpoints, foundBranches, startingPoint, workingBranch) {
    // Stores the branches found when traversing the endpoints
    foundBranches = foundBranches || [];
    // Stores the point to start at. If it's not given, then use the first item in checkableEndpoints
    startingPoint = startingPoint || checkableEndpoints[0];
    // Stores the list of endpoints that are part of the current branch
    workingBranch = workingBranch || [startingPoint];
    let validEndpoint = null;

    // Remove the startingPoint from the checkableEndpoints list, so
    // it's not found again when running the function for the found endpoint
    checkableEndpoints.splice(checkableEndpoints.indexOf(startingPoint), 1);

    for (let i = 0; i < checkableEndpoints.length; i++) {
      // Check if the current checkable endpoint is connected to the startingPoint
      if (this.connectionItemsAreNextToEachOther(startingPoint, checkableEndpoints[i]) == true) {
        // If we already found a validEndpoint before, then this is the start of a new branch
        if (validEndpoint != null) {
          workingBranch = [startingPoint];
        }
        // Save the endpoint found
        validEndpoint = checkableEndpoints[i];
        // Add the current validEndpoint to the workingBranch
        workingBranch.push(validEndpoint);
        // Call findBranchesForConnectedPoints again to continue adding the connected endpoints to the workingBranch
        this.findBranchesForConnectedPoints(checkableEndpoints, foundBranches, validEndpoint, workingBranch);
        // Set i to -1 so that it will start at 0 in the next loop iteration because
        // the contents of the array haschanged and should be checked again from the beginning
        i = -1;
      }
    }

    // If we never found a valid endpoint, then add the workingBranch
    // tofoundBranches because this is the end of a branch
    if (validEndpoint == null) {
      foundBranches.push(workingBranch.slice());
    }
  }

  connectionItemsAreNextToEachOther(itemOne, itemTwo) {
    // Only if a connection is connecting the two nodes at each end, and the connection has no sections
    if (itemOne.type == 'node' && itemTwo.type == 'node') {
      for (let key in this.connections) {
        // Check that the connection's endpoints are itemOne and itemTwo only
        if (
          (this.connections[key].node_id_1 == itemOne.key || this.connections[key].node_id_2 == itemOne.key) &&
          (this.connections[key].node_id_1 == itemTwo.key || this.connections[key].node_id_2 == itemTwo.key)
        ) {
          // Check that the connection has no sections
          if (this.connections[key].sections == null) {
            return true;
          }
        }
      }
    }
    // Check if either or both items are sections
    else if (itemOne.type == 'section' || itemTwo.type == 'section') {
      // Get the item of the two that is the section
      let sectionItem = itemOne.type == 'section' ? itemOne : itemTwo;
      // Get the item that isn't the sectionItem
      let otherItem = itemOne.type == 'section' ? itemTwo : itemOne;

      // Get the connection for the sectionItem's connection key
      let connection = this.connections[sectionItem.connectionKey];

      // Make sure the connection has sections
      if (connection.sections) {
        // Make sure the other item is associated with this section in some way
        if (
          connection.node_id_1 == otherItem.key ||
          connection.node_id_2 == otherItem.key ||
          otherItem.connectionKey == sectionItem.connectionKey
        ) {
          // Create two location points for the sectionItem and otherItem
          let sectionItemLocation = new google.maps.LatLng(sectionItem.latitude, sectionItem.longitude);
          let otherItemLocation = new google.maps.LatLng(otherItem.latitude, otherItem.longitude);
          // Get the distance between the two items
          let mainDistance = google.maps.geometry.spherical.computeDistanceBetween(sectionItemLocation, otherItemLocation);
          // Get the bearing between the two points
          let mainBearing = google.maps.geometry.spherical.computeHeading(sectionItemLocation, otherItemLocation);
          // Set to mark if we found a closer section
          let foundCloserSection = false;

          // Loop through the sections on the connection and make sure that there are none closer than the section
          for (let sectionKey in connection.sections) {
            // Skip the section if it's the same as sectionItem
            if (sectionKey == sectionItem.key) continue;
            // Get the location of the section we're testing
            let testSectionLocation = new google.maps.LatLng(
              connection.sections[sectionKey].latitude,
              connection.sections[sectionKey].longitude
            );
            // Get the distance between the sectionItem and the new section to test
            let testDistance = google.maps.geometry.spherical.computeDistanceBetween(sectionItemLocation, testSectionLocation);
            // Get the bearing between the sectionItem and the new section to test
            let testBearing = google.maps.geometry.spherical.computeHeading(sectionItemLocation, testSectionLocation);
            // Check if the test distance is less than the main distance (meaning
            // that this section is closer to the sectionItem than otherItem)
            if (testDistance < mainDistance) {
              // To make sure this section is actually closer to sectionItem, we have
              // to rule out the possibility that this section is closer to sectionItem,
              // but on the opposite side of it, which would mean that otherItem is
              // the closest on the side we care about

              // If the two bearings are less than 10 degrees apart, then the must be going in the same direction
              if (Math.abs(mainBearing, testBearing) < 10) {
                // Mark that we found a section that is closer to sectionItem than otherItem
                foundCloserSection = true;
                break;
              }
            }
          }
          // If we did not find a section that is closer, then the items are next to each other
          if (foundCloserSection == false) {
            return true;
          }
        }
      }
    }
    // None of the tests passed, so these items are not next to each other
    return false;
  }

  confirmSplitTrace() {
    // Check to make sure we have a trace key to split and it exists in the trace items
    if (this.splittingTraceKey != null && this.traces[this.splittingTraceKey] && this.traceItems[this.splittingTraceKey]) {
      // Create a key for the new trace to be created
      let newTraceKey = FirebaseWorker.ref().push().key;
      // Create an array to store the objects that have photos to pull main photos from
      let objectsWithPhotos = [];
      // Create an array to hold all of the keys of the photos that are part of the
      // selected nodes and connections that are also part of the splittingTraceKey trace
      let tracedPhotos = [];
      // Create an update object for updating the firebase data
      let update = {};

      // Loop through the selected nodes
      this.multiSelectedNodes = [];
      this.multiSelectedNodes = this.$.katapultMap.multiSelectedNodes;
      for (let i = 0; i < this.multiSelectedNodes.length; i++) {
        // Get the id of the node
        let nodeId = this.multiSelectedNodes[i];
        // Check if the node has photos and add them to the objectsWithPhotos list
        if (this.nodes[nodeId].photos) {
          objectsWithPhotos.push(this.nodes[nodeId]);
        }
      }

      // Loop through the connections and find all that should be included based on the selected nodes
      for (let connectionKey in this.connections) {
        // Get the connection data
        let connection = this.connections[connectionKey];
        // Check if the connection has both endpoints in multiSelectedNodes
        if (this.multiSelectedNodes.indexOf(connection.node_id_1) != -1 && this.multiSelectedNodes.indexOf(connection.node_id_2) != -1) {
          // Check if the connection has sections
          if (connection.sections) {
            // Loop through each section on the connection
            for (let sectionKey in connection.sections) {
              // Check if the section has photos and add them to the objectsWithPhotos list
              if (connection.sections[sectionKey].photos) {
                objectsWithPhotos.push(connection.sections[sectionKey]);
              }
            }
          }
        }
      }

      // Loop through the objectsWithPhotos list and add the main photos to
      // the tracedPhotos list if they are part of the trace
      for (let i = 0; i < objectsWithPhotos.length; i++) {
        // Get the main photo from the photoList
        let mainPhoto = this.getMainPhotoKey(objectsWithPhotos[i]);
        // Check if the photo exists and is part of the trace items
        if (mainPhoto != null && this.traceItems[this.splittingTraceKey][mainPhoto]) {
          // Add it to the tracedPhotos list
          tracedPhotos.push(mainPhoto);
        }
      }

      // Make a copy of the existing trace items data to be changed
      let oldTraceItemsData = JSON.parse(JSON.stringify(this.traceItems[this.splittingTraceKey]));
      // Create an object for the new trace's trace items data
      let newTraceItemsData = {};

      // Loop through the list of photos to update
      for (let i = 0; i < tracedPhotos.length; i++) {
        // Get the list of traced items on the photo for the trace
        let photoTraceList = this.traceItems[this.splittingTraceKey][tracedPhotos[i]];
        // Check to make sure the trace list exists
        if (photoTraceList) {
          // Loop through each item and its path on the traced photo
          for (let itemType in photoTraceList) {
            for (let itemPath in photoTraceList[itemType]) {
              // Replace any : characters in the item's path with / characters
              itemPath = itemPath.replace(/:/g, '/');
              // Update path to the current item's trace and set it to the new trace
              update['photos/' + tracedPhotos[i] + '/photofirst_data/' + itemType + '/' + itemPath + '/_trace'] = newTraceKey;
            }
          }
          // Add this photo and it's trace items data to the new list of trace items
          newTraceItemsData[tracedPhotos[i]] = photoTraceList;
          // Delete this photo from the old trace items data
          oldTraceItemsData[tracedPhotos[i]] = null;
        }
      }

      // Copy the trace data for the new key
      update['traces/trace_data/' + newTraceKey] = this.traces[this.splittingTraceKey];
      // Update the old and new trace items data
      update['traces/trace_items/' + newTraceKey] = newTraceItemsData;
      update['traces/trace_items/' + this.splittingTraceKey] = oldTraceItemsData;

      FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(
        update,
        function (error) {
          this.cancelPromptAction();
          if (error) {
            this.toast(error);
          }
        }.bind(this)
      );
    }
  }

  parseUserData() {
    if (this.userData && this.userData.hardware_details && this.nodes) {
      if (this.userData.hardware_details.start_node && this.nodes[this.userData.hardware_details.start_node]) {
        this._button_link_map_photo_data();
      } else if (this.userData.hardware_details.done_anchor && this.nodes[this.userData.hardware_details.done_anchor]) {
        var ref = FirebaseWorker.ref(
          'photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/hardware_details/done_anchor'
        );
        ref.remove();
        this.doNextMapPhotoAction();
      }
    }
    // Check for a map action
    if (this.userData && this.userData.map_action != null) {
      // Check if the action was to highlight a trace
      if (this.userData.map_action.highlight_trace != null) {
        // Get the key of the trace to split
        let traceKey = this.userData.map_action.highlight_trace;
        // Remove the call to highlight the trace
        this.$.userData.ref.child('map_action/highlight_trace').remove();
        // Highlight the trace and prompt for splitting
        this.highlightTraceOnMap(traceKey, true);
      }
      // Check if the action was to highlight a trace
      if (this.userData.map_action.link_down_guy != null) {
        const actionType = this.userData.map_action.link_down_guy.action_type;
        const jobId = this.userData.map_action.link_down_guy.job_id;
        const nodeId = this.userData.map_action.link_down_guy.node_id;

        // Ensure the down guy linking type is valid.
        if (this.job_id == jobId && ['proposed', 'transfer', 'proposed_new_anchor', 'transfer_new_anchor'].includes(actionType)) {
          // Zoom to the node for the photo
          if (nodeId) {
            this.selectedNode = nodeId;
            this.zoomToNode(nodeId);
          }

          // Save the map linking data as the active command data.
          this.activeCommandData = this.userData.map_action.link_down_guy;

          // Handle drawing new anchor and linking down guy to it.
          if (actionType == 'proposed_new_anchor' || actionType == 'transfer_new_anchor') {
            // Draw new proposed anchor.
            this.activeCommand = '$drawProposedAnchor';
            this.$.katapultMap.openActionDialog({
              title: this.activeCommandData.label,
              materialIcon: 'south_west'
            });
          }
          // Handle linking/transfering to an anchor.
          else {
            this.activeCommand = '_linkAnchorToDownGuy';
            this.$.katapultMap.openActionDialog({
              title: `Click on the anchor to link the ${this.activeCommandData.marker_description}.`,
              buttons: [{ title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } }]
            });
          }
        }
        // Remove the call to highlight the trace
        this.$.userData.ref.child('map_action/link_down_guy').remove();
      }
    }
    if ((this.nodes != null || this.nodesAreLoaded) && this.userData != null && this.userData.zoom_map != null) {
      // zoom to node
      var objectId = this.userData.zoom_map.key;
      if (this.userData.zoom_map.type == 'node') {
        if (this.nodes != null && this.nodes[objectId] != null) {
          this.editingNode = objectId;
          this.selectedNode = objectId;
          this.latitude = this.nodes[objectId].latitude;
          this.longitude = this.nodes[objectId].longitude;
          this.zoom = 20;
          this.selectDeliverablePhoto(this.userData.zoom_map.type, objectId);
          this.$.userData.ref.child('zoom_map').remove();
        } else {
          this.$.nodes.ref.child(objectId).once(
            'value',
            function (snapshot) {
              var node = snapshot.val();
              if (node != null) {
                this.editingNode = objectId;
                this.selectedNode = objectId;
                this.latitude = node.latitude;
                this.longitude = node.longitude;
                this.zoom = 20;
              } else {
                this.toast('Attempting to zoom to node that doesnt exist');
              }
              this.$.userData.ref.child('zoom_map').remove();
            }.bind(this)
          );
        }
      } else if (this.userData.zoom_map.type == 'connection') {
        if (this.connections != null && this.connections[objectId] != null) {
          this.activeConnection = objectId;
          var n1 = this.nodes[this.connections.node_id_1];
          var n2 = this.nodes[this.connections.node_id_2];
          var mid = GetMidpointLatLng(
            new google.maps.LatLng(n1.latitude, n1.longitude),
            new google.maps.LatLng(n2.latitude, n2.longitude),
            this.map.getProjection()
          );
          this.latitude = mid.lat();
          this.longitude = mid.lng();
          this.zoom = 20;
        } else {
          this.toast('Attempting to zoom to connection that doesnt exist');
        }
        this.$.userData.ref.child('zoom_map').remove();
      } else if (this.userData.zoom_map.type == 'section') {
        // reset the editing on a node
        this.editingNode = null;
        this.selectedNode = null;
        // set the proper active connection and section
        var ids = objectId.split(':');
        if (SquashNulls(this.connections, ids[0], 'sections')[ids[1]] != null) {
          this.activeConnection = ids[0];
          this.activeSection = ids[1];
          this.latitude = this.connections[ids[0]].sections[ids[1]].latitude;
          this.longitude = this.connections[ids[0]].sections[ids[1]].longitude;
          this.zoom = 20;
          this.selectDeliverablePhoto(this.userData.zoom_map.type, objectId);
        } else {
          this.toast(`Attempting to zoom to section that doesn't exist`);
        }
        this.$.userData.ref.child('zoom_map').remove();
      } else if (this.userData.zoom_map.type == 'point') {
        this.$.katapultMap.zoomToLocation({
          latitude: this.userData.zoom_map.latitude,
          longitude: this.userData.zoom_map.longitude,
          zoom: 20,
          showMarker: true,
          markerContent: this.userData.zoom_map.zoomText
        });
        this.$.userData.ref.child('zoom_map').remove();
      }
    } else if (this.userData != null && this.userData.photoTookLinkingAction == true) {
      this.$.userData.ref.child('photoTookLinkingAction').remove();
      this.doNextMapPhotoAction();
    }
  }

  toggleShowLines() {
    this.showLines = !this.showLines;
  }

  toggleAvatars(showAvatars, userGroup, mapInitialized, projection) {
    if (mapInitialized && userGroup && projection) {
      // Remove old listeners
      if (this.avatars && this.avatars.boundsListener) {
        this.avatars.boundsListener.remove();
        this.avatars.query?.cancel();
        this.avatars = { list: [], listLookup: {} };
      }

      if (showAvatars) {
        this.avatars = { list: [], listLookup: {} };
        // Set up listeners
        this.avatars.geoFire = new GeoFire(FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/avatars/map/'));
        this.avatars.boundsListener = google.maps.event.addListener(this.map, 'bounds_changed', this.updateAvatarQuery.bind(this));
        this.updateAvatarQuery();
      }
    }
  }

  updateAvatarQuery() {
    this.updateAvatarQueryDebouncer = Debouncer.debounce(this.updateAvatarQueryDebouncer, timeOut.after(100), () => {
      if (!this.projection) {
        this.projection = this.map.getProjection();
      }
      if (this.avatars.query) {
        this.avatars.query.updateCriteria(this.getRadiusFromBounds());
      } else {
        this.avatars.query = this.avatars.geoFire.query(this.getRadiusFromBounds());
        this.avatars.query.on('key_entered', this.avatarEntered.bind(this));
        this.avatars.query.on('key_exited', this.avatarExited.bind(this));
        this.avatars.query.on('key_moved', this.avatarMoved.bind(this));
      }
    });
  }

  avatarEntered(key, location, distance, data) {
    // Only Show if < 2hrs old
    let timeDiff = new Date().getTime() - data.lastLog + this.timeOffset;
    if (timeDiff < 7200000) {
      //If it is our users real position, don't overwrite it with virutal positions for the first 15min
      if (key == this.user.uid) {
        if (!data.virtual) {
          this.showingRealPosition = true;
          clearTimeout(this.keepRealPositionTimeout);
          this.keepRealPositionTimeout = setTimeout(() => {
            this.showingRealPosition = false;
          }, 60000);
          if (timeDiff < 60000) {
            this.$.katapultMap.$.geoLocation.idle = true;
            this.gpsLat = location[0];
            this.gpsLng = location[1];
          } else this.$.katapultMap.$.geoLocation.idle = false;
        }
      } else {
        this.avatars.listLookup[key] = this.avatars.list.length;
        let avatar = {
          key,
          latitude: location[0],
          longitude: location[1],
          email: data.email || 'Unknown',
          lastLog: data.lastLog + this.timeOffset,
          job_name: data.job_name,
          virtual: data.virtual,
          dragged: ''
        };
        avatar.iconContent = this.getAvatarIcon(avatar);
        this.push('avatars.list', avatar);
      }
    }
  }

  avatarMoved(key, location, distance, data) {
    let avatarIndex = this.avatars.listLookup[key];
    if (this.avatars.list[avatarIndex] != null) {
      this.set('avatars.list.' + avatarIndex + '.latitude', location[0]);
      this.set('avatars.list.' + avatarIndex + '.longitude', location[1]);
      this.set('avatars.list.' + avatarIndex + '.email', data.email);
      this.set('avatars.list.' + avatarIndex + '.lastLog', data.lastLog + this.timeOffset);
      this.set('avatars.list.' + avatarIndex + '.job_name', data.job_name);
      this.set('avatars.list.' + avatarIndex + '.virtual', data.virtual);
      this.set('avatars.list.' + avatarIndex + '.dragged', '');
      this.set('avatars.list.' + avatarIndex + '.iconContent', this.getAvatarIcon(this.get('avatars.list.' + avatarIndex)));
    } else {
      this.avatarEntered(key, location, distance, data);
    }
  }

  avatarExited(key, location, distance, data) {
    let avatarIndex = this.avatars.listLookup[key];
    if (this.avatars.list[avatarIndex] != null) {
      this.splice('avatars.list', avatarIndex, 1);
      var listLookup = {};
      for (var i = 0; i < this.avatars.list.length; i++) {
        listLookup[this.avatars.list[i].key] = i;
      }
      this.avatars.listLookup = listLookup;
    }
  }

  getAvatarIcon(avatar) {
    return `<avatar-icon id="avatar.${avatar.key}" email="${avatar.email}" uid="${avatar.key}" job-name="${
      avatar.job_name || ''
    }" last-seen="${avatar.lastLog}" icon="${avatar.virtual ? 'desktop_windows' : 'directions_car'}" time-offset="${
      this.timeOffset
    }"></avatar-icon>`;
  }

  dragAvatar(e) {
    if (!e.currentTarget.name != 'dragged') {
      e.currentTarget.richContent = e.currentTarget.richContent.replace('<avatar-icon', '<avatar-icon avatar-dragged');
      e.currentTarget.name = 'dragged';
    }
  }

  getRadiusFromBounds() {
    var center = this.map.getCenter();
    return {
      center: [center.lat(), center.lng()],
      radius: Math.max(0.05, google.maps.geometry.spherical.computeDistanceBetween(center, this.map.getBounds().getNorthEast()) / 1000)
    };
  }

  computeLegendItems(jobStyles, tier, _sharing) {
    var legendItems = [];
    var styleModel = SquashNulls(jobStyles, 'default') || [];
    // Loop through styles types
    for (var styleType in styleModel) {
      // Skip properties starting with _
      if (styleType[0] != '_') {
        for (var styleIndex in styleModel[styleType]) {
          if (styleModel[styleType].hasOwnProperty(styleIndex)) {
            legendItems.push(StyleRuleToIcon(styleModel[styleType][styleIndex], this.isLiteTier(this.tier)));
          }
        }
      }
    }

    return legendItems;
  }

  haveLabels() {
    return this.showSpanDistances || (this.nodeLabels != null && Object.keys(this.nodeLabels).length != 0);
  }

  toggleLabels(e) {
    if (e.currentTarget.checked) {
      this.showSpanDistances = this.oldShowSpanDistances == null ? true : this.oldShowSpanDistances;
      this.nodeLabels = this.oldNodeLabels || {};
    } else {
      this.oldShowSpanDistances = this.showSpanDistances;
      this.oldNodeLabels = this.nodeLabels;
      this.showSpanDistances = false;
      this.nodeLabels = {};
    }
  }

  toggleNodeLabel(e) {
    let item = e.currentTarget.item;
    if (item) this.set(`nodeLabels.${item}`, !this.get(`nodeLabels.${item}`));
  }

  clearNodeLabels(e) {
    let temp = {};
    for (let key in this.nodeLabels) {
      if (this.nodeLabels[key]) temp[key] = true;
    }
    this.nodeLabels = temp;
  }

  addNodeLabel(e) {
    if (e.detail.value) this.set('nodeLabels.' + e.detail.value, true);
    e.currentTarget.value = '';
  }

  focusLabelInput() {
    // this.$.layersDialog.style.overflow = 'visible';
  }

  blurLabelInput() {
    // this.$.layersDialog.style.overflow = '';
  }

  toggleLegendItemCheckbox(e) {
    let target = e.currentTarget;
    let value = target.checked;
    if (this.hiddenLegendItems == null) {
      this.hiddenLegendItems = {};
    } else if (this.hiddenLegendItems == 'all') {
      var hiddenLegendItems = {};
      var checkboxes = this.$.legendDrawer.querySelectorAll('.legendCheckbox:not(#allLegendCheckboxes)');
      for (var i = 0; i < checkboxes.length; i++) {
        hiddenLegendItems[checkboxes[i].name] = true;
      }
      this.hiddenLegendItems = hiddenLegendItems;
    }
    var id = target.name;
    if (value) {
      this.set('hiddenLegendItems.' + id, null);
    } else {
      this.set('hiddenLegendItems.' + id, true);
    }
  }

  toggleAllLegendCheckboxes(e) {
    e.stopPropagation();
    var checked = false;
    if (this.hiddenLegendItems == null) {
      this.hiddenLegendItems = 'all';
      checked = false;
    } else {
      this.hiddenLegendItems = null;
      checked = true;
    }
    var checkboxes = this.$.legendDrawer.querySelectorAll('.legendCheckbox');
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = checked;
    }
  }

  toggleEditability() {
    this.viewPublishedChecked = !this.viewPublishedChecked;
    if (this.viewPublishedChecked) this._sharing = 'read';
    else this._sharing = 'write';
  }

  async toggleEditableView() {
    if (!this.enabledFeatures.job_locking) {
      // by default, any write user can toggle a job's editability
      if (this.sharing == 'write') this.toggleEditability();
    } else {
      if (this.sharing == 'write') {
        // only users that have unlocking permission can unlock a job
        if (this.viewPublishedChecked) {
          // get the job unlocking permissions
          let uid = this.user.uid;
          let canUnlockJob = await FirebaseWorker.ref(`users/${this.userGroup}/${uid}/roles/photoheight/permissions/job_unlocking_access`)
            .once('value')
            .then((s) => s.val());
          if (canUnlockJob) {
            // show an alert that tells the user that they are only unlocking this job for the session
            KatapultDialog.alert({
              dialog: { title: 'Job Unlocked' },
              body: `This job has been unlocked for this session only. If the job should be unlocked for all users, uncheck the Job Locked checkbox in this job's attribute list.`
            });
            this.toggleEditability();
          } else {
            // show a confirmation when locking a job so that they know they may not be able to unlock it
            KatapultDialog.alert({
              dialog: { title: 'Job Unlocking Denied' },
              body: `You don't have permission to unlock this job. This permission can be granted in the admin page.`
            });
          }
        }
        // any write user should be able to lock a job
        else {
          // show a confirmation when locking a job so that they know they may not be able to unlock it
          const dialogConfig = KatapultDialog.confirm({
            dialog: { title: 'Job Locking Confirmation' },
            body: `Are you sure you want to lock this job? Only users with permission to unlock jobs will be able to unlock this job. This permission can be granted in the admin page.\n\nNote: This will only lock the job for the session. If the job should be locked for all users, check the Job Locked checkbox in this job's attribute list.`,
            confirmButton: { label: 'Lock' }
          });
          const didConfirm = await dialogConfig.confirmed;
          // If the user confirmed, lock the job
          if (didConfirm) this.toggleEditability();
        }
      }
    }
  }

  togglePrintView() {
    this.viewMode = this.viewMode == 'print' ? null : 'print';
  }

  activateCableTracing() {
    this.cableTracing = !this.cableTracing;
  }

  toggleShowSag() {
    this.showSag = !this.showSag;
    this.showClearances = false;
    this.showKpla = false;
  }

  toggleShowClearances() {
    this.showClearances = !this.showClearances;
    this.showSag = false;
  }

  toggleShowKpla() {
    this.showKpla = !this.showKpla;
    this.showSag = false;
  }

  set_sharing() {
    // if the job locking feature is enabled, then we should turn off this auto-locking functionality and rely on the Job Locked metadata property
    if (this.enabledFeatures.job_locking) {
      // if there isn't a Job Locked metadata property, then default to false so that the job will unlocked for them
      let jobLocked = this.metadata?.job_locked ?? false;
      if (this.readOnlyUser || this.metadata?.snapshot_of_job || jobLocked) this._sharing = 'read';
      else this._sharing = 'write';
    } else {
      // The internal _sharing setting should be "read" if the user is a read-only user, or if the job is set to published
      if (
        this.readOnlyUser ||
        (this.sharing == 'write' && this.status != null && this.status.published) ||
        this.metadata?.snapshot_of_job
      ) {
        this.viewPublishedChecked = true;
        this._sharing = 'read';
      }
      // Otherwise, the _sharing setting should be the same as sharing
      else {
        this._sharing = this.sharing;
      }
    }

    this.loadDeliverable();
  }

  hideSendFeedback(job_id, _sharing) {
    return !job_id || _sharing == 'write';
  }

  async openJobFeedback() {
    let projectManagerUid = await FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/metadata/project_manager')
      .once('value')
      .then((s) => s.val());
    if (projectManagerUid) {
      this.feedbackTo = [projectManagerUid];
    } else {
      // If the job creator contains katapult, preselect emails
      let defaultAdmins = []; // List of Katapult emails removed 6/13/2024
      // If there is a companyAdmins object, then search for matching emails
      if (this.companyAdmins) {
        for (var uid in this.companyAdmins) {
          if (this.companyAdmins[uid].email) {
            if (defaultAdmins.includes(this.companyAdmins[uid].email)) {
              this.push('feedbackTo', uid);
            }
          }
        }
      } else {
        // Add the emails to the feedbackToText data
        this.feedbackToText = defaultAdmins.join();
      }
    }

    this.feedbackCC = this.userEmail;
    this.feedbackSubject = 'Feedback on ' + this.jobName;
    var feedback = '';
    if (SquashNulls(this.status.feedback) != '') {
      feedback += this.status.feedback + '\n\n';
    }
    for (var nodeId in this.nodes) {
      if (this.nodes[nodeId].feedback != null && this.nodes[nodeId].feedback != '') {
        var label = this.getNodeLabel(this.nodes[nodeId]);
        feedback += label + ': ' + this.nodes[nodeId].feedback + '\n';
      }
    }
    for (var connId in this.connections) {
      var n1 = this.nodes[this.connections[connId].node_id_1];
      var n2 = this.nodes[this.connections[connId].node_id_2];
      var label = this.getNodeLabel(n1) + '-' + this.getNodeLabel(n2);
      if (this.connections[connId].feedback != null && this.connections[connId].feedback != '') {
        feedback += label + ': ' + this.connections[connId].feedback + '\n';
      }
      for (var sectionId in this.connections[connId].sections) {
        if (this.connections[connId].sections[sectionId].feedback != null && this.connections[connId].sections[sectionId].feedback != '') {
          feedback += 'Section on ' + label + ': ' + this.connections[connId].sections[sectionId].feedback + '\n';
        }
      }
    }
    feedback += '\n\n-- ' + this.userEmail;
    this.feedbackBody = feedback;
    this.feedBackUrl = encodeURI(window.location.href);
    this.$.jobFeedbackDialog.open();
  }

  getNodeLabel(item) {
    let orderingAttribute = PickAnAttribute(item.attributes, this.modelDefaults.ordering_attribute);
    if (orderingAttribute) return orderingAttribute;
    let tags = SquashNulls(item.attributes, 'pole_tag');
    let tag = null;
    for (let key in tags) {
      if (tags[key].owner && tags[key].tagtext) {
        return tags[key].tagtext;
      } else if (tags[key].tagtext) {
        tag = tags[key].tagtext;
      }
    }
    if (tag) return tag;
    if (item.latitude && item.longitude) return 'Pole at (' + item.latitude + ', ' + item.longitude + ')';
    return '';
  }

  sendJobFeedback() {
    // Initially set to variable
    var to = [];
    // Create flag to check to continue
    var shouldContinue = true;
    // Check if we have company admins to determine
    // if the feedbackTo was user input or selected from a list
    if (!this.companyAdmins) {
      // Check if feedbackToText is valid
      if (this.feedbackToText.indexOf('@') == -1) {
        shouldContinue = false;
      } else {
        // There is no admin list, so get the to field from user input
        to = this.feedbackToText.split(',');
      }
    } else {
      // Check if feedbackTo is valid
      if (this.feedbackTo == null || this.feedbackTo.length == 0) {
        shouldContinue = false;
      } else {
        // Get the emails from the selected uids
        for (var i = 0; i < this.feedbackTo.length; i++) {
          if (this.companyAdmins.hasOwnProperty(this.feedbackTo[i])) {
            to.push(this.companyAdmins[this.feedbackTo[i]].email);
          }
        }
      }
    }

    if (to.length == 0) {
      this.toast("Please provide a valid recipient's email address.");
      shouldContinue = false;
    }

    if (shouldContinue) {
      var nodeURL = '';
      var additionalBody = '';
      var cc = this.feedbackCC.split(',');
      for (var i = 0; i < cc.length; cc++) {
        if (cc[i] != '') {
          to.push({
            type: 'cc',
            email: cc[i]
          });
        }
      }
      // Adds extra text to email body, including links to job and individual nodes with feedback
      additionalBody = '\n\nLink to job:\n' + this.feedBackUrl;
      additionalBody += '\n\nLinks to node(s) with feedback:\n';
      // cycle through nodes for feedback
      for (var nodeId in this.nodes) {
        if (this.nodes[nodeId].feedback != null && this.nodes[nodeId].feedback != '') {
          nodeURL = this.getNodeURL(nodeId, 'Node');
          var label = this.getNodeLabel(this.nodes[nodeId]);
          additionalBody += label + ': ' + this.nodes[nodeId].feedback + '\n';
          additionalBody += nodeURL + '\n\n';
        }
      }
      //cycle through connections for feedback
      for (var connId in this.connections) {
        var n1 = this.nodes[this.connections[connId].node_id_1];
        var n2 = this.nodes[this.connections[connId].node_id_2];
        var label = this.getNodeLabel(n1) + '-' + this.getNodeLabel(n2);
        // skip feedback on connections
        if (this.connections[connId].feedback != null && this.connections[connId].feedback != '') {
          additionalBody += label + ': ' + this.connections[connId].feedback + '\n\n';
        }
        for (var sectionId in this.connections[connId].sections) {
          if (
            this.connections[connId].sections[sectionId].feedback != null &&
            this.connections[connId].sections[sectionId].feedback != ''
          ) {
            nodeURL = this.getNodeURL(sectionId, 'Section', connId);
            additionalBody += 'Section on ' + label + ': ' + this.connections[connId].sections[sectionId].feedback + '\n';
            additionalBody += nodeURL + '\n\n';
          }
        }
      }
      var key = FirebaseWorker.ref('photoheight/server_requests/email/requests').push().key;
      var update = {};
      update['/server_requests/email/requests/' + key] = {
        addresses: to,
        subject: this.feedbackSubject,
        body: this.feedbackBody + additionalBody,
        group_tag: 'legacy'
      };

      FirebaseWorker.ref('photoheight').update(
        update,
        function (error) {
          if (error) {
            this.toast(error);
          }
        }.bind(this)
      );
      FirebaseWorker.ref('photoheight/server_requests/email/responses/' + key).on(
        'value',
        function (snapshot) {
          var response = snapshot.val();
          if (response && response.success) {
            this.toast('Email successfully sent!');
          } else if (response && !response.success) {
            this.toast('Error sending email.');
            console.log('Error sending email - ', response.message);
          }
          snapshot.ref.remove();
          this.clearJobFeedbackFields();
        }.bind(this)
      );

      this.toast('Sending email ... ');
    }
  }

  clearJobFeedbackFields() {
    this.feedbackTo = [];
    this.feedbackToText = null;
    this.feedbackCC = null;
    this.feedbackSubject = null;
    this.feedbackBody = null;
    this.feedBackUrl = null;
  }

  haloAdded(event, detail, sender) {
    sender.feature.bringToBack();
  }

  getTierCompany(jobCreator, userGroup) {
    return jobCreator || userGroup || '';
  }

  setJobId(e, d) {
    // update jobId to be key from event details;
    this.job_id = d.key;
  }

  jobIdChanged() {
    this.jobIdAlreadyChanged = false;

    this.poleCount = null;
    this.jobChangedLoadDefaultLabels = true;
    this.jobChangedLoadDefaultView = true;
    this.hiddenLegendItems = null;
    this.$.katapultMap.polygonMapHighlights = null;
    this.activeSavedView = null;
    this.showPhoto = undefined;
    this.nodesAreLoaded = false;
    this.jobWarningReportsData = null;
    this.viewMode = null;
    this.toggleAllCrumbTrails(false);
    this.crumbTrails = null;
    this.startingLinkingOrderingAttribute = '';
    this.endLinkingOrderingAttribute = '';
    this.set('undoLog', []);
    this.set('checkedFolders', {});
    this.closeFullscreenPhoto();
    this.editingItemJob = null;
    this.set('multiJobIds', {});
    this.editingNode = null;
    this.selectedNode = null;
    this.activeConnection = null;
    this.activeSection = null;
    this.selectedDeliverablePhoto = null;
    this.selectedDeliverableNode = null;
    this.selectedDeliverableSection = null;

    if (this.$.welcomeDialog.opened && !this.createJobLoading) {
      // Close it unless the jobId is null
      if (this.job_id !== '') this.$.welcomeDialog.close();
      this.mapBase = 'hybrid';
      this.set('additionalMapOptions.styles', null);
    }
    if (this.$.zoomToLocationDialog.opened) {
      this.$.zoomToLocationDialog.close();
      this.mapBase = 'hybrid';
      this.set('additionalMapOptions.styles', null);
    }
    if (this.$.qcDialog && this.$.qcDialog.opened) {
      this.$.qcDialog.close();
    }
    if (this.$.warningsDialog && this.$.warningsDialog.opened) {
      this.$.warningsDialog.close();
    }
    if (this.$.pplInvoiceDialog && this.$.pplInvoiceDialog.opened) {
      this.$.pplInvoiceDialog.close();
    }
    this.cancelPromptAction();

    if (this.pageLoaded) {
      this.editing = null;
      this.zoomToJob = !this.loadedFromHash;
      // don't auto-lock by status if the job locking feature flag is on.  Instead, auto-lock based on the Job Locked metadata property
      if (!this.enabledFeatures.job_locking) this.viewPublishedChecked = !!this.status?.published;
      // if the job doesn't have a job locked property, default to it being unlocked
      else this.viewPublishedChecked = this.metadata?.job_locked ?? false;

      // Clear Context Layers
      this.searchContextLayers(null);
      if (this.zoomText != null && (this.zoomText.substring(0, 4) == 'APP_' || this.zoomText.substring(0, 4) == 'REL_')) {
        this.zoomText = '';
      }
      this.set('$.photoStream.style.display', 'none');
      this.set('$.photoStream.src', 'about:blank');
    }
  }

  async checkUserJobAccessStatus() {
    const jobId = this.job_id;
    const signedIn = this.signedIn;

    if (!jobId || !signedIn) return;

    // Check if the jobId has already changed because `jobIdChanged` is now a complex observer that runs whenever `jobId` or `signedIn` changes
    if (!this.jobIdAlreadyChanged) {
      this.jobIdAlreadyChanged = true;
      this.settingsLoadedFromSession = false;
    }

    const jobRef = FirebaseWorker.ref(`photoheight/jobs/${jobId}`);
    const userCanAccessJob = await jobRef
      .child(`name`)
      .once('value')
      .then((s) => s.exists())
      .catch(() => false);

    if (userCanAccessJob) {
      this.editingItemJob = jobId;
      this.loadJobMapLayer();
    } else {
      const jobKnownToBeDeleted = await jobRef
        .child('deleted')
        .once('value')
        .then((s) => Boolean(s.val()))
        .catch(() => false);
      if (jobKnownToBeDeleted) {
        KatapultDialog.alert(`This job has been deleted`, 'Job Deleted', 'var(--paper-red-500)');
      } else {
        KatapultDialog.alert('This job either does not exist, or you do not have permission to view it.', 'Job Not Found');
      }
    }
  }

  jobNameChanged() {
    if (this.jobName) {
      document.title = `${this.jobName} - ${this.config.firebaseData.name} Maps`;
    } else {
      document.title = `${this.config.firebaseData.name} Maps`;
    }
  }

  photoLabelsFromFirebaseChanged() {
    if (this.photoLabelsFromFirebase != null) {
      this.photoLabels = this.photoLabelsFromFirebase;
    }
  }

  photoLabelsChanged() {
    if (this.photoLabels == null) {
      this.photoLabels = { pole_tag: true };
      this.photoLabels[this.ordering_attribute] = true;
    }
    FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/photo_labels').set(
      this.photoLabels
    );
  }

  showError(event, detail, sender) {
    this.toast(event.message);
  }

  closeFullscreenPhoto(e) {
    this.fullscreenPhotoOpened = false;
    this.$.fullscreenPhotoViewer.stopAngleMeasurement();
    this.$.fullscreenPhoto.close();
    this.fullscreenPhoto = null;
    this.fullscreenPhotoSrc = null;
    var originalEvent = SquashNulls(e, 'detail', 'keyboardEvent');
    if (originalEvent) originalEvent.stopPropagation();
  }

  fullscreenPhotoChanged() {
    this.$.fullscreenPhotoViewer.stopAngleMeasurement();
  }

  getFullscreenButtons(fullscreenPhoto) {
    return fullscreenPhoto ? { angleMeasurement: true, filters: true } : {};
  }

  downloadSinglePhoto(e) {
    let altIsPressed = SquashNulls(e, 'detail', 'sourceEvent', 'altKey');
    if (this.fullscreenPhoto != null) {
      let photo = this.$.fullscreenPhotoViewer.photoData;
      if (this.$.fullscreenPhotoViewer.photoId[0] == '-') {
        this.downloadFile(photo.full_md5 != null && photo.url_full != null ? photo.url_full : photo.url_extra_large, photo.filename);
      } else {
        firebase
          .storage()
          .ref('photos/' + this.$.fullscreenPhotoViewer.photoId + '_full.webp')
          .getDownloadURL()
          .then((url) => {
            if (altIsPressed) {
              var xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = (event) => {
                var nameSplit = photo.filename.split('.');
                nameSplit.pop();
                this.downloadFile(URL.createObjectURL(xhr.response), nameSplit.join('.') + '.webp');
              };
              xhr.open('GET', url);
              xhr.send();
            } else {
              WebpToJpg(url, { mimeType: 'image/jpeg', quality: 0.75 }).then((blob) => {
                this.downloadFile(URL.createObjectURL(blob), photo.filename);
              });
            }
          });
      }
    }
  }

  downloadFile(url, name) {
    var a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  switchFullPhotoRight() {
    this.switchFullPhoto();
  }

  switchFullPhotoLeft() {
    this.switchFullPhoto(true);
  }

  switchFullPhoto(back) {
    if (this.unassocPhotos != null) {
      let index = this.unassocPhotos.indexOf(this.fullscreenPhoto);
      if (index + 1 < this.unassocPhotos.length && !back) {
        this.fullscreenPhoto = this.unassocPhotos[index + 1];
      }
      if (index - 1 >= 0 && back) {
        this.fullscreenPhoto = this.unassocPhotos[index - 1];
      }
    } else {
      let photos = Object.keys(this.$.infoPanel.itemData.photos);
      let index = photos.indexOf(this.fullscreenPhoto);
      if (index + 1 < photos.length && !back) {
        this.fullscreenPhoto = photos[index + 1];
      }
      if (index - 1 >= 0 && back) {
        this.fullscreenPhoto = photos[index - 1];
      }
    }
  }

  showFullscreenPanorama() {
    this.$.fullscreenPhotoViewer.showPanorama();
  }
  hideFullscreenPanorama() {
    this.$.fullscreenPhotoViewer.hidePanorama();
  }

  getUnassociatedPhotos() {
    if (this.job_id && this.signedIn) {
      FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photos`)
        .orderByChild('associated_locations')
        .equalTo(null)
        .on('value', (snapshot) => {
          if (snapshot.exists()) {
            let findUnassocPhotos = ToArray(snapshot.val());
            this.unassocPhotos = findUnassocPhotos.map((item) => item.$key);
          }
        });
    }
  }

  showStatusToast(event, detail, sender) {
    this.toast(detail.message, null, detail.duration);
  }

  displayToastMessage(e) {
    e.detail || e.detail || {};
    let message = SquashNulls(e, 'detail', 'detail', 'message') || SquashNulls(e, 'detail', 'message') || e.detail;
    if (typeof message === 'string') this.toast(message, e.detail.innerHTML, e.detail.duration);
  }

  togglePolylineEditing(connId, override) {
    this.$.katapultMap.togglePolylineEditing(connId, override);
  }

  toast(text, innerHTML, duration, showCloseButton, showSpinner) {
    this.$.toast.hide();
    this.toastText = text;
    // Set custom HTML inside of the toast
    if (innerHTML != null) this.$.toastBody.innerHTML = innerHTML;
    else this.set('$.toastBody.innerHTML', '');
    // Set the toast's duration (setting to 0 will leave it forever)
    if (duration != null) this.set('$.toast.duration', duration);
    else this.set('$.toast.duration', 6000);
    // Optionally show a close button
    if (duration == 0 && showCloseButton) this.showCloseToastButton = true;
    else this.showCloseToastButton = false;
    // Optionally show a loading spinner
    if (showSpinner) this.showToastSpinner = true;
    else this.showToastSpinner = false;
    this.$.toast.show();
  }

  closeToast() {
    this.$.toast.close();
    this.showCloseToastButton = false;
  }

  unassociationComplete(err) {
    this.toast(err || 'Done UnAssociating Photos');
    this.cancelPromptAction();

    // Allow a callback to be run when unassociation is done.
    if (this.unassociationCallback) {
      this.unassociationCallback();
      this.unassociationCallback = null;
    }
  }

  warningToast(text, innerHTML, duration) {
    if (!duration) {
      var duration = 6000;
    }
    this.warningToastText = text;
    if (innerHTML != null) {
      this.injectBoundHTML(innerHTML, this.$.warningToastBody);
    } else {
      this.set('$.warningToastBody.innerHTML', '');
    }
    if (duration) {
      this.set('$.warningToast.duration', duration);
    }
    this.$.warningToast.show();
  }

  warn(innerHTML, title) {
    this.injectBoundHTML(innerHTML, this.$.warnBody);
    this.warnTitle = title || 'Warning';
    this.$.warningDialog.open();
  }

  openModelEditor() {
    OpenPage('model-editor', {
      target: '_blank'
    });
  }

  getVisibleButtons(tier, showClearances, _sharing, selectedDeliverableNode, modelConfig, alternateDesignsConfig) {
    let buttons = {
      filters: true,
      historicalPhotoChooser: true,
      worstCaseSag: this.userGroup == 'katapult'
    };
    if (tier != 'katapult pro lite') {
      buttons.minimize = true;
    }

    if (_sharing == 'write') {
      buttons.chooser = true;
    }

    if (showClearances) {
      if (_sharing == 'write') {
        if (Object.keys(alternateDesignsConfig || {}).length) buttons.alternateDesigns = true;
        if (selectedDeliverableNode != null) {
          buttons.poleReplacement = true;
        }
      }
      if (SquashNulls(modelConfig, 'show_available_space')) {
        buttons.availableSpace = true;
      }
    }
    return buttons;
  }

  async openPhoto(e) {
    if (!e.timeStamp) {
      // Unassociated photos don't have a timestamp, ones from the toolbar do.
      this.getUnassociatedPhotos();
    } else {
      this.unassocPhotos = null;
    }
    if (e.detail.key) {
      this.fullscreenPhoto = e.detail.key;
    } else if (e.detail.url) {
      this.fullscreenPhotoSrc = e.detail.url;
    }
    this.fullscreenPhotoOpened = true;
    this.$.fullscreenPhoto.open();
    this.$.fullscreenPhoto.focus();
  }

  openPhotoFirst(e, options) {
    options = options || {};
    if (!e) e = { currentTarget: {} };
    else if (SquashNulls(e.detail, 'currentTarget') != '') e = e.detail;
    let queryParameters = '';
    if (katapultAuth.jobToken) {
      queryParameters = `?job_token=${katapultAuth.jobToken}`;
    }
    if (this.tier != 'katapult pro lite') {
      let linkingPhotoId = null;
      if (e.detail && e.detail.photoId) linkingPhotoId = e.detail.photoId;
      else linkingPhotoId = e.currentTarget.id;

      if (e.currentTarget.tagName == 'KATAPULT-PHOTO-VIEWER') {
        e.currentTarget.ignorePhotoClicks = 2;
      }
      let link = {};
      if (linkingPhotoId != null) {
        link.photo = linkingPhotoId;
        link.locked = this.viewPublishedChecked;
        link.jobId = this.job_id;
        if (e.currentTarget.property != null) {
          link.property = e.currentTarget.property;
          link.itemKey = e.currentTarget.itemKey;
        }
        if (e.currentTarget.action_type != null) {
          link.action_type = e.currentTarget.action_type;
        }
        if (e.currentTarget.marker_type != null) {
          link.marker_type = e.currentTarget.marker_type;
        }
        if (e.currentTarget.attribute_name != null) {
          link.attribute_name = e.currentTarget.attribute_name;
        }
        if (e.currentTarget.nodeId != null) {
          link.nodeId = e.currentTarget.nodeId;
        }
        if (e.currentTarget.setPowerSpec) {
          link.setPowerSpec = e.currentTarget.setPowerSpec;
        }
        if (e.currentTarget.workLocations) {
          link.workLocations = e.currentTarget.workLocations;
        }
        if (e.currentTarget.transferHeightData) {
          link.transferHeightData = e.currentTarget.transferHeightData;
        }
      } else if (options.view) {
        link.view = options.view;
      }
      if (options.properties) {
        Object.assign(link, options.properties);
      }
      if (Object.keys(link).length) {
        FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/user_data/${this.user.uid}/page_linking/`).set(link);
      }

      if (
        this.linkedWindow == null ||
        !this.linkedWindow.opener ||
        this.linkedWindow.opener.closed ||
        !this.linkedWindow.location.href.includes('/photos')
      ) {
        if (!this.dontLinkSpawnedWindows)
          this.linkedWindow = window.open('', 'photofirst', this.dontLinkSpawnedWindows ? 'noopener,toolbar,menubar' : '');
        if (
          this.linkedWindow == null ||
          this.linkedWindow.location.href === 'about:blank' ||
          !this.linkedWindow.location.href.includes('/photos')
        ) {
          let pathname = window.location.pathname.replace('/map/', '/photos/');
          let connectionId = options.connectionId || '';
          let view = options.view || '';
          if (connectionId) view = 'trace';
          let url = encodeURI(
            `${pathname}${queryParameters}#${this.job_id}/${linkingPhotoId}///${connectionId}/${view}///${options?.properties?.validationRoutine ?? ''}`
          );
          let windowOptions = this.dontLinkSpawnedWindows ? 'noopener,toolbar,menubar' : '';
          let windowFallback = {
            opener: {
              closed: false
            },
            focus: () => {}
          };
          this.linkedWindow = window.open(url, 'photofirst', windowOptions) || windowFallback;
        } else {
          this.linkedWindow.focus();
        }
      } else {
        this.linkedWindow.focus();
      }
    }
  }

  ////////////////////////////////////
  // warningsDialog Functions
  ////////////////////////////////////
  displayWarningsDialog(e) {
    // Get the dialog data from the event, or from the function parameter
    let dialogContent = SquashNulls(e, 'detail', 'dialogContent') || e;
    // Check if we have content to display
    if (dialogContent) {
      this.warningsDialogData = null;
      // Open the QC check dialog
      this.$.warningsDialog.open();
      // Get the QC results
      this.warningsDialogData = dialogContent;
    }
  }

  // This function is used to get an array of the lists
  // from the warningsDialogData object to display as sections in the dialog
  warningsDialog_GetResultsSections(warningsDialogData) {
    if (warningsDialogData) {
      // Loop through the keys in warningsDialogData to filter out the errors
      // list and only include the ones that have values that are Arrays.
      return Object.keys(warningsDialogData)
        .filter((x) => x != 'errors' && x != 'messagesList' && Array.isArray(warningsDialogData[x]))
        .map((x) => {
          let generalType = x.toLowerCase().split('warnings')[0];
          let item = { title: ToTitleCase(generalType) + ' Warnings' };
          // Make the generalWarnings match the other formats
          if (x == 'generalWarnings') {
            item.itemList = [{ warnings: warningsDialogData[x] }];
          } else {
            item.itemList = warningsDialogData[x];
            // Run through the itemList and apply a general type if it's missing
            item.itemList.forEach((x) => {
              if (!x.generalType) x.generalType = generalType;
            });
          }
          return item;
        });
    }
    return [];
  }

  warningsDialog_DisplayShowAllToggle(warningsDialogData, jobWarningReportsData) {
    if (warningsDialogData) {
      // Get the sections and see if any of them have have hidden warnings in them
      let showToggle = this.warningsDialog_GetResultsSections(warningsDialogData).some((section) =>
        this.warningsDialog_SectionHasInvisibleWarnings(section, jobWarningReportsData)
      );
      if (!showToggle) this.showAllDialogWarnings = false;
      return showToggle;
    }
  }

  warningsDialog_FindItem(e) {
    var item = SquashNulls(e, 'model', 'item') || {};
    if (item.key) {
      if (['node', 'pole'].includes(item.generalType)) {
        this.$.katapultMap.selectNode({ detail: { key: item.key, jobId: this.job_id } });
        this.zoomToNode(item.key);
      }
      if (['connection', 'span'].includes(item.generalType)) {
        this.$.katapultMap.selectConnection({
          detail: {
            jobId: this.job_id,
            key: item.key
          }
        });
        this.zoomToConnection(item.key);
      }
      if (['section', 'midspan'].includes(item.generalType) && item.connId) {
        this.$.katapultMap.selectSection({
          detail: {
            jobId: this.job_id,
            connId: item.connId,
            sectionId: item.key
          }
        });
        this.zoomToConnection(item.connId);
      }
    }
  }

  warningsDialog_GetWarningText(warning) {
    if (typeof warning === 'string') return warning;
    return warning.text || 'Error: Warning has incorrect format';
  }

  warningsDialog_SectionHasVisibleWarnings(sectionData, jobWarningReportsData, showAllDialogWarnings) {
    if (sectionData) {
      if (sectionData.itemList) {
        return sectionData.itemList.some((x) =>
          x.warnings.some((y) => this.warningsDialog_ShouldShowWarning(y, jobWarningReportsData, showAllDialogWarnings))
        );
      } else if (sectionData.warnings) {
        return sectionData.warnings.some((x) => this.warningsDialog_ShouldShowWarning(x, jobWarningReportsData, showAllDialogWarnings));
      }
    }
  }

  warningsDialog_SectionHasInvisibleWarnings(sectionData, jobWarningReportsData) {
    if (sectionData) {
      if (sectionData.itemList) {
        return sectionData.itemList.some((x) =>
          x.warnings.some((y) => !this.warningsDialog_ShouldShowWarning(y, jobWarningReportsData, false))
        );
      } else if (sectionData.warnings) {
        return sectionData.warnings.some((x) => !this.warningsDialog_ShouldShowWarning(x, jobWarningReportsData, false));
      }
    }
  }

  warningsDialog_ShouldShowWarning(warning, jobWarningReportsData, showAllDialogWarnings) {
    if (this.warningsDialogData) {
      if (showAllDialogWarnings == true || this.warningsDialog_CanHideWarning(warning) == false) return true;
      return !SquashNulls(jobWarningReportsData, this.warningsDialogData.reportType || 'general_qc', 'hidden_warnings', warning.key);
    }
  }

  warningsDialog_GetWarningTextStyle(warning, jobWarningReportsData) {
    if (this.warningsDialogData) {
      if (SquashNulls(jobWarningReportsData, this.warningsDialogData.reportType || 'general_qc', 'hidden_warnings', warning.key))
        return 'color: var(--paper-grey-400);';
    }
  }

  warningsDialog_CanHideWarning(warning) {
    return typeof warning === 'object' && warning.key;
  }

  warningsDialog_GetHideIcon(warning, jobWarningReportsData) {
    if (this.warningsDialogData) {
      if (SquashNulls(jobWarningReportsData, this.warningsDialogData.reportType || 'general_qc', 'hidden_warnings', warning.key))
        return 'visibility';
      return 'visibility_off';
    }
  }

  warningsDialog_ToggleHideWarning(e) {
    let warningKey = SquashNulls(e, 'model', 'warning', 'key');
    if (this.warningsDialogData && warningKey) {
      // Set the default value to 'hidden'
      let newValue = true;
      // See if the warning is already hidden in the jobWarningReportsData
      let reportType = this.warningsDialogData.reportType || 'general_qc';
      if (SquashNulls(this.jobWarningReportsData, reportType, 'hidden_warnings', warningKey)) newValue = null;
      FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/warning_reports/${reportType}/hidden_warnings/${warningKey}`).set(newValue);
    }
  }

  masterLocationDirectoryChanged(e) {
    let selectedDirectory = e.detail.value;
    if (selectedDirectory) {
      let selectedIndex = e.model.index;
      if (selectedIndex != null && selectedDirectory) {
        // update a local copy of the array of mlds, then use Polymer's set to notify the changes to the dom
        let currentSelectedDirectories = [...this.selectedMLDs];
        currentSelectedDirectories[selectedIndex] = selectedDirectory;
        this.set('selectedMLDs', currentSelectedDirectories);
      }
    }
  }

  removeMasterLocationDirectory(e) {
    let selectedIndex = e.model.index;
    if (selectedIndex != null) {
      // update a local copy of the array of mlds, then use Polymer's set to notify the changes to the dom
      let currentSelectedDirectories = [...this.selectedMLDs];
      currentSelectedDirectories.splice(selectedIndex, 1);
      this.set('selectedMLDs', currentSelectedDirectories);
    }
  }

  masterLocationDirectorySelected(e) {
    // only run this code if we can select multiple master location directories.  Otherwise, do nothing
    if (this.multiSelectMLD) {
      let selectedDirectory = e.detail.value;
      if (selectedDirectory) {
        // update a local copy of the array of mlds, then use Polymer's set to notify the changes to the dom
        let currentSelectedDirectories;
        if (this.selectedMLDs) currentSelectedDirectories = [...this.selectedMLDs];
        else currentSelectedDirectories = [];

        currentSelectedDirectories.push(selectedDirectory);
        this.set('selectedMLDs', currentSelectedDirectories);
        setTimeout(() => {
          this.selectedMLD = null;
        });
      }
    }
  }

  ////////////////////////////////////
  // End warningsDialog Functions
  ////////////////////////////////////

  /////////// button_functions below \/\/\/\/\/\/\/\\/\/\/\/\/\/\/\/

  async _button_star_best_meter() {
    this.cancelPromptAction();
    this.toast('Starring best meter connections...');
    const { starBestMeterConnection } = await import('./button_functions/star_best_meter_connection.js');
    await starBestMeterConnection(this.job_id, this.nodes, this.connections, this.jobStyles);
    this.toast('Done starring best meter connections.');
  }

  async _button_bulk_pole_loading(e) {
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = { nodes: true };
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around items to select them. Right click to delete polygon points.',
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Select All',
          callback: () => this.runBulkPoleLoading(),
          attributes: { style: 'background-color: var(--paper-grey-200); padding: 12px; margin:0 5px' }
        },
        {
          title: 'Continue',
          callback: () => this.runBulkPoleLoading(this.$.katapultMap.multiSelectedNodes),
          attributes: {
            style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); padding: 12px; margin:0 5px'
          }
        }
      ]
    });
  }

  async runBulkPoleLoading(nodeIds) {
    this.cancelPromptAction();
    const { runBulkPoleLoading } = await import('./button_functions/bulk_pole_load.js');
    runBulkPoleLoading(nodeIds, this.job_id, this.jobCreator, this.nodes, this.otherAttributes, this.jobStyles, this.modelDefaults);
  }

  async _button_update_cus_on_all_jobs() {
    this.cancelPromptAction();
    const { updateCusOnAllJobs } = await import('./button_functions/update_cus_on_all_jobs.js');
    try {
      await updateCusOnAllJobs(this.userGroup, {
        onProgress: async (progress) => {
          this.progressText = progress.message;
          this.progressPercent = progress.percent;
          this.$.progressToast.notifyResize();
          this.$.progressToast.show();
        }
      });
      KatapultDialog.alert({
        body: `Successfully Updated CUs on all Jobs`,
        dialog: {
          draggable: true,
          title: 'Successful CU Update',
          color: 'var(--secondary-color)'
        }
      });
    } catch (e) {
      KatapultDialog.alert({
        body: `Error Updating CUs: ${e.message}`,
        dialog: {
          closeOnEscape: false,
          closeOnOutsideClick: false,
          draggable: true,
          title: 'Failed CU Update',
          color: 'var(--paper-red-500)'
        }
      });
    }
    setTimeout(() => {
      this.$.progressToast.close();
    }, 8000);
  }

  async _button_api_test(e) {
    const apiPath = 'https://k8sdev.lgeenergy.int/lgeku/api-gateway/authenticate';
    const payload = await FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/models/api_payload`)
      .once('value')
      .then((s) => s.val());
    const authToken = await fetch(apiPath, {
      method: 'GET',
      headers: payload
    }).then((res) => res.text());
    console.log(authToken);
  }

  async _button_update_one_touch_summary(e) {
    let update = {};
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    let connLookup = GetConnectionLookup(this.nodes, this.connections);
    for (let nodeId in this.nodes) {
      if (
        this.modelDefaults.pole_node_types.includes(PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute))
      ) {
        let connPhotos = connLookup[nodeId]
          .map((conn) => Object.values(conn.connData.sections || {}).map((section) => photos[GetMainPhoto(section.photos)]))
          .flat(1);
        await CalcOneTouchSummary(
          this.job_id,
          this.nodes[nodeId],
          nodeId,
          photos[GetMainPhoto(this.nodes[nodeId].photos)],
          connPhotos,
          {
            traces: this.traces,
            otherAttributes: this.otherAttributes,
            jobStyles: this.jobStyles,
            newAttacher: this.attacherName ?? this.metadata?.attachment_owner
          },
          { update }
        );
      }
    }
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
    this.toast('One Touch Summary Updated');
    this.cancelPromptAction();
  }

  async _button_calc_total_house_count(e) {
    this.toast('Calculating total house count...');
    // Build a connection lookup.
    const connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);
    // Initialize an update object.
    const update = {};
    // Loop over poles and sum up the number of suites of connected service locations.
    for (const nodeId in this.nodes) {
      // Get the node.
      const node = this.nodes[nodeId];
      // Skip any nodes that are null.
      if (node == null) continue;
      // Get the the node's node type.
      const nodeType = PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute);
      // Define a list of node types we want this to work for.
      const nodeTypes = [
        ...this.modelDefaults.pole_node_types,
        'Proposed Pedestal',
        'Demarc Point (Internal)',
        'Demarc Point (External)',
        'Existing Pedestal',
        'Existing Handhole',
        'Proposed Handhold'
      ].map((x) => x.toLowerCase());
      // Make sure node is a pole.
      if (nodeTypes.includes(nodeType?.toLowerCase?.())) {
        // Initialize total house count to zero.
        let totalHouseCount = 0;
        // Get the connections for this node.
        const connections = connectionLookup[nodeId] || [];
        // Get the opposite node for each connection.
        for (const connection of connections) {
          // Get other node.
          const toNode = this.nodes[connection?.toNodeId];
          // Skip any nodes that are null.
          if (toNode == null) continue;
          // Get the other node's node type.
          const toNodeType = PickAnAttribute(toNode.attributes, this.modelDefaults.node_type_attribute);
          // Make sure the node is a service location.
          if (toNodeType?.toLowerCase?.() == 'service location') {
            // Get the service location's number of suites and parse it as a number.
            const numberOfSuites = Number(PickAnAttribute(toNode.attributes, 'number_of_suites'));
            if (isNaN(numberOfSuites))
              console.warn(`Got NaN when parsing number_of_suites attribute for node with ID ${connection.toNodeId}`);
            else if (numberOfSuites < 0)
              console.warn(`Got a negative value for number_of_suites attribute for node with ID ${connection.toNodeId}`);
            else totalHouseCount += numberOfSuites;
          }
        }
        // Add total house count to update.
        update[`nodes/${nodeId}/attributes/total_house_count/button_added`] = totalHouseCount.toString();
      }
    }
    // Write update to firebase.
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
    this.toast('Finished calculating total house count!');
  }

  async _button_import_overlapping_notes(e, options = {}) {
    let confirmFinished = new Promise(async (resolve, reject) => {
      // prompt the user to pick a master location directory
      const mldList = await MLD.getDirectoryList(this.userGroup, { includeSharedDirectories: true });
      for (const [id, { _sharing_record }] of Object.entries(mldList)) {
        // For each directory, if it is shared, add a link icon to the item
        if (_sharing_record) mldList[id].icons = [{ icon: 'link', size: 24 }];
      }
      this.mldList = mldList;

      // Set the default values for the dialog
      this.multiSelectMLD = true;
      this.confirmDialogPromptForUniqueIds = true;
      this.useUniqueIdsForMLD = false;

      const resetConfirmDialog = () => {
        this.multiSelectMLD = false;
        this.confirmDialogPromptForUniqueIds = false;
        this.useUniqueIdsForMLD = false;
      };

      this.confirm(
        'Import Overlapping Notes',
        'You can import overlapping notes from jobs in one or more Master Location Directories',
        'Import',
        options.cancelButtonText ?? 'Cancel',
        'color:white; background-color:var(--secondary-color);',
        'masterLocationDirectoryChooser',
        // Confirm callback
        async () => {
          // If the user didn't select any MLDs, don't do anything
          if (!this.selectedMLDs?.length) {
            resetConfirmDialog();
            return;
          }

          const locationDirectories = this.selectedMLDs.map((mldId) => mldList[mldId]);
          this.toast('Importing Overlapping Notes...', null, Infinity);
          const update = {};
          try {
            const { AddOverlappingNotes } = await import('../../modules/AddOverlappingNotes.js');
            const results = await AddOverlappingNotes(this.job_id, locationDirectories, {
              useUniqueIds: this.useUniqueIdsForMLD,
              update,
              nodes: this.nodes,
              userGroup: this.userGroup,
              jobStyles: this.jobStyles,
              onProgress: (message) => this.toast(message, null, Infinity)
            });

            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
            this.toast('Overlapping Notes Imported!', null, 6000);
            this.displayWarningsDialog({ title: 'Overlapping Notes Import Complete', messagesList: results });
          } catch (error) {
            this.toast('Overlapping Notes Import Error: ' + error.message, null, 6000);
          }

          this.cancelPromptAction();
          resetConfirmDialog();
          resolve();
        },
        // Cancel callback
        () => {
          resetConfirmDialog();
          resolve();
        }
      );
    });

    return confirmFinished;
  }

  async _button_update_jobs_for_the_week(e) {
    // disable button click until the update finishes
    let updateButton = e.currentTarget;
    updateButton.style.pointerEvents = 'none';
    console.time('fullUpdate');
    const failedUpdates = await JobDashboard.updateJobSummary(
      this.job_id,
      this.userGroup,
      {
        onProgress: (progress) => {
          this.progressText = progress.message;
          this.progressPercent = progress.percent;
          this.$.progressToast.notifyResize();
          this.$.progressToast.show();
        }
      },
      { users: this.users }
    );
    console.timeEnd('fullUpdate');
    if (failedUpdates.length > 0) {
      console.log('failedUpdates', failedUpdates);
      this.$.progressToast.close();
      KatapultDialog.alert({
        body: '• ' + failedUpdates.map((error) => error.message).join('\n• '),
        dialog: {
          closeOnEscape: false,
          closeOnOutsideClick: false,
          draggable: true,
          title: 'Failed Dashboard Update',
          color: 'var(--paper-red-500)'
        }
      });
    } else {
      setTimeout(() => {
        this.$.progressToast.close();
      }, 8000);
    }
    updateButton.style.pointerEvents = 'auto';
    this.cancelPromptAction();
  }

  async _button_assign_pe_review(e) {
    this._button_assign_review_attribute(e);
  }

  async _button_assign_review_attribute(e) {
    let model = SquashNulls(this.activeCommandModel, 'models') || {};
    this.toast(`Assigning ${CamelCase(model.attribute)} to all MR poles`, null, 0, false, true);
    this.createWebWorker('assign_review_attribute', 'assign_review_attribute', [
      model,
      this.nodes,
      this.jobCreator,
      this.userGroup,
      this.jobStyles
    ]);
    this.cancelPromptAction();
  }

  async finishAssignReview(jobId, update) {
    // run the update statement with a FirebaseWorker so that it tracks the changes with attribute tracking
    await FirebaseWorker.ref(`photoheight/jobs/${jobId}`).update(update);
  }

  showTrainingReview(feedbackConfig, sharing) {
    return feedbackConfig && sharing == 'write';
  }

  async fillInActionsForFeedbackReview() {
    this.$.toolbar.closeHelpMenu();
    if (!this.job_id) {
      this.toast('Please select a job to request to be reviewed.');
      return;
    }
    let actionItems = [];
    for (let key in this.feedbackConfig.default_actions) {
      this.feedbackConfig.default_actions[key].$key = key;
      actionItems.push(this.feedbackConfig.default_actions[key]);
    }
    this.actionItems = actionItems;
    this.confirm(
      '',
      'Katapult Engineering staff will review this job at a rate of $180/hour to help your team learn all the ins and outs of the platform.',
      'Submit',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'fillInActions',
      async () => {
        this.toast('Setting users and sending job to Katapult for Review.');
        // Share the job with Katapult
        let update = {};
        update['photoheight/jobs/' + this.job_id + '/sharing/katapult'] = 'read';
        update['photoheight/company_space/' + this.jobCreator + '/companies_with_model_access/katapult'] = true;
        update['photoheight/company_space/' + this.userGroup + '/contacts/katapult'] = true;
        update['photoheight/company_space/katapult/contacts/' + this.userGroup] = true;
        update[`photoheight/company_space/${this.userGroup}/pages/home`] = { order: 10, page: 'home', title: 'HOME' };
        if (this.project_folder != null) {
          var encodedPath = this.project_folder.split('/').map(FirebaseEncode.encode).join('/folders/');
          update['photoheight/company_space/katapult/project_folders/' + encodedPath + '/jobs/' + this.job_id] = true;
        }
        let jobPermissions = await FirebaseWorker.ref('photoheight/job_permissions/' + this.userGroup + '/jobs/' + this.job_id)
          .once('value')
          .then((s) => s.val());
        jobPermissions.date_shared = firebase.database.ServerValue.TIMESTAMP;
        jobPermissions.permission = 'read';
        jobPermissions.status = 'active';
        await UpdateJobPermissions(
          this.job_id,
          this.userGroup,
          {},
          {
            skipAddingMainCompanyToSharing: true,
            sharing: { katapult: true },
            update
          }
        );
        for (let uid in this.feedbackConfig.addresses_to_notify) {
          update[`user_groups/${uid}/individual_permissions/${this.userGroup}`] = { read: true, write: false };
        }
        await FirebaseWorker.ref().update(update);

        // Fill in the actions
        for (let nodeId in this.nodes) {
          this.actionItems.forEach((action) => {
            if (action.uid) {
              FirebaseWorker._recordAction(
                {
                  nodeId,
                  jobId: this.job_id,
                  $key: action.$key,
                  action_name: action.action_name,
                  order: action.order,
                  value: 'Marked For Review'
                },
                {
                  actionTrackingCompanyId: this.userGroup,
                  suppressEvents: true,
                  overwrites: { uid: action.uid }
                }
              );
            }
          });
        }
        let companyName = await FirebaseWorker.ref(`photoheight/companies/${this.userGroup}/name`)
          .once('value')
          .then((s) => s.val());
        // Send Email to Katapult
        let emailObject = {
          addresses: [...Object.values(this.feedbackConfig.addresses_to_notify || {}), 'support@katapultengineering.com'],
          subject: 'Training Feedback Review Requested',
          body: `Katapult Review Team!

${this.user.email} has requested Training via Feedback Review for ${companyName}'s job: "${this.jobName}".

They provided the following notes or areas of focus for the review:

"${this.feedbackTrainingNotes}"

Please switch your company to ${companyName} to review and leave feedback on this job ${window.location.href}

Thanks!

Katapult Pro
`,
          group_tag: 'internal'
        };
        let emailReqKey = FirebaseWorker.ref('/photoheight/server_requests/email/requests/').push(emailObject).key;
        FirebaseWorker.ref('/photoheight/server_requests/email/responses/' + emailReqKey).on(
          'value',
          function (snapshot) {
            var response = snapshot.val();
            if (response != null) {
              snapshot.ref.remove();
              this.toast(
                'Katapult Engineering staff will review the has been notified of your request and will start feedback on your job shortly!'
              );
            }
          }.bind(this)
        );
      },
      null,
      null,
      { confirmDialogTitle: 'Training Review Feedback' }
    );
  }

  async _button_fill_in_actions(e) {
    this.toast('Trying to fill in actions. This will take a few moments!');
    // Check that there are action tracking models available
    if (!FirebaseWorker?.actionTrackingModels?.length) {
      return;
    }
    try {
      let job = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`)
        .once('value')
        .then((s) => s.val());
      await FirebaseWorker._actionTracking(`photoheight/jobs/${this.job_id}`, job, {
        suppressEvents: true,
        overwrites: { uid: 'unknown' }
      });
      // Handle photofirst
      let nodes = ToArray(job.nodes);
      for (const node of nodes) {
        let firstEditors = [];
        let photosEditors = Object.keys(node.photos || {}).map((key) => Path.get(job, `photos.${key}.photofirst_data._editors`));
        // Get the list of editors, sort them by their first edit timestamp, and retrieve the first editor
        for (const editors of photosEditors) {
          if (editors) {
            let firstEditor = Object.entries(editors)
              .sort((a, b) => a[1] - b[1])
              .map((a) => a[0])[0];
            let editorIndex = firstEditors.findIndex((a) => a.uid == firstEditor);
            if (editorIndex >= 0) firstEditors[editorIndex].count += 1;
            else
              firstEditors.push({
                uid: firstEditor,
                count: 1,
                timestamp:
                  typeof editors[firstEditor] == 'boolean'
                    ? firebase.firestore.FieldValue.serverTimestamp()
                    : new Date(editors[firstEditor])
              });
          }
        }
        // Figure out who did the most photo-firsting on this node and log it
        if (firstEditors.length) {
          firstEditors.sort((a, b) => b.count - a.count);
          let topEditor = firstEditors[0];
          if (firstEditors.length > 1) {
            let topEditors = firstEditors.filter((a) => a.count == topEditor.count);
            topEditor = topEditors[Math.floor(Math.random() * topEditors.length)];
          }
          let firestoreRef = firebase.firestore().collection(`companies/${this.userGroup}/action_tracking`);
          // TODO-WARN: Hard coding of id is not a long-term solution
          let node_id = node.$key.slice();
          firestoreRef
            .where('job_id', '==', this.job_id)
            .where('action_id', '==', '-McL2dSwnbOrpU5TTiNY')
            .where('node_id', '==', node_id)
            .get()
            .then((s) => {
              if (s.empty) {
                firestoreRef.add({
                  uid: topEditor.uid,
                  timestamp: topEditor.timestamp,
                  time_of_association: firebase.firestore.FieldValue.serverTimestamp(),
                  job_id: this.job_id,
                  node_id: node_id,
                  action_name: 'PhotoFirst',
                  action_id: '-McL2dSwnbOrpU5TTiNY',
                  order: 3
                });
              }
            });
        }
      }
      this.toast('Fill in actions completed successfully!');
    } catch (error) {
      console.error(error);
      this.toast('ERROR: Fill in actions hit an error, check the console');
    }
    this.cancelPromptAction();
  }

  async _button_import_overlapping_nodes(e) {
    // prompt the user to select a master location directory
    this.mldList = (await MLD.getDirectoryList(this.userGroup, { includeSharedDirectories: true })) || {};
    this.multiSelectMLD = true;
    this.confirmDialogPromptForUniqueIds = true;
    this.useUniqueIdsForMLD = false;

    const resetConfirmDialog = () => {
      this.multiSelectMLD = false;
      this.confirmDialogPromptForUniqueIds = false;
      this.useUniqueIdsForMLD = false;
    };

    // Open the confirm dialog
    this.confirm(
      'Import Overlapping Nodes',
      'You can import overlapping nodes from jobs in one or more Master Location Directories',
      'Select Nodes...',
      'Cancel',
      'color:white; background-color:var(--secondary-color);',
      'masterLocationDirectoryChooser',
      async () => {
        if (this.selectedMLDs.length != 0) {
          const selectedDirectories = this.selectedMLDs.map((id) => this.mldList[id]);
          await import('./button_functions/overlappingNodesImporter');
          this.$.overlappingNodesImporter.begin(selectedDirectories, this.useUniqueIdsForMLD);
        }
        resetConfirmDialog();
      },
      () => resetConfirmDialog()
    );
  }

  async _button_transfer_attachers(e) {
    if (this.job_id) {
      this.toast('Starting transfer...');
      let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
      let update = {};
      // Loop through all of the nodes in the job
      for (let nodeId in this.nodes) {
        let companies = [];
        // Get the node's main photo
        let node = this.nodes[nodeId];
        // get markers on this node
        let mainPhotoKey = GetMainPhoto(node.photos);
        let mainPhotoData = SquashNulls(photos, mainPhotoKey, 'photofirst_data');
        // Get all of the markers on the main photo
        let markers = DataViews.help.getMarkers(mainPhotoData || {}, this.traces, { includeAllMarkerTypes: true });
        // Make a list of attachers
        let newAttachers = {};
        for (let i = 0; i < markers.length; i++) {
          let company = SquashNulls(this.traces, markers[i]._trace, 'company');
          if (company && !companies.includes(company)) {
            companies.push(company);
            newAttachers[FirebaseWorker.ref('/').push().key] = company;
          }
        }
        update[`nodes/${nodeId}/attributes/attachers`] = newAttachers;
        // Also set the local node data for the geohash to work correctly
        Path.set(node, 'attributes.attachers', newAttachers);
        // Update geohash
        GeofireTools.setGeohash('nodes', node, nodeId, this.jobStyles, update);
      }
      // Run through the list of companies and update the attachers list
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
      this.toast('Transfer attachers complete');
    } else {
      this.toast('No job is selected');
    }
  }

  async _button_set_pmr_annotation(e) {
    this.$.confirmDialog.noCancelOnEscKey = true;
    this.$.confirmDialog.noCancelOnOutsideClick = true;
    // check to see that we are editing a node, and make sure that its a pole
    let node = this.nodes[this.editingNode];
    if (!node || !this.modelDefaults.pole_node_types.includes(PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute))) {
      this.toast('Select a pole to generate an annotation for');
      return;
    }

    // gather existing power mr annotation on this node
    let existingAnnotation = PickAnAttribute(node.attributes, 'power_mr_annotation');
    this.existingPowerMakeReadyAnnotation = existingAnnotation;

    // generate proposed power make ready annotation, based off of a particular node
    let pmrAnnotationData = await this.generatePmrAnnotation(node);

    // SET VARIABLES FOR DIALOG
    let dialogTitle = 'Review PMR Annotation';
    let confirmButtonText = 'Commit';
    let cancelButtonText = 'Cancel';
    let dialogBody = '';
    let dialogId = 'setPmrAnnotation';
    this.powerMakeReadyAnnotation = pmrAnnotationData.annotation;

    let confirmCallback = () => {
      // set power annotation for this node
      FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes/${this.editingNode}/attributes/power_mr_annotation/`).set({
        button_added: this.powerMakeReadyAnnotation
      });
      this.$.confirmDialog.noCancelOnEscKey = false;
      this.$.confirmDialog.noCancelOnOutsideClick = false;
    };

    // set variables to display warnings
    if (pmrAnnotationData.warnings.length) {
      dialogTitle = 'PMR Annotation Warnings';
      dialogBody = pmrAnnotationData.warnings.join('\r\n');
      confirmButtonText = 'Okay';
      cancelButtonText = null;
      confirmCallback = null;
      dialogId = null;
    }

    // open dialog with variables set above
    this.confirm(dialogTitle, dialogBody, confirmButtonText, cancelButtonText, '', dialogId, confirmCallback, () => {
      this.$.confirmDialog.noCancelOnEscKey = false;
      this.$.confirmDialog.noCancelOnOutsideClick = false;
    });
  }

  // Used to generate the power mr annotation attribute for a pole
  async generatePmrAnnotation(node) {
    let annotation = ``;
    let proposedPoleHeight = null;
    let warnings = [];
    let proposedPoleClass = null;
    let lowPowerType = null;
    let topComHeight = 0;
    let buriedDepth = null;
    let clearanceNeeded = 4;

    let poleHeight = PickAnAttribute(node.attributes, 'pole_height');
    let poleClass = PickAnAttribute(node.attributes, 'pole_class');
    let proposedPoleSpec = PickAnAttribute(node.attributes, 'proposed_pole_spec');
    if (proposedPoleSpec != null) {
      proposedPoleHeight = proposedPoleSpec.split(' ')[0].split('-')[0];
      proposedPoleClass = proposedPoleSpec.split(' ')[0].split('-')[1];
      buriedDepth = getBuriedDepth(proposedPoleHeight);
    } else warnings.push('Missing proposed pole spec...');

    let mainPhoto = getMainPhoto(node);
    let constructionSpec = PickAnAttribute(node.attributes, 'PPL_construction_spec');
    let porcelainCutoutExists = PickAnAttribute(node.attributes, 'porcelain_fuse_cutout_present');

    function getBuriedDepth(h) {
      if (h < 45) return 6;
      else if (h < 50) return 6.5;
      else if (h < 55) return 7;
      else if (h < 60) return 7.5;
      else if (h < 65) return 8;
      else if (h < 70) return 8.5;
      else if (h < 75) return 9;
      return '[unknown buried depth]';
    }

    function getMainPhoto(n) {
      let photos = node.photos;
      for (let photoKey in photos) {
        if (photos[photoKey] === 'main' || photos[photoKey].association === 'main') return photoKey;
      }
      warnings.push('No main photo set');
    }

    function classifyByOwner(photoData, type, traces) {
      let wires = [];
      let arm = photoData.arm;
      let equipment = photoData.equipment;
      let insulator = photoData.insulator;
      let wire = photoData.wire;
      if (arm != null) {
        for (let a in arm) {
          TraverseMarkers(arm[a]._children, (child, path, property, itemKey) => {
            if (property == 'wire') {
              let temp = {};
              let traceId = child._trace;
              let wireType = traces[traceId].cable_type;
              let height = arm[a]._measured_height || arm[a]._manual_height;
              let company = traces[traceId].company;
              temp.company = company;
              if (type == 'PPL' && company == 'PPL Company' && wireType != 'Power Guy') {
                temp._type = wireType;
                temp.height = (height + (parseFloat(arm[a].mr_move) || 0)) / 12;
                wires.push(temp);
              } else if (type == 'com' && company != 'PPL Company') {
                temp.height = (height + (parseFloat(arm[a].mr_move) || 0)) / 12;
                wires.push(temp);
              }
            }
          });
        }
      }
      if (equipment != null) {
        for (let e in equipment) {
          let temp = {};
          let traceId = equipment[e]._trace;
          let company = traces[traceId].company;
          temp.company = company;
          let height = equipment[e]._measured_height || equipment[e]._manual_height;
          let equipmentType = equipment[e].equipment_type;
          if (type == 'PPL' && company == 'PPL Company') {
            if (equipmentType == 'riser') equipmentType = equipment[e].riser_type + ' ' + equipmentType;
            if (equipmentType == 'drip_loop') {
              if (equipment[e].drip_loop_spec == 'Streetlight') temp.drip_loop_type = 'STLT';
              else temp.drip_loop_type = equipment[e].drip_loop_spec;
            }
            temp._type = equipmentType;
            temp.height = (height + (parseFloat(equipment[e].mr_move) || 0)) / 12;
            wires.push(temp);
          } else if (type == 'com' && company != 'PPL Company' && equipmentType != 'riser') {
            temp.height = (height + (parseFloat(equipment[e].mr_move) || 0)) / 12;
            wires.push(temp);
          }
        }
      }
      if (insulator != null) {
        for (let i in insulator) {
          TraverseMarkers(insulator[i]._children, (child, path, property, itemKey) => {
            if (property == 'wire') {
              let temp = {};
              let traceId = child._trace;
              let wireType = traces[traceId].cable_type;
              let height = insulator[i]._measured_height || insulator[i]._manual_height;
              let company = traces[traceId].company;
              temp.company = company;
              if (type == 'PPL' && company == 'PPL Company' && wireType != 'Power Guy') {
                if (wireType == 'Bundled Primary') wireType = 'PAC';
                temp._type = wireType;
                temp.height = (height + (parseFloat(insulator[i].mr_move) || 0)) / 12;
                wires.push(temp);
              } else if (type == 'com' && company != 'PPL Company') {
                temp.height = (height + (parseFloat(insulator[i].mr_move) || 0)) / 12;
                wires.push(temp);
              }
            }
          });
        }
      }
      if (wire != null) {
        for (let w in wire) {
          let temp = {};
          let traceId = wire[w]._trace;
          let wireType = traces[traceId].cable_type;
          let height = wire[w]._measured_height || wire[w]._manual_height;
          let company = traces[traceId].company;
          temp.company = company;
          if (type == 'PPL' && company == 'PPL Company' && wireType != 'Power Guy') {
            temp._type = wireType;
            temp.height = (height + (parseFloat(wire[w].mr_move) || 0)) / 12;
            wires.push(temp);
          } else if (type == 'com' && company != 'PPL Company') {
            temp.height = (height + (parseFloat(wire[w].mr_move) || 0)) / 12;
            wires.push(temp);
          }
        }
      }
      return wires;
    }

    // get main photo data to finish building the annotation
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photos/${mainPhoto}`).once('value', async (s) => {
      let photoData = s.val();
      let photofirstData = photoData.photofirst_data;
      let pplMarkers = classifyByOwner(photofirstData, 'PPL', this.traces);
      let comMarkers = classifyByOwner(photofirstData, 'com', this.traces);
      pplMarkers.sort((a, b) => {
        return a.height - b.height;
      });
      comMarkers.sort((a, b) => {
        return b.height - a.height;
      });
      if (pplMarkers[0] != null) lowPowerType = pplMarkers[0]._type;
      if (lowPowerType != null) {
        lowPowerType = lowPowerType.replace(/_/g, ' ').toUpperCase();
        if (lowPowerType == 'CUTOUT ARRESTOR') lowPowerType = 'TFC';
        if (lowPowerType == 'DRIP LOOP') {
          lowPowerType = pplMarkers[0].drip_loop_type.toUpperCase() + ' ' + 'D/L';
          if (pplMarkers[0].drip_loop_type == 'STLT') clearanceNeeded = 1;
        }
        if (lowPowerType == 'STREET LIGHT') {
          lowPowerType = 'STLT BRKT';
          clearanceNeeded = 1;
        }
      }
      if (comMarkers[0] != null) topComHeight = Math.ceil(comMarkers[0].height);

      if (
        warnings.length == 0 &&
        poleHeight != null &&
        poleClass != null &&
        proposedPoleHeight != null &&
        proposedPoleClass != null &&
        buriedDepth != null
      ) {
        annotation += `> REPLACE ${poleHeight}-${poleClass} POLE WITH ${proposedPoleHeight}-${proposedPoleClass} POLE USING ${buriedDepth}' BURIED DEPTH`;
        if (porcelainCutoutExists === true) annotation += `\n> REPLACE TFC`;
        if (lowPowerType != null && topComHeight != 0)
          annotation += `\n> ENSURE ${lowPowerType} IS AT OR ABOVE ${topComHeight + clearanceNeeded}'-0"`;
        if (constructionSpec != null) {
          annotation += `\n[ SEE ${constructionSpec} ]`;
        }
      }
    });

    // return built annotation back to calling function
    return { annotation, warnings };
  }

  async _button_set_attachers_from_ppl_data(e) {
    // confirm with the user that they would like to run this button
    const res = await DialogService.confirm({
      title: 'Change Attachment Owner',
      message: litHtml`Running this button will update <b>Wires on Record</b> and <b>Other Attachments On Record</b> for all PPL owned poles in the job. Do you wish to continue?`,
      button: { text: 'Continue' },
      attributes: { 'max-width': 400 }
    });

    // If not confirmed, don't run the function
    if (!res.hasAttribute('dialog-confirm')) return;

    let update = {};
    let buttonModels = this.activeCommandModel.models;
    this.toast('Setting Attacher Information', null, 0);

    async function getPoleAttachments(node) {
      // check to make sure we have a ppl tag
      let tags = SquashNulls(node.attributes, 'pole_tag');
      let pplTag = Object.values(tags).find((x) => x.company == 'PPL Company' || x.company == 'PPL');
      if (pplTag) {
        // get attachments on record for this pole
        let attachments = await firebase
          .app()
          .database('https://ppl-kws-utility-data.firebaseio.com')
          .ref(`utility_info/ppl/attachments/${pplTag?.tagtext}`)
          .once('value')
          .then((s) => s.val());
        // loop through all attachments on this pole
        let attachmentsArray = [];
        for (let key in attachments) {
          // the attachments start with id, so only grab those entries
          if (key.startsWith('id')) {
            for (let i = 0; i < attachments[key].length; i++) {
              attachmentsArray.push({
                order: attachments[key][i].order,
                company: attachments[key][i].name,
                attach_type: attachments[key][i].attach_type
              });
            }
          }
        }
        // sort by attachment order
        return attachmentsArray.sort((a, b) => a.order - b.order);
      }
      // return an empty array, because we this pole didn't have a ppl tag
      return [];
    }

    // Build a company to attacher lookup for companies that didn't match an entry in the attacher list.
    const companyToAttacherLookup = {};

    let getCableTypeFromAttachType = async (attachment) => {
      let attachType = attachment?.attach_type;
      let cableType = buttonModels.attachment_lookup?.[attachType];

      // cases where we need to use the default_cable_type property in the company attribute
      // Cable, Communications conductor, or if the attach_type is blank
      if (cableType?.lookup || attachType == '') {
        // Try to find an attacher that matches the attachment's company.
        const companyAttribute = this.otherAttributes.company;
        const attachers = companyAttribute?.picklists?.attachers;
        let attachmentCompany = companyToAttacherLookup[attachment?.company] ?? attachment?.company;

        // If an attacher cannot be found that matches the attachment company, prompt the user to resolve.
        if (!attachers.some((x) => x.value == attachmentCompany)) {
          const dialog = KatapultDialog.open({
            dialog: { title: 'No Attacher Found', icon: 'search', modal: true, maxWidth: 600 },
            template: () => litHtml`
              <div style="text-align: center;">
                <p>No attacher was found that matched the following attachment company:</p>
                <p><strong>${attachmentCompany}</strong></p>
                <p>Please select one from the attachers list:</p>
              </div>
              <input-element attribute_name="Attacher" hide-attribute-name></input-element>
              <katapult-button slot="buttons" dialog-dismiss>Cancel</katapult-button>
              <katapult-button id="confirm" slot="buttons" color="var(--secondary-color)" disabled>Continue</katapult-button>
            `
          });
          await dialog.openStart;
          // Get the confirm button.
          const confirmButton = dialog.querySelector('#confirm');
          // Get the input and set the attachers attribute on it.
          const input = dialog.querySelector('input-element');
          input.model = companyAttribute;
          // When the input chages, enable/disable the button.
          input.addEventListener('value-changed', (e) => (confirmButton.disabled = !e.detail.value));
          // When the button is clicked, allow the dialog to close if valid value.
          confirmButton.addEventListener('click', () => {
            if (!!input.value) {
              // Store the pair in the lookup.
              companyToAttacherLookup[attachmentCompany] = input.value;
              // Update the attachment company.
              attachmentCompany = input.value;
              dialog.close();
            }
          });
          // Wait for the dialog to begin to close.
          await dialog.closeStart;
        }

        const attacher = attachers.find((x) => x.value == attachmentCompany);
        if (attacher == null) throw 'Failed to find an attacher';

        // Return the default cable type if we have one.
        if (attacher.default_cable_type) return attacher.default_cable_type;

        // if we don't have a default_cable_type, prompt the user to fill one out, and set it for that company
        const { dialog, promise } = DialogService.prompt({
          title: 'Set Default Cable Type',
          message: litHtml`
            Set the default cable type for: <b>${attacher?.agreement_number} - ${attachment.company} - Agreement Type (${attacher?.agreement_type})</b>
            <katapult-drop-down label="Default Cable Type" only-open-down></katapult-drop-down>
          `,
          button: { text: 'Confirm' },
          attributes: { 'min-width': 400 },
          bodyStyle: 'font-size: 14px'
        });

        // Wait for the dialog to be opened (confirm button may not exist until it is opened).
        await new Promise((x) => dialog.addEventListener('opened', x, { once: true }));

        // set the values on the dropdown after the dialog has been opened
        let dropdown = dialog.querySelector('katapult-drop-down');
        dropdown.items = this.getAttributePicklists(this.otherAttributes, 'cable_type');

        // when we click the button, gather the value that the user input
        let temp;
        dialog.confirmButton.callback = async () => {
          if (!dropdown.value) throw new Error('Please enter a value');
          temp = dropdown.value;
          let attacherIndex = attachers.findIndex((x) => x.value == attachmentCompany);

          // if we can't find an attacher index, return an error
          if (attacherIndex < 0) throw new Error('Could not find attacher index');

          // set the value in the database
          await FirebaseWorker.ref(
            `photoheight/company_space/${this.jobCreator}/models/attributes/company/picklists/attachers/${attacherIndex}/default_cable_type`
          ).set(temp);
          dialog.delayedClose();
          return true;
        };

        // await the dialog to be entirely closed
        await promise;

        // return the cable type provided by the user
        return temp;
      }

      // lookup data was able to give us the cable type straight up
      if (cableType) return cableType;
    };

    for (let nodeId in this.nodes) {
      let node = this.nodes[nodeId];
      // only get attachments for poles
      if (this.modelDefaults.pole_node_types.includes(PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute))) {
        // returns an array of pole attachments sorted by attachment order
        let attachments = await getPoleAttachments(node);
        // reverse the order of the array
        attachments.reverse();
        let wiresOnRecord = {};
        let otherAttachementsOnRecord = {};

        // wait until we've gathered ALL of the information regarding the attachments for this pole
        await new Promise(async (resolve) => {
          for (let i = 0; i < attachments.length; i++) {
            // determine cable type, given the PPL attachment type
            let cableType = await getCableTypeFromAttachType(attachments[i]);
            let pushId = firebase.database().ref().push().key;

            // attachments that return a cable type will be added to the wires_on_record table attribute
            if (cableType) {
              wiresOnRecord[pushId] = {
                attachment_order: attachments[i].order,
                company: attachments[i].company,
                cable_type: cableType
              };
            }
            // attachments that do not return a cable type will be added to the other_attachments_on_record table attribute
            else {
              otherAttachementsOnRecord[pushId] = {
                attachment_order: attachments[i].order,
                company: attachments[i].company,
                note: attachments[i].attach_type
              };
            }
          }
          resolve();
        });

        if (Object.keys(wiresOnRecord).length) update[`${nodeId}/attributes/wires_on_record`] = wiresOnRecord;
        if (Object.keys(otherAttachementsOnRecord).length)
          update[`${nodeId}/attributes/other_attachments_on_record`] = otherAttachementsOnRecord;
      }
    }
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
    this.toast('Done Setting Attacher Information');
    this.cancelPromptAction();
  }

  _button_set_cu_data(e) {
    // Get the list of work location nodes
    const nodesEntries = Object.entries(this.nodes ?? {});
    this.workLocations = [];

    for (const [, node] of nodesEntries) {
      const nodeType = PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute);

      // Only include nodes that are poles
      if (!this.modelDefaults.pole_node_types.includes(nodeType)) continue;

      // Only include nodes that are work locations
      const isWorkLocation =
        PickAnAttribute(node.attributes, 'mr_category') == 'Medium Make Ready' ||
        PickAnAttribute(node.attributes, 'mr_category') == 'Complex Make Ready';
      if (!isWorkLocation) continue;

      // Only include nodes that don't already have both the bucket_truck_accessible and traffic_control attributes set
      const bucketTruckAccessible = PickAnAttribute(node.attributes, 'bucket_truck_accessible');
      const trafficControl = PickAnAttribute(node.attributes, 'traffic_control');
      if (bucketTruckAccessible != null && trafficControl != null) continue;

      this.workLocations.push(node);
    }

    // Get the first work location node
    this.getNextWorkLocation();
  }

  async checkAdvanceWorkLocation(e) {
    // Get the data for the clicked button
    const button = e.target;
    const attribute = button.getAttribute('attribute');
    let data = button.getAttribute('data');

    const nodeId = this.setCUData.nodeId;

    // Get all the other buttons
    const otherButtons = Array.from(this.$.setCUDataDialog.querySelectorAll(`[attribute="${attribute}"]`)).filter(
      (x) => x.getAttribute('data') != data
    );

    // Modify the data we actually want to write to Firebase
    switch (attribute) {
      case 'bucket_truck_accessible':
        // The bucket_truck_accessible attribute is a Boolean, so we need to convert the data to a Boolean
        if (data == 'yes') data = true;
        else data = false;
        break;
      case 'traffic_control':
        // The traffic_control attribute is a dropdown and the data attribute is the value of the dropdown
        break;
      default:
        break;
    }

    // Update the button colors
    button.color = 'var(--secondary-color)';
    otherButtons.forEach((x) => (x.color = 'white'));

    // Update the attribute on the node
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes/${nodeId}/attributes/${attribute}/button_added`).set(data);

    // If the current work location has both bucket_truck_accessible and traffic_control attributes set, move on to the next work location
    const bucketTruckAccessible = PickAnAttribute(this.nodes[nodeId]?.attributes, 'bucket_truck_accessible');
    const trafficControl = PickAnAttribute(this.nodes[nodeId]?.attributes, 'traffic_control');

    if (bucketTruckAccessible != null && trafficControl != null) {
      // Reset all buttons to white
      const allButtons = this.$.setCUDataDialog.querySelectorAll('katapult-button').forEach((x) => (x.color = 'white'));

      this.getNextWorkLocation();
    }
  }

  getNextWorkLocation() {
    const dialog = this.$.setCUDataDialog;

    // If all work locations have been set, close the dialog
    if (this.workLocations.length == 0) {
      dialog.close();
      this.toast('Done Setting CU Data');
      return;
    }

    const nextWorkLocation = this.workLocations.shift();

    this.$.katapultMap.selectNode({ detail: { key: nextWorkLocation.$key, jobId: this.job_id } });
    this.zoomToNode(nextWorkLocation.$key);

    const cuData = {
      accessible: PickAnAttribute(nextWorkLocation.attributes, 'bucket_truck_accessible'),
      flagging: PickAnAttribute(nextWorkLocation.attributes, 'traffic_control'),
      poleTag:
        Object.values(nextWorkLocation.attributes?.pole_tag ?? {}).find((x) => x.company == 'PPL Company' || x.company == 'PPL') ?? {},
      scid: PickAnAttribute(nextWorkLocation.attributes, this.modelDefaults.ordering_attribute),
      nodeId: nextWorkLocation.$key
    };
    if (cuData.poleTag?.tagtext == null) cuData.poleTag.tagtext = 'NA';

    this.set('setCUData', cuData);

    // Open the Set CU Data dialog if it isn't already open
    if (dialog.opened === false) dialog.open();
  }

  async _button_pole_inspection_data(e) {
    let update = {};
    this.toast('Setting Pole Inspection Data');
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);

    for (let nodeId in this.nodes) {
      let node = this.nodes[nodeId];
      if (this.modelDefaults.pole_node_types.includes(PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute))) {
        let connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);
        let nodeConnections = connectionLookup[nodeId] || [];
        // this attribute dicates if we should add data to the poles or not
        let rejectStatus = PickAnAttribute(node.attributes, 'reject_status');
        if (rejectStatus == 'Non-Restorable') {
          // get markers on this node
          let mainPhotoKey = GetMainPhoto(node.photos);
          let mainPhotoData = SquashNulls(photos, mainPhotoKey, 'photofirst_data');
          // Get all of the markers on the main photo
          let markers = DataViews.help.getMarkers(mainPhotoData || {}, this.traces, { includeAllMarkerTypes: true });

          // calculate primary phase
          let priPhase = 0;
          for (let i = 0; i < nodeConnections.length; i++) {
            let conn = nodeConnections[i];
            // get markers on this span
            let mainPhotoData = SquashNulls(photos, conn?.mainPhotoId, 'photofirst_data');

            if (mainPhotoData) {
              // find primaries
              let sectionPhotos = DataViews.help.getMarkers(mainPhotoData || {}, this.traces, { includeAllMarkerTypes: true });
              let primaries = sectionPhotos.filter((x) => this.traces?.[x?._trace]?.cable_type == 'Primary');
              // check to see if this number is greater than our currently set value
              if (primaries.length > priPhase) priPhase = primaries.length;
            }
          }
          // this is the highest amount we can have, so cap the value at 3
          if (priPhase > 3) priPhase = 3;

          // set variables that will be used for the remaining calculations
          let remainingStrength = PickAnAttribute(node.attributes, 'remaining_strength_percentage');
          let existingLoading = PickAnAttribute(node.attributes, 'existing_loading_percentage');
          let loadingAnalysis = PickAnAttribute(node.attributes, 'load_case') ?? PickAnAttribute(node.attributes, 'loading_analysis');
          let poleYear = PickAnAttribute(node.attributes, 'pole_year');
          let poleHasThreePhaseEquipment = markers.some((x) =>
            ['recloser', 'capacitor', 'transformer_bank', 'regulator '].includes(x.equipment_type)
          );
          let groundType = markers.find((x) => x.ground_type)?.ground_type;
          let today = new Date();

          // calculate priority
          let priority;
          if (remainingStrength == -1 || remainingStrength == 'VR' || remainingStrength == 'TR') priority = '03';
          else if (remainingStrength <= 8) priority = '01';
          else if (remainingStrength <= 25) priority = '02';
          else if (remainingStrength > 25 || remainingStrength == 'NA' || remainingStrength == 'N/A') {
            if (
              loadingAnalysis == 'NESC - 250B - Heavy - B' ||
              poleHasThreePhaseEquipment ||
              existingLoading >= 65 ||
              (poleYear && today.getFullYear() - Number(poleYear) >= 40 && ['Asphalt', 'Concrete'].includes(groundType))
            ) {
              priority = '03';
            } else priority = '04';
          }

          // calculate the required date
          let start = 0;
          let timeBuckets = node?.attributes?.time_bucket;

          for (let key in timeBuckets) {
            if (timeBuckets[key].start > start) start = timeBuckets[key].start;
          }

          if (priority == '01') start = null;
          else if (priority == '02')
            start += 4838400000; // 8 weeks
          else if (priority == '03')
            if (poleHasThreePhaseEquipment)
              start += 9676800000; // 16 weeks
            else start += 15724800000;
          // 26 weeks
          else if (priority == '04') start += 31450000000; // 52 weeks

          // set the update values
          update[`${nodeId}/attributes/priority`] = priority ? { button_added: priority } : null;
          update[`${nodeId}/attributes/required_date`] = start ? { button_added: start } : null;
          update[`${nodeId}/attributes/primary_phase`] = { button_added: String(priPhase) };
        }
      }
    }
    firebase.database().ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
    this.toast('Finished Setting Pole Inspection Data');
  }

  _button_transfer_manual_heights(e) {
    if (this.selectedNode == null) {
      let buttons = [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Proceed', callback: this._button_transfer_manual_heights.bind(this), attributes: { 'secondary-color': '' } }
      ];
      this.$.katapultMap.openActionDialog({ title: 'Transfer Manual Heights', text: 'Please select a node then click Proceed:', buttons });
    } else {
      this.cancelPromptAction();
      let markersTable = SquashNulls(this.nodes, this.selectedNode, 'attributes', 'measured_heights');
      if (markersTable) {
        let linkMapPhotoActions = [];
        let photoId = GetMainPhoto(SquashNulls(this.nodes, this.selectedNode, 'photos'));
        let scid = PickAnAttribute(this.nodes[this.selectedNode].attributes, 'scid') || '';
        for (let prop in markersTable) {
          let marker = markersTable[prop];
          let manualHeight = '';
          let formattedManualHeight = '';
          if (this.useMetricUnits) {
            manualHeight = marker.manual_height;
            formattedManualHeight = FormatHeight(marker.manual_height, 'meters');
          } else {
            manualHeight = marker.manual_height * 12;
            formattedManualHeight = FormatHeight(marker.manual_height * 12, 'feet-inches');
          }
          linkMapPhotoActions.push({
            photoId: photoId,
            action_type: 'transfer_manual_heights',
            itemKey: 'button_added',
            nodeId: this.selectedNode,
            scid: scid,
            _manual_height: manualHeight,
            manual_height: formattedManualHeight,
            company: marker.company || '',
            cable_type: marker.cable_type || '',
            equipment_type: marker.equipment_type || ''
          });
        }
        FirebaseWorker.ref(
          'photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/map_action/transfer_manual_heights/'
        ).set(linkMapPhotoActions);
        this.openPhotoFirst({
          currentTarget: {
            id: photoId
          }
        });
        this.toast('Continue in Photos to set manual heights.');
      } else {
        this.toast('There are no manual heights to put on the photo.');
      }
    }
  }

  _button_calc_wind_speed(e) {
    // Office Power Tool for Alpine to set the Wind Zone attribute on a node based on defined model lookup
    let lookup = this.activeCommandModel.models.lookup;
    let update = {};
    this.toast('Calculating Wind Speed for all poles');
    for (let nodeId in this.nodes) {
      let type = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
      // filter down to just poles
      if (this.modelDefaults.pole_node_types.includes(type)) {
        // grab county attribute
        let county = PickAnAttribute(this.nodes[nodeId].attributes, 'county');
        if (county) {
          // convert to lowercase
          county = county.toLowerCase();
          // remove the word "county" from the attribute value, and check model lookup
          let windSpeed = lookup[FirebaseEncode.encode(county.replace(' county', ''))];
          if (windSpeed != null) update[nodeId + '/attributes/wind_speed'] = { button_added: windSpeed };
        }
      }
    }
    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
  }

  async _button_calc_pole_height(e) {
    // Office Power Tool for Alpine to set the Measured Pole Height attribute on a node based on the pole top marker
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    let update = {};
    this.toast('Calculating Measured Pole Height for all poles');
    for (let nodeId in this.nodes) {
      let type = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
      // filter down to just poles
      if (this.modelDefaults.pole_node_types.includes(type)) {
        let node = this.nodes[nodeId];
        // we only want to update for poles that don't have this attribute already
        if (!PickAnAttribute(node.attributes, 'measured_pole_height')) {
          for (var photoId in node.photos) {
            var photo = photos[photoId];
            for (var itemKey in photo?.photofirst_data?.pole_top) {
              if (!photo.photofirst_data.pole_top[itemKey].pole_top_extension) {
                var measuredHeight = photo.photofirst_data.pole_top[itemKey]._measured_height;
                var manualHeight = photo.photofirst_data.pole_top[itemKey].manual_height;
                if (measuredHeight != null) {
                  var feet = Math.trunc(measuredHeight / 12);
                  var decimal = measuredHeight / 12 - feet;
                  decimal = decimal.toString().substring(1, 3);
                  update[nodeId + '/attributes/measured_pole_height'] = { button_added: feet + decimal };
                } else if (manualHeight != null) update[nodeId + '/attributes/measured_pole_height'] = { button_added: manualHeight };
              }
            }
          }
        }
      }
    }
    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
  }

  _button_calc_permitted_status(e) {
    // Office Power Tool for Alpine to set the Permitted Status attribute on a node based on connection types adjacent to the node

    let connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);
    let update = {};
    this.toast('Calculating Permitted Status for all Poles');
    for (let nodeId in this.nodes) {
      let type = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
      // filter down to just poles
      if (this.modelDefaults.pole_node_types.includes(type)) {
        // filter down to just overlash connections
        let nodeConnections = connectionLookup[nodeId] || [];
        let overlashConnections = nodeConnections.filter((x) => {
          return PickAnAttribute(x.connData?.attributes, this.modelDefaults.connection_type_attribute) == 'overlash';
        });
        if (overlashConnections.length > 0) update[nodeId + '/attributes/permitted_status'] = { button_added: 'Existing' };
        else update[nodeId + '/attributes/permitted_status'] = { button_added: 'New' };
      }
    }
    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
    // keep count of any overlash cables
  }

  _button_json_calibrate_office_photo(e) {
    if (this.selectedNode == null && this.selectedDeliverableSection == null) {
      let buttons = [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Proceed', callback: this._button_json_calibrate_office_photo.bind(this), attributes: { 'secondary-color': '' } }
      ];
      this.$.katapultMap.openActionDialog({
        title: 'Json calibrate office photo',
        text: 'Please select a node or section then click Proceed:',
        buttons
      });
      //TODO - dropdown to select import JSON format
    } else {
      this.cancelPromptAction();

      //generate JSON obj
      let jsonObj = {};

      //Lasertech old - todo: add more
      let attrs = SquashNulls(this.nodes, this.selectedNode, 'attributes'); //selected node's attributes
      //if no node, section
      if (!attrs)
        attrs = SquashNulls(
          this.connections,
          this.selectedDeliverableSection.connId,
          'sections',
          this.selectedDeliverableSection.sectionId,
          'multi_attributes'
        );

      if (!attrs) throw 'JCOP no node or section attributes found';

      let ltElems = [
        'ht_base_of_pole',
        'ht_ground',
        'ht_top_of_pole_tag',
        'ht_lowest_com_cable',
        'ht_top_com_cable',
        'ht_lowest_power_cable',
        'ht_top_of_pole',
        'ht_other_one',
        'ht_other_two'
      ]; //lasertech elements
      let hd = 0; //horizontal distance - average
      let hdi = 0; //horizontal distance icrement - increments by 1 for each hd used to calc average
      let basePole = null;

      for (var ltElem of ltElems) {
        let attr = PickAnAttribute(attrs, ltElem);
        if (ltElem == 'ht_base_of_pole' || ltElem == 'ht_ground') {
          if (attr && !basePole) basePole = attr; //save reference so we don't need to fetch twice
        } else {
          if (attr) {
            let h = SquashNulls(attr, 'laserData', 'horiz');
            if (h) {
              hd += h;
              hdi++;
            }
          }
        }
      }

      if (hdi == 0 || !basePole) {
        this.toast('Pole needs laser measurements');
        return;
      }

      hd = hd / hdi;
      let ps = '';

      // Calculate actual base height equal to tan(rad(a))*hd.  Should be negative, as a is negative
      let baseHeightOffset = Math.tan((SquashNulls(basePole, 'laserData', 'incline') * Math.PI) / 180) * hd;
      //loop through once more to populate the json object
      let jsoni = 0;
      for (var ltElem of ltElems) {
        let attr = PickAnAttribute(attrs, ltElem);
        let h = SquashNulls(attr, 'laserData', 'vert');
        if (attr && h) {
          if (ltElem == 'ht_base_of_pole' || ltElem == 'ht_ground') h = 0;
          else h -= baseHeightOffset;
          jsonObj[jsoni] = {
            name: ltElem,
            height: h
          };
          jsoni++;
          ps += `${ltElem}
          ${h.toFixed(3)}
          `;
          //TODO - remove
        }
      }
      //return; //TODO - remove

      this.$.katapultMap.openActionDialog({
        title: 'Json calibrate office photo',
        text: 'Please select a photo to transfer onto',
        photoChooser: true,
        photoChooserItem: SquashNulls(this.nodes, this.selectedNode),
        jsonObj: jsonObj
      });
    }
  }

  jcopTransfer(e) {
    let jsonObj = e.detail.jsonObj;
    let transferTo = e.detail.photoList[e.detail.photoIndex].photoId;

    if ((jsonObj, transferTo)) {
      let linkMapPhotoActions = [];
      let scid = PickAnAttribute(this.nodes[this.selectedNode].attributes, 'scid') || '';

      for (let point of Object.values(jsonObj)) {
        linkMapPhotoActions.push({
          photoId: transferTo,
          action_type: 'jcop',
          itemKey: 'button_added',
          nodeId: this.selectedNode,
          scid: scid,
          _manual_height: Math.round(point.height * 100) / 100,
          manual_height: Math.floor(point.height) + "'-" + (Math.floor(12 * point.height) % 12) + '"', //formatted
          name: point.name
        });
      }

      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/map_action/jcop/').set(
        linkMapPhotoActions
      );

      this.toast('Continue in Photos to json calibrate.');
      this.openPhotoFirst({
        currentTarget: {
          id: transferTo,
          itemKey: e.detail.item
        }
      });

      this.$.katapultMap.closeActionDialog();
    } else {
      this.toast('Not enough info for json calibration.');
    }
  }

  async _button_set_job_metadata(e) {
    let metadata;
    if (this.mappingButtons?.[this.activeCommand]?.models?.data) {
      let computeFrom = this.mappingButtons?.[this.activeCommand]?.models?.compute_data_from;
      let oldValue = this.metadata?.[computeFrom];
      metadata = this.mappingButtons?.[this.activeCommand]?.models?.data?.[oldValue]?.metadata;
      let newValue = metadata?.[computeFrom];
      let promises = [];
      if (this.userGroup == 'alpine_communication_corp' && newValue) {
        let epoch = new Date().getTime();
        let oldTime = this.metadata.status_last_updated;
        if (oldTime) {
          //if 1500ms haven't passed since last update, don't update
          if (epoch - oldTime < 1500) {
            this.toast('Please wait 1.5 seconds between updates');
            return;
          }
        }
        metadata = Object.assign(metadata, { status_last_updated: epoch });
        if (oldValue == 'New_Job') {
          let uid = Object.keys(this.users || {}).find((x) => this.users[x].email == 'jrohlfing@alpinecc.us');
          metadata = Object.assign(metadata, { assigned_to: uid });
          this.toast('Status advanced to Field_Ready, assigned to jrohlfing@alpinecc.us');
        } else if (oldValue != 'Field_Issued') {
          promises.push(
            new Promise((resolve) => {
              this.confirm(
                'Assign a User',
                `The status of this job will be advanced to ${newValue}. Assign a user to this job.`,
                'Okay ',
                'Cancel',
                '',
                'selectAssignedToValue',
                () => {
                  metadata = Object.assign(metadata, { assigned_to: this.alpineAssignedTo || '' });
                  resolve();
                }
              );
            })
          );
        }
        // reset value for next input from user
        this.alpineAssignedTo = null;
      }

      Promise.all(promises).then(async () => {
        await setJobMetadata(this.userGroup, metadata, this.job_id);
        let toast = this.mappingButtons?.[this.activeCommand]?.models?.toast || 'Job Metadata';
        if (metadata) {
          if (this.userGroup == 'alpine_communication_corp' && oldValue == 'New_Job') {
            this.toast('Status advanced to Field_Ready, assigned to jrohlfing@alpinecc.us');
          } else this.toast(`${toast} updated`);
        } else {
          this.toast('No action taken');
        }
      });
    } else {
      metadata = this.mappingButtons?.[this.activeCommand]?.models?.metadata;
      await setJobMetadata(this.userGroup, metadata, this.job_id);
      let toast = this.mappingButtons?.[this.activeCommand]?.models?.toast || 'Job Metadata';
      this.toast(`${toast} updated`);
    }
  }

  async _button_recalc_photo_counters(e) {
    this.toast('Recalculating photo counters...');
    let counterUpdates = [];
    let counterUpdate = {};
    counterUpdates.push(
      CollectionSetTools.updateJobCounters({ jobId: this.job_id }).then((update) => Object.assign(counterUpdate, update))
    );
    counterUpdates.push(
      CollectionSetTools.updateJobLastUpload({ jobId: this.job_id }).then((update) => Object.assign(counterUpdate, update))
    );

    await Promise.all(counterUpdates).then(async () => {
      for (let path in counterUpdate) {
        if (typeof counterUpdate[path] === 'undefined') {
          delete counterUpdate[path];
        }
      }
      await FirebaseWorker.ref()
        .update(counterUpdate)
        .then(() => {
          this.toast('Photo Counters Updated!');
          this.cancelPromptAction();
        })
        .catch((error) => {
          this.toast(error);
        });
    });
  }

  async _button_scrape_photo_data_simple(e) {
    this.toast('Scraping photo data to nodes...');
    let update = {};
    let propertiesToSkip = [
      'pole_tag',
      'groundline_circumference',
      'grounded',
      'grounding',
      'birthmark_info',
      'visibility_striping',
      'osmose',
      'inspection_tag',
      'tag'
    ];
    let aventuraExceptions = ['osmose', 'inspection_tag'];
    if (this.jobCreator == 'aventura_group_PLA_tension_to_sag')
      propertiesToSkip = propertiesToSkip.filter((property) => {
        aventuraExceptions.includes(property);
      });
    let nodesUpdated = false;
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);

    for (let nodeId in this.nodes) {
      for (let property in this.nodes[nodeId].attributes) {
        for (let itemKey in this.nodes[nodeId].attributes[property]) {
          if (itemKey.startsWith('scraped_')) {
            update[nodeId + '/attributes/' + property + '/' + itemKey] = null;
          }
        }
      }
      for (let photoId in this.nodes[nodeId].photos) {
        let photo = photos[photoId];
        if (photo != null && photo.photofirst_data != null) {
          // Scrape any attributes that should go on the node
          for (let property in photo.photofirst_data) {
            // Skip special properties that already scrape below
            if (propertiesToSkip.includes(property)) continue;
            // Loop through each instance of the property
            for (let itemKey in photo.photofirst_data[property]) {
              // Get the data for the property item at itemKey
              let item = photo.photofirst_data[property][itemKey];
              // Special case for booleans. If the value for the attribute
              // is just true, then this is just a blank chip, and the attribute
              // name will not be listed. So assume that the attribute name
              // is actually the property and force an object to loop over
              if (item === true) {
                item = {};
                item[property] = true;
              }
              // Loop through the keys in the item to see if any are attributes
              for (let attributeName in item) {
                // Check if the attribute should be scraped to the node
                // todo We should also check if attributes should be scraped to sections and connections?
                if ((SquashNulls(this.otherAttributes, attributeName, 'attribute_types') || []).includes('node')) {
                  let keyName = 'scraped_' + itemKey;
                  // Check that the node doesn't have data for that attribute already
                  let existing = SquashNulls(this.nodes[nodeId].attributes, attributeName, keyName);
                  if (existing === item[attributeName]) {
                    delete update[nodeId + '/attributes/' + attributeName + '/' + keyName];
                  } else {
                    // Remove the item with the old itemKey
                    let existingItem = SquashNulls(this.nodes[nodeId].attributes, attributeName);
                    if (
                      existingItem &&
                      (this.jobCreator == 'asg_attachment_audit_models' || SquashNulls(this.metadata, 'clear_scraped_attributes'))
                    ) {
                      for (let key in existingItem) {
                        update[nodeId + '/attributes/' + attributeName + '/' + key] = null;
                      }
                    }
                    // Update the node to insert the value for the attribute
                    update[nodeId + '/attributes/' + attributeName + '/' + keyName] = item[attributeName];
                    nodesUpdated = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    // If we have removed a property
    for (let path in update) {
      if (update[path] === null) {
        nodesUpdated = true;
      }
    }
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
    setTimeout(async () => {
      const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
    }, 1);
    if (nodesUpdated) {
      this.toast('Nodes successfully updated!');
    } else {
      this.toast('No attribute data was found to scrape');
    }
  }
  async _button_generate_invoice_data(e) {
    // Add a new invoice data attribute bundle onto this job
    let metadata = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/metadata`)
      .once('value')
      .then((s) => s.val());
    let billing = await FirebaseWorker.ref('photoheight/company_space/katapult/billing/ppl')
      .once('value')
      .then((s) => s.val());
    let push = {};
    push['wo_number'] = SquashNulls(metadata, 'wo_number');
    push['wr_number'] = SquashNulls(metadata, 'wr_number');
    push['original_pole_count'] = SquashNulls(metadata, 'pole_count');

    let mrCounter = 0;
    let finalCounter = 0;
    for (let nodeId in this.nodes) {
      var mr_category = PickAnAttribute(this.nodes[nodeId].attributes, 'mr_category');
      var nodeType = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
      if (this.modelDefaults.pole_node_types.includes(nodeType)) finalCounter++;
      // calculate mr_pole_count
      if (mr_category && (mr_category.toLowerCase().includes('medium') || mr_category.toLowerCase().includes('complex'))) mrCounter++;

      billing.units.forEach((item, index) => {
        if (item.item_types.node && CalcStatement(item.include, this.nodes[nodeId].attributes)) {
          item.count = item.count ? item.count + 1 : 1;
        }
      });
    }
    for (var connId in this.connections) {
      billing.units.forEach((item, index) => {
        if (item.item_types.connection && CalcStatement(item.include, this.connections[connId].attributes)) {
          item.count = item.count ? item.count + 1 : 1;
        }
      });
      for (var sectionId in this.connections[connId].sections) {
        billing.units.forEach((item, index) => {
          if (item.item_types.section && CalcStatement(item.include, this.connections[connId].sections[sectionId].multi_attributes)) {
            item.count = item.count ? item.count + 1 : 1;
          }
        });
      }
    }
    var app_cost = 100;
    billing.units.forEach((item) => {
      item.count = item.count || 0;
      item.total = item.price * item.count;
      app_cost += item.total;
    });
    push['mr_pole_count'] = mrCounter;
    push['mr_engineering_actual'] = app_cost;
    push['final_pole_count'] = finalCounter;
    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/metadata/invoice_data/`)
      .push({
        attributes: push
      })
      .then(() => {
        this.toast('Invoice data generated');
      });
    this.cancelPromptAction();
  }

  async _button_wifinity_replace_commas(e) {
    this.toast('Fixing FDEC_pole_ID...');
    let update = {};
    for (let nodeId in this.nodes) {
      let FDEC_pole_ID = SquashNulls(this.nodes[nodeId], 'attributes', 'FDEC_pole_ID');
      if (FDEC_pole_ID) {
        for (let attributeKey in FDEC_pole_ID) {
          if (FDEC_pole_ID[attributeKey]) {
            update[`${nodeId}/attributes/FDEC_pole_ID/${attributeKey}`] = FDEC_pole_ID[attributeKey].replace(/,/g, '');
          }
        }
      }
    }
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
    this.toast('Done!');
  }

  async _button_import_pole_attributes(e) {
    this.confirm(
      'Import Pole Attributes',
      'Are you sure you want to import attributes from application poles to all poles in this job?',
      'Import ',
      'Cancel',
      '',
      '',
      async () => {
        this.toast('Importing Attributes...');
        const importPoleAttributes = await import('./button_functions/import_pole_attributes.js');
        importPoleAttributes.importPoleAttributes(this.job_id, this.nodes);
      }
    );
  }

  async _button_join_selected_nodes(e) {
    const selectNodesCallback = () => {
      let selectedNodes = this.$.katapultMap.multiSelectedNodes;
      let selectedPoles = selectedNodes.filter((node) => {
        const nodeType = PickAnAttribute(this.nodes[node]?.attributes, 'node_type');
        let isPole = nodeType == 'pole';
        return isPole;
      });
      this.joinNodes(selectedPoles);
    };

    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: false
    };
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around items to select them. Right click to delete polygon points.',
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        // this button has the same functionality as the join all nodes button that exists in Katapult Pro
        {
          title: 'Select All',
          callback: this._button_join_all_nodes.bind(this),
          attributes: { style: 'background-color: var(--paper-grey-200); padding: 12px; margin:0 5px' }
        },
        {
          title: 'Finish',
          callback: selectNodesCallback,
          attributes: {
            style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); padding: 12px; margin:0 5px'
          }
        }
      ]
    });
    this.$.katapultMap.countTouchedConnections = true;
  }

  async _button_join_all_nodes(e) {
    const { confirmed } = KatapultDialog.confirm('Are you sure you want to join all poles together?', 'Join All Poles');
    if (!(await confirmed)) return;

    const poleIds = Object.entries(this.nodes ?? {})
      .filter(([, node]) => {
        const nodeType = PickAnAttribute(node?.attributes, 'node_type');
        const isPole = nodeType == 'pole';
        return isPole;
      })
      .map(([nodeId]) => nodeId);

    await this.joinNodes(poleIds);
  }

  /**
   * Function that forms connections between poles in a selection (or between all poles if joinNodes is called from _button_join_all_nodes)
   * @param {Array<String>} poleIds array of node IDs of the poles to join
   */
  async joinNodes(poleIds) {
    // alert and return if user has not selected enough poles
    if (poleIds.length < 2) {
      this.toast('Please select more than one pole to join', null, 6000);
      return;
    }
    this.toast('Joining Nodes...');
    let update = {};
    let conns = {};
    let connCount = 0;
    let polesArr = [];

    // create array from poles object and add attributes
    polesArr = poleIds.map((nodeId) => {
      let node = this.nodes[nodeId];
      let nodeObject = {
        key: nodeId,
        value: node
      };
      let order = PickAnAttribute(node?.attributes, 'pole_app_order');
      if (order) {
        nodeObject['order'] = order;
      }
      let attachType = PickAnAttribute(node?.attributes, 'new_attach_type');
      if (attachType) {
        nodeObject['attach_type'] = attachType;
      }
      return nodeObject;
    });

    let orderedNodes = {};
    let unorderedNodes = {};
    if (polesArr.length > 0) {
      orderedNodes = polesArr.filter((item) => (item.order != null ? true : false));
      unorderedNodes = polesArr.filter((item) => (item.order != null ? false : true));

      if (orderedNodes.length != 0) {
        // sort by order
        orderedNodes.sort(function (a, b) {
          return a.order - b.order;
        });
        let branchCounter = 0;
        let returnFromBranch = false;
        outerLoop: for (let i = 0; i < orderedNodes.length - 1; i++) {
          let currentNode = orderedNodes[i];
          let otherNode = orderedNodes[i + 1];
          if (returnFromBranch) {
            // set the otherNode based on branch counter so that we skip all the connections already made while branching
            otherNode = orderedNodes[i + branchCounter + 1];
            // next time throught the loop skip ahead the same amount that we branched off for
            i = i + branchCounter;
            // reset the branch variables
            branchCounter = 0;
            returnFromBranch = false;
          }

          let currentNodeLatLng = new google.maps.LatLng(currentNode.value.latitude, currentNode.value.longitude);
          let otherNodeLatLng = new google.maps.LatLng(otherNode.value.latitude, otherNode.value.longitude);
          let distance = google.maps.geometry.spherical.computeDistanceBetween(currentNodeLatLng, otherNodeLatLng);

          if (distance < 183) {
            // check if this connection has already been made outside of this function
            for (let connId in this.connections) {
              if (
                (this.connections[connId].node_id_1 == currentNode.key && this.connections[connId].node_id_2 == otherNode.key) ||
                (this.connections[connId].node_id_1 == otherNode.key && this.connections[connId].node_id_2 == currentNode.key)
              ) {
                continue outerLoop;
              }
            }
          } else {
            continue outerLoop;
          }

          let connType =
            SquashNulls(this.activeCommandModel.models, 'attributes', this.modelDefaults.connection_type_attribute, 'value') ||
            'aerial cable';
          if (otherNode.attach_type) {
            if (otherNode.attach_type == 'Guy (Below Com Space)') {
              connType = 'pole to pole guy';
              // prepare to do another connection to the same node next time through the loop
              branchCounter += 1;
            } else if (otherNode.attach_type == 'Guy (In Com Space)') {
              connType = 'overhead guy';
              // prepare to do another connection to the same node next time through the loop
              branchCounter += 1;
            } else {
              // since the next available node is not of the correct attach_type
              // we need to return to where we left off when we started the branch and not make a connection
              if (branchCounter) {
                i = i - (branchCounter + 1);
                returnFromBranch = true;
                continue outerLoop;
              }
            }
          } else {
            // since the next available node has no attach_type
            // we need to return to where we left off when we started the branch and not make a connection
            if (branchCounter) {
              i = i - (branchCounter + 1);
              returnFromBranch = true;
              continue outerLoop;
            }
          }

          let conn = {
            node_id_1: currentNode.key,
            node_id_2: otherNode.key,
            attributes: {
              connection_type: {
                value: connType
              }
            }
          };
          if (SquashNulls(this.activeCommandModel.models, 'attributes')) {
            for (let property in this.activeCommandModel.models.attributes) {
              if (property != this.modelDefaults.connection_type_attribute) {
                conn.attributes[property] = this.activeCommandModel.models.attributes[property].value;
              }
            }
          }
          if (conn.node_id_1 != null && conn.node_id_2 != null && conn.node_id_1 != conn.node_id_2) {
            let newConnId = FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/connections').push().key;
            let l1 = [currentNode.value.latitude, currentNode.value.longitude];
            let l2 = [otherNode.value.latitude, otherNode.value.longitude];
            if (this.modelConfig) {
              let length = Round(
                google.maps.geometry.spherical.computeDistanceBetween(
                  new google.maps.LatLng(l1[0], l1[1]),
                  new google.maps.LatLng(l2[0], l2[1])
                ) / 0.3048,
                1
              );
              for (let key in this.modelConfig.connection_length_attributes) {
                let item = this.modelConfig.connection_length_attributes[key];
                let matches = (item.min == null || item.min <= length) && (item.max == null || item.max >= length);
                for (let prop in item.attributes) {
                  if (matches) conn.attributes[prop] = { map_added: item.attributes[prop] };
                  else delete conn.attributes[prop];
                }
              }
            }
            update['connections/' + newConnId] = conn;
            GeofireTools.setGeohash('connections', conn, newConnId, this.jobStyles, update, {
              nId1: conn.node_id_1,
              nId2: conn.node_id_2,
              location1: l1,
              location2: l2
            });
          }
        }
      }
      if (unorderedNodes.length != 0) {
        let greatestDistance = 0;
        let longAxis = false;
        // Find the major axis (lat or long) for this selection of poles
        const earthRadius = 6378;
        for (let node1 in unorderedNodes) {
          for (let node2 in unorderedNodes) {
            // earth's approx. radius in kilometers
            // angle between latitudes in radians
            let angleLat = Math.abs(unorderedNodes[node1].value.latitude - unorderedNodes[node2].value.latitude) * (Math.PI / 180);
            // angle between longitudes in radians
            let angleLong = Math.abs(unorderedNodes[node1].value.longitude - unorderedNodes[node2].value.longitude) * (Math.PI / 180);
            // Calculations to find distance between latitudes and longitudes in kilometers
            let distLat = earthRadius * angleLat;
            let distLong = earthRadius * Math.cos(angleLat) * angleLong;
            if (distLat >= greatestDistance) {
              greatestDistance = distLat;
              longAxis = false;
            }
            if (distLong >= greatestDistance) {
              greatestDistance = distLong;
              longAxis = true;
            }
          }
        }

        // Sort the poles by their location on the major axis
        // This helps in getting a logical ordering of poles in a line
        unorderedNodes.sort((a, b) => {
          if (longAxis) return a.value.longitude - b.value.longitude;
          else return a.value.latitude - b.value.latitude;
        });

        let currentNode = unorderedNodes[0];
        let nodesWithoutOptions = [];
        let skipNodeKeys = [currentNode.key];
        while (currentNode) {
          let closeAvailableNodes = unorderedNodes.filter((otherNode) => {
            if (currentNode.key != otherNode.key) {
              let currentNodeLatLng = new google.maps.LatLng(currentNode.value.latitude, currentNode.value.longitude);
              let otherNodeLatLng = new google.maps.LatLng(otherNode.value.latitude, otherNode.value.longitude);
              let distance = google.maps.geometry.spherical.computeDistanceBetween(currentNodeLatLng, otherNodeLatLng);

              // Set distance for later sorting
              otherNode.distance = distance;

              if (skipNodeKeys.includes(otherNode.key)) {
                return false;
              }

              if (distance < 183) {
                for (let connIndex in conns) {
                  if (
                    (conns[connIndex].node_id_1.key == currentNode.key && conns[connIndex].node_id_2.key == otherNode.key) ||
                    (conns[connIndex].node_id_1.key == otherNode.key && conns[connIndex].node_id_2.key == currentNode.key)
                  ) {
                    return false;
                  }
                }
                // check if this connection has already been made outside of this function
                for (let connId in this.connections) {
                  if (
                    (this.connections[connId].node_id_1 == currentNode.key && this.connections[connId].node_id_2 == otherNode.key) ||
                    (this.connections[connId].node_id_1 == otherNode.key && this.connections[connId].node_id_2 == currentNode.key)
                  ) {
                    return false;
                  }
                }
                return true;
              }
            }
            return false;
          });

          closeAvailableNodes.sort((a, b) => {
            return a.distance - b.distance;
          });

          if (closeAvailableNodes.length == 0) {
            nodesWithoutOptions.push(currentNode.key);
          }

          let closestNode = closeAvailableNodes[0];

          if (closestNode) {
            connCount++;
            skipNodeKeys.push(closestNode.key);
            Path.set(conns, `${connCount}.node_id_1`, JSON.parse(JSON.stringify(currentNode)));
            Path.set(conns, `${connCount}.node_id_2`, JSON.parse(JSON.stringify(closestNode)));
          }

          currentNode = closestNode;

          if (!currentNode) {
            let nodesToCheck = unorderedNodes.filter((x) => {
              let connectionCount = 0;
              for (let connIndex in conns) {
                if (conns[connIndex].node_id_1.key == x.key || conns[connIndex].node_id_2.key == x.key) {
                  connectionCount++;
                }
              }
              return connectionCount < 2 && nodesWithoutOptions.includes(x.key) == false;
            });

            if (nodesToCheck.length > 0) {
              currentNode = JSON.parse(JSON.stringify(nodesToCheck[0]));
            }
          }
        }

        for (let connIndex in conns) {
          let conn = {
            node_id_1: conns[connIndex].node_id_1.key,
            node_id_2: conns[connIndex].node_id_2.key,
            attributes: {
              connection_type: {
                value: this.activeCommandModel?.models?.attributes?.[this.modelDefaults.connection_type_attribute]?.value || 'aerial cable'
              }
            }
          };
          if (this.activeCommandModel?.models?.attributes) {
            for (let property in this.activeCommandModel.models.attributes) {
              if (property != this.modelDefaults.connection_type_attribute) {
                conn.attributes[property] = this.activeCommandModel.models.attributes[property].value;
              }
            }
          }
          if (conn.node_id_1 != null && conn.node_id_2 != null && conn.node_id_1 != conn.node_id_2) {
            let newConnId = FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/connections').push().key;
            let l1 = [conns[connIndex].node_id_1.value.latitude, conns[connIndex].node_id_1.value.longitude];
            let l2 = [conns[connIndex].node_id_2.value.latitude, conns[connIndex].node_id_2.value.longitude];
            if (this.modelConfig) {
              let length = Round(
                google.maps.geometry.spherical.computeDistanceBetween(
                  new google.maps.LatLng(l1[0], l1[1]),
                  new google.maps.LatLng(l2[0], l2[1])
                ) / 0.3048,
                1
              );
              for (let key in this.modelConfig.connection_length_attributes) {
                let item = this.modelConfig.connection_length_attributes[key];
                let matches = (item.min == null || item.min <= length) && (item.max == null || item.max >= length);
                for (let prop in item.attributes) {
                  if (matches) conn.attributes[prop] = { map_added: item.attributes[prop] };
                  else delete conn.attributes[prop];
                }
              }
            }
            update['connections/' + newConnId] = conn;
            GeofireTools.setGeohash('connections', conn, newConnId, this.jobStyles, update, {
              nId1: conn.node_id_1,
              nId2: conn.node_id_2,
              location1: l1,
              location2: l2
            });
          }
        }
      }
    }

    await FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
    this.cancelPromptAction();

    // check if each pole is part of an existing connection
    let numNodesConnected = 0;
    for (let pole of polesArr) {
      for (let connId in this.connections) {
        if (this.connections[connId].node_id_1 == pole.key || this.connections[connId].node_id_2 == pole.key) {
          numNodesConnected++;
          break;
        }
      }
    }

    // operation complete, now communicate the result to user:
    //  - 'Done!' if all selected nodes were successfully joined
    //  - 'Some poles in the selection were too far apart and unable to be connected' OR 'The poles in the selection were too far apart and unable to be connected' if some poles could not be joined
    if (polesArr.length - numNodesConnected > 0) {
      if (polesArr.length <= 2) this.toast('The poles in the selection were too far apart and unable to be connected', null, 6000);
      else this.toast('Some poles in the selection were too far apart and unable to be connected', null, 6000);
    } else this.toast('Done!', null, 6000);
  }

  async promptApplyTimeBucketOptimizations(sync_notes) {
    this.$.toast.close();
    let labels = [];
    for (let photoId in sync_notes) {
      let sync = sync_notes[photoId];
      let syncTimeDate = new Date(sync.sync_time);
      let name = `${syncTimeDate.toTimeString().split(' ')[0]} ${syncTimeDate.toDateString()} (GMT -${
        syncTimeDate.getTimezoneOffset() / 60
      })`;
      let syncTimeCopyPaste =
        syncTimeDate.getFullYear() +
        ':' +
        (syncTimeDate.getMonth() + 1) +
        ':' +
        syncTimeDate.getDate() +
        ':' +
        syncTimeDate.getHours() +
        ':' +
        syncTimeDate.getMinutes() +
        ':' +
        syncTimeDate.getSeconds() +
        ':' +
        syncTimeDate.getMilliseconds();
      let optimTimeDate = new Date(sync.best_sync_time);
      sync.optimTimeCopyPaste =
        optimTimeDate.getFullYear() +
        ':' +
        (optimTimeDate.getMonth() + 1) +
        ':' +
        optimTimeDate.getDate() +
        ':' +
        optimTimeDate.getHours() +
        ':' +
        optimTimeDate.getMinutes() +
        ':' +
        optimTimeDate.getSeconds() +
        ':' +
        optimTimeDate.getMilliseconds();
      let label = `<b>${name}</b>:<br>${syncTimeCopyPaste} resulted in <b>${sync.initial_healthy_location}</b> healthy locations.<br>${sync.optimTimeCopyPaste} resulted in <b>${sync.healthy_location_record}</b> healthy locations`;
      labels.push(label);
    }

    this.confirm(
      'Time Bucket Optimization Results',
      labels.join('<br><br>'),
      'Apply Optimizations',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      null,
      async () => {
        this.toast('Writing Time Bucket Optimizations...');
        let update = {};
        for (let photoId in sync_notes)
          update[`${photoId}/photofirst_data/sync/${sync_notes[photoId].sync_id}/optimizedTime`] = sync_notes[photoId].optimTimeCopyPaste;
        FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photos`)
          .update(update)
          .then(async () => {
            this.toast('Unassociating Photos...');
            let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
            this.createWebWorker('un_associate_photos', 'un_associate_photos', [
              true,
              this.userGroup,
              'optimize',
              this.nodes,
              this.connections,
              photos
            ]);
            this.timeBucketUnassociationComplete = () => {
              setTimeout(() => {
                this.continuePhotoAssociation(true);
              }, 1000);
            };
          });
      }
    );
  }

  async _button_optimize_time_buckets(e) {
    this.toast('Optimizing Time Buckets...');
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    this.createWebWorker('optimize_time_buckets', 'optimize_time_buckets', [this.nodes, photos]);
  }

  async openAnnotationEditor(e) {
    await import('../map-annotation-editor/map-annotation-editor.js');
    this.$.mapAnnotationEditor.edit(e.detail.key, this.multiJobIds[e.detail.jobId].url, e.detail.geoData, e.detail.jobId);
    this.$.katapultMap.$.googlemap.noKeyboardShortcuts = true;
  }

  closeAnnotationEditor(e) {
    if (this.$.mapAnnotationEditor && this.$.mapAnnotationEditor.close) this.$.mapAnnotationEditor.close();
    this.$.katapultMap.$.googlemap.noKeyboardShortcuts = false;
  }

  dragAnnotation(e) {
    let update = {};
    let origin = [e.detail.latLng.lat(), e.detail.latLng.lng()];
    // For length dimensions, lock the annotation to the line
    if (e.detail.geoData.leader?.type == 'length') {
      let snappedPoint = KatapultGeometry.SnapToLine(...origin, ...e.detail.geoData.leader.points[0], ...e.detail.geoData.leader.points[1]);
      origin = [snappedPoint.lat, snappedPoint.long];
      e.detail.geoData.origin = origin;
      e.detail.marker.setPosition(e.detail.geoData);
    }
    let annotation = loadRenderMap.annotationLocations[e.detail.key];
    update[`${e.detail.key}~1/origin`] = origin;
    update[`${e.detail.key}~1/l`] = [annotation.topLeft.lat(), annotation.topLeft.lng()];
    update[`${e.detail.key}~2/origin`] = origin;
    update[`${e.detail.key}~2/l`] = [annotation.topRight.lat(), annotation.topRight.lng()];
    update[`${e.detail.key}~3/origin`] = origin;
    update[`${e.detail.key}~3/l`] = [annotation.bottomRight.lat(), annotation.bottomRight.lng()];
    update[`${e.detail.key}~4/origin`] = origin;
    update[`${e.detail.key}~4/l`] = [annotation.bottomLeft.lat(), annotation.bottomLeft.lng()];
    if (e.detail.geoData.leader) {
      this.$.printModeToolbar.snapLeaderAndEndpoints(update, e.detail.key, e.detail.geoData);
    }
    FirebaseWorker.ref(`${this.multiJobIds[e.detail.jobId].url}/`).update(update);
  }

  annotationMouseover() {
    this.map.setOptions({ disableDoubleClickZoom: true });
  }

  annotationMouseout() {
    this.map.setOptions({ disableDoubleClickZoom: false });
  }

  tableButtonAction(e) {
    if (typeof this[e.detail.functionName] === 'function') {
      this[e.detail.functionName](e.detail);
    }
  }

  async completeNJUNSStep(options) {
    let nodeId = this.editingNode;
    let nodeAttributes = this.nodes[nodeId].attributes;
    let ticketId = PickAnAttribute(nodeAttributes, options.models.ticket_id_property);
    let nodeStep = SquashNulls(nodeAttributes, options.property, options.itemKey);
    this.njunsUser = this.njunsUser || this.get('user.email');
    this.njunsActionType = 'completeSteps';
    this.confirm(
      'Complete NJUNS Step',
      `Complete - ${nodeStep.code} ${nodeStep.status} Step ${nodeStep.sequence}`,
      'Complete Step',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'njuns',
      async () => {
        if (options.itemKey) {
          this.displayWarningsDialog({ title: 'NJUNS Complete Step Report', messagesList: [] });
          if (ticketId) {
            this.push(`warningsDialogData.messagesList`, { text: `Updating Ticket information` });
            let ticket = await this.getNjunsTicket(ticketId);
            let stepIds = [];
            ticket.steps.some((step) => {
              if (
                nodeStep.code == SquashNulls(step, 'assignedMember', 'code') &&
                nodeStep.sequence == step.sequence &&
                nodeStep.status == step.status
              ) {
                stepIds.push(step.id);
                return true;
              }
            });
            if (stepIds.length > 0) {
              this.push(`warningsDialogData.messagesList`, { text: `Completing Step` });
              let stepResult = await this.njunsApi('services/njuns_TicketService/completeSteps', {
                method: 'POST',
                data: {
                  ticketId: ticket.id,
                  stepIds
                }
              });
              this.push(`warningsDialogData.messagesList`, { text: `Updating Ticket information` });
              ticket = await this.getNjunsTicket(ticketId);
            } else {
              this.push(`warningsDialogData.messagesList`, { text: `Step not found` });
            }
            let update = {};
            await this.updateNodeFromNJUNSTicket(ticket, this.mappingButtons[options.models.seed_button].models, update, {
              onlyNjunsUpdate: true,
              node: this.nodes[nodeId]
            });
            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
            this.push(`warningsDialogData.messagesList`, { text: `Done Completing Step` });
          } else {
            this.push(`warningsDialogData.messagesList`, { text: `Unable to find Ticket Id` });
          }
        }
      }
    );
  }

  _button_offset_lines(e) {
    this.offsetLinesModels = this.activeCommandModel?.models;
    this.activeCommand = '_offsetLines';
    this.$.katapultMap.openActionDialog({ title: 'Choose a line to offset', icon: true });
    this.offsetLinesImport = import('./button_functions/offset_lines.js');
  }

  async promptOffsetLine(connId) {
    const { offsetLines } = await this.offsetLinesImport;
    const conn = this.connections[connId];
    const n1 = this.nodes[conn?.node_id_1];
    const n2 = this.nodes[conn?.node_id_2];
    await offsetLines(conn, n1, n2, this.offsetLinesModels, this.otherAttributes, this.jobStyles, this.job_id);
    this.cancelPromptAction();
  }

  async _button_seed_temperature_data(e) {
    this.$.toast.show('Scraping temperature data');

    let minLat = 999;
    let maxLat = -999;
    let minLon = 999;
    let maxLon = -999;
    let minDate = '9999-99-99';
    let maxDate = '0000-00-00';

    let updateDict = [];

    function compareDateString(a, b) {
      let aa = a.split('-');
      let bb = b.split('-');
      if (aa.length != 3 || bb.length != 3) return 0; //one of the date objs is invalid
      if (parseInt(aa[0]) == parseInt(bb[0])) {
        if (parseInt(aa[1]) == parseInt(bb[1])) {
          return aa[2] - bb[2];
        } else return aa[1] - bb[1];
      } else return aa[0] - bb[0];
    }

    for (let connectionId in this.connections) {
      let sections = SquashNulls(this.connections, connectionId, 'sections');
      for (let sectionId in sections) {
        let section = sections[sectionId];

        if (section && section.multi_attributes) {
          //we need a timebucket to seed temp data

          let existingTemp = PickAnAttribute(section.multi_attributes, 'collection_temperature');
          //if (existingTemp) continue; //don't need to seed temp data if we already have it - TODO add setting to reseed collected temp data

          let date = undefined;

          for (var tb of Object.values(SquashNulls(section, 'multi_attributes', 'time_bucket'))) {
            if (!tb.start) continue;
            let tbd = this.formatMomentDate(tb.start, 'YYYY-MM-DD');
            if (!date) date = tbd;
            else if (compareDateString(date, tbd) < 0) date = tbd; //make date newest timebucket start
          }

          if (date && section.latitude && section.longitude) {
            if (section.latitude < minLat) minLat = section.latitude;
            if (section.latitude > maxLat) maxLat = section.latitude;
            if (section.longitude < minLon) minLon = section.longitude;
            if (section.longitude > maxLon) maxLon = section.longitude;
            if (compareDateString(minDate, date) > 0) minDate = date;
            if (compareDateString(maxDate, date) < 0) maxDate = date;

            updateDict.push({ cId: connectionId, sId: sectionId, date: date }); //, photo: GetMainPhoto(section.photos)});
          }
        }
      }
    }

    if (minDate == '9999-99-99') {
      this.$.toast.show('No midspans need temperature data');
      return;
    }

    let stationId = null;
    let stationDist = 9999;
    let avgLat = (minLat + maxLat) / 2;
    let avgLon = (minLon + maxLon) / 2;

    let offset = 1;
    //find the station to fetch temp data for
    //needs tweaking, but essentially retry a few times until a station is found
    this.$.toast.show('Locating nearest station');

    minLat -= offset;
    maxLat += offset;
    minLon -= offset;
    maxLon += offset;

    //fetch data - find closest station
    await fetch(
      `https://www.ncdc.noaa.gov/cdo-web/api/v2/stations?extent=${minLat},${minLon},${maxLat},${maxLon}&startdate=${minDate}&datatypeid=TOBS`,
      {
        method: 'GET',
        headers: {
          token: 'LteJskNrClXqrRFeVLdlEhxmWhwVilkr'
        },
        data: {}
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        if (json.results)
          for (var station of Object.values(json.results)) {
            let dist = Math.sqrt(Math.pow(station.latitude - avgLat, 2) + Math.pow(station.longitude - avgLon, 2));
            if (dist < stationDist) {
              stationId = station.id;
              stationDist = dist;
            }
          }
      });

    if (!stationId) {
      //no station name means we failed
      this.$.toast.show('Failure - no stations within ±1° of job');
      return;
    }

    let dateDict = {};

    this.$.toast.show('Populating temperature dictionary');
    //fetch temperatures for date range
    await fetch(
      `https://www.ncdc.noaa.gov/cdo-web/api/v2/data?datasetid=${
        stationId.split(':')[0]
      }&datatypeid=TOBS&units=standard&stationid=${stationId}&startdate=${minDate}&enddate=${maxDate}&includemetadata=false&sortfield=date&sortorder=asc&limit=1000`,
      {
        method: 'GET',
        headers: {
          token: 'LteJskNrClXqrRFeVLdlEhxmWhwVilkr',
          Accept: 'application/json'
        },
        data: {}
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        if (json.results)
          for (var tempPair of Object.values(json.results)) {
            dateDict[tempPair.date.split('T')[0]] = tempPair.value;
          }
      });

    let update = {};

    for (var u of updateDict) {
      update[`connections/${u.cId}/sections/${u.sId}/multi_attributes/collection_temperature/button_added`] = dateDict[u.date] || 0;
      //update[`photos/${u.photo}/photofirst_data/collection_temperature/button_added`] = dateDict[u.date] || 0;
    }

    this.$.toast.show('Updating midspan data in firebase');

    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);

    this.$.toast.show('Temperatures updated');

    this.cancelPromptAction();
  }

  async njunsApi(path, options) {
    options = options || {};
    let token =
      options.token ||
      (await firebase.functions().httpsCallable('njunsAPI')({
        user: this.njunsUser,
        password: this.njunsPassword,
        testDatabase: this.njunsTestDatabase
      }));

    this.njunsToken = token;
    let url = (this.njunsTestDatabase ? 'https://test.njuns.com/app2018/rest/v2/' : 'https://www.njuns.com/app/rest/v2/') + path;
    var headers = new Headers();
    headers.append('Authorization', `Bearer ${token.data}`);
    headers.append('Content-Type', 'application/json');

    return fetch(url, {
      method: options.method || 'GET',
      headers,
      body: typeof options.data === 'object' ? JSON.stringify(options.data) : options.data,
      redirect: 'follow'
    })
      .then((response) => response.text())
      .then((response) => {
        let result = {};
        try {
          result = JSON.parse(response);
        } catch (e) {
          // normal result of no response
        }
        return result;
      });
  }

  async _button_seed_poles_from_njuns(e, models) {
    models = models || this.activeCommandModel.models;
    let query = JSON.parse(JSON.stringify(models.query));
    let flattenedQuery = Path.flattenObject(query);
    for (let path in flattenedQuery) {
      if (typeof flattenedQuery[path] === 'string' && flattenedQuery[path].startsWith('$') && path.endsWith('value')) {
        let item = Path.get(query, path.replace(/\.value$/, ''));
        item.value = this.get('metadata.' + flattenedQuery[path].slice(1));
      }
    }
    this.seedFromNjunsOptions = JSON.stringify(query, null, 4);

    this.njunsUser = this.njunsUser || this.get('user.email');
    this.njunsActionType = 'seedPoles';
    let lastUpdate = { time: 0, string: '' };
    this.confirm(
      'Seed Poles from NJUNS',
      null,
      'Seed Poles',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'njuns',
      async () => {
        // Make sure the json is valid
        try {
          JSON.parse(this.seedFromNjunsOptions);
        } catch (error) {
          this.shadowRoot.querySelector('#seedFromNjunsOptions').errorMessage = 'Invalid JSON';
          this.shadowRoot.querySelector('#seedFromNjunsOptions').invalid = true;
          setTimeout(() => {
            this._button_seed_poles_from_njuns(e);
          });
          return;
        }

        try {
          let files = this.shadowRoot.querySelector('#njunsDataInput').files;
          let result = null;
          if (files.length > 0) {
            result = {
              data: await new Promise((resolve) => {
                let reader = new FileReader();
                reader.onload = (e) => {
                  resolve(JSON.parse(e.target.result));
                };
                reader.readAsText(files[0]);
              })
            };
          } else {
            this.$.toast.show('Fetching data from NJUNS');
            result = await this.njunsApi('entities/njuns$Ticket/search?view=ticket-api-view', {
              method: 'POST',
              data: this.seedFromNjunsOptions
            });
          }
          this.$.toast.show('Parsing data from NJUNS');
          let update = {};
          let skippedTickets = [];
          if (result.error) {
            throw new Error(result.error + ' - ' + result.details);
          }
          for (let ticket of result) {
            if (ticket.updateTs) {
              let ticketUpdateTime = new Date(ticket.updateTs).getTime();
              if (ticketUpdateTime > lastUpdate.time) {
                lastUpdate = { time: ticketUpdateTime, string: ticket.updateTs };
              }
            }
            await this.updateNodeFromNJUNSTicket(ticket, models, update, {
              skippedTickets,
              justNodes: models.split_by,
              onlyNjunsUpdate: models.onlyNjunsUpdate
            });
            if (this.njunsSplitTickets) {
              let name = `CNP_${Path.get(ticket, '+PA-CenterPt:ServiceCenter').toUpperCase().slice(0, 3)}_${Path.get(ticket, 'referenceId')
                .toUpperCase()
                .slice(0, 3)} - Ticket #${Path.get(ticket, 'ticketNumber')}`;
              // Create the job using the job chooser element
              let newJob = await this.$.createJobForm.createNewJob({
                name,
                mapStyles: this.jobStyles,
                model: this.jobCreator,
                jobProjectFolderPath: this.project_folder,
                preventNewJobSelection: true
              });
              if (newJob.status != 'success') {
                throw new Error(`Error: ${newJob.message}`);
              }
              if (newJob.job_id) {
                update['metadata/seeded_from_njuns'] = true;
                await FirebaseWorker.ref('photoheight/jobs/' + newJob.job_id).update(update);
                update = {};
              }
            }
          }
          if (models.split_by) {
            let regions = {};
            let polesWithExceptions = {};
            let tagLookup = {};

            function setPoleException(nodeId, exception, note) {
              let node = update[nodeId];
              Path.set(node, `attributes.exception.missing_region`, exception);
              if (note) Path.set(node, `attributes.internal_note.${exception}`, note);
              polesWithExceptions[nodeId] = node;
              let region = PickAnAttribute(node.attributes, models.split_by.property);
              if (regions[region]) {
                delete regions[region].nodes[nodeId];
              }
            }

            for (let nodeId in update) {
              let node = update[nodeId];
              let region = PickAnAttribute(node.attributes, models.split_by.property);
              if (region) {
                regions[region] = regions[region] || { nodes: {}, update: {}, jobsToCheckAgainst: {} };
                regions[region].nodes[nodeId] = node;
              } else {
                setPoleException(nodeId, 'OTHER', `No ${models.split_by.property} Found`);
              }
              let tag = PickAnAttribute(node.attributes, 'pole_tag')?.trim();
              if (tag) tag = tag.tagtext;
              else setPoleException(nodeId, 'OTHER', `No tag Found`);
              if (tagLookup[tag]) {
                //This node
                setPoleException(nodeId, `DUPLICATE`);
                //Other node
                setPoleException(tagLookup[tag], 'DUPLICATE');
              } else {
                tagLookup[tag] = nodeId;
              }
            }
            let jobs = await FirebaseWorker.ref('photoheight/job_permissions/' + this.userGroup + '/list')
              .orderByChild('status')
              .equalTo('active')
              .once('value')
              .then((s) => s.val());
            for (let jobId in jobs) {
              let regionName = jobs[jobId].name.replace(models.split_by.job_prefix, '');
              if (regions[regionName]) {
                if (regions[regionName].jobId) {
                  throw new Error(`Error: Multiple jobs found matching ` + models.split_by.job_prefix + regionName);
                }
                regions[regionName].jobId = jobId;
              }
              if (models.split_by.check_jobs_prefix) {
                models.split_by.check_jobs_prefix.forEach((prefix) => {
                  let regionCheckName = jobs[jobId].name.replace(prefix, '');
                  if (regions[regionCheckName]) {
                    regions[regionCheckName].jobsToCheckAgainst[jobId] = {};
                  }
                });
              }
            }

            /////////// Begin check job for exceptions ///////////
            let checkJobForExceptions = async (jobId, region) => {
              let newNodes = region.nodes;
              let existingNodes = await FirebaseWorker.ref('photoheight/jobs/' + jobId + '/nodes')
                .once('value')
                .then((s) => s.val() || {});
              // Check for Unexpected Updates from NJUNS
              for (let nodeId in newNodes) {
                if (existingNodes[nodeId]) {
                  let newLastUpdate = PickAnAttribute(newNodes[nodeId].attributes, 'njuns_last_updated');
                  let oldLastUpdate = PickAnAttribute(existingNodes[nodeId].attributes, 'njuns_last_updated');
                  if (!newLastUpdate) {
                    setPoleException(nodeId, 'UNEXPECTED UPDATE', 'Missing updateTs in ticket, so cannot compare to existing node.');
                  } else if (!oldLastUpdate) {
                    setPoleException(nodeId, 'UNEXPECTED UPDATE', `Missing njuns_last_updated on node in ${models.split_by.property} map`);
                  } else if (new Date(newLastUpdate).getTime() > new Date(oldLastUpdate).getTime()) {
                    setPoleException(nodeId, 'UNEXPECTED UPDATE');
                  } else if (new Date(newLastUpdate).getTime() == new Date(oldLastUpdate).getTime()) {
                    // If it matches, skip it
                    delete newNodes[nodeId];
                  }
                }
              }

              //Check for duplicates of preexising pole tags (new ticket, same pole tag)
              for (let nodeId in existingNodes) {
                let poleTag = PickAnAttribute(existingNodes[nodeId].attributes, 'pole_tag').trim();
                if (poleTag && tagLookup[poleTag.tagtext] && tagLookup[poleTag.tagtext] != nodeId) {
                  setPoleException(tagLookup[poleTag.tagtext], 'DUPLICATE');
                }
              }

              //Check for other exceptions
              nodeLoop: for (let nodeId in newNodes) {
                let status = PickAnAttribute(newNodes[nodeId].attributes, 'status');
                let nonCompanyNTG = false;

                //Is PPL NTG with a dispute
                for (let stepKey in newNodes[nodeId].attributes.steps) {
                  let step = newNodes[nodeId].attributes.steps[stepKey];
                  if (step.status == 'NTG') {
                    if (models.company_codes.includes(step.code)) {
                      if (step.type == 'DISPUTE') {
                        //If it's closed or canceled, don't set exception, just skip it
                        if (status != 'CLOSED' && status != 'CANCELED') {
                          setPoleException(nodeId, 'DISPUTE');
                        }
                        continue nodeLoop;
                      }
                    } else {
                      nonCompanyNTG = true;
                    }
                  }
                }

                //todo Is this a pole replacement on a Make Ready Job? (push a note back into NJUNS)

                // If it has passed all these checks and is in these statuses, discard it
                if (status == 'DRAFT' || status == 'CLOSED' || status == 'CANCELED') {
                  continue nodeLoop;
                }

                // If it has passed all these checks and PPL is not NTG, discard it
                if (nonCompanyNTG) {
                  continue nodeLoop;
                }

                // If we passed all the checks, add it to the update!
                region.update['nodes/' + nodeId] = newNodes[nodeId];
                GeofireTools.setGeohash('nodes', newNodes[nodeId], nodeId, this.jobStyles, region.update, {});
              }
            };
            /////////// End check job for exceptions ///////////

            for (let regionName in regions) {
              let region = regions[regionName];
              let newNodes = region.nodes;

              // Create job if needed
              if (!region.jobId && models.split_by.create_jobs) {
                let newJob = await this.$.createJobForm.createNewJob({
                  name: models.split_by.job_prefix + regionName,
                  mapStyles: this.jobStyles,
                  model: this.jobCreator,
                  jobProjectFolderPath: this.project_folder,
                  preventNewJobSelection: true
                });
                if (newJob.status != 'success') {
                  throw new Error(`Error: ${newJob.message}`);
                }
                if (newJob.job_id) {
                  region.jobId = newJob.job_id;
                }
              }

              // Check against region nodes
              if (region.jobId) {
                await checkJobForExceptions(region.jobId, region);
                for (let jobId in region.jobsToCheckAgainst) {
                  await checkJobForExceptions(jobId, region);
                }
              } else {
                console.error(`Error: No job found matching "${models.split_by.job_prefix}${regionName}"`);
              }
            }
            let masterUpdate = {};
            if (lastUpdate.string) {
              masterUpdate['metadata/last_update_boundary_date'] = lastUpdate.string;
            }
            for (let nodeId in polesWithExceptions) {
              masterUpdate['nodes/' + nodeId] = polesWithExceptions[nodeId];
              GeofireTools.setGeohash('nodes', polesWithExceptions[nodeId], nodeId, this.jobStyles, masterUpdate, {});
            }
            await FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(masterUpdate);
            for (let regionName in regions) {
              if (regions[regionName].jobId) {
                this.$.toast.show('Writing Data for ' + regionName);
                await FirebaseWorker.ref('photoheight/jobs/' + regions[regionName].jobId).update(regions[regionName].update);
              }
            }
          } else {
            this.$.toast.show('Writing Data');
            await FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
          }
          if (skippedTickets.length > 0 && models.download_malformed_poles) {
            let link = document.createElement('a');
            let blob = new Blob([JSON.stringify(skippedTickets, null, 4)], {
              type: 'application/json'
            });
            link.href = URL.createObjectURL(blob);
            link.download = 'Skipped Tickets.json';
            link.click();
          }
          this.$.toast.show('Import Complete');
          this.cancelPromptAction();
        } catch (e) {
          this.$.toast.show(e.message);
          this.cancelPromptAction();
          throw e;
        }
      }
    );
  }

  async updateNodeFromNJUNSTicket(ticket, models, update, options) {
    for (let asset of ticket.assets) {
      let skipTicket = false;
      let nodeId = asset.id;
      if (options.onlyNjunsUpdate && !options.node) {
        options.node = this.nodes[nodeId];
      }
      let node = options.node || {
        latitude: asset.latitude,
        longitude: asset.longitude,
        attributes: {}
      };
      if (!node.latitude || !node.longitude) {
        if (models.calc_location_from_grid == 'ppl' && asset.assetId) {
          let location = PplTagLocation(asset.assetId);
          if (location) {
            node.latitude = location.latitude;
            node.longitude = location.longitude;
            Path.set(node.attributes, 'internal_note.calced_pole_tag', 'Location calculated from pole tag');
          } else {
            skipTicket = true;
          }
        } else {
          skipTicket = true;
        }
      }
      if (skipTicket && options.skippedTickets) {
        options.skippedTickets.push(ticket);
      } else {
        // Add Attributes
        for (let property in models.attributes) {
          if (property == 'pole_tag') {
            let tag = {};
            for (let key in models.attributes[property]) {
              await this.njunsComputeValue(models.attributes[property], key, tag, ticket, asset, null, options);
            }
            Path.set(node.attributes, property + '.-imported', tag);
          } else {
            await this.njunsComputeValue(models.attributes, property, node.attributes, ticket, asset, '.-imported', options);
          }
        }

        if (models.ppl_lookup && !options.onlyNjunsUpdate) {
          this.$.toast.show('Looking up ' + asset.assetId);
          let ref = firebase.app().database(`https://${models.ppl_lookup.database}.firebaseio.com`).ref(models.ppl_lookup.url);
          let lookup = null;
          if (this.validFirebaseKey(asset.assetId)) {
            lookup = await ref
              .child(asset.assetId)
              .once('value')
              .then((s) => s.val());
          } else {
            Path.set(node.attributes, 'internal_note.lookup', `Could not lookup pole due to bad tag`);
          }

          if (!lookup) {
            Path.set(node.attributes, 'exists_in_EFD.-imported', false);
            if ((node.latitude, node.longitude)) {
              //Radius set to 1000ft (0.3048 km)
              let closestPoles = await new GeoFire(ref).once({ radius: 0.3048, center: [node.latitude, node.longitude] });
              let poleLocation = new google.maps.LatLng(node.latitude, node.longitude);
              let closest = { pole: null, distance: Infinity };
              for (let poleId in closestPoles) {
                let distance = google.maps.geometry.spherical.computeDistanceBetween(
                  poleLocation,
                  new google.maps.LatLng(...closestPoles[poleId].l)
                );
                if (closest.distance > distance) {
                  closest = { pole: closestPoles[poleId], distance };
                }
              }
              if (closest.pole) {
                // set the region based off the closest pole
                Path.set(node.attributes, 'region.-imported', Path.get(closest.pole, 'info.region') || '');
              }
            } else {
              Path.set(node.attributes, 'internal_note.lookup_closest', `Could not lookup nearby poles due to bad latitude/longitude`);
            }
          } else {
            // Set pole tag Owner
            Path.set(node.attributes, 'pole_tag.-imported.owner', Path.get(lookup, 'info.pole_owner') == 'PPL Company');
            // Set exists_in_EFD
            Path.set(node.attributes, 'exists_in_EFD.-imported', true);
            // Set region
            Path.set(node.attributes, 'region.-imported', Path.get(lookup, 'info.region') || '');

            // Set lat/long
            if (lookup.l) {
              node.latitude = lookup.l[0];
              node.longitude = lookup.l[1];
            } else {
              Path.set(node.attributes, 'internal_note.lookupll', `Pole Lookup does not have location`);
            }
          }
        }

        // Import Existing Attachers
        if (models.import_attachers && !options.onlyNjunsUpdate) {
          if (this.validFirebaseKey(asset.assetId)) {
            let attachments = await firebase
              .app()
              .database(`https://${models.import_attachers.database}.firebaseio.com`)
              .ref(models.import_attachers.url + asset.assetId)
              .once('value')
              .then((s) => s.val());
            let existingAttachers = [];
            for (let id in attachments) {
              if (id.startsWith('id_')) {
                attachments[id].forEach((att) => {
                  existingAttachers.push({
                    attachment_order: att.order,
                    attachment_type: att.attach_type,
                    company: att.name,
                    agreement_number: id.slice(3)
                  });
                });
              }
            }
            existingAttachers.sort((a, b) => Number(a.attachment_order) - Number(b.attachment_order));
            existingAttachers.forEach((attacher, index) => {
              Path.set(node.attributes, models.import_attachers.property + '.-imported' + index, attacher);
            });
          } else {
            Path.set(node.attributes, 'internal_note.import_attachers', 'Could not import attachers due to bad tag');
          }
        }

        // Set data in firebase
        // If we are going to split the poles by region, we just want the nodes and we will manipulate the update later
        if (options.justNodes) {
          update[nodeId] = node;
        }
        // Otherwise, Flatten the update
        else {
          update = Object.assign(update, Path.flattenObject({ nodes: { [nodeId]: node } }, '/'));

          // Steps should be overwritten as a whole, rather than a flat update
          for (let path in update) {
            if (path.includes('/steps/')) {
              let split = path.split('/steps/');
              update[split[0] + '/steps'] = update[split[0] + '/steps'] || {};
              Path.set(update[split[0] + '/steps'], split[1], update[path], '/');
              delete update[path];
            }
          }
          GeofireTools.setGeohash('nodes', node, nodeId, this.jobStyles, update, {});
        }
      }
    }
  }

  async njunsComputeValue(attributes, property, value, ticket, asset, subKey, options) {
    subKey = subKey || '';
    if (attributes[property]._type == 'lookup' && !options.onlyNjunsUpdate) {
      if (this.validFirebaseKey(asset.assetId)) {
        this.$.toast.show('Looking up ' + asset.assetId);
        let lookup = await firebase
          .app()
          .database(`https://${attributes[property].database}.firebaseio.com`)
          .ref(attributes[property].url + asset.assetId + (attributes[property].subpath || ''))
          .once('value')
          .then((s) => s.val());
        if (attributes[property].compare) {
          if (attributes[property].compare.equal) {
            lookup = attributes[property].compare.equal == lookup;
          } else if (attributes[property].compare.exists) {
            lookup = lookup != null;
          } else {
            throw new Error('Error - Button improperly configured to set ' + property);
          }
        }
        Path.set(value, property + subKey, lookup);
      } else {
        Path.set(value, 'internal_note.lookup' + property, `Could not lookup ${property} due to bad tag`);
      }
    } else if (attributes[property]._type == 'table') {
      let list = ticket[attributes[property].property];
      if (list && Array.isArray(list)) {
        let tableAttributes = attributes[property].attributes;
        list.forEach((item, index) => {
          let val = {};
          for (let prop in tableAttributes) {
            if (typeof tableAttributes[prop] === 'string' && tableAttributes[prop][0] == '$') {
              val[prop] = Path.get(item, tableAttributes[prop].slice(1)) || '';
            } else {
              val[prop] = tableAttributes[prop];
            }
            Path.set(value, property + subKey + (attributes[property].sort ? item[attributes[property].sort] : index), val);
          }
        });
      }
    } else if (typeof attributes[property] === 'string' && attributes[property][0] == '$') {
      let val;
      if (attributes[property].startsWith('$$ntgStep.')) {
        let ntgStep = ticket.steps.find((step) => step.status == 'NTG');
        val = Path.get(ntgStep, attributes[property].slice(10)) || '';
      } else if (attributes[property].startsWith('$asset.')) {
        val = Path.get(asset, attributes[property].slice(7)) || '';
      } else {
        val = Path.get(ticket, attributes[property].slice(1)) || '';
      }
      Path.set(value, property + subKey, val);
    } else if (!options.onlyNjunsUpdate) {
      Path.set(value, property + subKey, attributes[property]);
    }
  }

  _button_sync_njuns_tickets(e) {
    this.selectedNode = null;
    this.njunsButtonModels = this.activeCommandModel.models;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: false
    };
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around poles to pull fresh data from their NJUNS Tickets. Right click to delete polygon points.',
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Update',
          callback: this.syncNjunsTickets.bind(this),
          attributes: { style: 'background-color: #971a1d; color: white; padding: 12px; margin:0 5px' }
        }
      ]
    });
  }

  async syncNjunsTickets() {
    let models = this.njunsButtonModels;
    this.multiSelectedNodes = [];
    this.multiSelectedNodes = this.$.katapultMap.multiSelectedNodes;
    this.njunsUser = this.njunsUser || this.get('user.email');
    this.njunsActionType = null;
    this.$.katapultMap.cancel();
    let ticketIds = [];
    for (let nodeId of this.multiSelectedNodes) {
      let ticketId = PickAnAttribute(this.nodes[nodeId].attributes, models.ticket_id_property);
      if (ticketId) {
        ticketIds.push(ticketId);
      }
    }
    let seedModels = this.mappingButtons[models.seed_button].models;
    delete seedModels.split_by;
    seedModels.onlyNjunsUpdate = true;
    seedModels.query = {
      filter: {
        conditions: [
          {
            operator: 'in',
            property: 'ticketId',
            value: ticketIds
          }
        ]
      },
      view: 'ticket-api-view'
    };
    this._button_seed_poles_from_njuns(e, seedModels);
  }

  _button_close_njuns_tickets(e) {
    this.selectedNode = null;
    this.njunsButtonModels = this.activeCommandModel.models;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: false
    };
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around poles to close their tickets. Right click to delete polygon points.',
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Finish',
          callback: this.closeNjunsTickets.bind(this),
          attributes: { style: 'background-color: #971a1d; color: white; padding: 12px; margin:0 5px' }
        }
      ]
    });
  }

  _button_create_njuns_tickets(e) {
    this.njunsActionType = 'createTickets';
    let models = this.activeCommandModel.models;
    this.confirm(
      'Create NJUNS Tickets',
      '',
      'Continue',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'njuns',
      async (e) => {
        let button = e.currentTarget;
        button.loading = true;
        await import('./njuns-ticket-creator');
        await this.$.njunsTicketCreator.open(this.njunsCreateTicketPoleNumbers, this.njunsCreateCompleteTicketsWithoutPrompt);
        button.loading = false;
        this.$.confirmDialog.close();
        const layer = this.mapLayers.find((x) => this.layerIdIsUtilityRefLayerType(x.$key, 'poles', 'ppl'));
        await this.toggleMapLayer(layer, true);
      },
      null,
      null,
      { closeOnConfirm: false }
    );

    this.njunsUser = this.njunsUser || this.get('user.email');

    let focus = 'njunsPoleNumbers';
    if (!this.njunsUser) focus = 'njunsUser';
    else if (!this.njunsPassword) focus = 'njunsPassword';
    setTimeout(() => {
      this.shadowRoot.querySelector('#' + focus).focus();
    }, 300);
    this.cancelPromptAction();
    import('./njuns-ticket-creator');
  }

  async geocode(lat, lng, timeout = 0) {
    return new Promise(async (resolve, reject) => {
      if (timeout) await new Promise((x) => setTimeout(x, timeout));
      this.googleGeocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK') {
          let address = {};
          results[0].address_components.forEach((item) => {
            address[item.types[0]] = item.long_name;
          });
          resolve(address);
        } else if (status == 'OVER_QUERY_LIMIT') {
          timeout = Math.random() * 1000;
          resolve(this.geocode(lat, lng, timeout));
        } else {
          reject(results, status);
        }
      });
    });
  }

  async closeNjunsTickets() {
    let models = this.njunsButtonModels;
    this.multiSelectedNodes = [];
    this.multiSelectedNodes = this.$.katapultMap.multiSelectedNodes;
    this.njunsUser = this.njunsUser || this.get('user.email');
    this.njunsActionType = 'closeTickets';
    this.$.katapultMap.cancel();
    this.confirm(
      'Close NJUNS Tickets',
      `Close tickets for ${this.multiSelectedNodes.length} poles`,
      'Close Tickets',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'njuns',
      async () => {
        let nodeWarnings = [];
        try {
          this.displayWarningsDialog({ title: 'NJUNS Close Ticket Report', messagesList: [] });
          for (let nodeId of this.multiSelectedNodes) {
            let warnings = [];
            let description = SquashNulls(PickAnAttribute(this.nodes[nodeId].attributes, 'pole_tag').trim(), 'tagtext') || '(No Tag)';
            let ticketId = PickAnAttribute(this.nodes[nodeId].attributes, models.ticket_id_property);
            if (ticketId) {
              this.push(`warningsDialogData.messagesList`, { text: `Updating Ticket information for ${description}` });
              let ticket = await this.getNjunsTicket(ticketId);
              if (ticket) {
                if (models.seed_button && this.mappingButtons[models.seed_button]) {
                  let update = {};
                  await this.updateNodeFromNJUNSTicket(ticket, this.mappingButtons[models.seed_button].models, update, {
                    onlyNjunsUpdate: true,
                    node: this.nodes[nodeId]
                  });
                  await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
                }
                let stepIds = [];
                let unclosedSteps = [];
                for (let step of ticket.steps) {
                  if (step.status != 'COMPLETE') {
                    if (models.member_steps_to_close && models.member_steps_to_close.includes(step.assignedMember.code)) {
                      stepIds.push(step.id);
                    } else {
                      //steps couldn't close
                      unclosedSteps.push(step.id);
                      let code = SquashNulls(step, 'assignedMember', 'code');
                      warnings.push({
                        text: `Could not complete step - ${code} - ${step.status}`,
                        key: `step_open_${code}_${step.sequence}`
                      });
                    }
                  }
                }
                if (stepIds.length > 0) {
                  this.push(`warningsDialogData.messagesList`, { text: `Completing ${stepIds.length} Steps` });
                  let stepResult = await this.njunsApi('services/njuns_TicketService/completeSteps', {
                    method: 'POST',
                    data: {
                      ticketId: ticket.id,
                      stepIds
                    }
                  });
                }
                if (unclosedSteps.length == 0) {
                  if (ticket.status != 'CLOSED') {
                    this.push(`warningsDialogData.messagesList`, { text: `Closing Ticket` });
                    let result = await this.njunsApi(
                      `services/njuns_TicketService/closeTicket?ticketId=${ticket.id}&comment=${encodeURIComponent(
                        this.njunsCloseTicketComment || ''
                      )}`
                    );
                    if (result.error) {
                      throw new Error(result.error + ' - ' + result.details);
                    }

                    if (models.seed_button && this.mappingButtons[models.seed_button]) {
                      // Re-load the ticket information after it is closed
                      let updatedTicket = await this.getNjunsTicket(ticketId);
                      this.push(`warningsDialogData.messagesList`, { text: `Updating Ticket information` });
                      let update = {};
                      await this.updateNodeFromNJUNSTicket(updatedTicket, this.mappingButtons[models.seed_button].models, update, {
                        onlyNjunsUpdate: true,
                        node: this.nodes[nodeId]
                      });
                      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
                    }
                  } else {
                    this.push(`warningsDialogData.messagesList`, { text: `Ticket Already Closed` });
                  }
                } else {
                  this.push(`warningsDialogData.messagesList`, { text: `Incomplete Steps` });
                }
              }
            } else {
              //ticket id not found
              warnings.push({ text: `Ticket Id Not found`, key: `ticket_id_not_found` });
            }
            if (warnings.length > 0) {
              nodeWarnings.push({ key: nodeId, generalType: 'node', description, warnings });
            }
          }
          this.push(`warningsDialogData.messagesList`, { text: 'Done Closing Tickets.' });
        } catch (e) {
          this.push(`warningsDialogData.messagesList`, { text: 'Error - ' + e.message });
          this.cancelPromptAction();
          throw e;
        } finally {
          this.set(`warningsDialogData.nodeWarnings`, nodeWarnings);
        }
      }
    );
  }

  _button_cancel_njuns_tickets(e) {
    this.selectedNode = null;
    this.njunsButtonModels = this.activeCommandModel.models;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: false
    };
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around poles to cancel their tickets. Right click to delete polygon points.',
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Finish',
          callback: this.cancelNjunsTickets.bind(this),
          attributes: { style: 'background-color: #971a1d; color: white; padding: 12px; margin:0 5px' }
        }
      ]
    });
  }

  async cancelNjunsTickets() {
    let models = this.njunsButtonModels;
    this.multiSelectedNodes = [];
    this.multiSelectedNodes = this.$.katapultMap.multiSelectedNodes;
    this.njunsUser = this.njunsUser || this.get('user.email');
    this.njunsActionType = 'closeTickets';
    this.$.katapultMap.cancel();
    this.confirm(
      'Cancel NJUNS Tickets',
      `Cancel tickets for ${this.multiSelectedNodes.length} poles`,
      'Cancel Tickets',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'njuns',
      async () => {
        let nodeWarnings = [];
        try {
          this.displayWarningsDialog({ title: 'NJUNs Cancel Ticket Report', messagesList: [] });
          for (let nodeId of this.multiSelectedNodes) {
            let warnings = [];
            let description = SquashNulls(PickAnAttribute(this.nodes[nodeId].attributes, 'pole_tag').trim(), 'tagtext') || '(No Tag)';
            let ticketId = PickAnAttribute(this.nodes[nodeId].attributes, models.ticket_id_property);
            if (ticketId) {
              let ticket = await this.getNjunsTicket(ticketId);
              if (ticket) {
                if (ticket.status == 'CLOSED' || ticket.status == 'CANCELED') {
                  this.push(`warningsDialogData.messagesList`, {
                    text: `Ticket Already ${ticket.status == 'CLOSED' ? 'Closed' : 'Canceled'}`
                  });
                  let update = {};
                  await this.updateNodeFromNJUNSTicket(ticket, this.mappingButtons[models.seed_button].models, update, {
                    onlyNjunsUpdate: true,
                    node: this.nodes[nodeId]
                  });
                  await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
                } else {
                  this.push(`warningsDialogData.messagesList`, { text: `Canceling Ticket` });
                  let result = await this.njunsApi(
                    `services/njuns_TicketService/cancelTicket?ticketId=${ticket.id}&comment=${encodeURIComponent(
                      this.njunsCloseTicketComment || ''
                    )}`
                  );
                  if (result.error) {
                    throw new Error(result.error + ' - ' + result.details);
                  }

                  if (models.seed_button && this.mappingButtons[models.seed_button]) {
                    // Re-load the ticket information after it is canceled
                    let updatedTicket = await this.getNjunsTicket(ticketId);
                    this.push(`warningsDialogData.messagesList`, { text: `Updating Ticket information` });
                    let update = {};
                    await this.updateNodeFromNJUNSTicket(updatedTicket, this.mappingButtons[models.seed_button].models, update, {
                      onlyNjunsUpdate: true,
                      node: this.nodes[nodeId]
                    });
                    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
                  }
                }
              } else {
                this.push(`warningsDialogData.messagesList`, { text: `Ticket Not Found in NJUNS` });
              }
            } else {
              //ticket id not found
              warnings.push({ text: `Ticket Id Not found`, key: `ticket_id_not_found` });
            }
            if (warnings.length > 0) {
              nodeWarnings.push({ key: nodeId, generalType: 'node', description, warnings });
            }
          }
          this.push(`warningsDialogData.messagesList`, { text: 'Done Canceling Tickets.' });
        } catch (e) {
          this.push(`warningsDialogData.messagesList`, { text: 'Error - ' + e.message });
          this.cancelPromptAction();
          throw e;
        } finally {
          this.set(`warningsDialogData.nodeWarnings`, nodeWarnings);
        }
      }
    );
  }

  async getNjunsTicket(ticketId) {
    let ticketResult = await this.njunsApi('entities/njuns$Ticket/search?view=ticket-api-view', {
      method: 'POST',
      data: {
        filter: {
          conditions: [
            {
              operator: '=',
              property: 'ticketId',
              value: ticketId
            }
          ]
        },
        view: 'ticket-api-view'
      }
    });
    if (ticketResult.error) {
      throw new Error(ticketResult.error + ' - ' + ticketResult.details);
    }
    return ticketResult[0];
  }

  validFirebaseKey(string) {
    return string.search(/[\.#\$\[\]]/g) == -1;
  }

  _button_spida_pole_loading(e) {
    // Cancel any current action and clear variables, including
    // the list of multiselected sections
    this.cancelPromptAction();
    // Clear whatever is selected on the map
    this.clearMapSelection();
    // Tell the multi select counter to show nodes and connections
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: false
    };
    // Set the active command to multiselect
    this.activeCommand = '_multiSelectItems';
    // Set the prompt for the user
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around them to run pole loading. Right click to delete polygon points.',
      buttons: [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Pole Load Selected', callback: this.continueSpidaPoleLoading.bind(this), attributes: { grey: '' } },
        { title: 'Pole Load All', callback: this.continueSpidaPoleLoading.bind(this, true), attributes: { 'secondary-color': '' } }
      ]
    });
  }

  async continueSpidaPoleLoading(shouldAssociateAll) {
    // Clear multiSelectedNodes
    this.multiSelectedNodes = [];
    // Set multiSelectedNodes to all the nodes, or just the ones selected, depending on shouldAssociateAll
    var multiSelectedNodes = shouldAssociateAll == true ? Object.keys(this.nodes) : this.$.katapultMap.multiSelectedNodes;

    multiSelectedNodes = multiSelectedNodes
      .filter((nodeId) =>
        this.modelDefaults.pole_node_types.includes(PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute))
      )
      .sort((a, b) => {
        return (PickAnAttribute(this.nodes[a].attributes, this.modelDefaults.ordering_attribute) || '').localeCompare(
          PickAnAttribute(this.nodes[b].attributes, this.modelDefaults.ordering_attribute) || ''
        );
      });
    this.cancelPromptAction();
    await import('./exports/export-manager.js');
    await import('./exports/spida-export.js');
    let data = await GetJobData(this.job_id, 'photos');
    this.$.exportManager.photos = data.photos;
    var json = await this.$.exportManager.$.spidaExport.getExport(
      multiSelectedNodes,
      null,
      SquashNulls(this.$.exportManager.exportModels, 'spida'),
      { existing: true }
    );
    var request = {
      clientFile: 'PPL_v0',
      loadCase: 'NESC Heavy Load (Grade B)',
      poles: json.leads[0].locations.map((location) => {
        location.designs[0].structure.label = location.label;
        return location.designs[0].structure;
      })
    };
    request.poles = JSON.stringify(request.poles);
    var requestKey = FirebaseWorker.ref('photoheight/server_requests/spida_api/requests').push(request).key;
    FirebaseWorker.ref('photoheight/server_requests/spida_api/responses/' + requestKey).on('value', async (snapshot) => {
      var response = snapshot.val();
      if (response) {
        if (response.error) {
          snapshot.ref.remove();
          console.log(response);
          this.toast(`Error: ${response.error}. Check the console for more info`, null, 6000);
        } else {
          if (response.detail) {
            if (response.detail.result) {
              var update = {};
              var nodeId = response.detail.nodeId;
              update['/nodes/' + nodeId + '/attributes/loading_result'] = { spida_calc: response.detail.result };
              update['/nodes/' + nodeId + '/attributes/loading_notes/spida_calc'] =
                `Stress: ${response.detail.STRESS}%; Buckling: ${response.detail.BUCKLING}%`;
              this.nodes[nodeId].attributes.loading_result = update['/nodes/' + nodeId + '/attributes/loading_result'];
              this.nodes[nodeId].attributes.loading_notes = this.nodes[nodeId].attributes.loading_notes || {};
              this.nodes[nodeId].attributes.loading_notes.spida_calc = update['/nodes/' + nodeId + '/attributes/loading_notes/spida_calc'];
              GeofireTools.updateStyle('nodes', nodeId, this.nodes[nodeId], update, this.jobStyles);
              await FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
              snapshot.ref.remove();
            }
          }
          if (response.message) {
            this.toast(response.message, null, 4000);
          }
        }
      }
    });
  }

  async _button_prep_for_post_construction(e) {
    this.preppingForPostConstruction = true;
    // reset important variables for pci before we prep
    this.snapshotMapStylesKey = null;
    // get the list of available map styles to choose from
    let mapStylesList = await FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/models/map_styles`)
      .once('value')
      .then((s) => s.val());
    this.mapStylesListPCI = Object.keys(mapStylesList).map((mapStylesKey) => {
      return { key: mapStylesKey, label: ToTitleCase(mapStylesKey) };
    });
    // if we have a pci map style, auto fill the map styles dropdown
    if (mapStylesList['pci']) this.snapshotMapStylesKey = 'pci';
    // open the snapshot creation dialog

    // Build/set the snapshot name so that it can be displayed when the dialog opens
    const date = DateTime.now();
    const formattedDate = date.toFormat('yyyy-LL-dd');
    this.newSnapshotName = `PCI Snapshot ${formattedDate}`;

    this.confirm(
      'Prep Job For Post Construction',
      'To prep this job, a snapshot of the existing job will be made. Additionally, all photos will be marked as pre-construction, Post Construction Job Styles will be loaded, and Field Completed, Done & Proposed will be cleared.',
      'Prep Job',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'createSnapshot',
      async () => {
        this.preppingForPostConstruction = false;
        await this.$.infoPanel.$.snapshots.createSnapshot(this.newSnapshotName, this.newSnapshotNumbers, this);
        await new Promise((x) => setTimeout(x, 500));
        const includeMidspansForPCI = this.includeMidspansForPCI || null;
        const prepForPCISubsequentRounds = this.enabledFeatures?.prep_for_PCI_subsequent_rounds || null;
        await PrepForPostConstruction.run(
          this.job_id,
          this.jobCreator,
          this.userGroup,
          this.activeCommandModel.models,
          this.modelDefaults,
          this.toast.bind(this),
          this.snapshotMapStylesKey,
          this.otherAttributes,
          {
            includeMidspansForPCI,
            prepForPCISubsequentRounds
          }
        );
        this.cancelPromptAction();
      }
    );
  }

  showMidspanOptionForPCI() {
    // show the option to include midspan photos in PCI if we've clicked on the prep for post construction button and the current userGroup has the proper feature flag
    // Note: we may need to expand this option using feature flags to other companies in the future when we make PCI
    // more widely available, but for now, we only want to show this option for TRC.
    return this.preppingForPostConstruction && this.enabledFeatures?.pci_midspans;
  }

  showSubsequentPCIRounds() {
    return this.preppingForPostConstruction && this.enabledFeatures?.prepForPCISubsequentRounds;
  }

  async _button_generate_pole_id(e) {
    let update = {};
    for (const [key, value] of Object.entries(this.nodes ?? {})) {
      let nodeType = PickAnAttribute(value.attributes, 'node_type');
      let wl = PickAnAttribute(value.attributes, 'WL') || '__';
      let tagText = null;
      let poleTags = value?.attributes?.pole_tag;
      if (poleTags) {
        poleTags = Object.values(poleTags);
        for (let i = 0; i < poleTags.length; i++) {
          if (poleTags[i].owner == true) {
            tagText = poleTags[i].tagtext;
          }
        }
        let scid = PickAnAttribute(value.attributes, 'scid');
        if (nodeType == 'pole' && wl && scid && tagText) {
          let poleID = 'WL' + wl + ' - ' + tagText + ' - SCID ' + scid;

          update[`${key}/attributes/pole_ID/button_added`] = poleID;
        }
      }
    }

    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
  }

  async _button_make_survey_available(e) {
    this.$.toast.show('Checking Survey Status');
    this.surveyAvailableJobError = '';
    if (this.jobName.endsWith(' SR')) {
      this.surveyAvailableSR = true;
      let mainJob = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/jobs`)
        .orderByChild('name')
        .equalTo(this.jobName.replace(' SR', ''))
        .once('value')
        .then((s) => s.val());
      if (mainJob) {
        this.surveyAvailableSRJob = Object.keys(mainJob)[0];
      } else {
        this.surveyAvailableSRJob = '';
      }
    } else {
      this.surveyAvailableSRJob = this.job_id;
    }
    let survey_available = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/metadata/survey_available`)
      .once('value')
      .then((s) => s.val());
    if (!survey_available) {
      this.surveyAvailableWr = this.metadata?.wr_number;
      this.surveyAvailableWo = this.metadata?.wo_number;
      this.$.toast.close();
      this.confirm(
        'Make Survey Data Available',
        '',
        'Confirm',
        'Cancel',
        'background-color:var(--secondary-color); color:white;',
        'makeSurveyAvailable',
        async () => {
          if (this.surveyAvailableSR && !this.surveyAvailableSRJob) {
            this.surveyAvailableJobError = 'Please Select a Job';
            return;
          } else {
            this.$.confirmDialog.close();
          }
          this.$.toast.show('Notifying Applicant');
          let update = {};
          update['jobs/' + this.job_id + '/status/published'] = true;
          update['jobs/' + this.job_id + '/metadata/survey_available'] = true;
          if (this.surveyAvailableSR && this.surveyAvailableSRJob != this.job_id) {
            update['jobs/' + this.surveyAvailableSRJob + '/metadata/service_request_survey'] =
              this.config.firebaseData.origin_url + '/map#' + this.job_id;
          } else if (this.surveyAvailableWr && this.surveyAvailableWo) {
            update['jobs/' + this.job_id + '/metadata/wr_number'] = this.surveyAvailableWr;
            update['jobs/' + this.job_id + '/metadata/wo_number'] = this.surveyAvailableWo;
          }
          let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);

          //Pole Lookup
          for (let nodeId in this.nodes) {
            let tags = SquashNulls(this.nodes[nodeId].attributes, 'pole_tag');
            let mainPhotoId = null;
            for (let photoId in this.nodes[nodeId].photos) {
              if (this.nodes[nodeId].photos[photoId] == 'main' || this.nodes[nodeId].photos[photoId].association == 'main') {
                mainPhotoId = photoId;
                break;
              }
            }
            for (let tagId in tags) {
              if (tags[tagId].company == 'PPL' || tags[tagId].company == 'PPL Company') {
                update[
                  'pole_lookup/PPL Company/' + FirebaseEncode.encode(tags[tagId].tagtext) + '/jobs/' + this.job_id + '/nodes/' + nodeId
                ] = {
                  photoId: mainPhotoId,
                  date_taken: SquashNulls(photos, mainPhotoId, 'date_taken') || null
                };
              }
            }
          }

          await UpdateJobPermissions(
            this.job_id,
            this.userGroup,
            { published: true },
            {
              jobChooserOptions: this.get('companyOptions.job_chooser'),
              updatePath: 'photoheight',
              sharing: this.sharedCompanies,
              update
            }
          );

          let companies = await this.shareWithVZ(this.job_id, this.nodes, this.traces);
          if (companies.length) {
            let jobPermissions = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/jobs/${this.job_id}`)
              .once('value')
              .then((s) => s.val());
            jobPermissions.permission = 'read';
            jobPermissions.published = true;
            for (let companyId of companies) {
              // only set job permissions for companies that haven't already been shared with
              if (!this.sharedCompanies[companyId]) {
                await UpdateJobPermissions(this.job_id, this.userGroup, jobPermissions, {
                  jobChooserOptions: this.get('companyOptions.job_chooser'),
                  skipAddingMainCompanyToSharing: true,
                  sharing: { [companyId]: true },
                  updatePath: 'photoheight',
                  wipeExistingData: true,
                  update
                });
                update[`jobs/${this.job_id}/sharing/${companyId}`] = 'read';
              }
            }
          }

          // Check the applications poles into each company's portal MLD
          const takePortalActionsClient = firebase.functions().httpsCallable('takePortalActionsClient_v2');
          await takePortalActionsClient({ jobKey: this.job_id, sourceType: 'MANUAL_APP_DIRECTORIES_UPDATE' });

          const buttonModelDomain = this.mappingButtons?.[this.activeCommand]?.models?.domain;
          let domain = buttonModelDomain || this.config.firebaseData.origin_url;
          domain = domain.replace(/\/+$/, '');

          FirebaseWorker.ref('photoheight').update(update, async (error) => {
            if (error) {
              this.toast(error);
            }
            const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
            let message = {
              subject: `Survey data for ${this.jobName}`,
              text: `Survey data for ${this.jobName} is available. \r\n\r\nLog in to ${domain}` + `/map/#${this.job_id} to view it.`,
              to: [],
              group_tag: 'survey_data'
            };
            firebase
              .functions()
              .httpsCallable('poleAppContact')({ jobId: this.job_id, message })
              .then((result) => {
                this.$.toast.show('Applicant notified');
              })
              .catch((err) => {
                this.$.toast.show(err.message);
              });
          });
          this.cancelPromptAction();
        },
        null,
        null,
        { closeOnConfirm: false }
      );
    } else this.toast('The applicant has already been notified.');
  }

  async shareWithVZ(jobId, nodes, traces) {
    let shareWithVZ = false;
    nodes =
      nodes ||
      (await firebase
        .database()
        .ref(`photoheight/jobs/${jobId}/nodes`)
        .once('value')
        .then((s) => s.val()));

    nodeLoop: for (let nodeId in nodes) {
      let tags = nodes[nodeId].attributes && nodes[nodeId].attributes.pole_tag;
      for (let tagId in tags) {
        if (tags[tagId].company && tags[tagId].company.toLowerCase().includes('verizon')) {
          shareWithVZ = true;
          break nodeLoop;
        }
      }
    }
    if (!shareWithVZ) {
      traces =
        traces ||
        (await firebase
          .database()
          .ref(`photoheight/jobs/${jobId}/traces/trace_data`)
          .once('value')
          .then((s) => s.val()));
      for (let traceId in traces) {
        if (traces[traceId] && traces[traceId].company && traces[traceId].company.toLowerCase().includes('verizon')) {
          shareWithVZ = true;
          break;
        }
      }
    }
    if (shareWithVZ) return ['ctdi', 'verizon_communications', 'verizon_pennsylvania_inc', 'verizon_north_inc_gte'];
    else return [];
  }

  _button_pci(e) {
    this.openPhotoFirst(null, { view: 'pci' });
    this.cancelPromptAction();
  }

  _button_validation_routine(e) {
    this.openPhotoFirst(null, { view: 'pci', properties: { validationRoutine: true } });
    this.cancelPromptAction();
  }

  async _button_cu_entry(e, nodes) {
    this.nodesSubset = nodes;
    nodes = nodes || this.nodes;
    let data = await GetJobData(this.job_id, 'photos');
    // default some values each time we run the button
    this.workLocationsExist = false;
    this.regenerateWorkLocations = false;

    const utilityCompany = await FirebaseWorker.ref(`photoheight/white_label/utilityCompany`)
      .once('value')
      .then((s) => s.val());

    // get relevant workLocationInformation
    let workLocationInfo = WorkLocations.checkForWorkLocations(
      nodes,
      this.connections,
      data.photos,
      this.traces,
      this.isOldApp() ? 'PPL' : 'PPL Company',
      utilityCompany,
      this.otherAttributes,
      this.modelDefaults,
      this.alternateDesignsConfig
    );
    // if we open up cu entry on a node that has a work location
    if (this.editing == 'Node' && this.editingNode && workLocationInfo?.nodesWithWorkLocations.includes(this.editingNode))
      this.cuEntryNodeId = this.editingNode;
    // otherwise set to the first work location in the job
    else this.cuEntryNodeId = workLocationInfo?.nodesWithWorkLocations[0];

    // build work location description string
    let workLocationInfoString = 'Work Locations will be generated for this job';
    if (workLocationInfo.workLocationsExist) {
      workLocationInfoString = `Work Locations already exist in this job.`;
      if (workLocationInfo.polesWithoutWorkLocations.length > 0) {
        let plural1 = workLocationInfo.polesWithoutWorkLocations.length > 1;
        workLocationInfoString += `${workLocationInfo.polesWithoutWorkLocations.length} pole${plural1 ? 's' : ''} do${
          plural1 ? '' : 'es'
        } not have a Work Location.`;
      }
      if (workLocationInfo.badPolesWithWorkLocations.length > 0) {
        let plural2 = workLocationInfo.badPolesWithWorkLocations.length > 1;
        workLocationInfoString += ` ${workLocationInfo.badPolesWithWorkLocations.length} pole${plural2 ? 's' : ''} ${
          plural2 ? 'have' : 'has'
        } a Work Location that should not.`;
      }
      // set flag that tells us we have existing work locations
      this.workLocationsExist = true;
    }
    this.set('workLocationInfoString', workLocationInfoString);

    this.confirm(
      'CU Entry',
      null,
      'Open Cu Entry',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'cuEntry',
      async () => {
        // if work locations don't exist yet, flag to generate them
        if (!workLocationInfo.workLocationsExist) this.regenerateWorkLocations = true;
        // run CU Entry with the options passed in from the user
        await this.runCUEntry(nodes, data.photos, { keepExistingWLs: !this.regenerateWorkLocations, nodeId: this.cuEntryNodeId });

        // if the user kept existing work locations, then run a QC listing all poles without work locations
        if (!this.regenerateWorkLocations) {
          let warnings = { title: 'Missing Work Locations', nodeWarnings: [] };
          for (let i = 0; i < workLocationInfo.polesWithoutWorkLocations.length; i++) {
            let nodeId = workLocationInfo.polesWithoutWorkLocations[i];
            warnings.nodeWarnings.push({
              key: nodeId,
              description:
                PickAnAttribute(SquashNulls(this.nodes, nodeId, 'attributes'), this.modelDefaults.ordering_attribute) ||
                `(No ${this.modelDefaults.ordering_attribute_label})`,
              warnings: ['This pole does not have a work location and should.']
            });
          }
          for (let i = 0; i < workLocationInfo.badPolesWithWorkLocations.length; i++) {
            let nodeId = workLocationInfo.badPolesWithWorkLocations[i];
            warnings.nodeWarnings.push({
              key: nodeId,
              description:
                PickAnAttribute(SquashNulls(this.nodes, nodeId, 'attributes'), this.modelDefaults.ordering_attribute) ||
                `(No ${this.modelDefaults.ordering_attribute_label})`,
              warnings: ["This pole has a work location and shouldn't."]
            });
          }
          if (warnings.nodeWarnings.length > 0) {
            this.displayWarningsDialog(warnings);
          }
        }
      }
    );
  }

  async runCUEntry(nodes, photos, options) {
    options = options || {};
    let workLocations = [];
    // determine if we should regenerate work locations or not
    if (options.keepExistingWLs) {
      workLocations = WorkLocations.returnListForCUEntry(nodes, this.otherAttributes, this.modelDefaults);
    } else {
      this.toast('Setting Work Locations');
      workLocations = await WorkLocations.insertAndReturnListForCUEntry(
        this.job_id,
        nodes,
        this.connections,
        photos,
        this.traces,
        this.otherAttributes,
        this.isOldApp() ? 'PPL' : 'PPL Company',
        this.modelDefaults,
        this.alternateDesignsConfig
      );
      this.toast('Done Setting Work Locations');
    }

    let photoId = workLocations[0]?.photoId;
    // if the user opted to use a specific node, find that node's main photo id
    if (options.nodeId) {
      let nodePhotos = await FirebaseWorker.database()
        .ref(`photoheight/jobs/${this.job_id}/nodes/${options.nodeId}/photos`)
        .once('value')
        .then((s) => s.val() || {});
      photoId = Object.keys(nodePhotos).find((x) => nodePhotos[x]?.association == 'main' || nodePhotos[x] == 'main');
    }

    if (workLocations.length > 0) {
      this.openPhotoFirst({
        currentTarget: {
          id: photoId,
          workLocations,
          action_type: 'cuEntry'
        }
      });
    } else {
      this.toast('No Work Locations Found');
    }
    this.cancelPromptAction();
  }

  cuNodeFilter(x) {
    return (
      this.nodes[x.key]?.attributes?.work_location &&
      this.nodes[x.key]?.attributes?.work_location != '' &&
      (!this.nodesSubset || this.nodesSubset[x.key])
    );
  }

  _button_draw_polygon(e) {
    this.drawPolygonAttributes = ToArray(SquashNulls(this.activeCommandModel, 'models', 'attributes') || { name: '', description: '' });
    this.activeCommand = '_drawPolygon';
    this.$.katapultMap.openActionDialog({
      title: 'Click to draw polygon.',
      buttons: [
        {
          title: 'Cancel',
          callback: () => {
            if (this.drawingPolygon) this.drawingPolygon.setMap(null);
            this.drawingPolygon = null;
            this.cancelPromptAction(new CustomEvent('cancel-draw-polygon'));
          },
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Create',
          callback: this.openPolygonDrawOptions.bind(this),
          attributes: {
            style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); padding: 12px; margin:0 5px'
          }
        }
      ],
      cancel: () => {
        this.activeCommand = null;
      }
    });
  }

  openPolygonDrawOptions() {
    this.newPolygonLayerInput = false;
    this.confirmDialogCancelCallback = null;
    this.cancelPromptAction(new CustomEvent('create-draw-polygon'));
    this.$.confirmDialog.noCancelOnEscKey = true;
    this.$.confirmDialog.noCancelOnOutsideClick = true;
    this.confirm(
      'Polygon Options:',
      null,
      'Add Polygon',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'drawPolygon',
      async () => {
        const points = this.drawingPolygon.getPath().getArray();
        let feature = {
          type: 'Feature',
          properties: {
            _key: FirebaseWorker.ref().push().key,
            stroke: this.drawPolygonColor || '#fff',
            fill: this.drawPolygonColor || '#fff'
          },
          geometry: {
            type: 'Polygon',
            coordinates: [[]]
          }
        };
        this.drawPolygonAttributes.forEach((item) => {
          feature.properties[item.$key] = item.$val;
        });
        points.forEach((point) => {
          feature.geometry.coordinates[0].push([point.lng(), point.lat()]);
        });
        feature.geometry.coordinates[0].push([points[0].lng(), points[0].lat()]);

        let update = {};
        if (this.newPolygonLayerInput) {
          const { createLayer } = await import('../../modules/JobLayers.js');
          let { id, item } = await createLayer({
            jobId: this.job_id,
            name: this.drawPolygonNewLayer,
            features: [feature],
            color: this.drawPolygonColor || '#fff'
          });
          // store the new layer id with layer information item
          item.$key = id;
          // Turn on the newly created layer
          await this.toggleMapLayer(item, true);
        } else {
          if (this.drawPolygonLayer) {
            const layer = this.$.mapLayers.child(this.drawPolygonLayer);
            if (layer.type == 'Reference Layer' || layer.type == 'API Layer' || layer.type == 'Overlay Layer') {
              this.toast('Reference Layers cannot have polygons added to them.');
              setTimeout(() => this.openPolygonDrawOptions());
              return;
            } else {
              const { addFeatureToLayer } = await import('../../modules/JobLayers.js');
              await addFeatureToLayer({
                jobId: this.job_id,
                layerId: this.drawPolygonLayer,
                feature,
                storageFileName: layer.storage_file_name
              });
              // Add the new feature to the layer on the map
              let geoJsonLayer = this.geoJsonLayers.find((x) => x.key == this.drawPolygonLayer);
              geoJsonLayer.features.push(...this.map.data.addGeoJson(feature));
            }
          } else {
            this.toast('No layer selected');
            setTimeout(() => this.openPolygonDrawOptions());
            return;
          }
        }
        // Send cancel event and clear dialog settings
        this.cancelPromptAction(new CustomEvent('cancel-draw-polygon'));
        this.$.confirmDialog.noCancelOnEscKey = false;
        this.$.confirmDialog.noCancelOnOutsideClick = false;
      },
      () => {
        this.confirmDialogCancelCallback = null;
        this.$.confirmDialog.noCancelOnEscKey = false;
        this.$.confirmDialog.noCancelOnOutsideClick = false;
        this.cancelPromptAction(new CustomEvent('cancel-draw-polygon'));
      }
    );
  }

  createNewPolygonLayer() {
    this.newPolygonLayerInput = true;
  }

  cancelCreateNewPolygonLayer() {
    this.newPolygonLayerInput = false;
  }

  _button_copy_reference_layer_to_job(e) {
    this.polygonModels = this.activeCommandModel.models;
    this.activeCommand = '_selectPolygon';
    this.$.katapultMap.openActionDialog({
      title: 'Select a polygon to chop layers.',
      buttons: [{ title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } }]
    });
  }
  copyPolygonNodesToJob(featureRef) {
    if (this.selectedPolygonData) {
      let bounds = new google.maps.LatLngBounds();
      let polygon = new google.maps.Polygon();
      this.selectedPolygonData.getGeometry().forEachLatLng((latLng) => {
        bounds.extend(latLng);
        polygon.getPath().push(latLng);
      });
      let center = bounds.getCenter();
      let radius = {
        center: [center.lat(), center.lng()],
        radius: google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000
      };
      // Create a temp list of nodes to copy
      let nodesToCopy = [];

      for (let nodeId in this.nodes) {
        // Check if the node has a job key from the admin report data
        let node = this.nodes[nodeId];
        if (
          node.latitude &&
          node.longitude &&
          google.maps.geometry.poly.containsLocation(new google.maps.LatLng(node.latitude, node.longitude), polygon)
        ) {
          nodesToCopy.push(nodeId);
        }
      }
      if (nodesToCopy.length == 0) {
        this.toast('No nodes were found to copy');
      } else {
        this.confirm(
          'Select Job to copy Nodes To',
          `${nodesToCopy.length} nodes will be copied to the job you select`,
          'Copy Nodes',
          'Cancel',
          'background-color:var(--secondary-color); color:white;',
          'copyPolygonNodesToJob',
          async () => {
            if (!this.copyPolygonNodesToJobId) {
              this.toast('No job selected');
              setTimeout(() => this.copyPolygonNodesToJob());
            } else {
              this.copyJobStyles = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/map_styles`)
                .once('value')
                .then((s) => s.val());

              // Set settings
              this.copyMoveAction = 'Copy';
              this.onlyCopyNodesWithFeedback = false;
              this.otherCopyJobNodeKeys = null;

              // Set the list of nodes to copy
              this.multiSelectedNodes = nodesToCopy;
              // Set the job id to copy to
              this.copyNodesJobId = this.copyPolygonNodesToJobId;

              // Copy this feature to the other job
              const { copyFeatureToJob } = await import('../../modules/JobLayers.js');
              await copyFeatureToJob({
                jobId: this.job_id,
                layerId: featureRef.layerKey,
                featureId: featureRef.featureKey,
                toJobId: this.copyPolygonNodesToJobId,
                storageFileName: featureRef.storageFileName
              });

              // Copy the nodes to the other job
              this.copyNodesToJob();
            }
          }
        );
      }
    } else this.toast('Polygon Improperly Selected');
  }

  addItemDataToJobData(itemType, itemKey, itemData, dataByJob, jobId) {
    jobId = jobId || itemData._admin_report_data.jobKey;
    if (!dataByJob[jobId]) dataByJob[jobId] = {};
    if (!dataByJob[jobId][itemType]) dataByJob[jobId][itemType] = {};
    dataByJob[jobId][itemType][itemKey] = itemData;
  }

  async downloadByPolygon(featureRef) {
    let feature = this.getFeatureFromRef(featureRef);
    this.mldList = (await MLD.getDirectoryList(this.userGroup, { includeSharedDirectories: true })) || {};
    // from this job, or
    this.confirm(
      'Export By Polygon',
      'You can export from nodes in a Master Location Directory',
      'Download',
      'Cancel',
      'color:white; background-color:var(--secondary-color);',
      'masterLocationDirectoryChooser',
      async () => {
        let directoryId = this.selectedMLD;

        let jobName = 'Polygon Export';
        feature.forEachProperty((data, key) => {
          if (key.toLowerCase().includes('name')) {
            jobName = data;
          }
        });

        // target.loading = true;
        let exportManagerLoaded = import('./exports/export-manager.js').then(() => {
          // target.loading = false;
          this.$.exportManager.open({ adminReport: true, willSetData: true, jobName });
        });

        let bounds = new google.maps.LatLngBounds();
        let polygon = new google.maps.Polygon();
        feature.getGeometry().forEachLatLng((latLng) => {
          bounds.extend(latLng);
          polygon.getPath().push(latLng);
        });
        let center = bounds.getCenter();
        let radius = {
          center: [center.lat(), center.lng()],
          radius: google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000
        };

        let nodes = await new GeoFire(
          firebase.firestore().collection(`companies/${this.userGroup}/directories/${directoryId}/locations`)
        ).once(radius);

        // If the selected MLD is a shared directory, the nodes object will be empty and we need to get the locations from the owner company's MLD
        if (Object.keys(nodes).length == 0) {
          const directoryOwner = await firebase
            .firestore()
            .collection(`companies/${this.userGroup}/shared_directories/`)
            .doc(directoryId)
            .get()
            .then((doc) => doc.data().owner_company);
          nodes = await new GeoFire(
            firebase.firestore().collection(`companies/${directoryOwner}/directories/${directoryId}/locations`)
          ).once(radius);
        }

        let jobs = {};
        let dataByJob = {};
        for (let key in nodes) {
          let jobIds = nodes[key]?.d?.j ?? [];
          for (const j of jobIds) {
            try {
              // If the job is deleted, skip it
              const isJobDeleted = await FirebaseWorker.ref(`photoheight/jobs/${j}/deleted`)
                .once('value')
                .then((s) => s.val());
              if (isJobDeleted === true) continue;
              jobs[j] = true;
            } catch (e) {
              if (e.message.indexOf('permission_denied') < 0) console.warn(e);
            }
          }
        }

        let exportData = { nodes: {}, connections: {}, jobId: null, jobName };
        for (let jobId in jobs) {
          let jobName = await FirebaseWorker.ref('photoheight/jobs/' + jobId + '/name')
            .once('value')
            .then((s) => s.val());
          jobs[jobId] = jobName;
          let jobNodes = await FirebaseWorker.ref('photoheight/jobs/' + jobId + '/nodes')
            .once('value')
            .then((s) => s.val());
          let foundNodes = false;
          for (let nodeId in jobNodes) {
            if (
              google.maps.geometry.poly.containsLocation(
                new google.maps.LatLng(jobNodes[nodeId].latitude, jobNodes[nodeId].longitude),
                polygon
              )
            ) {
              jobNodes[nodeId]._admin_report_data = { jobName: jobName, jobKey: jobId, firebaseKey: nodeId };
              exportData.nodes[nodeId] = jobNodes[nodeId];
              this.addItemDataToJobData('nodes', nodeId, jobNodes[nodeId], dataByJob);
              foundNodes = true;
            }
          }
          if (foundNodes) {
            let jobConns = await FirebaseWorker.ref('photoheight/jobs/' + jobId + '/connections')
              .once('value')
              .then((s) => s.val());
            for (let connId in jobConns) {
              if (dataByJob[jobId].nodes[jobConns[connId].node_id_1] && dataByJob[jobId].nodes[jobConns[connId].node_id_2]) {
                jobConns[connId]._admin_report_data = { jobName: jobName, jobKey: jobId, firebaseKey: connId };
                exportData.connections[connId] = jobConns[connId];
                this.addItemDataToJobData('connections', connId, jobConns[connId], dataByJob);
              }
            }
            for (let path of ['photos', 'geohash', 'traces']) {
              let data = await FirebaseWorker.ref('photoheight/jobs/' + jobId + '/' + path)
                .once('value')
                .then((s) => s.val());
              if (path == 'traces' && data) {
                exportData['traces'] = exportData['traces'] || {};
                for (let key in data.trace_data) {
                  data.trace_data[key]._admin_report_data = { jobName: jobName, jobKey: jobId, firebaseKey: key };
                  exportData['traces'][key] = data.trace_data[key];
                  this.addItemDataToJobData('traces', key, data.trace_data[key], dataByJob);
                }
                exportData['traceItems'] = exportData['traceItems'] || {};
                for (let key in data.trace_items) {
                  data.trace_items[key]._admin_report_data = { jobName: jobName, jobKey: jobId, firebaseKey: key };
                  exportData['traceItems'][key] = data.trace_items[key];
                  this.addItemDataToJobData('traceItems', key, data.trace_items[key], dataByJob);
                }
              } else {
                exportData[path] = exportData[path] || {};
                for (let key in data) {
                  data[key]._admin_report_data = { jobName: jobName, jobKey: jobId, firebaseKey: key };
                  exportData[path][key] = data[key];
                  this.addItemDataToJobData(path, key, data[key], dataByJob);
                }
              }
            }
          }
        }
        await exportManagerLoaded;
        this.$.exportManager.setData(exportData, dataByJob);
        this.$.exportManager.mapLayers = this.mapLayers;
      }
    );
  }

  async runMapErrorsReport(featureRef) {
    // Get the polygon
    const feature = this.getFeatureFromRef(featureRef);
    // Get a list of the company's Master Location Directories for the user to choose from
    this.mldList = (await MLD.getDirectoryList(this.userGroup)) || {};

    // Function to handle resetting state when closing the dialog
    const resetSettings = () => {
      this.confirmDialogStatus = '';
      this.confirmDialogError = '';
    };
    const closeDialog = () => {
      this.$.confirmDialog.close();
      if (runButton) runButton.loading = false;
      resetSettings();
      this.cancelPromptAction();
    };

    // Initialize settings
    resetSettings();
    let dialogBody = `This will run Fix Map Errors on all jobs found in the selected Master Location Directory bound by the polygon's area.`;
    let runButton = null;

    // Open the dialog
    this.confirm(
      'Run Map Errors Report',
      dialogBody,
      'Run',
      'Cancel',
      'color:white; background-color:var(--secondary-color);',
      'masterLocationDirectoryChooser',
      async (e) => {
        // Get a reference to the scan button and set it to loading
        runButton = e.currentTarget;
        runButton.loading = true;

        // Localize the mld directory id
        const directoryId = this.selectedMLD;

        // Get the bounds and polygon of the feature
        const bounds = new google.maps.LatLngBounds();
        const polygon = new google.maps.Polygon();
        feature.getGeometry().forEachLatLng((latLng) => {
          bounds.extend(latLng);
          polygon.getPath().push(latLng);
        });

        // Get the circle to query by
        const center = bounds.getCenter();
        const radius = {
          center: [center.lat(), center.lng()],
          radius: google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000
        };

        this.confirmDialogStatus = 'Searching directory for nodes within polygon bounds';

        // Query for all locations in the polygon radius in the MLD
        const nodes = await new GeoFire(
          firebase.firestore().collection(`companies/${this.userGroup}/directories/${directoryId}/locations`)
        ).once(radius);
        // Collect all of the jobs for the found nodes
        const jobs = {};
        for (const key in nodes) {
          const jobIds = nodes[key]?.d?.j ?? [];
          for (const j of jobIds) {
            try {
              // If the job is deleted, skip it
              const isJobDeleted = await FirebaseWorker.ref(`photoheight/jobs/${j}/deleted`)
                .once('value')
                .then((s) => s.val());
              if (isJobDeleted === true) continue;
              jobs[j] = true;
            } catch (e) {
              if (e.message.indexOf('permission_denied') < 0) console.warn(e);
            }
          }
        }

        let jobCounter = 1;
        let jobsCount = Object.keys(jobs).length;
        let csv = '';
        const errorTypes = ['bad node', 'malformed coordinates', 'bad connection', 'bad section'];

        for (const jobId in jobs) {
          this.confirmDialogStatus = `Collecting node data - ${jobCounter} of ${jobsCount}`;
          jobCounter++;

          let jobHasNodesInPolygon = false;
          const jobNodes = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/nodes`)
            .once('value')
            .then((s) => s.val());

          // Loop through the jobs to determine if any nodes are in the polygon
          for (const nodeId in jobNodes) {
            if (
              google.maps.geometry.poly.containsLocation(
                new google.maps.LatLng(jobNodes[nodeId].latitude, jobNodes[nodeId].longitude),
                polygon
              )
            ) {
              jobHasNodesInPolygon = true;
              break;
            }
          }

          // Skip the job if it doesn't have any nodes in the polygon
          if (!jobHasNodesInPolygon) continue;

          // Get the needed job data
          const options = {
            skipUpdate: true
          };

          // Run Fix Map Errors on the job
          const result = await FixMapErrors.run(jobId, this.map.getProjection(), null, null, null, null, null, null, options);

          if (new RegExp(errorTypes.join('|')).test(result)) {
            // At least one match
            if (!csv) {
              csv += 'Jobs with Map Errors:\n';
            }
            const jobName = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/name`)
              .once('value')
              .then((s) => s.val());
            csv += `${jobName}\n`;
          }
        }

        if (csv) {
          let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          let a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `Map Errors Report.csv`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          this.toast('No errors in any jobs!');
        }

        // Now close the original dialog
        closeDialog();
      },
      (e) => {
        this.confirmDialogCancelCallback = null;
        closeDialog();
      },
      null,
      { closeOnConfirm: false }
    );
  }

  async transferByPolygon(featureRef) {
    // Get the polygon
    let feature = this.getFeatureFromRef(featureRef);
    // Get a list of the company's Master Location Directories for the user to choose from
    this.mldList = (await MLD.getDirectoryList(this.userGroup, { includeSharedDirectories: true })) || {};

    // Function to handle resetting state when closing the dialog
    let resetSettings = () => {
      this.inTransferByPolygonMode = false;
      this.transferByPolygonJobName = '';
      this.confirmDialogStatus = '';
      this.confirmDialogError = '';
    };
    let closeDialog = () => {
      this.$.confirmDialog.close();
      if (scanButton) scanButton.loading = false;
      resetSettings();
      this.cancelPromptAction();
    };

    // Initialize settings
    resetSettings();
    let dialogBody = `This will create a new job containing the nodes and connections found in the selected Master Location Directory bound by the polygon's area. The share dialog will then appear, allowing you to transfer the newly created job.`;
    let scanButton = null;
    this.inTransferByPolygonMode = true;

    // Open the dialog
    this.confirm(
      'Transfer By Polygon',
      dialogBody,
      'Scan',
      'Cancel',
      'color:white; background-color:var(--secondary-color);',
      'masterLocationDirectoryChooser',
      async (e) => {
        // Get a reference to the scan button and set it to loading
        scanButton = e.currentTarget;
        scanButton.loading = true;

        // Localize the mld directory id
        let directoryId = this.selectedMLD;
        // Get the bounds and polygon of the feature
        let bounds = new google.maps.LatLngBounds();
        let polygon = new google.maps.Polygon();
        feature.getGeometry().forEachLatLng((latLng) => {
          bounds.extend(latLng);
          polygon.getPath().push(latLng);
        });
        // Get the circle to query by
        let center = bounds.getCenter();
        let radius = {
          center: [center.lat(), center.lng()],
          radius: google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000
        };

        this.confirmDialogStatus = 'Searching directory for nodes within polygon bounds';

        // Query for all locations in the polygon radius in the MLD
        let nodes = await new GeoFire(
          firebase.firestore().collection(`companies/${this.userGroup}/directories/${directoryId}/locations`)
        ).once(radius);
        // Collect all of the jobs for the found nodes
        let jobs = {};
        for (let key in nodes) {
          let jobIds = nodes[key]?.d?.j ?? [];
          for (let j of jobIds) {
            try {
              // If the job is deleted, skip it
              const isJobDeleted = await FirebaseWorker.ref(`photoheight/jobs/${j}/deleted`)
                .once('value')
                .then((s) => s.val());
              if (isJobDeleted === true) continue;
              jobs[j] = true;
            } catch (e) {
              if (e.message.indexOf('permission_denied') < 0) console.warn(e);
            }
          }
        }

        let sourceJobStyles = JSON.parse(JSON.stringify(this.jobStyles));

        // Create a list of just the nodes within the polygon
        let jobData = {};
        let includedNodeIds = {};
        let removedNodeIds = {};
        let nodeLocationLookup = {};
        let includedConnectionIds = {};
        let lostConnectionSections = {};
        let jobCounter = 1;
        let jobsCount = Object.keys(jobs).length;

        // Loop through the jobs once to determine which nodes should be included. We don't want to Overwrite
        // nodes with duplicate IDs that have been linked together with the reference tool
        for (let jobId in jobs) {
          this.confirmDialogStatus = `Collecting node data - ${jobCounter} of ${jobsCount}`;
          jobCounter++;

          // Initialize an object for the job
          jobData[jobId] = jobData[jobId] || {
            nodes: {},
            connections: {},
            photo_folders: {},
            photo_summary: {},
            photos: {},
            traces: {
              trace_data: {},
              trace_items: {}
            }
          };

          // Fetch and store nodes
          let jobNodes = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/nodes`)
            .once('value')
            .then((s) => s.val());
          for (let nodeId in jobNodes) {
            // Determine which nodes are actually in the polygon
            if (
              google.maps.geometry.poly.containsLocation(
                new google.maps.LatLng(jobNodes[nodeId].latitude, jobNodes[nodeId].longitude),
                polygon
              )
            ) {
              // We need to check for duplicate node IDs that are tied to each other with reference_data because of Charter's custom
              // workflow where they link a reference node in one job to a main node in another job. If we come across a duplicate
              // node ID, then we only want to keep the node that is the primary pole

              // Get the job ID for the node we already included, if any
              let otherJobId = includedNodeIds[nodeId];
              // Check if we have another node to compare to
              if (otherJobId) {
                // Check if the other node is primary or not
                let otherNodeIsPrimary = jobData[otherJobId]?.nodes[nodeId]?.reference_data?.primary_pole == true;
                if (otherNodeIsPrimary) {
                  // Mark that this node ID was removed from the current job
                  removedNodeIds[nodeId] = jobId;
                  // Remove reference_data from the other node that was already included
                  delete jobData[otherJobId]?.nodes[nodeId]?.reference_data;
                  // Since the other node is primary, we shouldn't include the current node
                  // because it's the reference node, so skip to the next iteration of the loop
                  continue;
                } else {
                  // Mark that the node ID has been removed from the other job
                  removedNodeIds[nodeId] = otherJobId;
                  // Remove reference_data from the node that is about to be included
                  delete jobNodes[nodeId].reference_data;
                  // Since the other node is non-primary, we should delete its data from jobData
                  // and the incoming node data will replace it for the current job
                  delete jobData[otherJobId]?.nodes[nodeId];
                }
              }
              // Mark that we've include the node ID with the job ID it belongs to
              includedNodeIds[nodeId] = jobId;
              // Set the node and associated data in jobData for the current job
              jobData[jobId].nodes[nodeId] = jobNodes[nodeId];
              // Store the location for the node to be used by connections later
              nodeLocationLookup[nodeId] = [jobData[jobId].nodes[nodeId].latitude, jobData[jobId].nodes[nodeId].longitude];
            }
          }
        }

        // Run the data collection for just connections to determine which ones to include
        jobCounter = 1;
        for (let jobId in jobs) {
          this.confirmDialogStatus = `Collecting job data associated with connections - ${jobCounter} of ${jobsCount}`;
          jobCounter++;

          // Determine the connections that should exist based on them having endpoints that are both in the job
          let jobConnections = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/connections`)
            .once('value')
            .then((s) => s.val());
          for (let connId in jobConnections) {
            // Check if either endpoint of the connection is in the removedNodeIds list
            let nId1 = jobConnections[connId].node_id_1;
            let nId2 = jobConnections[connId].node_id_2;
            let endPointRemoved = removedNodeIds[nId1] != null || removedNodeIds[nId2] != null;

            // Only include the connection if both endpoints are in the
            // current job data or if one endpoint was purposefully removed
            if ((jobData[jobId].nodes[nId1] && jobData[jobId].nodes[nId2]) || endPointRemoved == true) {
              // Get the job ID for the connection we already included, if any
              let otherJobId = includedConnectionIds[connId];
              // Check if we have another connection to compare to
              if (otherJobId) {
                let otherConnectionData = jobData[otherJobId]?.connections[connId];

                // Since this connection was already included, we need to check which version of
                // the connection should be considered the "primary" one
                let connectionTypeAttribute = PickAnAttribute(otherConnectionData.attributes, this.modelDefaults.connection_type_attribute);
                // It's the primary connection if the type is not in the reference_connection_types list
                let otherConnectionIsPrimary = !this.modelDefaults.reference_connection_types.includes(connectionTypeAttribute);
                // Check if the other connection should be prioritized (based on if it's not a reference type)
                if (otherConnectionIsPrimary) {
                  // Before we skip the connection, we should save data for its
                  // sections so we can restore them on the main connection that we are keeping
                  let sections = jobConnections[connId].sections;
                  if (sections) {
                    Path.set(lostConnectionSections, `${connId}.${jobId}`, JSON.parse(JSON.stringify(sections)));
                  }
                  // Since the other connection should be prioritized, we shouldn't include the
                  // current connection, so skip to the next iteration of the loop
                  continue;
                } else {
                  // Before we delete the other connection, we should save data for its
                  // sections so we can restore them on the main connection that we are keeping
                  let otherSections = jobData[otherJobId]?.connections[connId]?.sections;
                  if (otherSections) {
                    Path.set(lostConnectionSections, `${connId}.${otherJobId}`, JSON.parse(JSON.stringify(otherSections)));
                  }
                  // Since the other connection is not priority, we should delete its data from jobData
                  // and the incoming connection data will replace it for the current job
                  delete jobData[otherJobId]?.connections[connId];
                }
              }
              // Mark that we've include the connection ID with the job ID it belongs to
              includedConnectionIds[connId] = jobId;
              // Set the connection and associated data in jobData for the current job
              jobData[jobId].connections[connId] = jobConnections[connId];
            }
          }
        }

        // Run the data collection for everything else in the jobs now that we have a final node list
        jobCounter = 1;
        for (let jobId in jobs) {
          this.confirmDialogStatus = `Collecting job data associated with found nodes - ${jobCounter} of ${jobsCount}`;
          jobCounter++;

          // Fetch data photo data for the job
          let jobPhotos = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/photos`)
            .once('value')
            .then((s) => s.val());
          let jobPhotosSummaries = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/photo_summary`)
            .once('value')
            .then((s) => s.val());

          // Loop through the nodes for the current job
          for (let nodeId in jobData[jobId].nodes) {
            // Include photo's associated to the nodes in the job
            for (let photoId in jobData[jobId].nodes[nodeId].photos) {
              jobData[jobId].photos[photoId] = jobPhotos[photoId];
              jobData[jobId].photo_summary[photoId] = jobPhotosSummaries[photoId];
            }
          }

          // Loop through the connections to add for the current job and collect related data
          for (let connId in jobData[jobId].connections) {
            let nId1 = jobData[jobId].connections[connId].node_id_1;
            let nId2 = jobData[jobId].connections[connId].node_id_2;
            let l1 = nodeLocationLookup[nId1];
            let l2 = nodeLocationLookup[nId2];

            // Include photo's associated to the connections in the job
            for (let photoId in jobData[jobId].connections[connId].photos) {
              jobData[jobId].photos[photoId] = jobPhotos[photoId];
              jobData[jobId].photo_summary[photoId] = jobPhotosSummaries[photoId];
            }

            // Check if we have any lost sections that we should keep on this connection
            if (lostConnectionSections[connId]) {
              // Loop through every job id for the lost connection (should just be one)
              for (let lostConnectionJobId in lostConnectionSections[connId]) {
                // Loop through the sections for the lost connection
                for (let sectionId in lostConnectionSections[connId][lostConnectionJobId]) {
                  let lostSection = lostConnectionSections[connId][lostConnectionJobId][sectionId];
                  let newSectionId = sectionId;
                  // Set up a sections object if there isn't one
                  if (!jobData[jobId].connections[connId].sections) {
                    jobData[jobId].connections[connId].sections = {};
                  }
                  // Add the lost section to the current connection. If there is
                  // a key conflict, then use a new push id for the incoming section
                  if (jobData[jobId].connections[connId].sections[sectionId]) {
                    newSectionId = FirebaseWorker.ref().push().key;
                  }
                  // Set the section data
                  jobData[jobId].connections[connId].sections[newSectionId] = lostSection;
                  // Pull in photos needed for the section
                  for (let photoId in lostSection.photos) {
                    jobData[jobId].photos[photoId] = await FirebaseWorker.ref(`photoheight/jobs/${lostConnectionJobId}/photos/${photoId}`)
                      .once('value')
                      .then((s) => s.val());
                    jobData[jobId].photo_summary[photoId] = await FirebaseWorker.ref(
                      `photoheight/jobs/${lostConnectionJobId}/photo_summary/${photoId}`
                    )
                      .once('value')
                      .then((s) => s.val());
                  }
                }
              }
            }

            for (let sectionId in jobData[jobId].connections[connId].sections) {
              // Include photo's associated to the sections in the job
              for (let photoId in jobData[jobId].connections[connId].sections[sectionId].photos) {
                // Check that we don't already have the photo
                if (!jobData[jobId].photos[photoId]) {
                  jobData[jobId].photos[photoId] = jobPhotos[photoId];
                  jobData[jobId].photo_summary[photoId] = jobPhotosSummaries[photoId];
                }
              }
            }
          }

          // Set data for traces and photo folders
          let jobTraces = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/traces/trace_data`)
            .once('value')
            .then((s) => s.val());
          let jobTraceItems = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/traces/trace_items`)
            .once('value')
            .then((s) => s.val());
          let jobPhotoFolders = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/photo_folders`)
            .once('value')
            .then((s) => s.val() || {});
          for (let photoId in jobData[jobId].photos) {
            let photo = jobData[jobId].photos[photoId];
            TraverseMarkers(photo.photofirst_data, (child, path, childProperty, childItemKey) => {
              let traceId = child._trace;
              if (traceId && jobTraces?.[traceId]) {
                jobData[jobId].traces.trace_data[traceId] = jobTraces[traceId] || null;
                jobData[jobId].traces.trace_items[traceId] = jobData[jobId].traces.trace_items[traceId] || {};
                jobData[jobId].traces.trace_items[traceId][photoId] = jobTraceItems[traceId][photoId] || null;
              }
            });
            // Get data for photo folders
            if (photo.folder_id && !jobData[jobId].photo_folders[photo.folder_id]) {
              jobData[jobId].photo_folders[photo.folder_id] = jobPhotoFolders[photo.folder_id] || null;
            }
          }
        }

        this.confirmDialogStatus = 'Creating a new job';
        // Set the new job name
        let newJobName = this.transferByPolygonJobName || `${this.jobName} - To Be Transferred`;
        // Create a new job that will have the data copied to it and then be transferred
        let newJobId = await this.$.createJobForm
          .createNewJob(
            {
              name: newJobName,
              mapStyles: sourceJobStyles,
              model: this.jobCreator,
              jobProjectFolderPath: this.project_folder,
              preventNewJobSelection: true,
              copyOverridePhotos: true
            },
            (results) => {
              if (results.status == 'error') {
                this.confirmDialogError = `Error creating job: ${results.message}`;
                console.log('err', results);
              }
            }
          )
          .then((newJob) => newJob.job_id);

        if (newJobId) {
          this.confirmDialogStatus = 'Writing data to new job';
          // Write data to new job
          let updatePromises = [];
          let updatePaths = ['nodes', 'connections', 'photo_folders', 'photo_summary', 'photos', 'traces.trace_data', 'traces.trace_items'];
          for (let jobId in jobData) {
            // Map the job data to update paths
            let jobUpdate = {};
            for (let path of updatePaths) {
              let data = Path.get(jobData[jobId], path);
              for (let key in data) {
                jobUpdate[`${path.replace('.', '/')}/${key}`] = data[key];
              }
            }
            if (Object.keys(jobUpdate).length != 0) {
              // Run the data update on the job
              await LargeUpdate(FirebaseWorker.ref(`photoheight/jobs/${newJobId}`, { noTracking: true }), jobUpdate);
            }
          }
          // Run final geohash update on the new job
          let newNodes = await FirebaseWorker.ref(`photoheight/jobs/${newJobId}/nodes`)
            .once('value')
            .then((s) => s.val());
          let newConnections = await FirebaseWorker.ref(`photoheight/jobs/${newJobId}/connections`)
            .once('value')
            .then((s) => s.val());
          let newGeohash = GeofireTools.getJobGeohash(newNodes, newConnections, sourceJobStyles);
          await FirebaseWorker.ref(`photoheight/jobs/${newJobId}/geohash`).set(newGeohash);
          // Run the counter updates on the new job
          let counterUpdates = [];
          let counterUpdate = {};
          counterUpdates.push(
            CollectionSetTools.updateJobCounters({ jobId: newJobId }).then((update) => Object.assign(counterUpdate, update))
          );
          counterUpdates.push(
            CollectionSetTools.updateJobLastUpload({ jobId: newJobId }).then((update) => Object.assign(counterUpdate, update))
          );
          await Promise.all(counterUpdates).then(async () => {
            for (let path in counterUpdate) {
              if (typeof counterUpdate[path] === 'undefined') {
                delete counterUpdate[path];
              }
            }
            await FirebaseWorker.ref().update(counterUpdate);
          });

          await import('../job-chooser/project-folder-chooser.js');
          await import('../share-job/share-job.js');
          this.$.projectFolderChooser.$.shareJob.open(newJobId, newJobName, this.jobCreator, this.jobOwner, this.projectFolder, {
            newPermissionLevel: 'originalJob',
            transferByPolygon: true
          });

          // Remove the copy_override_photos flag from the new job
          FirebaseWorker.ref(`photoheight/jobs/${newJobId}/copy_override_photos`).set(null);

          // Now close the original dialog
          closeDialog();
        }
      },
      (e) => {
        this.confirmDialogCancelCallback = null;
        closeDialog();
      },
      null,
      { closeOnConfirm: false }
    );
  }

  async shareByPolygon(featureRef) {
    // Get the polygon
    let feature = this.getFeatureFromRef(featureRef);
    // Get ensure the master location directory list is available for the confirm dialog
    this.mldList = (await MLD.getDirectoryList(this.userGroup, { includeSharedDirectories: true })) || {};
    // Open the confirm dialog
    this.confirm(
      'Share By Polygon',
      'You can share jobs from nodes in a Master Location Directory',
      'Scan',
      'Cancel',
      'color:white; background-color:var(--secondary-color);',
      'masterLocationDirectoryChooser',
      async (e) => {
        // Prevent the dialog from closing right away
        e.stopPropagation();
        let target = e.currentTarget;
        target.loading = true;
        // Localize the mld directory id
        let directoryId = this.selectedMLD;
        // Get the bounds and polygon of the feature
        let bounds = new google.maps.LatLngBounds();
        let polygon = new google.maps.Polygon();
        feature.getGeometry().forEachLatLng((latLng) => {
          bounds.extend(latLng);
          polygon.getPath().push(latLng);
        });
        // Get the circle to query by
        let center = bounds.getCenter();
        let radius = {
          center: [center.lat(), center.lng()],
          radius: google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000
        };
        // Query for all of the nodes in the circle
        let nodes = Object.values(
          await new GeoFire(firebase.firestore().collection(`companies/${this.userGroup}/directories/${directoryId}/locations`)).once(
            radius
          )
        );
        // Filter out nodes not in the polygon
        nodes = nodes.filter((node) => google.maps.geometry.poly.containsLocation(new google.maps.LatLng(node.l[0], node.l[1]), polygon));
        // Build a list of jobs from the nodes returned by the query
        let jobs = {};
        for (const node of nodes) {
          let jobIds = node?.d?.j ?? [];
          for (const j of jobIds) {
            // If the job is deleted, skip it
            const isJobDeleted = await FirebaseWorker.ref(`photoheight/jobs/${j}/deleted`)
              .once('value')
              .then((s) => s.val());
            if (isJobDeleted === true) continue;
            jobs[j] = true;
          }
        }
        // Load folder chooser and share job
        await import('../job-chooser/project-folder-chooser.js');
        await import('../share-job/share-job.js');
        // Now close the original dialog
        target.loading = false;
        this.$.confirmDialog.close();
        // Arrayify the job list and prepare the jobs to be shared
        this.$.projectFolderChooser.$.shareJob.openMultiShare(
          Object.keys(jobs),
          SquashNulls(feature, 'i', 'OLT_NAME') || SquashNulls(feature, 'i', 'name') || 'a polygon'
        );
      }
    );
  }

  async polygonSelected(feature) {
    this.cancelPromptAction();
    let jobName = feature.getProperty(this.polygonModels.job_name);
    if (jobName == null || jobName == '') {
      this.toast('This polygon does not have a Job Name, please select one that does.');
    } else {
      this.toast('Copying Reference layers to ' + jobName, null, 0);
      let jobs = await FirebaseWorker.ref('photoheight/job_permissions/' + this.userGroup + '/list')
        .orderByChild('name')
        .equalTo(jobName)
        .once('value')
        .then((s) => s.val());
      let jobId = null;
      let job = {};
      if (jobs == null) {
        this.toast('Creating Job ' + jobName, null, 0);
        await new Promise((resolve, reject) => {
          // TODO: Needs testing...
          this.$.createJobForm.createNewJob(
            {
              name: jobName,
              model: this.jobCreator,
              jobProjectFolderPath: this.projectFolder,
              mapStyles: this.jobStyles,
              preventNewJobSelection: true
            },
            (result) => {
              if (result.status == 'error') {
                this.toast(result.message, null, 6000);
                reject();
              } else {
                jobId = result.job_id;
                resolve();
              }
            }
          );
        });
        if (this.polygonModels.metadata) {
          await FirebaseWorker.ref(`photoheight/jobs/${jobId}/metadata`).update(this.polygonModels.metadata);
        }
      } else {
        jobId = Object.keys(jobs)[0];
        job = await FirebaseWorker.ref('photoheight/jobs/' + jobId)
          .once('value')
          .then((s) => s.val());
      }
      let bounds = new google.maps.LatLngBounds();
      let polygon = new google.maps.Polygon();
      feature.getGeometry().forEachLatLng((latLng) => {
        bounds.extend(latLng);
        polygon.getPath().push(latLng);
      });
      let center = bounds.getCenter();
      let radius = {
        center: [center.lat(), center.lng()],
        radius: google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000
      };
      let getVal = (item, reference) => {
        if (reference == '$__NG_Pole_Tag') {
          let val = '';
          if (item.info.LINE_NO) {
            val += item.info.LINE_NO + '--';
          }
          if (item.info.POLE_NO || item.info.DT_POLE_NO) {
            val += item.info.POLE_NO || item.info.DT_POLE_NO;
            let suffix = item.info.POLE_SUFIX;
            if (suffix && suffix != '000') {
              val += '-' + suffix;
            }
          } else if (item.info.FULL_POLE_NO) {
            val += item.info.FULL_POLE_NO.replace(/P/g, '').trim();
          }
          return val;
        } else if (reference[0] == '$') {
          return (
            reference
              .slice(1)
              .split('||')
              .reduce((x, y) => x || SquashNulls(item, 'info', y), null) || null
          );
        }
        return reference;
      };

      if (this.polygonModels.for == 'national_grid') {
        // Add a default saved view that does not have any labels
        let savedViewUpdate = {
          default: true,
          name: 'Default',
          settings: {
            buttonGroup: 'national_grid',
            mapBase: 'hybrid',
            multiJobIds: {
              [jobId]: {
                url: `photoheight/jobs/${jobId}/geohash`
              }
            },
            nodeLabels: {
              lowCable: false,
              note: false,
              photoCount: false,
              scid: false,
              warning: false
            },
            showSpanDistances: false
          }
        };
        await FirebaseWorker.ref(`photoheight/jobs/${jobId}/saved_views/default_ng_saved_view`).update(savedViewUpdate);
      }

      for (let layerKey in this.polygonModels.layers) {
        let layer = this.polygonModels.layers[layerKey];
        let database = layer.database ? `https://${layer.database}.firebaseio.com` : undefined;
        this.toast('Getting ' + (layer.name || layerKey), null, 0);
        await new GeoFire(firebase.app().database(database).ref(layer.path)).once(radius).then(async (data) => {
          this.toast('Formatting ' + (layer.name || layerKey), null, 0);
          if (layer.type == 'nodes') {
            let update = {};
            for (let key in data) {
              if (
                (job.nodes == null || job.nodes[key] == null) &&
                google.maps.geometry.poly.containsLocation(new google.maps.LatLng(data[key].l[0], data[key].l[1]), polygon)
              ) {
                let node = {
                  latitude: data[key].l[0],
                  longitude: data[key].l[1],
                  attributes: {}
                };
                for (let attribute in layer.attributes) {
                  let value = layer.attributes[attribute];
                  if (typeof value === 'string' || typeof value === 'boolean') {
                    node.attributes[attribute] = { imported: getVal(data[key], value) };
                  } else if (attribute == 'pole_tag') {
                    let tag = {};
                    for (let prop in value) {
                      tag[prop] = getVal(data[key], value[prop]);
                      node.attributes[attribute] = { imported: tag };
                    }
                  } else {
                    value.some((item) => {
                      let matches = true;
                      for (let prop in item.source) {
                        let source = SquashNulls(data[key], 'info', prop);
                        let val = item.source[prop];
                        if (source == val || (source != '' && val == '!null')) {
                          //matches this key
                        } else {
                          matches = false;
                          break;
                        }
                      }
                      if (matches) {
                        node.attributes[attribute] = { imported: item.value };
                        return true;
                      }
                    });
                  }
                }
                update['/nodes/' + key] = node;
                GeofireTools.setGeohash('nodes', node, key, this.jobStyles, update);
              }
            }
            this.toast('Writing ' + (layer.name || layerKey), null, 0);
            await FirebaseWorker.ref('photoheight/jobs/' + jobId).update(update);
          } else if (layer.type == 'geojson') {
            let update = {};
            let items = {};
            for (let key in data) {
              if (google.maps.geometry.poly.containsLocation(new google.maps.LatLng(data[key].l[0], data[key].l[1]), polygon)) {
                items[key.split('-')[0]] = true;
              }
            }

            let newLayerId = 'imported_' + layer.name;
            let features = [];
            let count = 0;
            for (let key in items) {
              this.toast('Getting ' + (layer.name || layerKey) + ' ' + key, null, 0);
              let data = await firebase
                .app()
                .database(database)
                .ref(layer.dataPath + '/' + key)
                .once('value')
                .then((s) => s.val());
              features.push(data);
            }

            this.toast('Writing all layers...', null, 0);
            const { createLayer } = await import('../../modules/JobLayers.js');
            await createLayer({
              jobId,
              name: layer.name,
              features,
              layerId: newLayerId,
              overwrite: true
            });
          }
        });
      }
      this.toast('Reference data successfully copied.', null, 6000);
    }
  }

  _button_toggle_heat_map() {
    if (this.heatmap) {
      this.heatmap.setMap(null);
      this.hiddenLegendItems = null;
      this.heatmap = null;
      return;
    }

    this.hiddenLegendItems = 'all';

    const buttonModel = this.mappingButtons[this.activeCommand]?.models || {};
    const weightAttribute = buttonModel.weight_attribute || 'node_count';
    const radius = buttonModel.radius || 30;
    const dissipating = buttonModel.dissipating;
    const gradient = buttonModel.gradient;
    const maxIntensity = buttonModel.maxIntensity;
    const opacity = buttonModel.opacity;

    const heatMapData = Object.values(this.nodes).map((node) => {
      const weight = parseFloat(PickAnAttribute(node.attributes, weightAttribute)) || 0;
      return { location: new google.maps.LatLng(node.latitude, node.longitude), weight };
    });

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: heatMapData,
      radius,
      dissipating,
      gradient,
      maxIntensity,
      opacity
    });

    this.heatmap.setMap(this.map);
  }

  async _button_archive_jobs(e) {
    // ALl jobs created before this date will be archived.
    let archiveDate = new Date();
    archiveDate.setMonth(archiveDate.getMonth() - 3); // Three months prior to right now.
    let archiveTime = archiveDate.getTime();

    let jobKeys = [];
    let jobs =
      this.$.jobChooser.jobs ||
      (await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/list`)
        .orderByChild('status')
        .equalTo('active')
        .once('value')
        .then((s) => s.val()));
    for (let jobId in jobs) {
      if (jobs[jobId].status != 'archived') jobKeys.push(jobId);
    }
    let datesCreated = {};
    let jobPaths = {};
    let firebaseCounter = 0;

    this.$.progressToast.open();
    this.$.progressPercent.indeterminate = true;
    this.progressText = 'Scanning Jobs...';
    // Turn off all job choosers so that they don't freeze the interface for forever.
    this.shadowRoot.querySelectorAll('job-chooser').forEach((elem) => (elem.firebaseDisabled = true));

    jobKeys.forEach((jobKey) => {
      FirebaseWorker.ref('photoheight/jobs/' + jobKey + '/project_folder').once('value', (snapshot) => {
        jobPaths[jobKey] = snapshot.val();
        FirebaseWorker.ref('photoheight/jobs/' + jobKey + '/date_created').once('value', (snapshot) => {
          datesCreated[jobKey] = snapshot.val();
          if (++firebaseCounter == jobKeys.length) {
            let update = {};
            jobKeys.forEach((jobKey) => {
              if (datesCreated[jobKey] < archiveTime) {
                UpdateJobPermissions(
                  jobKey,
                  this.userGroup,
                  { status: 'archived' },
                  {
                    jobChooserOptions: this.get('companyOptions.job_chooser'),
                    updatePath: 'photoheight',
                    update
                  }
                );
                if (jobPaths[jobKey] != null) {
                  var encodedPath = jobPaths[jobKey].split('/').map(FirebaseEncode.encode).join('/folders/');
                  update['company_space/' + this.userGroup + '/project_folders/' + encodedPath + '/jobs/' + jobKey] = null;
                }
              }
            });
            this.progressText = 'Archiving Jobs...';
            FirebaseWorker.ref('photoheight').update(update, (err) => {
              if (!err) {
                this.progressText = 'Job Archiving Complete';
                this.$.progressPercent.indeterminate = false;
                this.progressPercent = 100;
                this.$.jobChooser.isDisabled = false;
                setTimeout(() => this.$.progressToast.close(), 3000);
                this.shadowRoot.querySelectorAll('job-chooser').forEach((elem) => (elem.firebaseDisabled = false));
                this.shadowRoot.querySelectorAll('job-chooser').forEach((elem) => (elem.firebaseDisabled = false));
                this.cancelPromptAction();
              }
            });
          }
        });
      });
    });
  }

  _button_toggle_polyline_editing(e) {
    this.polylinesEditable = !this.polylinesEditable;
    for (var connId in this.connections) {
      if (SquashNulls(this.mappingButtons, this.connections[connId].button, 'connection', 'polyline')) {
        this.togglePolylineEditing(connId, this.polylinesEditable);
      }
    }
    this.cancelPromptAction();
  }

  _button_move_item(e) {
    this.canDragOverride = true;
    this.cancelAfterDrag = true;
    this.$.katapultMap.openActionDialog({ title: 'Click and drag an item to move it.' });
  }

  async _button_insert_span_length(e) {
    this.toast('Inserting Span Length');
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    this.createWebWorker('insert_span_length', 'insert_span_length', [
      this.useMetricUnits,
      this.nodes,
      this.connections,
      photos,
      this.traces
    ]);
  }

  _button_insert_calculated_pole_length(e) {
    this.toast('Inserting Calculated Pole Length');
    this.createWebWorker('insert_calculated_pole_length', 'insert_calculated_pole_length', [this.nodes]);
  }

  _button_insert_foresite_wire_spec(e) {
    GetJobData(this.job_id, 'photos').then((data) => {
      var wirespec_lookup = {
        Primary: 'ACSR 2 AWG 6/1 SPARROW',
        Neutral: 'ACSR 2 AWG 6/1 SPARROW',
        'Static Wire': 'ACSR 2 AWG 6/1 SPARROW',
        'Street Light Feed': 'TRIPLEX 2 AWG',
        Secondary: 'TRIPLEX 2 AWG',
        'Power Drop': 'TRIPLEX 2 AWG',
        'Open Secondary': '#6 COPPER SOLID',
        'Power Guy': '25M',
        Guy: '10M',
        'CATV Com': 'COAX 18 AWG SERIES 6 NUMBER 9116',
        'Telco Com': 'ANMA 100 PR.',
        'Fiber Optic Com': '144 FIBERS (OFSDE)'
      };
      var update = {};
      for (var connId in this.connections) {
        for (var sectionId in this.connections[connId].sections) {
          for (var photoId in this.connections[connId].sections[sectionId].photos) {
            if (
              this.connections[connId].sections[sectionId].photos[photoId] == 'main' ||
              this.connections[connId].sections[sectionId].photos[photoId].association == 'main'
            ) {
              var wires = SquashNulls(data.photos, photoId, 'photofirst_data', 'wire');
              for (var itemKey in wires) {
                var wire_spec = wirespec_lookup[SquashNulls(this.traces, wires[itemKey]._trace, 'cable_type')];
                if (wire_spec != null && (wires[itemKey].wire_spec == '' || wires[itemKey].wire_spec == null)) {
                  update[photoId + '/photofirst_data/wire/' + itemKey + '/wire_spec'] = wire_spec;
                }
              }
            }
          }
        }
      }
      FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photos').update(
        update,
        function (err) {
          this.cancelPromptAction();
          if (err) {
            this.toast(err);
          } else {
            this.toast('Wire Spec Successfully Updated.');
          }
        }.bind(this)
      );
    });
  }

  async _button_insert_power_annotations(e) {
    // Get item data
    let item = this.hoverItemData.item;
    let itemKey = this.hoverItemData.key;
    // Short circuit if item or key is null or undefined
    if (!item || !itemKey) return;
    await import('../power-annotation-generator/power-annotation-generator-dialog.js');
    this.$.powerAnnotationGeneratorDialog.open(
      { nodeId: itemKey, jobId: this.job_id },
      { useMetricUnits: this.useMetricUnits, modelDefaults: this.modelDefaults }
    );
  }

  // a custom function that will star photos based on different logic than our normal process
  async _button_star_main_photos(e) {
    if (this.userGroup == 'techserv_pnm_audit') {
      this.toast('Setting main photos...');
      // loop through all nodes
      let update = {};
      let clearUpdate = {};
      for (let nodeId in this.nodes) {
        // clear existing main photos
        for (let photoId in this.nodes[nodeId].photos) {
          clearUpdate[`nodes/${nodeId}/photos/${photoId}`] = { association: true };
        }

        let photoCount = Object.keys(this.nodes[nodeId].photos || {}).length;
        let photoToStar = '';
        if (photoCount == 1) photoToStar = Object.keys(this.nodes[nodeId].photos)[0];
        else if (photoCount > 1) photoToStar = await this.findLastTakenPhoto(Object.keys(this.nodes[nodeId].photos));
        if (photoToStar) update[`nodes/${nodeId}/photos/${photoToStar}`] = { association: 'main' };
      }

      for (let connId in this.connections) {
        let conn = this.connections[connId];
        for (let sectionId in conn.sections) {
          let section = conn.sections[sectionId];

          // clear existing main photos
          for (let photoId in section.photos) {
            clearUpdate[`connections/${connId}/sections/${sectionId}/photos/${photoId}`] = { association: true };
          }

          let photoCount = Object.keys(section.photos || {}).length;
          let photoToStar = '';
          if (photoCount == 1) photoToStar = Object.keys(section.photos)[0];
          else if (photoCount > 1) photoToStar = await this.findLastTakenPhoto(Object.keys(section.photos));
          if (photoToStar) update[`connections/${connId}/sections/${sectionId}/photos/${photoToStar}`] = { association: 'main' };
        }
      }

      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(clearUpdate);
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
      this.toast('Done setting main photos');
      this.cancelPromptAction();
    } else {
      this.toast('This button is improperly configured to run');
    }
  }

  _button_star_pole_heights(e) {
    this.$.katapultMap.openActionDialog({
      title: 'Star the height photo of every node and span section, so that photo will appear in the trace view.',
      starFirstCheckbox: true,
      buttons: [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Star', callback: this.buttonStarPoleHeights.bind(this), attributes: { 'secondary-color': 'var(--secondary-color)' } }
      ]
    });
  }

  async buttonStarPoleHeights() {
    let clearMainUpdate = {};
    let update = {};
    const connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);
    let nodeAndSectionWarnings = [];
    this.toast('Setting main photos...');
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos);
    for (let nodeId in this.nodes) {
      const nodePhotos = this.nodes[nodeId].photos;
      if (nodePhotos != null) {
        let heightPhotos = [];
        for (let photoId in nodePhotos) {
          let poleHeight = SquashNulls(photos, photoId, 'photofirst_data', 'poleHeight');
          if (poleHeight) {
            heightPhotos.push(photoId);
          }
        }
        if (heightPhotos.length > 0) {
          // Loop through all the node photos and set any that are marked as 'main' to true
          for (const [photoId, photo] of Object.entries(nodePhotos)) {
            // Clean up legacy data structure if it exists
            if (photo == 'main' || photo === true) clearMainUpdate[`nodes/${nodeId}/photos/${photoId}`] = { association: true };
            else if (photo.association == 'main') clearMainUpdate[`nodes/${nodeId}/photos/${photoId}/association`] = true;
          }
          // of all the height photos get the most recently taken one and set its association to 'main'
          let mostRecentHeightPhoto = await this.findLastTakenPhoto(heightPhotos);
          update[`nodes/${nodeId}/photos/${mostRecentHeightPhoto}`] = { association: 'main' };
          // Check if a photo is already starred
        } else if (!Object.values(nodePhotos).some((nodePhoto) => nodePhoto == 'main' || nodePhoto.association == 'main')) {
          if (this.$.katapultMap.starFirstCheck) {
            // Pick the first photo on the node if no height photos are present
            const nonHeightMainPhoto = Object.keys(nodePhotos)[0];
            update[`nodes/${nodeId}/photos/${nonHeightMainPhoto}`] = { association: 'main' };
          } else {
            // If there are photos on the section but a main photo wasn't selected
            // Get the ID of the pole
            let title = '';
            if (PickAnAttribute(this.nodes[nodeId]?.attributes, 'structure_id')) {
              title = 'SSID ' + PickAnAttribute(this.nodes[nodeId].attributes, 'structure_id');
            } else if (PickAnAttribute(this.nodes[nodeId]?.attributes, 'scid')) {
              title = 'SCID ' + PickAnAttribute(this.nodes[nodeId].attributes, 'scid');
            } else {
              title = 'Blank ID';
            }
            // Generate the warning message
            nodeAndSectionWarnings.push({
              key: nodeId,
              generalType: 'node',
              description: [title],
              warnings: ['Main photo could not be selected']
            });
          }
        }
      }
    }
    for (let connId in this.connections) {
      const conn = this.connections[connId];
      for (let sectionId in conn.sections) {
        const sectionPhotos = conn.sections[sectionId].photos;
        if (sectionPhotos != null) {
          let heightPhotos = [];
          for (let photoId in sectionPhotos) {
            let midspanHeight = SquashNulls(photos, photoId, 'photofirst_data', 'midspanHeight');
            if (midspanHeight) {
              heightPhotos.push(photoId);
            }
          }
          if (heightPhotos.length > 0) {
            // Loop through all the section photos and set any that are marked as 'main' to true
            for (const [photoId, photo] of Object.entries(sectionPhotos)) {
              // Clean up legacy data structure if it exists
              if (photo == 'main' || photo === true)
                clearMainUpdate[`connections/${connId}/sections/${sectionId}/photos/${photoId}`] = { association: true };
              else if (photo.association == 'main')
                clearMainUpdate[`connections/${connId}/sections/${sectionId}/photos/${photoId}/association`] = true;
            }
            // of all the height photos get the most recently taken one and set its association to 'main'
            let mostRecentHeightPhoto = await this.findLastTakenPhoto(heightPhotos);
            update[`connections/${connId}/sections/${sectionId}/photos/${mostRecentHeightPhoto}`] = { association: 'main' };
            // Check if a photo is already starred
          } else if (!Object.values(sectionPhotos).some((sectionPhoto) => sectionPhoto == 'main' || sectionPhoto.association == 'main')) {
            if (this.$.katapultMap.starFirstCheck) {
              // Pick the first photo on the section if no height photos are present
              const nonHeightMainPhoto = Object.keys(sectionPhotos)[0];
              update[`connections/${connId}/sections/${sectionId}/photos/${nonHeightMainPhoto}`] = { association: 'main' };
            } else {
              // If there are photos on the section but a main photo wasn't selected
              // Get the ID of the backspan pole
              const fromNodeId = conn?.node_id_1;
              let fromNode = '';
              if (PickAnAttribute(this.nodes[fromNodeId]?.attributes, 'structure_id')) {
                fromNode = 'SSID ' + PickAnAttribute(this.nodes[fromNodeId].attributes, 'structure_id');
              } else if (PickAnAttribute(this.nodes[fromNodeId]?.attributes, 'scid')) {
                fromNode = 'SCID ' + PickAnAttribute(this.nodes[fromNodeId].attributes, 'scid');
              } else {
                fromNode = 'Blank ID';
              }
              // Get the ID of the forespan pole
              const toNodeId = conn?.node_id_2;
              let toNode = '';
              if (PickAnAttribute(this.nodes[toNodeId]?.attributes, 'structure_id')) {
                toNode = 'SSID ' + PickAnAttribute(this.nodes[toNodeId].attributes, 'structure_id');
              } else if (PickAnAttribute(this.nodes[toNodeId]?.attributes, 'scid')) {
                toNode = 'SCID ' + PickAnAttribute(this.nodes[toNodeId].attributes, 'scid');
              } else {
                toNode = 'Blank ID';
              }
              // Generate the warning message
              const title = fromNode + ' - ' + toNode;
              nodeAndSectionWarnings.push({
                key: sectionId,
                connId: connId,
                generalType: 'section',
                description: [title],
                warnings: ['Main photo could not be selected']
              });
            }
          }
        }
      }
    }
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(clearMainUpdate);
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
    if (nodeAndSectionWarnings.length > 0) {
      this.toast('Some nodes and sections were not assigned a main photo.');
      // Show a dialog with all of the nodes and sections that have photos but couldn't select a main photo
      this.displayWarningsDialog({ title: 'Star Height Photos', photoWarnings: nodeAndSectionWarnings });
    } else {
      this.toast('Done setting main photos with no warnings.');
    }
    this.cancelPromptAction();
  }

  async _button_star_pole_and_set_status(e) {
    this.toast('Checking poles photos...');
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    this.createWebWorker('star_pole_and_set_status', 'star_pole_and_set_status', [this.jobStyles, this.nodes, photos]);
  }

  _button_insert_wire_spec(e) {
    GetJobData(this.job_id, 'photos').then((data) => {
      var wirespec_lookup = SquashNulls(this.mappingButtons, this.activeCommand, 'models');
      var warnings = { title: 'Insert Wire Spec Warnings', nodeWarnings: [], generalWarnings: [], sectionWarnings: [] };
      if (wirespec_lookup == '') {
        this.cancelPromptAction();
        this.toast('Models not properly configured for this button.');
      } else {
        var update = {};
        for (var connId in this.connections) {
          for (var sectionId in this.connections[connId].sections) {
            for (var photoId in this.connections[connId].sections[sectionId].photos) {
              if (
                this.connections[connId].sections[sectionId].photos[photoId] == 'main' ||
                this.connections[connId].sections[sectionId].photos[photoId].association == 'main'
              ) {
                var wires = SquashNulls(data.photos, photoId, 'photofirst_data', 'wire');
                for (var itemKey in wires) {
                  var cableWireSpec = wirespec_lookup[SquashNulls(this.traces, wires[itemKey]._trace, 'cable_type')];
                  if (
                    cableWireSpec != null &&
                    (wirespec_lookup._overwrite || wires[itemKey].wire_spec == '' || wires[itemKey].wire_spec == null)
                  ) {
                    var wire_spec = cableWireSpec.default;
                    var photoIds = null;
                    if (cableWireSpec.insulator_spec != null) {
                      photoIds = [
                        this.getMainPhotoFromNodeId(this.connections[connId].node_id_1),
                        this.getMainPhotoFromNodeId(this.connections[connId].node_id_2)
                      ];
                      for (var i = 0; i < photoIds.length; i++) {
                        var insulators = SquashNulls(data.photos, photoIds[i], 'photofirst_data', 'insulator');
                        for (var iKey in insulators) {
                          if (
                            insulators[iKey]._trace == wires[itemKey]._trace &&
                            cableWireSpec.insulator_spec[insulators[iKey].insulator_spec] != null
                          ) {
                            wire_spec = cableWireSpec.insulator_spec[insulators[iKey].insulator_spec];
                          }
                        }
                      }
                    }
                    if (cableWireSpec.wire_attributes != null) {
                      var matches = true;
                      for (var aKey in cableWireSpec.wire_attributes.attributes) {
                        if (cableWireSpec.wire_attributes.attributes[aKey] != wires[itemKey][aKey]) {
                          matches = false;
                          break;
                        }
                      }
                      if (matches) {
                        wire_spec = cableWireSpec.wire_attributes.value;
                      } else {
                        photoIds = [
                          this.getMainPhotoFromNodeId(this.connections[connId].node_id_1),
                          this.getMainPhotoFromNodeId(this.connections[connId].node_id_2)
                        ];
                        for (var i = 0; i < photoIds.length; i++) {
                          var photoData = SquashNulls(data.photos, photoIds[i], 'photofirst_data');
                          for (var property in photoData) {
                            for (var itmKey in photoData[property]) {
                              RunOnChildren(
                                photoData[property][itmKey],
                                function (child, path, childProperty, childItemKey) {
                                  if (child._trace == wires[itemKey]._trace) {
                                    var matches = true;
                                    for (var aKey in cableWireSpec.wire_attributes.attributes) {
                                      if (cableWireSpec.wire_attributes.attributes[aKey] != child[aKey]) {
                                        matches = false;
                                        break;
                                      }
                                    }
                                    if (matches) {
                                      wire_spec = cableWireSpec.wire_attributes.value;
                                    }
                                  }
                                }.bind(this)
                              );
                            }
                          }
                        }
                      }
                    }
                    update[photoId + '/photofirst_data/wire/' + itemKey + '/wire_spec'] = wire_spec;
                  }
                }
              }
            }
          }
        }
        if (wirespec_lookup.Guying != null) {
          for (var nodeId in this.nodes) {
            for (var photoId in this.nodes[nodeId].photos) {
              if (this.nodes[nodeId].photos[photoId] == 'main' || this.nodes[nodeId].photos[photoId].association == 'main') {
                var guying = SquashNulls(data.photos, photoId, 'photofirst_data', 'guying');
                for (var itemKey in guying) {
                  if (wirespec_lookup._overwrite || guying[itemKey].wire_spec == '' || guying[itemKey].wire_spec == null) {
                    update[photoId + '/photofirst_data/guying/' + itemKey + '/wire_spec'] = wirespec_lookup.Guying.default;
                  }
                }
              }
            }
          }
        }
        this.insertDownGuySpecFromAnchor(wirespec_lookup, update, warnings, data.photos);
      }
    });
  }

  insertDownGuySpecFromAnchor(wirespec_lookup, update, warnings, photos) {
    if (wirespec_lookup.down_guys != null) {
      var specProperty = SquashNulls(this.otherAttributes, 'down_guy_spec') == '' ? 'wire_spec' : 'down_guy_spec';
      var dnGuyOptions = {};
      var picklists = SquashNulls(this.otherAttributes, specProperty, 'picklists');
      for (var key in picklists) {
        picklists[key].forEach((item) => {
          dnGuyOptions[item.value] = true;
        });
      }
      var dnGuyLookup = {};
      for (var i = 0; i < wirespec_lookup.down_guys.length; i++) {
        dnGuyLookup[wirespec_lookup.down_guys[i].input] = wirespec_lookup.down_guys[i].output;
      }
      for (var nodeId in this.nodes) {
        let orderingAttribute =
          PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.ordering_attribute) ||
          `(No ${this.modelDefaults.ordering_attribute_label})`;
        let nodeWarnings = [];
        for (var photoId in this.nodes[nodeId].photos) {
          if (this.nodes[nodeId].photos[photoId] == 'main' || this.nodes[nodeId].photos[photoId].association == 'main') {
            var guying = SquashNulls(photos, photoId, 'photofirst_data', 'guying');
            var dnGuys = {};
            var doGuying = true;
            for (var itemKey in guying) {
              if (guying[itemKey].guying_type == 'down guy' && !SquashNulls(this.traces, guying[itemKey]._trace, 'proposed')) {
                if (guying[itemKey].anchor_id != null && guying[itemKey].anchor_id != '') {
                  dnGuys[guying[itemKey].anchor_id] = dnGuys[guying[itemKey].anchor_id] || [];
                  dnGuys[guying[itemKey].anchor_id].push({ marker: guying[itemKey], itemKey });
                } else if (guying[itemKey].proposed_anchor_id != null && guying[itemKey].proposed_anchor_id != '') {
                  dnGuys[guying[itemKey].proposed_anchor_id] = dnGuys[guying[itemKey].proposed_anchor_id] || [];
                  dnGuys[guying[itemKey].proposed_anchor_id].push({ marker: guying[itemKey], itemKey });
                } else {
                  doGuying = false;
                  let downGuyCompany = this.get(`traces.${guying[itemKey]._trace}.company`);
                  let downGuyHeight = DataViews.help.getMarkerHeight(guying[itemKey], this.useMetricUnits);
                  nodeWarnings.push(
                    `${downGuyCompany ? downGuyCompany + ' d' : 'D'}own guy at ${downGuyHeight} is not linked to an anchor`
                  );
                }
              }
            }
            if (doGuying) {
              for (var anchor_id in dnGuys) {
                dnGuys[anchor_id].sort(function (a, b) {
                  return (b.marker._measured_height || b.marker._manual_height) - (a.marker._measured_height || a.marker._manual_height);
                });
                for (var i = 0; i < dnGuys[anchor_id].length; i++) {
                  if (
                    wirespec_lookup._overwrite ||
                    dnGuys[anchor_id][i].marker[specProperty] == '' ||
                    dnGuys[anchor_id][i].marker[specProperty] == null
                  ) {
                    var sizes = PickAnAttribute(SquashNulls(this.nodes, anchor_id, 'attributes'), 'sizes_of_attached_dn_guys');
                    if (sizes != null) {
                      sizes = sizes.split(',');
                      if (sizes.length == dnGuys[anchor_id].length) {
                        if (sizes[i] != null) {
                          var lookupSpec = dnGuyLookup[sizes[i].toLowerCase().trim()] || sizes[i];
                          if (dnGuyOptions[lookupSpec] == null) {
                            nodeWarnings.push(
                              `Unknown down guy spec ${sizes[i]} on ${this.modelDefaults.ordering_attribute_label} ${
                                PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.ordering_attribute) ||
                                '(No ' + this.modelDefaults.ordering_attribute_label + ')'
                              }`
                            );
                          }
                          update[photoId + '/photofirst_data/guying/' + dnGuys[anchor_id][i].itemKey + '/' + specProperty] = lookupSpec;
                        }
                      } else {
                        nodeWarnings.push('Down guy count does not match number of connected anchors');
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // Check if there are warnings for the node, and add a record for the node
        if (nodeWarnings.length > 0) {
          warnings.nodeWarnings.push({
            key: nodeId,
            description: orderingAttribute,
            warnings: nodeWarnings
          });
        }
      }
    }
    FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photos').update(update, (err) => {
      this.cancelPromptAction();
      if (err) this.toast(err);
      else {
        if (warnings.generalWarnings.length > 0 || warnings.nodeWarnings.length > 0 || warnings.sectionWarnings.length > 0) {
          this.displayWarningsDialog(warnings);
        } else this.toast('Finished inserting com spec');
      }
    });
  }

  getSpecFromXML(xmlString) {
    let xmlParser = new DOMParser();
    let domElement = xmlParser.parseFromString(xmlString, 'text/html');
    // get the value element with a name equal to "TYPE" (there will only ever be one)
    let typeElement = domElement?.getElementsByName('Type')[0];
    let spec = typeElement?.innerHTML;
    if (spec) return spec;
  }

  createMappingWithCatalogData(catalogData) {
    let mapping = {};
    for (let newWireSpecData of catalogData) {
      // get the xml data where the messenger spec and wire specs are located and skip this item if they don't have either of them
      let messengerXML = newWireSpecData?.ocalc_xml;
      let wireSpecXMLDataArray = newWireSpecData?.bundled_components;
      let newWireSpec = newWireSpecData.value;
      if (!wireSpecXMLDataArray || !messengerXML || !newWireSpec) continue;

      // get the new value of the new update wire spec
      // get the messenger spec from the xml
      let messengerSpec = this.getSpecFromXML(messengerXML);
      // skip this entry of the catalog data if we couldn't get a messenger spec out of the xml
      if (!messengerSpec) continue;
      // set the messenger spec portion of the mapping
      if (!mapping[messengerSpec]) mapping[messengerSpec] = {};
      // loop through every wire spec
      for (let wireSpecXML of wireSpecXMLDataArray) {
        let oldWireSpec = this.getSpecFromXML(wireSpecXML);
        mapping[messengerSpec][oldWireSpec] = newWireSpec;
      }
    }

    return mapping;
  }

  async _button_convert_wire_spec_ocalc(e) {
    // get the wire specs from the catalog
    let ocalcWireSpecCatalogData = await FirebaseWorker.ref(
      `photoheight/company_space/${this.jobCreator}/models/export_models/ocalc/catalogs/defaultCatalog/wire_spec`
    )
      .once('value')
      .then((s) => s.val());
    let bundledWireSpecData = ocalcWireSpecCatalogData.filter((catalogData) => catalogData.bundled_components?.length > 0);
    let wireSpecMapping = this.createMappingWithCatalogData(bundledWireSpecData);

    // get photos for this job
    let data = await GetJobData(this.job_id, 'photos');
    let photos = data?.photos || {};
    let shouldConvert = this.userGroup != 'tilson';
    let message = `This action will convert the wire specs of all midspan wires, then delete messengers from the job. A snapshot that can be used to restore the data if necessary will be created when "Continue" is pressed.  
    Please read the conditions and enter a name for the snapshot below. <br><br>  For this action to work, the following conditions need to be true: <br>
    1. Every midspan wire that is traced to a messenger marker in a connected pole needs to have a valid wire spec set. <br>
    2. Each of the connected messengers must have a valid messenger spec. <br>
    3. The wire spec and messenger spec must match to a new wire spec, as defined in the O-Calc catalog.  For these to match properly, the current model needs to have the latest version of the O-Calc catalog.`;
    let title = 'Convert Old O-Calc Data';
    if (!shouldConvert) {
      message = `This action will delete messengers from the job. A snapshot that can be used to restore the data if necessary will be created when "Continue" is pressed.  
        Please enter a name for the snapshot below.`;
      title = 'Remove messengers from job';
    }
    this.confirm(
      title,
      message,
      'Continue',
      'Cancel',
      'background-color:var(--secondary-color); color:white;',
      'createSnapshot',
      async () => {
        if (this.newSnapshotName) {
          await this.$.infoPanel.$.snapshots.createSnapshot(this.newSnapshotName, this.newSnapshotNumbers, this);
          await new Promise((x) => setTimeout(x, 500));
          // start the web worker after the snapshot has been created
          this.createWebWorker('convert_wire_spec_ocalc', 'convert_wire_spec_ocalc', [
            wireSpecMapping,
            this.nodes,
            this.connections,
            photos,
            this.traces,
            this.useMetricUnits,
            this.modelDefaults,
            shouldConvert
          ]);
          this.cancelPromptAction();
        } else this.toast('Please enter a valid snapshot name');
      }
    );
  }

  async _button_katapult_pole_loading(e) {
    // Open confirm dialog for analysis (on confirm, run callback)
    this.confirm(
      'Pole Loading',
      'Pole Loading will run on all poles in the job and log the results in the console. This could take a minute or two.',
      'Analyze Job',
      'Cancel',
      null,
      null,
      async () => {
        // Call the loading analysis engine and get results
        const KplaEngine = await getKPLAEngine({ jobId: this.job_id });
        let KPLA = new KplaEngine(this.jobCreator, this.job_id);
        let results = await KPLA.analyzeJob({ useProposed: this.showClearances });
      }
    );
  }

  async _button_kpla_unit_test(e) {
    this.createWebWorker('calculate_drift', 'kpla_unit_job', [{ modelDefaults: this.modelDefaults }]);
  }

  _button_set_kpla_baseline(e) {
    this.createWebWorker('set_baseline', 'kpla_unit_job', [{ modelDefaults: this.modelDefaults }]);
  }

  _button_insert_itc_pole_type(e) {
    GetJobData(this.job_id, 'photos').then((data) => {
      // power, power w/transformer, joint, joint w/transformer, telco
      var powerLookup = {};
      var powerCables = SquashNulls(this.otherAttributes, 'cable_type', 'picklists', 'power');
      for (var i = 0; i < powerCables.length; i++) {
        powerLookup[powerCables[i].value] = true;
      }

      var warnings = { title: 'Insert ITC Pole Type Warnings', nodeWarnings: [], generalWarnings: [], sectionWarnings: [] };
      var update = {};
      for (let nodeKey in this.nodes) {
        let nodeWarnings = [];
        var node = this.nodes[nodeKey];
        var node_type = PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute);
        if (this.modelDefaults.pole_node_types.includes(node_type)) {
          var poleType = PickAnAttribute(node.attributes, 'pole_type');
          var orderingAttribute =
            PickAnAttribute(node.attributes, this.modelDefaults.ordering_attribute) ||
            `(No ${this.modelDefaults.ordering_attribute_label})`;
          if (poleType == null || poleType == '') {
            var mainPhoto = null;
            // set main photo
            for (let photoKey in node.photos) {
              if (node.photos[photoKey] == 'main' || node.photos[photoKey].association == 'main') {
                mainPhoto = photoKey;
                break;
              }
            }
            if (mainPhoto != null) {
              var photo = data.photos[mainPhoto];
              var photoData = SquashNulls(photo, 'photofirst_data');
              // check if pole has a transformer
              // check if joint use, power only, or tel only
              var hasTransformer = false;
              var power = false,
                com = false;
              for (var property in photoData) {
                for (var itemKey in photoData[property]) {
                  var marker = photoData[property][itemKey];
                  if (property == 'wire' && SquashNulls(this.traces, marker._trace, 'proposed') !== true) {
                    var cable_type = SquashNulls(this.traces, marker._trace, 'cable_type');
                    if (cable_type != '') {
                      if (powerLookup[cable_type] != null) {
                        power = true;
                      } else {
                        com = true;
                      }
                    }
                  } else if (property == 'equipment') {
                    if (marker.equipment_type != null && marker.equipment_type.indexOf('transformer') > -1) {
                      hasTransformer = true;
                      break;
                    }
                  } else {
                    RunOnChildren(
                      marker,
                      function (child, path, childProperty, childItemKey) {
                        if (childProperty == 'wire' && SquashNulls(this.traces, child._trace, 'proposed') !== true) {
                          var cable_type = SquashNulls(this.traces, child._trace, 'cable_type');
                          if (cable_type != '') {
                            if (powerLookup[cable_type] != null) {
                              power = true;
                            } else {
                              com = true;
                            }
                          }
                        }
                      }.bind(this)
                    );
                  }
                }
              }
              var type = '';
              if (com && power) {
                type = 'Joint';
              } else if (com) {
                type = 'Telco Only';
              } else if (power) {
                type = 'Power';
              }

              if (hasTransformer) {
                if (power) {
                  type += ' with Transformer';
                } else {
                  nodeWarnings.push('Pole has a transformer with no power wires.');
                }
              }
              if (type == '') {
                nodeWarnings.push('No matching type found.');
              } else {
                update[nodeKey + '/attributes/pole_type/'] = { button_inserted: type };
              }
            }
          }
        }

        if (nodeWarnings.length > 0) {
          warnings.nodeWarnings.push({
            key: nodeKey,
            description: orderingAttribute,
            warnings: nodeWarnings
          });
        }
      }
      FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/nodes').update(update, (error) => {
        if (error) {
          this.toast(error);
        }

        if (warnings.generalWarnings.length > 0 || warnings.nodeWarnings.length > 0 || warnings.sectionWarnings.length > 0) {
          this.displayWarningsDialog(warnings);
        } else {
          this.toast('Finished inserting pole type');
        }

        this.cancelPromptAction();
      });
    });
  }

  _button_classify_photos(e) {
    this.linkedWindow = window.open(
      encodeURI(window.location.pathname.replace('/map/', '/photos/') + '#' + this.job_id),
      'photofirst',
      this.dontLinkSpawnedWindows ? 'noopener,toolbar,menubar' : ''
    ) || { opener: { closed: false }, focus: () => {} };
    this.cancelPromptAction();
  }

  async _button_add_nodes_to_workflow(e) {
    this.selectedNode = null;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = { nodes: true, sections: false, connections: false };

    const appType = this.metadata.app_type;
    const workflowLabel = appType ? `${capitalCase(appType)} Application` : 'workflow';
    this.$.katapultMap.openActionDialog({
      text: `Draw a polygon around the nodes you want to add to the ${workflowLabel}.`,
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: `Add nodes to the ${workflowLabel}`,
          callback: async () => {
            this.progressText = `Adding nodes to the ${workflowLabel}... `;
            this.progressPercent = 0;
            this.$.progressToast.open();
            await this.completeAddNodesToWorkflow({
              onProgress: (percent) => {
                this.progressPercent = percent;
              }
            })
              .then((importedNodeIds) => {
                const numNodes = importedNodeIds?.length ?? 0;
                if (numNodes > 0) {
                  this.toast(`Successfully added ${numNodes} ${pluralize('node', numNodes)} to the ${workflowLabel}`, null, 3000);
                } else {
                  this.toast(`All selected nodes alreaday exist in the ${workflowLabel}`, null, 3000);
                }
              })
              .catch((err) => {
                this.toast(`Failed to add nodes to the ${workflowLabel}`, null, 3000);
                throw err;
              })
              .finally(() => {
                this.cancelPromptAction();
              });
          },
          attributes: {
            style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); padding: 12px; margin:0 5px'
          }
        }
      ]
    });
  }

  async completeAddNodesToWorkflow(options = {}) {
    const { nodes } = this;

    const selectedNodeIds = this.$.katapultMap.multiSelectedNodes;
    if (selectedNodeIds.length == 0) return;

    const getNodePoleAppOrder = (node) => parseInt(node?.attributes?.pole_app_order?.app_added);

    // Get all of the poles in the job and find the largest pole_app_order attribute value.
    const poles = Object.values(nodes ?? {}).filter((node) => {
      const nodeType = PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute);
      return this.modelDefaults.pole_node_types.includes(nodeType);
    });
    const existingPoleAppOrderValues = poles.map(getNodePoleAppOrder).filter((x) => !isNaN(x));
    const largestPoleAppOrder = Math.max(-1, ...existingPoleAppOrderValues); // Math.max() returns -Infinity if not values are supplied, so I also add -1.

    // TODO (2025-03-13): This is bad UX because the user doesn't see this filtering happening
    // Filter the selected nodes (currently only poles not already in the application)
    const filteredNodeIds = selectedNodeIds.filter((nodeId) => {
      const node = nodes[nodeId];

      const nodeType = PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute);
      const isPoleNodeType = this.modelDefaults.pole_node_types.includes(nodeType);
      if (!isPoleNodeType) return false;

      const alreadyAddedToApplication = !isNaN(getNodePoleAppOrder(node));
      if (alreadyAddedToApplication) return false;

      return true;
    });

    const sortedNodeIds = filteredNodeIds.sort((nodeIdA, nodeIdB) => {
      const orderingAttribute = this.modelDefaults.ordering_attribute;
      const nodeA = nodes[nodeIdA];
      const nodeB = nodes[nodeIdB];
      const aOrderingAttribute = PickAnAttribute(nodeA.attributes, orderingAttribute) ?? '';
      const bOrderingAttribute = PickAnAttribute(nodeB.attributes, orderingAttribute) ?? '';
      return aOrderingAttribute.localeCompare(bOrderingAttribute);
    });

    const realtimeDb = globalThis.FirebaseWorker.database();

    const { utilityCompany } = this.config.firebaseData;
    const portalConfig = (await realtimeDb.ref(`photoheight/company_space/${utilityCompany}/portal_config`).once('value')).val();

    const jobRef = new KatapultJob(this.job_id, { noCache: true });

    for (const [index, nodeId] of sortedNodeIds.entries()) {
      const supplementalData = {
        jobId: this.job_id,
        nodeId,
        metadata: this.metadata,
        nodes,
        mapStyles: this.jobStyles,
        sharing: this.sharedCompanies,
        userGroup: this.userGroup,
        portalConfig: portalConfig
      };
      const poleAppOrder = largestPoleAppOrder + index + 1;
      // Flag this pole as the takeoff pole if it has index zero.
      if (poleAppOrder === 0) await jobRef.updateNodeAttribute(nodeId, 'take_off_pole', { app_added: true });
      // Set pole_app_order on the node.
      await jobRef.updateNodeAttribute(nodeId, 'pole_app_order', { app_added: poleAppOrder });
      // Since we have updated the node, we need to fetch a new copy of it.
      const updatedNode = await jobRef.getNodeById(nodeId);
      // Add this updated node to the application.
      await AddPoleToApplication(updatedNode, supplementalData);

      if (typeof options.onProgress === 'function') options.onProgress(((index + 1) / sortedNodeIds.length) * 100);
    }
    return sortedNodeIds;
  }

  ///////end button_functions (more below after some other code)

  async findLastTakenPhoto(photos) {
    let time = 0;
    let key = photos[0];
    if (photos.length > 1) {
      for (let i = 0; i < photos.length; i++) {
        let date_taken = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photos/${photos[i]}/date_taken`)
          .once('value')
          .then((s) => s.val());
        if (time == 0) time = date_taken;
        else if (date_taken > time) {
          time = date_taken;
          key = photos[i];
        }
      }
    }
    return key;
  }

  scrollToCurrent(e, d) {
    if (this.job_id) {
      d.searchTextChanged();
    }
  }

  async shareJob(e) {
    await import('../job-chooser/project-folder-chooser.js');
    await import('../share-job/share-job.js');
    this.$.projectFolderChooser.$.shareJob.open(this.job_id, this.jobName, this.jobCreator, this.jobOwner, this.projectFolder);
  }

  activeCommandChanged() {
    if (this.activeCommand == 'measure_bearing') {
      this.labelOptions = {
        showLength: false,
        showAngle: true
      };
    } else {
      this.labelOptions = null;
    }
  }

  shortcutKeyPressed(e) {
    let keyEvent = e.detail.keyboardEvent;
    if (this._sharing == 'write') {
      var key = keyEvent.key;
      let composedPath = keyEvent.composedPath();
      if (
        composedPath[0].tagName != 'INPUT' &&
        composedPath[0].tagName != 'TEXTAREA' &&
        !keyEvent.altKey &&
        !keyEvent.ctrlKey &&
        !keyEvent.shiftKey &&
        !keyEvent.metaKey
      ) {
        var now = new Date().getTime();
        // temporary fix for now
        if (this.lastShortcut != null && this.lastShortcut.time + 1000 > now && key != 'd' && key != 'p') {
          key = this.lastShortcut.key + key;
        }
        this.lastShortcut = { time: now, key: keyEvent.key };
        // If we are hovering over a pole (and we can write) try to apply shortcuts
        if (this.poleHover && this._sharing == 'write') this.applyShortcuts(keyEvent);
        // Otherwise, if there is an active toolset, do stuff
        else if (this.activeToolset) {
          for (var i = 0; i < this.activeToolset.length; i++) {
            if (this.activeToolset[i].shortcut == key) {
              this.mappingButtonPressed({ detail: { mappingButtonId: this.activeToolset[i].id } });
              break;
            }
          }
        }
      }
      // Handle alt+a
      if (key.toLowerCase() == 'a' && event.altKey && this.editing) {
        // focus the attribute dropdown of the info panel
        this.$.infoPanel.shadowRoot.querySelector('#addAttributeInput').focus();
      }
    }
  }

  async mappingButtonPressed(e) {
    this.activeCommand = e.detail.mappingButtonId;
    this.actionDialogModel = this.get(`mappingButtons.${this.activeCommand}`) || {};
    if (SquashNulls(this.nodes, this.editingNode) == '') {
      this.editingNode = null;
    }
    if (SquashNulls(this.nodes, this.selectedNode) == '') {
      this.selectedNode = null;
    }

    if (
      this.actionDialogModel.connection != null &&
      (this.actionDialogModel.connection.location_1 == 'target' || this.actionDialogModel.connection.location_2 == 'target')
    ) {
      if (this.activeCommand == 'measure_bearing') {
        if (this.selectedNode == null)
          this.$.katapultMap.openActionDialog({
            title: 'Please select a starting node for measurement, then click to copy bearing measurement.'
          });
        else this.$.katapultMap.openActionDialog({ title: 'Click to copy bearing measurement.' });
      } else this.$.katapultMap.openActionDialog({ title: 'Please Select a Node to ' + this.actionDialogModel.label });
      // // Removed so that Join could be rubberbanded
      // this.selectedNode = null;
      // this.editingNode = null;
    } else if (this.actionDialogModel != null && this.actionDialogModel.node != null && this.actionDialogModel.node.location == 'new') {
      let prefix = this.actionDialogModel.icon === 'create' ? 'Draw ' : '';
      this.$.katapultMap.openActionDialog({ title: prefix + this.actionDialogModel.label });
    } else if (
      this.actionDialogModel != null &&
      this.actionDialogModel.section != null &&
      this.actionDialogModel.section.location == 'new'
    ) {
      this.$.katapultMap.openActionDialog({ text: 'Please Enter Next Section' });
    } else if (this.actionDialogModel != null && this.actionDialogModel.node != null && this.actionDialogModel.node.location == 'new') {
      this.$.katapultMap.openActionDialog({ text: 'Please Enter Node' });
    } else if (this.actionDialogModel.function != null) {
      if (this.actionDialogModel.function.indexOf('_button') == 0 && typeof this[this.actionDialogModel.function] === 'function') {
        // track the number of custom tool executions
        const buttonFunctionName = this.actionDialogModel.function;
        const nodeCount = Object.keys(this.nodes ?? {}).length;
        const jobExceedsLoggingDangerLimit = this.toolLoggingDangerLimit != null && nodeCount > this.toolLoggingDangerLimit;

        // increment the count at the path associated with the event type
        const toolEventType = jobExceedsLoggingDangerLimit ? `dangerous_executions` : `executions`;
        FirebaseWorker.ref(`internal_logging/tool_events/${buttonFunctionName}/${toolEventType}`).set(
          firebase.database.ServerValue.increment(1)
        );

        // Set white listed tools that will always run
        const whiteListedTools = ['copy_nodes', 'multi_delete_item'];
        const toolIsAWhiteListed = whiteListedTools.includes(this.activeCommand);

        // Get the tool threshold data
        const toolExecutionOptions = config?.firebaseData?.database_write_options?.tool_execution_options;
        const thresholdToBlockTool = toolExecutionOptions?.blocking_threshold;
        const shouldBlockTool = thresholdToBlockTool != null && nodeCount > thresholdToBlockTool;

        // Run the tool if it's whitelisted or it shouldn't be blocked
        if (toolIsAWhiteListed || !shouldBlockTool) this[buttonFunctionName].call(this, e);
        else {
          // show an alert if the number of nodes exceeds the blocking threshold
          const message = 'This job contains too many nodes to run this tool performantly. Please subdivide this job to run bulk tools.';
          const dialogConfig = {
            body: message,
            dialog: { title: 'Node Limit Exceeded', color: 'var(--paper-red-500)' }
          };
          KatapultDialog.alert(dialogConfig);
        }
      } else {
        this.toast('Button improperly configured to run function');
        this.activeCommand = null;
      }
    } else if (this.actionDialogModel.type == 'attribute') {
      if (this.actionDialogModel.select == 'all') {
        this.setAttributesFromButton(Object.keys(this.nodes || {}));
      } else {
        this.$.katapultMap.openActionDialog({ title: 'Set ' + this.actionDialogModel.label });
      }
    } else if (this.actionDialogModel.action == 'run_qc_modules') {
      let modules = this.actionDialogModel.qc_modules ?? [];
      let canceled = false;
      if (this.actionDialogModel.qc_modules_selection_dialog) {
        const dialog = KatapultDialog.open({
          dialog: {
            title: this.actionDialogModel.label,
            draggable: true,
            maxWidth: 600
          },
          template: () => litHtml`
            <div style="padding:0 5px; gap:5px; display:flex; flex-direction:column;">
              ${map(modules, (item) => litHtml`<paper-checkbox id="${item.key}" class="qcModules">${CamelCase(item.key)}</paper-checkbox>`)}
            </div>
            <div slot="buttons">
              <katapult-button id="cancel">Cancel</katapult-button>
              <katapult-button id="confirm" color="var(--secondary-color)" dialog-confirm>Run QC</katapult-button>
            </div>
          `
        });
        await dialog.openStart;
        const checkboxes = Array.from(dialog.querySelectorAll('paper-checkbox.qcModules'));
        checkboxes.forEach((checkbox, i) => {
          checkbox.addEventListener('click', async () => {
            if (checkbox.checked) {
              checkboxes.forEach((x, j) => {
                if (j < i) x.checked = true;
              });
            }
          });
        });
        // Get the confirm button.
        dialog.querySelector('#confirm')?.addEventListener('click', async () => {
          modules = checkboxes.filter((x) => x.checked).map((x) => ({ key: x.id }));
          dialog.close();
        });
        dialog.querySelector('#cancel')?.addEventListener('click', async () => {
          canceled = true;
          dialog.close();
        });
        await dialog.closeStart;
      }
      if (!canceled) {
        this.runQCModules(modules);
      }
    } else if (this.actionDialogModel.endpoint_url || this.actionDialogModel.endpoint_action) {
      this.activeCommand = null;
      this.runWebTool(this.actionDialogModel);
    }
  }

  async runWebTool(webToolModel) {
    const { endpoint_url: endpointUrl } = webToolModel ?? {};

    const tokens = getTokensFromUrl(endpointUrl);

    // Select an item if used in the url.
    const selectionTypeToken = tokens.find((token) => ['node_id', 'connection_id', 'section_id'].includes(token));
    const selectionType = selectionTypeToken?.split('_')[0];
    let selectedItemId;
    if (selectionType) {
      const selectionResult = (await this.promptToSelect(selectionType)) ?? {};
      selectedItemId = selectionResult?.id;
      if (!selectedItemId) return;
    }

    const resolveToken = async (token) => {
      switch (token) {
        case 'job_id':
          return this.job_id;
        case 'node_id':
        case 'connection_id':
        case 'section_id': {
          return selectedItemId;
        }
      }
    };

    runWebTool(webToolModel, resolveToken);
  }

  //functionName is needed if you're calling a function in the webWorker other than the activeCommand
  //buttonName is needed if the name of the webWorker js file is different than the activeCommand
  //required_data is needed if is any data specific to that webWorker that needs to be passed in
  createWebWorker(functionName, buttonName, required_data) {
    if (functionName == null || typeof functionName === 'object') functionName = this.activeCommand;
    if (buttonName == null || typeof buttonName === 'object') buttonName = this.activeCommand;

    // Hacky way to delay running the button again within a time frame
    this.webWorkerRunTimes ??= {};
    const delay = 2500;
    const now = new Date().valueOf();
    const lastRunTime = this.webWorkerRunTimes[buttonName] || 0;
    // If enough delay hasn't passed, return
    if (lastRunTime + delay > now) {
      return;
    }
    this.webWorkerRunTimes[buttonName] = new Date().valueOf();

    let args = [this.config, this.job_id];
    this.webWorker = new Worker(`/source/_resources/elements/katapult-maps-desktop/button_functions/${buttonName}.js`, { type: 'module' });
    this.webWorker.postMessage({
      type: 'set-database-write-options',
      databaseWriteOptions: globalThis.config.firebaseData?.database_write_options
    });
    this.webWorker.addEventListener('message', (e) => {
      if (e.data.type == 'large-update-blocked') {
        ShowLargeUpdateBlockedDialog();
        return;
      }
      if (e.data.call) {
        if (this[e.data.call]) {
          this[e.data.call].apply(this, e.data.args);
        }
      }
    });
    if (required_data) {
      for (var i = 0; i < required_data.length; i++) {
        args.push(required_data[i]);
      }
    }
    this.webWorker.postMessage({
      call: functionName,
      args: args
    });
  }

  // Clears whatever is selected on the map
  clearMapSelection() {
    this.editingNode = null;
    this.selectedNode = null;
    this.activeConnection = null;
    this.activeSection = null;
    this.editing = null;
    this.selectedDeliverablePhoto = null;
    this.selectedDeliverableNode = null;
    this.selectedDeliverableSection = null;
  }

  cancelPromptAction(e) {
    this._promptToSelectCallback?.();
    if (
      (this.activeCommand === '_drawPolygon' || (this.confirmDialogOpened && this.confirmDialogBodyType === 'drawPolygon')) &&
      (e == null || !['cancel-draw-polygon', 'create-draw-polygon'].includes(e.type)) &&
      !['KATAPULT-BUTTON'].includes(e.currentTarget.tagName)
    )
      return;
    if (this.drawingPolygon && (e == null || e.type !== 'create-draw-polygon')) {
      this.drawingPolygon.setMap(null);
      this.drawingPolygon = null;
    }
    if (this.activeCommand == '_rotateIcon') {
      if (e?.type == 'rotate-icon-confirm') this.saveIconRotation(this.snappingAngle);
      else {
        // TODO (06-09-2023): This doesn't work if the user clicks on a node while rotating another.
        // Reset to original rotation.
        const initialRotation = this.nodes?.[this.selectedNode]?.style_adjustments?.rotation ?? 0;
        this.rotateIcon(initialRotation);
      }
    }
    // Make editing node the selected node.
    if (this.activeCommand == '$moveAnchor') {
      this.selectedNode = this.editingNode;
    }

    if (
      (this.activeCommand === '_drawPolygon' || (this.confirmDialogOpened && this.confirmDialogBodyType === 'drawPolygon')) &&
      (e == null || !['cancel-draw-polygon', 'create-draw-polygon'].includes(e.type)) &&
      !['KATAPULT-BUTTON'].includes(e.currentTarget.tagName)
    )
      return;
    if (this.drawingPolygon && (e == null || e.type !== 'create-draw-polygon')) {
      this.drawingPolygon.setMap(null);
      this.drawingPolygon = null;
    }

    if (e?.detail?.keyboardEvent?.key == 'Escape' && this.activeCommand == null && this.activeCommand != '_rotateIcon') {
      this.editing = null;
      this.selectedNode = null;
      this.editingNode = null;
      this.$.infoPanel.close();
    }

    if (this.confirmDialogCancelCallback != null) {
      this.confirmDialogCancelCallback();
      this.confirmDialogCancelCallback = null;
    }

    this.splittingTraceKey = null;
    if (['_addMapPrintItem', '_addMapLengthDimension', '_addMapAngleDimension'].includes(this.activeCommand)) {
      this.$.printModeToolbar.cancelInsertMapPrintItem();
    }
    this.activeCommand = null;
    this.activeCommandData = null;
    this.actionDialogModel = null;
    this.copyingToJobName = null;
    this.otherCopyJobNodeKeys = null;
    this.setPowerSpecData = null;
    if (this.$.njunsTicketCreator) this.$.njunsTicketCreator.show = false;
    if (this.cancelAfterDrag) {
      this.canDragOverride = false;
      this.cancelAfterDrag = false;
    }

    // reset google map shortcuts, essentially allowing them to fire again
    // we disable them when duplicating a job, so the map doesn't zoom in and out
    this.$.katapultMap.$.googlemap.noKeyboardShortcuts = false;

    this.closeFullscreenPhoto();

    // properly reset all multiAddAttributes values
    if (this.multiAddAttributes) {
      this.multiAddAttributes = this.multiAddAttributes.map((x) => {
        let attributeName = typeof x === 'string' ? x : x.name;
        let value = GetNewAttributeValue(attributeName, this.otherAttributes);
        return { name: attributeName, value };
      });
    }

    this.coordinateCapture = null;
    this.$.katapultMap.cancel();
    if (this.userGroup && this.user && this.user.uid) {
      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/mapTookLinkingAction').set(true);
    }
    if (this.linkMapPhotoActions != null && this.linkMapPhotoActions[0] != null) {
      if (this.linkMapPhotoActions[0].property === 'down_guy' || this.linkMapPhotoActions[0].property === 'guying') {
        FirebaseWorker.ref(
          'photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/hardware_details/canceled'
        ).set('map', function (error) {
          if (error) {
            console.log('error', error);
          }
        });
      }
    }
    this.linkMapPhotoActions = null;

    if (this.connectionTracing) this.connectionTracing = false;
  }

  attributeButtonClick(e) {
    if (e.detail.gui_element == 'coordinate_capture') {
      this.activeCommand = '_coorinateCapture';
      this.coordinateCapture = e.detail;
      this.editing = null;
      var prompt = 'Please select the location of the "' + e.detail.property;
      if (e.detail.list != null) {
        prompt += ' ' + e.detail.list[0];
        this.set('coordinateCapture.listIndex', 0);
      }
      prompt += '"';
      this.$.katapultMap.openActionDialog({ title: prompt });
    }
  }

  updateAlternateDesignData(type) {
    type = type.detail;
    if (type == 'nodes') type = 'node';
    if (type == 'sections') type = 'section';
    let path =
      (type == 'node' ? 'nodes/' + this.selectedNode : `connections/${this.activeConnection}/sections/${this.activeSection}`) + '/photos';
    this.alternateDesignDeliverablePhotoHelper(type, path);
  }

  selectDeliverablePhoto(type, key) {
    if (type.detail) {
      type = type.detail;
      key = null;
    }
    // Dont use for other jobs until we can load traces properly
    if (this.editingItemJob != this.job_id) {
      this.selectedDeliverablePhoto = this.selectedDeliverableNode = this.selectedDeliverableSection = null;
      return;
    }
    let path =
      (type == 'node' ? 'nodes/' + (key || this.selectedNode) : `connections/${this.activeConnection}/sections/${this.activeSection}`) +
      '/photos';
    const resetPage = true;
    this.alternateDesignDeliverablePhotoHelper(type, path, resetPage);
  }

  alternateDesignDeliverablePhotoHelper(type, path, resetPage = false) {
    GetJobData(this.editingItemJob, path).then((data) => {
      let photos = data[path];
      let mainPhoto = null;
      let mostRecent = 0;
      for (let photoId in photos) {
        if (photos[photoId] == 'main') {
          mainPhoto = photoId;
        } else if (photos[photoId].association == 'main') {
          if (photos[photoId].date_taken) {
            if (photos[photoId].date_taken > mostRecent) {
              mostRecent = photos[photoId].date_taken;
            }
          }
          mainPhoto = photoId;
        }
      }
      if (mainPhoto) {
        if (type == 'node') {
          this.selectedDeliverableNode = this.selectedNode;
          this.selectedDeliverableSection = null;
          var node = Object.assign({}, this.get(`nodes.${this.selectedDeliverableNode}`));
          node.nodeId = this.selectedDeliverableNode;
          this.selectedDeliverablePhotoItem = node;
          this.selectedDeliverablePhotoAssociation = 'node';
        } else if (type == 'section') {
          this.selectedDeliverableNode = null;
          this.selectedDeliverableSection = { connId: this.activeConnection, sectionId: this.activeSection };
          var section = Object.assign({}, this.connections?.[`${this.activeConnection}`]?.sections?.[`${this.activeSection}`]);
          section.connId = this.selectedDeliverableSection.connId;
          section.conn = this.connections?.[`${this.activeConnection}`];
          section.sectionId = this.selectedDeliverableSection.sectionId;
          this.selectedDeliverablePhotoItem = section;
          this.selectedDeliverablePhotoAssociation = 'connection';
        }
        this.selectedDeliverablePhoto = mainPhoto;
        if (type == 'section') {
          //todo need this for effective moves for other jobs
          if (this.connections && this.connections[this.activeConnection]) {
            var n1Id = this.connections[this.activeConnection].node_id_1;
            var n2Id = this.connections[this.activeConnection].node_id_2;
            var lr1 = CalcLeftRight(n1Id, this.nodes, this.connections);
            var lr2 = CalcLeftRight(n2Id, this.nodes, this.connections);
            if (lr1.connection_left == lr2.connection_right || lr2.connection_left == lr1.connection_right) {
              if (lr1.connection_right == this.activeConnection) {
                [n1Id, n2Id] = [n2Id, n1Id];
              }
            }
            var n1 = this.nodes[n1Id];
            this.leftNode = n1;
            var n2 = this.nodes[n2Id];
            this.rightNode = n2;
            this.photoIdLeft = this.getMainPhotoFromNodeId(n1Id);
            this.photoIdRight = this.getMainPhotoFromNodeId(n2Id);
            this.distanceRatios = CalcPhotoDistanceRatios(this.activeConnection, this.activeSection, this.nodes, this.connections);
          }
        }
      } else if (resetPage == true) {
        this.selectedDeliverablePhoto = null;
        this.selectedDeliverableNode = null;
        this.selectedDeliverableSection = null;
        this.selectedDeliverablePhotoItem = null;
        this.selectedDeliverablePhotoAssociation = null;
      }
    });
  }

  deliverablePhotoTap() {
    // clear open popups
    if (this.selectedMarkerPopups) {
      while (this.selectedMarkerPopups.length > 0) this.selectedMarkerPopups.pop().close();
    }
  }

  downloadWarnings(e) {
    const warnings = this.warningsDialog_GetResultsSections(this.warningsDialogData);
    let filteredNodes = {};
    let filteredConnections = {};
    const hiddenWarnings = Object.keys(this.jobWarningReportsData?.general_qc?.hidden_warnings || {});
    warnings.forEach((warningSection) => {
      warningSection.itemList.forEach((warning, warningIndex) => {
        const remainingWarnings = Object.values(warning?.warnings || {}).filter(
          (uniqueWarning) => !hiddenWarnings.includes(uniqueWarning?.key)
        );
        // If the Show Hidden Warnings toggle isn't true, then remove the hidden warnings from the download
        if (!this.showAllDialogWarnings) warning.warnings = remainingWarnings;
        // If there are no warnings left, remove this warning number from the download
        if (warning.warnings.length === 0) delete warningSection.itemList[warningIndex];
        else {
          if (warningSection.title.includes('Node')) filteredNodes[warning.key] = this.nodes[warning.key];
          else if (warningSection.title.includes('Connection')) filteredConnections[warning.key] = this.connections[warning.key];
          else if (warningSection.title.includes('Section')) filteredConnections[warning.connId] = this.connections[warning.connId];
        }
      });
    });
    this.$.warningsDialog.close();
    this.downloadJob({
      currentTarget: e.currentTarget,
      detail: {
        warnings,
        nodes: filteredNodes,
        connections: filteredConnections
      }
    });
  }

  async updateExistingSectionInfoWindows() {
    if (this.sectionInfoWindows?.length) await this.updateSectionInfoWindows();
  }

  async updateSectionInfoWindows(photoViewer = this.$.deliverablePhotoViewer) {
    // Get the node id from the photo viewer.
    const nodeId = photoViewer.item?.nodeId;
    if (!nodeId) {
      // Close any existing info windows.
      this.sectionInfoWindows?.forEach((x) => x.close());
      return;
    }
    // Get references to required job data.
    const { photos } = await GetJobData(this.job_id, 'photos');
    const nodes = this.nodes;
    const connections = this.connections;
    const traces = this.traces;
    const traceItems = this.traceItems;
    // Get the node type.
    const nodeType = Path.get(nodes[nodeId].attributes, `node_type.*`);
    // Get selected markers.
    const annotations = photoViewer.markers.filter((x) => x.itemKey == photoViewer.selectedAnnotation.itemKey || x.multiSelected);
    // Get the currently selected marker data.
    const markers = annotations.map((x) => photoViewer.photoData?.photofirst_data?.[x.property]?.[x.itemKey]);
    // Get a list of the traces on the markers and their descendants.
    const traceIds = [];
    for (const marker of markers) {
      if (marker._trace) traceIds.push(marker._trace);
      TraverseMarkers(marker._children, (child) => {
        if (child._trace) traceIds.push(child._trace);
      });
    }
    // Initialize an object to hold section labels.
    const sectionLabels = {};
    // Loop over the traces.
    for (const traceId of traceIds) {
      const trace = traces[traceId];
      const cableType = trace?.cable_type?.toLowerCase();
      // Loop over the ids of photos that exist on this trace.
      for (const photoId in traceItems[traceId]) {
        const photo = photos[photoId];
        // Find all sections to which this photo is associated.
        const sectionLocationIds = Object.entries(photo?.associated_locations ?? {})
          .filter(([key, association]) => association == 'section')
          .map(([key]) => key);
        // Loop over the section locations ids.
        for (const locationId of sectionLocationIds) {
          // Get the connection and section ids from the location id.
          const [connectionId, sectionId] = locationId.split(':');
          // Get the connection.
          const connection = connections[connectionId];
          // Skip any sections that do not belong to connections adjacent to this node.
          if (!connection || (connection.node_id_1 != nodeId && connection.node_id_2 != nodeId)) continue;
          // Get the other node's type.
          const otherNodeId = connection.node_id_1 == nodeId ? connection.node_id_2 : connection.node_id_1;
          const otherNodeType = Path.get(nodes[otherNodeId].attributes, `node_type.*`);
          // Find the marker on this photo that belongs to this trace.
          for (const prop in traceItems[traceId][photoId]) {
            for (const key in traceItems[traceId][photoId][prop]) {
              const marker = photo.photofirst_data?.[prop]?.[key];
              if (!marker) continue;
              // Build up the wire spec for this marker.
              const labelParts = [];
              // Add height to the label.
              const markerHeight = marker._measured_height ?? marker._manual_height;
              const finalHeight = this.showClearances
                ? ApplyEffectiveMoves(marker, markerHeight, { connectionId, sectionId, nodes, connections }).finalHeight
                : markerHeight;
              labelParts.push(FormatHeight(finalHeight, this.useMetricUnits ? 'meters' : 'feet-inches', 2));
              // Add specs to the label.
              const wireSpec = marker.wire_spec != '' && marker.wire_spec != null ? marker.wire_spec : null;
              if (wireSpec) labelParts.push(wireSpec);
              else if (marker.diameter != '' && marker.diameter != null)
                labelParts.push(`${FormatHeight(marker.diameter, this.useMetricUnits == true ? 'centimeters' : '', 2)} dia`);
              if (marker.power_spec != '' && marker.power_spec != null) labelParts.push(marker.power_spec);
              // Add tension to the label.
              if (this.showKpla && cableType != 'guy' && ![nodeType, otherNodeType].some((x) => x == 'crossover')) {
                let tension = parseFloat(marker.custom_tension) || null;
                if (!tension && wireSpec) {
                  const wireSpecData = await FirebaseWorker.ref(
                    `photoheight/company_space/${this.jobCreator}/models/pole_loading/specs/wire_specs`
                  )
                    .orderByChild('wire_spec')
                    .equalTo(wireSpec)
                    .once('value')
                    .then((s) => Object.values(s.val() ?? {})[0]);
                  const { rulingSpan } = CalcRulingSpan(connectionId, traceId, {
                    nodes,
                    connections,
                    photos,
                    traces: traceItems,
                    modelDefaults: this.modelDefaults,
                    proposed: this.showClearances
                  });
                  const KplaEngine = await getKPLAEngine({ jobId: this.job_id });
                  let loadCase;
                  if (
                    photoViewer.activeLoadCaseResultIndex != null &&
                    photoViewer.allLoadcaseResults[photoViewer.activeLoadCaseResultIndex]
                  ) {
                    loadCase = photoViewer.allLoadcaseResults[photoViewer.activeLoadCaseResultIndex].loadCase;
                  } else {
                    loadCase = KplaEngine.GetLoadCase(nodes[nodeId]);
                  }
                  if (loadCase) {
                    const loadCases = await FirebaseWorker.ref(
                      `photoheight/company_space/${this.jobCreator}/models/pole_loading/load_cases`
                    )
                      .orderByChild('load_case')
                      .equalTo(loadCase)
                      .once('value')
                      .then((s) => s.val());
                    const loadingDistrict = KplaEngine.GetLoadingDistrict(loadCase, loadCases);
                    const { tension: lookupTension } = KplaEngine.GetTensionFromLookup(
                      rulingSpan,
                      wireSpecData?.tension_tables,
                      marker.wire_tension?.toLowerCase(),
                      loadingDistrict
                    );
                    tension = lookupTension;
                  }
                }
                if (tension) labelParts.push(`${Number(tension).toFixed()} lbf`);
              }
              // Join the label parts.
              const label = [labelParts[0], labelParts.slice(1).join(' - ')].join(' ');
              // Initialize labels for this section and add the label.
              sectionLabels[locationId] ??= [];
              sectionLabels[locationId].push(label);
            }
          }
        }
      }
    }
    // Close any existing info windows.
    this.sectionInfoWindows?.forEach((x) => x.close());
    // Convert the arrays of labels for each section into an array of infoWindow instances.
    this.sectionInfoWindows = Object.entries(sectionLabels).map(([locationId, labels]) => {
      // Get the connection and section ids from the location id.
      const [connectionId, sectionId] = locationId.split(':');
      // Get the section.
      const section = connections[connectionId]?.sections?.[sectionId];
      // Get the coords of the section.
      const position = new google.maps.LatLng(section?.latitude, section.longitude);
      // Build the html for the info window.
      const bodyContent = document.createElement('div');
      const headerText = labels.length == 1 ? 'Wire Spec' : 'Wire Specs';
      render(litHtml`<div style="text-align:center;"><b>${join(labels, litHtml`<br />`)}</b></div>`, bodyContent);
      // Create a new info window at the section coords containing the labels.
      const infoWindow = new google.maps.InfoWindow({ content: bodyContent, position, disableAutoPan: true });
      infoWindow.setHeaderContent(headerText);
      // Open the newly created info window.
      infoWindow.open({ map: this.map, shouldFocus: false });
      // Return the info window.
      return infoWindow;
    });
  }

  deliverableMarkerSelected(e, d) {
    this.updateSectionInfoWindows(e.currentTarget);
    // add data about the selection to the 3d view data
    this.updateThreeDViewSelectedTraceData(e);
  }

  updateThreeDViewSelectedTraceData(e) {
    // get the trace of the marker that was selected
    const photoData = e.currentTarget.photoData?.photofirst_data;
    const pathToItem = `${e.detail.path}/${e.detail.itemKey}`;
    const markerData = Path.get(photoData, pathToItem, '/');
    const traceId = markerData?._trace;

    // build the selected trace data
    const selectedTraceData = { traceId };

    // get the connection id for the trace based on if the current item is a section or node
    const currentItem = e.currentTarget.item;
    const isSection = currentItem.sectionId != null;
    // add the connection id or the node id to the trace data depending on the item type
    if (isSection) {
      const connectionId = currentItem.connId;
      if (!connectionId) return;
      selectedTraceData.connectionId = connectionId;
    } else {
      const nodeId = currentItem.$key;
      if (!nodeId) return;
      selectedTraceData.nodeId = nodeId;
    }

    // add the selected id to the 3d view data
    if (!traceId || !this.userGroup || !this.user?.uid) return;
    const threeDViewUpdate = { selectedTraceData, setBy: 'maps' };
    FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/3d_view_data').update(
      threeDViewUpdate
    );
  }

  /** @typedef { 'node' | 'connection' | 'section' } MapItemType */
  /**
   * Prompts the user to select an item (node, connection, section).
   * @param { MapItemType | MapItemType[] } [allowedType] - Which item types to allow the user to select (default to all types if unspecified).
   * @returns { Promise<?{ type: string, id: string }> } - A promise which resolves to the selected item's type and id once the user has chosen or null if the selection is canceled.
   */
  async promptToSelect(allowedTypes, actionDialogMessage) {
    if (typeof allowedTypes === 'string') allowedTypes = [allowedTypes];
    if (allowedTypes != null && !Array.isArray(allowedTypes)) throw new TypeError('allowedTypes must be a string array');

    // Determine if the allowed types has been specified.
    const allowedTypesSpecified = allowedTypes != null && allowedTypes.length > 0;

    if (!actionDialogMessage) {
      // Build a description of which types may be selected.
      const allowedTypesDescription = allowedTypesSpecified
        ? `a ${[allowedTypes.slice(0, -1).join(', '), allowedTypes.at(-1)].filter((x) => !!x).join(' or ')}`
        : 'an item from the map';
      // Set default action dialog message.
      actionDialogMessage = `Please select ${allowedTypesDescription}`;
    }

    // Clear any currently selcted item.
    this.clearMapSelection();

    // Build a promise to resolve once a valid item has been selected.
    const promise = new Promise((resolve) => {
      // Setup a callback which may be called from the various type click handlers.
      /** @type {(x: [ MapItemType, string ]) => void} */
      this._promptToSelectCallback = (selectionResult) => {
        if (selectionResult == null) {
          resolve();
          return;
        }
        const [type, id] = selectionResult;
        const typeIsAllowed = !allowedTypesSpecified || allowedTypes?.includes(type);
        if (typeIsAllowed) {
          resolve({ type, id });
        } else {
          this.clearMapSelection();
        }
      };
    });

    // When the promise is settled, clear the callback to end "prompt to select" mode.
    promise.finally(() => {
      this._promptToSelectCallback = null;
      this.$.katapultMap.closeActionDialog();
    });

    // Open the action dialog.
    this.actionDialogModel = { icon: 'touch-app' };
    this.$.katapultMap.openActionDialog({ title: actionDialogMessage });

    return promise;
  }

  promptToSelectConnection(e) {
    const { callback, text } = e.detail;
    this.promptToSelect('connection', text).then((res) => {
      this.editing = null;
      callback(res?.id);
    });
  }

  getFirebaseUrl(appName, job_id) {
    var url = `https://console.firebase.google.com/u/1/project/${this.config.firebaseConfigs[appName].projectId}/database/data/`;
    for (var i = 1; i < arguments.length; i++) {
      url += arguments[i];
    }
    return url;
  }

  tracePole(e) {
    this.$.katapultMap.$.loadRenderMap.toggleHighlightTraceOverlay(null, this.connectionHighlight);
    this.connectionHighlight = null;
    var active_trace = {
      job: this.job_id,
      node_id_1: this.editingNode
    };
    // Update the user data
    FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid).update(
      {
        viewer_active_trace: active_trace,
        active_trace
      },
      function (error) {
        if (error) console.log('error', error);
      }
    );
    this.openPhotoFirst({ currentTarget: { id: 'null' } }, { connectionId: null });
  }

  openTransferHeights(e) {
    var active_trace = {
      job: this.job_id,
      node_id_1: this.editingNode
    };

    // Update the user data
    FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid).update(
      {
        viewer_active_trace: active_trace,
        active_trace
      },
      function (error) {
        //if (error) console.log('error', error);
      }
    );
    //TODO retry this error
    //console.error(active_trace, e.detail.transferFrom, e.detail.transferTo, e.detail.item);

    this.openPhotoFirst({
      currentTarget: {
        id: e.detail.transferFrom,
        action_type: 'transferHeights',
        transferHeightData: {
          transferFrom: e.detail.transferFrom,
          transferTo: e.detail.transferTo,
          item: e.detail.item
        }
      }
    });
  }

  minimizeNonSectionMarkers(connId, sectionId) {
    if (this.selectedDeliverablePhoto && this.selectedDeliverableNode) {
      let deliverablePhoto = this.shadowRoot.querySelector('#deliverablePhotoViewer');
      if (deliverablePhoto) {
        let photoId = this.getMainPhotoKey(SquashNulls(this.connections, connId, 'sections', sectionId));
        if (photoId) {
          let path = 'photos/' + photoId + '/photofirst_data/wire';
          GetJobData(this.job_id, path).then((data) => {
            let wires = data[path];
            let traces = {};
            for (var itemKey in wires) {
              if (wires[itemKey]._trace) {
                traces[wires[itemKey]._trace] = true;
              }
            }
            deliverablePhoto.markers.forEach((marker, i) => {
              let foundTrace = false;
              if (traces[marker.marker._trace]) {
                foundTrace = true;
              } else {
                RunOnChildren(marker.marker, (child, path, childProperty, childItemKey) => {
                  if (traces[child._trace]) foundTrace = true;
                });
              }
              deliverablePhoto.set('markers.' + i + '.minimized', !foundTrace);
            });
            deliverablePhoto.$.photo.orientateImage();
          });
        }
      }
    }
  }

  // TODO: Removed this on 1/17/2023 under the impression that it is no longer used.
  //       Delete this for real if nobody has re-added this by 7/17/2023.
  // async editJobForm() {
  //   await import('../job-form-editor/job-form-editor.js');
  //   this.$.jobFormEditor.open();
  //   FirebaseWorker.ref('photoheight/company_space/' + this.jobCreator + '/attributes_public').set(true, function(error){
  //     if (error) {
  //       this.toast(error)
  //     }
  //   }.bind(this));
  // }

  async editMapStyles() {
    await import('../map-styles-editor/map-styles-editor.js');
    this.$.mapStylesEditor.open();
  }

  findAttachedAnchors(nodeId) {
    var anchorsAttached = [];
    for (var connId in this.connections) {
      const connectionTypeAttribute = this.modelDefaults.connection_type_attribute;
      const downGuyConnectionTypes = this.modelDefaults.downguy_connection_types;
      const proposedDownGuyConnectionTypes = this.modelDefaults.proposed_downguy_connection_types;
      const allowedConnectionTypes = [...downGuyConnectionTypes, ...proposedDownGuyConnectionTypes, 'pushbrace'];
      if (allowedConnectionTypes.includes(PickAnAttribute(this.connections[connId].attributes, connectionTypeAttribute))) {
        var node1 = this.connections[connId].node_id_1;
        var node2 = this.connections[connId].node_id_2;
        if (nodeId == node1) {
          anchorsAttached.push({ nodeId: node2, connId });
        } else if (nodeId == node2) {
          anchorsAttached.push({ nodeId: node1, connId });
        }
      }
    }
    return anchorsAttached;
  }

  getAttributeLabel(property, value, divider) {
    if (property == 'time_bucket') return this.formatTime(value.start) + '-' + this.formatTime(value.stop);
    else if (property == 'pole_tag') return (value.company || '') + ' - ' + value.tagtext;
    else if (property == 'sizes_of_attached_dn_guys') {
      let label = '',
        sizes = value.replace(/\s+/g, '').split(','),
        t = sizes.length || 0;
      // for (var i = 0; i < t; i++) {
      //   label += sizes[i] + (divider || ',');
      // }
      label += sizes.join(divider || ', ');
      return label;
    } else return value;
  }

  formatTime(time) {
    if (time == null) return '';
    var date = new Date(time);
    var hours = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);
    var seconds = ('0' + date.getSeconds()).slice(-2);
    return hours + ':' + minutes + ':' + seconds;
  }

  async toggleDrawer(e) {
    var name = e.currentTarget.getAttribute('name');
    var drawer = this.$[name] || this.shadowRoot.querySelector('#' + name);
    if (drawer) {
      drawer.toggle();
      var icon = e.currentTarget.querySelector('.toggleIcon');
      if (icon) {
        icon.style.transform = drawer.opened ? 'rotate(180deg)' : '';
      }
      if (drawer.opened && name == 'jobLayersDrawer') {
        await Promise.all([import('../job-chooser/project-folder-chooser.js'), import('../job-chooser/project-folder-panel.js')]);
        drawer.querySelectorAll('.lazyLoadWaiting').forEach((n) => (n.style.display = 'none'));
        drawer.notifyResize();
      }
    }
  }

  propertySearch(search) {
    var searchKey = 'tag_ppl';
    for (var nodeId in this.nodes) {
      if (PickAnAttribute(this.nodes[nodeId].attributes, searchKey) == search) {
        this.latitude = this.nodes[nodeId].latitude;
        this.longitude = this.nodes[nodeId].longitude;
        this.zoom = 18;
        break;
      }
    }
  }

  searchContextLayers(searchText, options) {
    options = options || {};
    this.contextLayers = null;
    this.set('selectedContextLayers', []);
    this.assignedPoleCount = null;
    this.updateNodeCounters();
    this.contextInfo = null;
    this.contextLayersSearch = searchText || null;
    if (this.poleLine) {
      this.poleLine.setMap(null);
      this.poleLine = null;
    }
    for (let geoJsonLayer of this.geoJsonLayers) {
      let num = geoJsonLayer.features.length || 0;
      while (num > 0) this.map.data.remove(geoJsonLayer.features[--num]);
    }
    this.set('geoJsonLayers', []);
    this.geoJsonLayersChange = !this.geoJsonLayersChange;
    if (searchText != null && this.userGroup != null) {
      if (searchText.substring(0, 4) == 'APP_' || searchText.substring(0, 4) == 'REL_') {
        searchText = searchText.slice(4);
      }
      if (config.appName != 'ppl-kws') {
        var key = FirebaseWorker.ref('photoheight/server_requests/context_layers/requests').push({
          search: searchText,
          userGroup: this.userGroup
        }).key;
        FirebaseWorker.ref('photoheight/server_requests/context_layers/responses/' + key).on(
          'value',
          function (snapshot) {
            var contextLayers = snapshot.val();
            if (contextLayers != null) {
              if (contextLayers.status == 'success') {
                this.contextInfo = contextLayers._info || null;
                if (this.contextInfo) {
                  this.getCableSizeFromMetadata();
                }
                delete contextLayers.status;
                delete contextLayers.display_msg;
                delete contextLayers._info;
                var layerArray = [];

                for (var key in contextLayers) {
                  var layer = {
                    name: key,
                    value: contextLayers[key]
                  };
                  layerArray.push(layer);
                }
                this.contextLayers = layerArray;
                // Zoom to the bounds of the context data
                if (!options.noZoom && this.contextLayers != null) {
                  for (var i = 0; i < this.contextLayers.length; i++) {
                    if (this.contextLayers[i].value.bbox != null) {
                      var bbox = JSON.parse(this.contextLayers[i].value.bbox);
                      this.$.katapultMap.map.fitBounds(
                        new google.maps.LatLngBounds({ lat: bbox[0][0], lng: bbox[0][1] }, { lat: bbox[1][0], lng: bbox[1][1] })
                      );
                    }
                  }
                }
              }
              snapshot.ref.remove();
            }
          }.bind(this)
        );
      } else {
        // search for the pole in the poles context layer
        firebase
          .database()
          .ref(`photoheight/white_label/contextLayers/poles`)
          .once('value')
          .then((s) => {
            const poleContextLayer = s.val();
            firebase
              .app()
              .database(`https://${poleContextLayer.database}.firebaseio.com`)
              .ref(`${poleContextLayer.url}/${searchText.trim()}`)
              .once('value')
              .then((s) => {
                let pole = s.val();
                if (pole) {
                  // Find the existing map layer and turn it on
                  const layer = this.mapLayers.find((x) => this.layerIdIsUtilityRefLayerType(x.$key, 'poles', 'ppl'));
                  if (layer) {
                    this.toggleMapLayer(layer, true);
                  } else {
                    // set the ppl poles layer to be turned on
                    const layerId = poleContextLayer.url.replace(/\//g, '--');
                    this.set(`multiJobIds.__ref${layerId}`, {
                      database: poleContextLayer.database,
                      maxRadius: 1000,
                      url: poleContextLayer.url,
                      zIndex: 2
                    });
                  }
                  // zoom the map to the found pole's location
                  if (!options.noZoom) {
                    this.$.katapultMap.zoomToLocation({ latitude: pole.l[0], longitude: pole.l[1], zoom: 17, showMarker: true });
                  }
                }
              });
          });
      }
    }
  }

  async getCableSizeFromMetadata() {
    var diameter = await FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/metadata/attacher_cable_diameter')
      .once('value')
      .then((s) => s.val());
    var weight = await FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/metadata/attacher_cable_weight')
      .once('value')
      .then((s) => s.val());
    var strand_size = await FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/metadata/attacher_strand_size')
      .once('value')
      .then((s) => s.val());
    let cable_info = null;
    if (diameter || weight || strand_size) {
      cable_info = 'Dia: "' + diameter + '" Wt: "' + weight + '" Strand: "' + strand_size + '"';
    } else {
      cable_info = '';
    }
    this.set('contextInfo.cable_data', cable_info);
  }

  chooseContextLayers() {
    this.updateNodeCounters();
    if (this.contextLayers != null) {
      let l = this.contextLayers.length;
      for (let i = 0; i < l; i++) {
        let key = this.contextLayers[i].name;
        let geoJsonLayerIndex = this.geoJsonLayers.findIndex((x) => x.key == key);
        let geoJsonLayer = this.geoJsonLayers[geoJsonLayerIndex];
        if (this.selectedContextLayers.indexOf(key) < 0) {
          if (key == 'poles' && this.poleLine) {
            this.poleLine.setMap(null);
            this.poleLine = null;
          }
          if (geoJsonLayer) {
            let num = geoJsonLayer.features.length || 0;
            while (num > 0) this.map.data.remove(geoJsonLayer.features[--num]);
            this.splice('geoJsonLayers', geoJsonLayerIndex, 1);
            this.geoJsonLayersChange = !this.geoJsonLayersChange;
          }
        } else if (!geoJsonLayer) {
          this.addGeoJsonLayer(this.contextLayers[i]);
        }
      }
    }
  }

  addGeoJsonLayer(layer) {
    this.push('geoJsonLayers', {
      key: layer.name,
      features: this.map.data.addGeoJson(layer.value)
    });
    this.setMapLayerSelectable(layer.name, true);
    this.geoJsonLayersChange = !this.geoJsonLayersChange;
    if (layer.name == 'poles') {
      let poleLine = [],
        poleCount = 0,
        l = layer.value.features.length;
      for (let i = 0; i < l; i++) {
        if (layer.value.features[i].properties.activity_types != 'buffer_pole') {
          let coords = layer.value.features[i].geometry.coordinates;
          ++poleCount;
          poleLine.push(new google.maps.LatLng(coords[1], coords[0]));
        }
      }
      if (this.contextLayersSearch.substring(0, 4) == 'APP_') this.assignedPoleCount = poleCount;
      this.poleLine = new google.maps.Polyline({
        map: this.map,
        path: poleLine,
        strokeColor: '#FF0000',
        strokeOpacity: 0.7,
        strokeWeight: 3
      });
    }
  }

  reloadPhotos(callback) {
    var request = { photos: {}, jobId: this.job_id };
    GetJobData(this.job_id, 'photos').then((data) => {
      for (var photoId in data.photos) {
        if (!data.photos[photoId].skip_url_signing && photoId[0] == '-') {
          request.photos[photoId] = true;
        }
      }
      firebase
        .functions()
        .httpsCallable('signS3JobPhotos')(request)
        .then(async (result) => {
          let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
          callback(photos);
        })
        .catch((err) => {
          this.$.toast.close();
          this.$.toast.show(err.toString());
        });
    });
  }

  reloadJobUrls(e) {
    if (this.jobUrlsBusyLoading || this.currentlyUploading) return;
    var request = { photos: {} };
    var jobId = e.detail.jobId || this.job_id;
    if (jobId != null && jobId != '') request.jobId = jobId;
    var path = null;
    if (e.detail.photoIds) {
      request.photos = e.detail.photoIds;
    } else {
      path = 'photos';
    }
    this.jobUrlsBusyLoading = true;
    this.$.toast.show({ text: 'Loading Fresh Photo Urls.', duration: Infinity });
    this.$.toast.duration = 6000;
    GetJobData(jobId, path).then((data) => {
      if (data) {
        for (let photoKey in data.photos) {
          if (!data.photos[photoKey].skip_url_signing && photoKey[0] == '-') {
            request.photos[photoKey] = true;
          }
        }
      }
      firebase
        .functions()
        .httpsCallable('signS3JobPhotos')(request)
        .then((result) => {
          this.$.toast.close();
          this.$.toast.show(result.data);
          this.jobUrlsBusyLoading = false;
          if (this.$.makeReadyDetails.active) {
            setTimeout(() => {
              this.$.makeReadyDetails.$.toast.open();
            }, 3000);
          }
        })
        .catch((err) => {
          this.$.toast.close();
          this.$.toast.show(err.toString());
          if (this.$.makeReadyDetails.active) {
            setTimeout(() => {
              this.$.makeReadyDetails.$.toast.open();
            }, 3000);
          }
        });
    });
  }

  async runQCCheck(qcModuleName, options, nodesToCheck, connectionsToCheck) {
    await import('./qc-checks.js');
    this.warningsDialogData = null;
    this.$.warningsDialog.open();
    GetJobData(this.job_id, 'photos').then((data) => {
      this.cancelPromptAction();
      this.$.qcChecks
        .runReport(qcModuleName, data.photos, nodesToCheck || this.nodes, connectionsToCheck || this.connections, options)
        .then((report) => {
          if (SquashNulls(this.modelConfig.written_warning_attributes, qcModuleName)) {
            let update = {};
            if (report.nodeWarnings)
              report.nodeWarnings.forEach((node) => {
                node.warnings.forEach((warning) => {
                  update['nodes/' + node.key + '/attributes/warning/' + warning.key] = warning.text;
                  let n = this.nodes[node.key] || {};
                  n.attributes = n.attributes || {};
                  n.attributes.warning = n.attributes.warning || {};
                  n.attributes.warning[warning.key] = warning.text;
                  GeofireTools.updateStyle('nodes', node.key, n, update, this.jobStyles);
                });
              });
            if (report.connectionWarnings)
              report.connectionWarnings.forEach((connection) => {
                connection.warnings.forEach((warning) => {
                  update['connections/' + connection.key + '/attributes/warning/' + warning.key] = warning.text;
                  let conn = this.connections[connection.key] || {};
                  conn.attributes = conn.attributes || {};
                  conn.attributes.warning = conn.attributes.warning || {};
                  conn.attributes.warning[warning.key] = warning.text;
                  GeofireTools.updateStyle('connections', connection.key, conn, update, this.jobStyles);
                });
              });
            if (report.sectionWarnings)
              report.sectionWarnings.forEach((section) => {
                section.warnings.forEach((warning) => {
                  update['connections/' + section.connId + '/sections/' + section.key + '/multi_attributes/warning/' + warning.key] =
                    warning.text;
                  let sect = this.connections[section.connId].sections[section.key] || {};
                  sect.multi_attributes = sect.multi_attributes || {};
                  sect.multi_attributes.warning = sect.multi_attributes.warning || {};
                  sect.multi_attributes.warning[warning.key] = warning.text;
                  GeofireTools.updateStyle('sections', section.connId, sect, update, this.jobStyles, section.key);
                });
              });
            FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
            this.set('nodeLabels.warning', true);
          }
          this.displayWarningsDialog(report);
        });
    });
  }

  _button_rotate_icon(e) {
    this.activeCommand = '_rotateIcon';
    // IMPROVEMENT: Sections can still be selected here, but they shouldn't be clickable
    // Set the prompt for the user
    this.$.katapultMap.openActionDialog({ title: 'Rotate Icon' });
  }

  _button_send_email(e) {
    let emailObject = EmailTemplate.compute(this.activeCommandModel.models.email, {
      metadata: this.metadata,
      nodes: this.nodes,
      jobName: this.jobName
    });
    emailObject.group_tag = 'legacy';
    emailObject.to = emailObject.addresses
      .filter((x) => typeof x == 'string' || x.type == 'to')
      .map((x) => x.email || x)
      .join(', ');
    emailObject.cc = emailObject.addresses
      .filter((x) => x.type == 'cc')
      .map((x) => x.email || x)
      .join(', ');
    this.confirmEmail = emailObject;
    this.confirm(
      '',
      '',
      'Send',
      'Cancel',
      '',
      'email',
      () => {
        let to = [];
        this.confirmEmail.to
          .split(',')
          .map((x) => x.trim())
          .filter((x) => x)
          .forEach((x) => to.push({ email: x, type: 'to' }));
        this.confirmEmail.cc
          .split(',')
          .map((x) => x.trim())
          .filter((x) => x)
          .forEach((x) => to.push({ email: x, type: 'cc' }));
        this.confirmEmail.addresses = to;
        var emailReqKey = FirebaseWorker.ref('/photoheight/server_requests/email/requests/').push(this.confirmEmail).key;
        this.toast('Sending email');
        FirebaseWorker.ref('/photoheight/server_requests/email/responses/' + emailReqKey).on(
          'value',
          function (snapshot) {
            var response = snapshot.val();
            if (response != null) {
              snapshot.ref.remove();
              this.toast(response.success ? 'Email Sent' : 'Error Sending Email');
            }
          }.bind(this)
        );
        this.cancelPromptAction();
      },
      null,
      null,
      { confirmDialogTitle: this.activeCommandModel.label }
    );
  }

  async _button_run_load_analysis(e) {
    await import('./network-load-analysis.js');
    const element = this.shadowRoot.querySelector('network-load-analysis');
    element.promptRunLoadAnalysis();
  }

  autoRunLoadAnalysisChanged(event) {
    this.autoRunLoadAnalysis = event.detail.value;
  }

  debouncedRunLoadAnalysis() {
    if (!this.autoRunLoadAnalysis) return;
    this.runLoadAnalysisDebouncer = Debouncer.debounce(this.runLoadAnalysisDebouncer, timeOut.after(100), () => {
      const element = this.shadowRoot.querySelector('network-load-analysis');
      element.runLoadAnalysis();
    });
  }

  _button_qc_check(e) {
    this.runQCCheck('basic', { reportType: 'basic_qc' });
  }

  async _button_new_qc_ppl_check(e) {
    let models = this.activeCommandModel.models;

    // Set up for testing new modules
    let newModules = await FirebaseWorker.ref(`photoheight/company_space/${this.jobCreator}/models/quality_control`)
      .once('value')
      .then((s) => s.val());
    if (!this.newQCModules) {
      // For now, filter out the following new modules that match existing checks:
      let existingCheckes = models.steps.map((x) => x.title);
      this.newQCModules = Object.keys(newModules ?? {})
        .map((x) => {
          return { key: x, title: CamelCase(x) };
        })
        .filter((x) => !existingCheckes.includes(x.title));
    }

    // confirm(heading, body, affirmativeText, dismissiveText, affirmativeStyle, confirmDialogBodyType, callback, cancelCallback, otherButtons)
    this.confirm(
      'QC PPL',
      '',
      'Run QC',
      'Cancel',
      '',
      'qcSteps',
      () => {
        // Cancel any current action and clear variables, including
        // the list of multiselected sections
        this.cancelPromptAction();
        // Clear whatever is selected on the map
        this.clearMapSelection();
        // Tell the multi select counter to show nodes and connections
        this.multiSelectIncludedTypes = {
          nodes: true,
          sections: false,
          connections: true
        };
        // Set the active command to multiselect
        this.activeCommand = '_multiSelectItems';
        // Set the prompt for the user
        this.$.katapultMap.openActionDialog({
          text: 'Draw a polygon to select nodes and connections to include in the QC check. Right click to delete polygon points.',
          buttons: [
            {
              title: 'Cancel',
              callback: this.cancelPromptAction.bind(this),
              attributes: { outline: '' }
            },
            {
              title: 'QC Selected',
              callback: () => {
                // Create an object of nodes based on multiSelectedNodes
                let nodes = {};
                if (this.$.katapultMap.multiSelectedNodes) {
                  for (let i = 0; i < this.$.katapultMap.multiSelectedNodes.length; i++) {
                    let key = this.$.katapultMap.multiSelectedNodes[i];
                    nodes[key] = this.nodes[key];
                  }
                }
                let connections = {};
                if (this.$.katapultMap.multiSelectedConnections) {
                  for (let connId in this.$.katapultMap.multiSelectedConnections) {
                    connections[connId] = this.connections[connId];
                  }
                }
                // Run the report with a smaller set of nodes and connections
                this.runPPLQC(models, { skipAppWarnings: true }, nodes, connections);
              },
              attributes: { 'secondary-color': '' }
            },
            {
              title: 'QC All',
              callback: () => {
                this.runPPLQC(models);
              },
              attributes: { 'secondary-color': '' }
            }
          ]
        });
      },
      null,
      null,
      { dialogStyle: 'width:350px;' }
    );
  }

  selectQCGroup(e) {
    if (e.currentTarget.checked) {
      for (let i = 0; i < e.model.index; i++) {
        this.set('activeCommandModel.models.steps.' + i + '.checked', true);
      }
    }
  }

  runQCModules(modules) {
    import('./qc-checks.js').then(() => {
      if (modules?.length > 0) {
        this.warningsDialogData = null;
        this.$.warningsDialog.open();
        this.$.qcChecks
          .runReport('', null, null, null, {
            runNewModules: modules.map((x) => x.key)
          })
          .then((results) => {
            this.cancelPromptAction();
            this.displayWarningsDialog(results);
          });
      } else {
        this.toast('This button has no QC modules associated with it.');
      }
    });
  }

  runPPLQC(models, options, nodes, connections) {
    options = options || {};
    if (models.steps) {
      let qcModels = models.steps
        .filter((x) => x.checked)
        .map((step, i) => {
          this.set('activeCommandModel.models.steps.' + i + '.loading', true);
          this.set('activeCommandModel.models.steps.' + i + '.complete', false);
          step.qc.onComplete = () => {
            this.set('activeCommandModel.models.steps.' + i + '.loading', false);
            this.set('activeCommandModel.models.steps.' + i + '.complete', true);
          };
          return step.qc;
        });
      options.model = { otherModules: qcModels };
    }

    FirebaseWorker.ref(`photoheight/company_space/${this.jobCreator}/models/quality_control`)
      .once('value')
      .then((s) => {
        let newModules = s.val();
        let existingCheckes = models.steps.filter((x) => x.checked).map((x) => x.title);
        let newModulesToRun = this.newQCModules.filter((x) => x.checked).map((x) => x.key);
        // Also run new modules that match up with the existing checks
        Object.keys(newModules ?? {}).forEach((newModule) => {
          if (existingCheckes.includes(CamelCase(newModule))) {
            newModulesToRun.unshift(newModule);
          }
        });
        import('./qc-checks.js').then(() => {
          this.$.qcChecks
            .runReport('Test New QC Modules', null, null, null, {
              runNewModules: newModulesToRun
            })
            .then((report) => {
              options.initialReport = report;
              this.runQCCheck('new_ppl_qc', options, nodes, connections);
            });
        });
      });
  }

  _button_slack_span_check(e) {
    this.warningsDialogData = null;
    this.$.qcSlackSpansOptionsDialog.addEventListener(
      'iron-overlay-closed',
      async function (event) {
        if (event.detail.canceled == true) {
          return;
        } else {
          // Open the QC check dialog
          this.$.warningsDialog.open();
          let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
          this.createWebWorker(null, null, [this.qcSlackSpansAssessPower, this.qcSlackSpansAssessComs, photos]);
        }
      }.bind(this),
      { once: true }
    );
    // Open the options dialog
    this.$.qcSlackSpansOptionsDialog.open();
  }

  async buttonQC(e) {
    await import('../quality-control/quality-control.js');
    GetJobData(this.job_id, 'photos').then((data) => {
      if (this.qcPowerCompany) {
        let qc = this.$.qualityControl;
        if (this.$.resultPage.childElementCount > 1) {
          this.$.resultPage.removeChild(this.$.resultPage.children[1]);
          qc.qcMessage.innerHTML = '';
        }
        qc.silentRefresh();
        this.qcSelection = 1;
        qc.qcCheck(this.nodes, this.connections, data.photos, this.traces, this.traceItems, qc.powerCompany, this.jobName);
      } else this.toast('You must select a power company before running QC!');
    });
  }

  calcList(attributes) {
    if (attributes && attributes.company && attributes.company.picklists) {
      return attributes.company.picklists.power_companies;
    } else {
      return [];
    }
  }

  async openJobDialogue() {
    // Get entity data for opening the conversation
    let entityData = {
      company_id: this.userGroup,
      project_id: this.jobCreator,
      job_id: this.job_id,
      entity_type: 'JOB',
      entity_id: this.job_id
    };
    // Open the conversation
    this.$.feedbackChat.openFromEntity(entityData);
  }

  async downloadJob(e) {
    let target = e.currentTarget;
    target.loading = true;
    await import('./exports/export-manager.js');
    const exportOptions = {
      adminReport: false,
      nodeId: e.detail.nodeId,
      jobId: this.job_id,
      jobName: this.jobName,
      dataByJob: {
        [this.job_id]: {
          nodes: e.detail.nodes || this.nodes,
          connections: e.detail.connections || this.connections,
          traces: this.traces,
          traceItems: this.traceItems,
          photos: await GetJobData(this.job_id, 'photos').then((data) => data.photos),
          jobName: this.jobName
        }
      }
    };
    // There will be warnings in the event detail if the Download Manager was opened from a QC dialog
    if (e.detail?.warnings) exportOptions['qcWarnings'] = e.detail.warnings;
    this.$.exportManager.open(exportOptions);
    target.loading = false;
  }

  photoPage() {
    var a = document.createElement('a');
    a.href = window.location.pathname.replace('/map/', '/photos/');
    a.click();
  }

  format(input) {
    //Removes parenthesis and replaces spaces with underscores
    if (input != null) {
      input = input.toLowerCase();
      input = input.split(' ').join('_').split('(').join('').split(')').join('');
    }
    return input;
  }

  getIndex() {
    // Takes an arbitrary number of args to specify a specific sub-property of an object.
    // The last argument is the item to get the index of.
    var object = arguments[0];
    var item = arguments[arguments.length - 1];
    for (var i = 1; i < arguments.length - 1; i++) {
      if (object == null) break;
      object = object[arguments[i]];
    }
    if (object != null && typeof object == 'object') {
      if (!(object instanceof Array)) object = Object.keys(object);
      for (var i = 0; i < object.length; i++) {
        if (item == object[i]) {
          return i;
        }
      }
    }
    return -1;
  }

  formatDate(date) {
    if (date != null && date.split) {
      return date.split(' ')[0];
    }
    return null;
  }

  updateJobLocking() {
    if (this.cachedJobLocked === this.metadata?.job_locked || this.metadata?.job_locked == null) return;
    this.cachedJobLocked = this.metadata?.job_locked;
    this.statusChanged();
  }

  statusChanged() {
    this.changeInStatus = !this.changeInStatus;
    if (!this.enabledFeatures.job_locking) {
      if (this.status != null && this.status.published) {
        this.showLines = true;
        this.viewPublishedChecked = true;
      } else this.viewPublishedChecked = false;
    }
    // Set the view publishedChecked based on the Job Locked metadata property. Defaults to false
    else this.viewPublishedChecked = this.metadata?.job_locked ?? false;
  }

  loadDeliverable() {
    if (this._sharing != 'write' && this.status?.published && this.nodes != null) {
      setTimeout(() => {
        if (this.shadowRoot.querySelector('#feedbackAutogrowTextarea') != null) {
          this.shadowRoot.querySelector('#feedbackAutogrowTextarea').update(this.shadowRoot.querySelector('#feedbackTextarea'));
        }
      }, 100);

      // If no nodes are selected, select one with a main photo
      if (this.selectedNode == null || this.nodes[this.selectedNode] == null) {
        let firstNodeInOrder = {};
        // Loop over each node-key pair
        for (const [$key, data] of Object.entries(this.nodes)) {
          // If the node has a main photo
          const hasMainPhoto = Object.values(data.photos ?? {}).some((x) => x === 'main' || x.association == 'main');
          if (!hasMainPhoto) continue;

          // And if the node has an ordering attribute with a value
          const order = Path.get(data.attributes, `${this.modelDefaults.ordering_attribute}.*`);
          if (!order) continue;

          // Select the node if its order is lower than the last
          if (firstNodeInOrder.order == null || order.localeCompare(firstNodeInOrder.order) < 0) firstNodeInOrder = { id: $key, order };
        }
        this.selectedNode = firstNodeInOrder.id;
      }

      // Use the selected node to select a default photo
      this.selectDeliverablePhoto('node');
      // Show the photo tray if we selected a node, hide it otherwise
      this.hideDeliverablePhoto = !this.selectedNode;

      if (
        this.jobCreator != null &&
        this.jobCreator.indexOf('katapult') != -1 &&
        this.jobName != null &&
        this.jobName.toLowerCase().indexOf('app_') != -1
      ) {
        this.loadingDeliverableLayers = true;
        if (this.zoomText != this.jobName) {
          this.zoomText = this.jobName;
          this.searchContextLayers(this.jobName, { noZoom: true });
        }
        if (this.selectedContextLayers.length == 0) this.selectedContextLayers = ['state_roads'];
      }
    }
    // If we never selected a node, hide the photo tray
    else if (this.selectedNode == null) this.hideDeliverablePhoto = true;
  }

  nodesLoaded() {
    this.bigObjectKeys(this.nodes, 'nodeKeys');
    this.updateNodeCounters();

    if (!this.nodesAreLoaded && this.nodes != null) {
      // When nodes are loaded, prevent zooming to job if only consists of one or less nodes (otherwise zooms in infinitely).
      this.nodesAreLoaded = true;
      if (this.pageLoadTag != null) {
        var foundIt = false;
        for (var nodeId in this.nodes) {
          var pole_tags = SquashNulls(this.nodes[nodeId], 'attributes', 'pole_tag');
          for (var key in pole_tags) {
            if (pole_tags[key].tagtext == this.pageLoadTag) {
              this.selectedNode = nodeId;
              this.editingNode = nodeId;
              this.editing = 'Node';
              this.zoomNode = nodeId;
              foundIt = true;
              break;
            }
          }
          if (foundIt) {
            break;
          } else {
            if (
              SquashNulls(this.nodes[nodeId].attributes, 'tag_owner') == this.pageLoadTag ||
              SquashNulls(this.nodes[nodeId].attributes, 'tag_ppl') == this.pageLoadTag
            ) {
              this.selectedNode = nodeId;
              this.editingNode = nodeId;
              this.editing = 'Node';
              this.zoomNode = nodeId;
              foundIt = true;
              break;
            }
          }
        }
        if (!foundIt) {
          this.toast('Pole not found, showing first pole in job instead.');
        }
        this.pageLoadTag = null;
      }
      if (this.zoomNode != null) {
        if (this.nodes[this.zoomNode] != null) {
          this.latitude = this.nodes[this.zoomNode].latitude;
          this.longitude = this.nodes[this.zoomNode].longitude;
        }
        this.zoomNode = null;
      }
      this.loadDeliverable();
      this.parseUserData();
    }
  }

  s(x) {
    return x == 1 ? '' : 's';
  }

  zoomToActiveJob() {
    if (this.zoomToJob && this.nodesAreLoaded && this.mapInitialized) {
      this.zoomToJob = false;
      var latLngBounds = new google.maps.LatLngBounds();
      var count = 0;
      for (var nodeId in this.nodes) {
        if (this.nodes[nodeId].latitude && this.nodes[nodeId].longitude) {
          count++;
          latLngBounds.extend(new google.maps.LatLng(this.nodes[nodeId].latitude, this.nodes[nodeId].longitude));
        }
      }
      // For one marker, set the zoom a default amount
      if (count == 1) {
        this.map.setCenter(latLngBounds.getCenter());
        this.zoom = 18;
      } else if (count > 1) {
        this.map.fitBounds(latLngBounds);
        this.map.setCenter(latLngBounds.getCenter());
      }
    }
  }

  getFormatForCompanyName(companyName) {
    if (this.otherAttributes && this.otherAttributes.company && this.otherAttributes.company.picklists) {
      var companyPicklists = this.otherAttributes.company.picklists;
      for (var companyType in companyPicklists) {
        for (var companyIndex in companyPicklists[companyType]) {
          var firstKey = Object.keys(companyPicklists[companyType][companyIndex])[0];
          if (
            firstKey &&
            companyPicklists[companyType][companyIndex][firstKey] &&
            companyPicklists[companyType][companyIndex][firstKey].toLowerCase().replace(/\s/g, '') ==
              companyName.toLowerCase().replace(/\s/g, '')
          ) {
            return companyPicklists[companyType][companyIndex][firstKey];
          }
        }
      }
    }
    return companyName;
  }

  updateNodeCounters() {
    this.updateNodeCountersDebouncer = Debouncer.debounce(this.updateNodeCountersDebouncer, timeOut.after(500), () => {
      let nodes = this.job_id ? this.nodes : {}; // Nodes doesn't get cleared when jobId changes, so force it to be an empty object.
      this.mapsWorker.postMessage({
        call: 'updateNodeCounters',
        args: [nodes, this.nodeListType, this.nodeCounterConfig]
      });
    });
  }

  updateNodeCountersResult(result) {
    this.set('showPolesLabel', result.showPolesLabel);
    this.set('poleOwners', result.tempPoleOwners);
    this.set('poleCount', result.poleCount);
    this.set('nodesChanged', !this.nodesChanged);
    this.set('job_node_types', result.nodeTypes);
    this.set('nodeListType', result.nodeListType);
    this.set('nodeBreakdown', result.nodeBreakdown);

    if (this.nodeKeys != null && (this.contextLayers == null || this.selectedContextLayers.indexOf('poles') == -1)) {
      if (result.assignedPoleCount != 0) {
        this.assignedPoleCount = result.assignedPoleCount;
      } else {
        this.assignedPoleCount = null;
      }
    }
  }

  listCountMatches(list, count) {
    if (list instanceof Array) return list.length == count;
    else if (list instanceof Object) return Object.keys(list).length == count;
    return false;
  }

  getCount(poleOwners, company) {
    return poleOwners[company];
  }

  toggleNodeBreakdown() {
    this.showNodeBreakdown = !this.showNodeBreakdown;
  }

  _button_associate_photos_by_name(e) {
    GetJobData(this.job_id, 'photos').then((data) => {
      var photoLookup = {};
      for (var photoId in data.photos) {
        var fileName = data.photos[photoId].filename;
        if (fileName != null) {
          fileName = fileName.toLowerCase();
          photoLookup[fileName] = photoLookup[fileName] || [];
          photoLookup[fileName].push(photoId);
        }
      }
      var update = {};
      var count = 0;
      //Check nodes
      for (var nodeId in this.nodes) {
        var photoNames = SquashNulls(this.nodes[nodeId].attributes, 'photo_name');
        for (var itemKey in photoNames) {
          let photoName = photoNames[itemKey].toLowerCase();
          if (photoName.split('.').length == 1) {
            if (photoLookup[photoName + '.jpg'] != null) {
              photoName += '.jpg';
            } else {
              photoName += '.jpeg';
            }
          }
          let photoIds = photoLookup[photoName];
          if (photoIds != null) {
            for (var i = 0; i < photoIds.length; i++) {
              if (SquashNulls(this.nodes[nodeId].photos, photoIds[i]) == '') {
                update[`nodes/${nodeId}/photos/${photoIds[i]}`] = { association: true };
                count++;
              }
            }
          }
        }
      }

      //Check Conns
      for (var connId in this.connections) {
        var photoNames = SquashNulls(this.connections[connId].attributes, 'photo_name');
        for (var itemKey in photoNames) {
          let photoName = photoNames[itemKey].toLowerCase();
          if (photoName.split('.').length == 1) {
            if (photoLookup[photoName + '.jpg'] != null) {
              photoName += '.jpg';
            } else {
              photoName += '.jpeg';
            }
          }
          let photoIds = photoLookup[photoName];
          if (photoIds != null) {
            for (var i = 0; i < photoIds.length; i++) {
              if (SquashNulls(this.connections[connId].photos, photoIds[i]) == '') {
                update[`connections/${connId}/photos/${photoIds[i]}`] = { association: true };
                count++;
              }
            }
          }
        }
        // Check Sections
        for (var sectionId in this.connections[connId].sections) {
          let sectionPhotoNames = SquashNulls(this.connections[connId].sections[sectionId].multi_attributes, 'photo_name');
          for (var itemKey in sectionPhotoNames) {
            let photoName = sectionPhotoNames[itemKey].toLowerCase();
            if (photoName.split('.').length == 1) {
              if (photoLookup[photoName + '.jpg'] != null) {
                photoName += '.jpg';
              } else {
                photoName += '.jpeg';
              }
            }
            let photoIds = photoLookup[photoName];
            if (photoIds != null) {
              for (var i = 0; i < photoIds.length; i++) {
                if (SquashNulls(this.connections[connId].sections[sectionId].photos, photoIds[i]) == '') {
                  update[`connections/${connId}/sections/${sectionId}/photos/${photoIds[i]}`] = { association: true };
                  count++;
                }
              }
            }
          }
        }
      }
      FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(
        update,
        function (error) {
          if (error) {
            this.$.toast.show(error);
          } else {
            this.$.toast.show(count + ' photo' + (count == 1 ? '' : 's') + ' associated.');
          }
        }.bind(this)
      );
      this.cancelPromptAction();
    });
  }

  _button_calc_utm(e) {
    var update = {};
    var srid;
    for (var nodeId in this.nodes) {
      var utm = KatapultGeometry.LatLongToXY(this.nodes[nodeId].latitude, this.nodes[nodeId].longitude, srid);
      if (srid == null) {
        srid = utm.srid;
      }
      update[nodeId + '/utm_x'] = utm.x;
      update[nodeId + '/utm_y'] = utm.y;
      update[nodeId + '/srid'] = srid;
    }
    this.$.nodes.ref.update(update, async (error) => {
      if (error) {
        this.toast(error);
      } else {
        this.toast('UTM Set on Nodes.');
      }
      this.cancelPromptAction();
      const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
    });
  }
  _button_order_unconnected_nodes(e) {
    let nodeIds = Object.keys(this.nodes);
    let update = {};
    let counter = 0;
    nodeIds
      .filter((x) =>
        this.modelDefaults.pole_node_types.includes(PickAnAttribute(this.nodes[x].attributes, this.modelDefaults.node_type_attribute))
      )
      .forEach((x, i) => {
        update[`${x}/attributes/${this.modelDefaults.ordering_attribute}/button_added/`] = Pad(i + 1, 3);
        counter++;
      });
    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`)
      .update(update)
      .then(() => {
        this.toast(`${this.modelDefaults.ordering_attribute_label} set on ${counter} nodes.`);
      });
  }

  _button_calc_alamon_map_no(e) {
    let caliSrid = 'NAD27 / California zone VII';
    let utmSrid = 'UTM Zone 11';
    proj4.defs(
      caliSrid,
      '+proj=lcc +lat_1=34.41666666666666 +lat_2=33.86666666666667 +lat_0=34.13333333333333 +lon_0=-118.3333333333333 +x_0=1276106.450596901 +y_0=1268253.006858014 +datum=NAD27 +units=us-ft +no_defs'
    );
    proj4.defs(utmSrid, this.modelConfig.map_no.utm_11_proj4);
    let update = {};
    for (let nodeId in this.nodes) {
      // Cali code
      let xy = KatapultGeometry.LatLongToXY(this.nodes[nodeId].latitude, this.nodes[nodeId].longitude, caliSrid);
      let truncatedX = Math.floor(xy.x / 3000) * 3;
      let truncatedY = Math.floor(xy.y / 2000) * 2 - 4000;
      let caliGridNo = truncatedY + '-' + truncatedX;
      // UTM Code
      let utmXy = KatapultGeometry.LatLongToXY(this.nodes[nodeId].latitude, this.nodes[nodeId].longitude, utmSrid);
      let pageNumberX = (utmXy.x / 0.3048 - this.modelConfig.map_no.originX) / this.modelConfig.map_no.estSizeX;
      let pageNumberY = (utmXy.y / 0.3048 - this.modelConfig.map_no.originY) / this.modelConfig.map_no.estSizeY;

      let gridY = Math.ceil(pageNumberY / 2);
      let gridX = Math.ceil(pageNumberX / 2);

      let letterFromY = Math.floor(pageNumberY) % 2;
      let letterFromX = Math.floor(pageNumberX) % 2;

      let gridLetter = '';

      if (letterFromX == 0 && letterFromY == 1) {
        gridLetter = 'A';
      }
      if (letterFromX == 1 && letterFromY == 1) {
        gridLetter = 'B';
      }
      if (letterFromX == 0 && letterFromY == 0) {
        gridLetter = 'C';
      }
      if (letterFromX == 1 && letterFromY == 0) {
        gridLetter = 'D';
      }

      let utmGridNo = gridY + '-' + gridX + gridLetter;
      update[nodeId + '/attributes/map_no/auto'] = caliGridNo + ' or ' + utmGridNo;
    }
    FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/nodes')
      .update(update)
      .then(() => {
        this.cancelPromptAction();
        this.toast('Map Numbers Set.');
      });
  }

  _button_open_uploader(e) {
    window.open(window.location.pathname.replace('/map/', '/upload/'));
    this.cancelPromptAction();
  }

  _button_import_ike_data(e) {
    this.toast('Please select an IKE JSON Export.');
    this.promptForFiles(
      '.json',
      false,
      function (files) {
        if (files != null) {
          var reader = new FileReader();
          reader.onload = (event) => {
            let poles = JSON.parse(event.target.result);
            let update = {};
            poles.some((pole) => {
              let node = {
                attributes: {
                  node_type: { import_data: 'pole' },
                  pole_tag: {}
                },
                photos: {}
              };
              pole.captures.forEach((capture) => {
                let photoId = '-' + capture.id;
                update['photos/' + photoId] = {
                  associated_locations: { [pole.id]: 'node' },
                  url_small: capture.thumbnailUrl,
                  url_large: capture.mediumUrl,
                  url_extra_large: capture.imageUrl,
                  url_full: capture.imageUrl
                };
                node.photos[photoId] = { association: true };
              });
              pole.fields.forEach((field) => {
                if (field.type == 'location') {
                  node.latitude = field.value.latitude;
                  node.longitude = field.value.longitude;
                } else if (field.name == 'Tag Info') {
                  field.value.forEach((tag) => {
                    tag.fields.forEach((tagField) => {
                      if (tagField.value && tagField.type == 'text' && tagField.name.toLowerCase().includes('tag')) {
                        node.attributes.pole_tag[FirebaseWorker.ref().push().key] = {
                          company: '',
                          owner: false,
                          tagtext: tagField.value
                        };
                      }
                    });
                  });
                } else if (field.name == 'Pole Measure Photos') {
                  if (field.value.length > 0) {
                    node.photos['-' + field.value[0]] = { association: 'main' };
                  }
                }
                //pole inspection, birthmark, heights
                else if (typeof field.value === 'string') {
                  node.attributes[
                    field.name
                      .trim()
                      .toLowerCase()
                      .replace(/\s+/g, '_')
                      .replace(/\.\$#\[\]\\/g, '')
                  ] = { import_data: field.value };
                }
              });
              GeofireTools.setGeohash('nodes', node, pole.id, this.jobStyles, update);
              update['nodes/' + pole.id] = node;
              // return true;
            });
            if (this.job_id) {
              FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
            }
          };
          reader.readAsText(files[0]);
        }
      }.bind(this)
    );
    this.cancelPromptAction();
  }

  _button_set_order(e) {
    if (this.metadata?.date_of_virtual_rideout) {
      KatapultDialog.alert({
        body: 'Some exports such as MR Notification or VR Packet may need revision',
        dialog: {
          color: '#FF8300',
          modal: true,
          title: 'Attention'
        }
      });
    }
    if (this.job_id != null) {
      let title = this.actionDialogModel.label;
      let bodyText = 'Enter a starting SCID number, click the first pole in your job, then select the kind of order you want to do';
      let buttons = [
        {
          title: 'Order Sub Group',
          callback: () => {
            if (this.startingNodeIdForOrder) this.selectSubGroupToOrder();
            else this.toast(`Please select a starting node for the order`);
          },
          attributes: { style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); margin: 5px;' }
        },
        {
          title: 'Order All',
          callback: () => this.sendOrderCommand(false),
          attributes: { style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); margin: 5px;' }
        }
      ];

      // if there is a selected starting node, highlight it
      if (this.startingNodeIdForOrder) this.selectedNode = this.startingNodeIdForOrder;
      else this.selectedNode = null;

      const hasPoleAppOrderZero = Object.values(this.nodes ?? {}).some((x) => Path.get(x, `attributes.pole_app_order.*`) == 0);
      const defaultScid = hasPoleAppOrderZero ? '000' : '001';
      this.actionDialogData.startingSCID = this.actionDialogData.startingSCID || defaultScid;
      if (this.actionDialogData.scidGuysDotOne == null && !this.modelConfig.defaultScidToWholeNumbers)
        this.set('actionDialogData.scidGuysDotOne', true);
      if (this.config.appName == 'ppl-kws') {
        this.set('actionDialogData.scidByPoleAppOrder', true);
        bodyText = 'Click any pole, then select an option below to order';
      }

      this.$.katapultMap.openActionDialog({ body: 'setOrder', title, text: bodyText, buttons });
    } else {
      this.toast('Please Select a Job');
    }
  }

  sendOrderCommand(orderByGroup) {
    if (this.startingNodeIdForOrder) {
      this.activeCommand = 'set_order';
      // parameters to be sent to the order function
      let parametersToSend = [
        this.startingNodeIdForOrder,
        this.actionDialogData.startingSCID,
        this.actionDialogData.scidGuysDotOne,
        this.actionDialogData.scidByPoleAppOrder,
        this.modelDefaults,
        this.nodes,
        this.connections
      ];
      let runOrder = true;
      // if order by group is true, we need to check to see if our starting node exists in the list of nodes we've selected
      if (orderByGroup) {
        let nodesToOrder = this.$.katapultMap.multiSelectedNodes || [];
        // Warn the user if the starting node doesn't exist in the selection and prevent the order
        if (!nodesToOrder.includes(this.startingNodeIdForOrder)) {
          this.toast(`The selected node doesn't exist within the group`);
          runOrder = false;
        }
        // if it does exist in the selection, add the selection to the order parameters
        else parametersToSend.push(nodesToOrder);
      }

      // Only run the order if the starting node exists in the selection
      if (runOrder) {
        this.createWebWorker(null, null, parametersToSend);
        this.toast(`Ordering by ${this.modelDefaults.ordering_attribute_label}...`);
        this.startingNodeIdForOrder = null;
      }
    } else this.toast(`Please select a starting node for the order`);
  }

  selectSubGroupToOrder() {
    this.selectedNode = null;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = ['nodes'];

    this.$.katapultMap.openActionDialog({
      text: 'Click Nodes or Draw a Polygon to Select items and add attributes. Right click to delete polygon points.',
      buttons: [
        { title: 'Cancel', callback: () => this.cancelPromptAction(), attributes: { outline: '' } },
        {
          title: 'Finish',
          callback: () => this.sendOrderCommand(true),
          attributes: { style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color);' }
        }
      ]
    });
  }

  _button_set_vantage_point(e) {
    // order nodes above, then loop selecting each node and prompting to click on map. set gps as photo_position
    // can also click edit and set or modify the photo_position, but how does this work with the button model?
    this.vantagePointKeys = this.nodeKeys.slice(0);
    this.vantagePointKeys.sort(
      function (a, b) {
        var aOrder = this.nodes[a].attributes?.[this.modelDefaults.ordering_attribute];
        var bOrder = this.nodes[b].attributes?.[this.modelDefaults.ordering_attribute];
        if (!aOrder) {
          if (!bOrder) {
            return -1;
          } else {
            return 0;
          }
        } else if (!bOrder) {
          return 1;
        }
        var aKeys = Object.keys(aOrder);
        var bKeys = Object.keys(bOrder);
        return parseFloat(aOrder[aKeys[0]]) - parseFloat(bOrder[bKeys[0]]);
      }.bind(this)
    );
    this.activeCommand = '_coorinateCapture';
    if (this.doNextVantagePoint()) {
      this.$.katapultMap.openActionDialog({ title: 'Please select where you think the "vantage_point" is.' });
      this.map.setOptions({ draggableCursor: 'crosshair' });
    }
  }

  doNextVantagePoint() {
    if (this.vantagePointKeys != null && this.vantagePointKeys.length != 0) {
      var nodeId, nodeType, nodeTypeKeys;
      do {
        nodeId = this.vantagePointKeys.shift();
        nodeType = this.nodes[nodeId].attributes?.[this.modelDefaults.node_type_attribute];
        if (nodeType) {
          nodeTypeKeys = Object.keys(nodeType);
        }
      } while (
        nodeType &&
        nodeTypeKeys.length == 1 &&
        !this.modelDefaults.pole_node_types.includes(this.nodes[nodeId].attributes[this.modelDefaults.node_type_attribute][nodeTypeKeys[0]])
      );
      if (nodeId == null) {
        return false;
      }
      this.selectedNode = nodeId;
      this.map.panTo(new google.maps.LatLng(this.nodes[nodeId].latitude, this.nodes[nodeId].longitude));
      this.map.setZoom(20);
      this.coordinateCapture = {
        path: 'photoheight/jobs/' + this.job_id + '/nodes/' + nodeId + '/attributes',
        property: 'vantage_point',
        itemKey: 'auto_button'
      };
      return true;
    } else {
      return false;
    }
  }

  _button_associate_photos(e) {
    // Clear whatever is selected on the map
    this.clearMapSelection();
    // Tell the multi select counter to show nodes and connections
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: true
    };
    // Set the active command to multiselect
    this.activeCommand = '_multiSelectItems';
    // Set the prompt for the user
    this.$.katapultMap.openActionDialog({
      text: 'Draw a polygon to select nodes and connections to include in the association. Right click to delete polygon points.',
      associationCheckbox: true,
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Associate All',
          callback: this.continuePhotoAssociation.bind(this, true),
          attributes: {
            style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); padding: 12px; margin:0 5px'
          }
        },
        {
          title: 'Associate Selected',
          callback: this.continuePhotoAssociation.bind(this),
          attributes: {
            style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); padding: 12px; margin:0 5px'
          }
        }
      ]
    });
  }

  async continuePhotoAssociation(shouldAssociateAll) {
    this.shouldAssociateAll = shouldAssociateAll;
    // Clear multiSelectedNodes
    this.multiSelectedNodes = [];
    // Set multiSelectedNodes to all the nodes, or just the ones selected, depending on shouldAssociateAll
    this.multiSelectedNodes = shouldAssociateAll == true ? Object.keys(this.nodes) : this.$.katapultMap.multiSelectedNodes;
    // Stores the nodes to associate
    this.nodesToAssociate = {};
    // Stores the connections to associate based on the nodes selected
    this.connectionsToAssociate = {};
    // Loop through the connections and find all that should be included based on the selected nodes
    for (let connectionKey in this.connections) {
      // Get the connection data
      let connection = this.connections[connectionKey];
      // Check if the connection has both endpoints in multiSelectedNodes
      if (this.multiSelectedNodes.indexOf(connection.node_id_1) != -1 && this.multiSelectedNodes.indexOf(connection.node_id_2) != -1) {
        this.connectionsToAssociate[connectionKey] = connection;
      }
    }
    // Add nodes to nodesToAssociate
    for (let i = 0; i < this.multiSelectedNodes.length; i++) {
      // Add the node data for the key in multiSelectedNodes
      this.nodesToAssociate[this.multiSelectedNodes[i]] = this.nodes[this.multiSelectedNodes[i]];
    }

    if (this.nodesToAssociate && this.connectionsToAssociate) {
      // also run the overlapping nodes button when associating photos in the ppl stack
      if (this.jobCreator == 'ppl_attachments') {
        await this._button_import_overlapping_notes(undefined, { cancelButtonText: 'Skip' });
      }

      this.toast('Continuing photo association...');

      let photos = await GetJobData(this.job_id, 'photos').then((d) => d.photos);
      this.associationResults = await AssociatePhotos(this.job_id, this.nodesToAssociate, this.connectionsToAssociate, photos, {
        saveSyncedTime: true
      });
      this.associationResults.photos = photos;

      if (this.associationResults.largeTimeBuckets.length) {
        this.$.timeBucketDialog.open();
      } else {
        this.commitAssociation();
      }
    }
  }

  async deleteLargeTimeBucketsThenAssociate() {
    let update = {};
    this.associationResults.largeTimeBuckets.forEach((bucket) => {
      if (bucket.nodeId) {
        delete this.nodesToAssociate[bucket.nodeId].attributes.time_bucket[bucket.id];
      } else {
        delete this.connectionsToAssociate[bucket.connId].sections[bucket.sectId].multi_attributes.time_bucket[bucket.id];
      }
      update[
        `photoheight/jobs/${this.job_id}/${
          bucket.nodeId ? `nodes/${bucket.nodeId}/attributes` : `connections${bucket.connId}/sections/${bucket.sectId}/multi_attributes`
        }/time_bucket/${bucket.id}`
      ] = null;
    });
    this.associationResults = await AssociatePhotos(
      this.job_id,
      this.nodesToAssociate,
      this.connectionsToAssociate,
      this.associationResults.photos,
      { saveSyncedTime: true }
    );
    Object.assign(this.associationResults.update, update);
    this.commitAssociation();
  }

  async commitAssociation() {
    // Actions for Photofirst
    for (let id in this.associationResults.nodePhotoFirstEditors) {
      let ids = id.split(':');
      let jobId = ids[0];
      let nodeId = ids[1];
      let item = this.associationResults.nodePhotoFirstEditors[id];
      let firestoreRef = firebase.firestore().collection(`companies/${this.userGroup}/action_tracking`);
      // TODO-WARN: Hard coding of id is not a long-term solution
      firestoreRef
        .where('job_id', '==', jobId)
        .where('action_id', '==', '-McL2dSwnbOrpU5TTiNY')
        .where('node_id', '==', nodeId)
        .get()
        .then((s) => {
          if (s.empty) {
            firestoreRef.add({
              uid: item.uid,
              timestamp: item.timestamp || firebase.firestore.FieldValue.serverTimestamp(),
              time_of_association: firebase.firestore.FieldValue.serverTimestamp(),
              job_id: jobId,
              node_id: nodeId,
              action_name: 'PhotoFirst',
              action_id: '-McL2dSwnbOrpU5TTiNY',
              order: 3
            });
          }
        });
    }

    FirebaseWorker.ref().update(this.associationResults.update);

    // Update Counters
    for (let camera of this.associationResults.cameras) {
      if (!camera.folder_id) {
        // Find the folderId if we don't have it
        let folders = await FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photo_folders')
          .once('value')
          .then((s) => s.val());
        folderLoop: for (var folderId in folders) {
          for (let cameraId in folders[folderId].cameras) {
            if (cameraId == camera.camera_id) {
              camera.folder_id = folderId;
              break folderLoop;
            }
          }
        }
      }
      // Increment the counters
      IncrementFolderCounter(this.job_id, camera.folder_id, camera.camera_id, this.userGroup, 'numAssociated', {
        count: camera.numAssociated
      });
      if (camera.numTagged) {
        IncrementFolderCounter(this.job_id, camera.folder_id, camera.camera_id, this.userGroup, 'numTagged', { count: camera.numTagged });
      }
    }

    this.cancelPromptAction();
    this.toast('Finished associating photos', null, 3000);
    let dialogTitle = 'Photo Association Results';

    let generalWarnings = this.associationResults.warnings.map((x) => x.message);
    let unassociatedPhotoCount = this.associationResults.numPhotosToAssociate - this.associationResults.numAssociated;
    // Create general warnings for association issues
    if (unassociatedPhotoCount < 0) {
      generalWarnings.push(
        `At least ${Math.abs(unassociatedPhotoCount)} photos double associated. Please check for bad, long time buckets and remove them.`
      );
    } else if (unassociatedPhotoCount) {
      generalWarnings.push(
        `${unassociatedPhotoCount} photos left unassociated. Please double check that your time sync markers reflect exactly what is in the sync photo.`
      );
    }

    // Create a warning dialog object to pass into the QC before being displayed
    let associationWarnings = { title: dialogTitle, generalWarnings };

    //Update the nodes / conns so the correct warnings can be generated
    for (let key in this.associationResults.update) {
      let path = key.split('/');
      let photosIndex = path.indexOf('photos');
      let nodesIndex = path.indexOf('nodes');
      let connectionsIndex = path.indexOf('connections');
      if (photosIndex != -1 && nodesIndex != -1) {
        Path.set(this.nodes, path.slice(nodesIndex + 1).join('.'), this.associationResults.update);
      }
      if (photosIndex != -1 && connectionsIndex != -1) {
        Path.set(this.connections, path.slice(connectionsIndex + 1).join('.'), this.associationResults.update);
      }
    }

    for (let nodeId in this.associationResults.associations.nodes) {
      this.nodesToAssociate[nodeId].photos = this.nodesToAssociate[nodeId].photos || {};
      Object.assign(this.nodesToAssociate[nodeId].photos, this.associationResults.associations.nodes[nodeId]);
    }
    for (let id in this.associationResults.associations.sections) {
      let [connId, sectionId] = id.split(':');
      this.connectionsToAssociate[connId].sections[sectionId].photos = this.connectionsToAssociate[connId].sections[sectionId].photos || {};
      Object.assign(this.connectionsToAssociate[connId].sections[sectionId].photos, this.associationResults.associations.sections[id]);
    }

    // Run the QC report to get any photo warnings
    this.runQCCheck('photo_warnings', { initialReport: associationWarnings }, this.nodesToAssociate, this.connectionsToAssociate);

    //If the user desires (specified by a check box), automatically star mid-span or height photos for nodes or connections in association
    if (this.$.katapultMap.associationStarCheck) await this.starPhotosInAssociation(this.nodesToAssociate, this.connectionsToAssociate);

    setTimeout(async () => {
      const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
      await FirebaseWorker.database().ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
    }, 1);
    this.associationResults = null;
    this.nodesToAssociate = null;
    this.connectionsToAssociate = null;
  }

  async starPhotosInAssociation(nodes, connections) {
    //get photo data for job
    await GetJobData(this.job_id, 'photos').then((data) => {
      //star height photos in nodes
      for (let nodeId in nodes) {
        let node = nodes[nodeId];
        if (node.photos) {
          let nodePhotos = node.photos;
          for (let photoId in nodePhotos) {
            if (data.photos?.[photoId]?.photofirst_data?.poleHeight) {
              this.updateMainPhotoWithAssociation(photoId, nodeId, null, null);
              break;
            }
          }
        }
      }
      //star midspan photos in connections
      for (let connectionId in connections) {
        let connection = connections[connectionId];
        if (connection?.sections) {
          for (let sectionId in connection.sections) {
            let section = connection.sections[sectionId];
            if (section.photos) {
              let sectionPhotos = section.photos;
              for (let photoId in sectionPhotos) {
                if (data.photos?.[photoId]?.photofirst_data?.midspanHeight) {
                  this.updateMainPhotoWithAssociation(photoId, null, connectionId, sectionId);
                  break;
                }
              }
            }
          }
        }
      }
    });
  }

  async updateMainPhotoWithAssociation(photoId, nodeId, connectionId, sectionId) {
    let update = {};
    let clearUpdate = {};
    //set main photos in nodes
    if (nodeId != null && this.nodes?.[nodeId]?.photos) {
      //clear current main photo (to stage change)
      for (let photoIds in this.nodes[nodeId].photos) {
        clearUpdate[`nodes/${nodeId}/photos/${photoIds}`] = { association: true };
      }
      //set photo with photoId as main
      update[`nodes/${nodeId}/photos/${photoId}`] = { association: 'main' };
    }
    //set main photos in connections
    else if (connectionId != null && sectionId != null && this.connections?.[connectionId]?.sections[sectionId]?.photos) {
      for (let photoIds in this.connections[connectionId].sections[sectionId].photos) {
        clearUpdate[`connections/${connectionId}/sections/${sectionId}/photos/${photoIds}`] = { association: true };
      }
      update[`connections/${connectionId}/sections/${sectionId}/photos/${photoId}`] = { association: 'main' };
    }
    //update database
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(clearUpdate);
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
  }

  async _button_un_associate_photos(e) {
    this.keepManuallyAssociatedPhotos = true;
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    this.confirm(
      'Are you sure you want to unassociate all photos?',
      'The photos will still be in the job, just no longer associated to any locations.',
      'Ok',
      'Cancel',
      null,
      'unassociatePhotos',
      function () {
        this.toast('Un-Associating Photos...');
        this.createWebWorker('un_associate_photos', 'un_associate_photos', [
          this.keepManuallyAssociatedPhotos,
          this.userGroup,
          'normal',
          this.nodes,
          this.connections,
          photos
        ]);
      }.bind(this)
    );
  }

  async _button_calc_bearings(e) {
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    this.createWebWorker(null, null, [this.modelDefaults, this.nodes, this.connections, photos]);
  }

  _button_insert_ppl_pole_spec(e) {
    this.toast('Inserting pole spec...');
    this.createWebWorker('insert_ppl_pole_spec', 'insert_ppl_pole_spec', [
      this.mappingButtons[this.activeCommand].models,
      this.modelDefaults,
      this.otherAttributes,
      this.nodes
    ]);
  }

  async _button_insert_com_spec(e) {
    this.toast('Inserting com spec...');
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    let wireSpecLookup = FirebaseEncode.decodeKeys(this.get(`mappingButtons.${this.activeCommand}.models`));
    this.createWebWorker('insert_com_spec', 'insert_com_spec', [
      this.otherAttributes.wire_spec.picklists,
      wireSpecLookup,
      this.nodes,
      this.connections,
      photos,
      this.traces
    ]);
  }

  async _button_insert_com_spec_on_poles(e) {
    this.toast('Inserting com spec...');

    let update = {};
    let connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);
    let cableTypeLookup = GetAttributeLookup(this.otherAttributes.cable_type || {});
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    let nodeList = {};
    for (let nodeId in this.nodes) {
      let nodeType = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
      if (this.modelDefaults.pole_node_types.includes(nodeType)) {
        nodeList[nodeId] = this.nodes[nodeId];
      }
    }

    // loop through all the nodes
    for (let nodeId in nodeList) {
      let node = nodeList[nodeId];
      // Get the node's main photo's data
      let mainPhotoKey = this.getMainPhotoFromNodeId(nodeId);
      let mainPhotoData = photos[mainPhotoKey];

      if (mainPhotoData) {
        let wires = SquashNulls(photos, mainPhotoKey, 'photofirst_data', 'wire');
        // loop through all the comm wires and update their wire specs accordingly
        for (let itemKey in wires) {
          let marker = wires[itemKey];
          let cableType = SquashNulls(this.traces, marker._trace, 'cable_type');
          let markerIsComm = SquashNulls(cableTypeLookup, cableType, 'picklistId') == 'communications';
          if (markerIsComm) {
            let wireSpec = '';
            if (cableType == 'Fiber Optic Com' || cableType == 'CATV Com') {
              wireSpec = '6.6M (1/4" EHS)';
            } else if (cableType == 'Telco Com') {
              // check if the adjacent midspan diameter is less than 1.5"
              let nodeConnections = connectionLookup[nodeId] || [];
              if (
                nodeConnections.find((conn) => {
                  let connMainPhoto = SquashNulls(photos, SquashNulls(conn, 'mainPhotoId'), 'photofirst_data');
                  let matchingMarker = DataViews.help.getMarkers(connMainPhoto, this.traces).find((x) => marker._trace == x._trace);
                  if (SquashNulls(matchingMarker, 'diameter') && matchingMarker.diameter >= 1.5) return true;
                })
              ) {
                wireSpec = '10M (3/8" UG) (7/0)';
              } else {
                wireSpec = '6M (5/16" UG) (7/0)';
              }
            }
            if (wireSpec != '') {
              update[`${mainPhotoKey}/photofirst_data/wire/${itemKey}/wire_spec`] = wireSpec;
            }
          }
        }
      }
    }

    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photos`)
      .update(update)
      .then(async () => {
        this.toast('Finished inserting com spec');
      })
      .catch((error) => {
        this.toast(error);
      });
  }

  _button_clear_warnings(e) {
    this.toast('Clearing warnings in job ...');
    this.createWebWorker('clear_warnings', 'clear_warnings', [this.nodes, this.connections, this.jobStyles]);
  }

  calcBearingComplete(err) {
    let report = { title: 'Calc Bearing Results' };
    if (err) report.errors = [err];
    this.toast('Calc Bearings complete');
    this.runQCCheck('check_tracing_and_bearings', { initialReport: report });
  }

  async saveJobStyles() {
    const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
    this.toast('Job Styles Updated');
  }

  _button_show_orphaned_photos(e) {
    this._button_show_un_associated_photos();
  }

  _button_show_un_associated_photos(e) {
    this.set('$.photoStream.style.display', 'block');
    this.set('$.photoStream.src', window.location.pathname.replace('/map/', '/photosorting/') + '#' + this.job_id);
    this.cancelPromptAction();
  }

  copyDataFromLayerMasterCheckboxChanged(e) {
    let checked = e.currentTarget.checked;
    this.copyDataFromLayerJobs.forEach((x, index) => {
      this.set(`copyDataFromLayerJobs.${index}.wipeData`, checked);
    });
  }

  // This function is specific for Charter - used to create a connection
  // boundary from a polygon when automatically creating a new Charter job
  addGridConnectionToJob(polygon, jobId, jobStyles, update) {
    jobStyles = jobStyles || {};
    if (
      polygon &&
      polygon.geometry &&
      polygon.geometry.coordinates &&
      polygon.geometry.coordinates[0] &&
      polygon.geometry.coordinates[0].length >= 4
    ) {
      let coords = polygon.geometry.coordinates[0];

      let nodeAttributes = [
        {
          attribute: this.modelDefaults.node_type_attribute,
          key: 'grid_import',
          value: 'Grid'
        }
      ];
      let connAttributes = [
        {
          attribute: this.modelDefaults.connection_type_attribute,
          key: 'grid_import',
          value: 'Border'
        }
      ];

      let node1 = DataLayer.Nodes.create(coords[0][1], coords[0][0], null, FirebaseWorker, {
        update,
        jobId,
        attributes: nodeAttributes,
        jobStyles
      });
      let node2 = DataLayer.Nodes.create(coords[1][1], coords[1][0], null, FirebaseWorker, {
        update,
        jobId,
        attributes: nodeAttributes,
        jobStyles
      });
      let node3 = DataLayer.Nodes.create(coords[2][1], coords[2][0], null, FirebaseWorker, {
        update,
        jobId,
        attributes: nodeAttributes,
        jobStyles
      });
      let node4 = DataLayer.Nodes.create(coords[3][1], coords[3][0], null, FirebaseWorker, {
        update,
        jobId,
        attributes: nodeAttributes,
        jobStyles
      });

      DataLayer.Connections.create(node1.key, node1.data, node2.key, node2.data, null, FirebaseWorker, {
        update,
        jobId,
        attributes: connAttributes,
        jobStyles
      });
      DataLayer.Connections.create(node2.key, node2.data, node3.key, node3.data, null, FirebaseWorker, {
        update,
        jobId,
        attributes: connAttributes,
        jobStyles
      });
      DataLayer.Connections.create(node3.key, node3.data, node4.key, node4.data, null, FirebaseWorker, {
        update,
        jobId,
        attributes: connAttributes,
        jobStyles
      });
      DataLayer.Connections.create(node4.key, node4.data, node1.key, node1.data, null, FirebaseWorker, {
        update,
        jobId,
        attributes: connAttributes,
        jobStyles
      });
    }
  }

  // TODO (03/25/2024): Remove this function once the changes to the _buttonSliceJobByGrid are out everywhere
  // The purpose of this function is to esure that CCI is only able to run the version of the _buttonSliceJobByGrid that has the changes they need
  async _buttonCCISliceJobByGrid(e) {
    this._buttonSliceJobByGrid();
  }

  async _buttonSliceJobByGrid(e) {
    // Default to production shard
    let referenceDatabase = 'https://katapult-production-charter-master-layers.firebaseio.com';
    // Change the shard if we are on the Charter server
    if (config.appName == 'charter-katapult-pro') {
      referenceDatabase = 'https://charter-katapult-pro-charter-master-layers.firebaseio.com';
    }

    let buttonSettings = {
      confirmText:
        'WARNING: this button will slice up the data in the current job if the tile is overloaded. Make sure this job is fully collected and ready to be sliced before running this process. A backup copy will be created before the data is split up.',
      // Back up the main job by default
      backupMainJob: true,
      // Leave the job name alone for everyone else
      transformJobName: (jobName) => {
        return jobName;
      },
      // Don't allow cross-state grid slicing
      allowCrossStateSlicing: false,
      referenceDatabase: referenceDatabase
    };

    // Check if Aventura is running the button
    if (this.userGroup == 'aventura_group') {
      buttonSettings.confirmText =
        'This button slices up data in the current job and copies it into tile jobs that cover the same area. Make sure this job is fully collected and ready to be sliced before running this process. This job will be unaffected.';
      // Don't back up the main job for aventura since they are running slicing on custom jobs
      buttonSettings.backupMainJob = false;
      // Allow cross-state grid slicing
      buttonSettings.allowCrossStateSlicing = true;
      // Custom job names for Aventura
      buttonSettings.transformJobName = (jobName) => {
        return `A${jobName}`;
      };
      // Check if we are specifically working on the Aventura database
      if (config.appName == 'aventura-katapult-pro') {
        buttonSettings.referenceDatabase = `https://aventura-katapult-pro-master-geohash.firebaseio.com`;
      }
    }

    // Check if CCI is running the button
    if (this.userGroup == 'cci_systems') {
      buttonSettings.allowCrossStateSlicing = true;
    }

    // If cci shares jobs that have a model with this button and that company runs this function, then we should prevent them because
    // if we don't, Charter will get ownership of these jobs even though cci is not doing work for them.
    if (this.jobOwner == 'cci_systems' && this.userGroup != 'cci_systems') {
      this.toast(`Only CCI Systems users can slice the jobs that they own.`, null, 7000);
      return;
    }

    this.confirm('Slice Job Data', buttonSettings.confirmText, 'Run Slicing', 'Cancel', '', '', () => {
      // determine where to get the data from
      let utilityInfoPath = `utility_info/charter/grid`;
      if (this.userGroup == 'cci_systems') utilityInfoPath = `utility_info/cci_systems/grid`;
      // Fetch job data
      this.toast(`Searching for nearby grids. This may take a while...`, null, 0);

      // Create a boundary from the nodes in the job
      let bounds = new google.maps.LatLngBounds();
      // Check if the nodes in the job fall outside of the current job's grid
      for (let nodeId in this.nodes) {
        let latLng = new google.maps.LatLng(this.nodes[nodeId].latitude, this.nodes[nodeId].longitude);
        bounds.extend(latLng);
      }

      let center = bounds.getCenter();
      let jobRadius = google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast()) / 1000;
      // Grids are about 1.350 km wide, so make sure the radius is at least that
      if (jobRadius < 1.35) jobRadius = 1.35;
      let queryParameters = {
        center: [center.lat(), center.lng()],
        radius: jobRadius
      };
      // Create a geofire reference for the layer and set up the query
      let layerGeoRef = new GeoFire(firebase.app().database(buttonSettings.referenceDatabase).ref(utilityInfoPath));
      let layerQuery = layerGeoRef.query(queryParameters);

      // Stores the items found from the query
      let layerQueryItems = {};

      let connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);

      // Store the keys of items within the query
      layerQuery.on('key_entered', (key, location, distance, data) => {
        layerQueryItems[key] = data;
      });

      // Watch for the query to be ready (meaning we found all of the data)
      layerQuery.on('ready', async () => {
        this.cleanupPhotoUnassociation = await import('../../modules/CleanupPhotoUnassociation.js');

        this.toast(`Finished searching for grids`, null, 4000);

        // Determine the unique grids we found and fetch the rest of the needed
        // keys if we don't have them already. Example key: TX_2-513-perimeter-0-1~1
        let uniqueGridKeys = Object.keys(layerQueryItems).map((x) => x.split('-perimeter')[0]);
        uniqueGridKeys = uniqueGridKeys.filter((x, index) => index == uniqueGridKeys.indexOf(x));

        // Check that we found any grids to copy data to
        if (uniqueGridKeys.length) {
          // If the user group is TechServ and the job name is not found in any of the tiles,
          // then this should be a custom job and we won't back it up because we won't remove
          // data from it. If the job name is the same as one of the tiles, then we should
          // proceed like normal
          if (this.userGroup == 'techserv' && uniqueGridKeys.includes(this.jobName) == false) {
            buttonSettings.backupMainJob = false;
          }

          let sliceBackupJobId = null;
          if (buttonSettings.backupMainJob) {
            this.toast(`Backing up current job...`);

            let sliceBackupName = `${this.jobName} - Before Slice Backup`;
            // Check to see if a slice backup has alraedy been created for this job
            let sliceBackupPermissionRecord = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/list`)
              .orderByChild('name')
              .equalTo(sliceBackupName)
              .once('value')
              .then((s) => s.val());
            if (sliceBackupPermissionRecord) {
              sliceBackupJobId = Object.keys(sliceBackupPermissionRecord)[0];
            } else {
              // Create a slice backup job if one doesn't exist
              let newSliceBackupJob = await firebase
                .functions()
                .httpsCallable('createCharterGridJob')({ jobName: sliceBackupName, sourceJobId: this.job_id })
                .then((newJob) => newJob)
                .catch((err) => {
                  console.log('err', err);
                });
              sliceBackupJobId = SquashNulls(newSliceBackupJob, 'data', 'jobId') || null;
            }

            if (sliceBackupJobId) {
              // Set copy settings
              this.copyJobStyles = JSON.parse(JSON.stringify(this.jobStyles));
              this.copyMoveAction = 'Copy';
              this.copyPhotosToJob = true;
              this.onlyCopyNodesWithFeedback = false;
              this.copyConnsToJob = true;
              this.copyCUsToJob = false;
              this.otherCopyJobNodeKeys = null;

              // Set to copy all nodes
              this.multiSelectedNodes = Object.keys(this.nodes);
              // Set the job id to copy to
              this.copyNodesJobId = sliceBackupJobId;
              // Call to copy nodes to the backup job
              await this.copyNodesToJob();
            } else {
              this.toast('There was an error slicing the job', null, 6000);
              return;
            }
          }

          // List of all the unique endpoint keys that are needed for an entire grid polygon
          // We only need the ~1 endpoint because we can use the l2 data for that endpoint if needed
          let neededGridKeys = ['-perimeter-0-0~1', '-perimeter-0-1~1', '-perimeter-0-2~1', '-perimeter-0-3~1'];

          // Used to store the features for completed grids once we get all of the keys
          let gridFeatures = [];
          // Loop through the unique grids (the part before the -perimeter) and fetch other needed keys
          for (let gridKey of uniqueGridKeys) {
            // Make an array to hold the grid coordinates as we find them
            let gridCoordinates = [];
            for (let keyToAppend of neededGridKeys) {
              // Check if we don't have that item in our query items list
              let keyToCheck = `${gridKey}${keyToAppend}`;
              if (!layerQueryItems[keyToCheck]) {
                layerQueryItems[keyToCheck] = await firebase
                  .app()
                  .database(buttonSettings.referenceDatabase)
                  .ref(`${utilityInfoPath}/${keyToCheck}`)
                  .once('value')
                  .then((s) => s.val());
              }
              // Reverse the array because X/Y and Lat/Long are reversed
              gridCoordinates.push(layerQueryItems[keyToCheck].l.slice().reverse());
            }
            let firstItem = layerQueryItems[`${gridKey}${neededGridKeys[0]}`];
            // Push the first coordinate onto the array again as the last point
            gridCoordinates.push(firstItem.l.slice().reverse());
            // Modify the grid/job names of the feature for future use
            for (let infoKey in firstItem.info) {
              let testKey = infoKey.toLowerCase();
              if (testKey == 'grid' || testKey == 'name' || testKey == 'job name' || testKey == 'job_name' || testKey == 'job') {
                firstItem.info[infoKey] = buttonSettings.transformJobName(firstItem.info[infoKey]);
              }
            }
            // Build a grid feature now that we have all of the needed parts of the grid key
            gridFeatures.push({
              geometry: {
                coordinates: [gridCoordinates],
                type: 'Polygon'
              },
              properties: {
                ...firstItem.info
              },
              type: 'Feature'
            });
          }

          // Used to track the names of jobs that alread exists
          let existingJobNames = {};
          // Used to make sure we create each job only once and keep track of the newly created job ids
          let jobNamesToCreate = {};
          // Stores the update for creating the grid connection boundary in each job
          let gridConnectionUpdate = {};
          // Flag used to check if we should keep going because all of the
          // needed jobs didn't exist and have been created
          let keepGoing = true;

          // Use the grid features to determine which nodes/connections to assign to which feature
          let results = await JobDataByFeature(gridFeatures, this.nodes, this.connections, this.modelDefaults, google, {
            useMetricUnits: this.useMetricUnits,
            groupByProperty: 'grid'
          });

          // Check if all of the grids are in the same state
          let firstGridState = uniqueGridKeys[0].substring(0, 2);
          if (uniqueGridKeys.some((gridKey) => gridKey.startsWith(firstGridState) == false)) {
            if (buttonSettings.allowCrossStateSlicing) {
              // Allow the slicing, but warn the user of the consequences
              results.warnings.summaryText = `The area covered by the job to be sliced covers more than one state. The job can still be sliced, but all new jobs will be put in the ${this.projectFolder} folder.`;
            } else {
              results.warnings.generalWarnings.push(
                `The area covered by the job to be sliced covers more than one state. This situation is not handled currently, so the process has been stopped.`
              );
              keepGoing = false;
            }
          }

          if (keepGoing) {
            // Make a unique list of jobs that the nodes appear in
            for (let nodeId in results.nodeIdPolygonLookup) {
              let jobName = SquashNulls(results.nodeIdPolygonLookup[nodeId], 'properties', 'grid') || '';
              // Check that the job name isn't the current job
              if (jobName && jobName != this.jobName) {
                // Check if the job exists already
                let jobPermissionsRecord = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/list`)
                  .orderByChild('name')
                  .equalTo(jobName)
                  .once('value')
                  .then((s) => s.val());
                if (jobPermissionsRecord) {
                  // If the job already exists, we should only warn if the node has more
                  // than one connection going into the job. Each existing tile could have
                  // potentially one connection going into it from the field crew trying
                  // to connect to an existing tile. So determine if the node has more than
                  // connection in the tile and warn in that case
                  let nodeConnections = connectionLookup[nodeId] || [];
                  // Check if any of the connections coming off of the node
                  // has the same polygon assignment as the current node
                  let foundBadNode = false;
                  for (let conn of nodeConnections) {
                    let otherPolygonName = SquashNulls(results, 'nodeIdPolygonLookup', conn.toNodeId, 'properties', 'grid') || '';
                    if (otherPolygonName == jobName) {
                      foundBadNode = true;
                      break;
                    }
                  }
                  // If we found a node that shouldn't belong, then add the job
                  // to the list of existing jobs that aren't allowed
                  if (foundBadNode) {
                    existingJobNames[jobName] = true;
                  }
                } else {
                  jobNamesToCreate[jobName] = {
                    jobName,
                    polygon: results.nodeIdPolygonLookup[nodeId]
                  };
                }
              }
            }

            // Check if we found any existing jobs and warn the user
            let existingJobNamesArray = Object.keys(existingJobNames);
            // Skip this check if the user is techserv
            if (this.userGroup != 'techserv' && existingJobNamesArray.length) {
              // Warn about any jobs that already exist
              results.warnings.generalWarnings.push(
                `The following jobs need to be created but already exist, so the splicing process has been stopped:\n\n${existingJobNamesArray.join(
                  '\n'
                )}`
              );
              keepGoing = false;
            }

            for (let jobName in jobNamesToCreate) {
              if (keepGoing) {
                this.toast(`Creating job for ${jobName}...`);

                let newJob = await firebase
                  .functions()
                  .httpsCallable('createCharterGridJob')({ jobName, sourceJobId: this.job_id })
                  .then((newJob) => newJob)
                  .catch((err) => {
                    console.log('err', err);
                    results.warnings.generalWarnings.push(
                      `There was an error creating a job for ${jobName}. The process has been stopped.`
                    );
                    keepGoing = false;
                  });

                if (keepGoing) {
                  if (newJob && newJob.data && newJob.data.jobId) {
                    // Update the record for the job
                    jobNamesToCreate[jobName].jobId = newJob.data.jobId;
                    // Add the grid boundary for the new job to an update object
                    this.addGridConnectionToJob(jobNamesToCreate[jobName].polygon, newJob.data.jobId, this.jobStyles, gridConnectionUpdate);
                  } else {
                    results.warnings.generalWarnings.push(
                      `There was an error creating a job for ${jobName}. The process has been stopped.`
                    );
                    keepGoing = false;
                    break;
                  }
                }
              }
            }

            await FirebaseWorker.ref(`photoheight/jobs`, { noTracking: true })
              .update(gridConnectionUpdate)
              .catch((err) => {
                console.log('Error creating grid connection boundary', err);
                keepGoing = false;
              });

            this.copyDataFromLayerJobs = [];

            if (keepGoing) {
              let preparedData = await this.prepareJobDataForTileSlicing(results, connectionLookup, this.jobStyles);

              let runFinalCopy = async () => {
                // Preserve the job styles of the current job
                this.copyJobStyles = JSON.parse(JSON.stringify(this.jobStyles));
                // Set settings
                this.copyMoveAction = 'Copy';
                this.copyPhotosToJob = true;
                this.onlyCopyNodesWithFeedback = false;
                this.copyConnsToJob = true;
                this.copyCUsToJob = false;
                this.otherCopyJobNodeKeys = null;

                // Used to track the nodes to delete from the current job once the copying is finished
                let nodeIdsToDelete = [];

                for (let jobId in preparedData.nodesToCopyByJob) {
                  // Skip copying data the same data into the current job
                  if (jobId != this.job_id) {
                    // Allow TechServ to wipe data in the destination job
                    if (this.userGroup == 'techserv') {
                      let jobOption = this.copyDataFromLayerJobs.find((x) => x.record.targetJobId == jobId);
                      let shouldWipeData = jobOption && jobOption.wipeData == true;
                      if (shouldWipeData) {
                        let removeUpdate = {};
                        removeUpdate[`connections`] = null;
                        removeUpdate[`geohash`] = null;
                        removeUpdate[`nodes`] = null;
                        removeUpdate[`photo_folders`] = null;
                        removeUpdate[`photo_summary`] = null;
                        removeUpdate[`photos`] = null;
                        removeUpdate[`traces`] = null;
                        await FirebaseWorker.ref(`photoheight/jobs/${jobId}`).update(removeUpdate);
                      }
                    }

                    let foundJobName =
                      SquashNulls(
                        Object.values(jobNamesToCreate).find((x) => x.jobId == jobId),
                        'jobName'
                      ) || jobId;
                    this.toast(`Copying data to ${foundJobName}...`);
                    // Set the list of nodes to copy
                    this.multiSelectedNodes = preparedData.nodesToCopyByJob[jobId].map((x) => x.nodeId);

                    // Since we copied the data out of the main job instead of moving it,
                    // we need to delete out all of the data that shouldn't be in the main job
                    // anymore. The reason we copy the data out of the job instead of moving
                    // it is because the postCopyUpdate expects there to be a span in each
                    // job, going to the connected job. We then update one of those spans
                    // to be a reference. But if we do a move action, only one job gets the
                    // span, which causes an error when we try to update the non-existent span.
                    // So now we need to delete all of the nodes in the main job except the ones
                    // connecting out to other jobs
                    for (let nodeId of this.multiSelectedNodes) {
                      let nodeConnections = connectionLookup[nodeId] || [];
                      // Check if any of the node's connections have an
                      // endpoint assigned to the current job
                      let nodeConnectsToMainJob = nodeConnections.some((x) => {
                        if (SquashNulls(results, 'nodeIdPolygonLookup', x.fromNodeId, 'properties', 'grid') == this.jobName) return true;
                        if (SquashNulls(results, 'nodeIdPolygonLookup', x.toNodeId, 'properties', 'grid') == this.jobName) return true;
                        return false;
                      });
                      // Add all of the nodeIds that are not connected by a span to the current job
                      if (!nodeConnectsToMainJob) {
                        nodeIdsToDelete.push(nodeId);
                      }
                    }

                    // Set the job id to copy to
                    this.copyNodesJobId = jobId;
                    // Call to copy nodes
                    await this.copyNodesToJob();
                    // Delay a bit so the user can follow the toasts
                    await new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                      }, 2000);
                    });
                  }
                }

                // Only remove the nodes from the main job if a backup was created
                if (buttonSettings.backupMainJob) {
                  // Loop through all of the nodes in the job
                  let nodeDeleteUpdate = {};
                  let photoData = await GetJobData(this.job_id, 'photos').then((data) => data);
                  for (let i = 0; i < nodeIdsToDelete.length; i++) {
                    let id = nodeIdsToDelete[i];
                    nodeDeleteUpdate['nodes/' + id] = null;
                    nodeDeleteUpdate['geohash/' + id] = null;
                    nodeDeleteUpdate['app_geohash/' + id] = null;
                    for (var photoId in this.nodes[id].photos) {
                      this.cleanupPhotoUnassociation(nodeDeleteUpdate, photoId, id, photoData.photos);
                    }
                    if (connectionLookup[id]) {
                      for (var j = 0; j < connectionLookup[id].length; j++) {
                        let connId = connectionLookup[id][j].connId;
                        let conn = this.connections[connId];
                        nodeDeleteUpdate['connections/' + connId] = null;
                        nodeDeleteUpdate['geohash/' + connId + '~1'] = null;
                        nodeDeleteUpdate['geohash/' + connId + '~2'] = null;
                        if (!nodeIdsToDelete.includes(conn.node_id_1))
                          nodeDeleteUpdate['geohash/' + conn.node_id_1 + '/n/' + connId] = null;
                        else if (!nodeIdsToDelete.includes(conn.node_id_2))
                          nodeDeleteUpdate['geohash/' + conn.node_id_2 + '/n/' + connId] = null;
                        for (var section in conn.sections) {
                          nodeDeleteUpdate['/geohash/' + connId + ':' + section] = null;
                          for (var photoId in conn.sections[section].photos) {
                            this.cleanupPhotoUnassociation(nodeDeleteUpdate, photoId, connId + ':' + section, photoData.photos);
                          }
                        }
                      }
                    }
                  }
                  // Remove the data that shouldn't be in the current job anymore
                  await FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(nodeDeleteUpdate);
                }

                if (preparedData.postCopyUpdate) {
                  await FirebaseWorker.ref(`photoheight/jobs`).update(preparedData.postCopyUpdate);
                }

                this.toast('Job Splicing Complete!');
              };

              // Run custom workflow for Techserv
              if (this.userGroup == 'techserv') {
                let copyDataFromLayerJobs = [];
                for (let jobId in preparedData.jobCounts) {
                  let record = preparedData.jobCounts[jobId];
                  record.targetJobId = jobId;
                  copyDataFromLayerJobs.push({
                    record,
                    jobId,
                    wipeData: false
                  });
                }
                this.copyDataFromLayerJobs = copyDataFromLayerJobs.sort((a, b) => a.record.name.localeCompare(b.record.name));

                // Show warnings if there are any
                let showedWarnings = false;
                if (results.warnings.nodeWarnings.length > 0 || results.warnings.connectionWarnings.length) {
                  this.displayWarningsDialog(results.warnings);
                  showedWarnings = true;
                }

                this.confirm(
                  'Copy Data to Jobs',
                  `Here is the breakdown of data to be copied. This data will overwrite data in the destination jobs.<br /><br />${
                    showedWarnings ? 'Make sure to check any warnings before copying data.<br /><br />' : ''
                  }`,
                  'Copy Data',
                  'Cancel',
                  null,
                  'copyDataFromLayer',
                  async () => {
                    await runFinalCopy();
                  }
                );
              } else {
                // Run the copy actions like normal
                await runFinalCopy();
              }
            } else {
              results.warnings.generalWarnings.push(
                `There was an error slicing the job. The process has been stopped. Please contact Katapult for support.`
              );
            }
          }

          if (
            results.warnings.nodeWarnings.length ||
            results.warnings.connectionWarnings.length ||
            results.warnings.generalWarnings.length
          ) {
            this.toast('There was an error slicing the job', null, 6000);
            this.displayWarningsDialog(results.warnings);
          } else if (results.warnings.summaryText) {
            this.displayWarningsDialog(results.warnings);
          }
        }
      });
      this.cancelPromptAction();
    });
  }

  async prepareJobDataForTileSlicing(results, connectionLookup, jobStyles, selectedJobNames) {
    // Create a temp list of nodes to copy
    let nodesToCopyByJob = {};
    let connectionsToCopyByJob = {};
    let nodeJobIdLookup = {};
    let jobIdNameLookup = {};
    let jobNameIdLookup = {};

    for (let nodeId in results.nodeIdPolygonLookup) {
      let properties = SquashNulls(results.nodeIdPolygonLookup, nodeId, 'properties');
      for (let prop in properties) {
        if (
          prop.toLowerCase() == 'job name' ||
          prop.toLowerCase() == 'job_name' ||
          prop.toLowerCase() == 'job' ||
          prop.toLowerCase() == 'grid'
        ) {
          // Get the job name from the property
          let jobName = properties[prop];
          if (selectedJobNames?.length) jobName = selectedJobNames.find((selectedName) => selectedName.includes(jobName));
          if (jobName) {
            // If the id is not in the lookup, then find the id
            if (!jobIdNameLookup[jobName]) {
              // Look up the key for the job name
              let jobData = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/list`)
                .orderByChild('name')
                .equalTo(jobName)
                .once('value')
                .then((s) => s.val());
              if (jobData) {
                jobIdNameLookup[jobName] = Object.keys(jobData)[0];
              }
            }
            let jobId = jobIdNameLookup[jobName];
            jobNameIdLookup[jobId] = jobName;
            if (jobId) {
              // Add the job id to the node lookup
              nodeJobIdLookup[nodeId] = jobId;
              // Add the node to the lookup for the job id
              if (!nodesToCopyByJob[jobId]) {
                nodesToCopyByJob[jobId] = [];
              }
              nodesToCopyByJob[jobId].push({
                nodeId
              });
              // Add the number of connections to be added
              if (!connectionsToCopyByJob[jobId]) {
                connectionsToCopyByJob[jobId] = [];
              }
              let nodeConnections = connectionLookup[nodeId] || [];
              nodeConnections.forEach((conn) => {
                if (!connectionsToCopyByJob[jobId].find((x) => x.connId == conn.connId)) {
                  connectionsToCopyByJob[jobId].push({
                    connId: conn.connId
                  });
                }
              });
            }
          }
          break;
        }
      }
    }

    let defaultNodeTypeAttribute = this.modelDefaults.node_type_attribute;
    let defaultReferenceNodeTypes = this.modelDefaults.reference_node_types;
    let defaultConnectionTypeAttribute = this.modelDefaults.connection_type_attribute;
    let defaultReferenceConnectionTypes = this.modelDefaults.reference_connection_types;

    let nodeTypeLookup = GetAttributeLookup(this.otherAttributes[defaultNodeTypeAttribute] || {});

    // Build an update statement to run after the copy that will "correct" all of the reference nodes and connections
    let postCopyUpdate = {};

    for (let connId in results.connectionIdPolygonLookup) {
      let shouldConvertConnection = false;
      let connectionTypeAttribute = PickAnAttribute(this.connections[connId].attributes, this.modelDefaults.connection_type_attribute);
      // If Aventura, then make reference for all connection types
      // If Charter, then only make references for aerial cables
      if (this.userGroup == 'aventura_group') {
        shouldConvertConnection = true;
      } else if (connectionTypeAttribute == 'aerial cable') {
        shouldConvertConnection = true;
      }
      if (!shouldConvertConnection) {
        continue;
      }

      let nodeId1 = SquashNulls(this.connections, connId, 'node_id_1');
      let nodeId2 = SquashNulls(this.connections, connId, 'node_id_2');

      let job1 = nodeJobIdLookup[nodeId1];
      let job2 = nodeJobIdLookup[nodeId2];

      // If the endpoints are in two different jobs, then we need to create reference updates
      if (job1 && job2 && job1 != job2) {
        // Determine which job and node are primary (the node that is going to
        // the same job as the connection) and which are secondary
        let primaryJob = null;
        let primaryNode = null;
        let secondaryJob = null;
        let secondaryNode = null;

        let node1Type = PickAnAttribute(SquashNulls(this.nodes, nodeId1, 'attributes'), defaultNodeTypeAttribute);
        let node2Type = PickAnAttribute(SquashNulls(this.nodes, nodeId2, 'attributes'), defaultNodeTypeAttribute);
        let node1TypePicklistId = SquashNulls(nodeTypeLookup, node1Type, 'picklistId');
        let node2TypePicklistId = SquashNulls(nodeTypeLookup, node2Type, 'picklistId');
        let node1IsPremises = node1TypePicklistId == 'premises' || node1TypePicklistId == 'premise';
        let node2IsPremises = node2TypePicklistId == 'premises' || node2TypePicklistId == 'premise';

        // If the user is Aventura and either node type is any of the values in the
        // "premises" list, then do some custom logic instead of making references
        // based on length. The node and connection type should stay the same in the
        // job that contains the other node endpoint. The reference node and connection
        // should always be in the other job that the connection crosses into
        if (this.userGroup == 'aventura_group' && (node1IsPremises || node2IsPremises)) {
          // If node1 is the premise node, then make job1 and node1 primary, and vice versa
          if (node1IsPremises) {
            primaryJob = job1;
            primaryNode = nodeId1;
            secondaryJob = job2;
            secondaryNode = nodeId2;
          } else {
            primaryJob = job2;
            primaryNode = nodeId2;
            secondaryJob = job1;
            secondaryNode = nodeId1;
          }
        } else {
          // Otherwise, just make the primary job the job for the node that is contained in the same job assigned to the connection
          if (results.nodeIdPolygonLookup[nodeId1]._key == results.connectionIdPolygonLookup[connId]._key) {
            primaryJob = job1;
            primaryNode = nodeId1;
            secondaryJob = job2;
            secondaryNode = nodeId2;
          } else {
            primaryJob = job2;
            primaryNode = nodeId2;
            secondaryJob = job1;
            secondaryNode = nodeId1;
          }
        }

        // Link the main poles and reference poles together with reference_data (like
        // we do in mobile when drawing references into reference jobs). Mark the
        // main poles in each job as the primary poles
        postCopyUpdate[`${primaryJob}/nodes/${primaryNode}/reference_data`] = {
          primary_pole: true,
          job_id: secondaryJob
        };
        postCopyUpdate[`${secondaryJob}/nodes/${secondaryNode}/reference_data`] = {
          primary_pole: true,
          job_id: primaryJob
        };

        // Create the data for the reference node that will go into the secondary
        // job (it should replace the primary node in the secondary job)
        let secondaryJobRefNode = {
          reference_data: {
            primary_pole: false,
            job_id: primaryJob
          },
          attributes: {
            [defaultNodeTypeAttribute]: {
              [FirebaseWorker.ref().push().key]: defaultReferenceNodeTypes[0]
            }
          },
          latitude: this.nodes[primaryNode].latitude,
          longitude: this.nodes[primaryNode].longitude
        };

        // Make a copy of secondaryJobRefNode and update the data to be for the
        // reference node that will go in job 2
        let primaryJobRefNode = JSON.parse(JSON.stringify(secondaryJobRefNode));
        primaryJobRefNode.reference_data.job_id = secondaryJob;
        primaryJobRefNode.latitude = this.nodes[secondaryNode].latitude;
        primaryJobRefNode.longitude = this.nodes[secondaryNode].longitude;

        // Set the reference node for the both jobs as the key of the node in the other job
        postCopyUpdate[`${primaryJob}/nodes/${secondaryNode}`] = primaryJobRefNode;
        postCopyUpdate[`${secondaryJob}/nodes/${primaryNode}`] = secondaryJobRefNode;

        // Update the connection in only the secondary job to be a reference connection
        let connAttributeKey =
          Object.keys(SquashNulls(this.connections, connId, 'attributes', defaultConnectionTypeAttribute))[0] ||
          FirebaseWorker.ref().push().key;
        postCopyUpdate[`${secondaryJob}/connections/${connId}/attributes/${defaultConnectionTypeAttribute}/${connAttributeKey}`] =
          defaultReferenceConnectionTypes[0];

        // Update local copies of the connection for Geofire
        let connectionCopy = JSON.parse(JSON.stringify(this.connections[connId]));
        Path.set(connectionCopy, `attributes.${defaultConnectionTypeAttribute}.${connAttributeKey}`, defaultReferenceConnectionTypes[0]);
        // Do Geofire update to update styling for references
        GeofireTools.updateStyle('nodes', primaryNode, secondaryJobRefNode, postCopyUpdate, jobStyles, null, {
          geoPath: `${secondaryJob}/geohash/`
        });
        GeofireTools.updateStyle('nodes', secondaryNode, primaryJobRefNode, postCopyUpdate, jobStyles, null, {
          geoPath: `${primaryJob}/geohash/`
        });
        GeofireTools.updateStyle('connections', connId, connectionCopy, postCopyUpdate, jobStyles, null, {
          geoPath: `${secondaryJob}/geohash/`
        });

        // Update lookup for connections by job
        if (!connectionsToCopyByJob[secondaryJob]) {
          connectionsToCopyByJob[secondaryJob] = [];
        }
        connectionsToCopyByJob[secondaryJob].push({
          connId,
          createdReference: true
        });
      }
    }

    let jobCounts = {};
    for (let jobId in nodesToCopyByJob) {
      if (!jobCounts[jobId]) {
        jobCounts[jobId] = {
          name: jobNameIdLookup[jobId] || jobId,
          nodes: 0,
          connections: 0,
          referenceConnections: 0
        };
      }
      jobCounts[jobId].nodes += (nodesToCopyByJob[jobId] || []).length;
    }
    for (let jobId in connectionsToCopyByJob) {
      jobCounts[jobId].connections += (connectionsToCopyByJob[jobId] || []).filter((x) => !x.createdReference).length;
      jobCounts[jobId].referenceConnections += (connectionsToCopyByJob[jobId] || []).filter((x) => x.createdReference).length;
    }

    return { nodesToCopyByJob, jobCounts, postCopyUpdate, jobNameIdLookup };
  }

  async _button_copy_data_from_layer(e) {
    // This function is designed to take all of the nodes/sections/connections in a
    // job and copy them to other jobs based on their position within polygons in a map layer
    this.confirm(
      'Choose Map Layer',
      'Choose a layer to use to divide up map data up by job',
      'Proceed',
      'Cancel',
      null,
      'mapLayerSelectDialog',
      async () => {
        if (this.confirmDialogSelectedMapLayer && this.confirmDialogSelectedMapLayer.$key) {
          if (this.job_id && this.userGroup) {
            const { getLayer } = await import('../../modules/JobLayers.js');
            let layerData = await getLayer({
              jobId: this.job_id,
              storageFileName: this.confirmDialogSelectedMapLayer.storage_file_name,
              layerId: this.confirmDialogSelectedMapLayer.$key
            });
            if (layerData) {
              let connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);

              let features = layerData.features || [];
              let results = await JobDataByFeature(features, this.nodes, this.connections, this.modelDefaults, google, {
                useMetricUnits: this.useMetricUnits
              });

              let jobStyles = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/map_styles`)
                .once('value')
                .then((s) => s.val());
              let preparedData = await this.prepareJobDataForTileSlicing(results, connectionLookup, jobStyles);

              let copyDataFromLayerJobs = [];
              for (let jobId in preparedData.jobCounts) {
                let record = preparedData.jobCounts[jobId];
                record.targetJobId = jobId;
                copyDataFromLayerJobs.push({
                  record,
                  jobId,
                  wipeData: false
                });
              }
              this.copyDataFromLayerJobs = copyDataFromLayerJobs.sort((a, b) => a.record.name.localeCompare(b.record.name));

              // Preserve the job styles of the current job
              this.copyJobStyles = jobStyles;
              // Set settings
              this.copyMoveAction = 'Copy';
              this.copyPhotosToJob = true;
              this.onlyCopyNodesWithFeedback = false;
              this.copyConnsToJob = true;
              this.copyCUsToJob = false;
              this.otherCopyJobNodeKeys = null;

              // Show warnings if there are any
              let showedWarnings = false;
              if (results.warnings.nodeWarnings.length > 0 || results.warnings.connectionWarnings.length) {
                this.displayWarningsDialog(results.warnings);
                showedWarnings = true;
              }

              this.confirm(
                'Copy Data to Jobs',
                `Here is the breakdown of data to be copied. This data will overwrite data in the destination jobs.<br /><br />${
                  showedWarnings ? 'Make sure to check any warnings before copying data.<br /><br />' : ''
                }`,
                'Copy Data',
                'Cancel',
                null,
                'copyDataFromLayer',
                async () => {
                  // loop through Job ids from job choosers and make firebase calls to get the names
                  let selectedJobNames = [];
                  for (let i = 0; i < this.copyDataFromLayerJobs.length; i++) {
                    const targetJobId = this.copyDataFromLayerJobs[i].record.targetJobId;
                    if (!targetJobId) continue;
                    const jobName = await KatapultJob.fromId(targetJobId, { noCache: true }).getName();
                    selectedJobNames.push(jobName);
                  }
                  preparedData = await this.prepareJobDataForTileSlicing(results, connectionLookup, jobStyles, selectedJobNames);
                  for (let jobId in preparedData.nodesToCopyByJob) {
                    let jobOption = this.copyDataFromLayerJobs.find((x) => x.record.targetJobId == jobId);
                    let shouldWipeData = jobOption && jobOption.wipeData == true;
                    if (shouldWipeData) {
                      let removeUpdate = {};
                      removeUpdate[`connections`] = null;
                      removeUpdate[`geohash`] = null;
                      removeUpdate[`nodes`] = null;
                      removeUpdate[`photo_folders`] = null;
                      removeUpdate[`photo_summary`] = null;
                      removeUpdate[`photos`] = null;
                      removeUpdate[`traces`] = null;
                      await FirebaseWorker.ref(`photoheight/jobs/${jobId}`).update(removeUpdate);
                    }

                    this.toast(`Copying data to ${preparedData.jobNameIdLookup[jobId]}...`);

                    // Set the list of nodes to copy
                    this.multiSelectedNodes = preparedData.nodesToCopyByJob[jobId].map((x) => x.nodeId);
                    // Set the job id to copy to
                    this.copyNodesJobId = jobId;
                    // Call to copy nodes
                    await this.copyNodesToJob();
                  }
                  if (preparedData.postCopyUpdate) {
                    await FirebaseWorker.ref(`photoheight/jobs/`).update(preparedData.postCopyUpdate);
                  }
                  this.toast('Copy Job Data From Layer Complete');
                }
              );
            } else {
              this.toast('Error: Could not load layer data');
            }
          }
        } else {
          this.toast('No map layer was selected');
        }
        this.cancelPromptAction();
      }
    );
  }

  async _button_prepare_for_admin_report_return(e) {
    let update = {};

    let warnings = { title: 'Prepare Copied Nodes For Return Warnings', nodeWarnings: [], generalWarnings: [], sectionWarnings: [] };

    let nodeList = Object.keys(this.nodes)
      .filter((x) => {
        return this.modelDefaults.pole_node_types.includes(
          PickAnAttribute(this.nodes?.[x].attributes, this.modelDefaults.node_type_attribute)
        );
      })
      .map((x) => {
        return {
          key: x
        };
      });

    for (let i = 0; i < nodeList.length; i++) {
      let node = SquashNulls(this.nodes, nodeList[i].key);
      let ordering_attribute =
        PickAnAttribute(node.attributes, this.modelDefaults.ordering_attribute) || `(No ${this.modelDefaults.ordering_attribute_label})`;
      let internalNotes = SquashNulls(node.attributes, 'internal_note');
      let homeJobFound = false;
      if (internalNotes) {
        for (let internalNoteId in internalNotes) {
          let internalNote = internalNotes[internalNoteId];

          if (internalNote && typeof internalNote == 'string' && internalNote.includes('Node copied from ')) {
            // extract the job name from the internal note
            let jobName = internalNote.split('Node copied from ');
            if (jobName && jobName[1]) {
              // Find the job that matches the name we got from the internal note
              let jobs = await FirebaseWorker.ref(`photoheight/job_permissions/${this.userGroup}/list`)
                .orderByChild('name')
                .equalTo(jobName[1])
                .once('value')
                .then((s) => s.val());
              let homeJobId = Object.keys(jobs || {})[0];
              if (homeJobId) {
                update[`${this.job_id}/nodes/${nodeList[i].key}/_admin_report_data`] = { jobKey: homeJobId, jobName: jobName[1] };
                homeJobFound = true;
                break;
              }
            }
          }
        }
      }
      if (!homeJobFound) {
        //throw warning that no parent job was found for this node
        warnings.nodeWarnings.push({
          key: nodeList[i].key,
          description: ordering_attribute,
          warnings: [
            'No parent job was found for this node. (Check to make sure there is an internal note stating where the node came from)'
          ]
        });
      }
    }

    FirebaseWorker.ref('photoheight/jobs').update(update, (error) => {
      if (error) {
        this.toast(error);
      }
      if (warnings.generalWarnings.length > 0 || warnings.nodeWarnings.length > 0 || warnings.sectionWarnings.length > 0) {
        this.displayWarningsDialog(warnings);
      } else {
        this.toast('Job Ready for Nodes Return');
      }
      this.cancelPromptAction();
    });
  }

  async _button_copy_nodes_from_admin_report(e) {
    // This function is designed to take all of the nodes in a job that were put there
    // from an admin report and copy them back into their original jobs

    // Create a temp list of nodes to copy
    let nodesToCopyByJob = {};
    for (let nodeId in this.nodes) {
      // Check if the node has a job key from the admin report data
      let jobKey = SquashNulls(this.nodes, nodeId, '_admin_report_data', 'jobKey');
      if (jobKey) {
        // Used to mark if the node should be copied
        let shouldCopyNode = true;

        // If the user is davey in a spectrum models job, then only copy
        // the nodes that have the work completed attribute checked
        if (this.userGroup == 'davey_resource_group_inc' && this.jobCreator == 'spectrum_spida_models') {
          let workCompleted = PickAnAttribute(SquashNulls(this.nodes, nodeId, 'attributes'), 'work_completed');
          if (!workCompleted) {
            // If work is not completed, then we should not copy the node
            shouldCopyNode = false;
          }
        }

        if (shouldCopyNode) {
          // Check if there is no list for the given job key
          if (!nodesToCopyByJob[jobKey]) {
            nodesToCopyByJob[jobKey] = [];
          }
          nodesToCopyByJob[jobKey].push({
            nodeId,
            jobName: SquashNulls(this.nodes, nodeId, '_admin_report_data', 'jobName')
          });
        }
      }
    }

    if (Object.values(nodesToCopyByJob).length == 0) {
      this.toast('No admin-exported nodes were found to copy');
    } else {
      // Preserve the job styles of the current job
      this.copyJobStyles = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/map_styles`)
        .once('value')
        .then((s) => s.val());

      // Set settings
      this.copyMoveAction = 'Copy';
      this.copyPhotosToJob = true;
      this.onlyCopyNodesWithFeedback = false;
      this.copyConnsToJob = false;
      this.copyCUsToJob = false;
      this.otherCopyJobNodeKeys = null;
      if (this.config.appName == 'ppl-kws') {
        this.copyConnsToJob = true;
        this.pplMakeSurroundingNodesReferences = true;
        this.copyCUsToJob = true;
      }

      let summaryText = Object.values(nodesToCopyByJob)
        .map((x) => `${x[0].jobName}: ${x.length} node${x.length == 1 ? '' : 's'}`)
        .join('<br />');

      this.confirm(
        'Copy Nodes to Source Jobs',
        `The data and photos for the following nodes will be copied back into their respective source jobs (this will overwrite the data in the source jobs):<br /><br />${summaryText}`,
        'Proceed',
        'Cancel',
        null,
        null,
        async () => {
          for (let jobKey in nodesToCopyByJob) {
            // Set the list of nodes to copy
            this.multiSelectedNodes = nodesToCopyByJob[jobKey].map((x) => x.nodeId);
            // Set the job id to copy to
            this.copyNodesJobId = jobKey;
            // Call to copy nodes
            await this.copyNodesToJob();
          }
        }
      );
    }
    this.cancelPromptAction();
  }

  _button_copy_nodes(e) {
    this.copyNodesJobId = this.copyNodesJobId || '';
    this.copyMoveAction = this.copyMoveAction || 'Copy';
    this.moveActionsWithCopy = this.moveActionsWithCopy ?? false;
    this.buttonSelectedNodes = null;
    this.confirm(
      'Copy Nodes To Another Job',
      null,
      'Choose Nodes...',
      'Cancel',
      '',
      'copyNodes',
      function () {
        if (this.copyNodesJobId != null) {
          this.selectedNode = null;
          this.activeCommand = '_multiSelectItems';
          // Tell the multi select counter which types to show
          this.multiSelectIncludedTypes = {
            nodes: true,
            sections: false,
            connections: true
          };
          this.$.katapultMap.openActionDialog({
            text: 'Click Nodes or Draw a Polygon Around Them to ' + this.copyMoveAction + '. Right click to delete polygon points.',
            buttons: [
              { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
              { title: 'Finish', callback: this.checkCopyWarning.bind(this), attributes: { 'secondary-color': '' } }
            ]
          });
        } else {
          this.cancelPromptAction();
        }
      }.bind(this)
    );
  }

  copyNodesOfType() {
    var selectedTypes = [];
    var selectedTypesCheckboxes = this.$.nodeTypesList.querySelectorAll('paper-checkbox');
    for (var i = 0; i < selectedTypesCheckboxes.length; i++) {
      if (selectedTypesCheckboxes[i].checked == true) {
        selectedTypes.push(selectedTypesCheckboxes[i].dataset.type);
      }
    }
    this.buttonSelectedNodes = [];
    for (var nodeId in this.nodes) {
      if (this.nodes.hasOwnProperty(nodeId)) {
        // Check if the node type is in our selected node types
        var nodeType = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
        if (selectedTypes.indexOf(nodeType) != -1) {
          this.buttonSelectedNodes.push(nodeId);
        }
      }
    }
    this.checkCopyWarning();
  }

  async checkCopyWarning() {
    if (this.copyNodesJobId != null && this.copyNodesJobId != '') {
      this.multiSelectedNodes = [];
      this.multiSelectedNodes = this.buttonSelectedNodes || this.$.katapultMap.multiSelectedNodes;

      this.copyFoundMatchingNodes = false;
      this.copyFoundDifferentStyles = false;

      // get styles of the job that we're moving nodes to
      this.copyJobStyles = await FirebaseWorker.ref(`photoheight/jobs/${this.copyNodesJobId}/map_styles`)
        .once('value')
        .then((s) => s.val());

      var connLookup = {};
      for (var connId in this.connections) {
        connLookup[this.connections[connId].node_id_1] = connLookup[this.connections[connId].node_id_1] || [];
        connLookup[this.connections[connId].node_id_1].push({ connId, idNumber: 1 });
        connLookup[this.connections[connId].node_id_2] = connLookup[this.connections[connId].node_id_2] || [];
        connLookup[this.connections[connId].node_id_2].push({ connId, idNumber: 2 });
      }

      var nodeConnections = {};
      let differingNodes = 0;
      let differingConns = 0;
      let differingSections = 0;
      // This loop checks the current styles of all the items against the styles of the job that we're copying too
      // If there are any differences, then we increment the relevant counter
      for (var i = 0; i < this.multiSelectedNodes.length; i++) {
        var nodeId = this.multiSelectedNodes[i];
        // find differences in node styles
        let oldNodeStyles = JSON.stringify(GeofireTools._getItemGeoStyle('nodes', this.nodes[nodeId], this.jobStyles, nodeId));
        let newNodeStyles = JSON.stringify(GeofireTools._getItemGeoStyle('nodes', this.nodes[nodeId], this.copyJobStyles, nodeId));
        if (oldNodeStyles != newNodeStyles) differingNodes++;

        if (connLookup[nodeId] != null) {
          if (this.copyConnsToJob) {
            for (var j = 0; j < connLookup[nodeId].length; j++) {
              var connId = connLookup[nodeId][j].connId;
              // find differences in connection styles
              let oldConnStyles = JSON.stringify(GeofireTools._getItemGeoStyle('connections', this.connections[connId], this.jobStyles));
              let newConnStyles = JSON.stringify(
                GeofireTools._getItemGeoStyle('connections', this.connections[connId], this.copyJobStyles, connId)
              );
              if (oldConnStyles != newConnStyles) differingConns++;

              for (var sectionId in this.connections[connId].sections) {
                // find differences in section styles
                let oldSectionStyles = JSON.stringify(
                  GeofireTools._getItemGeoStyle('sections', this.connections[connId].sections[sectionId], this.jobStyles, sectionId)
                );
                let newSectionStyles = JSON.stringify(
                  GeofireTools._getItemGeoStyle('sections', this.connections[connId].sections[sectionId], this.copyJobStyles, sectionId)
                );
                if (oldSectionStyles != newSectionStyles) differingSections++;
              }
            }
          }
        }
      }

      // Builds the string that is displayed on the warning dialog
      if (differingNodes || differingConns || differingSections) {
        this.copyFoundDifferentStyles = true;
        let infoString = [];
        if (differingNodes) infoString.push(`${differingNodes} nodes`);
        if (differingConns) infoString.push(`${differingConns} connections`);
        if (differingSections) infoString.push(`${differingSections} sections`);
        this.copyingStyleError = infoString.join(', ');
      }

      FirebaseWorker.ref('photoheight/jobs/' + this.copyNodesJobId + '/name').once(
        'value',
        function (nameSnapshot) {
          // Set the job name we are copying to
          this.copyingToJobName = nameSnapshot.val();
          FirebaseWorker.ref('photoheight/jobs/' + this.copyNodesJobId + '/nodes').once(
            'value',
            function (nodesSnapshot) {
              var jobNodeData = nodesSnapshot.val();
              var foundMatchingNodes = false;
              if (jobNodeData != null) {
                this.otherCopyJobNodeKeys = Object.keys(jobNodeData);
                for (var i = 0; i < this.multiSelectedNodes.length; i++) {
                  if (this.otherCopyJobNodeKeys.indexOf(this.multiSelectedNodes[i]) != -1) {
                    this.copyFoundMatchingNodes = true;
                    foundMatchingNodes = true;
                    break;
                  }
                }
              }
              if (this.copyFoundMatchingNodes || this.copyFoundDifferentStyles) {
                this.$.checkCopyWarningDialog.open();
              }
              // Copy the nodes if we didn't have anything to warn about
              else this.copyNodesToJob();
            }.bind(this)
          );
        }.bind(this)
      );
    } else {
      this.toast('No Job Selected to Copy To');
      this.cancelPromptAction();
    }
  }

  copyAllNodes() {
    this.buttonSelectedNodes = Object.keys(this.nodes);
    this.checkCopyWarning();
  }

  appHasPortal() {
    const appName = this.config.appName;
    const appConfig = this.config?.firebaseConfigs[appName];
    const deployedPages = appConfig?.deployed_pages;
    return deployedPages && deployedPages.includes('pole-application');
  }

  async copyNodesToJob() {
    // create a copy of the nodes so that if they are moved, they are still accessible
    const nodesCopy = structuredClone(this.nodes);
    if (this.copyNodesJobId == null || this.copyNodesJobId == '') {
      this.toast('No Job Selected to Copy To');
      this.cancelPromptAction();
    } else {
      const toJobId = this.copyNodesJobId;
      if (this.copyPhotosToJob) {
        var photoSummary = await FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photo_summary')
          .once('value')
          .then((s) => s.val());
        let photoFolders = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photo_folders`)
          .once('value')
          .then((s) => (s.exists() ? s.val() : {}));
        let destinationPhotoFolders = await FirebaseWorker.ref(`photoheight/jobs/${this.copyNodesJobId}/photo_folders`)
          .once('value')
          .then((s) => (s.exists() ? s.val() : {}));
        await this.doCopyNodes(photoSummary, photoFolders, destinationPhotoFolders);
      } else {
        await this.doCopyNodes();
      }
      if (this.addPolesToApp) {
        this.mappedPoles = [];
        for (const nodeId of this.buttonSelectedNodes) {
          const nodeType = PickAnAttribute(nodesCopy[nodeId].attributes, this.modelDefaults.node_type_attribute);
          const isPole = this.modelDefaults.pole_node_types.includes(nodeType);
          if (!isPole) continue;
          const tagInfo = await findPoleTagMappingInfo(nodeId, nodesCopy, this.config.firebaseData.utilityCompany);
          this.mappedPoles.push(tagInfo);
        }
        openAddPoleToAppDialog(this.mappedPoles, toJobId, this.config.firebaseData.utilityCompany, this.userGroup, this.otherAttributes);
      }
    }
  }

  async doCopyNodes(photoSummary, photoFolders, destinationPhotoFolders) {
    // TODO (2022-12-13): Remove the for loop below once all instances of ToArray.js being used on this.nodes have been converted to use Object.entries()
    for (const nodeId in this.nodes) {
      delete this.nodes[nodeId]['$key'];
    }

    let currentLayers = loadRenderMap.layers;
    if (this.copyMoveAction == 'Move') {
      loadRenderMap.layers = {};
      this.$.katapultMap.cancel();
    }
    let paths = ['photos'];
    if (this.copyCUsToJob) paths.push('compatible_units');
    let data = await GetJobData(this.job_id, paths);
    var connLookup = {};
    for (var connId in this.connections) {
      connLookup[this.connections[connId].node_id_1] = connLookup[this.connections[connId].node_id_1] || [];
      connLookup[this.connections[connId].node_id_1].push({ connId, idNumber: 1 });
      connLookup[this.connections[connId].node_id_2] = connLookup[this.connections[connId].node_id_2] || [];
      connLookup[this.connections[connId].node_id_2].push({ connId, idNumber: 2 });
    }

    const generalJobUpdate = {};

    let addFolderAndCamera = (photo) => {
      let folderExists = false;
      let cameraExists = false;
      // See if folder and camera already exist.
      root: for (let tempFolderId in destinationPhotoFolders) {
        if (photo.folder_id && tempFolderId === photo.folder_id) {
          folderExists = true;
          for (let tempCameraId in destinationPhotoFolders[tempFolderId].cameras) {
            if (tempCameraId === photo.camera_id) {
              cameraExists = true;
              break root;
            }
          }
          break;
        }
      }
      if (folderExists) {
        // Folder exists on destination job.
        if (
          !cameraExists &&
          generalJobUpdate[`${this.copyNodesJobId}/photo_folders/${photo.folder_id}/cameras/${photo.camera_id}`] == null
        ) {
          let camera = Path.get(photoFolders, `${photo.folder_id}.cameras.${photo.camera_id}`);
          if (!camera) camera = { date_created: firebase.database.ServerValue.TIMESTAMP };
          generalJobUpdate[`${this.copyNodesJobId}/photo_folders/${photo.folder_id}/cameras/${photo.camera_id}`] = camera;
        }
      } else if (generalJobUpdate[`${this.copyNodesJobId}/photo_folders/${photo.folder_id}`] != null) {
        // Folder exists in update statement.
        let folder = generalJobUpdate[`${this.copyNodesJobId}/photo_folders/${photo.folder_id}`];
        if (!folder)
          folder = {
            cameras: {},
            date_created: firebase.database.ServerValue.TIMESTAMP,
            done: false
          };
        if (!Object.keys(folder.cameras).includes(photo.camera_id)) {
          let camera = Path.get(photoFolders, `${photo.folder_id}.cameras.${photo.camera_id}`);
          if (!camera) camera = { date_created: firebase.database.ServerValue.TIMESTAMP };
          folder.cameras[photo.camera_id] = camera;
        }
      } else {
        // Folder doesn't exist at all yet.
        if (photo.folder_id) {
          let folder = photoFolders[photo.folder_id];
          if (!folder)
            folder = {
              cameras: {},
              date_created: firebase.database.ServerValue.TIMESTAMP,
              done: false
            };
          folder.job_id = this.copyNodesJobId;
          folder.cameras = {};
          let camera = folder.cameras[photo.camera_id];
          if (!camera) camera = { date_created: firebase.database.ServerValue.TIMESTAMP };
          folder.cameras[photo.camera_id] = camera;
          generalJobUpdate[`${this.copyNodesJobId}/photo_folders/${photo.folder_id}`] = folder;
        }
      }
    };

    // open a progress dialog
    this.progressText =
      this.copyMoveAction == 'Move'
        ? 'Moving all selected itmes... Note: Layers will be re-enabled when moving is complete.'
        : 'Copying all selected items...';
    this.progressPercent = 0;
    this.$.progressToast.open();

    // create a copies of data if they're being moved so they can be referenced after they are deleted
    const jobConnections = this.copyMoveAction == 'Move' ? structuredClone(this.connections) : this.connections;
    const jobNodes = this.copyMoveAction == 'Move' ? structuredClone(this.nodes) : this.nodes;
    const jobTraces = this.copyMoveAction == 'Move' ? structuredClone(this.traces) : this.traces;
    const jobTraceItems = this.copyMoveAction == 'Move' ? structuredClone(this.traceItems) : this.traceItems;
    // create an object to store the full nodes update so it can be referenced across chunks
    let fullUpdateForReference = {};

    // chunk the nodes into groups that have a size that's defined by the ITEM_UPDATE_LIMIT
    const nodeChunks = Chunk(this.multiSelectedNodes, ITEM_UPDATE_LIMIT);

    // Loop the chunks to copy the nodes
    for (let chunkIndex = 0; chunkIndex < nodeChunks.length; chunkIndex++) {
      const nodeChunk = nodeChunks[chunkIndex];
      // create update objects for chunks of nodes
      const nodesUpdateChunk = {};
      const nodePropertiesUpdateChunk = {};

      // loop over the nodes in the chunk
      for (var i = 0; i < nodeChunk.length; i++) {
        // start building the node update based on the current options
        const nodeId = nodeChunk[i];
        if (!this.onlyCopyNodesWithFeedback || (jobNodes[nodeId].feedback != null && jobNodes[nodeId].feedback != '')) {
          nodesUpdateChunk[this.copyNodesJobId + '/nodes/' + nodeId] = jobNodes[nodeId];
          var nodeConnections = {};
          if (this.copyMoveAction == 'Move') {
            nodesUpdateChunk[this.job_id + '/nodes/' + nodeId] = null;
            nodesUpdateChunk[this.job_id + '/geohash/' + nodeId] = null;
          }
          // check to see if a 'Node copied from...' internal note already exists and if it does, update that property, otherwise update the auto_note
          let internalNoteUpdateProperty = 'auto_note';
          let internalNotes = SquashNulls(jobNodes[nodeId], 'attributes', 'internal_note');
          for (let internalNoteId in internalNotes) {
            let internalNote = internalNotes[internalNoteId];
            if (internalNote && typeof internalNote == 'string' && internalNote.includes('Node copied from ')) {
              internalNoteUpdateProperty = internalNoteId;
            }
          }
          nodePropertiesUpdateChunk[this.copyNodesJobId + '/nodes/' + nodeId + '/attributes/internal_note/' + internalNoteUpdateProperty] =
            'Node copied from ' + this.jobName;
          // Check if there are connections for the current nodeId
          if (connLookup[nodeId] != null) {
            // Check if we should also copy connections
            if (this.copyConnsToJob) {
              for (var j = 0; j < connLookup[nodeId].length; j++) {
                var connId = connLookup[nodeId][j].connId;
                nodeConnections[connId] = connLookup[nodeId][j].idNumber;
                nodesUpdateChunk[this.copyNodesJobId + '/connections/' + connId] = jobConnections[connId];
                var l1 = [jobNodes[jobConnections[connId].node_id_1].latitude, jobNodes[jobConnections[connId].node_id_1].longitude];
                var l2 = [jobNodes[jobConnections[connId].node_id_2].latitude, jobNodes[jobConnections[connId].node_id_2].longitude];
                // Set geoHash in the NEW job for the connections we're moving with the styles from the NEW job.
                GeofireTools.setGeohash('connections', jobConnections[connId], connId, this.copyJobStyles, nodesUpdateChunk, {
                  location1: l1,
                  location2: l2,
                  geoPath: this.copyNodesJobId + '/geohash/'
                });
                if (this.copyMoveAction == 'Move') {
                  nodesUpdateChunk[this.job_id + '/connections/' + connId] = null;
                  nodesUpdateChunk[this.job_id + '/geohash/' + connId + '~1'] = null;
                  nodesUpdateChunk[this.job_id + '/geohash/' + connId + '~2'] = null;
                }
                for (var sectionId in jobConnections[connId].sections) {
                  // Set geoHash in the NEW job for the sections we're moving with the styles from the NEW job.
                  GeofireTools.setGeohash(
                    'sections',
                    jobConnections[connId].sections[sectionId],
                    connId,
                    this.copyJobStyles,
                    nodesUpdateChunk,
                    {
                      sectionId,
                      geoPath: this.copyNodesJobId + '/geohash/'
                    }
                  );
                  if (this.copyMoveAction == 'Move') {
                    nodesUpdateChunk[this.job_id + '/geohash/' + connId + ':' + sectionId] = null;
                  }
                }
                var connectedNode;
                var connectedNodeNumber;
                if (jobConnections[connId].node_id_1 == nodeId) {
                  connectedNode = jobConnections[connId].node_id_2;
                  connectedNodeNumber = 2;
                } else {
                  connectedNode = jobConnections[connId].node_id_1;
                  connectedNodeNumber = 1;
                }
                if (
                  fullUpdateForReference[this.copyNodesJobId + '/nodes/' + connectedNode] == null &&
                  (this.otherCopyJobNodeKeys == null || this.otherCopyJobNodeKeys.indexOf(connectedNode) == -1)
                ) {
                  // special case for when using _button_copy_nodes_from_admin_report and on ppl-kws
                  if (this.pplMakeSurroundingNodesReferences) {
                    let nodeParentJobId = SquashNulls(jobNodes, nodeId, '_admin_report_data', 'jobKey');
                    let connectedNodeType = PickAnAttribute(jobNodes[connectedNode].attributes, this.modelDefaults.node_type_attribute);
                    if (
                      nodeParentJobId &&
                      this.copyNodesJobId == nodeParentJobId &&
                      this.modelDefaults.pole_node_types.includes(connectedNodeType)
                    ) {
                      let cleanedConnectedNode = JSON.parse(JSON.stringify(jobNodes[connectedNode]));
                      cleanedConnectedNode.attributes = {};
                      cleanedConnectedNode.attributes[this.modelDefaults.node_type_attribute] = { button_added: 'reference' };
                      cleanedConnectedNode.attributes[this.modelDefaults.ordering_attribute] = PickAnAttribute(
                        jobNodes[connectedNode].attributes,
                        this.modelDefaults.ordering_attribute
                      )
                        ? { button_added: PickAnAttribute(jobNodes[connectedNode].attributes, this.modelDefaults.ordering_attribute) }
                        : null;
                      cleanedConnectedNode.attributes.feeder = SquashNulls(jobNodes, connectedNode, 'attributes', 'feeder') || null;
                      cleanedConnectedNode.attributes.internal_note =
                        SquashNulls(jobNodes, connectedNode, 'attributes', 'internal_note') || null;
                      nodesUpdateChunk[this.copyNodesJobId + '/nodes/' + connectedNode] = cleanedConnectedNode;
                      // Set geoHash for in the NEW job the connected nodes we're moving with the styles from the NEW job.
                      GeofireTools.setGeohash('nodes', cleanedConnectedNode, connectedNode, this.copyJobStyles, nodesUpdateChunk, {
                        nodeConnections: { [connId]: connectedNodeNumber },
                        geoPath: this.copyNodesJobId + '/geohash/'
                      });

                      let cleanedConnection = JSON.parse(JSON.stringify(jobConnections[connId]));
                      nodesUpdateChunk[this.copyNodesJobId + '/connections/' + connId] = cleanedConnection;
                      // Set geoHash in the NEW job for the connections we're moving with the styles from the NEW job.
                      GeofireTools.setGeohash('connections', cleanedConnection, connId, this.copyJobStyles, nodesUpdateChunk, {
                        location1: l1,
                        location2: l2,
                        geoPath: this.copyNodesJobId + '/geohash/'
                      });
                    } else {
                      nodesUpdateChunk[this.copyNodesJobId + '/nodes/' + connectedNode] = jobNodes[connectedNode];
                      // Set geoHash for in the NEW job the connected nodes we're moving with the styles from the NEW job.
                      GeofireTools.setGeohash('nodes', jobNodes[connectedNode], connectedNode, this.copyJobStyles, nodesUpdateChunk, {
                        nodeConnections: { [connId]: connectedNodeNumber },
                        geoPath: this.copyNodesJobId + '/geohash/'
                      });
                    }
                  } else {
                    nodesUpdateChunk[this.copyNodesJobId + '/nodes/' + connectedNode] = jobNodes[connectedNode];
                    // Set geoHash for in the NEW job the connected nodes we're moving with the styles from the NEW job.
                    GeofireTools.setGeohash('nodes', jobNodes[connectedNode], connectedNode, this.copyJobStyles, nodesUpdateChunk, {
                      nodeConnections: { [connId]: connectedNodeNumber },
                      geoPath: this.copyNodesJobId + '/geohash/'
                    });
                  }
                }
                if (this.copyPhotosToJob) {
                  // Copy Connected Node Photos
                  if (this.otherCopyJobNodeKeys == null || this.otherCopyJobNodeKeys.indexOf(connectedNode) == -1) {
                    for (var photoId in jobNodes[connectedNode].photos) {
                      nodesUpdateChunk[this.copyNodesJobId + '/photos/' + photoId] = data.photos[photoId];
                      nodesUpdateChunk[this.copyNodesJobId + '/photo_summary/' + photoId] = photoSummary[photoId];
                      addFolderAndCamera(data.photos[photoId]);
                      TraverseMarkers(data.photos[photoId].photofirst_data, (child, path, childProperty, childItemKey) => {
                        var traceId = child._trace;
                        if (traceId && SquashNulls(jobTraces, traceId)) {
                          nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_data/' + traceId] = jobTraces[traceId] || null;
                          nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_items/' + traceId] = jobTraceItems[traceId] || null;
                        }
                      });
                    }
                  }
                  //Copy Conn Photos
                  for (var photoId in jobConnections[connId].photos) {
                    nodesUpdateChunk[this.copyNodesJobId + '/photos/' + photoId] = data.photos[photoId];
                    nodesUpdateChunk[this.copyNodesJobId + '/photo_summary/' + photoId] = photoSummary[photoId];
                    addFolderAndCamera(data.photos[photoId]);
                    if (this.copyMoveAction == 'Move') {
                      // why would it overwrite itself? Probably used to be set to null, but shouldt be if it's associated elsewhere
                      nodesUpdateChunk[this.job_id + '/photos/' + photoId] = data.photos[photoId];
                    }
                    TraverseMarkers(data.photos[photoId].photofirst_data, (child, path, childProperty, childItemKey) => {
                      var traceId = child._trace;
                      if (traceId && SquashNulls(jobTraces, traceId)) {
                        nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_data/' + traceId] = jobTraces[traceId] || null;
                        nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_items/' + traceId] = jobTraceItems[traceId] || null;
                        if (this.copyMoveAction == 'Move') {
                          nodesUpdateChunk[this.job_id + '/traces/trace_items/' + traceId + '/' + photoId] = null;
                        }
                      }
                    });
                  }
                  // Copy Section Photos
                  for (var sectionId in jobConnections[connId].sections) {
                    for (var photoId in jobConnections[connId].sections[sectionId].photos) {
                      nodesUpdateChunk[this.copyNodesJobId + '/photos/' + photoId] = data.photos[photoId];
                      nodesUpdateChunk[this.copyNodesJobId + '/photo_summary/' + photoId] = photoSummary[photoId];
                      addFolderAndCamera(data.photos[photoId]);
                      if (this.copyMoveAction == 'Move') {
                        nodesUpdateChunk[this.job_id + '/photos/' + photoId] = null;
                      }
                      if (data.photos[photoId]) {
                        TraverseMarkers(data.photos[photoId].photofirst_data, (child, path, childProperty, childItemKey) => {
                          var traceId = child._trace;
                          if (traceId && SquashNulls(jobTraces, traceId)) {
                            nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_data/' + traceId] = jobTraces[traceId] || null;
                            nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_items/' + traceId] = jobTraceItems[traceId] || null;
                            if (this.copyMoveAction == 'Move') {
                              nodesUpdateChunk[this.job_id + '/traces/trace_items/' + traceId + '/' + photoId] = null;
                            }
                          }
                        });
                      }
                    }
                  }
                } else {
                  if (this.otherCopyJobNodeKeys == null || this.otherCopyJobNodeKeys.indexOf(connectedNode) == -1) {
                    nodePropertiesUpdateChunk[this.copyNodesJobId + '/nodes/' + connectedNode + '/photos'] = null;
                  }
                  nodePropertiesUpdateChunk[this.copyNodesJobId + '/connections/' + connId + '/photos'] = null;
                  for (var sectionId in jobConnections[connId].sections) {
                    nodePropertiesUpdateChunk[this.copyNodesJobId + '/connections/' + connId + '/sections/' + sectionId + '/photos'] = null;
                  }
                }
              }
            } else {
              // If we shouldn't copy connections to the job, then check if
              // we are cutting out the nodes, and if so, delete the
              // connections to the node being cut
              if (this.copyMoveAction == 'Move') {
                // Remove the connection and the geohash for the connection
                for (var j = 0; j < connLookup[nodeId].length; j++) {
                  var connId = connLookup[nodeId][j].connId;
                  nodesUpdateChunk[this.job_id + '/connections/' + connId] = null;
                  nodesUpdateChunk[this.job_id + '/geohash/' + connId + '~1'] = null;
                  nodesUpdateChunk[this.job_id + '/geohash/' + connId + '~2'] = null;
                  // Also remove the geohash for any sections on the connection
                  for (var sectionId in jobConnections[connId].sections) {
                    nodesUpdateChunk[this.job_id + '/geohash/' + connId + ':' + sectionId] = null;
                  }
                }
              }
            }
          }
          if (this.copyPhotosToJob) {
            // temporarily add this flag so that we can avoid the firebase rule that prevents the upload page bug
            // ultimately the upload bug should be fixed and this removed
            nodesUpdateChunk[`${this.copyNodesJobId}/copy_override_photos`] = true;
            // Copy Node Photos
            for (var photoId in jobNodes[nodeId].photos) {
              nodesUpdateChunk[this.copyNodesJobId + '/photos/' + photoId] = data.photos[photoId];
              nodesUpdateChunk[this.copyNodesJobId + '/photo_summary/' + photoId] = photoSummary[photoId];
              addFolderAndCamera(data.photos[photoId]);
              if (this.copyMoveAction == 'Move') {
                nodesUpdateChunk[this.job_id + '/photos/' + photoId] = null;
                nodesUpdateChunk[this.job_id + '/photo_summary/' + photoId] = null;
              }
              TraverseMarkers(data.photos[photoId].photofirst_data, (child, path, childProperty, childItemKey) => {
                var traceId = child._trace;
                if (traceId && SquashNulls(jobTraces, traceId)) {
                  nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_data/' + traceId] = jobTraces[traceId] || null;
                  nodesUpdateChunk[this.copyNodesJobId + '/traces/trace_items/' + traceId] = jobTraceItems[traceId] || null;
                  if (this.copyMoveAction == 'Move') {
                    nodesUpdateChunk[this.job_id + '/traces/trace_items/' + traceId + '/' + photoId] = null;
                  }
                }
              });
            }
          } else {
            nodePropertiesUpdateChunk[this.copyNodesJobId + '/nodes/' + nodeId + '/photos'] = null;
          }
          if (this.copyCUsToJob) {
            if (SquashNulls(data, 'compatible_units', nodeId)) {
              nodesUpdateChunk[this.copyNodesJobId + '/compatible_units/' + nodeId] = data.compatible_units[nodeId];
              if (this.copyMoveAction == 'Move') {
                nodesUpdateChunk[this.job_id + '/compatible_units/' + nodeId] = null;
              }
            }
          }

          // Set geoHash in the NEW job for the nodes we're moving with the styles from the NEW job.
          GeofireTools.setGeohash('nodes', jobNodes[nodeId], nodeId, this.copyJobStyles, nodesUpdateChunk, {
            nodeConnections,
            geoPath: this.copyNodesJobId + '/geohash/'
          });

          // Set the progress percent for the operation
          this.progressPercent = (((i + 1) * (chunkIndex + 1)) / this.multiSelectedNodes.length) * 100;
        }
      }

      // Commit the chunk to the database
      try {
        await FirebaseWorker.ref('photoheight/jobs/', { noTracking: true }).update(nodesUpdateChunk);
        await FirebaseWorker.ref('photoheight/jobs/', { noTracking: true }).update(nodePropertiesUpdateChunk);

        fullUpdateForReference = { ...fullUpdateForReference, ...nodesUpdateChunk };
      } catch (error) {
        this.toast(error);
      }
    }

    // Close the progress bar and show a toast to show to notify the user that job data is now being updated
    this.$.progressToast.close();
    this.toast('Updating affected jobs...');

    try {
      // remove the override flag
      generalJobUpdate[`${this.copyNodesJobId}/copy_override_photos`] = null;
      // Commit the general job update
      await FirebaseWorker.ref('photoheight/jobs/', { noTracking: true }).update(generalJobUpdate);
      // Prepare counter updates and wait for them all to finish
      let counterUpdates = [];
      let counterUpdate = {};
      counterUpdates.push(
        CollectionSetTools.updateJobCounters({ jobId: this.job_id }).then((update) => Object.assign(counterUpdate, update))
      );
      counterUpdates.push(
        CollectionSetTools.updateJobCounters({ jobId: this.copyNodesJobId }).then((update) => Object.assign(counterUpdate, update))
      );
      counterUpdates.push(
        CollectionSetTools.updateJobLastUpload({ jobId: this.job_id }).then((update) => Object.assign(counterUpdate, update))
      );
      counterUpdates.push(
        CollectionSetTools.updateJobLastUpload({ jobId: this.copyNodesJobId }).then((update) => Object.assign(counterUpdate, update))
      );
      await Promise.all(counterUpdates);
      for (let path in counterUpdate) if (typeof counterUpdate[path] === 'undefined') delete counterUpdate[path];
      // Commit the counter updates
      await FirebaseWorker.ref().update(counterUpdate);
      // If desired, move any actions and their associated feedback to the other job
      if (this.moveActionsWithCopy || this.copyMoveAction == 'Move') {
        // Show a toast to show to notify the user that we're moving attribute history
        this.toast('Updating action tracking...');

        let queryResult = await firebase
          .firestore()
          .collection(`companies/${this.userGroup}/action_tracking`)
          .where('job_id', '==', this.job_id)
          .get();
        let jobActions = queryResult.docs.map((doc) => ({ ...doc.data(), $ref: doc.ref }));
        let actionsToMove = jobActions.filter((a) => this.multiSelectedNodes.includes(a.node_id));
        let copyNodesJobName = await FirebaseWorker.ref(`photoheight/jobs/${this.copyNodesJobId}/name`)
          .once('value')
          .then((s) => s.val());
        await Promise.all([
          ...actionsToMove.map((a) => a.$ref.update({ job_id: this.copyNodesJobId })),
          ...actionsToMove.map((a) => a.feedback_ref?.update({ job_id: this.copyNodesJobId, job_name: copyNodesJobName }))
        ]);
      }
      // Clean up UI
      if (this.copyMoveAction == 'Move') loadRenderMap.layers = currentLayers;
      // Toast our success
      this.toast((this.copyMoveAction == 'Move' ? 'Moving' : 'Copying') + ' Complete');
      this.copyNodesJobId = null;
      this.cancelPromptAction();
    } catch (error) {
      this.toast(error);
    }
    this.selectedNode = null;
  }

  openNodeTypeSelectDialog(eventOrOptions) {
    const actionType = eventOrOptions?.target?.dataset?.type ?? eventOrOptions?.actionType;
    if (!actionType) throw new Error('Could not determine action type');

    // Clear flags.
    this.nodeTypeSelectDialogAddAttributes = false;
    this.nodeTypeSelectDialogCopyNodes = false;
    this.nodeTypeSelectDialogAddressData = false;
    this.nodeTypeSelectDialogAddressDataFilter = false;
    this.nodeTypeSelectDialogHideCancelButton = false;

    switch (actionType) {
      case 'multiAddAttributes': {
        var list = [];
        if (this.multiAddAttributeItemsToEdit.nodes) list = this.job_node_types.slice(0);
        if (this.multiAddAttributeItemsToEdit.connections) {
          var connTypes = {};
          for (var connId in this.connections) {
            connTypes[PickAnAttribute(this.connections[connId].attributes, this.modelDefaults.connection_type_attribute)] = true;
          }
          for (var type in connTypes) {
            if (type === '' || type == 'null') list.push('(no type)');
            else if (type) list.push(type);
          }
        }
        if (this.multiAddAttributeItemsToEdit.sections) {
          list.push('midpoint section', 'other section');
        }
        this.itemTypeSelectDialogList = list;
        this.nodeTypeSelectDialogAddAttributes = true;
        break;
      }

      case 'copyNodes': {
        this.itemTypeSelectDialogList = this.job_node_types;
        this.nodeTypeSelectDialogCopyNodes = true;
        break;
      }

      case 'addressData': {
        this.itemTypeSelectDialogList = this.job_node_types;
        this.nodeTypeSelectDialogAddressData = true;
        break;
      }

      case 'addressDataFilter': {
        this.itemTypeSelectDialogList = this.job_node_types;
        this.nodeTypeSelectDialogAddressDataFilter = true;
        this.nodeTypeSelectDialogHideCancelButton = true;
        // Get the dom-repeat for the list.
        const listDomRepeat = this.$.nodeTypesList.querySelector('dom-repeat');
        // Attach a one-off change listener to it to set default values.
        listDomRepeat.addEventListener(
          'dom-change',
          (e) => {
            const poleNodeTypes = this.modelDefaults.pole_node_types;
            const referenceNodeTypes = this.modelDefaults.reference_node_types;
            let defaultChecks = [...poleNodeTypes, ...referenceNodeTypes]; // Is this where the default checked names should be?
            const checkboxes = this.$.nodeTypesList.querySelectorAll('#nodeTypesList paper-checkbox');
            for (const box of checkboxes) {
              box.checked = defaultChecks.includes(box.dataset.type);
            }
          },
          { once: true }
        );
        // Force it to render so the above event is guaranteed to fire.
        listDomRepeat.render();
        break;
      }
    }
    this.$.nodeTypeSelectDialog.open();
  }

  multiEditAttributesUpdateComposer(options, update) {
    let pathToAttributes, itemAttributes, itemType, itemId, item;
    switch (options?.type) {
      case 'nodes': {
        pathToAttributes = `nodes/${options.nodeId}/attributes`;
        itemAttributes = this.nodes?.[options.nodeId]?.attributes;
        itemType = 'node';
        itemId = options.nodeId;
        item = this.nodes?.[options.nodeId];
        break;
      }
      case 'connections': {
        pathToAttributes = `connections/${options.connId}/attributes`;
        itemAttributes = this.connections?.[options.connId]?.attributes;
        itemType = 'connection';
        itemId = options.connId;
        item = this.connections?.[options.connId];
        break;
      }
      case 'sections': {
        pathToAttributes = `connections/${options.connId}/sections/${options.sectionId}/multi_attributes`;
        itemAttributes = this.connections?.[options.connId]?.sections?.[options.sectionId]?.multi_attributes;
        itemType = 'section';
        itemId = `${options.connId}:${options.sectionId}`;
        item = this.connections?.[options.connId]?.sections?.[options.sectionId];
        break;
      }
    }

    for (const attribute of this.multiAddAttributes) {
      // Multi-dropdown attributes have a special (problematic) key pattern that needs to be handled differently
      const isMultiDropDown = this.otherAttributes?.[attribute.name]?.gui_element === 'multi_dropdown';

      switch (this.multiAddAttributeOverwrite) {
        case 'add': {
          // Most attributes use push-IDs, multidropdowns handle their own key list (so we omit that from the update path)
          const key = isMultiDropDown ? '' : this.$.nodes.ref.push().key;
          const path = `${pathToAttributes}/${attribute.name}/${key}`;
          // Don't overwite existing multidropdown values with empty string if it already exists
          const existingValue = Path.get(this, path, '/');
          if (isMultiDropDown && !Object.values(attribute.value ?? {}).some((x) => x != '') && existingValue) break;
          // Set the keys to be correct if adding to an existing multidropdown
          if (isMultiDropDown && existingValue) {
            // Get the largest existing key
            let count = Object.keys(existingValue)
              .map((x) => Number(x.replace(/[^0-9]/g, '')))
              .sort()
              .pop();
            // Add the new values to the end of the multidropdown
            for (let itemKey in attribute.value) {
              if (
                !this.otherAttributes?.[attribute.name]?.allow_duplicates &&
                Object.values(existingValue).includes(attribute.value[itemKey])
              )
                continue;
              Path.set(itemAttributes, `${attribute.name}.${key}-k${count.toString().padStart(3, '0')}`, attribute.value[itemKey]);
              update[`${path}-k${(++count).toString().padStart(3, '0')}`] = attribute.value[itemKey];
            }
            // Add the geohash update for the attribute
            Object.assign(update, composeItemGeoStyleUpdate(itemType, itemId, item, { jobStyles: this.jobStyles }));
            break;
          }
          // Otherwise, just add the new value
          Path.set(itemAttributes, `${attribute.name}.${key}`, attribute.value);
          Object.assign(
            update,
            composeItemAttributesUpdate(
              itemType,
              itemId,
              item,
              { [attribute.name]: { [key]: attribute.value } },
              { jobStyles: this.jobStyles }
            )
          );
          break;
        }
        case 'overwrite': {
          // Same issues with multidropdowns as above
          const value = isMultiDropDown ? attribute.value : { multi_added: attribute.value };

          // Prevent the `app_added` pole tag from being overwritten since it should only be updated via the portal
          if (attribute.name == 'pole_tag' && itemAttributes?.[attribute.name]?.app_added) {
            const appAddedValue = itemAttributes[attribute.name].app_added;
            value.app_added = appAddedValue;
          }

          // Create the attribute mutation before updating the item attributes to ensure all other attributes get set to null
          const attributesMutation = createReplacingItemAttributesMutation(itemAttributes, { [attribute.name]: value });
          Path.set(itemAttributes, attribute.name, value);
          Object.assign(update, composeItemAttributesUpdate(itemType, itemId, item, attributesMutation, { jobStyles: this.jobStyles }));
          break;
        }
        case 'delete': {
          // Deleting all instances? Set to null
          if (this.deleteInstance == 'all') {
            // Prevent the `app_added` pole tag from being deleted since it should only be removed via the portal
            if (attribute.name == 'pole_tag' && itemAttributes?.[attribute.name]?.app_added) {
              // Create the attribute mutation before updating the item attributes to ensure all other attributes get set to null
              const attributesMutation = createReplacingItemAttributesMutation(itemAttributes, {
                [attribute.name]: { app_added: itemAttributes?.[attribute.name]?.app_added }
              });
              Path.set(itemAttributes, attribute.name, { app_added: itemAttributes?.[attribute.name]?.app_added });
              Object.assign(update, composeItemAttributesUpdate(itemType, itemId, item, attributesMutation, { jobStyles: this.jobStyles }));
            } else {
              // Create the attribute mutation before updating the item attributes to ensure all other attributes get set to null
              const attributesMutation = createReplacingItemAttributesMutation(itemAttributes, { [attribute.name]: null });
              Path.set(itemAttributes, attribute.name, null);
              Object.assign(update, composeItemAttributesUpdate(itemType, itemId, item, attributesMutation, { jobStyles: this.jobStyles }));
            }
          }
          // TODO (01-27-2023): I'm not sure exactly what this does
          else if (this.deleteInstance == 'instance') {
            const existingAttribute = itemAttributes?.[attribute.name];

            // If the attribute is complicated (see list in `multiAddAttributesChanged`)
            if (this.multiAddAttributeUpdate) {
              for (const instanceKey in existingAttribute) {
                // Prevent the `app_added` pole tag from being deleted since it should only be removed via the portal
                if (attribute.name == 'pole_tag' && instanceKey == 'app_added') continue;

                const existingValue = existingAttribute[instanceKey] || {};
                let count = 0;

                // TODO (01-27-2023): This logic is hard to follow, I left it like it was
                for (let prop in attribute.value) {
                  if (prop in existingValue) {
                    if (DeepEqual(attribute.value[prop], existingValue[prop])) count++;
                    else continue;
                  } else continue;
                  if (count == Object.keys(existingValue).length) {
                    Path.set(itemAttributes, `${attribute.name}.${instanceKey}`, null);
                    Object.assign(
                      update,
                      composeItemAttributesUpdate(
                        itemType,
                        itemId,
                        item,
                        { [attribute.name]: { [instanceKey]: null } },
                        { jobStyles: this.jobStyles }
                      )
                    );
                  }
                }
              }
            }
            // For simple attributes
            else {
              for (const instanceKey in existingAttribute) {
                // Prevent the `app_added` pole tag from being deleted since it should only be removed via the portal
                if (attribute.name == 'pole_tag' && instanceKey == 'app_added') continue;

                const existing = existingAttribute[instanceKey];
                if (existing == attribute.value) {
                  Path.set(itemAttributes, `${attribute.name}.${instanceKey}`, null);
                  Object.assign(
                    update,
                    composeItemAttributesUpdate(
                      itemType,
                      itemId,
                      item,
                      { [attribute.name]: { [instanceKey]: null } },
                      { jobStyles: this.jobStyles }
                    )
                  );
                }
              }
            }
          }
          break;
        }
        case 'update': {
          const existingAttribute = itemAttributes?.[attribute.name];
          // TODO (01-30-2023): Again, weird logic here that I don't understand. Leaving like it is
          let flag = false;
          for (const instanceKey in existingAttribute) {
            // Prevent the `app_added` pole tag from being updated since it should only be updated via the portal
            // We could skip the `app_added` pole tag and update the next one, but I chose to skip the whole attribute if the `app_added` one is first to maintain existing functionality (i.e. don't do something unexpected like updating the second pole tag when the user is expecting the first one to be updated)
            if (attribute.name == 'pole_tag' && instanceKey == 'app_added') break;

            if (flag) continue;

            let existing = existingAttribute[instanceKey];
            for (let prop in attribute.value) {
              if (attribute.value[prop] != '') {
                Path.set(itemAttributes, `${attribute.name}.${instanceKey}.${prop}`, attribute.value[prop]);
                update[`${pathToAttributes}/${attribute.name}/${instanceKey}/${prop}`] = attribute.value[prop];
              } else {
                Path.set(itemAttributes, `${attribute.name}.${instanceKey}.${prop}`, existing[prop]);
                update[`${pathToAttributes}/${attribute.name}/${instanceKey}/${prop}`] = existing[prop];
              }
            }
            // Add the geohash update for the attribute
            Object.assign(update, composeItemGeoStyleUpdate(itemType, itemId, item, { jobStyles: this.jobStyles }));
            flag = true;
          }
          break;
        }
        default: {
          console.warn('Did not get a multi-add action');
        }
      }
    }

    return update;
  }

  _button_set_power_spec(e) {
    // Cancel any current action and clear variables, including
    // the list of multiselected sections
    this.cancelPromptAction();
    // Clear whatever is selected on the map
    this.clearMapSelection();
    // Tell the multi select counter to only show sections
    this.multiSelectIncludedTypes = {
      nodes: false,
      sections: true,
      connections: false
    };
    // Set the active command to multiselect
    this.activeCommand = '_multiSelectItems';
    // Set the prompt for the user
    this.$.katapultMap.openActionDialog({
      title: 'Click sections or draw a polygon around them to include them in setting wire spec. Right click to delete polygon points.',
      buttons: [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Continue', callback: this.continueSettingPowerSpec.bind(this), attributes: { 'secondary-color': '' } },
        { title: 'Select All', callback: this.continueSettingPowerSpec.bind(this, true), attributes: { 'secondary-color': '' } }
      ]
    });
    // Show the multi select count dialog
  }

  continueSettingPowerSpec(selectAll) {
    this.multiSelectedSections = [];
    if (selectAll) {
      for (var connId in this.connections) {
        for (let section in this.connections[connId].sections) {
          this.multiSelectedSections.push(connId + ':' + section);
        }
      }
    } else {
      this.multiSelectedSections = this.$.katapultMap.multiSelectedSections;
    }
    this.createWebWorker('set_power_wire_spec', 'set_power_wire_spec', [
      this.multiSelectedSections,
      this.otherAttributes,
      this.traces,
      this.setPowerSpecData,
      this.connections
    ]);
  }

  confirmSetPowerSpec(e) {
    this.createWebWorker('confirmSetPowerSpec', 'set_power_wire_spec', [
      this.setPowerSpecData,
      this.traces,
      this.setPowerSpecShouldOverwrite
    ]);
  }

  _button_insert_anchor_spec(e) {
    // Get the spec lookup from the button model.
    let buttonModels = this.get(`mappingButtons.${this.activeCommand}.models`);
    // Check if we have a model for the anchor_spec lookup
    if (buttonModels) {
      let anchorSpecLookup = FirebaseEncode.decodeKeys(buttonModels);

      /* TEMPORARY */
      // Check for legacy pipes being used in place of slashes (previous solution to storing slashes in firebase keys).
      let temp = {};
      for (let key in anchorSpecLookup) temp[key.replace(/\|/g, '/')] = anchorSpecLookup[key];
      anchorSpecLookup = temp;
      /* TEMPORARY */

      let defaultAnchorNodeTypes = this.modelDefaults.anchor_node_types;
      let failedUpdates = [];
      let update = {};
      let anchorNodeLookup = {};
      // Loop through every node
      for (let key in this.nodes) {
        // Get any anchors attached to the node
        const attachedAnchors = this.findAttachedAnchors(key);
        // Loop through the anchors and add it to the lookup
        for (let i = 0; i < attachedAnchors.length; i++) {
          anchorNodeLookup[attachedAnchors[i].nodeId] = key;
        }

        // Get the node type
        const type = PickAnAttribute(this.nodes[key].attributes, this.modelDefaults.node_type_attribute);
        // Check to make sure the type is existing anchor
        if (defaultAnchorNodeTypes.includes(type)) {
          // Get the rod size (fallback to size) for the anchor and replace any '/' characters with '|'
          const rodSize = PickAnAttribute(this.nodes[key].attributes, 'rod_size') || PickAnAttribute(this.nodes[key].attributes, 'size');
          // Check to see if we found a matching spec
          const spec = SquashNulls(anchorSpecLookup, rodSize, 'default');
          if (spec) {
            update[`${key}/attributes/${this.modelDefaults.anchor_spec_attribute}`] = { button_added: spec };
          } else {
            failedUpdates.push(key);
          }
        }
      }
      this.$.nodes.ref.update(
        update,
        function (error) {
          if (error) this.toast(error);
          if (failedUpdates.length > 0) {
            let failedOrderingAttributes = [];
            let results = { title: 'Insert Anchor Spec Issues', nodeWarnings: [], generalWarnings: [], sectionWarnings: [] };
            for (let i = 0; i < failedUpdates.length; i++) {
              let attributes = SquashNulls(this.nodes, anchorNodeLookup[failedUpdates[i]], 'attributes');
              const ordering_attribute = PickAnAttribute(attributes, this.modelDefaults.ordering_attribute);
              if (ordering_attribute && failedOrderingAttributes.indexOf(ordering_attribute) == -1) {
                failedOrderingAttributes.push(ordering_attribute);
                results.nodeWarnings.push({
                  key: anchorNodeLookup[failedUpdates[i]],
                  description: ordering_attribute,
                  warnings: ['Could not determine spec for connected anchors.']
                });
              }
            }
            if (failedOrderingAttributes.length > 0) {
              this.displayWarningsDialog(results);
            }
          } else {
            this.toast('Finished inserting anchor spec');
          }
        }.bind(this)
      );
    } else {
      this.toast('Error: Models not properly configured');
    }
    this.cancelPromptAction();
  }

  _button_remove_poles_by_polygon(e) {
    let resetMakeReady = (mainPhotoKey, photo, node, nodeId, update, nodeWarnings) => {
      // Stores the last state of make ready
      let lastMRState = {
        date: firebase.database.ServerValue.TIMESTAMP,
        mr_data: {},
        pole_mr_data: {}
      };

      if (photo && photo.photofirst_data) {
        let markers = DataViews.help.getMarkers(photo.photofirst_data, this.traces, { allowProposed: true, includeAllMarkerTypes: true });
        let makeReadyAttributes = [
          'mr_remove',
          'mr_note',
          'mr_move',
          'mr_ignore',
          'mr_resolved',
          'mr_existing_bolt_hole',
          '_effective_moves',
          'proposed_anchor_id'
        ];

        // Loop through every marker in the photo to get and clear their make ready data
        markers.forEach((marker) => {
          // try to get the marker's top parent context if there is one, if not then use the current marker
          let topParent = SquashNulls(marker, '_marker_context', 'top_parent') || marker;
          if (topParent) {
            let markerContext = topParent._marker_context;
            // check if this marker is a proposed marker
            let markerTrace = this.traces?.[marker?._trace] || '';
            // if the marker is proposed then delete it
            if (markerTrace?.proposed) {
              update[`photos/${mainPhotoKey}/photofirst_data/${markerContext.markerPath}`] = null;
              update[`traces/trace_items/${mainPhotoKey}`] = null;
            } else {
              // Loop through the list of makeReadyAttributes we should check
              makeReadyAttributes.forEach((attributeName) => {
                // Check if the marker has a value for the attribute
                let value = topParent[attributeName];
                if (value && markerContext.type && markerContext.key) {
                  // Save the marker's value for the attribute in the lastMRState object
                  lastMRState.mr_data[`${markerContext.type}:${markerContext.key}:${attributeName}`] = value;
                  // remove the attribute from the marker
                  update[`photos/${mainPhotoKey}/photofirst_data/${markerContext.markerPath}/${attributeName}`] = null;
                }
              });
            }
          }
        });
      }

      if (node?.attributes && nodeId) {
        let attributesToNotChange = ['traffic_control', 'pwr_mr_required', 'customer_directive'];
        // clear the MR on the pole
        for (let attributeName in node.attributes) {
          // Default the new value to null
          let newValue = null;
          // If the attribute is mr_state or mr_category then the value should be blank instead of null. cost_causer should be a custom object.
          if (attributeName == 'mr_state') {
            newValue = '';
          } else if (attributeName == 'mr_category' || attributeName == 'FCC_category') {
            // keep the mr_category and FCC_category attributes the same if After MR Estimate has been selected
            if (this.mrEstimateStatus == 'after') {
              attributesToNotChange.push('mr_category');
              attributesToNotChange.push('FCC_category');
            } else {
              newValue = '';
            }
          } else if (attributeName == 'cost_causer') {
            newValue = {
              cost: '',
              explanation: '',
              companies: {
                [FirebaseWorker.ref().push().key]: { company: '', percentage: '' }
              }
            };
          }
          // if we remove a work location, list the location in the summary
          if (attributeName == 'work_location') {
            let workLocation = PickAnAttribute(node.attributes, 'work_location') || '';
            if (workLocation) {
              if (!nodeWarnings[nodeId]) {
                let orderingAttribute =
                  PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.ordering_attribute) ||
                  `(No ${this.modelDefaults.ordering_attribute_label})`;
                nodeWarnings[nodeId] = {
                  key: nodeId,
                  description: orderingAttribute,
                  warnings: []
                };
              }
              nodeWarnings[nodeId].warnings.push(
                `The Work Location: ${PickAnAttribute(node.attributes, 'work_location')} has been removed from pole`
              );
            }
          }
          // Check if the attribute belongs to the make ready attributes group, exclude customer directive
          if (SquashNulls(this.otherAttributes, attributeName, 'grouping').toLowerCase() == 'make ready') {
            // Grab all of the values for that attribute and store it in lastMRState
            for (let attributeKey in node.attributes[attributeName]) {
              lastMRState.pole_mr_data[`${nodeId}:attributes:${attributeName}:${attributeKey}`] =
                node.attributes[attributeName][attributeKey];
              if (!attributesToNotChange.includes(attributeName)) {
                update[`nodes/${nodeId}/attributes/${attributeName}/${attributeKey}`] = newValue;
                node.attributes[attributeName][attributeKey] = newValue;
              }
            }
          }
        }
        // add customer directive to each pole
        update[`nodes/${nodeId}/attributes/customer_directive`] = { button_added: 'Deselected by Applicant' };
        node.attributes['customer_directive'] = { button_added: 'Deselected by Applicant' };

        // remove all permit attributes
        // get all permit attributes
        let permitAttributes = this.otherAttributes?.permit?.group_items || [];
        for (let permitAttribute of permitAttributes) {
          let attributeName = permitAttribute.attribute || '';
          let nodeValue = node.attributes[attributeName];

          // only delete the attribute if it exists on the node
          if (nodeValue) update[`nodes/${nodeId}/attributes/${attributeName}`] = null;
        }
      }

      // If there is no mr_data or pole_mr_data in lastMRState, then remove it
      if (
        Object.keys(SquashNulls(lastMRState, 'mr_data')).length == 0 &&
        Object.keys(SquashNulls(lastMRState, 'pole_mr_data')).length == 0
      ) {
        lastMRState = null;
      }

      // Set the update for lastMRState and then run the update
      update[`photos/${mainPhotoKey}/photofirst_data/last_mr_state`] = lastMRState;
    };

    let removePolesFromApplication = async () => {
      let multiSelectedNodes = this.$.katapultMap.multiSelectedNodes;
      let multiSelectedSections = this.$.katapultMap.multiSelectedSections;
      let multiSelectedConns = this.$.katapultMap.multiSelectedConnections;
      if (
        multiSelectedNodes.length != 0 ||
        multiSelectedSections.length != 0 ||
        (Object.keys(multiSelectedConns).length != 0 && multiSelectedConns.constructor === Object)
      ) {
        this.$.infoPanel.$.dialogMenu.close();
        this.$.confirmDialog.style.width = '400px';
        let createSnapshotConfirmCallback = (event) => {
          this.mrEstimateStatus = 'before';
          setTimeout(async () => {
            // prompt for either before MR Estimate or after MR Estimate
            this.confirm(
              'Before or After MR estimate?',
              "If you are running this before sending out the MR notification then select 'Before MR Estimate' if after then select 'After MR Estimate'.",
              'Confirm',
              'Cancel',
              'background-color:#d23f31; color:white;',
              'mrEstimateStatus',
              async () => {
                let update = {};
                let nodeWarnings = {};

                // create snapshot
                // skip taking the snapshot if e is undefined and therefore the skip button was pressed
                if (event != undefined) {
                  await this.$.infoPanel.$.snapshots.createSnapshot(this.newSnapshotName, this.newSnapshotNumbers, this).then(() => {
                    setTimeout(() => {
                      this.$.progressToast.open();
                      this.$.progressPercent.indeterminate = true;
                      this.progressText = 'Removing poles from application...';
                    }, 1000);
                  });
                } else {
                  this.$.progressToast.open();
                  this.$.progressPercent.indeterminate = true;
                  this.progressText = 'Removing poles from application...';
                }

                // loop through all selected nodes
                if (multiSelectedNodes) {
                  for (let i = 0; i < multiSelectedNodes.length; i++) {
                    let nodeId = multiSelectedNodes[i];
                    let node = this.nodes[nodeId];
                    if (node) {
                      // if the node is a new anchor, then delete it and its connection
                      let nodeType = PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute);
                      let connectionLookup = GetConnectionLookup(this.nodes, this.connections, this.useMetricUnits);
                      if (this.modelDefaults.proposed_anchor_node_type.includes(nodeType)) {
                        if (connectionLookup[nodeId] && connectionLookup[nodeId].length > 0) {
                          update[`nodes/${nodeId}`] = null;
                          update[`geohash/${nodeId}`] = null;
                          connectionLookup[nodeId].forEach((conn) => {
                            if (conn.connId) {
                              update[`connections/${conn.connId}`] = null;
                              update[`geohash/${conn.connId}~1`] = null;
                              update[`geohash/${conn.connId}~2`] = null;
                            }
                          });
                        }
                      } else if (nodeType == 'replaced anchor') {
                        // if the anchor is a replaced anchor, set the node type to existing anchor
                        update[`nodes/${nodeId}/attributes/${this.modelDefaults.node_type_attribute}`] = {
                          button_added: 'existing anchor'
                        };
                        node.attributes[this.modelDefaults.node_type_attribute] = { button_added: 'existing anchor' };
                        // replace icon when node type is changed
                        GeofireTools.setGeohash('nodes', node, nodeId, this.jobStyles, update, {
                          nodeConnections: GeofireTools.getNodeConnections(nodeId, this.connections)
                        });
                      } else if (this.modelDefaults.pole_node_types.includes(nodeType)) {
                        /*
                        add Deselected by Applicant attribute to the node
                      */
                        update[`nodes/${nodeId}/attributes/deselected_by_applicant`] = {
                          button_added: this.mrEstimateStatus == 'before' ? 'Before MR Estimate' : 'After MR Estimate'
                        };
                        node.attributes['deselected_by_applicant'] = {
                          button_added: this.mrEstimateStatus == 'before' ? 'Before MR Estimate' : 'After MR Estimate'
                        };

                        /*
                        remove node from application
                      */
                        update[`nodes/${nodeId}/attributes/pole_status`] = { app_added: 'Admin Removed' };
                        node.attributes['pole_status'] = { app_added: 'Admin Removed' };

                        // check if there are any permits on the pole that are not "Unnecessary" and if so list them on the summary
                        let permitList = [
                          'PennDOT_permit_status',
                          'sidewalk_cut_permit',
                          'RxR_permit_status',
                          'misc_permit_status',
                          'HOP_permit_status',
                          'trans_undercrossing_permit_status'
                        ];
                        permitList.forEach((permit) => {
                          if (node.attributes[permit] && PickAnAttribute(node.attributes, permit) != 'Unnecessary') {
                            if (!nodeWarnings[nodeId]) {
                              let orderingAttribute =
                                PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.ordering_attribute) ||
                                `(No ${this.modelDefaults.ordering_attribute_label})`;
                              nodeWarnings[nodeId] = {
                                key: nodeId,
                                description: orderingAttribute,
                                warnings: []
                              };
                            }
                            nodeWarnings[nodeId].warnings.push(`${ToTitleCase(permit)} is on removed pole`);
                          }
                        });

                        /*
                        reset all MRE on pole and remove all proposed markers
                      */
                        // Get the main photo for the node
                        let mainPhotoKey = this.getMainPhotoKey(node);
                        let photo = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photos/${mainPhotoKey}`)
                          .once('value')
                          .then((s) => s.val());
                        resetMakeReady(mainPhotoKey, photo, node, nodeId, update, nodeWarnings);
                        // set the geohash for the node
                        GeofireTools.updateStyle('nodes', nodeId, node, update, this.jobStyles);
                      }
                    }
                  }
                }

                // loop through all selected sections
                if (multiSelectedSections) {
                  for (let i = 0; i < multiSelectedSections.length; i++) {
                    let sectionKeys = multiSelectedSections[i].split(':');
                    let connId = sectionKeys[0];
                    let sectionId = sectionKeys[1];
                    let section = SquashNulls(this.connections, connId, 'sections', sectionId);
                    if (section) {
                      /*
                      reset all MRE on pole and remove all proposed markers
                    */
                      // Get the main photo for the node
                      let mainPhotoKey = this.getMainPhotoKey(section);
                      let photo = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/photos/${mainPhotoKey}`)
                        .once('value')
                        .then((s) => s.val());
                      resetMakeReady(mainPhotoKey, photo, section, '', update);
                      // set the geohash for the section
                      GeofireTools.updateStyle('sections', connId, section, update, this.jobStyles, sectionId);
                    }
                  }
                }

                // loop through all selected conns
                if (multiSelectedConns) {
                  for (let connId in multiSelectedConns) {
                    let conn = this.connections[connId];
                    if (conn) {
                      let connType = PickAnAttribute(conn.attributes, this.modelDefaults.connection_type_attribute);
                      if (connType) {
                        // if the connection type is any of these, change it to a reference
                        let connectionTypesToChange = ['aerial cable', 'pole to pole guy', 'overhead guy', 'slack span'];
                        if (connectionTypesToChange.includes(connType)) {
                          update[`connections/${connId}/attributes/${this.modelDefaults.connection_type_attribute}`] = {
                            button_added: 'reference'
                          };
                          update[`connections/${connId}/attributes/reference_type`] = { button_added: 'com reference' };
                          conn.attributes[this.modelDefaults.connection_type_attribute] = { button_added: 'reference' };
                          conn.attributes['reference_type'] = { button_added: 'com reference' };
                          // set the geohash for the connection
                          GeofireTools.updateStyle('connections', connId, conn, update, this.jobStyles);
                        }
                      }
                    }
                  }
                }

                FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`)
                  .update(update)
                  .then(() => {
                    if (Object.values(nodeWarnings).length > 0) {
                      this.displayWarningsDialog({
                        title: 'Remove Poles Summary',
                        generalWarnings: ['Pole App Order numbers may have changed. You may want to re-order the job.'],
                        nodeWarnings: Object.values(nodeWarnings)
                      });
                    }
                    this.toast('Poles Successfully Removed from Application!', null, 6000);
                    // this keeps the little delete, edit, and move popup under nodes from being hidden
                    this.cancelPromptAction();
                  });
              },
              () => {
                setTimeout(() => {
                  this.newSnapshotName = '';
                  this.newSnapshotNumbers = false;
                  this.$.confirmDialog.style.width = '600px';
                }, 500);
              }
            );
          }, 100);
        };
        this.confirm(
          'Create Snapshot',
          'Please give a name to this snapshot',
          'Continue',
          'Cancel',
          '',
          'createSnapshot',
          createSnapshotConfirmCallback,
          () => {
            setTimeout(() => {
              this.newSnapshotName = '';
              this.newSnapshotNumbers = false;
              this.$.confirmDialog.style.width = '600px';
            }, 500);
          },
          [{ text: 'Skip', callback: createSnapshotConfirmCallback }]
        );
      } else {
        this.toast('Nothing has been selected!');
      }
    };

    this.selectedNode = null;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: true,
      connections: true
    };
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around items to remove poles from the application. Right click to delete polygon points.',
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Remove',
          callback: removePolesFromApplication,
          attributes: { style: 'background-color: var(--paper-red-500); color: white; padding: 12px; margin:0 5px' }
        }
      ]
    });
    this.$.katapultMap.countTouchedConnections = true;
  }

  _button_select_items(e) {
    this.selectedNode = null;
    this.activeCommand = '_multiSelectItems';
    // Tell the multi select counter which types to show
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: true,
      connections: true
    };
    this.$.katapultMap.openActionDialog({ title: 'Click nodes or draw a polygon to count items. Right click to delete polygon points.' });
  }
  tallyByPolygon(featureRef) {
    // This function is for a shapefile polygon rather than a user drawn polygon
    const feature = this.getFeatureFromRef(featureRef);
    this.cancelPromptAction();
    this.activeCommand = '_multiSelectImportPolygon';
    // Tell the multi select counter which types to show
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: true,
      connections: true
    };
    let polygon = new google.maps.Polygon();
    feature.getGeometry().forEachLatLng((latLng) => {
      polygon.getPath().push(latLng);
    });
    this.multiSelectImportPolygon = polygon;
  }

  _button_muli_delete(e) {
    this.selectedNode = null;
    let actionDialogText = 'Click nodes or draw a polygon around items to delete them. Right click to delete polygon points.';
    if (e?.polygonType == '_multiSelectImportPolygon') {
      this.activeCommand = e.polygonType;
      actionDialogText = 'All items inside the feature polygon will be included.';
    } else this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: true,
      connections: true
    };
    // Default to deleting nodes
    this.$.katapultMap.multiDeleteItem = this.$.katapultMap.multiDeleteItem || 'nodes';
    this.$.katapultMap.openActionDialog({
      text: actionDialogText,
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Finish',
          callback: this.deleteMultiItems.bind(this),
          attributes: { style: 'background-color: var(--paper-red-500); color: white; padding: 12px; margin:0 5px' }
        }
      ],
      selectByType: true,
      selectByTypeText: 'Items to Delete'
    });
    this.$.katapultMap.countTouchedConnections = true;
  }
  multiDelete(featureRef) {
    // This function is for a shapefile polygon rather than a user drawn polygon
    const feature = this.getFeatureFromRef(featureRef);
    this.cancelPromptAction();
    let polygon = new google.maps.Polygon();
    feature.getGeometry().forEachLatLng((latLng) => {
      polygon.getPath().push(latLng);
    });
    this.multiSelectImportPolygon = polygon;
    this._button_muli_delete({ polygonType: '_multiSelectImportPolygon' });
  }

  async deleteMultiItems() {
    this.multiDeleteFunctions = await import('./button_functions/multi_delete.js');
    const multiSelectedNodes = this.$.katapultMap.multiSelectedNodes;
    const multiSelectedSections = this.$.katapultMap.multiSelectedSections;
    const multiSelectedConnections = this.$.katapultMap.multiSelectedConnections;
    const multiDeleteItem = this.$.katapultMap.multiDeleteItem;
    let data = {
      connections: this.connections,
      multiSelectedNodes: multiSelectedNodes,
      multiSelectedSections: multiSelectedSections,
      multiSelectedConnections: multiSelectedConnections,
      multiDeleteItem: multiDeleteItem
    };
    const results = this.multiDeleteFunctions.multiDelete(data);
    this.confirm(results.header, results.body, 'Delete', 'Cancel', 'background-color:#d23f31; color:white;', null, async function () {
      let currentLayers = loadRenderMap.layers;
      loadRenderMap.layers = {};
      this.progressText = 'Deleting Items. Layers will be re-enabled when deletion is complete.';
      this.$.progressPercent.indeterminate = true;
      this.$.progressToast.open();
      this.$.katapultMap.cancel();
      let args = {
        connections: this.connections,
        multiSelectedNodes: multiSelectedNodes,
        multiSelectedSections: multiSelectedSections,
        multiSelectedConnections: multiSelectedConnections,
        itemType: multiDeleteItem,
        job_id: this.job_id,
        nodes: this.nodes,
        connLookup: results.connLookup,
        appName: this.config.appName,
        userGroup: this.userGroup
      };

      try {
        const { otherJobUpdate, undoLog } = await this.multiDeleteFunctions.continueDelete(args);

        // Turn layers back on
        loadRenderMap.layers = currentLayers;
        // Save the changes made to the undo log
        undoLog.forEach((element) => {
          this.push('undoLog', element);
        });
        // Clear related variables
        this.cancelPromptAction();
        this.editing = null;
        this.selectedNode = null;
        this.editingNode = null;
        this.lastSelectedNode = null;

        // If necessary, update other jobs affected by the deletion
        if (Object.keys(otherJobUpdate).length > 0) {
          FirebaseWorker.ref('photoheight/jobs').update(otherJobUpdate);
        }

        // Close the progress toast
        this.$.progressPercent.indeterminate = false;
        this.$.progressToast.close();
        this.toast('Items deleted successfully.');
      } catch (error) {
        console.error(error);
        this.toast(error);
      }
    });
  }

  _button_insert_parent_node_creator(e) {
    this.selectedNode = null;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true
    };
    this.$.katapultMap.openActionDialog({
      text: 'Click or draw a polygon around nodes to select them. Right click to delete polygon points.',
      buttons: [
        {
          title: 'Cancel',
          callback: this.cancelPromptAction.bind(this),
          attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
        },
        {
          title: 'Finish',
          callback: this.insertParentNodeCreator.bind(this),
          attributes: { style: 'background-color: var(--paper-red-500); color: white; padding: 12px; margin:0 5px' }
        }
      ]
    });
  }

  async insertParentNodeCreator() {
    let nodesAdded = 0;
    let creatorUsers = {};
    const nodes = this.$.katapultMap.multiSelectedNodes;
    if (!this.job_id) return;
    const parentJobId = await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/metadata/parent_job_id`)
      .once('value')
      .then((s) => s.val());
    if (!parentJobId) {
      this.toast('This is not a snapshot with a Parent Job Id job-level attribute.');
      return;
    }
    // Get the Firestore data for who has moved the node, so we can find who created it
    let geoEvents = {};
    const getTimestampFromFirestore = (timestamp) => {
      return timestamp instanceof firebase.firestore.Timestamp ? timestamp.toMillis() : timestamp.seconds * 1000;
    };
    const jobRef = firebase.firestore().doc(`jobs/${parentJobId}`);
    let geoEventsQuery = jobRef.collection('geo_events').where('job_owner', 'in', [this.userGroup]).orderBy('set_at', 'desc');
    geoEventsQuery = await geoEventsQuery.get();
    geoEventsQuery.docs.forEach((doc) => {
      let data = doc.data();
      let millis = getTimestampFromFirestore(data.set_at);
      let event = {
        timestamp: millis,
        user: data.set_by.uid
      };
      if (!geoEvents[data.entity_id]) geoEvents[data.entity_id] = [];
      geoEvents[data.entity_id].push(event);
    });
    for (var node in nodes) {
      var nodeId = nodes[node];
      const attributeExisting = PickAnAttribute(this.nodes[nodeId]?.attributes, 'parent_node_creator');
      if (!geoEvents[nodeId]?.length) continue;
      const uid = geoEvents[nodeId].at(-1)?.user;
      if (!uid) continue;
      let email = this.users[uid]?.email || creatorUsers[uid]?.email;
      if (!email) {
        email = await FirebaseWorker.ref(`/user_info/${uid}/email`)
          .once('value')
          .then((s) => s.val());
        if (email) creatorUsers[uid] = { email: email };
      }
      // Turn the user email into a username
      const username = email?.split('@')?.[0] || 'Unknown User';
      try {
        const jobRef = KatapultJob.fromId(this.job_id);
        await jobRef.updateNodeAttribute(nodeId, 'parent_node_creator', [username], { replaceExisting: true });
        if (!attributeExisting) nodesAdded++;
      } catch (error) {
        return [{ message: error.message, type: 'element', selectionItem }];
      }
    }
    this.toast(`Parent Node Creator attribute added to ${nodesAdded} node(s).`);
    this.cancelPromptAction();
  }

  _button_multi_add_attribute(e) {
    this.multiAddAttributes = this.multiAddAttributes || [];
    this.multiAddAttributesChanged();
    // Default to adding the attributes
    this.multiAddAttributeOverwrite = this.multiAddAttributeOverwrite || 'add';
    // Default to only affecting nodes
    this.multiAddAttributeItemsToEdit = this.multiAddAttributeItemsToEdit || { nodes: true };
    let buttonConfirm = 'Select Items...';
    let dialogFunction = 'multiAddAttributes';
    let newCommand = '_multiSelectItems';
    let actionDialogText = 'Click Nodes or Draw a Polygon to Select items and add attributes. Right click to delete polygon points.';
    if (e?.polygonType == '_multiSelectImportPolygon') {
      buttonConfirm = 'Confirm';
      dialogFunction = 'multiAddAttributesImportPolygon';
      newCommand = e.polygonType;
      actionDialogText = 'Items inside the feature polygon will be included.';
    }
    // Show the dialog to allow the user to change options
    this.confirm(
      'Select Attribute To Edit',
      null,
      buttonConfirm,
      'Cancel',
      '',
      dialogFunction,
      function () {
        // Check that there are attributes in the multiAddAttributes list
        if (this.multiAddAttributes != null && this.multiAddAttributes.length > 0) {
          this.selectedNode = null;
          this.activeCommand = newCommand;
          this.multiSelectIncludedTypes = this.multiAddAttributeItemsToEdit;
          this.$.katapultMap.openActionDialog({
            text: actionDialogText,
            buttons: [
              {
                title: 'Cancel',
                callback: this.cancelPromptAction.bind(this),
                attributes: { style: 'padding: 12px; margin:0 5px; --katapult-button-border-color: rgba(230,230,230,1)', outline: '' }
              },
              {
                title: 'Finish',
                callback: this.multiEditAttributes.bind(this),
                attributes: {
                  style: 'background-color: var(--secondary-color); color: var(--secondary-color-text-color); padding: 12px; margin:0 5px'
                }
              }
            ]
          });
        } else {
          this.cancelPromptAction();
        }
      }.bind(this)
    );
  }
  multiEditAttribute(featureRef) {
    // This function is for a shapefile polygon rather than a user drawn polygon
    const feature = this.getFeatureFromRef(featureRef);
    this.cancelPromptAction();
    let polygon = new google.maps.Polygon();
    feature.getGeometry().forEachLatLng((latLng) => {
      polygon.getPath().push(latLng);
    });
    this.multiSelectImportPolygon = polygon;
    this._button_multi_add_attribute({ polygonType: '_multiSelectImportPolygon' });
  }

  multiAddAttributesSelectedChanged(e) {
    // Check if an attribute has been selected
    let selectedAttributeName = SquashNulls(e, 'detail', 'selectedItem');
    if (selectedAttributeName) {
      // Check if the selected attribute is a group
      let groupItems = SquashNulls(this.otherAttributes, selectedAttributeName, 'group_items');
      if (SquashNulls(this.otherAttributes, selectedAttributeName, 'gui_element') == 'group' && groupItems) {
        // Add each of the attributes that should be added by the group
        this.multiAddAttributes = groupItems.map((x) => {
          let attributeName = typeof x === 'string' ? x : x.attribute;
          let value = x.value || GetNewAttributeValue(attributeName, this.otherAttributes);
          return { name: attributeName, value };
        });
      }
      // Otherwise, just add the attribute to the list by itself
      else {
        this.multiAddAttributes = [
          {
            name: selectedAttributeName,
            value: ''
          }
        ];
      }
    } else {
      this.multiAddAttributes = [];
    }
  }

  refitConfirmDialog() {
    setTimeout(() => {
      this.$.confirmDialog.refit();
      this.$.confirmDialog.notifyResize();
    }, 500);
  }

  multiAddAttributeItemsToEditChanged(multiAddAttributeItemsToEdit) {
    let multiAddAttributeTypes = [];
    if (multiAddAttributeItemsToEdit && multiAddAttributeItemsToEdit.base) {
      if (multiAddAttributeItemsToEdit.base.nodes) multiAddAttributeTypes.push('node');
      if (multiAddAttributeItemsToEdit.base.sections) multiAddAttributeTypes.push('section');
      if (multiAddAttributeItemsToEdit.base.connections) multiAddAttributeTypes.push('connection');
    }
    this.multiAddAttributeTypes = multiAddAttributeTypes;
  }

  multiAddAttributesChanged() {
    if (!this.multiAddAttributes) return;

    // Go through each item in multiAddAttributes and set the appropriate default value if there isn't an existing value
    for (const index in this.multiAddAttributes) {
      const attr = this.multiAddAttributes[index];
      this.set(`multiAddAttributes.${index}.value`, GetNewAttributeValue(attr.name, this.otherAttributes));

      // Certain attributes (hardcoded list here) should show the "update" option for the multi-add tool
      const allowUpdateAttributes = ['pole_tag', 'cost_causer'];
      if (allowUpdateAttributes.includes(attr.name)) this.set('multiAddAttributeUpdate', true);
    }
  }

  async multiEditAttributesAllItems() {
    await this.multiEditAttributes(true);
  }

  async multiEditAttributesOfType() {
    this.multiEditAttributes(false, true);
  }

  async multiEditAttributes(updateAllItems = false, updateItemsOfType = false) {
    if (!this.job_id) return;
    if (!this.multiAddAttributes || this.multiAddAttributes.length === 0) {
      this.cancelPromptAction();
      return;
    }

    try {
      // Start the loading spinner
      this.toast('Updating attributes...', null, Infinity, false, true);

      let selectedTypes = null;
      if (updateItemsOfType) {
        selectedTypes = { node: [], conn: [], section: {} };
        const selectedTypesCheckboxes = this.$.nodeTypesList.querySelectorAll('paper-checkbox[checked]');
        for (let i = 0; i < selectedTypesCheckboxes.length; i++) {
          const type = selectedTypesCheckboxes[i].dataset.type;
          if (type == 'midpoint section') selectedTypes.section.midpoint = true;
          else if (type == 'other section') selectedTypes.section.other = true;
          else if (type == 'items with photos') selectedTypes.hasPhotos = true;
          else if (
            this.multiAddAttributeItemsToEdit.nodes &&
            parseInt(selectedTypesCheckboxes[i].dataset.index) < this.job_node_types.length
          )
            selectedTypes.node.push(type);
          else selectedTypes.conn.push(type);
        }
      }

      if (this.multiAddAttributeItemsToEdit.nodes) {
        let nodeUpdate = {};
        let nodeCounter = 0;
        const nodeIds = this.getMultiEditAttributesNodeIds(updateAllItems, selectedTypes);

        for (const nodeId of nodeIds) {
          if (!this.nodes[nodeId]) continue;

          const options = {
            type: 'nodes',
            nodeId: nodeId
          };
          this.multiEditAttributesUpdateComposer(options, nodeUpdate);
          nodeCounter++;

          // If we have hit the limit of nodes to update at once, then run the update
          if (nodeCounter === ITEM_UPDATE_LIMIT) {
            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(nodeUpdate);
            nodeUpdate = {};
            nodeCounter = 0;
          }
        }

        await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(nodeUpdate);
      }

      if (this.multiAddAttributeItemsToEdit.connections) {
        let connUpdate = {};
        let connCounter = 0;
        const connectionIds = this.getMultiEditAttributesConnectionIds(updateAllItems, selectedTypes);

        for (const connId of connectionIds) {
          if (!this.connections[connId]) continue;

          const options = {
            type: 'connections',
            connId: connId
          };
          this.multiEditAttributesUpdateComposer(options, connUpdate);
          connCounter++;

          // If we have hit the limit of connections to update at once, then run the update
          if (connCounter === ITEM_UPDATE_LIMIT) {
            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(connUpdate);
            connUpdate = {};
            connCounter = 0;
          }
        }

        await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(connUpdate);
      }

      if (this.multiAddAttributeItemsToEdit.sections) {
        let sectionUpdate = {};
        let sectionCounter = 0;
        const sectionPaths = this.getMultiEditAttributesSectionPaths(updateAllItems, selectedTypes);

        for (const sectionPath of sectionPaths) {
          const [connId, sectionId] = sectionPath.split(':');
          if (!this.connections[connId] || !this.connections[connId]?.sections?.[sectionId]) continue;

          let options = {
            type: 'sections',
            connId: connId,
            sectionId: sectionId
          };
          this.multiEditAttributesUpdateComposer(options, sectionUpdate);
          sectionCounter++;

          // If we have hit the limit of sections to update at once, then run the update
          if (sectionCounter === ITEM_UPDATE_LIMIT) {
            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(sectionUpdate);
            sectionUpdate = {};
            sectionCounter = 0;
          }
        }

        await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(sectionUpdate);
      }

      // Wait a bit before showing the toast to give the user a chance to see the loading spinner
      await new Promise((resolve) =>
        setTimeout(() => {
          this.toast('Attributes updated successfully.');
          resolve();
        }, 500)
      );
      this.cancelPromptAction();
    } catch (error) {
      console.error(error);
      this.toast(error);
    }
  }

  getMultiEditAttributesNodeIds(updateAllItems, selectedTypes) {
    if (updateAllItems) return Object.keys(this.nodes);

    if (selectedTypes) {
      const allNodeIds = Object.keys(this.nodes);
      return allNodeIds.filter((nodeId) => {
        // Get the node type from the node. If it's type null, fallback to '(no type)' to match the array item in selectedTypes
        const nodeType = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute) || '(no type)';
        return selectedTypes.node.indexOf(nodeType) != -1 || (selectedTypes.hasPhotos && this.nodes[nodeId].photos != null);
      });
    }

    return this.$.katapultMap.multiSelectedNodes;
  }

  getMultiEditAttributesConnectionIds(updateAllItems, selectedTypes) {
    if (updateAllItems) return Object.keys(this.connections);

    if (selectedTypes) {
      const allConnIds = Object.keys(this.connections);
      return allConnIds.filter((connId) => {
        // Get the connection type from the connection. If it's type null, fallback to '(no type)' to match the array item in selectedTypes
        const connectionType =
          PickAnAttribute(this.connections[connId].attributes, this.modelDefaults.connection_type_attribute) || '(no type)';
        return selectedTypes.conn.indexOf(connectionType) != -1 || (selectedTypes.hasPhotos && this.connections[connId].photos != null);
      });
    }

    return Object.keys(this.$.katapultMap.multiSelectedConnections);
  }

  getMultiEditAttributesSectionPaths(updateAllItems, selectedTypes) {
    if (updateAllItems) {
      let sectionPaths = [];
      for (const connId in this.connections) {
        for (const sectionId in this.connections[connId].sections) {
          sectionPaths.push(`${connId}:${sectionId}`);
        }
      }
      return sectionPaths;
    }

    if (selectedTypes) {
      let sectionPaths = [];
      for (const connId in this.connections) {
        for (const sectionId in this.connections[connId].sections) {
          if (
            (sectionId == 'midpoint_section' && selectedTypes.section.midpoint) ||
            (sectionId != 'midpoint_section' && selectedTypes.section.other) ||
            (selectedTypes.hasPhotos && this.connections[connId].sections[sectionId].photos != null)
          ) {
            sectionPaths.push(`${connId}:${sectionId}`);
          }
        }
      }
      return sectionPaths;
    }

    return this.$.katapultMap.multiSelectedSections;
  }

  async _button_fix_company_names(e) {
    let models = this.activeCommandModel.models;
    let modelCompanies = {};
    let fixCompanyList = [];
    for (let picklistId in this.otherAttributes.company.picklists) {
      fixCompanyList.push(...this.otherAttributes.company.picklists[picklistId]);
      this.otherAttributes.company.picklists[picklistId].forEach((item) => {
        modelCompanies[item.value] = true;
      });
    }
    let companies = await this.updateCompanies();
    let companyMatches = [];
    for (let company in companies) {
      if (company && company != 'undefined' && company != 'null') {
        companyMatches.push({
          company,
          toCompany: SquashNulls(models, 'company_lookup', company) || (modelCompanies[company] ? company : ''),
          save: true
        });
      }
    }
    this.companyNameMatches = companyMatches.sort((a, b) => a.company.localeCompare(b.company));
    this.fixCompanyList = fixCompanyList;
    // (not yet)// By default these changes are saved for future uses of this tool.
    this.confirm(
      'Fix Company Names',
      'The following companies were found in this job. Choose which companies you would like to change each company to. Rows in grey are companies that remain unchanged.',
      'Update Companies',
      'Cancel',
      '',
      'fixCompanyNames',
      async () => {
        let companyMatches = {};
        this.companyNameMatches.forEach((item) => {
          companyMatches[item.company] = item.toCompany;
        });
        let update = await this.updateCompanies(companyMatches);
        if (this.job_id) {
          await FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
        }
        this.cancelPromptAction();
      }
    );
  }

  async updateCompanies(companyMatches) {
    let update = {};
    //traces
    for (let traceId in this.traces) {
      let co = this.traces[traceId].company;
      if (companyMatches) {
        if (companyMatches[co] && companyMatches[co] != co) {
          update['traces/trace_data/' + traceId + '/company'] = companyMatches[co];
        }
      } else {
        update[co] = true;
      }
    }
    for (let nodeId in this.nodes) {
      if (this.nodes[nodeId].attributes) {
        //pole tags
        for (let itemKey in this.nodes[nodeId].attributes.pole_tag) {
          let co = this.nodes[nodeId].attributes.pole_tag[itemKey].company;
          if (companyMatches) {
            if (companyMatches[co] && companyMatches[co] != co) {
              update['nodes/' + nodeId + '/attributes/pole_tag/' + itemKey + '/company'] = companyMatches[co];
            }
          } else {
            update[co] = true;
          }
        }
        // company attribute
        for (let itemKey in this.nodes[nodeId].attributes.company) {
          let co = this.nodes[nodeId].attributes.company[itemKey];
          if (companyMatches) {
            if (companyMatches[co] && companyMatches[co] != co) {
              update['nodes/' + nodeId + '/attributes/company/' + itemKey] = companyMatches[co];
            }
          } else {
            update[co] = true;
          }
        }
        // cost causer
        for (let itemKey in this.nodes[nodeId].attributes.cost_causer) {
          // cost causer companies
          for (let key in this.nodes[nodeId].attributes.cost_causer[itemKey].companies) {
            let co = this.nodes[nodeId].attributes.cost_causer[itemKey].companies[key].company;
            if (companyMatches) {
              if (companyMatches[co] && companyMatches[co] != co) {
                update['nodes/' + nodeId + '/attributes/cost_causer/' + itemKey + '/companies/' + key + '/company'] = companyMatches[co];
              }
            } else {
              update[co] = true;
            }
          }
          // find & replace in cost causer explanation
          if (companyMatches) {
            let explanation = this.nodes[nodeId].attributes.cost_causer[itemKey].explanation;
            for (let company in companyMatches) {
              if (companyMatches[company]) {
                explanation = explanation.replace(new RegExp(company, 'g'), companyMatches[company]);
              }
            }
            if (explanation != this.nodes[nodeId].attributes.cost_causer[itemKey].explanation) {
              update['nodes/' + nodeId + '/attributes/cost_causer/' + itemKey + '/explanation'] = explanation;
            }
          }
        }
      }
    }

    // find & replace in mr_notes on markers
    if (companyMatches) {
      let data = await GetJobData(this.job_id, 'photos');
      for (let photoId in data.photos) {
        TraverseMarkers(data.photos[photoId].photofirst_data, (child, path, childProperty, childItemKey) => {
          let note = child.mr_note;
          if (note) {
            for (let company in companyMatches) {
              if (companyMatches[company]) {
                note = note.replace(new RegExp(company, 'g'), companyMatches[company]);
              }
            }
            if (note != child.mr_note) {
              update['photos/' + photoId + '/photofirst_data/' + path.replace(/\./g, '/') + '/mr_note'] = note;
            }
          }
        });
      }
    }
    return update;
  }

  toggleSaveFixCompanyName(e) {
    this.set('companyNameMatches.' + e.model.index + '.save', !e.model.item.save);
  }

  async _button_google_elevation(e) {
    this.toast('Setting Google Elevation...');

    try {
      // If the google elevation attribute doesn't exist, add it to the modelspace
      if (this.jobCreator && !this.otherAttributes.google_elevation) {
        const permissionsPath = `photoheight/company_space/${this.jobCreator}/companies_with_write_model_access/${this.userGroup}`;
        const hasPermission = await FirebaseWorker.database()
          .ref(permissionsPath)
          .once('value')
          .then((s) => s.val());
        if (hasPermission) {
          await FirebaseWorker.database()
            .ref(`photoheight/company_space/${this.jobCreator}/models/attributes/google_elevation`)
            .update({
              attribute_types: ['node'],
              gui_element: 'textbox',
              editability: 'uneditable',
              placeholder: `Elevation data from Google's geographic data`,
              priority: 999
            });
        }
      }

      // Import (if we haven't already) the elevation funtion and call it
      const { SetGoogleElevation } = await import('./button_functions/set_google_elevation.js');
      await SetGoogleElevation({
        jobId: this.job_id,
        nodes: this.nodes,
        connections: this.connections,
        jobStyles: this.jobStyles,
        useMetricUnits: this.useMetricUnits
      });

      // If we succeeded, notify the user
      this.toast('Done Setting Google Elevation');
      this.cancelPromptAction();
    } catch (error) {
      this.toast('Elevation lookup error');
      this.cancelPromptAction();
      throw error;
    }
  }

  async addPAStateRoadDataToNodes(nodeList) {
    const selectedMappingButton = this.selectedMappingButton;
    this.selectedMappingButton = null;
    this.progressText = '';
    this.progressPercent = 0;
    this.$.progressToast.open();
    let warningMessageParts = [];

    // If the node list is null or if it's not an array (because it's
    // probably an event object from the katapult-button) then use all nodes
    if (!nodeList || Array.isArray(nodeList) == false) {
      nodeList = Object.keys(this.nodes);
    }

    nodeList = nodeList.filter((nodeId) => {
      let type = PickAnAttribute(this.nodes[nodeId]?.attributes, this.modelDefaults.node_type_attribute);
      return this.modelDefaults.pole_node_types.includes(type) || this.modelDefaults.reference_node_types.includes(type);
    });

    let update = {};
    let buttonUpdate = {};
    let count = 0;
    for (let nodeId of nodeList) {
      let node = this.nodes[nodeId];
      let nodeLabel = `${this.modelDefaults.ordering_attribute_label} ${PickAnAttribute(node.attributes, this.modelDefaults.ordering_attribute) || `(No ${this.modelDefaults.ordering_attribute_label})`}`;

      // Fetch SR Segment and Offset
      this.progressText = `Getting State Road Data for ${nodeLabel}`;
      let response = await this.getPennDOTOffset(node.latitude, node.longitude);
      let stateRoute = response.stateRouteNumber.replace(/^0+/, '');
      let segment = response.segmentNumber;
      let offset = response.offset;
      update[nodeId + '/attributes/state_route'] = { button_added: stateRoute };
      update[nodeId + '/attributes/segment'] = { button_added: segment };
      update[nodeId + '/attributes/offset'] = { button_added: offset };

      if (this.otherAttributes?.segment_offset_bundle?.gui_element == 'group') {
        this.otherAttributes.segment_offset_bundle.group_items?.forEach((item) => {
          const currentValues = Path.get(node, `attributes.${item.attribute}`) ?? {};
          if (Object.values(currentValues).filter((x) => x).length == 0) {
            update[`${nodeId}/attributes/${item.attribute}`] = {
              button_added: item.value || GetNewAttributeValue(item.attribute, this.otherAttributes)
            };
          }
        });
      }

      //Get the position of the nearest State Road centerline
      const cl = Path.get(node, 'attributes.cl_ft.*');
      if (cl) {
        const layerList = await FirebaseWorker.ref(`utility_info/_list`)
          .once('value')
          .then((s) => s.val());
        const layerInfo = layerList?.find((x) => x.name === 'PA State Roads');
        if (!layerInfo) throw new Error('PA State Roads Layer Not Found');
        const layerUrlStartsWithUtilityInfo = layerInfo.url.startsWith('utility_info') || layerInfo.url.startsWith('/utility_info');
        const urlPrefix = layerUrlStartsWithUtilityInfo ? '' : 'utility_info/';
        const geo = new GeoFire(
          FirebaseWorker.database(layerInfo.options.database ? `https://${layerInfo.options.database}.firebaseio.com` : undefined).ref(
            `${urlPrefix}${layerInfo.url}`
          )
        );
        const nearbyRoads = await geo.once({
          center: [node.latitude, node.longitude],
          radius: 0.5 //500m
        });
        const closest = { distance: Infinity, segment: null };
        Object.values(nearbyRoads ?? {}).forEach((segment) => {
          const distance = KatapultGeometry.CalcDistanceToLine(
            node.latitude,
            node.longitude,
            segment.l[0],
            segment.l[1],
            segment.l2[0],
            segment.l2[1]
          );
          if (distance < closest.distance) {
            closest.distance = distance;
            closest.segment = segment;
          }
        });
        if (closest.segment) {
          const pointOnLine = KatapultGeometry.SnapToLine(
            node.latitude,
            node.longitude,
            closest.segment.l[0],
            closest.segment.l[1],
            closest.segment.l2[0],
            closest.segment.l2[1],
            true
          );
          const bearing = KatapultGeometry.CalcBearing(node.latitude, node.longitude, pointOnLine.lat, pointOnLine.long);
          const centerLineDistance = Convert(cl, 'ft', 'm');
          const centerLine = google.maps.geometry.spherical.computeOffset(
            new google.maps.LatLng(node.latitude, node.longitude),
            centerLineDistance,
            bearing
          );

          // Find north then add 360 and mod 360 to ensure a positive angle
          // This method works when bearing < 556°
          const northDegrees = (180 - bearing + 360) % 360;
          // Round to the nearest 30° and mod 360 again to ensure the value isn't 360 which happens when 180° < bearing <= 195°
          const roundedNorthDegrees = (Math.round(northDegrees / 30) * 30) % 360;
          update[nodeId + '/attributes/PennDOT_north'] = { button_added: `${roundedNorthDegrees}° N` };

          // Determine the PennDOT Grade by comparing Pole Elevation to Road CL elevation
          const locations = [centerLine];
          let poleElevation = Path.get(node, 'attributes.google_elevation.*');
          if (!poleElevation) locations.push(new google.maps.LatLng(node.latitude, node.longitude));
          const googleElevation = new google.maps.ElevationService();
          const { results } = await googleElevation.getElevationForLocations({ locations });
          const elevationAtCenterLine = Math.round(Convert(results[0].elevation, 'm', 'ft'));
          if (!poleElevation) {
            poleElevation = Math.round(Convert(results[1].elevation, 'm', 'ft')) + ' ft';
            update[nodeId + '/attributes/google_elevation'] = { button_added: poleElevation };
          }
          const delta = parseInt(poleElevation) - elevationAtCenterLine;
          let grade = 'Level';
          if (delta > 1) {
            grade = 'Positive';
          } else if (delta < -3) {
            grade = 'Negative';
          }
          update[nodeId + '/attributes/PennDOT_grade'] = { button_added: grade };

          // Calculate Side of Road
          const directions = [90, -90];
          segment = parseInt(segment);
          offset = parseInt(offset);
          for (const direction of directions) {
            const twentyFeetAway = google.maps.geometry.spherical.computeOffset(
              new google.maps.LatLng(node.latitude, node.longitude),
              6.096,
              bearing + direction
            );
            const resultTwentFeetAway = await this.getPennDOTOffset(twentyFeetAway.lat(), twentyFeetAway.lng());
            const segmentTwentyFeetAway = parseInt(resultTwentFeetAway.segmentNumber);
            const offsetTwentyFeetAway = parseInt(resultTwentFeetAway.offset);
            let side = '';
            // If it's the same offset and segment, we might be at the end of the road, try the other direction
            if (segmentTwentyFeetAway == segment && offsetTwentyFeetAway == offset) {
              continue;
            } else if (segmentTwentyFeetAway > segment || (segmentTwentyFeetAway == segment && offsetTwentyFeetAway > offset)) {
              side = direction > 0 ? 'Right' : 'Left';
            } else if (segmentTwentyFeetAway < segment || (segmentTwentyFeetAway == segment && offsetTwentyFeetAway < offset)) {
              side = direction > 0 ? 'Left' : 'Right';
            }
            if (!side) {
              warningMessageParts.push(`${nodeLabel} - Failed to Calculate PennDOT Roadside`);
            } else {
              update[nodeId + '/attributes/PennDOT_roadside'] = { button_added: side };
            }
          }
        }
      } else {
        warningMessageParts.push(
          `${nodeLabel} - Cannot calculate Penndot Grade, North or Roadside because the pole is missing a measured centerline (Cl Ft) `
        );
      }

      const municipality = PickAnAttribute(node.attributes, 'municipality');
      const township = PickAnAttribute(node.attributes, 'township');
      const county = PickAnAttribute(node.attributes, 'county');
      if ((municipality || township) && county) {
        const cleanCounty = county.toLowerCase().replace('county', '').trim();
        const countyCodes = this.actionDialogModel?.models?.municipality_codes[cleanCounty];
        let muni = (township || municipality).replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
        let type = '';
        if (muni.includes('city')) {
          type = 'city';
          muni = muni.replace('city', '').trim();
        } else if (muni.includes('borough')) {
          type = 'borough';
          muni = muni.replace('borough', '').trim();
        } else if (muni.includes(' boro')) {
          type = 'borough';
          muni = muni.replace(' boro', '').trim();
        } else if (muni.includes('township')) {
          type = 'township';
          muni = muni.replace('township', '').trim();
        }
        let code = countyCodes?.[type]?.[muni] ?? countyCodes?.user_entered?.[muni];
        // If we couldn't determine a type, check all the types to see if we only have one match
        if (!type && !code) {
          let matches = [];
          for (let key in countyCodes) {
            if (countyCodes[key][muni]) {
              matches.push({ code: countyCodes[key][muni], type: key });
            }
          }
          if (matches.length == 1) {
            code = matches[0].code;
            type = matches[0].type;
          }
        }

        // Build a list of all codes in the county for the dropdown and value text
        const municipalityList = [];
        for (const key in countyCodes) {
          for (const muni in countyCodes[key]) {
            const code = countyCodes[key][muni];
            municipalityList.push({
              value: code,
              type: key,
              muni,
              label: `${ToTitleCase(muni)} ${ToTitleCase(key)} - ${code}`
            });
          }
        }

        // If we still don't have a code, ask the user to select one
        if (!code) {
          const dialog = KatapultDialog.open({
            dialog: { title: 'Municipality Code', draggable: true, maxWidth: 500 },
            template: () => litHtml`
              <div style="text-align:center;">
                <div>Could not determine Municipality for ${nodeLabel} - ${township || municipality}. Please select the correct ${ToTitleCase(cleanCounty)} County Municipality below.</div>
                <katapult-drop-down id="muni" label="Municipality" value-path="value" label-path="label" style="text-align:left;"></katapult-drop-down>
              </div>
              <katapult-button slot="buttons" style="min-width:75px;" dialog-dismiss>Skip</katapult-button>
              <katapult-button id="confirm" slot="buttons" color="var(--secondary-color)">Select</katapult-button>
            `
          });
          await dialog.openStart;
          dialog.querySelector('#muni').items = municipalityList;
          dialog.querySelector('#confirm').addEventListener('click', () => {
            code = dialog.querySelector('#muni').value;
            if (code) {
              countyCodes.user_entered ??= {};
              countyCodes.user_entered[muni] = code;
              buttonUpdate[`municipality_codes/${cleanCounty}/user_entered/${muni}`] = code;
            }
            dialog.close();
          });
          await dialog.closeStart;
        }

        if (code) {
          let item = municipalityList.find((x) => x.value == code);
          update[nodeId + '/attributes/PennDOT_municipal_code'] = {
            button_added: `${code} - ${ToTitleCase(item?.muni)} ${ToTitleCase(item?.type)}`
          };
        } else {
          warningMessageParts.push(`${nodeLabel} - No PennDOT Municipal Code found`);
        }
      } else {
        warningMessageParts.push(
          `${nodeLabel} - Cannot calculate PennDOT Municipal Code because the pole is missing a municipality or county`
        );
      }

      const cleanCounty = county?.toLowerCase()?.replace('county', '')?.trim();
      const district = this.actionDialogModel?.models?.districts?.[cleanCounty];
      if (district) {
        update[nodeId + '/attributes/PennDOT_district'] = { button_added: `District ${district}` };
      } else {
        warningMessageParts.push(`${nodeLabel} - No PennDOT District found`);
      }

      // Lookup previous ROW Information
      if (municipality && county && segment && offset && stateRoute && this.job_id) {
        // update the pa state road data lookup with these values if they are different values
        let oldStateRoute = PickAnAttribute(node.attributes, 'state_route') || '';
        let oldSegment = PickAnAttribute(node.attributes, 'segment') || '';
        let oldSegmentNumber = parseInt(oldSegment);
        // add a new document if there is not one already
        let oldDocData = await firebase
          .firestore()
          .doc(`companies/${this.userGroup}/pa_state_road_segment_lookup/${nodeId}`)
          .get()
          .then((doc) => doc.data());
        let newDoc = false;
        if (!oldDocData) {
          newDoc = true;
          await firebase.firestore().doc(`companies/${this.userGroup}/pa_state_road_segment_lookup/${nodeId}`).set({});
        }

        // create the update data for the node, skipping values that are not different
        let nodeUpdate = { municipality, county, job_id: this.job_id }; // always update the municipality, county, and job_id
        // skip state route if the data is the same
        if (oldStateRoute != stateRoute || newDoc) nodeUpdate.state_route = stateRoute;
        // skip segment if their numbers are the same
        let numberSegment = parseInt(segment);
        if (numberSegment != oldSegmentNumber || newDoc) nodeUpdate.segment = numberSegment;
        // update the node data in firestore
        firebase.firestore().doc(`companies/${this.userGroup}/pa_state_road_segment_lookup/${nodeId}`).update(nodeUpdate);

        // fire a query to search for similar poles:  This queries a lookup that is updated by this function and populated by a script called "updatePaStateRoadSegmentLookup"
        let paLookupRef = firebase.firestore().collection(`companies/${this.userGroup}/pa_state_road_segment_lookup`);
        let nodesWithSimilarDataSnapshot = await paLookupRef
          .where('state_route', '==', stateRoute)
          .where('municipality', '==', municipality)
          .where('county', '==', county)
          .where('segment', '<=', numberSegment + 30)
          .where('segment', '>=', numberSegment - 30)
          .get();
        // get all similar nodes
        let allNodesWithSimilarData = [];
        nodesWithSimilarDataSnapshot.forEach((doc) => allNodesWithSimilarData.push({ nodeId: doc.id, node: doc.data() }));
        // filter out the nodes in this job
        let nodesWithSimilarData = allNodesWithSimilarData.filter((nodeData) => !Object.keys(this.nodes).includes(nodeData.nodeId));
        // Create the note for the row width
        let rowWidthNoteParts = [];
        for (let nodeData of nodesWithSimilarData) {
          let jobId = nodeData.node?.job_id;
          let similarNodeId = nodeData.nodeId;
          if (!jobId || !similarNodeId) continue;
          // get the needed data
          let dataPaths = [`nodes/${similarNodeId}`, 'name', 'files'];
          let jobData = await GetJobData(jobId, dataPaths);
          // get the node
          let similarNode = jobData[dataPaths[0]] || {};
          // check to see if this node has a ROW Width attribute
          let rowWidth = PickAnAttribute(similarNode.attributes, 'ROW_width');
          // if there is a row width add it to the message note
          if (rowWidth) {
            let similarNodeRowRecord = PickAnAttribute(similarNode?.attributes, 'ROW_record');
            if (similarNodeRowRecord) {
              let files = jobData?.[dataPaths?.[2]]?.poles;
              let record = files?.[similarNodeId]?.ROW_record;
              let filesUpdate = {};
              if (record) {
                filesUpdate[`files/poles/${nodeId}/ROW_record`] = record;
                let key = await FirebaseWorker.ref().push().key;
                filesUpdate[`nodes/${nodeId}/attributes/ROW_record/${key}`] = true;
                await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(filesUpdate);
              }
            }
            // get the job name
            let jobName = jobData[dataPaths[1]];
            let message = `See ${jobName} for ROW info`;
            if (!rowWidthNoteParts.includes(message)) rowWidthNoteParts.push(message);
          }
        }
        // create the final row width note
        let finalRowWidthNote;
        if (rowWidthNoteParts.length != 0) finalRowWidthNote = rowWidthNoteParts.join(', ');
        else finalRowWidthNote = 'Could not find similar nodes outside of this job';
        // only update the row width note if there is nothing there currently
        let currentNodeRowWidthNote = PickAnAttribute(node.attributes, 'ROW_width') || '';
        if (!currentNodeRowWidthNote) update[nodeId + '/attributes/ROW_width'] = { button_added: finalRowWidthNote };
      } else {
        let missingAttributes = [];
        let startOfMessage = `${nodeLabel} - Cannot check for other State Route / ROW information because the pole is missing the following attributes: `;
        if (!municipality) missingAttributes.push(`municipality`);
        if (!county) missingAttributes.push(`county`);
        if (!segment) missingAttributes.push(`segment`);
        if (!offset) missingAttributes.push(`offset`);
        if (!stateRoute) missingAttributes.push(`stateRoute`);
        warningMessageParts.push(startOfMessage + missingAttributes.join(', '));
      }

      count++;
      this.progressPercent = count / nodeList.length;
    }
    if (this.job_id) {
      this.progressText = 'Saving State Road Data';
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes`).update(update);
    }

    if (selectedMappingButton && this.jobCreator && Object.keys(buttonUpdate).length) {
      await FirebaseWorker.ref(
        `photoheight/company_space/${this.jobCreator}/models/mapping_buttons/${selectedMappingButton}/models`
      ).update(buttonUpdate);
    }

    this.progressText = 'Done looking up State Road Data.';
    this.progressPercent = 100;

    setTimeout(() => {
      this.$.progressToast.close();
    }, 3000);

    // show a dialog that notifies the user of any warnings
    if (warningMessageParts.length != 0) {
      KatapultDialog.alert(warningMessageParts.join('\n'), 'Warnings');
    }

    this.cancelPromptAction();
  }

  async getPennDOTOffset(latitude, longitude) {
    const url = `https://gis.penndot.gov/mapcore/api/graphql?GetOffsetForLatLong($latitude:%20[Float],%20$longitude:%20[Float],%20$tolerance:%20[Float],%20$autoIncRadius:%20[Float],%20$minRecReturn:%20[Float])&query=query%20GetOffsetForLatLong($latitude:%20%5BFloat%5D,%20$longitude:%20%5BFloat%5D,%20$tolerance:%20%5BFloat%5D,%20$autoIncRadius:%20%5BFloat%5D,%20$minRecReturn:%20%5BFloat%5D)%20%7B%20rmsOffset(latitude:%20$latitude,%20longitude:%20$longitude,%20buffer:%20$tolerance,%20autoIncRadius:%20$autoIncRadius,%20minRecReturn:%20$minRecReturn)%20%7B%20countyCode,countyName,direction,distance,juris,offset,stateRouteNumber,routeName,segmentNumber,sideIndicator,rmsKey%20%7D%20%7D&variables=%7B%22latitude%22:${latitude.toFixed(
      9
    )},%22longitude%22:${longitude.toFixed(9)},%22tolerance%22:100,%22autoIncRadius%22:1,%22minRecReturn%22:10%7D`;
    const response = await fetch(url).then((x) => x.json());
    return response.data.rmsOffset[0];
  }

  async _button_pa_state_road_segment_offset(e) {
    this.selectedMappingButton = this.activeCommand;
    this.confirm(
      'Lookup PA State Road, Segment and Offset',
      'You can add state road data to all poles, use the polygon selection tool to select specific poles, or select node types to get state road data for.',
      'Select Nodes...',
      'Cancel',
      '',
      'paStateRoadData',
      () => {
        this.selectedNode = null;
        this.activeCommand = '_multiSelectItems';
        this.multiSelectIncludedTypes = {
          nodes: true,
          sections: false,
          connections: false
        };

        this.$.katapultMap.openActionDialog({
          title: this.actionDialogModel.label,
          text: 'Click nodes or draw a polygon around them to add state road data. Only poles and references will get state road data. Right click to delete polygon points.',
          buttons: [
            {
              title: 'Cancel',
              callback: this.cancelPromptAction.bind(this),
              attributes: { outline: '' }
            },
            {
              title: 'Finish',
              callback: () => {
                this.addPAStateRoadDataToNodes(this.$.katapultMap.multiSelectedNodes);
              },
              attributes: { 'secondary-color': '' }
            }
          ]
        });
      }
    );
  }

  _getAddressOptions(options) {
    let addressOptions = {
      street_number: false,
      street_name: true,
      township: true,
      municipality: true,
      county: true,
      state: true,
      zip_code: false
    };

    if (SquashNulls(options, 'street_number')) {
      addressOptions.street_number = true;
    }
    if (SquashNulls(options, 'zip_code')) {
      addressOptions.zip_code = true;
    }
    return addressOptions;
  }

  addAddressDataToNodeTypes() {
    this.progressText = '';
    this.progressPercent = 0;
    this.$.progressToast.open();

    var selectedTypes = [];
    var selectedTypesCheckboxes = this.$.nodeTypesList.querySelectorAll('paper-checkbox');
    for (var i = 0; i < selectedTypesCheckboxes.length; i++) {
      if (selectedTypesCheckboxes[i].checked == true) {
        selectedTypes.push(selectedTypesCheckboxes[i].dataset.type);
      }
    }
    this.buttonSelectedNodes = [];
    for (var nodeId in this.nodes) {
      if (this.nodes.hasOwnProperty(nodeId)) {
        // Check if the node type is in our selected node types
        var nodeType = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
        if (selectedTypes.indexOf(nodeType) != -1) {
          this.buttonSelectedNodes.push(nodeId);
        }
      }
    }

    var coordinateList = [];
    // Loop through the nodes to find the ones to process for address data
    for (let i = 0; i < this.buttonSelectedNodes.length; i++) {
      let key = this.buttonSelectedNodes[i];
      let node = this.nodes[key];
      // Get the ordering attribute for the node
      var orderingAttribute = PickAnAttribute(node.attributes, this.modelDefaults.ordering_attribute) || '???';
      // Check if the node has lat/long data
      if (node.latitude && node.longitude) {
        coordinateList.push({
          key: key,
          attributes: node.attributes,
          lat: Number(node.latitude),
          long: Number(node.longitude),
          [this.modelDefaults.ordering_attribute]: orderingAttribute
        });
      }
    }

    this.callGetAddressData(coordinateList);

    this.cancelPromptAction();
  }

  callGetAddressData(coordinateList) {
    // Build an address attribute list based on a combination of the button
    // model and the options chosen in the interface
    let addressAttributes = {};
    for (let addressOption in this.addressAttributes) {
      // If the value is set to true from the interface, then set the value
      // from the button model first and fall back to true
      if (this.addressAttributes[addressOption] === true) {
        addressAttributes[addressOption] = SquashNulls(this.mappingButtons, this.activeCommand, 'models', addressOption) || true;
      }
    }

    let options = {
      addIndividualAddressAttr: this.addIndividualAddressAttr,
      addressAttributes,
      addFormattedAddressAttr: this.addFormattedAddressAttr,
      modelDefaults: this.modelDefaults,
      prioritizeStreetNumber: this.prioritizeStreetNumber,
      stateAbbreviation: this.stateAbbreviation,
      otherAttributes: this.otherAttributes,
      firebaseRef: FirebaseWorker.ref(`/`),
      jobCreator: this.jobCreator,
      userGroup: this.userGroup
    };

    let jobRef = FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`);
    getAddressData(
      coordinateList,
      0,
      coordinateList.length,
      5,
      jobRef,
      options,
      (progress) => {
        // Progress callback
        this.progressText = progress.text;
        this.progressPercent = progress.percent;
      },
      async () => {
        // Final callback
        const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
        await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
        this.progressText = 'Finished adding address data';
        this.progressPercent = 100;
        setTimeout(() => {
          this.$.progressToast.close();
        }, 3000);
      }
    );
  }

  addAddressDataToNodes(nodeList) {
    this.progressText = '';
    this.progressPercent = 0;
    this.$.progressToast.open();

    // If the node list is null or if it's not an array (because it's
    // probably an event object from the katapult-button) then use all nodes
    if (!nodeList || Array.isArray(nodeList) == false) {
      nodeList = Object.keys(this.nodes);
    }

    var coordinateList = [];
    // Loop through the nodes to find the ones to process for address data
    for (let i = 0; i < nodeList.length; i++) {
      let key = nodeList[i];
      let node = this.nodes[key];
      // Get the scid for the node
      var orderingAttribute = PickAnAttribute(node.attributes, this.modelDefaults.ordering_attribute) || '???';
      // Check if the node has lat/long data
      if (node.latitude && node.longitude) {
        coordinateList.push({
          key: key,
          attributes: node.attributes,
          lat: Number(node.latitude),
          long: Number(node.longitude),
          [this.modelDefaults.ordering_attribute]: orderingAttribute
        });
      }
    }

    this.callGetAddressData(coordinateList);

    this.cancelPromptAction();
  }

  _button_address_data(e) {
    if (this.userGroup == 'conexon') {
      this.hideAddressOptions = true;
      this.addIndividualAddressAttr = false;
      this.addFormattedAddressAttr = true;
      this.prioritizeStreetNumber = false;
    } else {
      this.hideAddressOptions = false;
    }
    // Save the button action for later
    let mainButtonAction = this.activeCommand;
    const message =
      'You can add address data to all poles, use the polygon selection tool to select specific poles, ' +
      'or select node types to get address data for.';

    this.confirm('Add Address Data', message, 'Select Nodes', 'Cancel', '', 'addAddressData', () => {
      this.selectedNode = null;
      this.activeCommand = '_multiSelectItems';
      this.multiSelectIncludedTypes = {
        nodes: true,
        sections: false,
        connections: false
      };

      this.$.katapultMap.openActionDialog({
        title: this.actionDialogModel.label,
        text:
          'Click nodes or draw a polygon around them to add address data. Right click to delete polygon points. ' +
          'Only included node types will receive address data (default: Pole and Reference).',
        buttons: [
          {
            title: 'Cancel',
            callback: this.cancelPromptAction.bind(this),
            attributes: { outline: '' }
          },
          {
            title: 'Included Node Types',
            callback: () => {
              this.openNodeTypeSelectDialog({ actionType: 'addressDataFilter' });
            },
            attributes: { outline: '' }
          },
          {
            title: 'Finish',
            callback: () => {
              // Restore the button action to the active command because the addAddressDataToNodes function needs it
              this.activeCommand = mainButtonAction;
              let nodeList = this.$.katapultMap.multiSelectedNodes;
              // Get selected node types from checkboxes
              let selectedNodeTypes = [];
              const nodeTypesCheckboxes = this.$.nodeTypesList.querySelectorAll('paper-checkbox');

              // If our query for the checkboxes returned nothing, the dialog was never opened; default to poles and references
              if (nodeTypesCheckboxes.length == 0)
                selectedNodeTypes = [...this.modelDefaults.pole_node_types, ...this.modelDefaults.reference_node_types];
              // Otherwise, add all types that have been selected
              else
                selectedNodeTypes = Array.from(nodeTypesCheckboxes)
                  .filter((x) => x.checked)
                  .map((x) => x.dataset.type);

              // Filter to selected node types
              nodeList = nodeList.filter((nodeId) =>
                selectedNodeTypes.includes(
                  PickAnAttribute(Path.get(this.nodes[nodeId], `attributes`), this.modelDefaults.node_type_attribute)
                )
              );
              // Add address data
              this.addAddressDataToNodes(nodeList);
            },
            attributes: { 'secondary-color': '' }
          }
        ]
      });
    });
  }

  alreadyExists(nodeData, dataType) {
    return PickAnAttribute(nodeData, dataType) != null && PickAnAttribute(nodeData, dataType) != '';
  }

  _button_scrape_photo_data(e) {
    this.selectedNode = null;
    this.activeCommand = '_multiSelectItems';
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: false
    };
    // Set the prompt for the user
    this.$.katapultMap.openActionDialog({
      text: 'Click Nodes or Draw a Polygon Around Them to Scrape Photo Data. Right click to delete polygon points.',
      buttons: [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Scrape all', callback: this.scrapeAllNodes.bind(this), attributes: { 'secondary-color': '' } },
        { title: 'Scrape Selected', callback: this.scrapeSelectedNodes.bind(this), attributes: { 'secondary-color': '' } }
      ]
    });
  }

  async scrapeAllNodes() {
    this.toast('Scraping Photo Data...');
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    this.createWebWorker('scrapeSelectedNodes', 'scrape_photo_data', [
      this.nodes,
      photos,
      this.otherAttributes,
      this.modelDefaults,
      this.jobStyles,
      this.userGroup,
      this.jobName,
      this.isUtilityReviewContractor
    ]);
  }

  async scrapeSelectedNodes() {
    this.toast('Scraping Photo Data...');
    this.multiSelectedNodes = [];
    this.multiSelectedNodes = this.$.katapultMap.multiSelectedNodes;
    let nodes = {};
    // Add nodes to nodesToAssociate
    for (let i = 0; i < this.multiSelectedNodes.length; i++) {
      // Add the node data for the key in multiSelectedNodes
      nodes[this.multiSelectedNodes[i]] = this.nodes[this.multiSelectedNodes[i]];
    }
    let photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);
    this.createWebWorker('scrapeSelectedNodes', 'scrape_photo_data', [
      nodes,
      photos,
      this.otherAttributes,
      this.modelDefaults,
      this.jobStyles,
      this.userGroup,
      this.jobName,
      this.isUtilityReviewContractor
    ]);
  }

  async _finishedScrapeNodes(error, warnings, nodeList, nodes) {
    this.cancelPromptAction();
    await import('./qc-checks.js');

    setTimeout(async () => {
      const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
    }, 1);

    let title = 'Scrape Photos Results';
    let errors = error ? [error] : [];

    let nodeWarnings = Object.keys(warnings).map((x) => {
      return this.$.qcChecks.buildWarningGroup(x, nodes[x], 'node', warnings[x], null);
    });

    let initialReport = { title, errors, nodeWarnings };

    this.runQCCheck('check_scraped_data', { initialReport }, nodes, {});
  }

  async _button_calc_ground_line(e) {
    try {
      this.toast('Calculating groundlines...');
      var update = {};
      var speciesLookup = {
        SP: 'SYP',
        Pine: 'SYP',
        DF: 'DFir',
        Fir: 'DFir',
        WC: 'WRC',
        Cedar: 'WRC'
      };
      let requests = [];
      for (var nodeId in this.nodes) {
        var type = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute);
        if (this.modelDefaults.pole_node_types.includes(type)) {
          var pole_class = PickAnAttribute(this.nodes[nodeId].attributes, 'pole_class');
          var pole_height = PickAnAttribute(this.nodes[nodeId].attributes, 'pole_height');
          var pole_species = PickAnAttribute(this.nodes[nodeId].attributes, 'pole_species');
          var species = null;
          if (pole_class == null && pole_height == null && pole_species == null) {
            update['jobs/' + this.job_id + '/nodes/' + nodeId + '/attributes/warning/calculated_groundline_circumference'] =
              'No birthmark data. Cannot lookup groundline circumference.';
          } else {
            for (var start in speciesLookup) {
              if (pole_species != null && pole_species.indexOf(start) == 0) {
                species = speciesLookup[start];
                break;
              }
            }
            if (pole_height != '' && pole_class != '' && species != null) {
              requests.push(
                FirebaseWorker.ref('utility_info/ansi_specification/pole_size/' + pole_class + '/' + pole_height + '/' + species)
                  .once('value')
                  .then(
                    function (nodeId, snapshot) {
                      var groundline = snapshot.val();
                      var calculatedGroundlineAlreadyExists = this.alreadyExists(
                        this.nodes[nodeId].attributes,
                        'calculated_groundline_circumference'
                      );
                      if (groundline == null) {
                        if (!calculatedGroundlineAlreadyExists)
                          update['jobs/' + this.job_id + '/nodes/' + nodeId + '/attributes/calculated_groundline_circumference/one'] = '';
                        if (!calculatedGroundlineAlreadyExists)
                          update['jobs/' + this.job_id + '/nodes/' + nodeId + '/attributes/warning/calculated_groundline_circumference'] =
                            'No pole_groundline_circumference found for this Height, Class, and Species.';
                      } else {
                        if (!calculatedGroundlineAlreadyExists)
                          update['jobs/' + this.job_id + '/nodes/' + nodeId + '/attributes/calculated_groundline_circumference/one'] =
                            groundline;
                      }
                    }.bind(this, nodeId)
                  )
              );
            } else {
              var calculatedGroundlineAlreadyExists = this.alreadyExists(
                this.nodes[nodeId].attributes,
                'calculated_groundline_circumference'
              );
              if (!calculatedGroundlineAlreadyExists)
                update['jobs/' + this.job_id + '/nodes/' + nodeId + '/attributes/warning/calculated_groundline_circumference'] =
                  'Invalid birthmark data. Cannot lookup groundline circumference.';
              if (!calculatedGroundlineAlreadyExists)
                update['jobs/' + this.job_id + '/nodes/' + nodeId + '/attributes/calculated_groundline_circumference/one'] = '';
            }
          }
        }
      }
      await Promise.all(requests);
      if (this.jobCreator && !this.otherAttributes.calculated_groundline_circumference) {
        let hasPermission = await FirebaseWorker.ref(
          `photoheight/company_space/${this.jobCreator}/companies_with_write_model_access/${this.userGroup}`
        )
          .once('value')
          .then((s) => s.val());
        if (hasPermission) {
          update[`company_space/${this.jobCreator}/models/attributes/calculated_groundline_circumference`] = {
            attribute_types: ['node'],
            gui_element: 'textbox',
            placeholder: 'enter calculated glc',
            editability: 'uneditable',
            priority: 250,
            required_permission: 'write',
            type: 'number'
          };
        }
      }
      await FirebaseWorker.ref('photoheight').update(update);
      this.toast('Done calculating groundlines');
      this.set('nodeLabels.warning', true);
      setTimeout(async () => {
        const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
        await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
      }, 1);
      this.cancelPromptAction();
    } catch (error) {
      this.toast(error);
      this.cancelPromptAction();
    }
  }

  tag_cleaner(tag) {
    var tag = tag.toUpperCase().replace(/[\W_A-MO-RT-Z]/g, '');
    var valid = tag.search(/^\d{5}(N|S)\d{5}$/) == 0;
    return {
      valid: valid,
      tag: tag
    };
  }

  _button_make_section_primary(e) {
    this.activeCommand = '_makeSectionPrimary';
    this.$.katapultMap.openActionDialog({ text: 'Select a Section to Make Primary' });
  }

  makeSectionPrimary() {
    if (this.activeSection !== 'midpoint_section' && this.job_id) {
      let conn = this.get(`connections.${this.activeConnection}`);
      let sections = SquashNulls(conn, 'sections');
      let update = {};
      let newKey = null;

      // Check to see if midpoint_section object exists, if it does move that data to a new key
      if (sections.midpoint_section) {
        newKey = this.$.connections.ref.child(this.activeConnection + '/sections').push().key;
        update['/connections/' + this.activeConnection + '/sections/' + newKey] = sections.midpoint_section;
        GeofireTools.setGeohash('sections', sections.midpoint_section, this.activeConnection, this.jobStyles, update, {
          sectionId: newKey
        });
      }

      // Move the current section to midpoint_section
      update['/connections/' + this.activeConnection + '/sections/midpoint_section'] = sections[this.activeSection];
      GeofireTools.setGeohash('sections', sections[this.activeSection], this.activeConnection, this.jobStyles, update, {
        sectionId: 'midpoint_section'
      });
      update['/connections/' + this.activeConnection + '/sections/' + this.activeSection] = null;
      update['/geohash/' + this.activeConnection + ':' + this.activeSection] = null;

      // Check photos associated with sections and update associated locations accordingly
      for (let section in sections) {
        if (!sections[section].photos) continue;

        // For each photo associated to the old midspan_section, change associated location to newKey
        if (section == 'midpoint_section') {
          for (let photo in sections[section].photos) {
            update['/photos/' + photo + '/associated_locations/' + this.activeConnection + ':' + newKey] = 'section';

            // check to make sure the same photo isn't already associated to our new section before removing midpoint_section key
            if (!sections[this.activeSection].photos || !sections[this.activeSection].photos[photo]) {
              update['/photos/' + photo + '/associated_locations/' + this.activeConnection + ':midpoint_section'] = null;
            }
          }
        }

        // for each photo associated to the new midspan_section, change associated location to midspan_section
        else if (section == this.activeSection) {
          for (let photo in sections[section].photos) {
            update['/photos/' + photo + '/associated_locations/' + this.activeConnection + ':midpoint_section'] = 'section';
            update['/photos/' + photo + '/associated_locations/' + this.activeConnection + ':' + section] = null;
          }
        }
      }
      FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update, (e) => {
        if (e) this.toast(e);
      });
      this.cancelPromptAction();
    }
  }

  _button_measure_path_length(e) {
    if (this.selectedNode) this.selectedNode = null;
    this.findPathLengthNodes = [];
    this.$.katapultMap.openActionDialog({ text: 'Please select a starting node for measurement' });
  }

  async _button_fix_map_errors(e) {
    this.toast('Fixing map errors...');
    let result = await FixMapErrors.run(
      this.job_id,
      this.map.getProjection(),
      this.jobStyles,
      this.jobCreator,
      this.modelConfig,
      this.inputModels,
      this.traces,
      this.traceModels
    );

    result = result.split('.').join('.\n');

    this.closeToast();

    KatapultDialog.alert({
      body: result,
      dialog: {
        title: 'Errors Found',
        color: 'var(--paper-red-500)',
        icon: 'report'
      }
    });

    this.cancelPromptAction();
  }

  _button_upload_spreadsheet(e) {
    this.toast('Please select a CSV, XLS, or XLSX to import node locations.');
    this.promptForFiles(
      '.csv,.xls,.xlsx',
      false,
      function (files) {
        if (files != null) {
          this.$.dropDetector.$.jobUploader.readFiles(files);
        }
      }.bind(this)
    );
    this.cancelPromptAction();
  }

  promptForFiles(accept, multiple, callback) {
    this.acceptedFileInputTypes = accept;
    this.acceptMultipleFileInputs = multiple;
    this.filesChosenCallback = callback || function () {};
    this.$.fileInput.click();
  }

  filesChosen(e) {
    this.filesChosenCallback(e.target.files);
    this.$.fileInput.value = null;
  }

  async _button_quality_control(e) {
    if (this.job_id) {
      await import('../quality-control/quality-control.js');
      this.$.qualityControl.refresh();
      this.qcSelection = 0;
      this.$.qcDialog.open();
    }
    this.cancelPromptAction();
  }

  async _button_invoice_ppl(e) {
    this.$.pplInvoiceDialog.close();
    await import('./invoice-ppl.js');
    this.pplInvoice = null;
    var called = false;
    var app_no = this.jobName.split('_')[1];
    // Check if an app number was found in the job name
    if (app_no != null) {
      this.$.pplInvoiceDialog.open();
      var context;
      var key = FirebaseWorker.ref('photoheight/server_requests/context_layers/requests').push({
        search: app_no,
        userGroup: this.userGroup
      }).key;
      FirebaseWorker.ref('photoheight/server_requests/context_layers/responses/' + key).on(
        'value',
        function (snapshot) {
          var contextLayers = snapshot.val();
          if (contextLayers != null) {
            if (contextLayers.status == 'success') {
              context = contextLayers.poles;
            } else {
              this.toast('Error: context layers not found');
            }
            snapshot.ref.remove();
          }
          var job_info = {
            sharing: this.sharedCompanies,
            status: this.status
          };
          if (!(typeof context === 'undefined') && !called) {
            this.pplInvoice = this.$.invoicePPL.calc_ppl_dollars(this.nodes, this.connection, this.jobName);
            this.$.pplInvoiceDialog.notifyResize();
            this.cancelPromptAction();
            called = true;
          }
        }.bind(this)
      );
    } else {
      // Show a warning that the job is not valid
      this.toast('This job is not a valid PPL job. The job name must start with "APP"');
    }
  }

  _button_qc_ppl(e) {
    this.$.pplQcDialog.close();
    this.toast('This QC PPL button has been deprecated. Please use the new QC PPL button.');
  }

  async _button_ppl_billing(e) {
    this.$.pplInvoiceDialog.close();
    this.pplInvoice = null;
    await PPLBilling(this.nodes, this.connections, this.job_id, this.activeCommandModel.id).then((invoiceData) => {
      this.pplInvoice = invoiceData;
    });
    this.$.pplInvoiceDialog.open();
    this.cancelPromptAction();
  }

  async updatePCIAmountInvoiced(e) {
    let amount = e.target.dataset.amount;
    let oldAmount =
      (await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/metadata/PCI_amount_invoiced`)
        .once('value')
        .then((s) => s.val())) || 0;
    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/metadata`).update({
      PCI_amount_invoiced: parseFloat(amount) + parseFloat(oldAmount)
    });
  }

  async _button_qc_spimcg(e) {
    await import('./qc-spimcg.js');
    var job_info = {
      sharing: this.sharedCompanies,
      status: this.status
    };
    this.$.pplQcDialog.close();
    GetJobData(this.job_id, 'photos').then((data) => {
      this.qcPPLReport = this.$.qcSPIMCG.run_spimcg_qc(
        this.nodes,
        this.connection,
        data.photos,
        this.jobName,
        job_info,
        this.otherAttributes
      );
      this.$.pplQcDialog.open();
      this.cancelPromptAction();
    });
  }

  _button_adss_qc(e) {
    // QC Check for the ADSS Katapult job model
    this.runQCCheck('adss_qc');
  }

  async _button_check_guying(e) {
    await import('./guying-check.js');
    var guying_update = this.$.guyingCheck.run_guying_check(this.nodes, this.connections);
    this.$.nodes.ref.update(
      guying_update,
      function (err) {
        this.cancelPromptAction();
        if (err) {
          this.toast(err);
        } else {
          this.toast('Guying Check Complete');
        }
      }.bind(this)
    );
  }

  async _button_calc_bellspecs(e) {
    await import('./calc-bellspecs.js');
    var pull_update = this.$.calcBellSpecs.calc_bell_specs(this.nodes, this.connections);
    this.$.nodes.ref.update(
      pull_update,
      function (err) {
        this.cancelPromptAction();
        if (err) {
          this.toast(err);
        } else {
          this.toast('Pull inserted into appropriate poles');
        }
      }.bind(this)
    );
  }

  pplQCWarningsExist(warningsArray) {
    if (warningsArray && warningsArray.length > 0) return true;
    return false;
  }

  pplQCMissingsPolesExist(polesArray) {
    if (polesArray && polesArray.length > 0) return true;
    return false;
  }

  pplQCPoleWarningsExist(warnings) {
    var warningsExist = false;
    if (warnings) {
      for (var key in warnings) {
        if (warnings[key].warnings.length > 0) {
          warningsExist = true;
          break;
        }
      }
    }
    return warningsExist;
  }

  getPoleWarningNodes(warnings) {
    var warningsArray = [];
    for (var key in warnings) {
      if (warnings[key].warnings.length > 0) {
        if (warnings[key].key == null) warnings[key].key = key;
        warningsArray.push(warnings[key]);
      }
    }
    return warningsArray;
  }

  getMainPhotoFromNodeId(nodeID) {
    if (this.nodes && this.nodes[nodeID] && this.nodes[nodeID].photos) {
      for (var photo in this.nodes[nodeID].photos) {
        if (this.nodes[nodeID].photos[photo] === 'main' || this.nodes[nodeID].photos[photo].association === 'main') {
          return photo;
        }
      }
    }
    return null;
  }

  _button_measure_heights(e) {
    this.cableTracing = true;
    this.nodeLabels = { scid: true };
    var connLookup = {};
    for (var connId in this.connections) {
      connLookup[this.connections[connId].node_id_1] = connLookup[this.connections[connId].node_id_1] || [];
      connLookup[this.connections[connId].node_id_1].push({
        connId: connId,
        toScid: PickAnAttribute(this.nodes[this.connections[connId].node_id_2].attributes, 'scid')
      });
      connLookup[this.connections[connId].node_id_2] = connLookup[this.connections[connId].node_id_2] || [];
      connLookup[this.connections[connId].node_id_2].push({
        connId: connId,
        toScid: PickAnAttribute(this.nodes[this.connections[connId].node_id_1].attributes, 'scid')
      });
    }
    var firstNode;
    for (var nodeId in this.nodes) {
      var scid = PickAnAttribute(this.nodes[nodeId].attributes, 'scid');
      if (connLookup[nodeId] != null && scid != null && (firstNode == null || scid < firstNode.scid)) {
        firstNode = {
          nodeId: nodeId,
          scid: scid
        };
      }
    }
    var firstConn;
    if (firstNode != null) {
      for (var i = 0; i < connLookup[firstNode.nodeId].length; i++) {
        if (
          connLookup[firstNode.nodeId][i].toScid != null &&
          (firstConn == null || connLookup[firstNode.nodeId][i].toScid < firstConn.toScid)
        ) {
          firstConn = connLookup[firstNode.nodeId][i];
        }
      }
    }
    if (firstConn != null) this.selectCableTrace(firstConn.connId);
    else if (this.connectionKeys.length > 0) this.selectCableTrace(this.connectionKeys[0]);
    else this.toast('No Connections to open for Measuring Heights.');

    this.activeCommand = null;
  }

  selectCableTrace(connId) {
    // Tell katapult-map to highlight the connection
    this.$.katapultMap.$.loadRenderMap.toggleHighlightTraceOverlay(connId, this.connectionHighlight);
    this.connectionHighlight = connId;
    // Create an object with the data for the trace
    var active_trace = {
      connection: connId,
      job: this.job_id,
      node_id_1: this.connections[connId].node_id_1,
      node_id_2: this.connections[connId].node_id_2
    };
    // Loop through all the connections and find the photo paths that are associated with the connection
    var connected_photos = [];
    for (var cid in this.connections) {
      var connType = PickAnAttribute(this.connections[cid].attributes, this.modelDefaults.connection_type_attribute);
      const downGuyConnectionTypes = this.modelDefaults.downguy_connection_types;
      const proposedDownGuyConnectionTypes = this.modelDefaults.proposed_downguy_connection_types;
      const disallowedConnectionTypes = [...downGuyConnectionTypes, ...proposedDownGuyConnectionTypes, 'pushbrace'];
      if (
        cid != connId &&
        !disallowedConnectionTypes.includes(connType) &&
        (this.connections[cid].node_id_1 == active_trace.node_id_1 ||
          this.connections[cid].node_id_1 == active_trace.node_id_2 ||
          this.connections[cid].node_id_2 == active_trace.node_id_1 ||
          this.connections[cid].node_id_2 == active_trace.node_id_2)
      ) {
        for (var sectionId in this.connections[cid].sections) {
          for (var photoId in this.connections[cid].sections[sectionId].photos) {
            if (
              this.connections[cid].sections[sectionId].photos[photoId] == 'main' ||
              this.connections[cid].sections[sectionId].photos[photoId].association == 'main'
            )
              connected_photos.push(photoId);
          }
        }
      }
    }
    // Add the photo data to the active_trace object
    active_trace.connected_photos = connected_photos;
    // Update the user data
    FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid).update(
      {
        viewer_active_trace: active_trace,
        active_trace
      },
      function (error) {
        if (error) console.log('error', error);
      }
    );
    // Launch photofirst
    if (this.linkedWindow == null || !this.linkedWindow.opener || this.linkedWindow.opener.closed) {
      this.openPhotoFirst({ currentTarget: { id: 'null' } }, { connectionId: connId });
    }
  }

  selectConnectionTrace(connId) {
    this.connectionHighlight = connId;
    // Create an object with the data for the trace
    var active_trace = {
      connection: connId,
      job: this.job_id,
      node_id_1: this.connections[connId].node_id_1,
      node_id_2: this.connections[connId].node_id_2
    };
    if (this.connectionTraceStore.length < 2) {
      this.connectionTraceStore.push(active_trace);
      this.$.katapultMap.$.loadRenderMap.toggleHighlightTraceOverlayConn(connId, this.connectionHighlight);
    }
    if (this.connectionTraceStore.length === 2) {
      this.line_angle_calculation();
      this.$.katapultMap.closeActionDialog();
      this.cancelPromptAction();
    }
  }

  getPicklist(attribute_model) {
    var picklist = [];
    if (attribute_model != null) {
      for (var picklistId in attribute_model.picklists) {
        picklist = picklist.concat(attribute_model.picklists[picklistId]);
      }
    }
    return picklist;
  }

  _button_ppl_mr_view(e) {
    this.set_mr_messages('warning', 'PPL', true);
  }

  getMainPhotoKey(obj) {
    if (obj && obj.photos) for (let id in obj.photos) if (obj.photos[id] == 'main' || obj.photos[id].association == 'main') return id;
    return null;
  }

  _button_insert_sections(e) {
    this.confirm(
      'Insert Sections',
      'Are you sure you want to insert sections on all the connections in the job?',
      'Insert ',
      'Cancel',
      '',
      '',
      async () => {
        if (this.job_id != null && this.job_id != '') {
          var update = {};
          for (var conn in this.connections) {
            if (this.connections[conn].sections == null) {
              var connType = PickAnAttribute(this.connections[conn].attributes, this.modelDefaults.connection_type_attribute);
              const downGuyConnectionTypes = this.modelDefaults.downguy_connection_types;
              const proposedDownGuyConnectionTypes = this.modelDefaults.proposed_downguy_connection_types;
              const disallowedConnectionTypes = [
                ...downGuyConnectionTypes,
                ...proposedDownGuyConnectionTypes,
                'pushbrace',
                'underground cable'
              ];
              if (!disallowedConnectionTypes.includes(connType)) {
                var node_id_1 = this.connections[conn].node_id_1;
                var node_id_2 = this.connections[conn].node_id_2;
                var n1 = this.nodes[node_id_1];
                var n2 = this.nodes[node_id_2];
                var mid = GetMidpointLatLng(
                  new google.maps.LatLng(n1.latitude, n1.longitude),
                  new google.maps.LatLng(n2.latitude, n2.longitude),
                  this.map.getProjection()
                );
                var section = {
                  longitude: mid.lng(),
                  latitude: mid.lat(),
                  multi_attributes: {}
                };
                var sectionAttributes = SquashNulls(this.modelConfig, 'section_attributes');
                for (var key in sectionAttributes) {
                  section.multi_attributes[key] = { button_added: sectionAttributes[key] };
                }
                GeofireTools.setGeohash('sections', section, conn, this.jobStyles, update, { sectionId: 'midpoint_section' });
                update['connections/' + conn + '/sections/midpoint_section'] = section;
                FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(
                  update,
                  function (error) {
                    if (error) {
                      this.toast(error);
                    } else {
                      this.toast('Done inserting sections.');
                    }
                  }.bind(this)
                );
              }
            }
          }
        }
        this.cancelPromptAction();
      }
    );
  }

  _button_calc_mr_violations(e, callback) {
    GetJobData(this.job_id, 'photos').then((data) => {
      if (data.photos) {
        for (var nodeId in this.nodes) {
          const node = this.nodes[nodeId];
          let photoId = this.getMainPhotoKey(node);
          if (data.photos[photoId]) {
            this.$.multiMrCalcs.computePhotoLookups(node, data.photos[photoId], photoId, 'node');
            this.$.multiMrCalcs.calcPhotoClearances(node, nodeId, null, 'node', this.modelConfig);

            // Check that the node is a pole
            if (this.modelDefaults.pole_node_types.includes(PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute))) {
              // Check if there are models for the button
              if (SquashNulls(this.mappingButtons, this.activeCommand, 'models')) {
                // Loop through the attributes in the buttons models to add them
                for (let attributeName in this.mappingButtons[this.activeCommand].models) {
                  // Skip cost_causer, mr_category, and FCC_category if pole is marked added for loading
                  const attributesToSkip = ['cost_causer', 'mr_category', 'FCC_category'];
                  if (attributesToSkip.includes(attributeName) && PickAnAttribute(node.attributes, 'added_for_loading') == true) {
                    continue;
                  }
                  // Check that there isn't already a value for the attribute
                  if (!PickAnAttribute(node.attributes, attributeName)) {
                    // Add the attribute to the node
                    let value = this.mappingButtons[this.activeCommand].models[attributeName] || '';
                    if (attributeName == 'cost_causer') {
                      value = {
                        cost: '',
                        explanation: '',
                        companies: {
                          [FirebaseWorker.ref().push().key]: { company: '', percentage: '' }
                        }
                      };
                    }
                    FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/nodes/${nodeId}/attributes/${attributeName}`).set({
                      button_added: value
                    });
                  }
                }
              }
            }
          }
        }
        for (var connId in this.connections) {
          for (var sectionId in this.connections[connId].sections) {
            const section = this.connections[connId].sections[sectionId];
            let photoId = this.getMainPhotoKey(section);
            if (data.photos[photoId]) {
              let distanceRatios = CalcPhotoDistanceRatios(connId, sectionId, this.nodes, this.connections);
              // Calc MR
              this.$.multiMrCalcs.computePhotoLookups(section, data.photos[photoId], photoId, 'connection', distanceRatios);
              this.$.multiMrCalcs.calcPhotoClearances(section, sectionId, connId, 'connection', this.modelConfig);
            }
          }
        }
        this.toast('Make Ready State Updated.');
      }
      this.cancelPromptAction();
      if (callback) callback();
    });
  }

  _button_line_angle_calculator(e) {
    this.connectionTraceStore = [];
    this.$.katapultMap.openActionDialog({ title: 'Click on the two connections whose angle calculations you would like to run' });
    this.connectionTracing = true;
  }

  line_angle_calculation() {
    // Define the first, middle, and last nodes for calculation.
    let nodeOne = '';
    let midNode = '';
    let endNode = '';
    let checkedNodes = [];

    // If node ID's overlap between 2 connections, it is the middle node.
    this.connectionTraceStore.forEach((connection) => {
      if (checkedNodes.includes(connection.node_id_1)) midNode = connection.node_id_1;
      else checkedNodes.push(connection.node_id_1);
      if (checkedNodes.includes(connection.node_id_2)) midNode = connection.node_id_2;
      else checkedNodes.push(connection.node_id_2);
    });

    // If there is no overlapping node ID, the selected spans are not adjacent to each other.
    if (!midNode) {
      this.toast('The connections selected are not adjacent to each other.');
      this.$.katapultMap.closeActionDialog();
      this.cancelPromptAction();
      return;
    }

    // Go through the rest of the nodes and assign them to the correct variables
    this.connectionTraceStore.forEach((connection) => {
      if (connection.node_id_1 != midNode) {
        if (!nodeOne) nodeOne = connection.node_id_1;
        else if (!endNode) endNode = connection.node_id_1;
      }
      if (connection.node_id_2 != midNode) {
        if (!nodeOne) nodeOne = connection.node_id_2;
        else if (!endNode) endNode = connection.node_id_2;
      }
    });

    // Pass lat and long to variables to be passed into function defined in LineAngleCalculations.js
    let node1 = { lat: this.nodes[nodeOne].latitude, lng: this.nodes[nodeOne].longitude };
    let nodeMid = { lat: this.nodes[midNode].latitude, lng: this.nodes[midNode].longitude };
    let node2 = { lat: this.nodes[endNode].latitude, lng: this.nodes[endNode].longitude };

    this.lineAngleResults = LineAngleCalculations(node1, nodeMid, node2);
    this.$.lineAngleCalculatorDialog.open();
  }

  async copyLineAngleCalc(e) {
    let resultId = e.currentTarget.id;
    navigator.clipboard.writeText(this.lineAngleResults[resultId]);
  }

  async _button_embedment_attribute(e) {
    const job = await KatapultJob.fromId(this.job_id);
    const photos = await job.getPhotos();
    let update = {};
    const nodeEntries = Object.entries(this.nodes ?? {});
    const nodeEntriesWithNodeTypePole = nodeEntries.filter(([nodeId, node]) => {
      const nodeType = PickAnAttribute(this.nodes?.[nodeId]?.attributes, 'node_type');
      return nodeType === 'pole';
    });
    nodeEntriesWithNodeTypePole.forEach(([nodeId, node]) => {
      const photoData = photos[GetMainPhoto(node?.photos)]?.photofirst_data;
      const poleTopHeight = Object.values(photoData?.pole_top || {})?.[0]?._measured_height || 0;
      const poleSpecHeight = PickAnAttribute(node?.attributes, 'pole_height') || 0;
      const poleHeightInInches = poleSpecHeight * 12;
      let embedmentHtDiff = '';
      // Check if pole top and pole height are available
      if (!poleTopHeight && !poleHeightInInches) embedmentHtDiff = 'No pole top marker or pole height found';
      else if (!poleHeightInInches) embedmentHtDiff = 'No pole height found';
      else if (!poleTopHeight) embedmentHtDiff = 'No pole top marker found';
      else embedmentHtDiff = FormatHeight(poleHeightInInches - poleTopHeight, 'decimal-feet', 2);

      // If the embedment attribute exists, reuse it's key. Otherwise generate a new key
      const key = node.attributes.embedment ? Object.keys(node.attributes.embedment)[0] : FirebaseWorker.ref().push().key;
      update[`nodes/${nodeId}/attributes/embedment/${key}/`] = embedmentHtDiff;
    });
    if (!this.job_id) return;
    await FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
  }

  _button_mr_state_prompt(e) {
    this._button_calc_mr_violations();
    // this.confirm('Set Make Ready State', null, 'Continue', 'Cancel', null, 'setMakeReadyState', this.setMakeReadyState.bind(this));
  }

  async setMakeReadyState() {
    await import('../make-ready-calculations/make-ready-calculations.js');
    // PURPOSE: Adds an fills out a mr_violation attribute on all nodes or sections containing a make ready violation,
    //          and removes all mr_violation attributes on nodes or sections which are not in make ready violation.

    // Get an array of all the sections from the connections retaining the connection and section key as {connKey: '', sectKey: '', item: {SECTION OBJECT}}.
    let sections = Object.keys(this.connections)
      .map((connKey) => ({ connKey, item: this.connections[connKey] }))
      .reduce((sections, connKeyItemPair) => {
        let connSections = Object.keys(connKeyItemPair.item.sections || {}).map((sectKey) => ({
          connKey: connKeyItemPair.connKey,
          sectKey,
          item: connKeyItemPair.item.sections[sectKey]
        }));
        return sections.concat(connSections);
      }, []);
    // Get an array of all of the nodes retaining the node key as {nodeKey: '', item: {node OBJECT}}.
    let nodes = Object.keys(this.nodes)
      .map((nodeKey) => ({ nodeKey, item: this.nodes[nodeKey] }))
      .filter((node) =>
        this.modelDefaults.pole_node_types.includes(PickAnAttribute(node.item.attributes, this.modelDefaults.node_type_attribute))
      );
    // Combine the nodes and sections into one array.
    let keyItemPairs = [].concat(nodes, sections);
    // List of photos.
    let photoPaths = [];
    // Get list of photos needed by items.
    keyItemPairs.forEach((keyItemPair) => {
      let item = keyItemPair.item;
      let photoKey = Object.keys(item.photos || {}).find(
        (photoKey) => item.photos[photoKey] == 'main' || item.photos[photoKey].association == 'main'
      );
      if (photoKey) photoPaths.push('photos/' + photoKey);
    });
    GetJobData(this.job_id, photoPaths).then((data) => {
      let finished = function (updateObject) {
        // Write update to firebase.
        FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(updateObject, async (err) => {
          if (!err) this.cancelPromptAction();
          const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
          await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
        });
      }.bind(this);

      // An update object to write to firebase.
      let update = {};
      let keyItemPairCounter = 0;
      // Loop through all nodes and sections.
      keyItemPairs.forEach((keyItemPair) => {
        let item = keyItemPair.item;
        // Get the photo key of the main photo on the item.
        let photoKey = Object.keys(item.photos || {}).find(
          (photoKey) => item.photos[photoKey] == 'main' || item.photos[photoKey].association == 'main'
        );
        let markerContexts = this.$.makeReadyCalculations.getMarkerContextsFromPhoto(photoKey, data['photos/' + photoKey]);
        let flattenedMarkerContexts = this.$.makeReadyCalculations.flattenMarkerContexts(markerContexts);
        let options = { photo: data['photos/' + photoKey] };
        if (keyItemPair.connKey) {
          options.leftPhotoId = this.getMainPhotoFromNodeId(n1Id);
          options.rightPhotoId = this.getMainPhotoFromNodeId(n2Id);
          options.distanceRatios = CalcPhotoDistanceRatios(keyItemPair.connKey, keyItemPair.sectKey, this.nodes, this.connections);
        }
        this.$.makeReadyCalculations.getMakeReadyDetails(flattenedMarkerContexts, options, (makeReadyInformation) => {
          let go95Details =
            this.includeGO95 && keyItemPair.nodeKey
              ? this.$.makeReadyCalculations.getGO95Details(keyItemPair.nodeKey, flattenedMarkerContexts)
              : null;
          let itemFbPath = keyItemPair.nodeKey
            ? 'nodes/' + keyItemPair.nodeKey + '/attributes/'
            : 'connections/' + keyItemPair.connKey + '/sections/' + keyItemPair.sectKey + '/multi_attributes/';
          // Populate attributes if make ready information exists.
          if (makeReadyInformation.length) {
            let clearanceViolationNotes = makeReadyInformation.filter((x) => x).reduce((notes, x) => notes.concat(x.notes), []);

            let noMakeReady =
              makeReadyInformation.every((x) => !x || (x.wasInViolation == null && !x.inViolation)) &&
              !SquashNulls(go95Details, 'wasInViolation');
            let resolvedMakeReady = makeReadyInformation.every((x) => !x || !x.inViolation);

            // Check if the pole had any mr violations marked
            let poleHasMRNotes = SquashNulls(go95Details, 'wasInViolation') == true;

            let currentMrState = PickAnAttribute(item.attributes || item.multi_attributes, 'mr_state');
            if (!currentMrState || currentMrState != 'MR Complex')
              update[itemFbPath + 'mr_state'] = {
                auto_button: poleHasMRNotes ? 'MR Resolved' : resolvedMakeReady ? 'MR Resolved' : 'MR Unresolved'
              };

            // Add make ready notes to update.
            if (go95Details) {
              let numMrNotes = 0;
              for (let key in SquashNulls(item, 'attributes', 'mr_note')) if (key.startsWith('mr_details_')) numMrNotes++;
              for (let i = 0; i < (go95Details.notes.length > numMrNotes ? go95Details.notes.length : numMrNotes); i++) {
                update[itemFbPath + 'mr_note/mr_details_' + i] = go95Details.notes[i] || null;
              }
            }
            // Add clearance violation notes to update.
            update[itemFbPath + 'mr_violation'] = {
              auto_button: !(resolvedMakeReady || noMakeReady) && clearanceViolationNotes.length ? clearanceViolationNotes.join('\n') : null
            };
          } else {
            // Clear attributes if no make ready information was returned for this item.
            update[itemFbPath + 'mr_state'] = update[itemFbPath + 'mr_violation'] = null;
          }
          if (++keyItemPairCounter == keyItemPairs.length) finished(update);
        });
      });
      if (keyItemPairCounter == keyItemPairs.length) finished(update);
    });
  }

  set_mr_messages(property, requiredOwner, addTwelveForNewCable) {
    GetJobData(this.job_id, 'photos').then((data) => {
      this.toast('Inserting MR Notes...');
      var requirements = {
        power_to_com: 40 + (addTwelveForNewCable ? 12 : 0),
        span_power_to_com: 30 + (addTwelveForNewCable ? 12 : 0),
        streetlight_to_com: 20,
        streetlight_drip_to_com: 12,
        streetlight_to_power: 6
      };
      var errorLog = [];
      //generate power / comm lookups (Power Guys Count as Com Cables)
      var powerCables = {};
      var commCables = {};
      var cableTypes = this.otherAttributes.cable_type.picklists;
      for (var i = 0; i < cableTypes.power.length; i++) {
        powerCables[cableTypes.power[i].value] = true;
      }
      for (var i = 0; i < cableTypes.communications.length; i++) {
        commCables[cableTypes.communications[i].value] = true;
      }
      var update = {};
      // put a warning on all poles if they are ppl_owned and have < 52" between top com and low power
      for (var nodeId in this.nodes) {
        var nodeWarning = '';
        var attributes = this.nodes[nodeId].attributes;
        let orderingAttribute =
          PickAnAttribute(attributes, this.modelDefaults.ordering_attribute) || `(No ${this.modelDefaults.ordering_attribute_label})`;
        if (this.modelDefaults.pole_node_types.includes(PickAnAttribute(attributes, this.modelDefaults.node_type_attribute))) {
          if (requiredOwner != null) {
            //check if correct owner
            var isRequiredOwner = false;
            for (var itemKey in attributes.pole_tag) {
              if (attributes.pole_tag[itemKey].company == requiredOwner && attributes.pole_tag[itemKey].owner) {
                isRequiredOwner = true;
                break;
              }
            }
          }
          // check clearances at pole
          if (requiredOwner == null || isRequiredOwner) {
            var mainPhoto = null;
            for (var photoId in this.nodes[nodeId].photos) {
              if (this.nodes[nodeId].photos[photoId] == 'main' || this.nodes[nodeId].photos[photoId].association == 'main') {
                mainPhoto = photoId;
                break;
              }
            }
            var mainPhotoData = SquashNulls(data.photos, mainPhoto, 'photofirst_data');
            var lowPower = Infinity;
            var topCom = 0;
            var streetLights = {};
            var streetLightDripLoops = [];
            for (var itemKey in mainPhotoData.power_hardware) {
              var marker = mainPhotoData.power_hardware[itemKey];
              var height = marker._measured_height || marker._manual_height;
              if (height != null && height != '') {
                if (marker.type == 'streetlight_bottom_of_bracket') {
                  if (marker._trace == null) {
                    errorLog.push(`Streetlight not traced together on ${this.modelDefaults.ordering_attribute_label} ${orderingAttribute}`);
                  } else {
                    streetLights[marker._trace] = streetLights[marker._trace] || {};
                    streetLights[marker._trace].bottom = Math.round(height);
                  }
                } else if (marker.type == 'streetlight_top_of_bracket') {
                  if (marker._trace == null) {
                    errorLog.push(`Streetlight not traced together on ${this.modelDefaults.ordering_attribute_label} ${orderingAttribute}`);
                  } else {
                    streetLights[marker._trace] = streetLights[marker._trace] || {};
                    streetLights[marker._trace].top = Math.round(height);
                  }
                } else if (marker.type == 'drip loop' && marker.drip_loop_type == 'streetlight') {
                  streetLightDripLoops.push(Math.round(height));
                } else if (height < lowPower) {
                  lowPower = height;
                }
              }
            }
            var comCables = [];
            for (var itemKey in mainPhotoData.pole_cable) {
              var marker = mainPhotoData.pole_cable[itemKey];
              var height = marker._measured_height || marker._manual_height || '';
              var cable_type = SquashNulls(this.traces, marker._trace, 'cable_type');
              if (marker.mr_class == 'low power') {
                cable_type = 'Power';
              } else if (marker.mr_class == 'top com') {
                cable_type = 'Com';
              }
              if (height != '' && cable_type != '' && cable_type != 'Power Guy') {
                if ((cable_type == 'Power' || powerCables[cable_type]) && height < lowPower) {
                  lowPower = height;
                } else if (cable_type == 'Com' || commCables[cable_type]) {
                  comCables.push(Math.round(height));

                  if (height > topCom) {
                    topCom = height;
                  }
                }
              }
            }
            topCom = Math.round(topCom);
            lowPower = Math.round(lowPower);
            for (var streetLightId in streetLights) {
              if (streetLights[streetLightId].top + requirements.streetlight_to_power > lowPower) {
                var dist = lowPower - streetLights[streetLightId].top;
                nodeWarning += 'Street Light ' + Math.abs(dist) + '" ' + (dist >= 0 ? 'from' : 'above') + ' power. ';
              }
              for (var i = 0; i < comCables.length; i++) {
                if (comCables[i] > streetLights[streetLightId].bottom && comCables[i] < streetLights[streetLightId].top) {
                  nodeWarning += 'Com threads through Street Light. ';
                } else if (
                  comCables[i] < streetLights[streetLightId].bottom &&
                  comCables[i] + requirements.streetlight_to_com + (addTwelveForNewCable && topCom == comCables[i] ? 12 : 0) >
                    streetLights[streetLightId].bottom
                ) {
                  nodeWarning += 'Com ' + (streetLights[streetLightId].bottom - comCables[i]) + '" from Street Light. ';
                } else if (
                  comCables[i] >= streetLights[streetLightId].top &&
                  comCables[i] - requirements.streetlight_to_com < streetLights[streetLightId].top
                ) {
                  nodeWarning += 'Com ' + (comCables[i] - streetLights[streetLightId].top) + '" from Street Light. ';
                }
              }
            }
            for (var i = 0; i < streetLightDripLoops.length; i++) {
              for (var j = 0; j < comCables.length; j++) {
                var dist = Math.abs(comCables[j] - streetLightDripLoops[i]);
                if (dist < requirements.streetlight_drip_to_com) {
                  nodeWarning += 'Com ' + dist + '" from Street Light Drip Loop. ';
                }
              }
            }
            if (topCom + requirements.power_to_com > lowPower) {
              nodeWarning += lowPower - topCom + '" between Com and Power. ';
            }
            if (nodeWarning != '') {
              update['nodes/' + nodeId + '/attributes/' + property + '/mr_check'] = nodeWarning;
            }
          }
        }
      }
      //put a warning on all midspans that have either a cable below 18' or have < 30" between top com and low power
      for (var connId in this.connections) {
        for (var sectionId in this.connections[connId].sections) {
          var mainPhoto = null;
          for (var photoId in this.connections[connId].sections[sectionId].photos) {
            if (
              this.connections[connId].sections[sectionId].photos[photoId] == 'main' ||
              this.connections[connId].sections[sectionId].photos[photoId].association == 'main'
            ) {
              mainPhoto = photoId;
              break;
            }
          }
          var mainPhotoData = SquashNulls(data.photos, mainPhoto, 'photofirst_data');
          var lowPower = Infinity;
          var lowCom = Infinity;
          var topCom = 0;
          var noComs = true;
          for (var itemKey in mainPhotoData.midspan_cable) {
            var marker = mainPhotoData.midspan_cable[itemKey];
            var height = marker._measured_height || marker._manual_height || '';
            var cable_type = SquashNulls(this.traces, marker._trace, 'cable_type');
            if (height != '' && cable_type != '' && cable_type != 'Power Guy') {
              if (powerCables[cable_type] && height < lowPower) {
                lowPower = height;
              } else if (commCables[cable_type]) {
                noComs = false;
                if (height > topCom) {
                  topCom = height;
                }
                if (height < lowCom) {
                  lowCom = height;
                }
              }
            }
          }
          topCom = Math.round(topCom);
          lowPower = Math.round(lowPower);
          lowCom = Math.round(lowCom);
          var sectionWarning = '';
          if (topCom + requirements.span_power_to_com > lowPower) {
            sectionWarning += lowPower - topCom + '" between Com and Power. ';
          }
          if (lowCom < 18 * 12) {
            sectionWarning += FormatHeight(lowCom, this.useMetricUnits == true ? 'meters' : 'feet-inches') + ' over ground. ';
          }
          // If there are no coms, and power is less than 18' + 30", the new com will go in below 18'
          if (addTwelveForNewCable && noComs && lowPower < 20.5 * 12) {
            sectionWarning += "New Cable below 18'. ";
          }
          if (sectionWarning != '') {
            update['connections/' + connId + '/sections/' + sectionId + '/multi_attributes/' + property + '/mr_check'] = sectionWarning;
          }
        }
      }

      if (this.job_id != null && this.job_id != '') {
        FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update, async (error) => {
          this.cancelPromptAction();
          if (error) {
            this.toast(error);
          } else {
            var labels = {};
            labels[property] = true;
            this.nodeLabels = labels;
            const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
            this.toast('Done Generating MR View.');
          }
        });
      }
    });
  }

  async _button_mr_details(e) {
    await import('../make-ready-details/make-ready-details.js');
    this.$.makeReadyDetails.begin();
    this.cancelPromptAction();
  }

  async _button_submit_to_coordinate_pa(e) {
    this.toast('Loading Coordinate PA API...');
    await import('./coordinate-pa-api.js');
    await this.$.coordinatePAapi.open();
    this.$.toast.close(0);
    this.cancelPromptAction();
  }

  _button_link_map_photo_data(e) {
    this.skipConfirm = false;
    // Loop to find the active button model since this can be called from /photos and therefore the activeCommand is not always the button key
    for (var key in this.mappingButtons) {
      if (this.mappingButtons[key].function == '_button_link_map_photo_data') {
        this.actionDialogModel = this.mappingButtons[key];
      }
    }
    if (this.userData && this.userData.hardware_details != null && this.userData.hardware_details.start_node != null) {
      var photofirstNodeId = this.userData.hardware_details.start_node;
      var ref = FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/user_data/${this.user.uid}`);
      ref.child('hardware_details').remove();
      this.HWDetailsSinglePole = true;
    } else {
      this.HWDetailsSinglePole = false;
      if (this.startingLinkingOrderingAttribute == '') {
        for (var nodeId in this.nodes) {
          var orderingAttribute = PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.ordering_attribute);
          if (
            orderingAttribute != null &&
            (this.startingLinkingOrderingAttribute == '' || orderingAttribute < this.startingLinkingOrderingAttribute)
          ) {
            this.startingLinkingOrderingAttribute = orderingAttribute;
          }
        }
      }
    }
    if (this.already_confirmed_hw_detail_options) {
      if (this.HWDetailsSinglePole) {
        this.skipConfirm = true;
      }
    } else {
      this.already_confirmed_hw_detail_options = true;
      this.linkDownguys = true;
      this.enterHardwareAngles = true;
      this.enterPowerSpec = true;
      this.skipDoneMapPhotoLinks = true;
      this.enterCrossArmLengths = true;
      this.doStreetLightLengths = true;
      this.doEquipmentSizes = true;
    }
    let doHwDetails = function () {
      GetJobData(this.job_id, 'photos').then((data) => {
        var hwDetail = new HardwareDetails();
        hwDetail.setOptions(
          this.skipDoneMapPhotoLinks,
          this.linkDownguys,
          this.enterHardwareAngles,
          this.enterPowerSpec,
          this.enterCrossArmLengths,
          this.doStreetLightLengths,
          this.doEquipmentSizes
        );
        if (this.HWDetailsSinglePole) {
          if (this.linkDownguys || this.enterPowerSpec) {
            var downGuysOnPole = {};
            var connectionsOnPole = {};
            for (var connection in this.connections) {
              let connType = PickAnAttribute(this.connections[connection].attributes, this.modelDefaults.connection_type_attribute);
              if (
                (this.linkDownguys && this.modelDefaults.downguy_connection_types.includes(connType)) ||
                this.modelDefaults.proposed_downguy_connection_types.includes(connType)
              ) {
                if (this.connections[connection].node_id_1 === photofirstNodeId) {
                  let n1 = new google.maps.LatLng(
                    this.nodes[this.connections[connection].node_id_1].latitude,
                    this.nodes[this.connections[connection].node_id_1].longitude
                  );
                  let n2 = new google.maps.LatLng(
                    this.nodes[this.connections[connection].node_id_2].latitude,
                    this.nodes[this.connections[connection].node_id_2].longitude
                  );
                  downGuysOnPole[this.connections[connection].node_id_2] = {
                    node: this.nodes[this.connections[connection].node_id_2],
                    bearing: Round((google.maps.geometry.spherical.computeHeading(n1, n2) + 360) % 360, 1),
                    length: Round(google.maps.geometry.spherical.computeDistanceBetween(n1, n2) / 0.3048, 1)
                  };
                } else if (this.connections[connection].node_id_2 === photofirstNodeId) {
                  let n1 = new google.maps.LatLng(
                    this.nodes[this.connections[connection].node_id_1].latitude,
                    this.nodes[this.connections[connection].node_id_1].longitude
                  );
                  let n2 = new google.maps.LatLng(
                    this.nodes[this.connections[connection].node_id_2].latitude,
                    this.nodes[this.connections[connection].node_id_2].longitude
                  );
                  downGuysOnPole[this.connections[connection].node_id_1] = {
                    node: this.nodes[this.connections[connection].node_id_1],
                    bearing: Round((google.maps.geometry.spherical.computeHeading(n2, n1) + 360) % 360, 1),
                    length: Round(google.maps.geometry.spherical.computeDistanceBetween(n1, n2) / 0.3048, 1)
                  };
                }
              }
              if (
                this.enterPowerSpec &&
                (this.connections[connection].node_id_1 === photofirstNodeId || this.connections[connection].node_id_2 === photofirstNodeId)
              ) {
                connectionsOnPole[connection] = this.connections[connection];
              }
            }
          }
          var photoId = this.getMainPhotoFromNodeId(photofirstNodeId);
          if (downGuysOnPole == {}) {
            downGuysOnPole = null;
          }
          if (connectionsOnPole == {}) {
            connectionsOnPole = null;
          }
          hwDetail.addPoleToQueue(
            this.nodes[photofirstNodeId],
            photofirstNodeId,
            data.photos[photoId],
            photoId,
            downGuysOnPole,
            true,
            this.hwDetailsOptions || {}
          );
          var sectionPhotos = {};
          if (connectionsOnPole != null) {
            for (var connection in connectionsOnPole) {
              if (connectionsOnPole[connection].sections) {
                for (var section in connectionsOnPole[connection].sections) {
                  if (connectionsOnPole[connection].sections[section].photos) {
                    for (var photo in connectionsOnPole[connection].sections[section].photos) {
                      if (
                        connectionsOnPole[connection].sections[section].photos[photo] === 'main' ||
                        connectionsOnPole[connection].sections[section].photos[photo].association === 'main'
                      ) {
                        sectionPhotos[photo] = data.photos[photo];
                      }
                    }
                  }
                }
              }
              hwDetail.addConnectionToQueue(
                connectionsOnPole[connection],
                connection,
                sectionPhotos,
                this.otherAttributes,
                this.traces,
                true,
                this.nodes[connectionsOnPole[connection].node_id_1],
                this.nodes[connectionsOnPole[connection].node_id_2]
              );
            }
          }
        } else {
          for (var nodeId in this.nodes) {
            if (
              !this.modelDefaults.anchor_node_types.includes(
                PickAnAttribute(this.nodes[nodeId].attributes, this.modelDefaults.node_type_attribute)
              )
            ) {
              var downGuysOnPole = {};
              for (var connection in this.connections) {
                let connType = PickAnAttribute(this.connections[connection].attributes, this.modelDefaults.connection_type_attribute);
                if (
                  (this.linkDownguys && this.modelDefaults.downguy_connection_types.includes(connType)) ||
                  this.modelDefaults.proposed_downguy_connection_types.includes(connType)
                ) {
                  if (this.connections[connection].node_id_1 === nodeId) {
                    let n1 = new google.maps.LatLng(
                      this.nodes[this.connections[connection].node_id_1].latitude,
                      this.nodes[this.connections[connection].node_id_1].longitude
                    );
                    let n2 = new google.maps.LatLng(
                      this.nodes[this.connections[connection].node_id_2].latitude,
                      this.nodes[this.connections[connection].node_id_2].longitude
                    );
                    downGuysOnPole[this.connections[connection].node_id_2] = {
                      node: this.nodes[this.connections[connection].node_id_2],
                      bearing: Round((google.maps.geometry.spherical.computeHeading(n1, n2) + 360) % 360, 1),
                      length: Round(google.maps.geometry.spherical.computeDistanceBetween(n1, n2) / 0.3048, 1)
                    };
                  } else if (this.connections[connection].node_id_2 === nodeId) {
                    let n1 = new google.maps.LatLng(
                      this.nodes[this.connections[connection].node_id_1].latitude,
                      this.nodes[this.connections[connection].node_id_1].longitude
                    );
                    let n2 = new google.maps.LatLng(
                      this.nodes[this.connections[connection].node_id_2].latitude,
                      this.nodes[this.connections[connection].node_id_2].longitude
                    );
                    downGuysOnPole[this.connections[connection].node_id_1] = {
                      node: this.nodes[this.connections[connection].node_id_1],
                      bearing: Round((google.maps.geometry.spherical.computeHeading(n2, n1) + 360) % 360, 1),
                      length: Round(google.maps.geometry.spherical.computeDistanceBetween(n1, n2) / 0.3048, 1)
                    };
                  }
                }
              }
              if (downGuysOnPole == {}) {
                downGuysOnPole = null;
              } else {
              }
              if (connectionsOnPole == {}) {
                connectionsOnPole = null;
              }
              hwDetail.addPoleToQueue(
                this.nodes[nodeId],
                nodeId,
                data.photos[this.getMainPhotoFromNodeId(nodeId)],
                this.getMainPhotoFromNodeId(nodeId),
                downGuysOnPole,
                true,
                this.hwDetailsOptions || {}
              );
            }
          }
          for (var connId in this.connections) {
            var sectionPhotos = {};
            if (this.connections[connId].sections) {
              for (var section in this.connections[connId].sections) {
                if (this.connections[connId].sections[section].photos) {
                  for (var photo in this.connections[connId].sections[section].photos) {
                    if (
                      this.connections[connId].sections[section].photos[photo] === 'main' ||
                      this.connections[connId].sections[section].photos[photo].association === 'main'
                    ) {
                      sectionPhotos[photo] = data.photos[photo];
                    }
                  }
                }
              }
            }
            hwDetail.addConnectionToQueue(
              this.connections[connId],
              connId,
              sectionPhotos,
              this.otherAttributes,
              this.traces,
              true,
              this.nodes[this.connections[connId].node_id_1],
              this.nodes[this.connections[connId].node_id_2]
            );
          }
        }
        var linkMapPhotoActions = hwDetail.start();
        this.cableTracing = true;

        var startIndex = undefined;
        var endIndex = undefined;
        var foundEndOrderingAttribute = false;
        for (var i = 0; i < linkMapPhotoActions.length; i++) {
          if (
            startIndex == null &&
            linkMapPhotoActions[i][this.modelDefaults.ordering_attribute] != null &&
            CompareScids(linkMapPhotoActions[i][this.modelDefaults.ordering_attribute], this.startingLinkingOrderingAttribute) >= 0
          ) {
            startIndex = i;
          }
          // include 'ending' ordering attributes's items but stop directly after it
          if (
            foundEndOrderingAttribute &&
            SquashNulls(linkMapPhotoActions[i], this.modelDefaults.ordering_attribute).indexOf(this.endLinkingOrderingAttribute) == -1
          ) {
            endIndex = i;
            break;
          }
          if (
            linkMapPhotoActions[i][this.modelDefaults.ordering_attribute] != null &&
            SquashNulls(linkMapPhotoActions[i], this.modelDefaults.ordering_attribute).indexOf(this.endLinkingOrderingAttribute) != -1
          ) {
            foundEndOrderingAttribute = true;
          }
        }
        linkMapPhotoActions = linkMapPhotoActions.slice(startIndex || 0, endIndex);
        if (this.skipDoneMapPhotoLinks) {
          var notDoneActions = [];
          for (var i = 0; i < linkMapPhotoActions.length; i++) {
            if (!linkMapPhotoActions[i].done) {
              notDoneActions.push(linkMapPhotoActions[i]);
            }
          }
          this.set('linkMapPhotoActions', notDoneActions);
        } else {
          this.set('linkMapPhotoActions', linkMapPhotoActions);
        }
        // this.computeHideGenericSpecButton();
        this.completedHWDetails = [];
        this.doNextMapPhotoAction(true);
      });
    };
    this.confirm(
      'Choose which details to enter:',
      null,
      'Enter Hardware Details',
      'Cancel',
      '',
      'linkMapPhotoData',
      doHwDetails.bind(this)
    );
    this.skipConfirm = false;
  }

  _button_node_over_connection(e) {
    this.cancelPromptAction();
    this.clearMapSelection();
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: true
    };
    this.activeCommand = '_multiSelectItems';
    this.$.katapultMap.openActionDialog({
      title: 'Click sections or draw a polygon around them to include them in the overlap check. Right click to delete polygon points.',
      buttons: [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Continue', callback: this.setupNodeOverConnection.bind(this), attributes: { 'secondary-color': '' } },
        { title: 'Select All', callback: this.setupNodeOverConnection.bind(this, true), attributes: { 'secondary-color': '' } }
      ]
    });
  }
  setupNodeOverConnection(selectAll) {
    this.multiSelectedConnectionKeys = [];
    this.multiSelectedNodeKeys = [];

    if (selectAll) {
      for (var connId in this.connections) {
        this.multiSelectedConnectionKeys.push(connId);
      }
      for (var key in this.nodes) {
        this.multiSelectedNodeKeys.push(key);
      }
    } else {
      this.multiSelectedConnectionKeys = Object.keys(this.$.katapultMap.multiSelectedConnections);
      this.multiSelectedNodeKeys = this.$.katapultMap.multiSelectedNodes;
    }

    this.multiSelectedConnections = [];
    this.multiSelectedConnectionKeys.forEach((connId) => {
      this.multiSelectedConnections.push({
        connId: connId,
        node1: {
          nodeId: this.connections[connId].node_id_1,
          latitude: this.nodes[this.connections[connId].node_id_1].latitude,
          longitude: this.nodes[this.connections[connId].node_id_1].longitude
        },
        node2: {
          nodeId: this.connections[connId].node_id_2,
          latitude: this.nodes[this.connections[connId].node_id_2].latitude,
          longitude: this.nodes[this.connections[connId].node_id_2].longitude
        }
      });
    });
    this.multiSelectedNodes = [];
    this.multiSelectedNodeKeys.forEach((key) => {
      this.multiSelectedNodes.push({ [key]: this.nodes[key] });
    });

    if (this.multiSelectedConnections.length > 0 && this.multiSelectedNodes.length > 0) this.$.nodeOverConnectionToleranceDialog.open();
    else this.toast('Please select nodes and connections');
  }
  async checkNodeOverConnection(tolerance, nodes, connections) {
    const update = {};
    if (tolerance <= 0) return this.toast('Please provide a valid tolerance value');

    nodes.forEach((node) => {
      let nodeData = Object.values(node)[0];
      let nodeId = Object.keys(node)[0];
      let nodeLatLng = [nodeData.latitude, nodeData.longitude];

      let connectionOverlaps = 0;
      connections.forEach((connection) => {
        if (nodeId != connection.node1.nodeId && nodeId != connection.node2.nodeId) {
          let connPoint1LatLng = [connection.node1.latitude, connection.node1.longitude];
          let connPoint2LatLng = [connection.node2.latitude, connection.node2.longitude];

          let distance = KatapultGeometry.CalcDistanceToLine(
            nodeLatLng[0],
            nodeLatLng[1],
            connPoint1LatLng[0],
            connPoint1LatLng[1],
            connPoint2LatLng[0],
            connPoint2LatLng[1]
          );
          distance = distance / 0.3048; // meters to feet

          if (distance <= tolerance) connectionOverlaps += 1;
        }
      });
      if (connectionOverlaps > 0) {
        let warning = 'no huts connected';
        update[`nodes/${nodeId}/attributes/warning/floating_node`] = warning;
      }
    });
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
  }
  async checkNodesConnected(nodes, connections) {
    const update = {};
    let nodeList = [];
    let hutList = [];

    nodes.forEach((node) => {
      // store all meters and huts
      let nodeType = Object.values(Object.values(node)[0].attributes.node_type)[0];
      if (nodeType == 'hut') hutList.push(Object.keys(node)[0]);
      else nodeList.push({ nodeId: Object.keys(node)[0], num_huts_connected: 0 });
    });

    hutList.forEach((hut) => {
      // traverse nodes and mark connected meters
      this.visitedNodes = [];
      this.traverseNodes(hut, '', connections, (current) => {
        nodeList.forEach((node) => {
          if (node.nodeId == current) node.num_huts_connected += 1;
        });
      });
    });

    nodeList.forEach((node) => {
      // for each unconnected node add a warning
      if (node.num_huts_connected == 0) {
        let warning = 'no huts connected';
        update[`nodes/${node.nodeId}/attributes/warning/floating_node`] = warning;
      }
    });
    await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
  }
  traverseNodes(current, previous, connections, runnable) {
    // set this.visitedNodes = []; before calling function
    if (connections.length == 0) return;

    // if a function is provided, run it
    if (runnable) runnable(current, previous); //give runnable access to current and last nodes

    let newConnections = [];
    connections.forEach((connection) => {
      if (current == connection.node1.nodeId && previous != connection.node2.nodeId) {
        if (!this.visitedNodes.includes(connection.node2.nodeId)) newConnections.push(connection.node2.nodeId);
      } else if (current == connection.node2.nodeId && previous != connection.node1.nodeId) {
        if (!this.visitedNodes.includes(connection.node1.nodeId)) newConnections.push(connection.node1.nodeId);
      }
    });

    this.visitedNodes.push(current);
    // run again for all new connections
    newConnections.forEach((newConnection) => this.traverseNodes(newConnection, current, connections, runnable));
  }
  async finishNodeOverConnection(e) {
    let tolerance = this.distanceValue;
    let button = e.currentTarget;

    this.$.katapultMap.closeActionDialog(); // close action dialog
    this.cancelPromptAction();
    this.$.nodeOverConnectionToleranceDialog.close();
    this.distanceValue = null; // reset input value after dialog close

    if (button.dataset.runTolerance != 'true') return;

    await this.checkNodesConnected(this.multiSelectedNodes, this.multiSelectedConnections);
    await this.checkNodeOverConnection(tolerance, this.multiSelectedNodes, this.multiSelectedConnections);

    setTimeout(async () => {
      const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
    }, 1);
  }

  _button_pole_foreman_framing(e) {
    this.cancelPromptAction();
    this.clearMapSelection();
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: false,
      connections: false
    };
    this.activeCommand = '_multiSelectItems';
    this.$.katapultMap.openActionDialog({
      title: 'Click nodes or draw a polygon around them to include them. Right click to delete polygon points.',
      buttons: [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Continue', callback: this.runPoleForemanCheck.bind(this), attributes: { 'secondary-color': '' } },
        { title: 'Select All', callback: this.runPoleForemanCheck.bind(this, true), attributes: { 'secondary-color': '' } }
      ]
    });
  }

  async _button_create_job_from_feeder() {
    const { CreateJobFromFeeder } = await import('./button_functions/CreateJobFromFeeder.js');
    await CreateJobFromFeeder({
      jobCreator: this.jobCreator,
      model: this.actionDialogModel,
      userGroup: this.userGroup,
      modelDefaults: this.modelDefaults
    });
  }

  combineWarnings(nodeIds, warnings) {
    var combinedWarnings = [];
    for (var i = 0; i < warnings.length; i++) {
      if (combinedWarnings.length == 0) {
        combinedWarnings.push({ key: nodeIds[0], title: warnings[0].split(' - ')[0], warnings: [warnings[0].split(' - ')[1]] });
        continue;
      }
      let found = false;
      let foundIndex = 0;
      for (var j = 0; j < combinedWarnings.length; j++) {
        if (nodeIds[i] == combinedWarnings[j].key) {
          found = true;
          foundIndex = j;
        }
      }
      if (found) combinedWarnings[foundIndex].warnings.push(warnings[i].split(' - ')[1]);
      else combinedWarnings.push({ key: nodeIds[i], title: warnings[i].split(' - ')[0], warnings: [warnings[i].split(' - ')[1]] });
    }
    return combinedWarnings;
  }

  async runPoleForemanCheck(selectAll) {
    const update = {};

    this.multiSelectedNodes = {};
    if (selectAll) {
      this.multiSelectedNodes = this.nodes;
    } else {
      this.$.katapultMap.multiSelectedNodes.forEach((key) => (this.multiSelectedNodes[`${key}`] = this.nodes[key]));
    }

    if (Object.keys(this.multiSelectedNodes).length == 0) {
      this.toast('Please select at least one node');
      return;
    } else if (Object.keys(this.multiSelectedNodes).length == 1) {
      this.singleNode = Object.keys(this.multiSelectedNodes)[0];
      this.multiSelectedNodes = this.nodes;
    } else {
      this.singleNode = false;
    }

    this.toast('Getting Pole Foreman Warnings');

    let jobPhotoData = await GetJobData(this.job_id, 'photos');
    import('./exports/export-manager.js').then(() => {
      import('./exports/pole-foreman-export.js').then(async () => {
        setTimeout(() => {
          var pf = this.$.exportManager.$.poleForemanExport.exportPF(
            this.singleNode,
            this.jobName,
            this.multiSelectedNodes,
            this.connections,
            jobPhotoData.photos,
            this.traces,
            this.traceItems,
            this.otherAttributes,
            this.$.exportManager.exportModels?.poleforeman,
            { skipSpecMatching: true }
          );
          if (pf.errors.length == 0) return this.toast('No Pole Foreman Warnings');
          let combinedWarnings = this.combineWarnings(pf.errorNodes, pf.errors);
          let warnings = { title: 'Pole Foreman Check', nodeWarnings: [] };
          for (let i = 0; i < combinedWarnings.length; i++) {
            let nodeId = combinedWarnings[i].key;
            let pfPoleName = combinedWarnings[i].title;
            let pfWarnings = combinedWarnings[i].warnings;
            warnings.nodeWarnings.push({
              key: nodeId,
              description: pfPoleName,
              warnings: pfWarnings
            });
            for (let j = 0; j < pfWarnings.length; j++) {
              update[`nodes/${nodeId}/attributes/warning/pf_warning_${j}`] = pfWarnings[j];
            }
          }
          if (warnings.nodeWarnings.length > 0) {
            this.displayWarningsDialog(warnings);
          }
          //await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
        }, 200);
      });
    });
    this.$.katapultMap.closeActionDialog(); // close action dialog
    this.cancelPromptAction();
  }

  confirmDialogButtonCallback(e) {
    this.confirmDialogOtherButtons[e.model.index].callback();
  }

  confirm(
    heading,
    body,
    affirmativeText,
    dismissiveText,
    affirmativeStyle,
    confirmDialogBodyType,
    callback,
    cancelCallback,
    otherButtons,
    options
  ) {
    //close action dialog box to avoid overlap and confusion on which program you are using
    this.$.katapultMap.closeActionDialog();
    if (this.skipConfirm) {
      callback();
    } else {
      options = options || {};
      this.confirmDialogHeading = heading || '';
      this.$.confirmDialogBody.innerHTML = body || '';
      this.confirmDialogAffirmativeText = affirmativeText || '';
      this.confirmDialogDismissiveText = dismissiveText || '';
      this.confirmDialogAffirmativeStyle = affirmativeStyle || '';
      this.confirmDialogStyle = options.dialogStyle || '';
      if (affirmativeStyle != null && affirmativeStyle != '') {
        this.confirmDialogDismissiveStyle = 'color:black';
      } else {
        this.confirmDialogDismissiveStyle = '';
      }
      this.confirmDialogCloseOnConfirm = options.hasOwnProperty('closeOnConfirm') ? options.closeOnConfirm : true;
      this.confirmDialogCallback = callback || function () {};
      this.confirmDialogCancelCallback = cancelCallback || function () {};
      this.confirmDialogBodyType = confirmDialogBodyType;
      this.confirmDialogOtherButtons = otherButtons;
      this.confirmDialogTitle = options.confirmDialogTitle;
      this.$.confirmDialog.open();
    }
  }

  viewerConfirm(e) {
    if (e.detail.otherButtons) {
      e.detail.otherButtons.forEach((button) => (button.callback = e.currentTarget[button.callback].bind(e.currentTarget)));
    }
    this.confirm(
      e.detail.heading,
      e.detail.body,
      e.detail.affirmativeText,
      e.detail.dismissiveText,
      e.detail.affirmativeStyle,
      null,
      (e.currentTarget[e.detail.callback] || function () {}).bind(e.currentTarget),
      (e.currentTarget[e.detail.cancelCallback] || function () {}).bind(e.currentTarget),
      e.detail.otherButtons
    );
  }

  useGenericPowerSpec() {
    if (this.linkMapPhotoActions != null && this.linkMapPhotoActions[0] != null && this.linkMapPhotoActions[0].cable_type != null) {
      var powerSpecAttribute = 'wire_spec';
      if (SquashNulls(this.otherAttributes, 'power_spec') != '') {
        powerSpecAttribute = 'power_spec';
      }
      var cableType = this.linkMapPhotoActions[0].cable_type;
      var update = {};
      var genericSpec = null;
      var insert_spec_buttons = ['insert_ppl_com_spec', 'insert_wire_spec', 'insert_com_spec'];
      for (var i = 0; i < insert_spec_buttons.length; i++) {
        var spec = SquashNulls(this.mappingButtons, insert_spec_buttons[i], 'models', cableType, 'default');
        if (spec != '') {
          genericSpec = spec;
          break;
        }
      }
      if (genericSpec == null) {
        switch (cableType) {
          case 'Primary':
            genericSpec = 'primary';
            break;
          case 'Secondary':
            genericSpec = 'secondary';
            break;
          case 'Neutral':
            genericSpec = 'neutral';
            break;
          case 'Power Guy':
            genericSpec = 'power guy';
            break;
          case 'Open Secondary':
            genericSpec = 'open secondary';
            break;
          case 'Street Light Feed':
            genericSpec = 'streetlight wire';
            break;
          case 'Power Drop':
            genericSpec = 'power drop';
            break;
          case 'ADSS':
            genericSpec = 'ADSS';
            break;
        }
      }
      if (genericSpec != null) {
        for (var i = 0; i < this.linkMapPhotoActions[0].sectionHeights.length; i++) {
          update[
            this.linkMapPhotoActions[0].sectionHeights[i].photoId +
              '/photofirst_data/' +
              this.linkMapPhotoActions[0].sectionHeights[i].property +
              '/' +
              this.linkMapPhotoActions[0].sectionHeights[i].itemKey +
              '/' +
              powerSpecAttribute
          ] = genericSpec;
        }
        FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photos/').update(
          update,
          function (error) {
            if (error) {
              this.toast(error);
            }
            this.specCounter = -1;
            this.doNextMapPhotoAction(false);
          }.bind(this)
        );
      }
    }
  }

  previousMapPhotoAction() {
    if (this.completedHWDetails.length > 0) {
      this.linkMapPhotoActions.unshift(this.completedHWDetails.pop());
      if (this.completedHWDetails.length > 0) {
        this.linkMapPhotoActions.unshift(this.completedHWDetails.pop());
        this.doNextMapPhotoAction();
      } else {
        this.doNextMapPhotoAction(true);
      }
    }
  }

  skipThisMapPhotoAction() {
    if (this.linkMapPhotoActions != null && this.linkMapPhotoActions[0] != null && this.linkMapPhotoActions[0].property === 'down_guy') {
      FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/hardware_details/skipped/').set(
        'map',
        function (error) {
          if (error) {
            console.log('error', error);
          }
          setTimeout(this.doNextMapPhotoAction.bind(this));
        }.bind(this)
      );
    } else {
      this.doNextMapPhotoAction();
    }
  }

  doNextMapPhotoAction(first, shiftNext) {
    if (this.linkMapPhotoActions != null) {
      if (first !== true) {
        if (this.linkMapPhotoActions[0].property == 'midspan_cable' || this.linkMapPhotoActions[0].property == 'cable') {
          FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/mapTookLinkingAction').set(
            true
          );
        }
        if (shiftNext != null && shiftNext.doIt) {
          for (var i = 0; i < shiftNext.count; i++) {
            this.completedHWDetails.push(this.linkMapPhotoActions.shift());
          }
        } else {
          this.completedHWDetails.push(this.linkMapPhotoActions.shift());
        }
        // this.computeHideGenericSpecButton();
      }
      if (this.skipDoneMapPhotoLinks) {
        while (this.linkMapPhotoActions.length > 0 && this.linkMapPhotoActions[0].done == true) {
          this.completedHWDetails.push(this.linkMapPhotoActions.shift());
          // this.computeHideGenericSpecButton();
        }
      }
      if (this.linkMapPhotoActions.length > 0) {
        var action = this.linkMapPhotoActions[0];
        var openPhotoFirstInfo = {
          id: action.photoId,
          property: action.property,
          itemKey: action.itemKey
        };
        if (action.action_type != null) {
          if (this.geoJsonInfo) this.geoJsonInfo.close();
          if (this.infoWindow) this.infoWindow.close();
          openPhotoFirstInfo.action_type = action.action_type;
        }
        if (action.marker_type != null) {
          openPhotoFirstInfo.marker_type = action.marker_type;
        }
        if (action.nodeId != null) {
          openPhotoFirstInfo.nodeId = action.nodeId;
          this.selectedNode = action.nodeId;
          this.editingNode = action.nodeId;
          // zoom map if node is not in view
          var latlng = new google.maps.LatLng(this.nodes[action.nodeId].latitude, this.nodes[action.nodeId].longitude);
          if (!this.map.getBounds().contains(latlng)) {
            this.map.panTo(latlng);
          }
        } else if (action.connId != null) {
          this.$.katapultMap.$.loadRenderMap.toggleHighlightTraceOverlay(action.connId, this.connectionHighlight);
          this.connectionHighlight = action.connId;
          this.editing = null;
          if (action.sectionId != null) {
            let c = this.connections,
              s = c[action.connId].sections[action.sectionId],
              latlng = new google.maps.LatLng(s.latitude, s.longitude);
            // pan but don't zoom map when entering hardware details for power spec
            this.map.panTo(latlng);
          }
        }
        this.mouseLat = null;
        this.mouseLng = null;
        this.set('$.rubberbandExtended.style.display', 'none');
        this.activeCommand = '_linkMapPhotoData';

        let buttons = [
          { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
          { title: 'Previous', callback: this.previousMapPhotoAction.bind(this), attributes: { grey: '' } },
          { title: 'Next', callback: this.skipThisMapPhotoAction.bind(this), attributes: { grey: '' } }
        ];
        // if (!this.hideGenericSpecButton) buttons.push({title: 'Use Generic', callback: this.useGenericPowerSpec.bind(this), attributes: {'secondary-color': ''}});
        if (action.property == 'down_guy' || action.property == 'guying') {
          this.set('nodeLabels.sizes_of_attached_dn_guys', true);
          this.$.katapultMap.openActionDialog({ text: 'Please enter down guy bolt on the photo for this selected anchor:', buttons });
          FirebaseWorker.ref(
            'photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/hardware_details/doing_anchor'
          ).set({
            nodeId: action.itemKey,
            bearing: action.bearing,
            length: action.length,
            dnGuys: action.dnGuys || [],
            skipDone: this.skipDoneMapPhotoLinks
          });
        } else if (action.action_type === 'upshotLength') {
          this.set('nodeLabels.sizes_of_attached_dn_guys', false);
          this.$.katapultMap.openActionDialog({ text: 'Please measure length for this ' + action.marker_type + ' on photo', buttons });
        } else if (action.action_type == 'hardwareAngle') {
          this.set('nodeLabels.sizes_of_attached_dn_guys', false);
          this.$.katapultMap.openActionDialog({ text: 'Please select angle for selected ' + action.marker_type + ' on photo', buttons });
        } else if (action.property == 'midspan_cable' || action.property == 'cable' || action.property == 'wire') {
          var powerSpecAttribute = 'wire_spec';
          var update = {};
          if (typeof dont_overwrite_wire_spec === 'undefined' || dont_overwrite_wire_spec !== true) {
            update[powerSpecAttribute] = '';
          }
          if (SquashNulls(this.otherAttributes, 'power_spec') != '') {
            powerSpecAttribute = 'power_spec';
          }
          var additionalAttributes = SquashNulls(this.hwDetailsOptions, 'additional_wire_spec_attributes');
          for (var property in additionalAttributes) {
            update[property] = '';
          }
          FirebaseWorker.ref(
            'photoheight/jobs/' + this.job_id + '/photos/' + action.photoId + '/photofirst_data/' + action.property + '/' + action.itemKey
          ).update(update);
          openPhotoFirstInfo.setPowerSpec = {
            skipDone: !!this.skipDoneMapPhotoLinks,
            sectionHeights: action.sectionHeights
          };
          this.$.katapultMap.openActionDialog({
            text: 'Please enter Power Spec for selected ' + action.cable_description + ' in photofirst:',
            buttons
          });
        } else if (action.action_type == 'equipmentSize') {
          this.editing = 'Node';
          openPhotoFirstInfo.attribute_name = action.attribute_name;
          FirebaseWorker.ref(
            'photoheight/jobs/' +
              this.job_id +
              '/photos/' +
              action.photoId +
              '/photofirst_data/' +
              action.property +
              '/' +
              action.itemKey +
              '/' +
              action.attribute_name
          ).set('');
          this.$.katapultMap.openActionDialog({
            text: 'Please enter Spec for selected ' + action.marker_type + ' in photofirst:',
            buttons
          });
        }
        this.openPhotoFirst({ currentTarget: openPhotoFirstInfo });
      } else {
        this.completedHWDetails = [];
        this.cancelPromptAction();
        FirebaseWorker.ref('photoheight/company_space/' + this.userGroup + '/user_data/' + this.user.uid + '/hardware_details/done').set(
          true
        );
        this.toast('Done Entering Hardware Details.');
      }
    }
  }

  doHwDetailForDnGuy() {
    if (this.nodes && this.nodes[this.editingNode]) {
      let attr = this.nodes[this.editingNode].attributes,
        val = PickAnAttribute(attr, 'sizes_of_attached_dn_guys');
      if (!val || val == '') {
        this.toast('Down guy sizes on anchor not specified! We need to know how many down guys are attached to anchor before linking');
        return;
      }
    }
    if (this.geoJsonInfo) this.geoJsonInfo.close();
    if (this.infoWindow) this.infoWindow.close();
    var downGuy = {};
    var poleId;
    var hwDetail = new HardwareDetails();
    this.skipDoneMapPhotoLinks = false;
    hwDetail.setOptions(false, true, false, false, false, false);
    for (var connection in this.connections) {
      let connType = PickAnAttribute(this.connections[connection].attributes, this.modelDefaults.connection_type_attribute);
      if (
        this.modelDefaults.downguy_connection_types.includes(connType) ||
        this.modelDefaults.proposed_downguy_connection_types.includes(connType)
      ) {
        if (this.connections[connection].node_id_1 === this.editingNode) {
          let n1 = new google.maps.LatLng(
            this.nodes[this.connections[connection].node_id_1].latitude,
            this.nodes[this.connections[connection].node_id_1].longitude
          );
          let n2 = new google.maps.LatLng(
            this.nodes[this.connections[connection].node_id_2].latitude,
            this.nodes[this.connections[connection].node_id_2].longitude
          );
          downGuy[this.connections[connection].node_id_1] = {
            node: this.nodes[this.connections[connection].node_id_1],
            bearing: Round((google.maps.geometry.spherical.computeHeading(n2, n1) + 360) % 360, 1),
            length: Round(google.maps.geometry.spherical.computeDistanceBetween(n1, n2) / 0.3048, 1)
          };
          poleId = this.connections[connection].node_id_2;
        } else if (this.connections[connection].node_id_2 === this.editingNode) {
          poleId = this.connections[connection].node_id_1;
          let n1 = new google.maps.LatLng(
            this.nodes[this.connections[connection].node_id_1].latitude,
            this.nodes[this.connections[connection].node_id_1].longitude
          );
          let n2 = new google.maps.LatLng(
            this.nodes[this.connections[connection].node_id_2].latitude,
            this.nodes[this.connections[connection].node_id_2].longitude
          );
          downGuy[this.connections[connection].node_id_2] = {
            node: this.nodes[this.connections[connection].node_id_2],
            bearing: Round((google.maps.geometry.spherical.computeHeading(n1, n2) + 360) % 360, 1),
            length: Round(google.maps.geometry.spherical.computeDistanceBetween(n1, n2) / 0.3048, 1)
          };
        }
      }
    }
    var mainPhotoId = this.getMainPhotoFromNodeId(poleId);
    GetJobData(this.job_id, 'photos/' + mainPhotoId).then((data) => {
      hwDetail.addPoleToQueue(
        this.nodes[poleId],
        poleId,
        data['photos/' + mainPhotoId],
        mainPhotoId,
        downGuy,
        true,
        this.hwDetailsOptions || {}
      );
      var linkMapPhotoActions = hwDetail.start();
      this.cableTracing = true;
      this.set('linkMapPhotoActions', linkMapPhotoActions);
      // this.computeHideGenericSpecButton();
      this.completedHWDetails = [];
      this.doNextMapPhotoAction(true);
      // var ref = FirebaseWorker.ref("photoheight/compan
    });
  }

  editAnchorLocation() {
    // Get editing node and make sure it is an anchor.
    const nodeId = this.editingNode;
    const node = this.nodes[nodeId];
    const nodeType = PickAnAttribute(node?.attributes, this.modelDefaults.node_type_attribute);
    if (!this.modelDefaults.anchor_node_types.includes(nodeType)) throw new Error('Selected node must be an anchor');

    // Get anchor's connected pole.
    const connections = GetConnectionLookup(this.nodes, this.connections)[nodeId];
    if (!Array.isArray(connections) || connections.length == 0) throw new Error('No connections found for this anchor');
    if (connections.length > 1) throw new Error('More than one connection found for this anchor');
    const otherNodeId = connections[0].toNodeId;

    // Set the other node as the selected node and begin drawing from it.
    this.selectedNode = otherNodeId;

    // Activate the move anchor command.
    this.activeCommand = '$moveAnchor';
    this.$.katapultMap.openActionDialog({
      title: 'Move Anchor',
      materialIcon: 'moving'
    });
  }

  async importKMZToNodesAndConnections(files) {
    // Note: there may be a larger problem with this import because even if multiple files
    // are drug onto the map, it will only ever process the first one.
    let json = await this.kmzOrShapeToJson(files?.[0] || []);
    this.kmzImportNodeAttributes = SquashNulls(this.modelConfig, 'kmz_import_attributes', 'node') || [];
    this.kmzImportScrapedAttributes = SquashNulls(this.modelConfig, 'kmz_import_attributes', 'map_point_description') || [];
    this.kmzImportScrapedAttributes.forEach((attr) => {
      attr.count = 0;
      attr.example = '(None)';
    });
    let result = this.geoJsonToNodesAndConnections(json.geoJSON);
    let mappingAttributes = [];
    let lowerCaseAttributes = {};
    for (let attribute in this.otherAttributes) {
      lowerCaseAttributes[attribute.toLowerCase()] = attribute;
    }
    for (let property in result.properties) {
      if (property != 'style' && property != 'styleUrl' && property != 'styleHash' && property != 'styleMapHash') {
        mappingAttributes.push({
          property,
          mapped_property: lowerCaseAttributes[property.toLowerCase().trim().replace(/ /g, '_')] || '',
          sample: result.properties[property]
        });
      }
    }
    this.kmzImportMappingAttributes = mappingAttributes;
    this.confirm(
      'Import Nodes from KMZ?',
      result.nodes.length + ' points and ' + result.conns.length + ' connections found. Select attributes to add to each node:',
      'Import',
      'Cancel',
      `background-color:${this.config.firebaseData.palette.secondaryColor}; color:${this.config.firebaseData.palette.secondaryColorLightTextColor};`,
      'importKMZ',
      () => {
        var update = {};
        result.nodes.forEach((node) => {
          this.kmzImportNodeAttributes.forEach((item) => {
            node.attributes[item.property] = node.attributes[item.property] || {};
            node.attributes[item.property][FirebaseWorker.ref().push().key] = item.value;
            AddNodeCalls(item.property, node.attributes, this.otherAttributes, { newValue: item.value });
          });
          this.kmzImportMappingAttributes.forEach((item) => {
            // == check for NaN
            if (
              item.mapped_property &&
              node.properties[item.property] != null &&
              node.properties[item.property] == node.properties[item.property]
            ) {
              node.attributes[item.mapped_property] = node.attributes[item.mapped_property] || {};
              node.attributes[item.mapped_property][FirebaseWorker.ref().push().key] = node.properties[item.property];
              AddNodeCalls(item.mapped_property, node.attributes, this.otherAttributes, { newValue: node.properties[item.property] });
            }
          });
          delete node.properties;
          GeofireTools.setGeohash('nodes', node, node.$key, this.jobStyles, update);
          update['nodes/' + node.$key] = node;
          delete node.$key;
        });
        result.conns.forEach((conn) => {
          GeofireTools.setGeohash('connections', conn, conn.$key, this.jobStyles, update, {
            location1: [conn.n1.node.latitude, conn.n1.node.longitude],
            location2: [conn.n2.node.latitude, conn.n2.node.longitude],
            nId1: conn.node_id_1,
            nId2: conn.node_id_2
          });
          update['connections/' + conn.$key] = conn;
          delete conn.$key;
          delete conn.n1;
          delete conn.n2;
        });
        FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update);
      }
    );
  }

  kmzAttributeAdded(e) {
    // Check if an attribute has been selected
    let selectedAttributeName = SquashNulls(e, 'detail', 'selectedItem');
    if (selectedAttributeName) {
      // Check if the selected attribute is a group
      let groupItems = SquashNulls(this.otherAttributes, selectedAttributeName, 'group_items');
      if (SquashNulls(this.otherAttributes, selectedAttributeName, 'gui_element') == 'group' && groupItems) {
        // Add each of the attributes that should be added by the group
        this.push(
          'kmzImportNodeAttributes',
          ...groupItems.map((x) => {
            let attributeName = typeof x === 'string' ? x : x.attribute;
            let value = x.value || GetNewAttributeValue(attributeName, this.otherAttributes);
            return { property: attributeName, value };
          })
        );
      }
      // Otherwise, just add the attribute to the list by itself
      else {
        this.push('kmzImportNodeAttributes', {
          property: selectedAttributeName,
          value: ''
        });
      }
    }
    e.currentTarget.value = '';
  }

  kmzAttributeRemoved(e) {
    this.splice('kmzImportNodeAttributes', e.model.index, 1);
  }

  geoJsonToNodesAndConnections(geoJson) {
    let nodes = [];
    let conns = [];
    let lines = [];
    let properties = {};
    let nodeLookup = {};

    // Default to an array of just the geoJson
    let geoJsonArray = [geoJson];
    // If the geoJSON property is an array (multiple shape
    // files), then map the array to geoJsonArray so we import each layer
    if (geoJson && Array.isArray(geoJson)) {
      geoJsonArray = geoJson.map((x) => x);
    }
    geoJsonArray.forEach((layer) => {
      layer.features.forEach((feature) => {
        if (feature && feature.geometry) {
          if (feature.geometry.type == 'LineString') {
            lines.push(feature);
          } else if (feature.geometry.type == 'Point') {
            // filter out our hidden connection label placemark upon re-import
            if (SquashNulls(feature, 'properties', 'styleUrl') != '#HiddenIcon') {
              let node = {
                $key: FirebaseWorker.ref().push().key,
                latitude: feature.geometry.coordinates[1],
                longitude: feature.geometry.coordinates[0],
                attributes: {},
                properties: feature.properties
              };
              nodeLookup[node.latitude.toFixed(5) + ':' + node.longitude.toFixed(5)] = { node, nodeId: node.$key };

              for (let prop in feature.properties) {
                if (!properties[prop] && feature.properties[prop] !== '') {
                  properties[prop] = feature.properties[prop];
                }
              }

              this.kmzImportScrapedAttributes.forEach((attribute) => {
                let value = '';
                if (attribute.kml_properties && attribute.kml_properties.some((x) => feature.properties.hasOwnProperty(x))) {
                  attribute.kml_properties.forEach((prop) => {
                    if (feature.properties.hasOwnProperty(prop)) {
                      if (attribute.delimiter && value != '') value += attribute.delimiter;
                      value += feature.properties[prop];
                    }
                  });
                } else if (attribute.regexps && feature.properties.description) {
                  attribute.regexps.forEach((regex) => {
                    let result = new RegExp(regex, 'gm').exec(feature.properties.description);
                    if (result != null) {
                      if (attribute.delimiter && value != '') value += attribute.delimiter;
                      value += result[1];
                    }
                  });
                }
                if (value != '') {
                  if (attribute.property.startsWith('pole_tag')) {
                    node.attributes.pole_tag =
                      node.attributes.pole_tag.trim() || { imported: { company: '', tagtext: '', owner: false } }.trim();
                    node.attributes.pole_tag.imported[attribute.property.split('.').pop()] = value;
                  } else {
                    node.attributes[attribute.property] = { imported: value };
                  }
                  AddNodeCalls(attribute.property, node.attributes, this.otherAttributes, { newValue: value });
                  attribute.count++;
                  if (attribute.example == '(None)') {
                    attribute.example = value;
                  }
                }
              });
              nodes.push(node);
            }
          }
        }
      });
      if (lines.length) {
        for (let nodeId in this.nodes) {
          nodeLookup[this.nodes[nodeId].latitude.toFixed(5) + ':' + this.nodes[nodeId].longitude.toFixed(5)] = {
            nodeId,
            node: this.nodes[nodeId]
          };
        }
      }
      // Leave connections out for now as they are not working
      lines.forEach((feature) => {
        feature.geometry.coordinates.forEach((coord, i) => {
          if (i != 0) {
            let coord1 = feature.geometry.coordinates[i - 1];
            let n1 = nodeLookup[coord[1].toFixed(5) + ':' + coord[0].toFixed(5)];
            let n2 = nodeLookup[coord1[1].toFixed(5) + ':' + coord1[0].toFixed(5)];

            if (n1 && n2) {
              let conn = {
                $key: FirebaseWorker.ref().push().key,
                node_id_1: n1.nodeId,
                node_id_2: n2.nodeId,
                n1,
                n2,
                attributes: {
                  connection_type: {
                    imported: 'aerial cable'
                  }
                  // unique_span_id:{
                  //   imported:feature.properties.wmElementN || ''
                  // }
                }
              };
              AddNodeCalls(this.modelDefaults.connection_type_attribute, conn.attributes, this.otherAttributes, {
                newValue: 'aerial cable'
              });
              conns.push(conn);
            }
          }
        });
      });
    });
    return { nodes, conns, properties };
  }

  async openCustomFileImporter(files, nodes) {
    await import('./custom-file-importer.js');
    this.$.customFileImporter.open(files, nodes);
  }

  async uploadWireData(files) {
    await import('./csv-xlsx-parser.js');
    GetJobData(this.job_id, 'photos').then((data) => {
      //Setup Lookup
      var orderingAttributeNodeLookup = {};
      let update = {};
      let jobNodes = this.nodes,
        jobPhotos = data.photos;
      for (var nodeId in jobNodes) {
        var orderingAttribute = PickAnAttribute(jobNodes[nodeId].attributes, this.modelDefaults.ordering_attribute) || null;
        var measuredPoleHeight = PickAnAttribute(jobNodes[nodeId].attributes, 'measured_pole_height') || null;
        // Only do it if it has an ordering attribute
        if (orderingAttribute != null) {
          //Use either first photo or main photo if it exists
          let photoToUse;
          for (var photoId in jobNodes[nodeId].photos) {
            if (jobPhotos[photoId] != null) {
              if (jobNodes[nodeId].photos[photoId] == 'main' || jobNodes[nodeId].photos[photoId].association == 'main') {
                photoToUse = photoId;
                break;
              } else if (photoToUse == null) {
                photoToUse = photoId;
              }
            }
          }
          let isExtracted =
            SquashNulls(jobPhotos, photoToUse, 'photofirst_data', 'wire') != '' ||
            SquashNulls(jobPhotos, photoToUse, 'photofirst_data', 'messenger') != '' ||
            SquashNulls(jobPhotos, photoToUse, 'photofirst_data', 'insulator') != '' ||
            SquashNulls(jobPhotos, photoToUse, 'photofirst_data', 'equipment') != '';
          orderingAttributeNodeLookup[orderingAttribute] = { photoId: photoToUse, heights: [], measuredPoleHeight, isExtracted, nodeId };
        } else {
          // update['nodes/' + nodeId + '/attributes/warning/no_height_to_import'] = `No ${this.modelsDefaults.ordering_attribute_label} on pole; Unable to find Heights by ${this.modelsDefaults.ordering_attribute_label}.`;
        }
      }

      //Parse the CSV
      let ref = FirebaseWorker.ref('photoheight/jobs/' + this.job_id);
      this.$.csvXlsxParser.readFiles(
        files,
        null,
        function (rowData) {
          if (orderingAttributeNodeLookup[rowData.PoleID] != null && rowData.Height != '') {
            orderingAttributeNodeLookup[rowData.PoleID].heights.push(rowData);
          }
        }.bind(this),
        function (errorMessages) {
          for (var orderingAttribute in orderingAttributeNodeLookup) {
            var nodeData = orderingAttributeNodeLookup[orderingAttribute];
            if (nodeData.heights.length > 0) {
              if (!nodeData.isExtracted && nodeData.photoId != null) {
                errorMessages.push(`${this.modelDefaults.ordering_attribute_label} ${orderingAttribute} updated.`);
                var poleTop = null;
                if (nodeData.measuredPoleHeight == null) {
                  nodeData.heights.sort(function (a, b) {
                    return parseFloat(b.Height) - parseFloat(a.Height);
                  });
                  poleTop = parseFloat(nodeData.heights[0].Height);
                } else {
                  poleTop = parseFloat(nodeData.measuredPoleHeight);
                  if (!isNaN(poleTop)) {
                    update['photos/' + nodeData.photoId + '/photofirst_data/pole_top/' + ref.push().key] = {
                      _manual_height: Round(poleTop * 12, 2),
                      manual_height: nodeData.measuredPoleHeight,
                      pixel_selection: [{ percentX: 60, percentY: 10 }]
                    };
                  }
                }
                for (var i = 0; i < nodeData.heights.length; i++) {
                  let rowData = nodeData.heights[i];
                  if (rowData.Type != 'DropWire' && rowData.Type != 'PowerDripLoops') {
                    let percentOfPhoto = Math.abs(100 - ((rowData.Height * 80) / poleTop + 10));
                    let height = parseFloat(rowData.Height);
                    if (!isNaN(height)) {
                      let marker = {
                        _manual_height: Round(height * 12, 2),
                        manual_height: rowData.Height,
                        label: rowData.Type,
                        pixel_selection: [{ percentX: 60, percentY: percentOfPhoto }]
                      };
                      let markerKey = ref.push().key;
                      let note = '';
                      if (rowData.Comments1 != null && rowData.Comments1 != '') {
                        note += rowData.Comments1;
                      }
                      if (rowData.Comments2 != null && rowData.Comments2 != '') {
                        if (note != '') note += '; ';
                        note += rowData.Comments2;
                      }
                      if (note != '') {
                        marker.note = note;
                      }
                      let property = 'wire';
                      if (rowData.Type == 'UtilityStrand') {
                        property = 'messenger';
                        marker.messenger_spec = '10M';
                        marker._children = { wire: {} };
                        marker._children.wire[ref.push().key] = { _exists: true };
                      } else if (rowData.Type == 'DownGuy') {
                        property = 'guying';
                        marker.guying_type = 'down guy';
                        let traceId = ref.push().key;
                        marker._trace = traceId;
                        update['/traces/trace_data/' + traceId] = {
                          _trace_type: 'down_guy',
                          company: '',
                          label: ''
                        };
                        update['/traces/trace_items/' + traceId + '/' + nodeData.photoId + '/equipment/' + markerKey] = true;
                      } else if (rowData.Type == 'SidewalkGuy') {
                        property = 'guying';
                        marker.guying_type = 'down guy';
                        let sidewalkBrace = {
                          manual_height: '',
                          guying_type: 'sidewalk brace',
                          sidewalk_brace_spec: 'standard',
                          pixel_selection: marker.pixel_selection
                        };
                        let sidewalkBraceKey = ref.push().key;
                        let traceId = ref.push().key;
                        marker._trace = traceId;
                        sidewalkBrace._trace = traceId;
                        update['/traces/trace_data/' + traceId] = {
                          _trace_type: 'down_guy',
                          company: '',
                          label: ''
                        };
                        update['/traces/trace_items/' + traceId + '/' + nodeData.photoId + '/equipment/' + markerKey] = true;
                        update['/traces/trace_items/' + traceId + '/' + nodeData.photoId + '/equipment/' + sidewalkBraceKey] = true;
                        update['photos/' + nodeData.photoId + '/photofirst_data/' + property + '/' + sidewalkBraceKey] = sidewalkBrace;
                      } else if (rowData.Type == 'PowerPrimary') {
                        property = 'insulator';
                        marker.bearing = '';
                        marker.insulator_spec = '';
                        marker._children = { wire: {} };
                        let wireKey = ref.push().key;
                        marker._children.wire[wireKey] = { _exists: true };
                        if (rowData.PowerCompany != null) {
                          let traceId = ref.push().key;
                          marker._children.wire[wireKey]._trace = traceId;
                          update['/traces/trace_data/' + traceId] = {
                            _trace_type: 'cable',
                            company: rowData.PowerCompany,
                            cable_type: 'Primary',
                            label: ''
                          };
                          update[
                            '/traces/trace_items/' +
                              traceId +
                              '/' +
                              nodeData.photoId +
                              '/insulator/' +
                              markerKey +
                              ':_children:wire:' +
                              wireKey
                          ] = true;
                        }
                      } else if (rowData.Type == 'StreetLight') {
                        property = 'equipment';
                        marker.equipment_type = 'street_light';
                        marker.measurement_of = 'top_of_bracket';
                        let stltBottom = {
                          _manual_height: marker._manual_height,
                          manual_height: marker.manual_height,
                          bearing: '',
                          equipment_type: 'street_light',
                          measurement_of: 'bottom_of_bracket',
                          pixel_selection: marker.pixel_selection,
                          street_light_spec: 'standard'
                        };
                        let stltBottomKey = ref.push().key;
                        if (rowData.PowerCompany != null) {
                          let traceId = ref.push().key;
                          marker._trace = traceId;
                          stltBottom._trace = traceId;
                          update['/traces/trace_data/' + traceId] = {
                            _trace_type: 'equipment',
                            company: rowData.PowerCompany,
                            label: ''
                          };
                          update['/traces/trace_items/' + traceId + '/' + nodeData.photoId + '/equipment/' + markerKey] = true;
                          update['/traces/trace_items/' + traceId + '/' + nodeData.photoId + '/equipment/' + stltBottomKey] = true;
                        }
                        update['photos/' + nodeData.photoId + '/photofirst_data/' + property + '/' + stltBottomKey] = stltBottom;
                      } else if (rowData.Type == 'PowerTransformer') {
                        property = 'equipment';
                        marker.equipment_type = 'transformer';
                        marker.measurement_of = 'top_bolt';
                        let tfmrBottom = {
                          _manual_height: marker._manual_height,
                          manual_height: marker.manual_height,
                          bearing: '',
                          equipment_type: 'transformer',
                          measurement_of: 'bottom_of_equipment',
                          pixel_selection: marker.pixel_selection,
                          transformer_spec: 'Transformer 1PH-25KVA'
                        };
                        let tfmrBottomKey = ref.push().key;
                        if (rowData.PowerCompany != null) {
                          let traceId = ref.push().key;
                          marker._trace = traceId;
                          tfmrBottom._trace = traceId;
                          update['/traces/trace_data/' + traceId] = {
                            _trace_type: 'equipment',
                            company: rowData.PowerCompany,
                            label: ''
                          };
                          update['/traces/trace_items/' + traceId + '/' + nodeData.photoId + '/equipment/' + markerKey] = true;
                          update['/traces/trace_items/' + traceId + '/' + nodeData.photoId + '/equipment/' + tfmrBottomKey] = true;
                        }
                        update['photos/' + nodeData.photoId + '/photofirst_data/' + property + '/' + tfmrBottomKey] = tfmrBottom;
                      }
                      update['photos/' + nodeData.photoId + '/photofirst_data/' + property + '/' + markerKey] = marker;
                    }
                  }
                }
              }
            } else {
              //no heights
              update['nodes/' + nodeData.nodeId + '/attributes/warning/no_height_to_import'] = 'No Heights Found In Spreadsheet.';
            }
          }
          if (errorMessages.length == 0) {
            errorMessages.push('No poles to Update');
          }
          ref.update(update, async (error) => {
            if (error) {
              errorMessages.push('Failed to write to database: ' + error);
            } else {
              errorMessages.push('Upload Complete.');
            }
            console.log(errorMessages);
            const geohash = GeofireTools.getJobGeohash(this.nodes, this.connections, this.jobStyles);
            await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}/geohash`).set(geohash);
            this.$.toast.show(errorMessages.join('; '));
          });
        }.bind(this)
      );
    });
  }

  bigObjectKeys(obj, name) {
    // Debounce the function named after the passed in name
    this['bigObjectKeys-' + name] = Debouncer.debounce(this['bigObjectKeys-' + name], timeOut.after(8), () => {
      this.mapsWorker.postMessage({
        call: 'bigObjectKeys',
        args: [name, obj, this.get(name)]
      });
    });
  }

  bigObjectKeysResult(name, keys) {
    this.set(name, keys);
  }

  objectKeys(obj) {
    // var startTime = new Date().getTime();
    if (obj == null) {
      return obj;
    } else {
      var keys = Object.keys(obj);
      keys.sort(function (a, b) {
        return obj[a].priority - obj[b].priority;
      });
      return keys;
    }
  }

  toArray(obj) {
    return ToArray(obj);
  }

  either(a, b) {
    return a || b;
  }

  orderedPropertyKeys(attributes, models) {
    if (attributes == null) {
      return attributes;
    }
    var keys = Object.keys(attributes);
    keys.sort(function (a, b) {
      var aPriority = SquashNulls(models, a, 'priority') || 10000;
      var bPriority = SquashNulls(models, b, 'priority') || 10000;
      return aPriority - bPriority;
    });
    return keys;
  }

  getSignedUrl(photoKey, callback, infinite, size) {
    size = size || 'extra_large';
    var requestKey = FirebaseWorker.ref('photoheight/server_requests/sign_files/requests').push(
      { name: this.config.firebaseData.amazonFolder + photoKey + '_' + size + '.jpg' },
      function (error) {
        if (error) {
          console.log('Error', error);
        }
      }
    ).key;
    FirebaseWorker.ref('photoheight/server_requests/sign_files/responses/' + requestKey).on(
      'value',
      function (snapshot) {
        var url = snapshot.val();
        if (url != null) {
          snapshot.ref.remove();
          callback(url);
        }
      }.bind(this)
    );
  }

  isDraggingAllowed(_sharing) {
    return _sharing == 'write';
  }

  locationClasses(job_id, published, editing) {
    if (job_id) {
      if (published == 'write') {
        if (editing) {
          return 'write editorOpen';
        } else {
          return 'write';
        }
      } else {
        if (editing) {
          return 'read editorOpen';
        } else {
          return 'read';
        }
      }
    } else {
      return '';
    }
  }

  _computeDragging(_sharing) {
    return _sharing == 'write';
  }

  hiddenMapsFiles(files) {
    if (files == undefined) {
      return true;
    } else {
      return files.length == 0;
    }
  }

  _hasContextInfo(contextInfo) {
    return contextInfo != null;
  }

  getContextInfoLink(filePath, appId) {
    return (
      'https://katapultwebservices.com/ppl/poleattachmentservices/OpenApplicationFile/?name=' +
      encodeURIComponent(filePath) +
      '&appid=' +
      encodeURIComponent(appId)
    );
  }

  _computeOpened3(contextInfo, contextLayers) {
    return contextInfo != null || contextLayers != null;
  }

  _computeOpened4(contextLayers, userGroup) {
    return (this.isUtilityReviewContractor || userGroup == 'PPL') && contextLayers != null;
  }

  _computeChecked(_sharing) {
    return _sharing == 'read';
  }

  showDeliverablePhoto() {
    if (this.showPhoto === undefined) this.showPhoto = this.status?.published;
  }

  calcPhotoIcon(show) {
    return show ? 'chevron-left' : 'chevron-right';
  }

  calcPhotoTooltip(showPhoto) {
    if (showPhoto) return 'Hides the main photo';
    return 'Shows the main photo';
  }

  toggleHideDeliverablePhoto(e) {
    this.hideDeliverablePhoto = !this.hideDeliverablePhoto;
  }

  calcToggleAndPhoto(photo, node) {
    return photo != null || node != null;
  }

  isNull(a) {
    return a == null;
  }

  computePoleCountClass(job_id, poleCount, assignedPoleCount) {
    var className = '';
    if (poleCount != null && job_id != '') {
      className += 'opened';
    }
    if (assignedPoleCount != null) {
      if (poleCount == assignedPoleCount) {
        className += ' green';
      } else if (poleCount > assignedPoleCount) {
        className += ' yellow';
      } else {
        className += ' red';
      }
    }
    return className;
  }

  hidePoleCountTotal(assignedPoleCount) {
    return assignedPoleCount == null;
  }

  computeIgnoredropevents(tier) {
    return tier == 'katapult pro lite';
  }

  getConnNodeAttribute(connKey, nodeAttribute, attribute) {
    return SquashNulls(this.nodes, SquashNulls(this.connections, connKey, nodeAttribute), attribute);
  }

  getConnSectionAttribute(connKey, sectionKey, attribute) {
    return SquashNulls(this.connections, connKey, 'sections', sectionKey, attribute);
  }

  getConnAttribute(connKey, attribute) {
    return SquashNulls(this.connections, connKey, attribute);
  }

  _computeExpression15(contextInfo) {
    if (contextInfo == null) return '';
    return contextInfo.ppl_wr_no || '(None)';
  }

  _computeExpression16(contextInfo) {
    if (contextInfo == null) return '';
    return contextInfo.ppl_wo_no || '(None)';
  }

  async handleMapDrop(e) {
    let eventType = SquashNulls(e, 'detail', 'eventType');

    if (this.sharing == 'read') {
      this.toast('You cannot drop files into this job because you do not have editing access.');
    } else if (this._sharing == 'read') {
      this.toast('You are in read-only mode. Click the red lock to edit this job.');
    } else if (eventType == 'drop-on-item') {
      // Get the item we dropped the file on
      this.dragFileItem = e.detail.dragFileItem;

      let locType, nodeID, secID, connID;
      if (this.dragFileItem[0] == 'n') {
        locType = 'node';
        nodeID = this.dragFileItem.slice(1);
      } else if (this.dragFileItem[0] == 's') {
        locType = 'section';
        let ids = this.dragFileItem.slice(1).split(':');
        connID = ids[0];
        secID = ids[1];
      }

      // Get the data for the dropped file
      this.dragPhotoData = e.detail.originalEvent.dataTransfer.getData('application/json');
      let dropData = null;
      try {
        dropData = JSON.parse(this.dragPhotoData);
      } catch (e) {}
      let fileList = Path.get(e.detail.originalEvent, 'dataTransfer.files') || Path.get(e.detail.originalEvent, 'target.files');
      let fileCount = Array.from(fileList).filter((file) => file.type === 'image/jpeg').length;
      if (fileCount) {
        this.uploadsInProgress = this.uploadsInProgress || 0;
        this.uploadsComplete = this.uploadsComplete || 0;
        this.uploadsInProgress += fileCount;
        this.showUploadNotification = true;
        if (this.uploader == null)
          this.uploader = new Uploader(config.firebaseConfigs[config.appName], { useWorker: true, parallelCount: 1 });
        this.uploader.uploadFromFiles(
          fileList,
          { jobId: this.job_id, folderId: 'map_drop_upload', cameraId: 'default', uid: this.user.uid },
          async (details) => {
            if (details.message) {
              this.uploadMessage = details.message;
              clearTimeout(this.uploadTimeout);
              this.uploadTimeout = setTimeout(() => (this.uploadMessage = null), 3000);
            }
            if (details.status === 'failed') {
              console.warn('Photo Upload Failed', details.photo);
            } else if (details.status === 'uploading') {
              this.progressPercentage = details.progress;
            } else if (details.status === 'complete') {
              let update = {};
              if (locType == 'node') {
                update['nodes/' + nodeID + '/photos/' + details.photo._key] = { association: true, association_type: 'manual' };
                update['photos/' + details.photo._key + '/associated_locations/' + nodeID] = 'node';
              } else {
                update['connections/' + connID + '/sections/' + secID + '/photos/' + details.photo._key] = {
                  association: true,
                  association_type: 'manual'
                };
                update['photos/' + details.photo._key + '/associated_locations/' + connID + ':' + secID] = 'section';
              }
              update['photo_summary/' + details.photo._key + '/associated'] = true;
              await FirebaseWorker.ref(`photoheight/jobs/${details.photo._jobId}`).update(update);
              this.uploadsComplete++;
              if (this.uploadsComplete == this.uploadsInProgress) {
                setTimeout(() => {
                  this.showUploadNotification = false;
                  this.uploadsInProgress = 0;
                  this.uploadsComplete = 0;
                  this.progressPercentage = 0;
                }, 1000);
              } else {
                this.progressPercentage = 0;
              }
            }
          }
        );
      } else if (dropData && dropData.type == 'add-only') {
        this.movePhoto(this.dragFileItem, this.dragPhotoData, true);
      } else if (e.detail.originalEvent.ctrlKey || e.detail.originalEvent.metaKey || e.detail.originalEvent.altKey) {
        this.movePhoto(this.dragFileItem, this.dragPhotoData);
      } else {
        this.$.dragPhotoMenu.style.top = e.detail.originalEvent.clientY + 10 + 'px';
        this.$.dragPhotoMenu.style.left = e.detail.originalEvent.clientX + 10 + 'px';
        this.$.dragPhotoMenu.style.display = 'block';
        document.addEventListener('click', this._boundCloseDragPhotoMenu);
      }
    } else if (this.runKplaTestImporter(e.detail.originalEvent || e)) {
      this.kplaTestImporter(e.detail.originalEvent || e);
    } else if (eventType == 'drop-file') {
      this.$.dropDetector.dropHandler(e.detail.originalEvent || e);
    }
  }

  runKplaTestImporter(e) {
    let file = e?.dataTransfer?.files?.[0];
    return (
      file &&
      file.name.endsWith('.json') &&
      [
        'JyyDEnYelZg9zeirfUR3DblTkqa2',
        'bbmhW3b4r9e5YjyeRiWtgYopEkh2',
        'zDj6EbHUNSQ4OPa7JQHbFRlePia2',
        'yFuDMG43ndfVT8XPYjN28xi5MNo1',
        '12hyJEAVM7P6j5LLHZCpL97ySaO2',
        'MGU8K56twAeIcSXjuy8kpNWEaa82',
        'rq715F2UEvXa5zyVpn07VLgxws72'
      ].includes(this.user.uid)
    );
  }

  async kplaTestImporter(e) {
    if (!this.job_id) {
      this.$.toast.show('Please select a job to import test cases to.');
      return;
    }
    let id = () => FirebaseWorker.ref().push().key;
    let ftIn = (inches) => Math.floor(inches / 12) + '-' + Math.round(inches % 12);
    let px = (ht, poleTop) => [{ percentX: 50, percentY: 100 - ((82.26 * ht) / poleTop + 10.5) }]; //scale markers to the height of the pole, based on the blank photo pole top position
    let totalCount = 0;
    let direction = 'first';
    let directionCount = 0;
    let directionToggle = 0;
    let file = e.dataTransfer.files?.[0];
    if (file && file.name.endsWith('.json')) {
      e.stopPropagation();
      e.preventDefault();
      this.$.toast.show('Importing Test Cases');
      let poles = null;
      try {
        poles = JSON.parse(await this.readFile(file));
      } catch (e) {
        this.$.toast.show('Invalid JSON to import.');
        throw e;
      }
      let config = await FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/models/kpla_test_importer`)
        .once('value')
        .then((s) => s.val());
      let ll = new google.maps.LatLng(config.map.start_latitude, config.map.start_longitude);
      let update = {};
      for (let pole of poles) {
        let nodeId = id();
        let photoId = id();
        let node = {
          latitude: ll.lat(),
          longitude: ll.lng(),
          photos: { [photoId]: { association: 'main' } },
          attributes: {
            scid: { '-imported': ('' + totalCount).padStart(3, '0') },
            note: { '-imported': JSON.stringify(pole, null, 4) }
          }
        };

        let photo = this.getBlankPhoto('node', { [nodeId]: 'node' });
        for (let prop in config.attributes) {
          node.attributes[prop] = { '-imported': config.attributes[prop] };
        }
        if (pole.hasOwnProperty('class')) node.attributes.pole_class = { '-imported': pole.class };
        if (pole.hasOwnProperty('height')) node.attributes.pole_height = { '-imported': pole.height };
        if (pole.hasOwnProperty('pole_top'))
          photo.photofirst_data.pole_top = {
            [id()]: {
              pole_top_extension: false,
              pixel_selection: [{ percentX: 50, percentY: 10.5 }],
              _manual_height: pole.pole_top,
              manual_height: ftIn(pole.pole_top)
            }
          };
        if (pole.hasOwnProperty('pole_spec')) node.attributes.pole_spec = { '-imported': pole.pole_spec };
        if (pole.hasOwnProperty('class') && pole.hasOwnProperty('height'))
          node.attributes.pole_spec = { '-imported': `${pole.height}-${pole.class} Southern Yellow Pine` };

        update['nodes/' + nodeId] = node;
        GeofireTools.setGeohash('nodes', node, nodeId, this.jobStyles, update);
        update['photos/' + photoId] = photo;
        update['photo_summary/' + photoId] = GetPhotoSummary(photo);

        // Equipment
        if (pole.equipment) {
          pole.equipment.forEach((eq) => {
            let traceId = id();
            update['traces/trace_data/' + traceId] = { company: '', label: '', _trace_type: 'equipment' };
            let marker = { _trace: traceId };
            let markerId = id();
            for (let prop in eq) {
              if (prop == 'measured_height') {
                marker._manual_height = eq[prop];
                marker.manual_height = ftIn(eq[prop]);
                marker.pixel_selection = px(eq[prop], pole.pole_top);
              } else {
                if (prop == 'equipment_type') {
                  let measurements = {
                    street_light: {
                      used: 'bottom_of_bracket',
                      other: {
                        name: 'top_of_bracket',
                        offset: 12
                      }
                    },
                    transformer: {
                      used: 'top_bolt',
                      other: {
                        name: 'bottom_of_equipment',
                        offset: -24
                      }
                    }
                  };
                  if (measurements[eq.equipment_type]) {
                    marker.measurement_of = measurements[eq.equipment_type].used;
                    let otherId = id();
                    update['traces/trace_items/' + traceId + '/' + photoId + '/equipment/' + otherId] = true;
                    photo.photofirst_data.equipment[otherId] = {
                      _trace: traceId,
                      equipment_type: eq.equipment_type,
                      measurement_of: measurements[eq.equipment_type].other.name,
                      _manual_height: eq.measured_height + measurements[eq.equipment_type].other.offset,
                      manual_height: ftIn(eq.measured_height + measurements[eq.equipment_type].other.offset),
                      pixel_selection: px(eq.measured_height + measurements[eq.equipment_type].other.offset, pole.pole_top)
                    };
                  }

                  let dripLoops = {
                    street_light: {
                      spec: 'Streetlight',
                      offset: -4
                    },
                    transformer: {
                      spec: 'Secondary',
                      offset: -28
                    }
                  };
                  if (dripLoops[eq.equipment_type]) {
                    let dripLoopId = id();
                    update['traces/trace_items/' + traceId + '/' + photoId + '/equipment/' + dripLoopId] = true;
                    photo.photofirst_data.equipment[dripLoopId] = {
                      _trace: traceId,
                      equipment_type: 'drip_loop',
                      drip_loop_spec: dripLoops[eq.equipment_type].spec,
                      _manual_height: eq.measured_height + dripLoops[eq.equipment_type].offset,
                      manual_height: ftIn(eq.measured_height + dripLoops[eq.equipment_type].offset),
                      pixel_selection: px(eq.measured_height + dripLoops[eq.equipment_type].offset, pole.pole_top)
                    };
                  }
                }
                marker[prop] = eq[prop];
              }
            }
            update['traces/trace_items/' + traceId + '/' + photoId + '/equipment/' + markerId] = true;
            photo.photofirst_data.equipment[markerId] = marker;
          });
        }
        let spans = {};
        let addWire = (wire, parent, parentMarker, parentType) => {
          let type = parentType || 'wire';
          let span = spans[wire.span_length + '|' + wire.bearing];
          if (!span) {
            let endpointId = id();
            let endpointLL = google.maps.geometry.spherical.computeOffset(ll, wire.span_length * 0.3048, wire.bearing);
            let endpoint = {
              latitude: endpointLL.lat(),
              longitude: endpointLL.lng(),
              attributes: {
                node_type: { '-imported': 'reference' }
              }
            };
            update['nodes/' + endpointId] = endpoint;
            GeofireTools.setGeohash('nodes', endpoint, endpointId, this.jobStyles, update);
            let connId = id();
            let sectionPhotoId = id();
            let sectionLL = google.maps.geometry.spherical.computeOffset(ll, (wire.span_length / 2) * 0.3048, wire.bearing);
            let conn = {
              attributes: {
                connection_type: { '-imported': 'reference' }
              },
              node_id_1: nodeId,
              node_id_2: endpointId,
              sections: {
                midpoint_section: {
                  latitude: sectionLL.lat(),
                  longitude: sectionLL.lng(),
                  photos: { [sectionPhotoId]: { association: 'main' } }
                }
              }
            };
            update['connections/' + connId] = conn;
            GeofireTools.setGeohash('connections', conn, connId, this.jobStyles, update, {
              location1: [ll.lat(), ll.lng()],
              location2: [endpointLL.lat(), endpointLL.lng()],
              nId1: nodeId,
              nId2: endpointId
            });
            GeofireTools.setGeohash('sections', conn.sections.midpoint_section, connId, this.jobStyles, update, {
              sectionId: 'midpoint_section'
            });
            let sectionPhoto = this.getBlankPhoto('section', { [connId + ':midpoint_section']: 'section' });
            update['photos/' + sectionPhotoId] = sectionPhoto;
            update['photo_summary/' + sectionPhotoId] = GetPhotoSummary(sectionPhoto);
            span = {
              photo: sectionPhoto,
              photoId: sectionPhotoId
            };
            spans[wire.span_length + '|' + wire.bearing] = span;
          }
          //Trace
          let traceId = id();
          let cable_type = '';
          if (wire.type == 'primary/neutral' && parent) cable_type = 'Primary';
          else if (wire.type == 'primary/neutral' && !parent) cable_type = 'Neutral';
          else if (wire.type == 'communication') cable_type = 'Telco Com';
          else if (wire.type) cable_type = CamelCase(wire.type);
          update['traces/trace_data/' + traceId] = { company: '', label: '', cable_type, _trace_type: 'cable' };
          //Marker
          let markerId = parentMarker?.id || id();
          let ht = parent?.measured_height ?? wire.measured_height;
          let marker = parentMarker?.marker || {
            _manual_height: ht,
            manual_height: ftIn(ht),
            pixel_selection: px(ht, pole.pole_top),
            arm_spec: parent?.arm_spec ?? null,
            insulator_spec: parent?.insulator_spec ?? null,
            bearing: parent?.bearing ?? null,
            _children: { wire: {} }
          };
          if (parent) {
            let childId = id();
            marker._children.wire[childId] = { _trace: traceId };
            update['traces/trace_items/' + traceId + '/' + photoId + '/' + type + '/' + markerId + ':_children:wire:' + childId] = true;
          } else {
            update['traces/trace_items/' + traceId + '/' + photoId + '/wire/' + markerId] = true;
            marker._trace = traceId;
          }
          photo.photofirst_data[type][markerId] = marker;
          //Midspan Marker
          let spanMarkerId = id();
          update['traces/trace_items/' + traceId + '/' + span.photoId + '/wire/' + spanMarkerId] = true;
          span.photo.photofirst_data.wire[spanMarkerId] = {
            _trace: traceId,
            _manual_height: wire.measured_height,
            manual_height: ftIn(wire.measured_height),
            pixel_selection: px(wire.measured_height, pole.pole_top),
            wire_spec: wire.wire_spec
          };
          return { marker, id: markerId };
        };
        let attTypes = ['arm', 'insulator', 'wire'];
        attTypes.forEach((type) => {
          if (pole[type]) {
            pole[type].forEach((item) => {
              if (item._children) {
                let parentMarker = null;
                item._children.forEach((wire) => {
                  parentMarker = addWire(wire, item, parentMarker, type);
                });
              } else {
                addWire(item);
              }
            });
          }
        });

        // Anchors
        if (pole.guy) {
          pole.guy.forEach((guy) => {
            let endpointId = id();
            let endpointLL = google.maps.geometry.spherical.computeOffset(ll, guy.lead * 0.3048, guy.bearing);
            let endpoint = {
              latitude: endpointLL.lat(),
              longitude: endpointLL.lng(),
              attributes: {
                node_type: { '-imported': 'existing anchor' },
                anc_elevation: { '-imported': '0' },
                existing_aux_eye: { '-imported': '0' },
                eyes: { '-imported': '' },
                size: { '-imported': '' },
                sizes_of_attached_dn_guys: { '-imported': '' },
                anchor_spec: { '-imported': guy[this.modelDefaults.anchor_spec_attribute] ?? null },
                soil_type: { '-imported': guy.soil_type ?? null }
              }
            };
            update['nodes/' + endpointId] = endpoint;
            GeofireTools.setGeohash('nodes', endpoint, endpointId, this.jobStyles, update);
            let connId = id();
            let conn = {
              attributes: {
                connection_type: { '-imported': 'down guy' }
              },
              node_id_1: nodeId,
              node_id_2: endpointId
            };
            update['connections/' + connId] = conn;
            GeofireTools.setGeohash('connections', conn, connId, this.jobStyles, update, {
              location1: [ll.lat(), ll.lng()],
              location2: [endpointLL.lat(), endpointLL.lng()],
              nId1: nodeId,
              nId2: endpointId
            });
            //Trace
            let traceId = id();
            update['traces/trace_data/' + traceId] = { company: '', label: '', _trace_type: 'down_guy' };
            //Marker
            let markerId = id();
            update['traces/trace_items/' + traceId + '/' + photoId + '/guying/' + markerId] = true;
            photo.photofirst_data.guying[markerId] = {
              _trace: traceId,
              _manual_height: guy.measured_height,
              manual_height: ftIn(guy.measured_height),
              pixel_selection: px(guy.measured_height, pole.pole_top),
              wire_spec: guy.wire_spec,
              guying_type: guy.guying_type,
              anchor_id: endpointId
            };
            // Sidewalk brace marker
            if (guy.sidewalk_brace_height) {
              let braceMarkerId = id();
              update['traces/trace_items/' + traceId + '/' + photoId + '/guying/' + braceMarkerId] = true;
              photo.photofirst_data.guying[braceMarkerId] = {
                _trace: traceId,
                _manual_height: guy.sidewalk_brace_height,
                manual_height: ftIn(guy.sidewalk_brace_height),
                pixel_selection: px(guy.sidewalk_brace_height, pole.pole_top),
                side_walk_brace_spec: guy.sidewalk_brace_spec,
                guying_type: 'sidewalk brace'
              };
            }
          });
        }

        directionCount++;
        if (directionCount >= config.map[direction + '_bearing_count']) {
          // switch direction
          direction = direction == 'first' ? 'second' : 'first';
          directionCount = 1;
          if (direction == 'first') {
            directionToggle += 180;
          }
        }
        ll = google.maps.geometry.spherical.computeOffset(
          ll,
          config.map.distance_between * 0.3048,
          config.map[direction + '_bearing'] + (direction == 'second' ? 0 : directionToggle)
        );

        totalCount++;
      }
      await FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
      this.$.toast.show('Import complete.');
    }
  }

  getBlankPhoto(type, associated_locations) {
    associated_locations = associated_locations || {};
    return {
      associated_locations,
      skip_url_signing: true,
      image_width: 600,
      image_height: 800,
      tags: 'Blank Photos',
      filename: 'Blank ' + type + ' photo',
      photofirst_data: { wire: {}, insulator: {}, arm: {}, guying: {}, equipment: {}, pole_top: {} },
      uploaded_by: this.get('user.uid'),
      url_extra_large: 'https://storage.googleapis.com/katapult-pro-shared-files/photos/blank_' + type + '_photo.png',
      url_large: 'https://storage.googleapis.com/katapult-pro-shared-files/photos/blank_' + type + '_photo.png',
      url_small: 'https://storage.googleapis.com/katapult-pro-shared-files/photos/blank_' + type + '_photo.png',
      url_tiny: 'https://storage.googleapis.com/katapult-pro-shared-files/photos/blank_' + type + '_photo.png'
    };
  }

  // type can be text, arrayBuffer, binaryString, dataURL or other readAs type
  async readFile(file, type) {
    type = type || 'text';
    //Make the first letter uppercase
    type = type[0].toUpperCase() + type.slice(1);
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = async function (e) {
        resolve(reader.result);
      };
      reader['readAs' + type](file);
    });
  }

  getProgressLabel(uploadsComplete, uploadsInProgress) {
    return (uploadsComplete || 0) + '/ ' + (uploadsInProgress || 0);
  }

  dragMovePhoto() {
    this.movePhoto(this.dragFileItem, this.dragPhotoData);
  }

  dragAddPhoto() {
    this.movePhoto(this.dragFileItem, this.dragPhotoData, true);
  }

  dragDuplicatePhoto() {
    var dropData = JSON.parse(this.dragPhotoData);
    var dragFileItem = this.dragFileItem;
    var permissionCheck = true;
    if (dropData.jobid != this.job_id) {
      permissionCheck = FirebaseWorker.ref('photoheight/jobs/' + dropData.jobid + '/sharing/' + this.userGroup)
        .once('value')
        .then((s) => s.val() == 'write');
    }
    Promise.resolve(permissionCheck).then((permission) => {
      for (var i = 0; i < dropData.photos.length; i++) {
        this.duplicateImage(dropData.photos[i], dropData.jobid, dragFileItem);
      }
    });
  }

  duplicateImage(photoId, jobId, dragFileItem) {
    FirebaseWorker.ref('photoheight/jobs/' + jobId + '/photo_summary/' + photoId).once(
      'value',
      function (photoId, s) {
        var photoSummary = s.val();
        photoSummary.associated = true;
        var newPhoto = {};
        GetJobData(jobId, 'photos/' + photoId).then((data) => {
          var photo = data['photos/' + photoId];
          var isTagged = false;
          for (var key in photo) {
            if (key == 'filename') {
              var nameSplit = photo[key].split('.');
              var name = nameSplit[nameSplit.length - 2];
              var nameNumberIndex = name.search(/\(\d{1,}\)$/);
              if (nameNumberIndex != -1) {
                var nameString = name.substring(0, nameNumberIndex);
                var nameNumber = Number(name.substring(nameNumberIndex).replace(/\D+/g, ''));
                nameNumber++;
                nameSplit[nameSplit.length - 2] = nameString + '(' + nameNumber + ')';
              } else {
                nameSplit[nameSplit.length - 2] = name + '(2)';
              }
              newPhoto[key] = nameSplit.join('.');
            } else if (key != 'associated_locations') {
              newPhoto[key] = photo[key];
              if (photoSummary.data) {
                isTagged = true;
              }
            }
          }
          if (photo.camera_id) {
            let types = ['numPhotos', 'numAssociated', 'numUploaded'];
            if (isTagged) types.push('numTagged');
            if (photo.folder_id) {
              IncrementFolderCounter(this.job_id, photo.folder_id, photo.camera_id, this.userGroup, types);
            } else {
              FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photo_folders')
                .orderByChild('tags')
                .equalTo(photo.tags)
                .once(
                  'value',
                  function (cameraId, isTagged, s) {
                    var folders = s.val();
                    for (var folderId in folders) {
                      IncrementFolderCounter(this.job_id, folderId, cameraId, this.userGroup, types);
                      break;
                    }
                  }.bind(this, photo.camera_id, isTagged)
                );
            }
          }
          var priority = newPhoto.synced_time;
          if (newPhoto.synced_time == null) {
            priority = newPhoto.filename;
          }
          var newPhotoId = photoId[0] == '-' ? FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photos/').push().key : uuidv4();
          var photoUpdate = {};
          var locationType, nodeId, sectionId, connId;
          if (dragFileItem[0] == 'n') {
            locationType = 'node';
            nodeId = dragFileItem.slice(1);
          } else if (dragFileItem[0] == 's') {
            locationType = 'section';
            var ids = dragFileItem.slice(1).split(':');
            connId = ids[0];
            sectionId = ids[1];
          }
          // Build the photo object to store on the item this photo is being duplicated to.
          const locationData = { association: true, association_type: 'manual' };
          // Set pre construction flag if applicable.
          if (newPhoto.pre_construction != null) locationData.pre_construction = true;
          newPhoto.associated_locations = {};
          if (locationType == 'node') {
            photoUpdate['nodes/' + nodeId + '/photos/' + newPhotoId] = locationData;
            newPhoto.associated_locations[nodeId] = locationType;
          } else {
            photoUpdate['connections/' + connId + '/sections/' + sectionId + '/photos/' + newPhotoId] = locationData;
            newPhoto.associated_locations[connId + ':' + sectionId] = locationType;
          }

          newPhoto['.priority'] = priority;
          photoSummary['.priority'] = priority;

          photoUpdate['/photos/' + newPhotoId] = newPhoto;
          photoUpdate['/photo_summary/' + newPhotoId] = photoSummary;

          new Promise((resolveDuplication) => {
            var types = ['full', 'extra_large', 'large', 'small', 'tiny', 'exif'];
            var count = types.length;
            var files = [];
            var update = {};
            for (var i = 0; i < types.length; i++) {
              var typeName = '_' + types[i] + (types[i] != 'exif' ? (photoId[0] == '-' ? '.jpg' : '.webp') : '');
              if (photoId[0] == '-') {
                var requestKey = photoId + i + '_copy';
                var newFile = this.config.firebaseData.amazonFolder + newPhotoId + typeName;
                var oldFile = this.config.firebaseData.amazonFolder + photoId + typeName;
                var mimetype = 'image/jpeg';
                if (types[i] == 'exif') {
                  mimetype = 'application/octet-stream';
                }
                update[requestKey] = {
                  name: newFile,
                  method: 'PUT',
                  headers: ['x-amz-copy-source:/' + this.config.firebaseData.amazonBucket + oldFile],
                  mimetype: mimetype
                };
                FirebaseWorker.ref('photoheight/server_requests/sign_files/responses/' + requestKey).on(
                  'value',
                  function (newFile, oldFile, mimetype, snapshot) {
                    var url = snapshot.val();
                    if (url != null) {
                      snapshot.ref.remove();
                      var xhr = new XMLHttpRequest();
                      xhr.open('PUT', url, true);
                      xhr.onload = function (xhr) {
                        if (xhr.status === 200) {
                          count--;
                          if (count == 0) {
                            this.photo = newPhotoId;
                            resolveDuplication();
                          }
                        } else {
                          console.log(xhr.responseText);
                          this.duplicateImageError(newPhotoId, 'STATUS ' + xhr.status);
                        }
                      }.bind(this, xhr);
                      xhr.onerror = function (e) {
                        this.duplicateImageError(newPhotoId, e.error);
                      }.bind(this);
                      xhr.onabort = function (e) {
                        this.duplicateImageError(newPhotoId, e.error);
                      }.bind(this);
                      xhr.setRequestHeader('Content-Type', mimetype);
                      // 	xhr.setRequestHeader("Content-Disposition", "filename=" + newFile);
                      xhr.setRequestHeader('x-amz-copy-source', '/' + this.config.firebaseData.amazonBucket + oldFile);
                      xhr.setRequestHeader('x-amz-acl', 'authenticated-read');
                      xhr.send();
                    }
                  }.bind(this, newFile, oldFile, mimetype)
                );
              } else {
                files.push({ from: 'photos/' + photoId + typeName, to: 'photos/' + newPhotoId + typeName });
              }
            }
            if (photoId[0] == '-') {
              FirebaseWorker.ref('photoheight/server_requests/sign_files/requests').update(update);
            } else {
              firebase
                .functions()
                .httpsCallable('copyFiles')({ files })
                .then((result) => {
                  this.$.infoPanel.reloadImage(newPhotoId);
                  resolveDuplication();
                })
                .catch((err) => {
                  console.log('err', err);
                  this.duplicateImageError(newPhotoId, '');
                });
            }
          }).then(() => {
            // Update Firebase with new photo data
            if (this.job_id != null && this.job_id != '') {
              FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(photoUpdate, (error) => {
                if (error) {
                  console.log(error);
                } else {
                  this.toast('Photo Duplicated');
                }
              });
            }
          });
        });
      }.bind(this, photoId)
    );
  }

  duplicateImageError(newPhotoId, message) {
    this.toast('Error in Photo Duplication: ' + message);
    FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photos/' + newPhotoId).remove();
  }

  closeDragPhotoMenu(e) {
    document.removeEventListener('click', this._boundCloseDragPhotoMenu);
    this.$.dragPhotoMenu.style.display = 'none';
    this.dragFileItem = null;
    this.dragPhotoData = null;
  }

  movePhoto(moveItemId, dragData, addOnly) {
    let locType, nodeID, secID, connID;
    if (this.dragFileItem[0] == 'n') {
      locType = 'node';
      nodeID = this.dragFileItem.slice(1);
    } else if (this.dragFileItem[0] == 's') {
      locType = 'section';
      var ids = this.dragFileItem.slice(1).split(':');
      connID = ids[0];
      secID = ids[1];
    }
    let dropData = dragData;
    try {
      dropData = JSON.parse(dropData);
      let paths = dropData.photos.map((photoId) => 'photos/' + photoId);
      GetJobData(dropData.jobid, paths).then((data) => {
        var permissionCheck = true;
        if (dropData.jobid != this.job_id) {
          permissionCheck = FirebaseWorker.ref('photoheight/jobs/' + dropData.jobid + '/sharing/' + this.userGroup)
            .once('value')
            .then((s) => s.val() == 'write');
        }
        Promise.resolve(permissionCheck).then((permission) => {
          if (!permission) {
            this.toast('You do not have permission to move photos from a read-only job.');
          } else if (
            (dropData.move_from != 'n' + nodeID && dropData.move_from != 's' + connID + ':' + secID) ||
            dropData.jobid != this.job_id
          ) {
            // Prepare to store first editors to action logging purposes
            let firstEditors = [];
            // Loop through each dropped photo
            for (let i = 0; i < dropData.photos.length; i++) {
              var photo = SquashNulls(data, 'photos/' + dropData.photos[i]);
              let update = {};
              if (dropData.cameraid) {
                photo.camera_id = dropData.cameraid;
              }
              let photoSummary = GetPhotoSummary(photo);
              if (dropData.folderid) {
                photo.folder_id = dropData.folderid;
                if (dropData.jobid != this.job_id) {
                  let types = ['numPhotos', 'numUploaded', 'numAssociated'];
                  if (photoSummary.data) {
                    types.push('numTagged');
                  }
                  IncrementFolderCounter(this.job_id, dropData.folderid, photo.camera_id, this.userGroup, types);
                }
              }
              // Build the photo object to store on the item this photo is being duplicated to.
              const locationData = { association: true, association_type: 'manual' };
              // Set pre construction flag if applicable.
              if (photo.pre_construction != null) locationData.pre_construction = true;
              if (locType == 'node') {
                update[this.job_id + '/nodes/' + nodeID + '/photos/' + dropData.photos[i]] = locationData;
                if (dropData.jobid != this.job_id) {
                  photo.associated_locations = photo.associated_locations || {};
                  photo.associated_locations[nodeID] = 'node';
                  update[this.job_id + '/photos/' + dropData.photos[i]] = photo;
                  update[this.job_id + '/photo_summary/' + dropData.photos[i]] = photoSummary;
                } else {
                  update[this.job_id + '/photos/' + dropData.photos[i] + '/associated_locations/' + nodeID] = 'node';
                  update[this.job_id + '/photo_summary/' + dropData.photos[i] + '/associated'] = true;
                }
              } else {
                update[this.job_id + '/connections/' + connID + '/sections/' + secID + '/photos/' + dropData.photos[i]] = locationData;
                if (dropData.jobid != this.job_id) {
                  photo.associated_locations = photo.associated_locations || {};
                  photo.associated_locations[connID + ':' + secID] = 'section';
                  update[this.job_id + '/photos/' + dropData.photos[i]] = photo;
                  update[this.job_id + '/photo_summary/' + dropData.photos[i]] = photoSummary;
                } else {
                  update[this.job_id + '/photos/' + dropData.photos[i] + '/associated_locations/' + connID + ':' + secID] = 'section';
                  update[this.job_id + '/photo_summary/' + dropData.photos[i] + '/associated'] = true;
                }
              }
              if (photo.associated_locations == null && photo.camera_id) {
                if (photo.folder_id) {
                  IncrementFolderCounter(this.job_id, photo.folder_id, photo.camera_id, this.userGroup, 'numAssociated');
                } else {
                  FirebaseWorker.ref('photoheight/jobs/' + this.job_id + '/photo_folders')
                    .orderByChild('tags')
                    .equalTo(photo.tags)
                    .once(
                      'value',
                      function (photo, s) {
                        var folders = s.val();
                        for (var folderId in folders) {
                          IncrementFolderCounter(this.job_id, folderId, photo.camera_id, this.userGroup, 'numAssociated');
                          break;
                        }
                      }.bind(this, photo)
                    );
                }
              }
              if (dropData.move_from && !addOnly) {
                let id = dropData.move_from.slice(1);
                if (dropData.jobid != this.job_id) {
                  update[dropData.jobid + '/photos/' + dropData.photos[i]] == null;
                  update[dropData.jobid + '/photo_summary/' + dropData.photos[i]] = null;
                } else {
                  update[dropData.jobid + '/photos/' + dropData.photos[i] + '/associated_locations/' + id] = null;
                }
                if (dropData.move_from[0] == 's') {
                  let ids = id.split(':');
                  update[dropData.jobid + '/connections/' + ids[0] + '/sections/' + ids[1] + '/photos/' + dropData.photos[i]] = null;
                } else
                  update[
                    dropData.jobid + (dropData.move_from[0] == 'n' ? '/nodes/' : '/connections/') + id + '/photos/' + dropData.photos[i]
                  ] = null;
              }
              if (dropData.move_from_associated && !addOnly) {
                for (var key in photo.associated_locations) {
                  if (update[dropData.jobid + '/photos/' + dropData.photos[i] + '/associated_locations/' + key] == null) {
                    // only remove association if it is not where we are dropping
                    if (dropData.jobid != this.job_id) {
                      update[dropData.jobid + '/photos/' + dropData.photos[i]] = null;
                      update[dropData.jobid + '/photo_summary/' + dropData.photos[i]] = null;
                    } else {
                      update[dropData.jobid + '/photos/' + dropData.photos[i] + '/associated_locations/' + key] = null;
                    }
                    if (photo.associated_locations[key] == 'section') {
                      let ids = key.split(':');
                      update[dropData.jobid + '/connections/' + ids[0] + '/sections/' + ids[1] + '/photos/' + dropData.photos[i]] = null;
                    } else {
                      update[
                        dropData.jobid +
                          (photo.associated_locations[key] == 'node' ? '/nodes/' : '/connections/') +
                          key +
                          '/photos/' +
                          dropData.photos[i]
                      ] = null;
                    }
                  }
                }
              }
              if (this.job_id != null && this.job_id != '' && dropData.jobid != null && dropData.jobid != '') {
                FirebaseWorker.ref('photoheight/jobs/').update(update, (e) => {
                  if (e) this.toast(e);
                });
              }
              // Get the list of editors, sort them by their first edit timestamp, and retrieve the first editor
              let editors = SquashNulls(data, 'photos/' + dropData.photos[i], 'photofirst_data', '_editors');
              let firstEditor = Object.entries(editors)
                .sort((a, b) => a[1] - b[1])
                .map((a) => a[0])[0];
              let editorIndex = firstEditors.findIndex((a) => a.uid == firstEditor);
              if (editorIndex >= 0) firstEditors[editorIndex].count += 1;
              else
                firstEditors.push({
                  uid: firstEditor,
                  count: 1,
                  timestamp:
                    typeof editors[firstEditor] == 'boolean'
                      ? firebase.firestore.FieldValue.serverTimestamp()
                      : new Date(editors[firstEditor])
                });
            }
            // Check that there are action tracking models available and check that the nodeID is available, meaning this was
            // a photo drop on a node. We don't do action tracking on sections, so we can skip this if the photo was moved to a section
            if (FirebaseWorker?.actionTrackingModels?.length && nodeID) {
              // Figure out who did the most photo-firsting on this node and log it
              if (firstEditors.length) {
                firstEditors.sort((a, b) => b.count - a.count);
                let topEditor = firstEditors[0];
                if (firstEditors.length > 1) {
                  let topEditors = firstEditors.filter((a) => a.count == topEditor.count);
                  topEditor = topEditors[Math.floor(Math.random() * topEditors.length)];
                }
                let ref = firebase.firestore().collection(`companies/${this.userGroup}/action_tracking`);
                // TODO-WARN: Hard coding of id is not a long-term solution
                ref
                  .where('job_id', '==', this.job_id)
                  .where('action_id', '==', '-McL2dSwnbOrpU5TTiNY')
                  .where('node_id', '==', nodeID)
                  .get()
                  .then((s) => {
                    if (s.empty) {
                      ref.add({
                        uid: topEditor.uid,
                        timestamp: topEditor.timestamp,
                        time_of_association: firebase.firestore.FieldValue.serverTimestamp(),
                        job_id: this.job_id,
                        node_id: nodeID,
                        action_name: 'PhotoFirst',
                        action_id: '-McL2dSwnbOrpU5TTiNY'
                      });
                    }
                  });
              }
            }
          }
        });
      });
    } catch (e) {
      console.warn(e, dropData);
      this.toast('Bad dropped data. No action taken.');
    }
  }

  applyShortcuts(keyEvent) {
    // Get item data
    let item = this.hoverItemData.item;
    let itemKey = this.hoverItemData.key;
    // Short circuit if item or key is null or undefined
    if (!item || !itemKey) return;
    // Determine which shortcuts apply
    let shortcutsToApply = this.shortcuts.filter((s) => s.trigger.toLowerCase() == keyEvent.key.toLowerCase());
    // Apply each shortcut
    for (const shortcut of shortcutsToApply) {
      if (shortcut.type == 'call') {
        this[shortcut.function]();
      } else {
        let valueKey, oldValue, value;
        let update = {};
        // Check if the attribute exists
        let attributeExists = Path.get(item, `attributes.${shortcut.attribute}`);
        // If the attribute exists, get the old key and value (first set)
        if (attributeExists) [valueKey, oldValue] = Object.entries(Path.get(item, `attributes.${shortcut.attribute}`))[0];
        // If valueKey is still undefined, get a new key
        if (!valueKey) valueKey = FirebaseWorker.ref().push().key;
        // Get the new value based on the old
        switch (shortcut.type) {
          case 'toggle':
            value = !oldValue;
            break;
          case 'set':
            value = shortcut.value;
            break;
        }
        // Update the local data with the toggled value
        Path.set(item, `attributes.${shortcut.attribute}.${valueKey}`, value);
        // Set the update object and update styles and firebase data with the toggled value
        update[`nodes/${itemKey}/attributes/${shortcut.attribute}/${valueKey}`] = value;
        GeofireTools.updateStyle('nodes', itemKey, item, update, this.jobStyles, this.sectionId);
        FirebaseWorker.ref(`photoheight/jobs/${this.job_id}`).update(update);
        // Get the ordering attribute for this node
        let orderValue = PickAnAttribute(item.attributes, this.modelDefaults.ordering_attribute) || 'the specified node';
        // Toast our success!
        this.toast(`Updated ${CamelCase(shortcut.attribute)} on ${orderValue}`);
      }
    }
  }

  itemMouseover(e, d) {
    if (d) {
      let key = d.key;
      if (key) {
        let item = SquashNulls(this.nodes, d.key);

        if (item) {
          // TODO: Presently, this does nothing. Adding hover-item to katapult-map will show hover icon on nodes all the time.
          this.hoverItem = `n${d.key}`;
          this.hoverItemData = { item, key: d.key };
          let nodeType = PickAnAttribute(item.attributes, this.modelDefaults.node_type_attribute);
          if (this.modelDefaults.pole_node_types.includes(nodeType)) this.poleHover = true;
        }
      }
    }
  }

  itemMouseout(e) {
    this.hoverItem = null;
    this.poleHover = false;
    this.hoverItemData = null;
  }

  setPplPowerSpecLookup() {
    if (this.powerSpecLookup != null) {
      var lookup = {};
      for (var i = 0; i < this.powerSpecLookup.length; i++) {
        lookup[this.powerSpecLookup[i].ppl_database] = this.powerSpecLookup[i].ppl_poleforeman;
      }
      this.pplPowerSpecLookup = lookup;
    }
  }

  _connectionsObserver() {
    if (this.connections != null) {
      this.bigObjectKeys(this.connections, 'connectionKeys');
    }
  }

  async laserHeightRoutine() {
    let poleCount = 0;
    let attCount = 0;

    //loop through every pole
    for (let node in this.nodes) {
      if (this.nodes[node]) {
        if (this.nodes[node].attributes.ht_base_of_pole || this.nodes[node].attributes.ht_ground) {
          //let basePoleHeight = strToHeight(SquashNulls(this.nodes[node],'attributes','ht_base_of_pole','assessment','measurement'));
          let basePoleHeight = SquashNulls(this.nodes[node], 'attributes', 'ht_ground', 'assessment', 'measurement');
          if (!basePoleHeight) basePoleHeight = SquashNulls(this.nodes[node], 'attributes', 'ht_base_of_pole', 'assessment', 'measurement');

          poleCount++;

          let i = 0;
          for (let attribute in this.nodes[node].attributes) {
            if (attribute.charAt(0) === 'h' && attribute.charAt(1) === 't') {
              //let heightRep = heightReplace(SquashNulls(this.nodes[node],'attributes',attribute,'measurement'), basePoleHeight);
              let heightRep = SquashNulls(this.nodes[node], 'attributes', attribute, 'assessment', 'measurement');
              if (heightRep) {
                attCount++;
                this.set(`nodes.${node}.attributes.${attribute}.assessment.measurement`, heightRep - basePoleHeight); //.str);

                FirebaseWorker.ref(
                  `photoheight/jobs/${this.job_id}/photos/${this.getMainPhotoFromNodeId(node)}/photofirst_data/anchor_calibration`
                ).push({
                  height: heightRep - basePoleHeight, //.ht/12,
                  pixel_selection: [{ percentX: 10 + i, percentY: 10 + 3 * i }]
                });
                i++;
              }
            }
          }
        }
      }
    }
  }

  async getShortcuts(modelspace) {
    // Short circuit if modelspace is null or undefined
    if (!modelspace) return;
    // Return the shortcuts model in array form
    this.shortcuts = Object.values(
      await FirebaseWorker.ref(`photoheight/company_space/${modelspace}/models/shortcuts/map`)
        .once('value')
        .then((s) => s.val() || {})
    );
  }

  async masterLocationDirectoryToggleJob(srcElement, jobId, jobPath) {
    if (srcElement && jobId && jobPath) {
      await import('../job-chooser/project-folder-panel.js');
      if (srcElement.checked) {
        this.$.projectFolderPanel.turnOnJobLayer(jobId, jobPath);
      } else {
        this.$.projectFolderPanel.turnOffJobLayer(jobId, jobPath);
      }
    }
  }

  dragContextPhoto(e) {
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({
        photos: JSON.parse(e.target.getAttribute('photos')),
        jobid: e.target.getAttribute('jobid'),
        type: e.target.getAttribute('type'),
        cameraid: e.target.getAttribute('cameraid'),
        folderid: e.target.getAttribute('folderid')
      })
    );
    e.dataTransfer.effectAllowed = 'move';
  }

  expandReferenceInfo(srcElement) {
    srcElement.icon = srcElement.icon == 'expand_more' ? 'expand_less' : 'expand_more';
    let ironCollapse = SquashNulls(srcElement, 'parentNode', 'parentNode', 'nextElementSibling');
    if (ironCollapse) {
      ironCollapse.toggle();
    }
  }

  linkCableSpec() {
    var powerSpecAttribute = 'wire_spec';
    if (SquashNulls(this.otherAttributes, 'power_spec') != '') {
      powerSpecAttribute = 'power_spec';
    }
    var selectedSpec = this.selectedContextCable.power_spec;
    var selectedNeutralSpec = this.selectedContextCable.neutral_spec;
    if (powerSpecAttribute == 'wire_spec' && this.pplPowerSpecLookup != null) {
      if (this.pplPowerSpecLookup[selectedSpec] != null) selectedSpec = this.pplPowerSpecLookup[selectedSpec];
      if (this.pplPowerSpecLookup[selectedNeutralSpec] != null) selectedNeutralSpec = this.pplPowerSpecLookup[selectedNeutralSpec];
    }
    if (this.selectedContextCable != null && this.activeCommand == '_linkMapPhotoData') {
      var action = this.linkMapPhotoActions[0];
      var update = {};
      var gotData = false;
      var doneItemsCount = 0;
      var secondaryTypes = ['Secondary', 'Open Secondary', 'Street Light Feed', 'Power Drop', 'Service'];
      if (this.selectedContextCable.feeder) {
        let conn = SquashNulls(this.connections, action.connId);
        let n1 = SquashNulls(this.nodes, conn.node_id_1);
        if (n1) {
          let feeder = PickAnAttribute(n1.attributes, 'feeder') || '';
          if (!feeder.includes(this.selectedContextCable.feeder)) {
            if (feeder != '') feeder += '/';
            feeder += this.selectedContextCable.feeder;
          }
          update['nodes/' + conn.node_id_1 + '/attributes/feeder/'] = { hw_details_added: feeder };
        }
        let n2 = SquashNulls(this.nodes, conn.node_id_2);
        if (n2) {
          let feeder = PickAnAttribute(n2.attributes, 'feeder') || '';
          if (!feeder.includes(this.selectedContextCable.feeder)) {
            if (feeder != '') feeder += '/';
            feeder += this.selectedContextCable.feeder;
          }
          update['nodes/' + conn.node_id_2 + '/attributes/feeder/'] = { hw_details_added: feeder };
        }
      }
      if (this.selectedContextCable.type == 'secondary' && secondaryTypes.indexOf(action.cable_type) != -1) {
        for (var j = 0; j < this.linkMapPhotoActions.length; j++) {
          var futureAction = this.linkMapPhotoActions[j];
          if (
            futureAction.connId === action.connId &&
            action.cable_type == futureAction.cable_type &&
            secondaryTypes.indexOf(futureAction.cable_type) != -1 &&
            futureAction.sectionHeights != null
          ) {
            for (var i = 0; i < futureAction.sectionHeights.length; i++) {
              if (!this.skipDoneMapPhotoLinks || !futureAction.sectionHeights[i].done) {
                update[
                  'photos/' +
                    futureAction.sectionHeights[i].photoId +
                    '/photofirst_data/' +
                    futureAction.sectionHeights[i].property +
                    '/' +
                    futureAction.sectionHeights[i].itemKey +
                    '/' +
                    powerSpecAttribute
                ] = selectedSpec;
                gotData = true;
              }
            }
            doneItemsCount++;
          } else break;
        }
      } else if (this.selectedContextCable.type == 'primary') {
        var primaryTypes = ['Primary', 'Bundled Primary'];
        var foundPrimaryType = null;
        for (var j = 0; j < this.linkMapPhotoActions.length; j++) {
          var futureAction = this.linkMapPhotoActions[j];
          if (
            futureAction.connId === action.connId &&
            ((primaryTypes.indexOf(futureAction.cable_type) != -1 &&
              (foundPrimaryType == null || foundPrimaryType == futureAction.cable_type)) ||
              futureAction.cable_type == 'Neutral') &&
            futureAction.sectionHeights != null
          ) {
            if (primaryTypes.indexOf(futureAction.cable_type) != -1) {
              foundPrimaryType = futureAction.cable_type;
              for (var i = 0; i < futureAction.sectionHeights.length; i++) {
                if (!this.skipDoneMapPhotoLinks || !futureAction.sectionHeights[i].done) {
                  update[
                    'photos/' +
                      futureAction.sectionHeights[i].photoId +
                      '/photofirst_data/' +
                      futureAction.sectionHeights[i].property +
                      '/' +
                      futureAction.sectionHeights[i].itemKey +
                      '/' +
                      powerSpecAttribute
                  ] = selectedSpec;
                  gotData = true;
                }
              }
              doneItemsCount++;
            } else if (futureAction.cable_type == 'Neutral') {
              for (var i = 0; i < futureAction.sectionHeights.length; i++) {
                if (!this.skipDoneMapPhotoLinks || !futureAction.sectionHeights[i].done) {
                  update[
                    'photos/' +
                      futureAction.sectionHeights[i].photoId +
                      '/photofirst_data/' +
                      futureAction.sectionHeights[i].property +
                      '/' +
                      futureAction.sectionHeights[i].itemKey +
                      '/' +
                      powerSpecAttribute
                  ] = selectedNeutralSpec;
                  gotData = true;
                }
              }
              doneItemsCount++;
            }
          } else break;
        }
      }
      if (gotData) {
        FirebaseWorker.ref('photoheight/jobs/' + this.job_id).update(update, (error) => {
          if (error) this.warningToast(error);
          else {
            var shiftNext = { doIt: true, count: doneItemsCount };
            this.doNextMapPhotoAction(false, shiftNext);
          }
        });
      } else this.warningToast('No valid Spec found for ' + action.cable_description, null, 2000);
    }
    this.infoWindow.close();
  }

  updateLoadingArrows(e) {
    const cleanup = (nodeId) => {
      if (this.loadingArrows[nodeId] == null) return;
      this.loadingArrows[nodeId].windAngle.setMap(null);
      this.loadingArrows[nodeId].deflectionAngle.setMap(null);
      delete this.loadingArrows[nodeId];
    };

    // If detail is null, remove existing arrows from the map
    if (e.type == 'clear-loading-arrows') {
      for (const nodeId in this.loadingArrows) cleanup(nodeId);
      return;
    }
    // Otherwise, add the arrows to the map
    let origin = new google.maps.LatLng(this.getNodeLatitude(this.selectedNode), this.getNodeLongitude(this.selectedNode));
    if (!this.loadingArrows) this.loadingArrows = {};
    // Cleanup existing arrows for this node.
    cleanup(e.detail.nodeId);
    // Setup the new arrows.
    this.loadingArrows[e.detail.nodeId] = {
      windAngle: new google.maps.Marker({
        position: origin,
        clickable: false,
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          anchor: { x: 0, y: -5 },
          scale: 4,
          fillColor: '#2196f3', // paper-blue-500
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: Convert(e.detail.windAngle, 'rad', 'deg')
        },
        map: this.map
      }),
      deflectionAngle: new google.maps.Marker({
        position: origin,
        clickable: false,
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          anchor: { x: 0, y: 10 },
          scale: 4,
          fillColor: '#f44336', // paper-red-500
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: Convert(e.detail.deflectionAngle, 'rad', 'deg')
        },
        map: this.map
      })
    };
  }

  // This is the delete photo data button
  async _button_delete_photo_data() {
    this.activeCommand = '_multiSelectItems';
    // Tell the multi select counter which types to show
    this.multiSelectIncludedTypes = {
      nodes: true,
      sections: true,
      connections: false
    };
    this.$.katapultMap.openActionDialog({
      text: 'Click nodes or draw a polygon around them to select. Right click to delete polygon points. Click Finish without selecting anything to delete the data on all nodes.',
      buttons: [
        { title: 'Cancel', callback: this.cancelPromptAction.bind(this), attributes: { outline: '' } },
        { title: 'Finish', callback: this.openDeleteDialog.bind(this), attributes: { 'secondary-color': '' } }
      ]
    });
  }

  async openDeleteDialog() {
    //Combine all selected items into one array
    this.associatedLocations = [...this.$.katapultMap.multiSelectedNodes, ...this.$.katapultMap.multiSelectedSections];

    this.$.katapultMap.closeActionDialog();
    this.$.removePhotoDataDialog.open();
  }

  async deletePhotoData() {
    //Set flags for delete function
    this.deleteTraceData = this.deleteMarkerData = this.deleteClassificationData = true;
    this.deleteCalibrationData = false;

    this.removeAnnotationData();
  }

  _button_remove_annotation_data() {
    this.associatedLocations = [];
    this.$.removeAnnotationDataDialog.open();
  }

  async _button_bulk_insert_proposed() {
    this.confirm(
      'Set Proposed Height',
      'You can determine the height in inches that the proposed cables will be generated above or below existing comms. Enter 0 inches for the cable to appear at the same height as the existing comm.',
      'Confirm',
      'Cancel',
      null,
      'bulkInsertProposed',
      this.bulkInsertProposed.bind(this)
    );
  }

  async bulkInsertProposed() {
    let warningMessage = null;
    if (this.locked) warningMessage = 'Unlock the job to insert a proposed attachment.';
    if (this.inARoutine || this.mode) warningMessage = 'Exit the current routine or mode to insert a proposed attachment.';
    if (warningMessage) {
      this.fire('toast', warningMessage);
      return;
    }
    let options = {};

    // Check if we should use the old logic or the new one. If the user does not have models set up, use the basic mode made for Tilson
    const insertionMode =
      Object.values(this.proposedCableLogic?.insertion_logic || {}).find((mode) => mode.active == true)?.value ||
      'tilson_custom_insertion_mode';
    const photos = await GetJobData(this.job_id, 'photos').then((data) => data.photos || null);

    let photoList = {};
    for (let nodeId in this.nodes) {
      const node = this.nodes[nodeId];
      if (!this.modelDefaults.pole_node_types.includes(PickAnAttribute(node.attributes, this.modelDefaults.node_type_attribute))) continue;
      for (var key in node.photos) {
        // Find the key for the main photo
        if (node.photos[key] == 'main' || node.photos[key].association == 'main') {
          photoList[key] = { photoAssociation: 'node', photoData: photos[key], item: { nodeId: nodeId } };
        }
      }
    }
    for (let connId in this.connections) {
      const conn = this.connections[connId];
      const connType = PickAnAttribute(conn.attributes, this.modelDefaults.connection_type_attribute);
      if (connType == 'dropwire' || connType == 'reference') continue;
      for (let sectionId in conn.sections) {
        const section = conn.sections?.[sectionId];
        for (var key in section.photos) {
          // Find the key for the main photo
          if (section.photos[key] == 'main' || section.photos[key].association == 'main') {
            photoList[key] = { photoAssociation: 'connection', photoData: photos[key] };
          }
        }
      }
    }

    const jobContext = {
      jobId: this.job_id,
      traceModels: this.traceModels,
      inputModels: this.inputModels,
      mrClearances: this.mrClearances?.data,
      modelAttributes: this.otherAttributes,
      traces: this.traces,
      connections: this.connections,
      nodes: this.nodes,
      photos
    };

    options.bulkInsert = true;
    options.forceInsertNewProposed = this.forceInsertCheck;
    options.proposedInchDistance = this.proposedInchDistance;
    options.proposedInsertLocation = this.proposedInsertLocation;

    this.$.katapultMap.closeActionDialog();
    this.shadowRoot.querySelector('#photoControls').insertProposed(insertionMode, photoList, jobContext, options);
  }

  removeAnnotationData() {
    this.$.removeAnnotationDataDialog.close();
    this.newSnapshotName = null;
    this.newSnapshotNumbers = null;

    // If associated locations is empty (didn't use polygon selection), grab all nodes and sections
    let sectionIds = [];
    if (this.associatedLocations === undefined || this.associatedLocations.length === 0) {
      Object.entries(this.connections).forEach((connection) => {
        if (connection[1].sections != undefined) {
          Object.keys(connection[1].sections).forEach((section) => {
            sectionIds.push(connection[0].concat(':', section));
          });
        }
      });
      this.associatedLocations = Object.keys(this.nodes).concat(sectionIds);
    }
    this.confirm('Create Snapshot', 'Please give a name to this snapshot', 'Continue', 'Cancel', '', 'createSnapshot', async () => {
      await this.$.infoPanel.$.snapshots.createSnapshot(this.newSnapshotName, this.newSnapshotNumbers, this).catch((err) => {
        if (err) {
          throw new Error('Failed to generate snapshot');
        }
      });
      await new Promise((x) => setTimeout(x, 500));
      const { removeAnnotationDataFromJob } = await import('../../../_resources/modules/removeAnnotationDataFromJob.js');
      await removeAnnotationDataFromJob(
        this.job_id,
        this.deleteTraceData,
        this.deleteMarkerData,
        this.deleteCalibrationData,
        this.deleteClassificationData,
        this.associatedLocations,
        FirebaseWorker
      );

      this.deleteTraceData = this.deleteMarkerData = this.deleteCalibrationData = this.deleteClassificationData = false;
      this.toast('Annotation data removed from job');
      this.cancelPromptAction();
    });
  }

  deleteMarkerDataChanged() {
    if (this.deleteMarkerData) this.deleteTraceData = true;
  }

  isSnapshotValid() {
    if (!this.shadowRoot.querySelector('#snapshotName').value) {
      this.isConfirmDisabled = true;
    } else {
      this.isConfirmDisabled = false;
    }
  }

  async updateShowLegacyFeedback() {
    // Use the flag setting, and fall back if necessary
    this.showLegacyFeedback = this.enabledFeatures?.legacy_job_feedback || this.companyOptions?.map?.old_feedback || false;
  }

  async updateMapLayer() {
    if (this.canWrite && this.job_id) {
      // Edit just one feature within the layer.
      if (this.editingFeatureRef) {
        // Update the feature in cloud storage (or RTDB), if it isn't a master reference layer
        if (!this.editingFeatureRef.masterRefLayer) {
          const { updateFeature } = await import('../../modules/JobLayers.js');
          await updateFeature({
            jobId: this.job_id,
            layerId: this.editingFeatureRef.layerKey,
            featureId: this.editingFeatureRef.featureKey,
            storageFileName: this.editingFeatureRef.storage_file_name,
            properties: {
              stroke: this.editingMapLayerColor,
              fill: this.editingMapLayerFillColor,
              'stroke-width': this.editingMapLayerWeight
            },
            commit: true
          });
        }

        // Update the feature in memory
        this.editingFeature.setProperty('stroke', this.editingMapLayerColor);
        this.editingFeature.setProperty('fill', this.editingMapLayerFillColor);
        this.editingFeature.setProperty('stroke-width', this.editingMapLayerWeight);
      }
      // Edit the whole layer.
      else if (this.editingMapLayer && this.editingMapLayer.$key) {
        // Update the layer in cloud storage (or RTDB)
        const { updateLayer } = await import('../../modules/JobLayers.js');
        const { iconURL } = await updateLayer({
          jobId: this.job_id,
          layerId: this.editingMapLayer.$key,
          storageFileName: this.editingMapLayer.storage_file_name,
          color: this.editingMapLayerColor,
          fillColor: this.editingMapLayerFillColor,
          weight: this.editingMapLayerWeight,
          iconColor: this.editingMapLayerIconColor || '#000000',
          icon: this.editingMapLayerIcon
        });

        // Update the layer in memory
        let geoJsonLayer = this.geoJsonLayers.find((x) => x.key == this.editingMapLayer.$key);
        // Check if the map layer is in geoJsonLayers
        if (geoJsonLayer) {
          // Get the features and update the colors
          let features = geoJsonLayer.features;
          features.forEach((feature, index) => {
            feature.setProperty('stroke', this.editingMapLayerColor);
            feature.setProperty('fill', this.editingMapLayerFillColor);
            feature.setProperty('stroke-width', this.editingMapLayerWeight);
            // only update features that have no icon properties or that have the noIconDataFound tag
            if (
              (this.enabledFeatures.configurable_kmz_icon_fallback === true &&
                !feature.getProperty('icon') &&
                feature.getProperty('styleUrl') != '#HiddenIcon') ||
              feature.getProperty('noIconDataFound')
            ) {
              feature.setProperty('icon', iconURL);
            }
          });
        }
      }
    }
    this.$.editFeatureDialog.close();
  }

  /**
   * Opens a new window to display a 3D view of the current map and currently selected node
   * If a 3D view window is already open, it will focus on that window instead.
   */
  open3DView() {
    if (this.linkedThreeDViewWindow && this.linkedThreeDViewWindow.closed === false) {
      this.linkedThreeDViewWindow.location.hash = `${this.job_id}/${this.selectedNode}`;
      this.linkedThreeDViewWindow.focus();
      return;
    }

    this.linkedThreeDViewWindow = window.open(
      `${window.location.pathname.replace('/map', '/3d-model')}#${this.job_id}/${this.selectedNode}`,
      '3DView'
    );
  }
}

customElements.define('katapult-maps-desktop', KatapultMapsDesktop);
