/*global firebase, k, google, GeofireTools*/
import { PickAnAttribute } from '../../modules/PickAnAttribute';
import { LargeUpdate } from '../../modules/LargeUpdate.js';
import { SquashNulls } from '../../modules/SquashNulls.js';
import { GetMainPhoto } from '../../modules/GetMainPhoto.js';
import { GetJobData } from '../../modules/GetJobData.js';
import { GeofireTools } from '../../modules/GeofireTools.js';
import { KLogic } from '../../modules/KLogic.js';
import { Path } from '../../modules/Path.js';
import { GetNewAttributeValue } from '../../modules/GetNewAttributeValue.js';

class PrepForPostConstruction {
  static async run(jobId, jobCreator, userGroup, models = {}, modelDefaults, toast, preppedMapStylesKey, otherAttributes, options = {}) {
    /* NOTE: I have intentionally chosen not to use FirebaseWorker here because it is important to know we are working with the latest data and FirebaseWorker was causing "confusion and delay" */

    let _toast = (text, disappear = false) => {
      // Prep for Post Construction takes a very long time to run on mobile.
      // Because of this, I'd like the toasts to be persistant on mobile so you know something is still happening.
      if (self.isMobile) {
        let toastOptions = { text };
        if (!disappear) toastOptions.duration = Infinity;
        toast(toastOptions);
      } else {
        toast(text);
      }
    };

    try {
      _toast('Prepping for Post Construction...');

      let jobData = await GetJobData(jobId, ['metadata', 'photos', 'nodes', 'connections', 'traces'], firebase);
      let jobPermissions = await firebase
        .database()
        .ref(`photoheight/job_permissions/${userGroup}/jobs`)
        .once('value')
        .then((s) => s.val());

      _toast('Clearing Completed and Proposed Attributes...');

      let update = {
        compatible_units: null,
        'metadata/tracking_model': 'pci'
      };

      // Loop through the metadata attributes in the button model and set each according to their klogic.
      for (const [attr, expression] of Object.entries(models.metadata ?? {})) {
        update[`metadata/${attr}`] = KLogic.compute(expression, { metadata: jobData.metadata });
      }

      // Insert job_link attribute with appropriate job_id for all ppl database jobs.
      if (firebase.database().app.options.projectId == 'ppl-kws')
        update['metadata/job_link'] = `https://ppl.katapultwebservices.com/map/#${jobId}`;

      let overlappingJobs = [];
      const thisDateSubmitted = jobData?.metadata?.date_OTMR_submitted ?? jobData?.metadata?.date_submitted;
      //get all newer overlapping jobs/poles
      for (const jobPair of Object.entries(jobPermissions ?? {})) {
        let key = jobPair[0];
        let job = jobPair[1];

        if (!job?.metadata) continue;
        const dateSubmitted = job.metadata.date_OTMR_submitted ?? job.metadata.date_submitted;
        // If the job is in the correct app type and status
        // TODO (2024-11-26): This list of app statuses should be configurable via the pole app portal model-editor
        if (
          job.metadata.app_type &&
          ['attachment_application', 'one_touch_make_ready_application', 'unauthorized_attachment_application'].includes(
            job.metadata.app_type
          ) &&
          job.metadata.app_status &&
          ![
            'Draft',
            'Review for Completeness',
            'Incomplete',
            'Rejected',
            'Reviewing for Completeness (Revisions)',
            'Awaiting Payment (MR ENG)',
            'Canceled',
            'On Hold',
            'Complete'
          ].includes(job.metadata.app_status) &&
          key != jobId &&
          dateSubmitted &&
          thisDateSubmitted &&
          dateSubmitted > thisDateSubmitted
        ) {
          let overlaps = job.metadata?.pole_tag_summary?.split(', ') ?? [];
          overlappingJobs.push({
            key,
            nodes: overlaps,
            name: job.name,
            date_submitted: dateSubmitted,
            app_status: job.metadata.app_status
          });
        }
      }

      _toast('Loading Post Construction Map Styles...');
      // if there are map styles passed in, use those, if there's not fallback to using the pci map styles
      const mapStylesKey = preppedMapStylesKey ?? 'pci';
      const mapStylesRef = FirebaseWorker.ref(`photoheight/company_space/${userGroup}/models/map_styles/${mapStylesKey}`);
      const mapStyles = (await mapStylesRef.once('value')).val();
      const jobStyles = { default: mapStyles };
      if (mapStyles) update['map_styles/default'] = mapStyles;
      else jobStyles.default = (await FirebaseWorker.ref(`photoheight/jobs/${jobId}/map_styles/default`).once('value')).val();

      const lastPCIStatus = jobData.metadata?.PCI_status;
      //Loop connections
      const connLookup = {};
      for (let connId in jobData.connections) {
        const conn = jobData.connections[connId];
        connLookup[conn.node_id_1] ??= [];
        connLookup[conn.node_id_1].push({ id: 1, connId });
        connLookup[conn.node_id_2] ??= [];
        connLookup[conn.node_id_2].push({ id: 2, connId });
        for (let sectionId in conn.sections) {
          const section = conn.sections[sectionId];
          section.multi_attributes ??= {};
          section.photos ??= {};
          section.multi_attributes.field_completed = update[
            `connections/${connId}/sections/${sectionId}/multi_attributes/field_completed`
          ] = null;
          if (options.prepForPCISubsequentRounds && lastPCIStatus === undefined) {
            section.multi_attributes.PCI_midspan_status = update[
              `connections/${connId}/sections/${sectionId}/multi_attributes/PCI_midspan_status`
            ] = { button_added: '' };
          } else if (options.prepForPCISubsequentRounds) {
            let PCI_midspan_status = PickAnAttribute(section.multi_attributes, 'PCI_midspan_status') || '';
            if (PCI_midspan_status === 'Pass') {
              let existingKey = Object.keys(section?.multi_attributes?.PCI_midspan_status)[0];
              section.multi_attributes.PCI_midspan_status[existingKey] = update[
                `connections/${connId}/sections/${sectionId}/multi_attributes/PCI_midspan_status/${existingKey}`
              ] = 'Already passed in previous round';
            } else if (PCI_midspan_status.includes('Fails')) {
              let existingKey = Object.keys(section?.multi_attributes?.PCI_midspan_status)[0];
              section.multi_attributes.PCI_midspan_status[existingKey] = update[
                `connections/${connId}/sections/${sectionId}/multi_attributes/PCI_midspan_status/${existingKey}`
              ] = '';
            } else {
              section.multi_attributes.PCI_midspan_status = update[
                `connections/${connId}/sections/${sectionId}/multi_attributes/PCI_midspan_status`
              ] = { button_added: '' };
            }
          }
          for (let photoId in conn.sections[sectionId].photos) {
            let date_taken = jobData.photos?.[photoId]?.synced_time || jobData.photos?.[photoId]?.date_taken || new Date().getTime();
            section.photos[photoId] = update[`connections/${connId}/sections/${sectionId}/photos/${photoId}`] = {
              date_taken,
              pre_construction: true,
              association: conn.sections[sectionId].photos[photoId].association || conn.sections[sectionId].photos[photoId]
            };
          }
          GeofireTools.setGeohash('sections', section, connId, jobStyles, update, { sectionId });
        }
      }

      // Updates the job metadata for the first round of PCI when the "Subsequent Rounds of PCI" checkbox is unchecked
      if (options.prepForPCISubsequentRounds && lastPCIStatus === undefined) {
        update[`metadata/PCI_status`] = 'ABD';
        if (!jobData?.metadata?.PCI_results) {
          let generatedKey = FirebaseWorker.ref().push().key;
          let newPCIResultsAttribute = GetNewAttributeValue('PCI_results', otherAttributes);
          if (jobData.metadata) jobData.metadata.PCI_results = update[`metadata/PCI_results/${generatedKey}`] = newPCIResultsAttribute;
        }
      }
      // Updates the job metadata when the "Subsequent Rounds of PCI" checkbox is unchecked
      else if (options.prepForPCISubsequentRounds) {
        update[`metadata/PCI_status`] = 'ABD';
      }

      let nodesToInspect = 0;

      for (const nodeId in jobData.nodes) {
        //TODO: Poles without a prep for pci attribute and install_dt after job collection date (earliest time bucket) mark as complex

        const nodeType = PickAnAttribute(jobData.nodes[nodeId].attributes, modelDefaults.node_type_attribute);
        const nodeIsPole = modelDefaults.pole_node_types.includes(nodeType);
        const nodeIsProposedAnchor = modelDefaults.proposed_anchor_node_type.includes(nodeType);

        let mrCategory = '';
        // Save the original MR category in a new attribute
        if (nodeIsPole) {
          if (jobData.nodes[nodeId].attributes.mr_category) {
            // get the mr category value
            mrCategory = PickAnAttribute(jobData.nodes[nodeId].attributes, 'mr_category');

            // write the value to the location in database
            await FirebaseWorker.database()
              .ref(`/photoheight/jobs/${jobId}/nodes/${nodeId}/attributes/original_mr_category/button_added`)
              .set(mrCategory);

            // assign jobData with up-to-date
            jobData = await GetJobData(jobId, ['metadata', 'photos', 'nodes', 'connections', 'traces'], firebase);
          }
        }

        const node = jobData.nodes[nodeId];
        node.attributes ??= {};
        node.photos ??= {};
        const attributes = node.attributes;

        // Loop through the attributes in the button model and set each according to their klogic.
        for (const [attr, expression] of Object.entries(models.attributes ?? {})) {
          // Get the current value of the attribute from the node.
          // Note: make sure it is null, not defined, so the klogic expressions are free to set the new value to the current value without firebase throwing an undefined error.
          const attributeValue = Path.get(attributes, `${attr}.*`) ?? null;
          // Set the new value to be a single `button_added` instance with the computed value.
          const value = KLogic.compute(expression, { attributeValue, attributes, nodeIsPole });
          update[`nodes/${nodeId}/attributes/${attr}`] = { button_added: value };
          if (value == null) {
            delete node.attributes[attr];
          } else {
            node.attributes[attr] = { button_added: value };
          }
        }

        for (let photoId in jobData.nodes[nodeId].photos) {
          let date_taken =
            SquashNulls(jobData.photos, photoId, 'synced_time') ||
            SquashNulls(jobData.photos, photoId, 'date_taken') ||
            new Date().getTime();
          node.photos[photoId] = update[`nodes/${nodeId}/photos/${photoId}`] = {
            date_taken,
            pre_construction: true,
            association: jobData.nodes[nodeId].photos[photoId].association || jobData.nodes[nodeId].photos[photoId]
          };
        }

        if (nodeIsPole) {
          // Check to see if the pole has a PCI Failure Type attribute existing, if it has, remove the value from it.
          if (
            options.prepForPCISubsequentRounds &&
            lastPCIStatus !== undefined &&
            PickAnAttribute(jobData.nodes[nodeId].attributes, 'PCI_failure_type')
          ) {
            node.attributes.PCI_failure_type = update[`nodes/${nodeId}/attributes/PCI_failure_type`] = { call_added: '' };
          }
          // Mark nodes that have a newer overlapping job as "Delayed" when prep for PCI is pushed.  Also add PCI note with overlapping job ID
          const poleTagAttrs = Object.values(Path.get(jobData.nodes, `${nodeId}.attributes.pole_tag`) ?? {});

          outerLoop: for (const tag of poleTagAttrs) {
            if (tag.tagtext?.length != 11) continue;

            for (const job of overlappingJobs) {
              if (!job.nodes.includes(tag.tagtext)) continue;

              // Check that the overlapping node is a pole type node
              const overlappingJobData = await GetJobData(job.key, ['nodes'], firebase);
              for (const overlappingNode of Object.values(overlappingJobData.nodes ?? {})) {
                const nodeType = PickAnAttribute(overlappingNode.attributes, modelDefaults.node_type_attribute);
                if (!modelDefaults.pole_node_types.includes(nodeType)) continue;

                node.attributes.PCI_note ??= {};
                node.attributes.PCI_note.button_added = update[`nodes/${nodeId}/attributes/PCI_note/button_added`] =
                  `Overlaps with ${job.name}`;
                node.attributes.post_construction_inspection = update[`nodes/${nodeId}/attributes/post_construction_inspection`] = {
                  button_added: 'Delayed'
                };
                break outerLoop;
              }
            }
          }

          if (update[`nodes/${nodeId}/attributes/post_construction_inspection`]?.button_added !== 'Already passed in previous round') {
            nodesToInspect++;

            //Intelligently clear field_completed and done - clear if:
            //1. "post_construction_inspection" is (going to be) set to a non "Already passed in previous round" value
            //2. & Each value in "mr_state" is non-null / non ''
            if (
              !Object.values(SquashNulls(jobData.nodes, nodeId, 'attributes', 'mr_state')).some((v) => {
                return v == null || v == '';
              })
            ) {
              node.attributes.field_completed = update[`nodes/${nodeId}/attributes/field_completed`] = { value: '' };
              node.attributes.done = update[`nodes/${nodeId}/attributes/done`] = { value: '' };
            }
          }
        }

        //If new anchor, make sure it has an anchor_was_installed attribute
        if (nodeIsProposedAnchor && !PickAnAttribute(jobData.nodes[nodeId].attributes, 'anchor_was_installed')) {
          node.attributes.anchor_was_installed = update[`nodes/${nodeId}/attributes/anchor_was_installed`] = { button_added: '' };
        }

        let mainPhotoId = GetMainPhoto(jobData.nodes[nodeId].photos);
        if (mainPhotoId) {
          let mainPhoto = jobData.photos?.[mainPhotoId];

          //Cable Bonded to Ground Wire
          if (
            (SquashNulls(mainPhoto, 'photofirst_data', 'insulator') &&
              Object.values(mainPhoto.photofirst_data.insulator).some((data) => {
                if (data.mr_note && data.mr_note.toUpperCase().includes('GROUND')) return true;
              })) ||
            (SquashNulls(mainPhoto, 'photofirst_data', 'equipment') &&
              Object.values(mainPhoto.photofirst_data.equipment).some((data) => {
                if (data.mr_note && data.mr_note.toUpperCase().includes('GROUND')) return true;
              })) ||
            (SquashNulls(mainPhoto, 'photofirst_data', 'wire') &&
              Object.values(mainPhoto.photofirst_data.wire).some((data) => {
                if (data.mr_note && data.mr_note.toUpperCase().includes('GROUND')) return true;
              }))
          ) {
            if (!PickAnAttribute(jobData.nodes[nodeId].attributes, 'cable_was_bonded'))
              node.attributes.cable_was_bonded = update[`nodes/${nodeId}/attributes/cable_was_bonded`] = { button_added: '' };
          }

          // Checks related to guying markers
          const guyMarkers = mainPhoto?.photofirst_data?.guying;
          const guyMarkerValues = Object.values(guyMarkers ?? {});
          //Proposed guying check
          if (
            guyMarkerValues.some(
              (data) =>
                modelDefaults.anchor_node_types.includes(data.guying_type) &&
                SquashNulls(jobData, 'traces', 'trace_data', data._trace, 'proposed')
            )
          ) {
            node.attributes.down_guy_installed = update[`nodes/${nodeId}/attributes/down_guy_installed`] = { button_added: '' };
          }
          // Check for upgrading down guys, only if this attribute hasn't been set before
          if (
            guyMarkerValues.some((marker) => marker.proposed_wire_spec) &&
            !PickAnAttribute(jobData.nodes[nodeId].attributes, 'down_guy_was_upgraded')
          ) {
            node.attributes.down_guy_was_upgraded = update[`nodes/${nodeId}/attributes/down_guy_was_upgraded`] = { button_added: '' };
          }
        }
        const nodeConnections = {};
        connLookup[nodeId]?.forEach((conn) => {
          nodeConnections[conn.connId] = conn.id;
        });
        GeofireTools.setGeohash('nodes', node, nodeId, jobStyles, update, { nodeConnections });
      }

      //Attribute 'PCI_inspection_count' tallies poles we set for inspection
      update['metadata/PCI_inspection_count'] = nodesToInspect;

      // Set if we want to include midspans or not
      if (options.includeMidspansForPCI !== undefined) update['metadata/include_midspans_for_PCI'] = options.includeMidspansForPCI;

      for (let photoId in jobData.photos) {
        // if photo is already marked as pre_construction, add an outdated attribute
        if (jobData.photos[photoId].pre_construction) {
          update[`photos/${photoId}/pre_construction/outdated`] = true;
        } else update[`photos/${photoId}/pre_construction`] = { date_archived: new Date().getTime() };

        const photoElements = jobData.photos[photoId].photofirst_data || {};
        for (const [elementType, elementList] of Object.entries(photoElements)) {
          for (const [elementId, element] of Object.entries(elementList)) {
            const elementHasProposedAttribute = element.proposed;
            const elementTraceIsProposed = jobData.traces?.trace_data[element._trace]?.proposed === true;
            const elementIsProposed = elementHasProposedAttribute || elementTraceIsProposed;
            if (elementIsProposed) {
              // We don't want to select this marker as an option for photo calibration when transferring heights,
              // so apply the `_pci_ignore_anchor` attribute to it.
              update[`photos/${photoId}/photofirst_data/${elementType}/${elementId}/_pci_ignore_anchor`] = true;
              // Remove the proposed attribute from the element and the trace
              update[`photos/${photoId}/photofirst_data/${elementType}/${elementId}/proposed`] = null;
              if (element._trace) {
                update[`traces/trace_data/${element._trace}/proposed`] = false;
              }
            }
          }
        }
      }

      // Set the tracking model to pci
      if (jobCreator == 'ppl_attachments') {
        update['metadata/tracking_model'] = 'pci';
      }

      _toast('Writing Post Construction Data...');
      // Write the update to firebase.
      await LargeUpdate(FirebaseWorker.ref(`photoheight/jobs/${jobId}`), update);

      // If running this module on mobile, we need to get the data updates for this job and pass them back to update the local copy of the data
      if (self.isMobile === true) {
        return update;
      }

      _toast('Job Prepped for Post Construction!', true);
    } catch (err) {
      _toast(`Error: ${err.message}`);
      throw err;
    }
  }
  static checkForDuplicateJobName(name, append, jobPermissions, counter) {
    counter = counter || 1;
    for (let key in jobPermissions) {
      if (jobPermissions[key].name == name + (counter == 1 ? '' : append + counter)) {
        return this.checkForDuplicateJobName(name, append, jobPermissions, ++counter);
      }
    }
    return name + (counter == 1 ? '' : append + counter);
  }
}

export default PrepForPostConstruction;
