import { LitElement, html, css } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { when } from 'lit/directives/when.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js';
import { serialize } from '@shoelace-style/shoelace';
import '../katapult-elements/katapult-dialog';
import { OpenPage } from '../../modules/OpenPage.js';

/** @import { Ref } from 'lit/directives/ref.js' */
/** @import SlInput from '@shoelace-style/shoelace/dist/components/input/input.js' */
/** @import { KatapultButton } from '../katapult-elements/katapult-button.js' */
/** @import { KatapultDialog } from '../katapult-elements/katapult-dialog.js' */
/** @import { CreateReadOnlyUserRequest } from '../../../../functions/source/endpoints/createReadOnlyUser.js' */

export function ShowReadOnlyWelcomeDialog() {
  const dialog = new ReadOnlyWelcomeDialog();
  document.body.appendChild(dialog);
  dialog.updateComplete.then(() => {
    dialog.closeComplete?.then(() => dialog.remove());
  });
}

class ReadOnlyWelcomeDialog extends LitElement {
  get closeComplete() {
    return this._dialogRef.value?.closeComplete;
  }

  static get properties() {
    return {
      _creatingAccount: { type: Boolean },
      _accountCreated: { type: Boolean },
      _disableInputs: { type: Boolean }
    };
  }

  static styles = css`
    #body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--sl-spacing-large);
    }
    #title {
      overflow: hidden;
      display: flex;
      align-items: center;
      gap: var(--sl-spacing-x-small);
      font-size: var(--sl-font-size-2x-large);
      font-weight: var(--sl-font-weight-bold);
      color: var(--sl-color-secondary);
    }
    .row {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: var(--sl-spacing-small);
      width: -webkit-fill-available;
    }
    form {
      display: contents;
    }
    sl-input {
      width: 400px;
      flex-grow: 1;
    }
    sl-checkbox {
      color: var(--sl-color-neutral-800);
    }
    .paragraph {
      display: flex;
      flex-direction: column;
      gap: var(--sl-spacing-x-small);
      box-sizing: border-box;
      padding: 0 var(--sl-spacing-large);
      color: var(--sl-color-neutral-800);
      font-weight: var(--sl-font-weight-semibold);
      text-align: center;
    }
    table {
      text-align: left;
      align-self: center;
      font-size: var(--sl-font-size-medium);
      font-weight: var(--sl-font-weight-bold);
      color: var(--sl-color-primary);
    }
    td {
      padding: 4px;
    }
  `;

  render() {
    const { _disableInputs: disableInputs } = this;
    const { value: form } = this._formRef ?? {};
    const { value: companyNameInput } = this._companyNameInputRef ?? {};
    return html`
      <katapult-dialog
        ${ref(this._dialogRef)}
        maxWidth="600"
        noHeader
        modal
        .closeOnOutsideClick=${!this._creatingAccount}
        .closeOnEscape=${!this._creatingAccount}
        opened
        @opened=${() => companyNameInput?.focus()}
        @closed=${() => form?.reset()}
      >
        <div id="body">
          <div id="title">
            <div>Welcome to Katapult Pro!</div>
          </div>
          ${when(
            this._accountCreated,
            () => html` <div class="paragraph" style="text-align: center;">A verification link has been sent to your email!</div> `,
            () => html`
              <div class="paragraph" style="text-align: center;">
                We're excited to see how you leverage Katapult maps, measurements, photos, and other data to bring reliable utilities to
                people!
              </div>
              <div class="paragraph">
                <div>With a Katapult Pro account you can...</div>
                <table>
                  <tr>
                    <td><katapult-icon icon="inventory"></katapult-icon></td>
                    <td>View & Download Shared Data</td>
                  </tr>
                  <tr>
                    <td><katapult-icon icon="groups"></katapult-icon></td>
                    <td>Collaborate with Teammates</td>
                  </tr>
                  <tr>
                    <td><katapult-icon icon="forum"></katapult-icon></td>
                    <td>Provide and Receive Feedback</td>
                  </tr>
                </table>
                <div>...and so much more!</div>
              </div>
              <div class="paragraph" style="text-align: center;">Read access to Katapult Pro will always be completely free.</div>
              <form ${ref(this._formRef)} @submit=${(e) => e.preventDefault()}>
                <sl-input
                  ${ref(this._companyNameInputRef)}
                  placeholder="Company Name"
                  name="company_name"
                  required
                  .disabled=${disableInputs}
                  @sl-change=${this._trimValue}
                >
                  <katapult-icon slot="prefix" icon="business"></katapult-icon>
                </sl-input>
                <sl-input
                  type="email"
                  autocomplete="email"
                  placeholder="Email"
                  name="email"
                  required
                  .disabled=${disableInputs}
                  @sl-change=${this._trimValue}
                >
                  <katapult-icon slot="prefix" icon="email"></katapult-icon>
                </sl-input>
                <sl-checkbox size="small" name="promotional_emails" .disabled=${disableInputs}
                  >I would like to receive updates on the latest Katapult Pro features</sl-checkbox
                >
              </form>
            `
          )}
        </div>
        ${when(
          this._accountCreated,
          () => html` <katapult-button slot="buttons" dialog-dismiss>Close</katapult-button> `,
          () => html`
            <katapult-button slot="buttons" icon="no_accounts" dialog-dismiss outlined .disabled=${disableInputs}
              >Continue Without an Account</katapult-button
            >
            <katapult-button
              slot="buttons"
              icon="account_circle"
              color="var(--sl-color-primary)"
              .callback=${this._createAccountButtonCallback.bind(this)}
              >Create a Free Account</katapult-button
            >
          `
        )}
      </katapult-dialog>
    `;
  }

  constructor() {
    super();

    /** @type { boolean } */
    this._creatingAccount = false;

    /** @type { boolean } */
    this._accountCreated = false;

    /** @type { boolean } */
    this._disableInputs = false;

    /** @type { Ref<HTMLFormElement> } */
    this._formRef = createRef();

    /** @type { Ref<SlInput> } */
    this._companyNameInputRef = createRef();

    /** @type { Ref<KatapultDialog> } */
    this._dialogRef = createRef();
  }

  async _createAccountButtonCallback(e) {
    if (this._creatingAccount || this._accountCreated) return;
    this._creatingAccount = this._disableInputs = true;
    const promise = this._createAccount();
    await promise
      .then(() => {
        this._accountCreated = true;
      })
      .catch((err) => {
        this._disableInputs = false;
        if (err.message !== 'Form is invalid') throw err;
      })
      .finally(() => {
        this._creatingAccount = false;
      });
  }

  async _createAccount() {
    const { value: form } = this._formRef ?? {};
    if (!form) throw new Error('Form could not be found');

    const formIsValid = form.reportValidity();
    if (!formIsValid) throw new Error('Form is invalid');

    const formData = serialize(form);
    if (typeof formData.company_name !== 'string') throw new Error('Company name is not a string');
    if (typeof formData.email !== 'string') throw new Error('Email is not a string');

    const url = OpenPage('map', { returnLink: true });
    if (typeof url !== 'string') throw new Error('Failed to determine maps url');

    const jobId = globalThis.katapultAuth._hashParameters?.job_id;
    if (typeof jobId !== 'string') throw new Error('Failed to get job id');

    const jobToken = globalThis.katapultAuth._hashParameters?.job_token;
    if (typeof jobToken !== 'string') throw new Error('Failed to get job token');

    /** @type { CreateReadOnlyUserRequest } */
    const request = {
      jobId,
      jobToken,
      companyName: formData.company_name,
      email: formData.email,
      promotionalEmails: formData.promotional_emails === 'on',
      url
    };

    await globalThis.firebase.functions().httpsCallable('createReadOnlyUser')(request);
  }

  _trimValue(e) {
    const { currentTarget: input } = e;
    if (typeof input.value !== 'string') return;
    input.value = input.value.trim();
  }
}
customElements.define('read-only-welcome-dialog', ReadOnlyWelcomeDialog);

export function ShowReadOnlyWelcomeDialogIfNotShownBefore() {
  const localStorageItemName = 'readOnlyWelcomeDialogShown';
  const localStorageItemValue = 'true';

  const dialogHasAlreadyBeenShown = localStorage.getItem(localStorageItemName) === localStorageItemValue;
  if (dialogHasAlreadyBeenShown) return;

  ShowReadOnlyWelcomeDialog();

  localStorage.setItem(localStorageItemName, localStorageItemValue);
}
