import '../../style-modules/paper-table-style.js';
import { GeoFire } from 'geofire';
import { KatapultGeometry } from 'katapult-toolbox';
import { Convert } from '../../../modules/Convert';
import { Round } from 'katapult-toolbox/katapult-geometry';
import { KatapultDialog } from '../../katapult-elements/katapult-dialog';
import { html } from 'lit';
import { map } from 'lit/directives/map.js';
import { AddPoleToApplication, ApplyPoleLookupData, getUtilityPoleTagCompanies } from '../../../modules/PoleApplication.js';
import { Path } from '../../../modules/Path.js';
import { PickAnAttribute } from '../../../modules/PickAnAttribute.js';

export async function findPoleTagMappingInfo(nodeId, nodes, utilityCompany, options) {
  const node = nodes[nodeId];
  const poleTags = node?.attributes?.pole_tag;
  const sanitizedUtilityCompany = utilityCompany.replace(/[^a-zA-Z]/g, '').toLowerCase();
  const surveyUtilityTag = Object.values(poleTags ?? []).find((x) => {
    const sanitizedCompany = x?.company?.replace(/[^a-zA-Z]/g, '').toLowerCase();
    return sanitizedUtilityCompany.includes(sanitizedCompany);
  });
  const scid = PickAnAttribute(node.attributes, 'scid');
  let surveyTagText = surveyUtilityTag?.tagtext || `SCID: ${scid}`;
  surveyTagText = surveyTagText == 'NO TAG' ? `SCID: ${scid}` : surveyTagText;
  if (options?.useTag) surveyTagText = options.useTag;
  const layerList = await FirebaseWorker.ref(`utility_info/_list`)
    .once('value')
    .then((s) => s.val());
  const layerInfo = layerList?.find((x) => x.name === 'Poles');
  if (!layerInfo) throw new Error('Pole Layer Not Found');
  const geo = new GeoFire(
    FirebaseWorker.database(layerInfo.options.database ? `https://${layerInfo.options.database}.firebaseio.com` : undefined).ref(
      `utility_info/${layerInfo.url}`
    )
  );
  const nearbyPoles = await geo.once({
    center: [node.latitude, node.longitude],
    radius: 0.15 //150m
  });
  const closest = { distance: Infinity, match: null };
  const match = { utilityTag: 'Tag Not Found', distance: 'Distance Not Found', utilityInfo: {} };
  Object.values(nearbyPoles ?? {}).forEach((pole) => {
    const utilityTag = pole?.info?.pole_tag;
    if (!utilityTag) return;
    const distance = KatapultGeometry.CalcDistance(node.latitude, node.longitude, pole.l[0], pole.l[1]);
    if (utilityTag === surveyTagText) {
      match.utilityTag = utilityTag;
      const distanceFt = Convert(distance, 'm', 'ft');
      match.distance = Round(distanceFt, 2);
      match.utilityInfo = pole.info;
      match.poleData = pole;
      match.poleData.$key = pole.info?.pole_id;
    }
    if (distance < closest.distance) {
      closest.distance = distance;
      closest.match = pole;
    }
  });
  if (match.utilityTag === 'Tag Not Found' && closest.match) {
    match.utilityTag = closest.match.info?.pole_tag;
    const distanceFt = Convert(closest.distance, 'm', 'ft');
    match.distance = Round(distanceFt, 2);
    match.utilityInfo = closest.match.info;
    match.poleData = closest.match;
    match.poleData.$key = closest.match.info?.pole_id;
  }
  return {
    survey_tag: surveyTagText,
    node_id: nodeId,
    utility_tag: match.utilityTag,
    distance: match.distance,
    utilityInfo: match.utilityInfo,
    poleData: match.poleData
  };
}

export async function openAddPoleToAppDialog(mappedPoles, toJobId, utilityCompany, userGroup, otherAttributes) {
  const dialog = KatapultDialog.open({
    dialog: { title: 'Pole Tag Mapping', maxWidth: 600, closeButton: false, draggable: true },
    template: () => html`
      <div>
        <paper-table outline dividers rounded style="overflow: hidden; margin-bottom: 12px;">
          <paper-row header short grey>
            <paper-cell>Survey Tag</paper-cell>
            <paper-cell>Utility Tag</paper-cell>
            <paper-cell>Distance (in feet)</paper-cell>
            <paper-cell>Add to App</paper-cell>
            <paper-cell>App Order</paper-cell>
            <paper-cell>Remap</paper-cell>
          </paper-row>
          ${map(
            mappedPoles,
            (pole) =>
              html` <paper-row>
                <paper-cell>${pole.survey_tag}</paper-cell>
                <paper-cell>${pole.utility_tag}</paper-cell>
                <paper-cell>${pole.distance}</paper-cell>
                <paper-cell>
                  <paper-checkbox @checked-changed=${(e) => (pole.add_to_app = e.target.checked)}></paper-checkbox>
                </paper-cell>
                <paper-cell>
                  <paper-input type="number" @input=${(e) => (pole.app_order = e.target.value)}></paper-input>
                </paper-cell>
                <paper-cell>
                  <paper-checkbox @checked-changed=${(e) => (pole.remap = e.target.checked)}></paper-checkbox>
                </paper-cell>
              </paper-row>`
          )}
        </paper-table>
        <katapult-button dialog-dismiss slot="buttons">Cancel</katapult-button>
        <katapult-button id="confirm" color="var(--secondary-color)" textColor="#fff" slot="buttons"> Confirm </katapult-button>
      </div>
    `
  });
  await dialog.openStart;
  const confirmButton = dialog.querySelector('#confirm');
  confirmButton.callback = async () => {
    const job = await FirebaseWorker.ref(`photoheight/jobs/${toJobId}`)
      .once('value')
      .then((s) => s.val());
    if (!job) throw new Error(`Job ${toJobId} not found`);
    const remapPoles = mappedPoles.filter((pole) => pole.remap);
    const addPoles = mappedPoles.filter((pole) => pole.add_to_app);
    if (addPoles.length > 0) await addPoleToApplication(addPoles, job, toJobId, utilityCompany, userGroup, otherAttributes);
    if (remapPoles.length > 0) await getRemapDialog(remapPoles, job, toJobId, utilityCompany, userGroup, otherAttributes);
    dialog.close();
  };
}

export async function addPoleToApplication(mappedPoles, job, toJobId, utilityCompany, userGroup, otherAttributes) {
  let update = {};
  let appOrderNext = 0;
  const metadata = job.metadata;
  const portalConfig = await FirebaseWorker.ref(`photoheight/company_space/${utilityCompany}/portal_config`)
    .once('value')
    .then((s) => s.val());
  if (portalConfig == null) throw new Error(`No portal config found for utility company ${utilityCompany}`);
  const utilityPoleTagCompanies = getUtilityPoleTagCompanies(portalConfig.pole_tag_company, utilityCompany, otherAttributes?.company);
  const appType = Object.values(portalConfig?.app_types).find((x) => x.type == metadata?.app_type);
  if (appType == null) throw new Error(`No configuration found for app type ${metadata?.app_type}`);
  for (const pole of mappedPoles) {
    if (!pole.add_to_app) continue;
    let node = job.nodes[pole.node_id];
    node.attributes.pole_app_order = pole.app_order ? { app_added: pole.app_order } : { app_added: appOrderNext++ };
    const nodeId = pole.node_id;
    const lookupDataResults = ApplyPoleLookupData(node, pole.poleData, appType.pole_attributes, portalConfig.pole_tag_company);
    node = lookupDataResults.pole;
    update = Object.fromEntries(
      Object.entries(lookupDataResults.update).map(([key, value]) => [Path.join([`nodes/${nodeId}`, key], '/'), value])
    );
    // Make sure the pole does not get moved (now that it is in survey mode).
    delete update[`nodes/${nodeId}/latitude`];
    delete update[`nodes/${nodeId}/longitude`];
    await AddPoleToApplication(node, {
      jobId: toJobId,
      nodeId: nodeId,
      metadata: metadata,
      nodes: job.nodes,
      mapStyles: job.map_styles,
      sharing: job.sharing,
      userGroup: userGroup,
      portalConfig: portalConfig,
      utilityPoleTagCompanies
    });
  }
}

async function getRemapDialog(nodes, job, toJobId, utilityCompany, userGroup, otherAttributes) {
  const jobCreator = job.job_creator;
  const dialog = KatapultDialog.open({
    dialog: { title: 'Pole Tag Mapping', maxWidth: 600, closeButton: false, draggable: true },
    template: () => html`
      <div>
        <paper-table outline dividers rounded style="overflow: hidden; margin-bottom: 12px;">
          <paper-row header short grey style="display:flex; align-items:center;">
            <paper-cell style="justify-content: center;">Survey Tag</paper-cell>
            <paper-cell style="justify-content: center;">New Tag</paper-cell>
            <paper-cell style="justify-content: center;">Add to App</paper-cell>
            <paper-cell style="justify-content: center;">App Order</paper-cell>
          </paper-row>
          ${map(
            nodes,
            (pole) =>
              html` <paper-row style="display:flex; align-items:center;">
                <paper-cell>${pole.survey_tag}</paper-cell>
                <paper-cell>
                  <paper-input type="text" @input=${(e) => (pole.new_tag = e.target.value)}></paper-input>
                </paper-cell>
                <paper-cell>
                  <paper-checkbox @checked-changed=${(e) => (pole.add_to_app = e.target.checked)}></paper-checkbox>
                </paper-cell>
                <paper-cell>
                  <paper-input type="number" @input=${(e) => (pole.app_order = e.target.value)}></paper-input>
                </paper-cell>
              </paper-row>`
          )}
        </paper-table>
        <katapult-button dialog-dismiss slot="buttons">Cancel</katapult-button>
        <katapult-button id="confirm" color="var(--secondary-color)" textColor="#fff" slot="buttons"> Confirm </katapult-button>
      </div>
    `
  });

  await dialog.openStart;
  const confirmButton = dialog.querySelector('#confirm');
  confirmButton.callback = async () => {
    const newMappedPoles = [];
    for (const pole of nodes) {
      const newMapping = await findPoleTagMappingInfo(pole.node_id, job.nodes, utilityCompany, { useTag: pole.new_tag });
      newMapping.app_order = pole.app_order;
      newMapping.add_to_app = pole.add_to_app;
      newMappedPoles.push(newMapping);
    }
    await addPoleToApplication(newMappedPoles, job, toJobId, utilityCompany, userGroup, otherAttributes);
    dialog.close();
  };
}
