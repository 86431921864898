import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { gvector } from '../../js/open-source/gvector.js';

class APIMapLayer extends PolymerElement {
  static get template() {
    return html``;
  }

  static get is() {
    return 'api-map-layer';
  }

  static get properties() {
    return {
      apiLayers: {
        type: Object,
        value: () => {
          return {};
        }
      },
      mapIsReady: {
        type: Boolean,
        value: false
      }
    };
  }

  static get observers() {
    return ['layersChanged(layers.*)', 'mapIsReadyChanged(mapIsReady)'];
  }

  ready() {
    super.ready();
  }

  mapIsReadyChanged(mapIsReady) {
    if (mapIsReady) {
      // Add any layers to the map if they aren't already
      for (let layerId in this.apiLayers) {
        if (this.layers[layerId].type == 'API Layer' && !this.apiLayers[layerId].apiLayer.map) {
          this.apiLayers[layerId].apiLayer.setMap(this.map);
        }
      }
    }
  }

  removeAPILayer(layerId) {
    this.apiLayers[layerId].apiLayer.setMap(null);
    delete this.apiLayers[layerId];
  }

  getLayerGeometries(layerId) {
    return this.apiLayers[layerId].apiLayer.getGeometries();
  }

  getPropertiesForLayerGeometry(layerId, geometry) {
    return this.apiLayers[layerId].apiLayer.getPropertiesForGeometry(geometry);
  }

  setLayerSelectable(layerId, selectable) {
    this.apiLayers[layerId].selectable = selectable;
    this.apiLayers[layerId].apiLayer.setClickable(selectable);
  }

  layerIsSelectable(layerId) {
    return !!this.apiLayers[layerId].selectable;
  }

  addAPILayer(layerId) {
    // Copy the record to the local list
    this.apiLayers[layerId] = JSON.parse(JSON.stringify(this.layers[layerId]));

    // Make a copy of the layer options
    let layerOptions = JSON.parse(JSON.stringify(this.layers[layerId].options || {}));

    // Add defaults for missing options
    // Reference this documentation for options:
    // http://jasonsanford.github.io/google-vector-layers/documentation/#docs-symbology
    layerOptions.fields = layerOptions.fields || '*';
    layerOptions.uniqueField = layerOptions.uniqueField || 'OBJECTID';
    layerOptions.scaleRange = layerOptions.scaleRange || [13, 20];
    layerOptions.symbology = layerOptions.symbology || {
      type: 'single',
      vectorOptions: {
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: '#000000',
        fillOpacity: 0.3
      }
    };
    // Set the option to only have one info window
    layerOptions.singleInfoWindow = true;

    // Add custom option for clickable (default to true)
    layerOptions.clickable = this.apiLayers[layerId].selectable == null ? true : !!this.apiLayers[layerId].selectable;

    // Create the layer and assign the instance of it to the local list
    let newLayer = new gvector.AGS({
      url: this.apiLayers[layerId].url,
      ...layerOptions,
      infoWindowTemplate: this.infoWindowTemplateFunction
    });

    // Set up the loading event callback
    newLayer.setLoadingCallback(this.loadingChanged.bind(this));

    // Add an instance of the layer to the list
    this.apiLayers[layerId].apiLayer = newLayer;

    // Add the layer to the map
    this.apiLayers[layerId].apiLayer.setMap(this.map);
  }

  infoWindowTemplateFunction(properties) {
    let output = '<h3>Feature Properties</h3>';
    for (let prop in properties) {
      output += `<b>${prop}:</b> ${properties[prop]}<br />`;
    }
    return output;
  }

  loadingChanged(loading, layer) {
    for (let layerId in this.apiLayers) {
      if (this.apiLayers[layerId].apiLayer == layer) {
        this.dispatchEvent(
          new CustomEvent('loading-changed', { detail: { loading, loadingName: layerId }, bubbles: true, composed: true })
        );
        break;
      }
    }
  }

  layersChanged() {
    if (!this.layers) return;

    // Loop through the apiLayers and add to the map any new layers from the layers object
    for (let layerId in this.apiLayers) {
      // If the global layer object does not have the layer any
      // more, remove it from the map and the local apiLayers object
      if (this.layers[layerId] == null) {
        this.removeAPILayer(layerId);
      }
    }

    // Loop through the layers object and add any new layers to the map
    for (let layerId in this.layers) {
      // Check that the layer is an API layer and is not already in the local list
      if (this.layers[layerId]?.type == 'API Layer') {
        if (this.apiLayers[layerId] == null) {
          this.addAPILayer(layerId);
        } else {
          // If the layer already exists, update the selectable state if needed
          if (this.layers[layerId].selectable != this.apiLayers[layerId].selectable) {
            this.setLayerSelectable(layerId, this.layers[layerId].selectable);
          }
        }
      }
    }
  }
}
window.customElements.define(APIMapLayer.is, APIMapLayer);
