import firebase from 'firebase/compat/app';
import 'firebase/database';
import { Path } from './Path.js';
import { DateTime } from 'luxon';
import { getOverlappingWork } from './OverlappingWork.js';
import { composeItemAttributesUpdate } from './katapult-job/KatapultJobUpdateComposers.js';
import { createReplacingItemAttributesMutation } from './katapult-job/KatapultJobUtilities.js';

/** @import { DirectoryData } from './MasterLocationDirectory.js' */
/** @import { OverlappingJobRecord } from './OverlappingWork.js' */

/**
 * Takes in a list of master location directories and returns all necessary overlapping notes. If an update object is passed in, add
 * overlapping_note attribute to the update object.
 * @param {string} searchJobId - the id of the job we are searching for overlapping notes for
 * @param {DirectoryData[]} locationDirectories - an array of master location directory ids to search
 * @param {object} [options] - an object containing the options for the function
 * @param {boolean} [options.useUniqueIds] - whether or not to use unique ids
 * @param {object} [options.update] - the update object to add the overlapping_note attribute to
 * @param {object} [options.nodes] - the nodes object
 * @param {string} [options.userGroup] - the user group
 * @param {object} [options.jobStyles] - the job styles object
 * @param {(progressMessage: string) => void} [options.onProgress] - a function to be called with text to notify the user of progress
 * @returns {Promise<object[]>} An array of overlapping notes
 */
export async function AddOverlappingNotes(searchJobId, locationDirectories, options = {}) {
  const { useUniqueIds, update, nodes, userGroup, jobStyles, onProgress } = options;
  const realtimeDb = globalThis.FirebaseWorker?.database() ?? firebase.database();

  if (!locationDirectories?.length || !nodes || !userGroup)
    return [{ text: 'Please Select a Location Directory to import overlapping notes.' }];

  /** @type {Record<string,Record<string,OverlappingJobRecord>>} */
  const overlappingJobs = {};
  /** @type {Record<string,Record<string,boolean>>} */
  const overlappingNodes = {};

  // loop through all of the master location directories and get all of the nodes that match in it
  for (const locationDirectory of locationDirectories) {
    onProgress?.(`Loading Overlapping Work for ${locationDirectory.name}`);
    const overlappingWork = await getOverlappingWork(searchJobId, locationDirectory, { useUniqueIds });

    for (const overlappingJobRecord of overlappingWork) {
      const { id: overlappingJobId, locations } = overlappingJobRecord;
      overlappingJobs[overlappingJobId] ??= {};
      overlappingJobs[overlappingJobId][locationDirectory.name] = overlappingJobRecord;
      locations.forEach((location) => {
        overlappingNodes[location.searchNodeId] ??= {};
        overlappingNodes[location.searchNodeId][overlappingJobId] = true;
      });
    }
  }

  // TODO (2024-08-15): Might be able to loop over something else here
  for (const nodeId in nodes) {
    const overlappingNotes = [];

    if (overlappingNodes[nodeId]) {
      for (const jobId in overlappingNodes[nodeId]) {
        const directoriesWithJob = overlappingJobs[jobId];
        // If the job is in multiple directories, get the first one to display (it doesn't really matter which one)
        const firstDirectoryOverlappingJobRecord = Object.values(directoriesWithJob)[0];
        const note = getOverlappingNote(jobId, firstDirectoryOverlappingJobRecord, directoriesWithJob, true);

        // If the job is a draft or canceled, don't add it to the overlapping notes
        if (typeof note.appStatus == 'string' && ['Draft', 'Canceled'].includes(note.appStatus)) {
          delete overlappingJobs[jobId];
          continue;
        }
        // Otherwise, add the note to the list of overlapping notes
        overlappingNotes.push(note);
      }
    }

    // Get the attributes mutation
    const sortedOverlappingNotes = overlappingNotes.sort((a, b) => (b.date ?? 0) - (a.date ?? 0));
    const newNotes = Object.fromEntries(sortedOverlappingNotes.map((note) => [realtimeDb.ref().push().key, note.text]));
    const attributesMutation = createReplacingItemAttributesMutation(nodes[nodeId].attributes, { overlapping_note: newNotes });

    // Add the overlapping note to the update object
    Path.set(nodes[nodeId], 'attributes.overlapping_note', newNotes);
    Object.assign(update, composeItemAttributesUpdate('node', nodeId, nodes[nodeId], attributesMutation, { jobStyles }));
  }

  // Get the "results" array to return
  const unsortedResults = Object.entries(overlappingJobs).map(([overlappingJobId, recordsByDirectoryName]) =>
    getOverlappingNote(overlappingJobId, Object.values(recordsByDirectoryName)[0], recordsByDirectoryName, false)
  );
  const results = unsortedResults.sort((a, b) => (b.date ?? 0) - (a.date ?? 0));

  // Add a header to the list of overlapping jobs
  return [{ text: results.length ? 'Overlapping Jobs:' : 'No overlapping jobs found.' }, ...results];
}

/**
 * Get a note for the overlapping job
 * @param {string} overlappingJobId - The id of the overlapping job
 * @param {OverlappingJobRecord} overlappingJobRecord - Information about the overlapping job
 * @param {Record<string, OverlappingJobRecord>} directoriesWithJob - A list of directories that the job is in
 * @param {boolean} overlapsWith - Start the note with "Overlaps with"
 * @returns {{ text: string, appStatus?: string, date?: number }} The note to be added
 */
function getOverlappingNote(overlappingJobId, overlappingJobRecord, directoriesWithJob, overlapsWith) {
  // If the job is unshared, return a note saying so
  if (!overlappingJobRecord.isShared) {
    const directoryNames = Object.keys(directoriesWithJob);
    return { text: `Unshared job ${overlappingJobId} in ${directoryNames.join(', ')}` };
  }

  // Get the name, app status, and date submitted of the overlapping job
  const { name: jobName, appInfo: { appStatus = 'App Status Not Set', dateSubmitted } = {} } = overlappingJobRecord;
  const prettyData = dateSubmitted ? DateTime.fromMillis(dateSubmitted).toFormat('MM/dd/yyyy') : 'No Date Submitted';
  const text = `${overlapsWith ? 'Overlaps with ' : ''}${jobName} - ${prettyData} (${appStatus})`;

  return { appStatus, date: dateSubmitted, text };
}
